import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { ICreateDocumentCategoryParams } from "./types";
import {
  CreateDocumentCategoryMutation,
  ListDocumentCategoriesQuery,
  GetDocumentCategoryQuery,
  DeleteDocumentCategoryMutation,
  UpdateDocumentCategoryMutation,
} from "../../API";

class DocumentCategoryService {
  /*
    Task: Create a new DocumentCategory using graphql
  */
  async createDocumentCategory(
    DocumentCategory: ICreateDocumentCategoryParams
  ) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createDocumentCategory,
        variables: { input: DocumentCategory },
      })) as { data: CreateDocumentCategoryMutation; errors: any[] };

      // send back newly created DocumentCategory details
      return temp.data.createDocumentCategory;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: get DocumentCategory by id using graphql
  */
  async getDocumentCategoryById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: queries.getDocumentCategory,
        variables: { id },
      })) as { data: GetDocumentCategoryQuery; errors: any[] };

      // send back DocumentCategory details
      return temp.data.getDocumentCategory;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: delete DocumentCategory by id using graphql
  */
  async deleteDocumentCategoryById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteDocumentCategory,
        variables: { input: { id } },
      })) as { data: DeleteDocumentCategoryMutation; errors: any[] };

      // send back DocumentCategory details
      return temp.data.deleteDocumentCategory;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: update DocumentCategory by id using graphql
  */
  async updateDocumentCategoryById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateDocumentCategory,
        variables: { input },
      })) as { data: UpdateDocumentCategoryMutation; errors: any[] };

      // send back technology details
      return temp.data.updateDocumentCategory;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: list all DocumentCategory using graphql
  */
  async getAllDocumentCategories(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listDocumentCategories
  ) {
    try {
      // send grapql api call
      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListDocumentCategoriesQuery; errors: any[] };

      if (temp.data.listDocumentCategories?.items) {
        return {
          categories: temp.data.listDocumentCategories?.items,
          nextToken: temp.data.listDocumentCategories?.nextToken,
        };
      }
      // send back all DocumentCategory details
      return { categories: [], nextToken: null };
    } catch (error) {
      return { categories: [], nextToken: null };
    }
  }

  /*
    Task: recursively list all document categories using graphql
  */
  async recursiveFetchListDocumentCategories(
    filter: any = {},
    currentCategories: Array<any> = [],
    reqNextToken: string | null = null,
    query_src = queries.listCompanies
  ): Promise<any> {
    const { categories, nextToken } = await this.getAllDocumentCategories(
      filter,
      reqNextToken,
      query_src
    );

    // merge old and new data
    let allData = [...currentCategories, ...categories];

    if (nextToken) {
      return await this.recursiveFetchListDocumentCategories(
        filter,
        allData,
        nextToken,
        query_src
      );
    } else {
      if (allData?.length) {
        return allData;
      }

      return [];
    }
  }
}

export { DocumentCategoryService };
