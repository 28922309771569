import React, { createContext, useEffect, useReducer } from "react";
import {
  IAirport,
  IAirportVolume,
  ICompany,
  IMapContext,
  IMapProvider,
  IMapState,
  IUser,
} from "./types";
import MapService from "../../services/MapService";
import { useAuth } from "../AuthProvider";
import { constants } from "../../utils";
import { AirportConsortiumGroupSubscription } from "../../services/AirportConsortiumGroupService";

const mapContextAction = {
  SET_AIPORT_LIST: "SET_AIPORT_LIST",
  SET_AIPORT_VOLUME_LIST: "SET_AIPORT_VOLUME_LIST",
  SET_ACTIVE_AIRPORT: "SET_ACTIVE_AIRPORT",
  SET_ACTIVE_CONSORTIUM: "SET_ACTIVE_CONSORTIUM",
  SET_AIRPORT_UPDATE_TEXT: "SET_AIRPORT_UPDATE_TEXT",
  SET_AIRPORT_UPDATE_ALERT: "SET_AIRPORT_UPDATE_ALERT",
  SET_COMPANY_LIST: "SET_COMPANY_LIST",
  SET_USER_LIST: "SET_USER_LIST",
  SET_USER_ORGANIZATION_ROLES: "SET_USER_ORGANIZATION_ROLES",
  SET_IS_USER_A4A_ADMIN: "SET_IS_USER_A4A_ADMIN",
  SET_FILTER_OPERATORS: "SET_FILTER_OPERATORS",
  SET_FILTER_CHAIRS: "SET_FILTER_CHAIRS",
  SET_CURRENT_USER_DB_DATA: "SET_CURRENT_USER_DB_DATA",
  SET_CURRENT_FOCUSED_AIRPORT: "SET_CURRENT_FOCUSED_AIRPORT",
  SET_MAP_ROLES_LOADING: "SET_MAP_ROLES_LOADING",
  REINITIALIZE_STATE: "REINITIALIZE_STATE",
};

type Action = { type: string; payload: any };

const initialMapState: IMapState = {
  aiportList: [],
  airportVolumeList: [],
  activeAirport: null,
  activeConsortium: null,
  airportUpdateText: "",
  airportUpdateAlert: {
    text: "",
    type: undefined,
  },
  companyList: [],
  userList: [],
  userOrganizationRoles: [],
  isUserA4AAdmin: false,
  filterOperators: [],
  filterChairs: [],
  currentUserDbData: null,
  currentFocusedAirport: null,
  isMapRolesLoading: true,
};

function mapReducer(state: IMapState, action: Action): IMapState {
  switch (action.type) {
    case mapContextAction.SET_AIPORT_LIST:
      return { ...state, aiportList: action.payload };
    case mapContextAction.SET_AIPORT_VOLUME_LIST:
      return { ...state, airportVolumeList: action.payload };
    case mapContextAction.SET_ACTIVE_AIRPORT:
      return { ...state, activeAirport: action.payload };
    case mapContextAction.SET_ACTIVE_CONSORTIUM:
      return { ...state, activeConsortium: action.payload };
    case mapContextAction.SET_AIRPORT_UPDATE_TEXT:
      return { ...state, airportUpdateText: action.payload };
    case mapContextAction.SET_AIRPORT_UPDATE_ALERT:
      return { ...state, airportUpdateAlert: action.payload };
    case mapContextAction.SET_COMPANY_LIST:
      return { ...state, companyList: action.payload };
    case mapContextAction.SET_USER_LIST:
      return { ...state, userList: action.payload };
    case mapContextAction.SET_USER_ORGANIZATION_ROLES:
      return { ...state, userOrganizationRoles: action.payload };
    case mapContextAction.SET_IS_USER_A4A_ADMIN:
      return { ...state, isUserA4AAdmin: action.payload };
    case mapContextAction.SET_FILTER_OPERATORS:
      return { ...state, filterOperators: action.payload };
    case mapContextAction.SET_FILTER_CHAIRS:
      return { ...state, filterChairs: action.payload };
    case mapContextAction.SET_CURRENT_USER_DB_DATA:
      return { ...state, currentUserDbData: action.payload };
    case mapContextAction.SET_CURRENT_FOCUSED_AIRPORT:
      return { ...state, currentFocusedAirport: action.payload };
    case mapContextAction.SET_MAP_ROLES_LOADING:
      return { ...state, isMapRolesLoading: action.payload };
    case mapContextAction.REINITIALIZE_STATE:
      return { ...initialMapState };
    default:
      return state;
  }
}

const MapContext = createContext<IMapContext | undefined>(undefined);

const MapProvider: React.FC<IMapProvider> = ({ children }) => {
  const updateAirportConsortiumSubscription =
    AirportConsortiumGroupSubscription.getOnUpdate();
  const createAirportConsortiumSubscription =
    AirportConsortiumGroupSubscription.getOnCreate();
  const [state, dispatch] = useReducer(mapReducer, initialMapState);
  const {
    state: { user, isSignedIn },
  } = useAuth();

  useEffect(() => {
    if (!user?.id) {
      dispatch({
        type: mapContextAction.REINITIALIZE_STATE,
        payload: null,
      });
    }
  }, [user]);

  const fetchAirports = async (
    currentAirports: IAirport[],
    reqNextToken: string | null = null
  ) => {
    const { airports, nextToken } = await MapService.listAirports(
      user?.idToken?.getJwtToken() ?? "",
      reqNextToken
    );
    let allAirports = [...currentAirports, ...airports];
    if (nextToken) {
      fetchAirports(allAirports, nextToken);
    } else {
      const filteredOperators: { value: string; name: string }[] = [];
      const filteredChairs: { value: string; name: string }[] = [];
      allAirports.forEach((airport) => {
        airport.consortiumGroup?.items?.forEach((consortium) => {
          if (
            consortium.operatorId &&
            !filteredOperators.find(
              (operator) => operator.value === consortium.operatorId
            )
          ) {
            filteredOperators.push({
              value: consortium.operatorId,
              name: consortium.operator?.name ?? "",
            });
          }
          if (
            consortium.chairId &&
            !filteredChairs.find((chair) => chair.value === consortium.chairId)
          ) {
            filteredChairs.push({
              value: consortium.chairId,
              name: consortium.chair?.name ?? "",
            });
          }
        });
      });
      dispatch({
        type: mapContextAction.SET_AIPORT_LIST,
        payload: allAirports,
      });
      dispatch({
        type: mapContextAction.SET_FILTER_OPERATORS,
        payload: filteredOperators,
      });
      dispatch({
        type: mapContextAction.SET_FILTER_CHAIRS,
        payload: filteredChairs,
      });
    }
  };

  const fetchAirportVolumes = async (
    currentAirports: IAirportVolume[],
    reqNextToken: string | null = null
  ) => {
    const { airportVolumes, nextToken } = await MapService.listAirportVolumes(
      user?.idToken?.getJwtToken() ?? "",
      reqNextToken
    );
    let allAirportVolumes = [...currentAirports, ...airportVolumes];
    if (nextToken) {
      fetchAirportVolumes(allAirportVolumes, nextToken);
    } else {
      dispatch({
        type: mapContextAction.SET_AIPORT_VOLUME_LIST,
        payload: allAirportVolumes,
      });
    }
  };

  const fetchCompanies = async (
    currentCompanies: ICompany[],
    reqNextToken: string | null = null
  ) => {
    const { companies, nextToken } = await MapService.listCompanies(
      reqNextToken
    );
    let allCompanies = [...currentCompanies, ...companies];
    if (nextToken) {
      fetchCompanies(allCompanies, nextToken);
    } else {
      // sort company data
      allCompanies.sort((itemA: any, itemB: any) => {
        if (itemA.name < itemB.name) {
          return -1;
        }
        if (itemA.name > itemB.name) {
          return 1;
        }
        return 0;
      });

      dispatch({
        type: mapContextAction.SET_COMPANY_LIST,
        payload: allCompanies,
      });
    }
  };

  const fetchUsers = async (
    currentUsers: IUser[],
    reqNextToken: string | null = null
  ) => {
    // deleted user filter condition
    const filter = { deleted: { ne: true } };

    const { users, nextToken } = await MapService.listUsers(
      reqNextToken,
      filter
    );
    let allUsers = [...currentUsers, ...users];
    if (nextToken) {
      fetchUsers(allUsers, nextToken);
    } else {
      // sort company data
      allUsers.sort((itemA: any, itemB: any) => {
        if (
          `${itemA.firstName} ${itemA.lastName}` <
          `${itemB.firstName} ${itemB.lastName}`
        ) {
          return -1;
        }
        if (
          `${itemA.firstName} ${itemA.lastName}` >
          `${itemB.firstName} ${itemB.lastName}`
        ) {
          return 1;
        }
        return 0;
      });

      dispatch({
        type: mapContextAction.SET_USER_LIST,
        payload: allUsers,
      });
    }
  };

  const fetchOrgs = async () => {
    if (user?.dbUserId) {
      const userOrganizationRoles = await MapService.getUserOrganizationRoles(
        user.dbUserId
      );
      if (userOrganizationRoles.length) {
        dispatch({
          type: mapContextAction.SET_USER_ORGANIZATION_ROLES,
          payload: userOrganizationRoles,
        });
      }

      // stop map roles data loading
      dispatch({
        type: mapContextAction.SET_MAP_ROLES_LOADING,
        payload: false,
      });
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      const subscription = updateAirportConsortiumSubscription.subscribe({
        next: async (data: any) => {
          const currentAirports = state.aiportList.map((airport) => {
            if (
              airport.id ===
              data.value.data.onUpdateAirportConsortiumGroup.airportId
            ) {
              return {
                ...airport,
                consortiumGroup: {
                  ...airport.consortiumGroup,
                  items:
                    airport.consortiumGroup?.items?.map((consortiumGroup) => {
                      if (
                        consortiumGroup.id ===
                        data.value.data.onUpdateAirportConsortiumGroup.id
                      ) {
                        return {
                          ...consortiumGroup,
                          ...data.value.data.onUpdateAirportConsortiumGroup,
                        };
                      }
                      return consortiumGroup;
                    }) ?? [],
                },
              };
            }
            return airport;
          });
          if (currentAirports.length) {
            dispatch({
              type: mapContextAction.SET_AIPORT_LIST,
              payload: currentAirports,
            });
          }
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [updateAirportConsortiumSubscription, isSignedIn]);

  useEffect(() => {
    if (isSignedIn) {
      const subscription = createAirportConsortiumSubscription.subscribe({
        next: async (data: any) => {
          const currentAirports = state.aiportList.map((airport) => {
            if (
              airport.id ===
              data.value.data.onCreateAirportConsortiumGroup.airportId
            ) {
              return {
                ...airport,
                consortiumGroup: {
                  ...airport.consortiumGroup,
                  items: [
                    ...(airport.consortiumGroup?.items ?? []),
                    data.value.data.onCreateAirportConsortiumGroup,
                  ],
                },
              };
            }
            return airport;
          });
          if (currentAirports.length) {
            dispatch({
              type: mapContextAction.SET_AIPORT_LIST,
              payload: currentAirports,
            });
          }
        },
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [createAirportConsortiumSubscription, isSignedIn]);

  // useEffect(() => {
  //   if (isSignedIn) {
  //     const subscription = updateAirportSubscription.subscribe({
  //       next: async (data: any) => {
  //         console.log(data.value.data);
  //         // if (currentAirports.length) {
  //         //   dispatch({
  //         //     type: mapContextAction.SET_AIPORT_LIST,
  //         //     payload: currentAirports,
  //         //   });
  //         // }
  //       },
  //     });
  //     return () => {
  //       subscription.unsubscribe();
  //     };
  //   }
  // }, [updateAirportSubscription, isSignedIn]);

  // useEffect(() => {
  //   if (!state.aiportList.length) {
  //     fetchAirports([]);
  //   }
  //   if (!state.companyList.length) {
  //     fetchCompanies([]);
  //   }
  //   if (!state.userList.length) {
  //     fetchUsers([]);
  //   }
  //   if (!state.userOrganizationRoles.length) {
  //     fetchOrgs();
  //   }
  //   if (
  //     user?.groups.includes(constants?.ROLES?.A4A_ADMIN) &&
  //     !state.isUserA4AAdmin
  //   ) {
  //     dispatch({
  //       type: mapContextAction.SET_IS_USER_A4A_ADMIN,
  //       payload: true,
  //     });
  //   }
  // }, [user?.idToken]);

  useEffect(() => {
    if (state.userList) {
      const currentUser = state.userList.find(
        (loopUser) => loopUser.cognitoUserId === user?.id
      );
      if (currentUser) {
        dispatch({
          type: mapContextAction.SET_CURRENT_USER_DB_DATA,
          payload: currentUser,
        });
      }
    }
  }, [state.userList]);

  // fetch api based on subscription updates
  useEffect(() => {
    if (isSignedIn) {
      if (!state.aiportList.length) {
        fetchAirports([]);
      }
      if (!state.companyList.length) {
        fetchCompanies([]);
      }
      if (!state.userList.length) {
        fetchUsers([]);
      }
      if (!state.userOrganizationRoles.length) {
        fetchOrgs();
      }
      if (
        user?.groups.includes(constants?.ROLES?.A4A_ADMIN) &&
        !state.isUserA4AAdmin
      ) {
        dispatch({
          type: mapContextAction.SET_IS_USER_A4A_ADMIN,
          payload: true,
        });
      }
    } else {
      // clear state data
      dispatch({
        type: mapContextAction.REINITIALIZE_STATE,
        payload: initialMapState,
      });
    }
  }, [isSignedIn, user?.orgRole?.admin.length, user?.orgRole?.member.length]);

  return (
    <MapContext.Provider value={{ state, dispatch, fetchAirportVolumes }}>
      {children}
    </MapContext.Provider>
  );
};

function useMapContext(): IMapContext {
  const context = React.useContext(MapContext);
  if (context === undefined) {
    throw new Error("useMapContext must be used within an MapProvider");
  }
  return context;
}

export { MapProvider, useMapContext, mapContextAction };
