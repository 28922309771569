import React from "react";
import "./styles.css";

import { helpers } from "../../../utils";
import { AuthSchema } from "../../../validations/schema";

// props types
interface ITagsInput {
  tags: Array<string>;
  setTags: (prev: any) => void;
  placeholder?: string;
  error?: string;
}

const TagsInput: React.FC<ITagsInput> = ({
  tags,
  setTags,
  placeholder = "",
  error = "",
}) => {
  // const [tags, setTags] = React.useState<Array<string>>([]);

  const handleKeyDown = (e: any) => {
    const value = e.target.value;

    if (e.key !== "Tab") return;
    if (!value.trim()) return;

    let result: any = {};

    result = AuthSchema.signInValidationSchema["email"].validate(value) as any;

    // if there is no validation error
    if (helpers.isEmptyObject(result.error)) {
      setTags([...tags, value.toLowerCase()]);
      e.target.value = "";
    }
  };

  // delete tag on button click
  const handleDeleteTag = (idx: number) => {
    setTags([...tags.filter((el, i) => i !== idx)]);
    // setTags([...tags.splice(idx + 1, 1)]);
  };

  // stopping tab movement
  const handleKeyDownCapture = (event: any) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="tags-input-container">
        {tags.map((item: string, idx: number) => (
          <div className="tag-item" key={idx}>
            <span className="text">{item}</span>
            <span className="close" onClick={() => handleDeleteTag(idx)}>
              &times;
            </span>
          </div>
        ))}
        <input
          onKeyDown={handleKeyDown}
          type="text"
          placeholder={placeholder !== "" ? placeholder : "Type here..."}
          className="tags-input"
          onKeyDownCapture={handleKeyDownCapture}
        />
      </div>
      <p style={{ color: "grey", fontSize: "18px" }}>Press Tab to add item.</p>
      {error !== "" && <p style={{ color: "red" }}>{error}</p>}
    </>
  );
};

export { TagsInput };
