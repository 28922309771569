import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { ICreateOrgAdminUserParams } from "./types";
import {
  CreateOrgAdminUserMutation,
  ListOrgAdminUsersQuery,
  GetOrgAdminUserQuery,
  DeleteOrgAdminUserMutation,
  UpdateOrgAdminUserMutation,
} from "../../API";

class OrgAdminUserService {
  /*
    Task: Create a new OrgAdminUser using graphql
  */
  async createOrgAdminUser(OrgAdminUser: ICreateOrgAdminUserParams) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createOrgAdminUser,
        variables: { input: OrgAdminUser },
      })) as { data: CreateOrgAdminUserMutation; errors: any[] };

      // send back newly created OrgAdminUser details
      if (temp.data.createOrgAdminUser) {
        return temp.data.createOrgAdminUser;
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: get OrgAdminUser by id using graphql
  */
  async getOrgAdminUserById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: queries.getOrgAdminUser,
        variables: { id },
      })) as { data: GetOrgAdminUserQuery; errors: any[] };

      // send back OrgAdminUser details
      if (temp.data.getOrgAdminUser) {
        return temp.data.getOrgAdminUser;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: delete OrgAdminUser by id using graphql
  */
  async deleteOrgAdminUserById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteOrgAdminUser,
        variables: { input: { id } },
      })) as { data: DeleteOrgAdminUserMutation; errors: any[] };

      // send back OrgAdminUser details
      if (temp.data.deleteOrgAdminUser) {
        return temp.data.deleteOrgAdminUser;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: update OrgAdminUser by id using graphql
  */
  async updateOrgAdminUserById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateOrgAdminUser,
        variables: { input },
      })) as { data: UpdateOrgAdminUserMutation; errors: any[] };

      // send back technology details
      if (temp.data.updateOrgAdminUser) {
        return temp.data.updateOrgAdminUser;
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: list all OrgAdminUser using graphql
  */
  async getAllOrgAdminUsers(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listOrgAdminUsers
  ) {
    try {
      // send grapql api call
      // const temp = (await API.graphql({
      //   query: query_src,
      // })) as { data: ListOrgAdminUsersQuery; errors: any[] };

      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListOrgAdminUsersQuery; errors: any[] };

      // send back all OrgAdminUser details

      if (temp.data.listOrgAdminUsers?.items) {
        return {
          relations: temp.data.listOrgAdminUsers?.items,
          nextToken: temp.data.listOrgAdminUsers?.nextToken,
        };
      }

      return { relations: [], nextToken: null };
    } catch (error) {
      console.log(error);
      return { relations: [], nextToken: null };
    }
  }

  /*
    Task: recursively list all OrgAdminUser using graphql
  */
  async recursiveFetchListOrgAdminUsers(
    filter: any = {},
    currentRelations: Array<any> = [],
    reqNextToken: string | null = null,
    query_src = queries.listOrgAdminUsers
  ): Promise<any> {
    const { relations, nextToken } = await this.getAllOrgAdminUsers(
      filter,
      reqNextToken,
      query_src
    );

    // merge data
    let allData = [...currentRelations, ...relations];

    if (nextToken) {
      return await this.recursiveFetchListOrgAdminUsers(
        filter,
        allData,
        nextToken,
        query_src
      );
    } else {
      if (allData?.length) {
        return allData;
      }

      return [];
    }
  }
}

export { OrgAdminUserService };
