import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { CardContent, Typography, Button } from "@mui/material";
import { Sidebar } from "../Common/Sidebar";
import { constants, helpers } from "../../utils";
import { useAuth } from "../AuthProvider";
import AirplanemodeInactiveIcon from "@mui/icons-material/AirplanemodeInactive";
import { AddAirport } from "../AddAirport";
import { useMapContext, mapContextAction } from "./../MapProvider/MapProvider";
import { DataTableBase } from "./../Common/DataTableBase";
import { Link } from "react-router-dom";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
import Swal from "sweetalert2";
import { AirportService } from "./../../services/AirportService";
import { useNavigate } from "react-router-dom";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { toast } from "react-toastify";

interface IGetListView {
  filteredAirportList: Array<any>;
  handleDeactivateAirport: any;
  handleReactivateAirport: any;
}

const GetMemomizedListView: React.FC<IGetListView> = React.memo(
  ({
    filteredAirportList = [],
    handleDeactivateAirport,
    handleReactivateAirport,
  }) => {
    const { dispatch: mapStateDispatch } = useMapContext();
    const { state: authProviderState } = useAuth();

    const isSuperUser = authProviderState?.user?.groups?.includes(
      constants?.ROLES?.A4A_ADMIN
    );

    const columns = [
      {
        name: "Code",
        grow: 0.1,
        selector: (row: any) => row?.code,
        sortable: true,
        cell: (row: any) => (
          <Link
            to={"/main"}
            onClick={() => {
              mapStateDispatch({
                type: mapContextAction.SET_CURRENT_FOCUSED_AIRPORT,
                // payload: row.code,
                payload: row.code,
              });
            }}
          >
            {row.code}
          </Link>
        ),
      },
      {
        name: "State",
        grow: 0.1,
        selector: (row: any) => row?.state,
        sortable: true,
      },
      {
        name: "Name",
        grow: 1,
        selector: (row: any) => row?.name,
        sortable: true,
        cell: (row: any) => (
          <Link to={`/airport/${row?.id}`} title={row.name}>
            {row.name}
          </Link>
        ),
      },
      {
        name: "Region",
        grow: 0.8,
        selector: (row: any) => row?.region,
        sortable: true,
      },
      {
        name: "Action",
        grow: 0.4,
        cell: (row: any) => (
          <>
            <IconButton
              disabled={!isSuperUser || row?.deleted}
              title={`Deactivate ${row?.name}.`}
              onClick={(event) => {
                Swal.fire({
                  title: "Deactivate airport ?",
                  showCancelButton: true,
                  confirmButtonText: "Deactivate",
                }).then(async (result: any) => {
                  if (result.isConfirmed) {
                    await handleDeactivateAirport(row);
                  }
                });
              }}
            >
              <AirplanemodeInactiveIcon
                color={isSuperUser && !row?.deleted ? "error" : "disabled"}
              />
            </IconButton>
            <IconButton
              disabled={!isSuperUser || !row?.deleted}
              title={`Reactivate ${row?.name}.`}
              onClick={(event) => {
                Swal.fire({
                  title: "Reactivate airport ?",
                  showCancelButton: true,
                  confirmButtonText: "Reactivate",
                }).then(async (result: any) => {
                  if (result.isConfirmed) {
                    await handleReactivateAirport(row);
                  }
                });
              }}
            >
              <AirplanemodeActiveIcon
                color={isSuperUser && row?.deleted ? "success" : "disabled"}
              />
            </IconButton>
          </>
        ),
      },
    ];

    let tblData = filteredAirportList?.map((airport: any) => ({
      id: airport?.id,
      name: airport?.name,
      code: airport?.code,
      state: airport?.state,
      region: helpers?.capitalizeFirstLetterEveryWord(
        airport?.region.replaceAll("_", " ").toLocaleLowerCase()
      ),
      deleted: airport?.deleted ?? false,
    }));

    // alpha sort by airport code
    tblData.sort((itemA: any, itemB: any) => {
      if (itemA.code < itemB.code) {
        return -1;
      }
      if (itemA.code > itemB?.code) {
        return 1;
      }
      return 0;
    });

    return (
      <DataTableBase
        columns={columns}
        data={tblData}
        dense={false}
        fixedHeader
        pagination={false}
        persistTableHead
        highlightOnHover
      />
    );
  }
);

const ViewAirport = () => {
  // state
  const [openAddAirport, setOpenAddAirport] = useState<boolean>(false);
  const [filteredAirportList, setFilteredAirportList] = React.useState<
    Array<any>
  >([]);

  const [isSuperUser, setSuperUser] = React.useState<boolean>(false);

  const { state: auth } = useAuth();
  const { state: mapProviderState } = useMapContext();
  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();

  const apiAirport = new AirportService();
  const navigateTo = useNavigate();

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.AIRPORT;

    // if user is not A4A Admin the redirect to main
    if (!auth?.user?.groups?.includes(constants.ROLES.A4A_ADMIN)) {
      // redirect to main page
      navigateTo("/main");
    }

    // is signed user is A4A Admin
    if (auth?.user?.groups?.includes(constants.ROLES.A4A_ADMIN)) {
      setSuperUser(true);
    }

    // clear global search bar text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
      payload: "",
    });

    // set global search bar placeholder text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT,
      payload: "Search airports...",
    });

    // cleanup
    return () => {};
  }, []);

  useEffect(() => {
    // change page title
    if (openAddAirport) {
      document.title = constants.PAGE_TITLE.ADD_AIRPORT;
    } else {
      document.title = constants.PAGE_TITLE.AIRPORT;
    }
  }, [openAddAirport]);

  useEffect(() => {
    // set filtered data
    setFilteredAirportList([...mapProviderState?.aiportList]);
  }, [mapProviderState?.aiportList]);

  useEffect(() => {
    let timeoutId: any = null;

    let searchText = dataProviderState?.topBarSearchText;

    if (searchText.trim() !== "") {
      timeoutId = setTimeout(() => {
        const generalFilter = mapProviderState?.aiportList.filter(
          (airport: any) =>
            airport?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            airport?.code?.toLowerCase().includes(searchText.toLowerCase()) ||
            airport?.state?.toLowerCase().includes(searchText.toLowerCase()) ||
            airport?.region
              ?.split("_")
              ?.join(" ")
              ?.toLowerCase()
              .includes(searchText.toLowerCase())
        );

        // udpate airport list
        setFilteredAirportList([...generalFilter]);
      }, 1000);
    } else {
      // udpate airport list
      setFilteredAirportList([...mapProviderState?.aiportList]);
    }

    // cleanup
    return () => clearTimeout(timeoutId);
  }, [dataProviderState?.topBarSearchText]);

  // handle delete / deactivate airport
  const handleDeactivateAirport = async (airport: any) => {
    const apiParams = {
      id: airport?.id,
      deleted: true,
    };

    // start page loader
    helpers.startPageLoader();

    const response = await apiAirport?.updateAirportById(apiParams);
    // const response = airport;

    // update success
    if (response !== null) {
      let newAirportList = filteredAirportList?.map((item) => {
        if (item?.id === airport?.id) {
          return { ...item, deleted: true };
        } else {
          return { ...item };
        }
      });

      setFilteredAirportList([...newAirportList]);

      // show toast message
      toast.success("Airport Deactivated.", { theme: "colored" });
    }

    // stop page loader
    helpers.stopPageLoader();
  };

  // handle reactivate airport
  const handleReactivateAirport = async (airport: any) => {
    const apiParams = {
      id: airport?.id,
      deleted: false,
    };

    // start page loader
    helpers.startPageLoader();

    const response = await apiAirport?.updateAirportById(apiParams);
    // const response = airport;

    // update success
    if (response !== null) {
      let newAirportList = filteredAirportList?.map((item) => {
        if (item?.id === airport?.id) {
          return { ...item, deleted: false };
        } else {
          return { ...item };
        }
      });

      setFilteredAirportList([...newAirportList]);

      // show toast message
      toast.success("Airport Reactivated.", { theme: "colored" });
    }

    // stop page loader
    helpers.stopPageLoader();
  };

  return (
    <>
      <Grid sx={{ mt: 2 }}>
        <Card
          style={{
            padding: "20px 5px",
            margin: "0 auto",
            borderRadius: "16px",
            background: "rgb(96, 96, 96, 0.05)",
            minHeight: "75vh",
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h6">
              Airports
            </Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                float: "right",
                mb: 3,
              }}
              onClick={() => setOpenAddAirport((prev) => !prev)}
              disabled={!isSuperUser}
            >
              New
            </Button>
            <GetMemomizedListView
              filteredAirportList={filteredAirportList}
              handleDeactivateAirport={handleDeactivateAirport}
              handleReactivateAirport={handleReactivateAirport}
            />
          </CardContent>
        </Card>
      </Grid>
      {openAddAirport && (
        <Sidebar
          handleClose={() => setOpenAddAirport(false)}
          open={openAddAirport}
        >
          <AddAirport closeSidebar={() => setOpenAddAirport(false)} />
        </Sidebar>
      )}
    </>
  );
};

export { ViewAirport };
