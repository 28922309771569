import React, { FC, useState, useEffect } from "react";
import { Button, Box, Alert, AlertColor } from "@mui/material";
import { A4ALoader } from "../Common/A4ALoader";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import SelectDropdown from "../SelectDropdown";
import MapService from "../../services/MapService";
import { useDataContext } from "../DataProvider/DataProvider";

// prop type
interface IAddOrganizationAirport {
  closeSidebar: () => void;
  existingAirports: any[];
  organizationId: string;
  setCurrentOrganization?: any;
}

const AddOrganizationAirport: FC<IAddOrganizationAirport> = ({
  closeSidebar,
  existingAirports,
  organizationId = "",
  setCurrentOrganization,
}) => {
  const { state, dispatch } = useMapContext();
  const { state: dataState } = useDataContext();
  // state
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [addedAirport, setAddedAirport] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: AlertColor;
  }>({
    message: "",
    severity: "success",
  });

  // componentDidMount
  useEffect(() => {
    // cleanup
    return () => {};
  }, []);

  // add company
  const handleAddOrganizationAirport = async () => {
    // clear state
    setAlertMessage({ message: "", severity: "success" });

    if (!addedAirport) {
      setAlertMessage({ message: "Please select Airport", severity: "error" });
      return;
    }

    // start api loader
    setApiLoading(true);

    try {
      const addResponse = await MapService.createAirportConsortiumGroup({
        airportId: addedAirport,
        organizationId: organizationId,
      });
      const addedAirportObj = state.aiportList.find(
        (airport) => airport.id === addedAirport
      );
      const orgObj = dataState.organizationList.find(
        (org) => org.id === organizationId
      );

      if (typeof addResponse !== "string") {
        if (!addedAirportObj?.consortiumType) {
          const updateAirportResponse = await MapService.updateAirport(
            addedAirport,
            { consortiumType: orgObj?.groupType ?? null }
          );
          if (typeof updateAirportResponse !== "string") {
            dispatch({
              type: mapContextAction.SET_AIPORT_LIST,
              payload: state.aiportList.map((airport) => {
                if (airport.id === addedAirport) {
                  return { ...airport, ...updateAirportResponse };
                }
                return airport;
              }),
            });
          }
        }
        setCurrentOrganization((prevCurrentOrganization: any) => ({
          ...prevCurrentOrganization,
          airports: {
            ...prevCurrentOrganization.airports,
            items: [...prevCurrentOrganization.airports.items, addResponse],
          },
        }));
        setAddedAirport("");
        setAlertMessage({
          message: "Added airport successfully to current organization",
          severity: "success",
        });
        closeSidebar();
      } else {
        setAlertMessage({
          message: addResponse ?? "An error occured while adding airport",
          severity: "error",
        });
      }
    } catch (e) {
      console.log(e);
      setAlertMessage({
        message: "An error occured while adding airport",
        severity: "error",
      });
    } finally {
      setApiLoading(false);
    }
  };

  const airportOptions = state.aiportList
    ?.sort((a, b) => a.code.localeCompare(b.code))
    .reduce(
      (
        currentAirportList: Array<{
          name: string;
          value: string;
        }>,
        airport
      ) => {
        if (
          !existingAirports.find(
            (existingAirport) => existingAirport.airportId === airport.id
          )
        ) {
          currentAirportList.push({
            name: `${airport.name} (${airport.code})`,
            value: airport.id,
          });
        }
        return currentAirportList;
      },
      []
    );

  return (
    <div>
      <p>Select Airport</p>
      <SelectDropdown
        label="Airport"
        options={airportOptions}
        selectedValue={addedAirport}
        selectHandler={(e) => {
          setAddedAirport(e.target.value);
          setAlertMessage({ message: "", severity: "success" });
        }}
        sx={{
          width: "100%",
          "& .MuiFormControl-root": {
            width: "100%",
            margin: "0px",
          },
        }}
      />
      {!apiLoading && (
        <Button
          onClick={handleAddOrganizationAirport}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none" }}
        >
          {/* <InsertInvitationIcon /> */}
          Add
        </Button>
      )}
      {!apiLoading && (
        <Button
          onClick={closeSidebar}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none", mx: 1 }}
          color="error"
        >
          {/* <InsertInvitationIcon /> */}
          Cancel
        </Button>
      )}
      {apiLoading ? (
        <Box sx={{ mt: 2 }}>
          <A4ALoader />
        </Box>
      ) : (
        ""
      )}

      {!!alertMessage.message && (
        <Alert severity={alertMessage.severity} sx={{ mt: 10 }}>
          {alertMessage.message}
        </Alert>
      )}
    </div>
  );
};

export { AddOrganizationAirport };
