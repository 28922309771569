import { useEffect } from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "../../routes";
import { AuthProvider } from "../AuthProvider";
import { MapProvider } from "../MapProvider/MapProvider";
import { DataProvider } from "./../DataProvider/DataProvider";
import { CalendarProvider } from "../CalendarProvider/CalendarProvider";
import { TrackingLogProvider } from "./../TrackingLogProvider/TrackingLogProvider";
import { TransactionProvider } from "./../TransactionProvider/TransactionProvider";

function App() {
  // componentDidMount
  useEffect(() => {
    // componentWillUnmount
    return () => {};
  }, []);

  return (
    <Router>
      <AuthProvider>
        <TrackingLogProvider>
          <TransactionProvider>
            <MapProvider>
              <CalendarProvider>
                <DataProvider>
                  <AppRoutes />
                </DataProvider>
              </CalendarProvider>
            </MapProvider>
          </TransactionProvider>
        </TrackingLogProvider>
      </AuthProvider>
    </Router>
  );
}

export { App };
