import React, { FC, useEffect, useRef, useState } from "react";
import mapboxgl, {
  LngLatBoundsLike,
  LngLatLike,
  Map,
  Point,
  Popup,
} from "mapbox-gl";
import {
  AllMapsWrapper,
  MainMapWrapper,
  MapContainer,
  MapFilterFade,
  MapFilterContainer,
  MapFilterIcon,
  MapFilterIconContainer,
  SmallMapContainer,
  SmallMapWrapper,
  SmallMapsWrapper,
  VolumeCategoryIcon,
  MapFilterCloseIcon,
  MapTitle,
  AddVolumeButton,
  MapListContainer,
  ListViewSwitchButton,
  VolumeButtonContainer,
} from "./style";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import ReactDOMServer from "react-dom/server";
import { IAirport, IAirportVolume } from "../MapProvider/types";
import AirportPopup from "../AiportPopup";
import AiportDetailsDrawer from "../AiportDetailsDrawer/AiportDetailsDrawer";
import MapService from "../../services/MapService";
import { Box, Button, IconButton, SelectChangeEvent } from "@mui/material";
import {
  ALL_US_STATES,
  CONTINENTAL,
  mapBounds,
  mapType,
  usStatesFilterOptions,
  usStateCode,
  heatMapRange,
  heatMapRangeColor,
  organizationGroupTypeColorMapping,
  airportRegionOptions,
  usStatesBoundingBoxes,
  heapMapRangeOptions,
  DeliveryMethodOptions,
} from "../../constants/map";
import AirportVolumePopup from "../AirportVolumePopup";
import SelectCheckboxDropdown from "../SelectDropdown";
import MapLegend from "./MapLegend";
import { AirportRegionChoice } from "../../API";
import AddVolumeModal from "./AddVolumeModal";
import { useAuth } from "../AuthProvider";
import { constants } from "../../utils";
import * as XLSX from "xlsx";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { DataTableBase } from "../Common/DataTableBase";
import ListIcon from "@mui/icons-material/List";
import MapIcon from "@mui/icons-material/Map";
import {
  dataContextAction,
  useDataContext,
} from "../DataProvider/DataProvider";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!;

interface IMapBoxMap {
  isVolumesMap: boolean;
  isListView: boolean;
  sidebarCollapse: boolean;
  showApiLoader: () => void;
  hideApiLoader: () => void;
}

const popups: Popup[] = [];
const volumePopups: Popup[] = [];

let markerLayers: string[] = [];

interface IMapFilter {
  state: string;
  showHeatMap: boolean;
  volumeYear: number | undefined;
  airportCode: string;
  airportOrg: string;
  airportRegion: string;
  airportOperator: string;
  airportChair: string;
  deliveryMethod: string;
  volumeCategory: string;
}

const mapFilterInitialState = {
  state: CONTINENTAL,
  showHeatMap: false,
  volumeYear: undefined,
  airportCode: "",
  airportOrg: "",
  airportRegion: "",
  airportOperator: "",
  airportChair: "",
  deliveryMethod: "",
  volumeCategory: "",
};

const currentDate = new Date();

const last5Years = [
  currentDate.getFullYear() - 1,
  currentDate.getFullYear() - 2,
  currentDate.getFullYear() - 3,
  currentDate.getFullYear() - 4,
  currentDate.getFullYear() - 5,
];

const MapBoxMap: FC<IMapBoxMap> = ({
  isVolumesMap,
  isListView,
  sidebarCollapse,
  showApiLoader,
  hideApiLoader,
}: IMapBoxMap) => {
  const { state, dispatch, fetchAirportVolumes } = useMapContext();
  const { state: authState } = useAuth();
  const { dispatch: dataDispatch } = useDataContext();
  const navigate = useNavigate();
  const mainMapContainer = useRef<null | HTMLDivElement>(null);
  const hawaiMapContainer = useRef<null | HTMLDivElement>(null);
  const alaskaMapContainer = useRef<null | HTMLDivElement>(null);
  const mainMap = useRef<null | Map>(null);
  const hawaiMap = useRef<null | Map>(null);
  const alaskaMap = useRef<null | Map>(null);
  const [markersHidden, setMarkersHidden] = useState<boolean>(isVolumesMap);
  const [markersLoaded, setMarkersLoaded] = useState<boolean>(false);
  const [mainMapStylesLoaded, setMainMapStylesLoaded] =
    useState<boolean>(false);
  // const [hawaiMapStylesLoaded, setHawaiMapStylesLoaded] =
  //   useState<boolean>(false);
  // const [alaskaMapStylesLoaded, setAlaskaMapStylesLoaded] =
  //   useState<boolean>(false);
  const [isLegendExpanded, setIsLegendExpanded] = useState<boolean>(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false);

  const [mapFilter, setMapFilter] = useState<IMapFilter>(mapFilterInitialState);
  const [filteredAirports, setFilteredAirports] = useState<IAirport[]>([]);
  const [volumeYearOptions, setVolumeYearOptions] = useState<Array<any>>([]);
  const [isAddVolumeModalOpened, setIsAddVolumeModalOpened] =
    useState<boolean>(false);

  useEffect(() => {
    if (mainMap.current) {
      setTimeout(() => {
        mainMap.current!.resize();
      }, 250);
    }
  }, [sidebarCollapse]);

  useEffect(() => {
    if (mainMapStylesLoaded) {
      setMapFilter((prevFilter) => ({
        ...prevFilter,
        showHeatMap: isVolumesMap,
      }));
      if (state.currentFocusedAirport) {
        setMapFilter((prevFilter) => ({
          ...prevFilter,
          airportCode: state.currentFocusedAirport!,
        }));
        dispatch({
          type: mapContextAction.SET_CURRENT_FOCUSED_AIRPORT,
          payload: null,
        });
      }
    }
    if (isVolumesMap) {
      setMapFilter((prevFilter) => ({
        ...prevFilter,
        airportChair: "",
        deliveryMethod: "",
      }));
    }

    dispatch({
      type: mapContextAction.SET_ACTIVE_AIRPORT,
      payload: null,
    });
  }, [mainMapStylesLoaded, isVolumesMap, state.currentFocusedAirport]);

  const getBoundsCenter = (bounds: number[]): LngLatLike => {
    const lng = (bounds[0] + bounds[2]) / 2;
    const lat = (bounds[1] + bounds[3]) / 2;

    return [lng, lat];
  };

  function moveMainMap(dx: number, dy: number) {
    const currentCenter = mainMap.current!.getCenter();
    const newCenter: LngLatLike = [
      currentCenter.lng + dx,
      currentCenter.lat + dy,
    ];
    mainMap.current!.setCenter(newCenter);
  }

  // Function to display detail drawer for selected airport
  const showDetailsDrawer = async (airport: IAirport): Promise<void> => {
    const airportDetails = await MapService.getAirport(airport.id);
    // setting active airport
    dispatch({
      type: mapContextAction.SET_ACTIVE_AIRPORT,
      payload: airportDetails,
    });
    popups.forEach((popup) => popup.remove());
  };

  // Function to load map
  const loadMap = (
    mapContainer: React.MutableRefObject<HTMLDivElement | null>,
    map: React.MutableRefObject<mapboxgl.Map | null>,
    mapName: (typeof mapType)[keyof typeof mapType],
    bounds: LngLatBoundsLike
  ) => {
    // if map is already loaded
    if (map.current) return;
    // initializing map
    map.current = new mapboxgl.Map({
      container: mapContainer.current ?? "",
      style: "mapbox://styles/mapbox/light-v10",
      bounds: bounds,
      maxBounds: bounds,
      maxZoom: 18,
      dragRotate: false,
      touchZoomRotate: false,
      keyboard: true,
    });
    map.current.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      "bottom-right"
    );
    // listener for map style load
    map.current.on("style.load", function () {
      if (mapName === mapType.MAIN) {
        setMainMapStylesLoaded(true);
        mainMap.current!.getCanvas().addEventListener(
          "keydown",
          (e) => {
            e.preventDefault();
            if (e.which === 38) {
              // up
              moveMainMap(0, 0.2);
            } else if (e.which === 40) {
              // down
              moveMainMap(0, -0.2);
            } else if (e.which === 37) {
              // left
              moveMainMap(-0.2, 0);
            } else if (e.which === 39) {
              // right
              moveMainMap(0.2, 0);
            }
          },
          true
        );
        mainMap.current!.zoomOut();
      }
      // if (mapName === mapType.HAWAII) {
      //   setHawaiMapStylesLoaded(true);
      //   hawaiMap.current!.zoomOut();
      // }
      // if (mapName === mapType.ALASKA) {
      //   setAlaskaMapStylesLoaded(true);
      //   alaskaMap.current!.zoomOut();
      // }
    });
  };

  // method to add markers on screen
  const loadMarkers = (
    map: React.MutableRefObject<mapboxgl.Map | null>,
    name: (typeof mapType)[keyof typeof mapType],
    airports: IAirport[]
  ) => {
    airports.forEach((airport) => {
      if (!airport.consortiumGroup?.items?.length) return;
      const layerName = `airport-marker-${name}-${airport.id}`;
      // if marker layer doesn't exist on map
      if (!map.current!.getLayer(layerName)) {
        markerLayers.push(layerName);
        // adding point on map
        map.current!.addSource(layerName, {
          type: "geojson",
          data: {
            type: "Point",
            coordinates: [
              parseFloat(airport.place?.lon ?? "0.0"),
              parseFloat(airport.place?.lat ?? "0.0"),
            ],
          },
        });
        // setting color for circle based on consortium type
        let circleColor: string = organizationGroupTypeColorMapping.NULL;
        if (airport.consortiumGroup?.items?.length) {
          circleColor =
            organizationGroupTypeColorMapping[
              airport.consortiumGroup?.items?.[
                airport.consortiumGroup?.items?.length - 1
              ]?.organization
                ?.groupType as keyof typeof organizationGroupTypeColorMapping
            ];
        }
        // adding circle on point
        map.current!.addLayer({
          id: layerName,
          type: "circle",
          source: layerName,
          layout: {
            visibility: isVolumesMap ? "none" : "visible",
          },
          paint: {
            "circle-radius": [
              "interpolate",
              ["linear"],
              ["zoom"],
              0,
              7.5,
              18,
              20,
            ],
            "circle-color": circleColor,
            // "circle-opacity": 0.8,
          },
        });
        // initializing airport marker/circle popup which appears when clicked on marker/circle
        const popup: Popup = new mapboxgl.Popup({
          closeButton: false,
        });
        popup.addClassName("marker-popup");

        // Function to run when popup is opened
        popup.on("open", (e) => {
          popups.forEach((p) => popup !== p && p.remove());
        });
        popups.push(popup);

        // Function to run when a marker/circle is clicked
        map.current!.on("click", layerName, () => {
          const markerLngLat = {
            lng: parseFloat(airport.place?.lon ?? "0.0"),
            lat: parseFloat(airport.place?.lat ?? "0.0"),
          };
          const popupWidth = 240;

          const markerPoint = map.current!.project(markerLngLat);

          const popupPoint = {
            x: markerPoint.x,
            y: markerPoint.y,
          };

          const popupLngLat = map.current!.unproject(popupPoint as Point);

          const bounds = map.current!.getBounds();
          const maxLng =
            bounds.getEast() -
            mapboxgl.MercatorCoordinate.fromLngLat(
              [popupWidth, 0],
              map.current!.getZoom()
            ).x;
          popupLngLat.lng = Math.max(
            bounds.getWest(),
            Math.min(popupLngLat.lng, maxLng)
          );
          popupLngLat.lat = Math.max(
            bounds.getSouth(),
            Math.min(popupLngLat.lat, bounds.getNorth())
          );

          map.current!.getCanvas().style.cursor = "pointer";
          popup
            .setHTML(
              ReactDOMServer.renderToString(
                <AirportPopup
                  airport={airport}
                  userRoles={state.userOrganizationRoles}
                  isUserA4AAdmin={state.isUserA4AAdmin}
                />
              )
            )
            .setLngLat([popupLngLat.lng, popupLngLat.lat])
            .addTo(map.current!)
            .getElement()
            .addEventListener("click", (e: any) => {
              if (e.target?.classList?.contains("popup-viewmore-btn")) {
                showDetailsDrawer(airport);
              }
              if (e.target?.classList?.contains("popup-gm-contact")) {
                const consortriumIndex = parseInt(
                  Array.from(e.target.classList as string[])
                    .find((className: string) =>
                      className.includes("popup-gm-contact-")
                    )
                    ?.split("popup-gm-contact-")[1] ?? "0"
                );
                const gmEmail =
                  airport.consortiumGroup?.items?.[consortriumIndex]
                    ?.operatorGeneralManager?.email;
                if (gmEmail) {
                  navigate("/company");
                  dataDispatch({
                    type: dataContextAction.SET_TOP_BAR_SEARCH_TEXT,
                    payload: gmEmail,
                  });
                }
              }
              if (e.target?.classList?.contains("popup-chair-contact")) {
                const consortriumIndex = parseInt(
                  Array.from(e.target.classList as string[])
                    .find((className: string) =>
                      className.includes("popup-chair-contact-")
                    )
                    ?.split("popup-chair-contact-")[1] ?? "0"
                );
                const gmEmail =
                  airport.consortiumGroup?.items?.[consortriumIndex]
                    ?.chairContact?.email;
                if (gmEmail) {
                  navigate("/company");
                  dataDispatch({
                    type: dataContextAction.SET_TOP_BAR_SEARCH_TEXT,
                    payload: gmEmail,
                  });
                }
              }
            });
        });
      }
    });
  };

  // method to filter markers visible on map
  const filterMarkers = (
    map: React.MutableRefObject<mapboxgl.Map | null>,
    name: (typeof mapType)[keyof typeof mapType],
    airports: IAirport[]
  ) => {
    airports.forEach((airport) => {
      const layerName = `airport-marker-${name}-${airport.id}`;
      if (map.current!.getLayer(layerName)) {
        map.current!.setLayoutProperty(layerName, "visibility", "visible");
      }
    });
  };

  const hideMarkers = (): void => {
    markerLayers.forEach((markerLayer) => {
      if (mainMap.current!.getLayer(markerLayer)) {
        mainMap.current!.setLayoutProperty(markerLayer, "visibility", "none");
      }
      // if (hawaiMap.current!.getLayer(markerLayer)) {
      //   hawaiMap.current!.setLayoutProperty(markerLayer, "visibility", "none");
      // }
      // if (alaskaMap.current!.getLayer(markerLayer)) {
      //   alaskaMap.current!.setLayoutProperty(markerLayer, "visibility", "none");
      // }
    });
  };

  const loadHeatLayer = (
    hideOnly = false,
    map: React.MutableRefObject<mapboxgl.Map | null>,
    mapName: (typeof mapType)[keyof typeof mapType],
    currentVolumes: IAirportVolume[] = [],
    last5YearsRecord?: any
  ) => {
    const earthquakesLayerName = `earthquakes-${mapName}`;
    const earthquakePointsLayerName = `earthquakes-point-${mapName}`;
    const earthquakeHeatLayerName = `earthquakes-heat-${mapName}`;
    if (map.current!.getSource(earthquakesLayerName)) {
      map.current!.removeLayer(earthquakePointsLayerName);
      map.current!.removeLayer(earthquakeHeatLayerName);
      map.current!.removeSource(earthquakesLayerName);
    }
    if (hideOnly) return;
    let minVol: number = Number.MIN_SAFE_INTEGER;
    let maxVol: number = Number.MAX_SAFE_INTEGER;
    if (mapFilter.volumeCategory) {
      const [minVolRaw, maxVolRaw] = mapFilter.volumeCategory.split("-");
      minVol = minVolRaw ? parseInt(minVolRaw, 10) : Number.MIN_SAFE_INTEGER;
      maxVol = maxVolRaw ? parseInt(maxVolRaw, 10) : Number.MAX_SAFE_INTEGER;
    }
    const features: any = [];
    currentVolumes.forEach((currentVolume) => {
      // const currentAirport = state.aiportList.find((airport) => airport.id === key)
      if (
        currentVolume.year === mapFilter.volumeYear &&
        (currentVolume?.volume ?? 0) > minVol &&
        (currentVolume?.volume ?? 0) <= maxVol &&
        currentVolume.airport?.place
        // ,currentAirport?.place
      ) {
        const coordinates = [
          currentVolume.airport?.place?.lon,
          currentVolume.airport?.place?.lat,
        ];
        features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates,
          },
          properties: {
            volumes: last5YearsRecord[currentVolume.airport.id],
            volume: currentVolume.volume,
            coordinates,
            name: currentVolume?.airport.name,
            state: currentVolume?.airport.state,
            code: currentVolume?.airport.code,
          },
        });
      }
    });

    // const features = currentVolumes.reduce((addedFeatures: any, currentVolume: any) => {
    //   if (
    //     currentVolume.year === mapFilter.volumeYear &&
    //     (currentVolume?.volume ?? 0) > minVol &&
    //     (currentVolume?.volume ?? 0) <= maxVol &&
    //     currentVolume.airport?.place
    //   ) {
    //     const coordinates = [
    //       currentVolume.airport?.place?.lon,
    //       currentVolume.airport?.place?.lat,
    //     ];
    //     addedFeatures.push({
    //       type: "Feature",
    //       geometry: {
    //         type: "Point",
    //         coordinates,
    //       },
    //       properties: {
    //         volume: currentVolume.volume,
    //         coordinates,
    //         name: currentVolume.airport.name,
    //         state: currentVolume.airport.state,
    //         code: currentVolume.airport.code,
    //       },
    //     });
    //   }
    //   return addedFeatures;
    // }, [] as any[]);

    const geoJSON = {
      type: "FeatureCollection",
      features: features,
    };

    map.current!.addSource(earthquakesLayerName, {
      type: "geojson",
      data: geoJSON as any,
    });

    map.current!.addLayer(
      {
        id: earthquakeHeatLayerName,
        type: "heatmap",
        source: earthquakesLayerName,
        maxzoom: 9,
        paint: {
          "heatmap-weight": [
            "interpolate",
            ["linear"],
            ["get", "volume"],
            heatMapRange.Range0,
            0.15,
            heatMapRange.Range6,
            1.25,
          ],
          "heatmap-intensity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            1,
            9,
            3,
          ],
          "heatmap-color": [
            "interpolate",
            ["linear"],
            ["heatmap-density"],
            0,
            "rgba(33,102,172,0)",
            0.33,
            heatMapRangeColor.Range2,
            0.66,
            heatMapRangeColor.Range4,
            1,
            heatMapRangeColor.Range6,
          ],
          "heatmap-radius": ["interpolate", ["linear"], ["zoom"], 0, 10, 1, 60],
          "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 0, 1, 1, 0],
        },
      },
      "waterway-label"
    );

    map.current!.addLayer(
      {
        id: earthquakePointsLayerName,
        type: "circle",
        source: earthquakesLayerName,
        minzoom: 1,
        paint: {
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            1,
            [
              "interpolate",
              ["linear"],
              ["get", "volume"],
              heatMapRange.Range0,
              3,
              heatMapRange.Range6,
              3,
            ],
            16,
            [
              "interpolate",
              ["linear"],
              ["get", "volume"],
              heatMapRange.Range0,
              25,
              heatMapRange.Range6,
              25,
            ],
          ],
          "circle-color": [
            "step",
            ["get", "volume"],
            heatMapRangeColor.Range0,
            heatMapRange.Range2,
            heatMapRangeColor.Range2,
            heatMapRange.Range4,
            heatMapRangeColor.Range4,
            heatMapRange.Range6,
            heatMapRangeColor.Range6,
          ],
          "circle-stroke-color": "white",
          "circle-stroke-width": 1,
          "circle-opacity": ["interpolate", ["linear"], ["zoom"], 0, 0, 1, 1],
        },
      },
      "waterway-label"
    );
    map.current!.on("mouseenter", earthquakePointsLayerName, (e) => {
      // Change the cursor style to a pointer
      map.current!.getCanvas().style.cursor = "pointer";

      const coordinates = e
        .features![0].properties!.coordinates.replace("[", "")
        .replace("]", "")
        .replaceAll('"', "")
        .replaceAll("'", "")
        .split(",");
      const volumes = e.features![0].properties!.volumes;
      const volume = e.features![0].properties!.volume;
      const code = e.features![0].properties!.code;
      const name = e.features![0].properties!.name;
      const state = e.features![0].properties!.state;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const popup: Popup = new mapboxgl.Popup({
        // offset: 25,
        // anchor: "bottom",
        closeButton: false,
      });
      popup.addClassName("marker-popup");
      popup
        .setLngLat(coordinates)
        .setHTML(
          ReactDOMServer.renderToString(
            <AirportVolumePopup
              selectedYear={mapFilter.volumeYear ?? new Date().getFullYear()}
              volume={volume}
              last5YearRecord={JSON.parse(volumes)}
              name={name}
              state={state}
              code={code}
            />
          )
        )
        .addTo(map.current!);
      volumePopups.push(popup);
    });

    map.current!.on("mouseleave", earthquakePointsLayerName, () => {
      map.current!.getCanvas().style.cursor = "";
      volumePopups.forEach((p) => p?.remove());
    });
  };

  useEffect(() => {
    if (mainMap.current && hawaiMap.current && alaskaMap.current) {
      return;
    }
    showApiLoader();
    loadMap(mainMapContainer, mainMap, mapType.MAIN, mapBounds.MAIN);
    // loadMap(alaskaMapContainer, alaskaMap, mapType.ALASKA, mapBounds.ALASKA);
    // loadMap(hawaiMapContainer, hawaiMap, mapType.HAWAII, mapBounds.HAWAII);
    hideApiLoader();
    setMarkersLoaded(false);
    markerLayers = [];
  }, []);

  useEffect(() => {
    if (state.aiportList && !filteredAirports.length) {
      setFilteredAirports(state.aiportList);
    }
  }, [state.aiportList]);

  useEffect(() => {
    if (
      filteredAirports.length &&
      mainMapStylesLoaded &&
      // hawaiMapStylesLoaded &&
      // alaskaMapStylesLoaded &&
      !markerLayers.length &&
      !markersLoaded
    ) {
      loadMarkers(mainMap, mapType.MAIN, filteredAirports);
      // loadMarkers(
      //   alaskaMap,
      //   mapType.ALASKA,
      //   filteredAirports.filter(
      //     (airport) => airport.state === usStateCode.ALASKA
      //   )
      // );
      // loadMarkers(
      //   hawaiMap,
      //   mapType.HAWAII,
      //   filteredAirports.filter(
      //     (airport) => airport.state === usStateCode.HAWAII
      //   )
      // );
      setMarkersLoaded(true);
    } else if (markerLayers.length && !markersHidden) {
      hideMarkers();
      filterMarkers(mainMap, mapType.MAIN, filteredAirports);
      // filterMarkers(
      //   alaskaMap,
      //   mapType.ALASKA,
      //   filteredAirports.filter(
      //     (airport) => airport.state === usStateCode.ALASKA
      //   )
      // );
      // filterMarkers(
      //   hawaiMap,
      //   mapType.HAWAII,
      //   filteredAirports.filter(
      //     (airport) => airport.state === usStateCode.HAWAII
      //   )
      // );
    }
  }, [
    filteredAirports,
    markersHidden,
    markersLoaded,
    mainMapStylesLoaded,
    // hawaiMapStylesLoaded,
    // alaskaMapStylesLoaded,
  ]);

  const applyFilter = () => {
    let currentAirports: IAirport[] = state.aiportList;
    let currentAirportIds: string[] = [];
    let currentVolumes: IAirportVolume[] = state.airportVolumeList;
    if (
      mapFilter.state &&
      mapFilter.state !== ALL_US_STATES &&
      mapFilter.state !== CONTINENTAL
    ) {
      const stateBounds = usStatesBoundingBoxes[
        mapFilter.state as keyof typeof usStatesBoundingBoxes
      ] as mapboxgl.LngLatBoundsLike;
      currentAirports = state.aiportList.filter(
        (airport) => mapFilter.state === airport.state
      );
      if (
        mapFilter.state === usStateCode.ALASKA ||
        mapFilter.state === usStateCode.HAWAII
      ) {
        const stateMaxBounds =
          mapFilter.state === usStateCode.ALASKA
            ? mapBounds.ALASKA
            : mapBounds.HAWAII;
        mainMap.current!.setMaxBounds(stateMaxBounds);
        const center = getBoundsCenter(stateMaxBounds as number[]);
        mainMap.current!.easeTo({
          center,
          zoom: 1,
          duration: 2000,
        });
      } else {
        mainMap.current!.setMaxBounds(mapBounds.MAIN);
        mainMap.current!.fitBounds(stateBounds, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 },
          maxZoom: 14,
          duration: 2000,
        });
      }
    } else if (mapFilter.state === CONTINENTAL) {
      mainMap.current!.setMaxBounds(mapBounds.MAIN);
      const bounds = mapBounds.MAIN as number[];

      const lng = (bounds[0] + bounds[2]) / 2;

      mainMap.current!.easeTo({
        center: [lng, bounds[3]],
        zoom: 1,
        duration: 2000,
      });
    } else if (mapFilter.state === ALL_US_STATES) {
      mainMap.current!.setMaxBounds(mapBounds.WHOLE);
      const center = getBoundsCenter(mapBounds.WHOLE as number[]);

      mainMap.current!.easeTo({
        center,
        zoom: 1,
        duration: 2000,
      });
    }
    if (mapFilter.airportOrg) {
      currentAirportIds = [];
      currentAirports = currentAirports.filter((airport) =>
        airport?.consortiumGroup?.items?.find(
          (consortiumGroup) =>
            consortiumGroup.organization.groupType === mapFilter.airportOrg
        )
      );
    }
    if (mapFilter.airportRegion) {
      if (
        (mapFilter.airportRegion === AirportRegionChoice.PADD_5_ALASKA ||
          mapFilter.airportRegion === AirportRegionChoice.PADD_5_HAWAII) &&
        mapFilter.state !== ALL_US_STATES
      ) {
        // mainMap.current!.setMaxBounds(mapBounds.WHOLE);
        // const center = getBoundsCenter(mapBounds.WHOLE as number[]);
        // mainMap.current!.easeTo({
        //   center,
        //   zoom: 1,
        //   duration: 2000,
        // });
        setMapFilter((prevFilter) => ({ ...prevFilter, state: ALL_US_STATES }));
      }
      currentAirportIds = [];
      currentAirports = currentAirports.filter(
        (airport) => airport.region === mapFilter.airportRegion
      );
    }
    if (mapFilter.airportOperator) {
      currentAirportIds = [];
      currentAirports = currentAirports.filter((airport) =>
        airport.consortiumGroup?.items?.find(
          (consortium) => consortium.operatorId === mapFilter.airportOperator
        )
      );
      if (
        currentAirports.find(
          (airport) =>
            airport.state === usStateCode.ALASKA ||
            airport.state === usStateCode.HAWAII
        ) &&
        mapFilter.state !== ALL_US_STATES
      ) {
        setMapFilter((prevMapFilter) => ({
          ...prevMapFilter,
          state: ALL_US_STATES,
        }));
      }
    }
    if (mapFilter.airportChair) {
      currentAirportIds = [];
      currentAirports = currentAirports.filter((airport) =>
        airport.consortiumGroup?.items?.find(
          (consortium) => consortium.chairId === mapFilter.airportChair
        )
      );
      if (
        currentAirports.find(
          (airport) =>
            airport.state === usStateCode.ALASKA ||
            airport.state === usStateCode.HAWAII
        ) &&
        mapFilter.state !== ALL_US_STATES
      ) {
        setMapFilter((prevMapFilter) => ({
          ...prevMapFilter,
          state: ALL_US_STATES,
        }));
      }
    }
    if (mapFilter.deliveryMethod) {
      currentAirportIds = [];
      currentAirports = currentAirports.filter((airport) =>
        airport.deliveryToFacility?.items?.find(
          (deliveryToFacility) =>
            deliveryToFacility.method === mapFilter.deliveryMethod
        )
      );
    }
    if (mapFilter.airportCode) {
      currentAirportIds = [];
      currentAirports = currentAirports.filter(
        (airport) => airport.code === mapFilter.airportCode
      );
      if (
        currentAirports[0] &&
        currentAirports[0].state !== usStateCode.ALASKA &&
        currentAirports[0].state !== usStateCode.HAWAII
      ) {
        mainMap.current!.setMaxBounds(mapBounds.MAIN);
        mainMap.current!.flyTo({
          center: [
            parseFloat(currentAirports[0].place?.lon ?? "0.0"),
            parseFloat(currentAirports[0].place?.lat ?? "0.0"),
          ],
          essential: true,
          zoom: 8,
        });
      } else if (
        currentAirports[0] &&
        (currentAirports[0].state === usStateCode.ALASKA ||
          currentAirports[0].state === usStateCode.HAWAII)
      ) {
        const stateMaxBounds =
          currentAirports[0].state === usStateCode.ALASKA
            ? mapBounds.ALASKA
            : mapBounds.HAWAII;
        mainMap.current!.setMaxBounds(stateMaxBounds);
        mainMap.current!.flyTo({
          center: [
            parseFloat(currentAirports[0].place?.lon ?? "0.0"),
            parseFloat(currentAirports[0].place?.lat ?? "0.0"),
          ],
          essential: true,
          zoom: 8,
        });
      }
    }
    currentAirportIds = currentAirports.map((airport) => airport.id);

    currentAirports.forEach((airport) => {
      const layerName = `airport-marker-main-${airport.id}`;
      if (mainMap.current!.getLayer(layerName)) {
        let circleColor: string = organizationGroupTypeColorMapping.NULL;

        if (mapFilter.airportOrg) {
          circleColor =
            organizationGroupTypeColorMapping[
              mapFilter.airportOrg as keyof typeof organizationGroupTypeColorMapping
            ];
        } else {
          circleColor =
            organizationGroupTypeColorMapping[
              airport.consortiumGroup?.items?.[
                airport.consortiumGroup?.items?.length - 1
              ]?.organization
                ?.groupType as keyof typeof organizationGroupTypeColorMapping
            ];
        }
        mainMap.current!.setPaintProperty(
          layerName,
          "circle-color",
          circleColor
        );
      }
    });

    if (
      mainMap.current &&
      // hawaiMap.current &&
      // alaskaMap.current &&
      mapFilter.showHeatMap &&
      isVolumesMap
    ) {
      currentVolumes = currentVolumes.filter((volume) =>
        currentAirportIds.includes(volume.airportId ?? "")
      );
      setMarkersHidden(true);
      setIsLegendExpanded(false);
      hideMarkers();

      const last5YearsRecord: any = {};
      // const last5YearsRecord: any = defaultLast5YearsRecord;
      currentVolumes.forEach((volume) => {
        if (
          volume.airportId &&
          currentAirportIds.includes(volume.airportId) &&
          last5Years.includes(volume.year!)
        ) {
          if (!last5YearsRecord[volume.airportId]) {
            last5YearsRecord[volume.airportId] = {};
          }
          last5YearsRecord[volume.airportId][
            parseInt(volume.year!.toString())
          ] = volume;
        }
      });
      loadHeatLayer(
        false,
        mainMap,
        mapType.MAIN,
        currentVolumes,
        last5YearsRecord
      );
      // loadHeatLayer(false, hawaiMap, mapType.HAWAII, currentVolumes);
      // loadHeatLayer(false, alaskaMap, mapType.ALASKA, currentVolumes);
    } else if (!mapFilter.showHeatMap && !isVolumesMap) {
      setMarkersHidden(false);
      setIsLegendExpanded(false);
      loadHeatLayer(true, mainMap, mapType.MAIN);
      // loadHeatLayer(true, hawaiMap, mapType.HAWAII);
      // loadHeatLayer(true, alaskaMap, mapType.ALASKA);
    }
    setFilteredAirports(currentAirports);
  };

  useEffect(() => {
    setMapFilter((prevMapFilter) => ({
      ...prevMapFilter,
      volumeYear: undefined,
    }));
    setVolumeYearOptions(
      [
        ...state.airportVolumeList.reduce((currentYears: any[], volume) => {
          if (
            volume.year &&
            !currentYears.find((year: any) => year.value === volume.year)
          ) {
            currentYears.push({
              value: volume.year!,
              name: volume.year!,
            });
          }
          return currentYears;
        }, []),
      ].sort((a, b) => b.value - a.value)
    );
  }, [state.airportVolumeList]);

  useEffect(() => {
    if (!mapFilter.volumeYear && volumeYearOptions.length) {
      setMapFilter((prevMapFilter) => ({
        ...prevMapFilter,
        volumeYear: parseInt(volumeYearOptions[0].value),
      }));
    }
    applyFilter();
  }, [mapFilter, isVolumesMap, volumeYearOptions, state.aiportList]);

  useEffect(() => {
    if (!state.airportVolumeList.length) {
      fetchAirportVolumes([], null);
    }
  }, [mapFilter.showHeatMap]);

  useEffect(() => {
    if (!mapFilter.airportCode) {
      mainMap.current!.easeTo({
        zoom: 4,
        duration: 2000,
      });
    }
  }, [mapFilter.airportCode]);

  const stateFilterHandler = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    const state: string = value;
    setMapFilter((prevMapFilter) => ({
      ...prevMapFilter,
      state,
    }));
    // }
  };

  const airportCodeFilterHandler = (event: SelectChangeEvent<string>) => {
    setMapFilter((prevMapFilter) => ({
      ...prevMapFilter,
      airportCode: event.target.value,
    }));
  };

  const airportOrgFilterHandler = (event: SelectChangeEvent<string>) => {
    setMapFilter((prevMapFilter) => ({
      ...prevMapFilter,
      airportOrg: event.target.value,
    }));
  };

  const airportRegionFilterHandler = (event: SelectChangeEvent<string>) => {
    setMapFilter((prevMapFilter) => ({
      ...prevMapFilter,
      airportRegion: event.target.value,
    }));
  };

  const airportChairFilterHandler = (event: SelectChangeEvent<string>) => {
    setMapFilter((prevMapFilter) => ({
      ...prevMapFilter,
      airportChair: event.target.value,
    }));
  };

  const airportDeliveryMethodFilterHandler = (
    event: SelectChangeEvent<string>
  ) => {
    setMapFilter((prevMapFilter) => ({
      ...prevMapFilter,
      deliveryMethod: event.target.value,
    }));
  };

  const airportOperatorFilterHandler = (event: SelectChangeEvent<string>) => {
    setMapFilter((prevMapFilter) => ({
      ...prevMapFilter,
      airportOperator: event.target.value,
    }));
  };

  const volumeCategoryFilterHandler = (event: SelectChangeEvent<string>) => {
    setMapFilter((prevMapFilter) => ({
      ...prevMapFilter,
      volumeCategory: event.target.value,
    }));
  };

  const allAirportCodeOptions = state.aiportList
    .map((airport) => ({
      name: airport.code,
      value: airport.code,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const filteredAirportCodeOptions = state.aiportList
    .reduce((currAirports: any[], airport) => {
      if (airport.consortiumType) {
        currAirports.push({
          name: airport.code,
          value: airport.code,
        });
      }
      return currAirports;
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));

  const exportVolumesData = () => {
    if (!state.airportVolumeList.length) return;
    const volumeYears = state.airportVolumeList.reduce(
      (volumes: any, currentVolume) => {
        if (
          currentVolume.airport?.code &&
          currentVolume.year &&
          currentVolume.volume
        ) {
          if (!volumes[currentVolume.year]) {
            volumes[currentVolume.year] = [];
          }
          volumes[currentVolume.year].push({
            Code: currentVolume.airport?.code ?? "",
            Year: currentVolume.year ?? "",
            Volume: currentVolume.volume ?? "",
          });
        }
        return volumes;
      },
      {}
    );
    let data: any[] = [];
    for (const key of Object.keys(volumeYears)) {
      const volumeArr = volumeYears[key];
      volumeArr.sort((a: any, b: any) => a.Code.localeCompare(b.Code));
      console.log(volumeArr);
      data = [...data, ...volumeArr];
    }
    data.sort((a, b) => b.Year - a.Year);
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Volumes");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `Volumes.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = [
    {
      name: "Code",
      grow: 0.1,
      selector: (row: any) => row?.code,
      sortable: true,
      cell: (row: any) => (
        <Link
          to={"/main"}
          onClick={() => {
            dispatch({
              type: mapContextAction.SET_CURRENT_FOCUSED_AIRPORT,
              // payload: row.code,
              payload: row.code,
            });
          }}
        >
          {row.code}
        </Link>
      ),
    },
    {
      name: "Name",
      grow: 1.5,
      selector: (row: any) => row?.name,
      sortable: true,
      // cell: (row: any) => (
      //   <Link to={`/org/${row?.orgId}`} title={row.name}>
      //     {row.name}
      //   </Link>
      // ),
    },
    {
      name: "Chair Company",
      selector: (row: any) => row?.chair,
      sortable: true,
    },
    {
      name: "Chair Contact",
      selector: (row: any) => row?.chair_email,
      sortable: true,
    },
    {
      name: "GM Company",
      selector: (row: any) => row?.gm,
      sortable: true,
    },
    {
      name: "GM Contact",
      selector: (row: any) => row?.gm_email,
      sortable: true,
    },
  ];

  const tableData: Array<any> = filteredAirports
    ?.filter((airport) => airport.consortiumGroup?.items?.length)
    ?.map((airport) => ({
      id: airport.id,
      code: airport.code,
      name: airport?.name ?? "",
      chair: airport.consortiumGroup?.items
        ?.map((consortiumGroup) => consortiumGroup?.chair?.name ?? "")
        .join(", "),
      // chair_email: item?.chairContact?.email,
      chair_email: airport.consortiumGroup?.items
        ?.map(
          (consortiumGroup) =>
            `${consortiumGroup?.chairContact?.firstName ?? ""} ${
              consortiumGroup?.chairContact?.lastName ?? ""
            }`
        )
        .join(", "),
      // gm: item?.operator?.name,
      gm: airport.consortiumGroup?.items
        ?.map((consortiumGroup) => consortiumGroup?.operator?.name ?? "")
        .join(", "),
      // gm_email: item?.operatorContact?.email,
      // gm_email: item?.operatorGeneralManager?.email,
      gm_email: airport.consortiumGroup?.items
        ?.map(
          (consortiumGroup) =>
            `${consortiumGroup?.operatorGeneralManager?.firstName ?? ""} ${
              consortiumGroup?.operatorGeneralManager?.lastName ?? ""
            }`
        )
        .join(", "),
    }));

  return (
    <AllMapsWrapper>
      <SmallMapsWrapper>
        <SmallMapWrapper display="none">
          <SmallMapContainer
            className="alaska-map"
            ref={alaskaMapContainer}
          ></SmallMapContainer>
        </SmallMapWrapper>
        <SmallMapWrapper display="none">
          <SmallMapContainer
            className="hawaii-map"
            ref={hawaiMapContainer}
            style={{
              top: "calc(100vh - 50vh)",
            }}
          ></SmallMapContainer>
        </SmallMapWrapper>
      </SmallMapsWrapper>
      <MainMapWrapper>
        {!isListView && (
          <>
            {!isFilterExpanded && (
              <MapFilterIconContainer onClick={() => setIsFilterExpanded(true)}>
                <MapFilterIcon />
              </MapFilterIconContainer>
            )}
            <MapFilterFade in={isFilterExpanded} timeout={500}>
              <MapFilterContainer>
                <>
                  {isVolumesMap && (
                    <>
                      <SelectCheckboxDropdown
                        label="Volume Year"
                        options={volumeYearOptions}
                        selectedValue={
                          mapFilter.volumeYear ?? new Date().getFullYear()
                        }
                        selectHandler={(event) =>
                          setMapFilter((prevMapFilter) => ({
                            ...prevMapFilter,
                            volumeYear: parseInt(event?.target.value),
                          }))
                        }
                        sx={{
                          width: 135,
                          "& .MuiFormLabel-root": {
                            fontSize: "13px",
                          },
                          "& .MuiListItemText-primary": {
                            fontSize: "13px",
                            overflow: "hidden",
                          },
                        }}
                      />
                      <SelectCheckboxDropdown
                        label="Volume Category"
                        options={heapMapRangeOptions.map(
                          ({ name, value, color }) => ({
                            name,
                            value,
                            prefix: (
                              <VolumeCategoryIcon
                                sx={{ backgroundColor: color }}
                              />
                            ),
                          })
                        )}
                        hasPrefixes={true}
                        selectedValue={mapFilter.volumeCategory}
                        selectHandler={volumeCategoryFilterHandler}
                        sx={{
                          width: 135,
                          "& .MuiFormLabel-root": {
                            fontSize: "13px",
                          },
                          "& .MuiListItemText-primary": {
                            fontSize: "13px",
                            overflow: "hidden",
                          },
                          "& .MuiInputBase-input": {
                            fontSize: "13px",
                            overflow: "hidden",
                          },
                        }}
                      />
                    </>
                  )}
                  {!isVolumesMap && (
                    <>
                      <SelectCheckboxDropdown
                        label="Delivery Method"
                        options={[
                          { name: "All", value: "" },
                          ...DeliveryMethodOptions,
                        ]}
                        selectedValue={mapFilter.deliveryMethod}
                        selectHandler={airportDeliveryMethodFilterHandler}
                        sx={{
                          width: 135,
                          "& .MuiFormLabel-root": {
                            fontSize: "13px",
                          },
                          "& .MuiListItemText-primary": {
                            fontSize: "13px",
                            overflow: "hidden",
                          },
                        }}
                      />
                      <SelectCheckboxDropdown
                        label="Filter Chairs"
                        options={[
                          { name: "All", value: "" },
                          ...state.filterChairs.sort((a, b) =>
                            a.name.localeCompare(b.name)
                          ),
                        ]}
                        selectedValue={mapFilter.airportChair}
                        selectHandler={airportChairFilterHandler}
                        sx={{
                          width: 135,
                          "& .MuiFormLabel-root": {
                            fontSize: "13px",
                          },
                          "& .MuiListItemText-primary": {
                            fontSize: "13px",
                            overflow: "hidden",
                          },
                        }}
                      />
                    </>
                  )}
                  <SelectCheckboxDropdown
                    label="Filter Operators"
                    options={[
                      { name: "All", value: "" },
                      ...state.filterOperators.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      ),
                    ]}
                    selectedValue={mapFilter.airportOperator}
                    selectHandler={airportOperatorFilterHandler}
                    sx={{
                      width: 135,
                      "& .MuiFormLabel-root": {
                        fontSize: "13px",
                      },
                      "& .MuiListItemText-primary": {
                        fontSize: "13px",
                        overflow: "hidden",
                      },
                    }}
                  />
                  <SelectCheckboxDropdown
                    label="Filter States"
                    options={usStatesFilterOptions}
                    selectedValue={mapFilter.state}
                    selectHandler={stateFilterHandler}
                    sx={{
                      width: 135,
                      "& .MuiFormLabel-root": {
                        fontSize: "13px",
                      },
                      "& .MuiListItemText-primary": {
                        fontSize: "13px",
                        overflow: "hidden",
                      },
                    }}
                  />
                  <SelectCheckboxDropdown
                    label="Filter Region"
                    options={[
                      { name: "All", value: "" },
                      ...airportRegionOptions,
                    ]}
                    selectedValue={mapFilter.airportRegion}
                    selectHandler={airportRegionFilterHandler}
                    sx={{
                      width: 135,
                      "& .MuiFormLabel-root": {
                        fontSize: "13px",
                      },
                      "& .MuiListItemText-primary": {
                        fontSize: "13px",
                        overflow: "hidden",
                      },
                    }}
                  />
                  <SelectCheckboxDropdown
                    label="Filter Airport"
                    options={[
                      { name: "All", value: "" },
                      ...(isVolumesMap
                        ? allAirportCodeOptions
                        : filteredAirportCodeOptions),
                    ]}
                    selectedValue={mapFilter.airportCode}
                    selectHandler={airportCodeFilterHandler}
                    sx={{
                      width: 135,
                      "& .MuiFormLabel-root": {
                        fontSize: "13px",
                      },
                      "& .MuiListItemText-primary": {
                        fontSize: "13px",
                        overflow: "hidden",
                      },
                    }}
                  />
                  <SelectCheckboxDropdown
                    label="Filter Org Type"
                    options={[
                      { name: "All", value: "" },
                      {
                        name: "Advisory Committee",
                        value: "ADVISORY_COMMITTEE",
                      },
                      { name: "Committee", value: "COMMITTEE" },
                      { name: "Corp Co LLC", value: "CORP_CO_LLC" },
                    ]}
                    selectedValue={mapFilter.airportOrg}
                    selectHandler={airportOrgFilterHandler}
                    sx={{
                      width: 135,
                      "& .MuiFormLabel-root": {
                        fontSize: "13px",
                      },
                      "& .MuiListItemText-primary": {
                        fontSize: "13px",
                        overflow: "hidden",
                      },
                    }}
                  />
                  <Button
                    onClick={() => {
                      setMapFilter((prevState) => ({
                        ...mapFilterInitialState,
                        showHeatMap: prevState.showHeatMap,
                      }));
                    }}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Reset Filters
                  </Button>
                  <MapFilterCloseIcon
                    onClick={() => setIsFilterExpanded(false)}
                  />
                </>
              </MapFilterContainer>
            </MapFilterFade>
          </>
        )}
        <MapContainer
          className="test"
          ref={mainMapContainer}
          style={{
            opacity: isListView ? "0" : "1",
            visibility: isListView ? "hidden" : "visible",
          }}
        />
        {isListView && (
          <MapListContainer>
            <DataTableBase
              columns={columns}
              data={tableData}
              defaultSortFieldId={1}
              dense={false}
              fixedHeader
              pagination={false}
              persistTableHead
              highlightOnHover
            />
          </MapListContainer>
        )}
      </MainMapWrapper>
      {!!state.activeAirport && (
        <AiportDetailsDrawer airport={state.activeAirport} />
      )}
      {isVolumesMap && (
        <VolumeButtonContainer>
          <AddVolumeButton
            onClick={exportVolumesData}
            style={{ right: "210px" }}
          >
            Export Volumes
          </AddVolumeButton>

          {authState.user?.groups?.includes(constants.ROLES.A4A_ADMIN) && (
            <>
              <AddVolumeButton onClick={() => setIsAddVolumeModalOpened(true)}>
                Import Volumes
              </AddVolumeButton>
              <AddVolumeModal
                open={isAddVolumeModalOpened}
                handleClose={() => setIsAddVolumeModalOpened(false)}
              />
            </>
          )}
        </VolumeButtonContainer>
      )}

      {!isVolumesMap && (
        <ListViewSwitchButton
          aria-label="delete"
          onClick={() => {
            if (isListView) {
              navigate("/main");
            } else {
              navigate("/map/list");
            }
          }}
        >
          {isListView ? "Map View" : "List View"}
        </ListViewSwitchButton>
      )}
      {!isListView && (
        <MapLegend
          isVolumesMap={isVolumesMap}
          markersHidden={markersHidden}
          isLegendExpanded={isLegendExpanded}
          setIsLegendExpanded={setIsLegendExpanded}
        />
      )}
    </AllMapsWrapper>
  );
};

export default MapBoxMap;
