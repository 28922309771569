import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface IConfirmDeleteModal {
  open: boolean;
  handleClose: Function;
  handleDelete: Function;
}

const ConfirmDeleteModal = ({
  open,
  handleClose,
  handleDelete,
}: IConfirmDeleteModal) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      PaperProps={{ sx: { p: 1 } }}
    >
      <DialogTitle id="confirm-dialog-title">Confirm delete</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
        This action cannot be reversed. Are you sure you want to delete?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <Button onClick={() => handleDelete()} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
