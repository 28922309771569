import { Grid, Skeleton } from "@mui/material";

const ViewDocument = () => {
  return (
    <>
      <Grid sx={{ my: 2 }} container>
        <Grid md={4} item>
          <Skeleton variant="rounded" sx={{ width: "70%" }} height={100} />
        </Grid>
        <Grid md={4} item>
          <Skeleton variant="rounded" sx={{ width: "70%" }} height={100} />
        </Grid>
        <Grid md={4} item>
          <Skeleton variant="rounded" sx={{ width: "70%" }} height={100} />
        </Grid>
      </Grid>
    </>
  );
};

export default ViewDocument;
