import { CognitoUserPool } from "amazon-cognito-identity-js";

//Aws-cognito credentials
const poolData = {
  UserPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
  ClientId: `${process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID}`,
};

const UserPool = new CognitoUserPool(poolData);

export { UserPool };
