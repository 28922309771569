import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { toast } from "react-toastify";

import { useAuth } from "./../AuthProvider/AuthProvider";
import { useDataContext } from "./../DataProvider/DataProvider";
import { useCalendarContext } from "./../CalendarProvider/CalendarProvider";
import { useMapContext } from "./../MapProvider/MapProvider";
import { useTransactionContext } from "./../TransactionProvider/TransactionProvider";
import { constants, helpers } from "./../../utils";
import { useNavigate } from "react-router-dom";

const ViewExportData = () => {
  const [isA4AAdmin, setA4AAdmin] = React.useState<boolean>(false);
  const [exportConsortiums, setExportConsortiums] =
    React.useState<boolean>(false);
  const [exportContacts, setExportContacts] = React.useState<boolean>(false);
  const [exportCalendarEvents, setExportCalendarEvents] =
    React.useState<boolean>(false);
  const [isPendingInviteLoaded, setPendingInviteLoaded] =
    React.useState<boolean>(false);
  const { state: authProviderState } = useAuth();
  const { state: dataProviderState } = useDataContext();
  const { state: calendarProviderState } = useCalendarContext();
  const { state: mapProviderState } = useMapContext();
  const { state: transactionProviderState } = useTransactionContext();

  const navigateTo = useNavigate();

  // componentDidMount
  React.useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.DATA_EXPORT;

    // is logged in user A4A Admin
    if (
      authProviderState?.user?.groups?.includes(constants?.ROLES?.A4A_ADMIN)
    ) {
      // set A4A Admin flag
      setA4AAdmin(true);
    } else {
      // redirect to main page
      navigateTo("/main");
    }

    // cleanup
    return () => {};
  }, []);

  // calendar list length check
  React.useEffect(() => {
    if (calendarProviderState?.calendarList?.length) {
      setExportCalendarEvents(true);
    } else {
      setExportCalendarEvents(false);
    }

    if (dataProviderState?.organizationList?.length) {
      setExportConsortiums(true);
    } else {
      setExportConsortiums(false);
    }

    if (mapProviderState?.userList?.length) {
      setExportContacts(true);
    } else {
      setExportContacts(false);
    }
  }, [
    calendarProviderState?.calendarList?.length,
    dataProviderState?.organizationList?.length,
    dataProviderState?.allPendingInviteList?.length,
    mapProviderState?.userList?.length,
  ]);

  React.useEffect(() => {
    // if pending invite api call done
    if (!dataProviderState?.isAllPendingInviteListApiLoading) {
      setPendingInviteLoaded(true);
    } else {
      setPendingInviteLoaded(false);
    }
  }, [dataProviderState?.isAllPendingInviteListApiLoading]);

  // export consortium list
  const handleExportConsortiumData = () => {
    // if consortium list array empty
    if (!dataProviderState?.organizationList?.length) {
      // show toast message
      toast.warning("Consortium list empty.", { theme: "colored" });
      return;
    }

    const data: any[] = dataProviderState?.organizationList?.map(
      (consortium: any) => ({
        "Airport Code": consortium?.airport?.code ?? "",
        "Airport Name": consortium?.airport?.name ?? "",
        State: consortium?.airport?.state ?? "",
        Region: consortium?.airport?.region ?? "",
        "Consortium Name": consortium?.name ?? "",
        "Chair Company": consortium?.chair?.name ?? "",
        "Chair Contact":
          consortium?.chairContact?.firstName &&
          consortium?.chairContact?.lastName
            ? `${consortium?.chairContact?.firstName} ${consortium?.chairContact?.lastName}`
            : "",
        "Chair Email": consortium?.chairContact?.email ?? "",
        "Chair Phone": consortium?.chairContact?.mobile ?? "",
        "GM Company": consortium?.operator?.name ?? "",
        "GM Contact":
          consortium?.operatorGeneralManager?.firstName &&
          consortium?.operatorGeneralManager?.lastName
            ? `${consortium?.operatorGeneralManager?.firstName} ${consortium?.operatorGeneralManager?.lastName}`
            : "",
        "GM Email": consortium?.operatorGeneralManager?.email ?? "",
        "GM Phone": consortium?.operatorGeneralManager?.phone ?? "",
      })
    );

    // sort by airport code
    data.sort((itemA: any, itemB: any) => {
      if (itemA["Airport Code"] < itemB["Airport Code"]) {
        return -1;
      }
      if (itemA["Airport Code"] > itemB["Airport Code"]) {
        return 1;
      }
      return 0;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "consortium-list");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `consortium-list-${helpers.getRandomString(4)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExportCalenarEventListData = () => {
    // if calendar event list array empty
    if (!calendarProviderState?.calendarList?.length) {
      // show toast message
      toast.warning("Calendar event list empty.", { theme: "colored" });
      return;
    }

    const data: any[] = calendarProviderState?.calendarList?.map(
      (event: any) => ({
        Name: event?.name ?? "",
        Description:
          event?.description &&
          event?.description !== "nan" &&
          event?.description !== "None"
            ? event?.description
            : "",
        Organizer: event?.organizer ?? "",
        Venue: event?.venue ?? "",
        "Venue Web Site":
          event?.venueWebSite && event?.venueWebSite !== "None"
            ? event?.venueWebSite
            : "",
        "Start At":
          dayjs(event?.startDateTime).format("D MMM YYYY hh:mm A") ?? "",
        "End At": dayjs(event?.endDateTime).format("D MMM YYYY hh:mm A") ?? "",
        "Event Type": event?.calendarEventType ?? "",
      })
    );

    // sort by name
    data.sort((itemA: any, itemB: any) => {
      if (itemA.Name < itemB.Name) {
        return -1;
      }
      if (itemA.Name > itemB.Name) {
        return 1;
      }
      return 0;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "calendar-event-list");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `calendar-event-list-${helpers.getRandomString(4)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export contact list to excel file
  const handleExportContactListData = () => {
    // if empty array then return
    if (!mapProviderState?.userList?.length) {
      // show toast message
      toast.warning("Contact list empty.", { theme: "colored" });
      return;
    }

    let data: any[] = [];

    mapProviderState?.userList?.forEach((contact: any) => {
      const pendingInvites = dataProviderState?.allPendingInviteList?.filter(
        (invite: any) => invite?.userId === contact?.id
      );
      if (
        !contact?.organizationAdmin?.items?.length &&
        !contact?.organizationMember?.items?.length &&
        !contact?.organizationSupportAdmin?.items?.length &&
        !pendingInvites.length
      ) {
        const temp = {
          LastName: contact?.lastName ?? "",
          FirstName: contact?.firstName ?? "",
          Company: contact?.company?.name ?? "",
          Email: contact?.email ?? "",
          Mobile: contact?.mobile ?? "",
          Group: "",
          Role: "",
        };

        // add user to array
        data.push(temp);
      }

      // Pending invite data
      if (pendingInvites.length) {
        pendingInvites?.forEach((invite: any) => {
          const temp = {
            LastName: contact?.lastName ?? "",
            FirstName: contact?.firstName ?? "",
            Company: contact?.company?.name ?? "",
            Email: contact?.email ?? "",
            Mobile: contact?.mobile ?? "",
            Group: invite?.organization?.name,
            Role: invite?.type === "ADMIN" ? "Admin" : "Member",
            Status: "Pending",
          };

          // add user to array
          data.push(temp);
        });
      }

      if (contact?.organizationAdmin?.items?.length) {
        contact?.organizationAdmin?.items?.forEach((role: any) => {
          const temp = {
            LastName: contact?.lastName ?? "",
            FirstName: contact?.firstName ?? "",
            Company: contact?.company?.name ?? "",
            Email: contact?.email ?? "",
            Mobile: contact?.mobile ?? "",
            Group: role?.organization?.name,
            Role: "Admin",
            Status: "Accepted",
          };

          // add user to array
          data.push(temp);
        });
      }

      if (contact?.organizationMember?.items?.length) {
        contact?.organizationMember?.items?.forEach((role: any) => {
          const temp = {
            LastName: contact?.lastName ?? "",
            FirstName: contact?.firstName ?? "",
            Company: contact?.company?.name ?? "",
            Email: contact?.email ?? "",
            Mobile: contact?.mobile ?? "",
            Group: role?.organization?.name,
            Role: "Member",
            Status: "Accepted",
          };

          // add user to array
          data.push(temp);
        });
      }

      if (contact?.organizationSupportAdmin?.items?.length) {
        contact?.organizationSupportAdmin?.items?.forEach((role: any) => {
          const temp = {
            LastName: contact?.lastName ?? "",
            FirstName: contact?.firstName ?? "",
            Company: contact?.company?.name ?? "",
            Email: contact?.email ?? "",
            Mobile: contact?.mobile ?? "",
            Group: role?.organization?.name,
            Role: "Support Admin",
            Status: "Accepted",
          };

          // add user to array
          data.push(temp);
        });
      }
    });

    // consortium chair contacts details
    const chairContacts = dataProviderState?.organizationList?.filter(
      (consortium) => consortium?.chairContact !== null
    );

    if (chairContacts?.length) {
      chairContacts?.forEach((consortium: any) => {
        const temp = {
          LastName: consortium?.chairContact?.lastName ?? "",
          FirstName: consortium?.chairContact?.firstName ?? "",
          Company: consortium?.chairContact?.company?.name ?? "",
          Email: consortium?.chairContact?.email ?? "",
          Mobile: consortium?.chairContact?.mobile ?? "",
          Group: consortium?.name,
          Role: "Chair",
          Status: "Accepted",
        };

        // add user to array
        data.push(temp);
      });
    }

    // sort user data
    data.sort((itemA: any, itemB: any) => {
      if (itemA.LastName < itemB.LastName) {
        return -1;
      }
      if (itemA.LastName > itemB.LastName) {
        return 1;
      }
      return 0;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "contact-list");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `contact-list-${helpers.getRandomString(4)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export participation list to excel file
  const handleParticipationLogData = () => {
    // if empty array then return
    if (!transactionProviderState?.allInviteLogs?.length) {
      // show toast message
      toast.warning("Invite logs empty.", { theme: "colored" });
      return;
    }

    let data: any[] = [];

    // remove deleted & not accepted user from list
    const tempArr = transactionProviderState?.allInviteLogs?.filter(
      (log) => log?.deleted !== true && log?.isAccepted
    );

    tempArr?.forEach((participationLog: any) => {
      const temp = {
        Organization: participationLog?.organization?.name ?? "",
        FirstName: participationLog?.user?.firstName ?? "",
        LastName: participationLog?.user?.lastName ?? "",
        Company: participationLog?.company?.name ?? "",
        Role: participationLog?.type ?? "",
        Email: participationLog?.user?.email ?? "",
        Mobile: participationLog?.user?.mobile ?? "",
        AcceptedAt:
          dayjs(participationLog?.acceptedAt).format("D MMM YYYY hh:mm A") ??
          "",
      };

      // add log to array
      data.push(temp);
    });

    // sort user data
    data.sort((itemA: any, itemB: any) => {
      if (itemA.LastName < itemB.LastName) {
        return -1;
      }
      if (itemA.LastName > itemB.LastName) {
        return 1;
      }
      return 0;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "contact-list");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `accepted-participation-logs-${helpers.getRandomString(
      4
    )}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export invite user log list to excel file
  const handleInvitedUserLogData = () => {
    // if empty array then return
    if (!transactionProviderState?.allInviteLogs?.length) {
      // show toast message
      toast.warning("Invite logs empty.", { theme: "colored" });
      return;
    }

    let data: any[] = [];

    // remove deleted & accepted user from list
    // && log?.isAccepted !== true
    const tempArr = transactionProviderState?.allInviteLogs?.filter(
      (log) => log?.deleted !== true
    );

    tempArr?.forEach((invitedLog: any) => {
      const temp = {
        Organization: invitedLog?.organization?.name ?? "",
        FirstName: invitedLog?.user?.firstName ?? "",
        LastName: invitedLog?.user?.lastName ?? "",
        Company: invitedLog?.company?.name ?? "",
        Role: invitedLog?.type ?? "",
        Email: invitedLog?.user?.email ?? "",
        Mobile: invitedLog?.user?.mobile ?? "",
        "InvitedBy Name": `${invitedLog?.sendBy?.firstName ?? ""} ${
          invitedLog?.sendBy?.lastName ?? ""
        }`,
        "InvitedBy Email": invitedLog?.sendBy?.email ?? "",
        InvitedAt:
          dayjs(invitedLog?.createdAt).format("D MMM YYYY hh:mm A") ?? "",
      };

      // add log to arrays
      data.push(temp);
    });

    // sort user data
    data.sort((itemA: any, itemB: any) => {
      if (itemA.LastName < itemB.LastName) {
        return -1;
      }
      if (itemA.LastName > itemB.LastName) {
        return 1;
      }
      return 0;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "contact-list");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `invited-user-logs-${helpers.getRandomString(4)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // export document log list to excel file
  const handleDocumentLogData = () => {
    // if empty array then return
    if (!transactionProviderState?.allDocuments?.length) {
      // show toast message
      toast.warning("Document logs empty.", { theme: "colored" });
      return;
    }

    let data: any[] = [];

    // remove deleted document from list
    const tempArr = transactionProviderState?.allDocuments?.filter(
      (log) => log?.deleted !== true
    );

    tempArr?.forEach((document: any) => {
      const temp = {
        Title: document?.title ?? "",
        URL: document?.url ?? "",
        "S3 Bucket Name": process.env.REACT_APP_A4A_DOCUMENT_S3_BUCKET_NAME,
        Bucket: document?.bucketType ?? "",
        Category: document?.documentCategory?.name ?? "",
        "PublishedBy Name": `${document?.publishedBy?.firstName ?? ""} ${
          document?.publishedBy?.lastName ?? ""
        }`,
        "PublishedBy Email": document?.publishedBy?.email ?? "",
        "CreatedBy Name": `${document?.createdBy?.firstName ?? ""} ${
          document?.createdBy?.lastName ?? ""
        }`,
        "CreatedBy Email": document?.createdBy?.email ?? "",
        PublishedDate:
          dayjs(document?.publishedDate).format("D MMM YYYY hh:mm A") ?? "",
        CreatedAt:
          dayjs(document?.createdAt).format("D MMM YYYY hh:mm A") ?? "",
      };

      // add log to array
      data.push(temp);
    });

    // sort document data
    data.sort((itemA: any, itemB: any) => {
      if (itemA.Title < itemB.Title) {
        return -1;
      }
      if (itemA.Title > itemB.Title) {
        return 1;
      }
      return 0;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "contact-list");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `document-logs-${helpers.getRandomString(4)}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportContactCheck =
    exportContacts && isPendingInviteLoaded ? true : false;

  return (
    <>
      <Grid sx={{ my: 2 }}>
        <Card
          style={{
            padding: "20px 5px",
            margin: "0 auto",
            borderRadius: "16px",
            background: "rgb(96, 96, 96, 0.05)",
            minHeight: "75vh",
          }}
        >
          <CardContent>
            <Typography variant="h6">Data Export</Typography>
            <Grid sx={{ mt: 2 }} container>
              <Grid md={4} item>
                <Box
                  component={Paper}
                  elevation={1}
                  sx={{
                    height: "100%",
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "16px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography variant="h6">Export Consortium List</Typography>
                  <Typography component="p">
                    Export consortium list in excel format. Having Airport Code,
                    Airport Name, State, Region, Consortium Name, Chair Company,
                    Chair Contact + Chair Email +Chair Phone, GM Company + GM
                    Contact + GM Email + GM Phone data.
                  </Typography>
                  <Button
                    disabled={!isA4AAdmin || !exportConsortiums}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      width: "25%",
                      ml: "auto",
                      borderRadius: "16px",
                      mt: 1,
                    }}
                    onClick={handleExportConsortiumData}
                  >
                    Export
                  </Button>
                </Box>
              </Grid>
              <Grid md={4} item>
                <Box
                  component={Paper}
                  elevation={1}
                  sx={{
                    height: "100%",
                    p: 2,
                    ml: 1,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "16px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography variant="h6">Export Contact List</Typography>
                  <Typography component="p">
                    Export user list in excel format. Having First, Last,
                    Company, Email, Phone and groups they are in – also, include
                    their roles in the group (admin, member, support admin,
                    chair).
                  </Typography>
                  <Button
                    disabled={
                      !isA4AAdmin || (!exportContactCheck && !exportConsortiums)
                    }
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      width: "25%",
                      ml: "auto",
                      borderRadius: "16px",
                      mt: 1,
                    }}
                    onClick={handleExportContactListData}
                  >
                    Export
                  </Button>
                </Box>
              </Grid>
              <Grid md={4} item>
                <Box
                  component={Paper}
                  elevation={1}
                  sx={{
                    height: "100%",
                    p: 2,
                    ml: 1,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "16px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography variant="h6">
                    Export Calendar Event List
                  </Typography>
                  <Typography component="p">
                    Export calendar event list in excel format. Having Name,
                    Description, Organizer, Venue, Venue Web Site, Start At, End
                    At, Event Type data.
                  </Typography>
                  <Button
                    disabled={!isA4AAdmin || !exportCalendarEvents}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      width: "25%",
                      ml: "auto",
                      borderRadius: "16px",
                      mt: 1,
                    }}
                    onClick={handleExportCalenarEventListData}
                  >
                    Export
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid sx={{ mt: 2 }} container>
              <Grid md={4} item>
                <Box
                  component={Paper}
                  elevation={1}
                  sx={{
                    height: "100%",
                    p: 2,
                    ml: 1,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "16px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography variant="h6">
                    Accepted Participation Logs
                  </Typography>
                  <Typography component="p">
                    Export accepted participation logs in excel format.
                  </Typography>
                  <Button
                    disabled={
                      !isA4AAdmin ||
                      transactionProviderState?.allInviteLogApiLoading
                    }
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      width: "25%",
                      ml: "auto",
                      borderRadius: "16px",
                      mt: 1,
                    }}
                    onClick={handleParticipationLogData}
                  >
                    Export
                  </Button>
                </Box>
              </Grid>
              <Grid md={4} item>
                <Box
                  component={Paper}
                  elevation={1}
                  sx={{
                    height: "100%",
                    p: 2,
                    ml: 1,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "16px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography variant="h6">Invited User Logs</Typography>
                  <Typography component="p">
                    Export invited user logs in excel format.
                  </Typography>
                  <Button
                    disabled={
                      !isA4AAdmin ||
                      transactionProviderState?.allInviteLogApiLoading
                    }
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      width: "25%",
                      ml: "auto",
                      borderRadius: "16px",
                      mt: 1,
                    }}
                    onClick={handleInvitedUserLogData}
                  >
                    Export
                  </Button>
                </Box>
              </Grid>
              <Grid md={4} item>
                <Box
                  component={Paper}
                  elevation={1}
                  sx={{
                    height: "100%",
                    p: 2,
                    ml: 1,
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "16px",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography variant="h6">Document Logs</Typography>
                  <Typography component="p">
                    Export document logs in excel format.
                  </Typography>
                  <Button
                    disabled={
                      !isA4AAdmin ||
                      transactionProviderState?.allDocumentListApiLoading
                    }
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      width: "25%",
                      ml: "auto",
                      borderRadius: "16px",
                      mt: 1,
                    }}
                    onClick={handleDocumentLogData}
                  >
                    Export
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export { ViewExportData };
