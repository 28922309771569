import joi from "joi";

// Strong Password pattern
// Password policy rules:
// * Require at least 8 characters long.
// * Require least one uppercase letter.
// * Require least one lowercase letter.
// * Require least one digit.
// * Require least one special character.

const passwordRegexPattern =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

// sign-in login params
// validation schema
const signInValidationSchema: any = {
  email: joi
    .string()
    .trim()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "Enter a valid email.",
      "string.empty": "Email cannot be empty.",
    }),
  password: joi.string().trim().messages({
    "string.empty": "Password cannot be empty.",
    "any.required": "Password required.",
  }),
};

// sign-up params
// validation schema
const signUpValidationSchema: any = {
  email: joi
    .string()
    .trim()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "Enter a valid email.",
      "string.empty": "Email cannot be empty.",
    }),
  // password: joi.string().trim().messages({
  //   "string.empty": "Password cannot be empty.",
  //   "any.required": "Password required.",
  // }),
  password: joi
    .string()
    .regex(RegExp(passwordRegexPattern))
    .required()
    .messages({
      "string.empty": "Password cannot be empty.",
      "string.pattern.base": "Choose a strong password.",
    }),
  firstName: joi.string().trim().messages({
    "string.empty": "Cannot be empty.",
    "any.required": "First name required.",
  }),
  lastName: joi.string().trim().messages({
    "string.empty": "Cannot be empty.",
    "any.required": "Last name required.",
  }),
  company: joi.string().trim().messages({
    "string.empty": "Cannot be empty.",
    "any.required": "Company required.",
  }),
};

// forgot / reset password params
// validation schema
const forgotPasswordValidationSchema: any = {
  email: joi
    .string()
    .trim()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "Enter a valid email.",
      "string.empty": "Email cannot be empty.",
    }),
};

// reset password params
// validation schema
const resetPasswordValidationSchema: any = {
  verificationCode: joi.string().trim().required().messages({
    "string.empty": "Verification code cannot be empty.",
    "any.required": "Verification code required.",
  }),
  // newPassword: joi.string().trim().required().messages({
  //   "string.empty": "Password cannot be empty.",
  //   "any.required": "Password required.",
  // }),
  newPassword: joi
    .string()
    .regex(RegExp(passwordRegexPattern))
    .required()
    .messages({
      "string.empty": "Password cannot be empty.",
      "string.pattern.base": "Choose a strong password.",
    }),
  // confirmNewPassword: joi
  //   .string()
  //   .trim()
  //   // .valid(joi.ref("newPassword"))
  //   .required()
  //   .messages({
  //     "string.empty": "Confirm Password cannot be empty.",
  //     "any.required": "Password don't match.",
  //     "any.only": "Password don't match.",
  //   }),
  confirmNewPassword: joi
    .string()
    .regex(RegExp(passwordRegexPattern))
    .required()
    .messages({
      "string.empty": "Password cannot be empty.",
      "string.pattern.base": "Choose a strong password.",
    }),
};

// update password params
// validation schema
const updatePasswordValidationSchema: any = {
  oldPassword: joi.string().trim().required().messages({
    "string.empty": "Old Password cannot be empty.",
    "any.required": "Old Password required.",
  }),
  // newPassword: joi.string().trim().required().messages({
  //   "string.empty": "Password cannot be empty.",
  //   "any.required": "Password required.",
  // }),
  newPassword: joi
    .string()
    .regex(RegExp(passwordRegexPattern))
    .required()
    .messages({
      "string.empty": "Password cannot be empty.",
      "string.pattern.base": "Choose a strong password.",
    }),
  // confirmNewPassword: joi
  //   .string()
  //   .trim()
  //   // .valid(joi.ref("newPassword"))
  //   .required()
  //   .messages({
  //     "string.empty": "Confirm Password cannot be empty.",
  //     "any.required": "Password don't match.",
  //     "any.only": "Password don't match.",
  //   }),
  confirmNewPassword: joi
    .string()
    .regex(RegExp(passwordRegexPattern))
    .required()
    .messages({
      "string.empty": "Password cannot be empty.",
      "string.pattern.base": "Choose a strong password.",
    }),
};

// email list params
// validation schema
const emailListValidationSchema: any = {
  firstName: joi.string().trim().required().messages({
    "string.empty": "cannot be empty.",
    "any.required": "required.",
  }),
  lastName: joi.string().trim().required().messages({
    "string.empty": "cannot be empty.",
    "any.required": "required.",
  }),
  company: joi.string().trim().required().messages({
    "string.empty": "cannot be empty.",
    "any.required": "required.",
  }),
  email: joi
    .string()
    .trim()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "invalid email.",
      "string.empty": "cannot be empty.",
    }),
};

export {
  signInValidationSchema,
  signUpValidationSchema,
  forgotPasswordValidationSchema,
  resetPasswordValidationSchema,
  updatePasswordValidationSchema,
  emailListValidationSchema,
};
