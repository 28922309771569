import React from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

interface IGeneralTabConfirmationModal {
  open: boolean;
  handleClose: Function;
  confirmUpdate: Function;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const GeneralTabConfirmationModal = ({
  open,
  handleClose,
  confirmUpdate,
}: IGeneralTabConfirmationModal) => {
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          disableRipple
          style={{ position: "absolute", right: "5px", top: "10px" }}
          onClick={() => handleClose()}
        >
          <Close />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Update confirmation?
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to update?
        </Typography>
        <Button
          style={{ display: "block", margin: "auto", marginTop: "10px" }}
          color="info"
          variant="contained"
          onClick={() => confirmUpdate()}
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  );
};

export default GeneralTabConfirmationModal;
