import { API } from "aws-amplify";
import { IInviteBody } from "./types";

class MailService {
  private apiName = "";
  private apiBasePath = "";

  constructor() {
    // set api name
    this.apiName = "mailapi";
    this.apiBasePath = "/mail";
  }

  /*
    Task: send organization invite mail to user
  */
  async sendOrgInviteMail(body: IInviteBody) {
    // HTTP header
    const init = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: body,
    };

    let response = {};

    try {
      const temp = await API.post(
        this.apiName,
        `${this.apiBasePath}/org/invite`,
        init
      );

      // set data
      response = temp?.data;
    } catch (error: any) {
      // set error
      response = error?.response;
    }

    return response;
  }
}

export { MailService };
