import React, { useState } from "react";
import {
  AirportDetailDrawerDocumentsSectionBtnSegnment,
  AirportDetailDrawerDocumentsSectionContent,
  AirportDetailDrawerEditIcons,
  AirportDetailDrawerInfoCardCloseIcon,
  AirportDetailDrawerInfoCardDeleteIcon,
  AirportDetailDrawerInfoCardEditIcon,
  AirportDetailDrawerInfoCardSaveButton,
} from "./style";
import {
  AirportFieldValidator,
  IDeliveryToFacility,
} from "../MapProvider/types";
import {
  AirportFieldValidatorType,
  DeliveryFrequencyChoiceMapping,
  DeliveryFrequencyOptions,
  DeliveryMethodChoiceMapping,
  DeliveryMethodOptions,
  FiltrationTypeChoiceMapping,
  FiltrationTypeOptions,
  deliveryToFacilityFields,
  generalTabFieldType,
  generalTabFields,
} from "../../constants/map";
import {
  DeliveryFrequencyChoice,
  DeliveryMethodChoice,
  FiltrationTypeChoice,
} from "../../API";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import AirportDetailDrawerInfoCard from "./AirportDetailDrawerInfoCard";
import MapService from "../../services/MapService";
import { airportFieldValidator } from "../../utils/helpers";
import ConfirmDeleteModal from "../ViewCalendar/ConfirmDeleteModal";

type IDeliveryToFacilityTab = {
  airportId: string;
  organizationId: string;
  airportCode: string;
  oldCode: string;
  facility: string;
  deliveryToFacility?: IDeliveryToFacility;
  // canUserEditOrgInfo: boolean;
  isAdding?: boolean;
  setIsAdding?: any;
};

export interface IEditDeliveryToFacilityInput {
  provider?: string | null;
  method?: DeliveryMethodChoice | null;
  primary?: boolean | null;
  numberLines?: number | null;
  frequency?: DeliveryFrequencyChoice | null;
  frequencyCustomValue?: number | null;
  filtrationType?: FiltrationTypeChoice | null;
  filtrationTypeCustomValue?: string | null;
  filtrationDescription?: string | null;
  flowRateTypical?: number | null;
  flowRateMax?: number | null;
  pipingSize?: number | null;
  offloadLocations?: number | null;
}

const defaultDeliveryToFacilityObj = {
  provider: "",
  method: DeliveryMethodChoice.PIPELINE,
  primary: false,
  numberLines: null,
  frequency: DeliveryFrequencyChoice.ON_DEMAND,
  frequencyCustomValue: null,
  filtrationType: FiltrationTypeChoice.NONE,
  filtrationTypeCustomValue: "",
  filtrationDescription: "",
  flowRateTypical: null,
  flowRateMax: null,
  pipingSize: null,
  offloadLocations: null,
};

const deliveryToFacilityFieldValidations: AirportFieldValidator[] = [
  {
    field: deliveryToFacilityFields.PROVIDER,
    errorMessage: "Provider is required",
    type: AirportFieldValidatorType.STRING,
    isRequired: true,
  },
  {
    field: deliveryToFacilityFields.NUMBER_OF_LINES,
    errorMessage: "Number of Lines must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToFacilityFields.FLOW_RATE_TYPICAL,
    errorMessage: "Flow Rate: Typical (gpm) must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToFacilityFields.FLOW_RATE_MAX,
    errorMessage: "Flow Rate: Max (gpm) must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToFacilityFields.PIPING_SIZE,
    errorMessage: "Piping Size (in) must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToFacilityFields.OFF_ROAD_LOCATIONS,
    errorMessage: "Offload Locations (#) must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
];

const DeliveryToFacilityTab = ({
  airportId,
  airportCode,
  organizationId,
  oldCode,
  facility,
  deliveryToFacility,
  // canUserEditOrgInfo,
  isAdding = false,
  setIsAdding,
}: IDeliveryToFacilityTab) => {
  const { state, dispatch } = useMapContext();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(isAdding);
  const [editInputs, setEditInputs] = useState<IEditDeliveryToFacilityInput>(
    deliveryToFacility
      ? {
          provider: deliveryToFacility.provider,
          method: deliveryToFacility.method,
          primary: deliveryToFacility.primary,
          numberLines: deliveryToFacility.numberLines,
          frequency: deliveryToFacility.frequency,
          frequencyCustomValue: deliveryToFacility.frequencyCustomValue,
          filtrationType: deliveryToFacility.filtrationType,
          filtrationTypeCustomValue:
            deliveryToFacility.filtrationTypeCustomValue,
          filtrationDescription: deliveryToFacility.filtrationDescription,
          flowRateTypical: deliveryToFacility.flowRateTypical,
          flowRateMax: deliveryToFacility.flowRateMax,
          pipingSize: deliveryToFacility.pipingSize,
          offloadLocations: deliveryToFacility.offloadLocations,
        }
      : defaultDeliveryToFacilityObj
  );

  const companyOptions = state.companyList
    .map((company) => ({
      name: company.name,
      value: company.id,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const dTFData = [
    {
      heading: "Provider",
      infoArray: [
        {
          name: deliveryToFacilityFields.PROVIDER,
          value: `${deliveryToFacility?.provider ?? ""}`,
          type: generalTabFieldType.TEXT,
          placeHolder: "Provider",
        },
      ],
      infoText: "Name of company delivering fuel to facility",
    },
    {
      heading: "Method",
      infoArray: [
        {
          name: deliveryToFacilityFields.METHOD,
          value: deliveryToFacility?.[deliveryToFacilityFields.METHOD]
            ? DeliveryMethodChoiceMapping[
                deliveryToFacility?.[
                  deliveryToFacilityFields.METHOD
                ] as keyof typeof DeliveryMethodChoiceMapping
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: DeliveryMethodOptions,
        },
      ],
    },
    {
      heading: "Primary",
      infoArray: [
        {
          name: deliveryToFacilityFields.PRIMARY,
          value: deliveryToFacility?.[deliveryToFacilityFields.PRIMARY]
            ? "Yes"
            : "No",
          type: generalTabFieldType.BOOLEAN,
        },
      ],
    },
    {
      heading: "Number of Lines",
      infoArray: [
        {
          name: deliveryToFacilityFields.NUMBER_OF_LINES,
          value:
            deliveryToFacility?.[
              deliveryToFacilityFields.NUMBER_OF_LINES
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
      infoText: "How many lines does the pipeline have feeding the tank farm?",
    },
    {
      heading: "Frequency",
      infoArray: [
        {
          name: deliveryToFacilityFields.FREQUENCY,
          value: deliveryToFacility?.[deliveryToFacilityFields.FREQUENCY]
            ? DeliveryFrequencyChoiceMapping[
                deliveryToFacility?.[
                  deliveryToFacilityFields.FREQUENCY
                ] as keyof typeof DeliveryFrequencyChoiceMapping
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: DeliveryFrequencyOptions,
        },
      ],
      infoText: "Indicate how many days between receipts from this provider",
    },
    // {
    //   heading: "Frequency Custom Value",
    //   infoArray: [
    //     {
    //       name: deliveryToFacilityFields.FREQUENCY_CUSTOM_VALUE,
    //       value:
    //         deliveryToFacility?.[
    //           deliveryToFacilityFields.FREQUENCY_CUSTOM_VALUE
    //         ]?.toString() ?? "",
    //       type: generalTabFieldType.TEXT,
    //     },
    //   ],
    // },
    {
      heading: "Filtration Type",
      infoArray: [
        {
          name: deliveryToFacilityFields.FILTRATION_TYPE,
          value: deliveryToFacility?.[deliveryToFacilityFields.FILTRATION_TYPE]
            ? FiltrationTypeChoiceMapping[
                deliveryToFacility?.[
                  deliveryToFacilityFields.FILTRATION_TYPE
                ] as keyof typeof FiltrationTypeChoiceMapping
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: FiltrationTypeOptions,
        },
      ],
      infoText:
        'In terms of the "last line of defense," what kind of inbound filtration, if any, does the fuel go through immediately prior to entering the fuel farm tanks? (For pipeline deliveries, selecting "three-stage filtration" requires the presence of micronic filters, clay treaters, and filter separators.) Pick only one of the above.',
    },
    // {
    //   heading: "Filtration Type Custom Value",
    //   infoArray: [
    //     {
    //       name: deliveryToFacilityFields.FILTRATION_CUSTOM_VALUE,
    //       value:
    //         deliveryToFacility?.[
    //           deliveryToFacilityFields.FILTRATION_CUSTOM_VALUE
    //         ] ?? "",
    //       type: generalTabFieldType.TEXT,
    //     },
    //   ],
    // },
    {
      heading: "Filtration Description",
      infoArray: [
        {
          name: deliveryToFacilityFields.FILTRATION_DESCRIPTION,
          value:
            deliveryToFacility?.[
              deliveryToFacilityFields.FILTRATION_DESCRIPTION
            ] ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
      infoText: "Please describe inbound filtration, especially if off site",
    },
    {
      heading: "Flow Rate: Typical (gpm)",
      infoArray: [
        {
          name: deliveryToFacilityFields.FLOW_RATE_TYPICAL,
          value:
            deliveryToFacility?.[
              deliveryToFacilityFields.FLOW_RATE_TYPICAL
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
      infoText: "Actual inbound pumping/flow rate (gallons per minute)",
    },
    {
      heading: "Flow Rate: Max (gpm)",
      infoArray: [
        {
          name: deliveryToFacilityFields.FLOW_RATE_MAX,
          value:
            deliveryToFacility?.[
              deliveryToFacilityFields.FLOW_RATE_MAX
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
      infoText: "Maximum inbound pumping/flow rate (gallons per minute)",
    },
    {
      heading: "Piping Size (in)",
      infoArray: [
        {
          name: deliveryToFacilityFields.PIPING_SIZE,
          value:
            deliveryToFacility?.[
              deliveryToFacilityFields.PIPING_SIZE
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
      infoText: "Diameter, in inches, if applicable",
    },
    {
      heading: "Offload Locations (#)",
      infoArray: [
        {
          name: deliveryToFacilityFields.OFF_ROAD_LOCATIONS,
          value:
            deliveryToFacility?.[
              deliveryToFacilityFields.OFF_ROAD_LOCATIONS
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
      infoText:
        "How many offload locations are available for trucks, barges or railcars?",
    },
  ];

  const editInputChangeHandler = (
    name: keyof any,
    value: string,
    type?: generalTabFieldType
  ) => {
    let finalValue: string = value;
    if (type === generalTabFieldType.DATE) {
      finalValue = new Date(finalValue as string).toISOString();
    }
    setEditInputs((prevState) => ({ ...prevState, [name]: finalValue }));
  };

  const updateHandler = async (): Promise<void> => {
    // if (!canUserEditOrgInfo) return;
    const errorMessage = airportFieldValidator(
      deliveryToFacilityFieldValidations.map((validation) => ({
        ...validation,
        field:
          editInputs[
            validation.field as keyof IEditDeliveryToFacilityInput
          ]?.toString() ?? "",
      }))
    );
    if (!!errorMessage) {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: errorMessage,
          type: "error",
        },
      });
      return;
    }
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: `${
        isAdding ? "Adding" : "Updating"
      } Delivery to Facility Information`,
    });
    const editInputsCopy = { ...editInputs };
    editInputsCopy.numberLines = isNaN(
      parseInt(editInputsCopy?.numberLines?.toString() ?? "")
    )
      ? null
      : editInputsCopy.numberLines;
    editInputsCopy.flowRateTypical = isNaN(
      parseInt(editInputsCopy?.flowRateTypical?.toString() ?? "")
    )
      ? null
      : editInputsCopy.flowRateTypical;
    editInputsCopy.flowRateMax = isNaN(
      parseInt(editInputsCopy?.flowRateMax?.toString() ?? "")
    )
      ? null
      : editInputsCopy.flowRateMax;
    editInputsCopy.pipingSize = isNaN(
      parseInt(editInputsCopy?.pipingSize?.toString() ?? "")
    )
      ? null
      : editInputsCopy.pipingSize;
    editInputsCopy.offloadLocations = isNaN(
      parseInt(editInputsCopy?.offloadLocations?.toString() ?? "")
    )
      ? null
      : editInputsCopy.offloadLocations;
    let response: any;
    if (isAdding) {
      response = await MapService.createDeliveryToFacility({
        ...editInputsCopy,
        airportId,
        organizationId,
      });
    } else {
      response = await MapService.updateDeliveryToFacility(
        deliveryToFacility!.id,
        editInputsCopy
      );
    }
    if (typeof response !== "string") {
      let updatedAirport = {
        ...state.activeAirport,
        deliveryToFacility: {
          ...state.activeAirport?.deliveryToFacility,
          items: isAdding
            ? [
                ...(state.activeAirport?.deliveryToFacility?.items ?? []),
                response,
              ]
            : state.activeAirport?.deliveryToFacility?.items?.map(
                (currentDeliveryToFacility) => {
                  if (
                    currentDeliveryToFacility?.id === deliveryToFacility!.id
                  ) {
                    return { ...currentDeliveryToFacility, ...response };
                  }
                  return currentDeliveryToFacility;
                }
              ),
        },
      };
      setEditInputs(editInputsCopy)
      dispatch({
        type: mapContextAction.SET_ACTIVE_AIRPORT,
        payload: updatedAirport,
      });
      setIsInEditMode(false);
      if (isAdding) setIsAdding(false);
    } else {
      alert(response);
    }
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "",
    });
  };

  const deleteHandler = async () => {
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "Deleting delivery to facility entry",
    });
    const { message, type: severity } =
      await MapService.deleteDeliveryToFacility(deliveryToFacility?.id ?? "");
    if (severity === "success") {
      let updatedAirport = {
        ...state.activeAirport,
        deliveryToFacility: {
          ...state.activeAirport?.deliveryToFacility,
          items: state.activeAirport?.deliveryToFacility?.items?.filter(
            (currentDeliveryToFacility) =>
              currentDeliveryToFacility?.id !== deliveryToFacility!.id
          ),
        },
      };
      setIsDeleting(false);
      dispatch({
        type: mapContextAction.SET_ACTIVE_AIRPORT,
        payload: updatedAirport,
      });
    } else {
      alert(message);
    }
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "",
    });
  };

  return (
    <>
      <AirportDetailDrawerDocumentsSectionContent>
        {/* {canUserEditOrgInfo && ( */}
        <AirportDetailDrawerEditIcons>
          {!isInEditMode && (
            <AirportDetailDrawerInfoCardEditIcon
              onClick={() => setIsInEditMode(true)}
            />
          )}
          {!isInEditMode && !isAdding && (
            <AirportDetailDrawerInfoCardDeleteIcon
              onClick={() => setIsDeleting(true)}
            />
          )}
          {isInEditMode && (
            <AirportDetailDrawerInfoCardCloseIcon
              onClick={() => {
                isAdding ? setIsAdding(false) : setIsInEditMode(false);
              }}
            />
          )}
        </AirportDetailDrawerEditIcons>
        {/* )} */}
        {dTFData.map((data) => {
          return (
            <AirportDetailDrawerInfoCard
              key={data.heading}
              role={data.heading}
              isEditable={true}
              infoArray={data.infoArray}
              isInEditMode={isInEditMode}
              editInputs={editInputs}
              editInputChangeHandler={editInputChangeHandler}
              containerClassName="data-tab-card"
              infoText={data.infoText}
            />
          );
        })}
        {isInEditMode && (
          <AirportDetailDrawerDocumentsSectionBtnSegnment>
            <AirportDetailDrawerInfoCardSaveButton
              color="info"
              onClick={updateHandler}
            >
              Save Changes
            </AirportDetailDrawerInfoCardSaveButton>
          </AirportDetailDrawerDocumentsSectionBtnSegnment>
        )}
      </AirportDetailDrawerDocumentsSectionContent>
      <ConfirmDeleteModal
        open={isDeleting}
        handleClose={() => setIsDeleting(false)}
        handleDelete={deleteHandler}
      />
    </>
  );
};

export default DeliveryToFacilityTab;
