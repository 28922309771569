import React, { useState } from "react";
import {
  AirportDetailDrawerDocumentsSectionBtnSegnment,
  AirportDetailDrawerDocumentsSectionContent,
  AirportDetailDrawerEditIcons,
  AirportDetailDrawerInfoCardCloseIcon,
  AirportDetailDrawerInfoCardDeleteIcon,
  AirportDetailDrawerInfoCardEditIcon,
  AirportDetailDrawerInfoCardSaveButton,
} from "./style";
import { AirportFieldValidator, IDeliveryToPlane } from "../MapProvider/types";
import {
  AirportFieldValidatorType,
  closedLoopEnumMapping,
  closedLoopEnumOptions,
  deliveryToPlaneFields,
  generalTabFieldType,
  hydrantChoiceEnumMapping,
  hydrantChoiceEnumOptions,
} from "../../constants/map";
import { ClosedLoopChoice, HydrantChoice } from "../../API";
import AirportDetailDrawerInfoCard from "./AirportDetailDrawerInfoCard";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import MapService from "../../services/MapService";
import { airportFieldValidator } from "../../utils/helpers";
import ConfirmDeleteModal from "../ViewCalendar/ConfirmDeleteModal";

type IDeliveryToPlaneTab = {
  airportId: string;
  organizationId: string;
  airportCode: string;
  oldCode: string;
  facility: string;
  deliveryToPlane?: IDeliveryToPlane;
  // canUserEditOrgInfo: boolean;
  isAdding?: boolean;
  setIsAdding?: any;
};

export interface IEditDeliveryToPlaneInput {
  obFiltration?: boolean | null;
  hydrant?: HydrantChoice | null;
  closedLoop: ClosedLoopChoice;
  length?: number | null;
  hydrantLocations?: string | null;
  pits?: number | null;
  refuelerLocations?: string | null;
  efso?: boolean | null;
  efsoLocation?: string | null;
  leakDetection?: boolean | null;
  leakDetectionDescription?: string | null;
  flowRateF1Peak?: number | null;
  flowRateF1Max?: number | null;
  flowRateF2Peak?: number | null;
  flowRateF2Max?: number | null;
  flowRateF3Peak?: number | null;
  flowRateF3Max?: number | null;
  pipingSizeMax?: number | null;
  pipingSizeMin?: number | null;
  currentIntoPlaneOperators?: string | null;
}

const defaultDeliveryToPlaneObj = {
  obFiltration: false,
  hydrant: HydrantChoice.NO,
  closedLoop: ClosedLoopChoice.NO,
  length: null,
  hydrantLocations: "",
  pits: null,
  refuelerLocations: "",
  efso: false,
  efsoLocation: "",
  leakDetection: false,
  leakDetectionDescription: "",
  flowRateF1Peak: null,
  flowRateF1Max: null,
  flowRateF2Peak: null,
  flowRateF2Max: null,
  flowRateF3Peak: null,
  flowRateF3Max: null,
  pipingSizeMax: null,
  pipingSizeMin: null,
  currentIntoPlaneOperators: "",
};

const deliveryToPlaneFieldValidations: AirportFieldValidator[] = [
  {
    field: deliveryToPlaneFields.LENGTH,
    errorMessage: "Length must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToPlaneFields.PITS,
    errorMessage: "Pits must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToPlaneFields.FLOW_RATE_F1_PEAK,
    errorMessage: "Flow Rate F1: Peak (gpm) must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToPlaneFields.FLOW_RATE_F1_MAX,
    errorMessage: "Flow Rate F1: Design Max (gpm) must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToPlaneFields.FLOW_RATE_F2_PEAK,
    errorMessage: "Flow F2: Peak must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToPlaneFields.FLOW_RATE_F2_MAX,
    errorMessage: "Flow F2: Design Max must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToPlaneFields.FLOW_RATE_F3_PEAK,
    errorMessage: "Flow F3: Peak must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToPlaneFields.FLOW_RATE_F3_MAX,
    errorMessage: "Flow F3: Design Max must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToPlaneFields.PIPING_SIZE_MIN,
    errorMessage: "Piping Size (in): Min must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: deliveryToPlaneFields.PIPING_SIZE_MAX,
    errorMessage: "Piping Size (in): Max must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
];

const DeliveryToPlaneTab = ({
  airportId,
  airportCode,
  organizationId,
  oldCode,
  facility,
  deliveryToPlane,
  // canUserEditOrgInfo,
  isAdding = false,
  setIsAdding,
}: IDeliveryToPlaneTab) => {
  const { state, dispatch } = useMapContext();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(isAdding);
  const [editInputs, setEditInputs] = useState<IEditDeliveryToPlaneInput>(
    deliveryToPlane
      ? {
          obFiltration: deliveryToPlane.obFiltration,
          hydrant: deliveryToPlane.hydrant,
          closedLoop: deliveryToPlane.closedLoop,
          length: deliveryToPlane.length,
          hydrantLocations: deliveryToPlane.hydrantLocations,
          pits: deliveryToPlane.pits,
          refuelerLocations: deliveryToPlane.refuelerLocations,
          efso: deliveryToPlane.efso,
          efsoLocation: deliveryToPlane.efsoLocation,
          leakDetection: deliveryToPlane.leakDetection,
          leakDetectionDescription: deliveryToPlane.leakDetectionDescription,
          flowRateF1Peak: deliveryToPlane.flowRateF1Peak,
          flowRateF1Max: deliveryToPlane.flowRateF1Max,
          flowRateF2Peak: deliveryToPlane.flowRateF2Peak,
          flowRateF2Max: deliveryToPlane.flowRateF2Max,
          flowRateF3Peak: deliveryToPlane.flowRateF3Peak,
          flowRateF3Max: deliveryToPlane.flowRateF3Max,
          pipingSizeMax: deliveryToPlane.pipingSizeMax,
          pipingSizeMin: deliveryToPlane.pipingSizeMin,
          currentIntoPlaneOperators: deliveryToPlane.currentIntoPlaneOperators,
        }
      : defaultDeliveryToPlaneObj
  );

  const dTPData = [
    {
      heading: "Current Into-plane Operators",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.CURRENT_INTO_PLANE_OPERATORS,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.CURRENT_INTO_PLANE_OPERATORS
            ] ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Current Into-plane Operators",
        },
      ],
    },
    {
      heading: "O/B Filteration",
      infoArray: [
        {
          name: deliveryToPlaneFields.OB_FILTRATION,
          value:
            deliveryToPlane?.[deliveryToPlaneFields.OB_FILTRATION] ?? false
              ? "Yes"
              : "No",
          type: generalTabFieldType.BOOLEAN,
        },
      ],
      infoText: "Is the fuel filtered upon leaving the facility for the ramp?",
    },
    {
      heading: "Hydrant",
      infoArray: [
        {
          name: deliveryToPlaneFields.HYDRANT,
          value: deliveryToPlane?.[deliveryToPlaneFields.HYDRANT]
            ? hydrantChoiceEnumMapping[
                deliveryToPlane?.[
                  deliveryToPlaneFields.HYDRANT
                ] as keyof typeof hydrantChoiceEnumMapping
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: hydrantChoiceEnumOptions,
        },
      ],
    },
    {
      heading: "Closed Loop",
      infoArray: [
        {
          name: deliveryToPlaneFields.CLOSED_LOOP,
          value: deliveryToPlane?.[deliveryToPlaneFields.CLOSED_LOOP]
            ? closedLoopEnumMapping[
                deliveryToPlane?.[deliveryToPlaneFields.CLOSED_LOOP]
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: closedLoopEnumOptions,
        },
      ],
    },
    {
      heading: "Length (ft)",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.LENGTH,
          value:
            deliveryToPlane?.[deliveryToPlaneFields.LENGTH]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Length",
        },
      ],
      infoText: "Please approximate length of hydrant, in feet.",
    },
    {
      heading: "Hydrant Locations",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.HYDRANT_LOCATIONS,
          value:
            deliveryToPlane?.[deliveryToPlaneFields.HYDRANT_LOCATIONS] ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
      infoText: "At what terminal/concourse/gates?",
    },
    {
      heading: "Pits (#)",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.PITS,
          value:
            deliveryToPlane?.[deliveryToPlaneFields.PITS]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Refueler Locations",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.REFUELER_LOCATIONS,
          value:
            deliveryToPlane?.[deliveryToPlaneFields.REFUELER_LOCATIONS] ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
      infoText: "At what terminal/concourse/gates?",
    },
    {
      heading: "EFSO",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.EFSO,
          value:
            deliveryToPlane?.[deliveryToPlaneFields.EFSO] ?? false
              ? "Yes"
              : "No",
          type: generalTabFieldType.BOOLEAN,
        },
      ],
      infoText: "Is an Emergency Fuel Shutoff system available?",
    },
    {
      heading: "EFSO Locations",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.EFSO_LOCATIONS,
          value: deliveryToPlane?.[deliveryToPlaneFields.EFSO_LOCATIONS] ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Locations",
        },
      ],
    },
    {
      heading: "Leak Detection",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.LEAK_DETECTION,
          value:
            deliveryToPlane?.[deliveryToPlaneFields.LEAK_DETECTION] ?? false
              ? "Yes"
              : "No",
          type: generalTabFieldType.BOOLEAN,
        },
      ],
      infoText: "Is a leak detection system in place?",
    },
    {
      heading: "Leak Detection Description",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.LEAK_DETECTION_DESCRIPTION,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.LEAK_DETECTION_DESCRIPTION
            ] ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Description",
        },
      ],
    },
    {
      heading: "Flow Rate F1: Peak (gpm)",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.FLOW_RATE_F1_PEAK,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.FLOW_RATE_F1_PEAK
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "F1 Peak",
        },
      ],
      infoText:
        "Primary facility: maximum (designed) pumping/flow rate (gallons per minute)",
    },
    {
      heading: "Flow Rate F1: Design Max (gpm)",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.FLOW_RATE_F1_MAX,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.FLOW_RATE_F1_MAX
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "F1 Max",
        },
      ],
      infoText:
        "Primary facility: pumping/flow rate (gallons per minute) at peak demand",
    },
    {
      heading: "Flow F2: Peak",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.FLOW_RATE_F2_PEAK,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.FLOW_RATE_F2_PEAK
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "F2 Peak",
        },
      ],
      infoText: "If applicable, for 2nd facility",
    },
    {
      heading: "Flow F2: Design Max",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.FLOW_RATE_F2_MAX,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.FLOW_RATE_F2_MAX
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "F2 Max",
        },
      ],
      infoText: "If applicable, for 2nd facility",
    },
    {
      heading: "Flow F3: Peak",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.FLOW_RATE_F3_PEAK,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.FLOW_RATE_F3_PEAK
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "F3 Peak",
        },
      ],
      infoText: "If applicable, for 3rd facility",
    },
    {
      heading: "Flow F3: Design Max",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.FLOW_RATE_F3_MAX,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.FLOW_RATE_F3_MAX
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "F3 Max",
        },
      ],
      infoText: "If applicable, for 3rd facility",
    },
    {
      heading: "Piping Size (in): Min",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.PIPING_SIZE_MIN,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.PIPING_SIZE_MIN
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Piping Min",
        },
      ],
      infoText:
        "Diameter, in inches, if applicable, at smallest part of system",
    },
    {
      heading: "Piping Size (in): Max",
      isEditable: true,
      infoArray: [
        {
          name: deliveryToPlaneFields.PIPING_SIZE_MAX,
          value:
            deliveryToPlane?.[
              deliveryToPlaneFields.PIPING_SIZE_MAX
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Piping Max",
        },
      ],
      infoText: "Diameter, in inches, if applicable, at largest part of system",
    },
  ];

  const editInputChangeHandler = (
    name: keyof any,
    value: string,
    type?: generalTabFieldType
  ) => {
    let finalValue: string = value;
    if (type === generalTabFieldType.DATE) {
      finalValue = new Date(finalValue as string).toISOString();
    }
    setEditInputs((prevState) => ({ ...prevState, [name]: finalValue }));
  };

  const updateHandler = async (): Promise<void> => {
    // if (!canUserEditOrgInfo) return;
    const errorMessage = airportFieldValidator(
      deliveryToPlaneFieldValidations.map((validation) => ({
        ...validation,
        field:
          editInputs[
            validation.field as keyof IEditDeliveryToPlaneInput
          ]?.toString() ?? "",
      }))
    );
    if (!!errorMessage) {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: errorMessage,
          type: "error",
        },
      });
      return;
    }
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: `${
        isAdding ? "Adding" : "Updating"
      } Delivery to Airplane Information`,
    });
    let response: any;
    const editInputsCopy = { ...editInputs };
    editInputsCopy.length = isNaN(
      parseInt(editInputsCopy?.length?.toString() ?? "")
    )
      ? null
      : editInputsCopy.length;
    editInputsCopy.pits = isNaN(
      parseInt(editInputsCopy?.pits?.toString() ?? "")
    )
      ? null
      : editInputsCopy.pits;
    editInputsCopy.flowRateF1Peak = isNaN(
      parseInt(editInputsCopy?.flowRateF1Peak?.toString() ?? "")
    )
      ? null
      : editInputsCopy.flowRateF1Peak;
    editInputsCopy.flowRateF1Max = isNaN(
      parseInt(editInputsCopy?.flowRateF1Max?.toString() ?? "")
    )
      ? null
      : editInputsCopy.flowRateF1Max;
    editInputsCopy.flowRateF2Peak = isNaN(
      parseInt(editInputsCopy?.flowRateF2Peak?.toString() ?? "")
    )
      ? null
      : editInputsCopy.flowRateF2Peak;
    editInputsCopy.flowRateF2Max = isNaN(
      parseInt(editInputsCopy?.flowRateF2Max?.toString() ?? "")
    )
      ? null
      : editInputsCopy.flowRateF2Max;
    editInputsCopy.flowRateF3Peak = isNaN(
      parseInt(editInputsCopy?.flowRateF3Peak?.toString() ?? "")
    )
      ? null
      : editInputsCopy.flowRateF3Peak;
    editInputsCopy.flowRateF3Max = isNaN(
      parseInt(editInputsCopy?.flowRateF3Max?.toString() ?? "")
    )
      ? null
      : editInputsCopy.flowRateF3Max;
    editInputsCopy.pipingSizeMin = isNaN(
      parseInt(editInputsCopy?.pipingSizeMin?.toString() ?? "")
    )
      ? null
      : editInputsCopy.pipingSizeMin;
    editInputsCopy.pipingSizeMax = isNaN(
      parseInt(editInputsCopy?.pipingSizeMax?.toString() ?? "")
    )
      ? null
      : editInputsCopy.pipingSizeMax;
    if (isAdding) {
      response = await MapService.createDeliveryToAirport({
        ...editInputsCopy,
        airportId,
        organizationId,
      });
    } else {
      response = await MapService.updateDeliveryToAirport(
        deliveryToPlane!.id,
        editInputsCopy
      );
    }
    if (typeof response !== "string") {
      let updatedAirport = {
        ...state.activeAirport,
        deliveryToPlane: {
          ...state.activeAirport?.deliveryToPlane,
          items: isAdding
            ? [...(state.activeAirport?.deliveryToPlane?.items ?? []), response]
            : state.activeAirport?.deliveryToPlane?.items?.map(
                (currentDeliveryToPlane) => {
                  if (currentDeliveryToPlane?.id === deliveryToPlane!.id) {
                    return { ...currentDeliveryToPlane, ...response };
                  }
                  return currentDeliveryToPlane;
                }
              ),
        },
      };
      setEditInputs(editInputsCopy);
      dispatch({
        type: mapContextAction.SET_ACTIVE_AIRPORT,
        payload: updatedAirport,
      });
      setIsInEditMode(false);
      if (isAdding) setIsAdding(false);
    } else {
      alert(response);
    }
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "",
    });
  };

  const deleteHandler = async () => {
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "Deleting delivery to airplane entry",
    });
    const { message, type: severity } = await MapService.deleteDeliveryToPlane(
      deliveryToPlane?.id ?? ""
    );
    if (severity === "success") {
      let updatedAirport = {
        ...state.activeAirport,
        deliveryToPlane: {
          ...state.activeAirport?.deliveryToPlane,
          items: state.activeAirport?.deliveryToPlane?.items?.filter(
            (currentDeliveryToPlane) =>
              currentDeliveryToPlane?.id !== deliveryToPlane!.id
          ),
        },
      };
      setIsDeleting(false);
      dispatch({
        type: mapContextAction.SET_ACTIVE_AIRPORT,
        payload: updatedAirport,
      });
    } else {
      alert(message);
    }
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "",
    });
  };

  return (
    <>
      <AirportDetailDrawerDocumentsSectionContent>
        {/* {canUserEditOrgInfo && ( */}
        <AirportDetailDrawerEditIcons>
          {!isInEditMode && (
            <AirportDetailDrawerInfoCardEditIcon
              onClick={() => setIsInEditMode(true)}
            />
          )}
          {!isInEditMode && !isAdding && (
            <AirportDetailDrawerInfoCardDeleteIcon
              onClick={() => setIsDeleting(true)}
            />
          )}
          {isInEditMode && (
            <AirportDetailDrawerInfoCardCloseIcon
              onClick={() => {
                isAdding ? setIsAdding(false) : setIsInEditMode(false);
              }}
            />
          )}
        </AirportDetailDrawerEditIcons>
        {/* )} */}
        {dTPData.map((data) => {
          return (
            <AirportDetailDrawerInfoCard
              key={data.heading}
              role={data.heading}
              isEditable={data.isEditable}
              infoArray={data.infoArray}
              isInEditMode={isInEditMode}
              editInputs={editInputs}
              editInputChangeHandler={editInputChangeHandler}
              containerClassName="data-tab-card"
              infoText={data.infoText}
            />
          );
        })}
        {isInEditMode && (
          <AirportDetailDrawerDocumentsSectionBtnSegnment>
            <AirportDetailDrawerInfoCardSaveButton
              color="info"
              onClick={updateHandler}
            >
              Save Changes
            </AirportDetailDrawerInfoCardSaveButton>
          </AirportDetailDrawerDocumentsSectionBtnSegnment>
        )}
      </AirportDetailDrawerDocumentsSectionContent>
      <ConfirmDeleteModal
        open={isDeleting}
        handleClose={() => setIsDeleting(false)}
        handleDelete={deleteHandler}
      />
    </>
  );
};

export default DeliveryToPlaneTab;
