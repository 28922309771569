import React, { useState, useEffect, FC } from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import DoneIcon from "@mui/icons-material/Done";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

interface IViewNotification {}

interface INotificationCard {
  title: string;
  description: string;
}

const NotificationCard: FC<INotificationCard> = ({ title, description }) => {
  // state
  const [isHover, setHover] = useState<boolean>(false);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", mb: 2 }}
      component={Paper}
      elevation={1}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
        }}
      >
        <CheckCircleOutlineIcon sx={{ color: "green" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          p: 1,
        }}
      >
        <h6 style={{ margin: 0 }}>{title}</h6>
        <p style={{ margin: 0 }}>{description}</p>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 1,
        }}
        component={Paper}
        elevation={isHover ? 1 : 0}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <CloseIcon
          sx={{ cursor: "pointer" }}
          onClick={() => console.log("close alert message.")}
        />
      </Box>
    </Box>
  );
};

const ViewNotification: FC<IViewNotification> = ({}) => {
  // componentDidMount
  useEffect(() => {
    // cleanup
    return () => {};
  }, []);
  return (
    <>
      <h3>Notifications</h3>
      <Box sx={{ textAlign: "center" }}>
        <ButtonGroup
          variant="outlined"
          color="success"
          aria-label="outlined primary button group"
          disableElevation
        >
          <Button
            sx={{
              borderTopLeftRadius: 50,
              borderBottomLeftRadius: 50,
              textTransform: "none",
            }}
          >
            <DoneIcon />
            New
          </Button>
          <Button sx={{ textTransform: "none" }}>Archived</Button>
          <Button
            sx={{
              borderTopRightRadius: 50,
              borderBottomRightRadius: 50,
              textTransform: "none",
            }}
          >
            Deleted
          </Button>
        </ButtonGroup>
      </Box>
      <div style={{ width: "100%", marginTop: "30px" }}>
        <NotificationCard
          title="Notification 1"
          description="Something related to notification one."
        />
        <NotificationCard
          title="Notification 2"
          description="Something related to notification two."
        />
      </div>
    </>
  );
};

export { ViewNotification };
