import React, { FC } from "react";
import TextField from "@mui/material/TextField";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

interface IPasswordFieldInputProps {
  value: any;
  type?: string;
  error: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onKeyDown?: (e: any) => void;
  onKeyDownCapture?: (e: any) => void;
  label: string;
  disabled?: boolean;
  name: string;
  id: string;
  variant?: any;
  required?: boolean;
  helperText?: string;
  color?: any;
  fullWidth?: boolean;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
  margin?: any;
  sx?: any;
}

const PasswordFieldInput: FC<IPasswordFieldInputProps> = (props) => {
  const {
    name,
    id,
    value,
    type = "text",
    error,
    handleChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    onKeyDownCapture = () => {},
    label = "",
    disabled = false,
    variant = "outlined",
    required = false,
    helperText = " ",
    color = "",
    fullWidth = false,
    placeholder = "",
    className = "",
    readOnly = false,
    autoFocus = false,
    autoComplete = "",
    margin = "none",
    sx = {},
  } = props;

  // check error flag
  const errorFlag = error !== "" && error !== undefined ? true : false;
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth={fullWidth} variant={variant}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        type={showPassword ? "text" : "password"}
        label={label}
        name={name}
        value={value}
        placeholder={placeholder}
        className={className}
        error={errorFlag}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onKeyDownCapture={onKeyDownCapture}
        disabled={disabled}
        required={required}
        readOnly={readOnly}
        autoFocus={autoFocus}
      />
      {errorFlag ? (
        <FormHelperText error id={`${id}-error`}>
          {error}
        </FormHelperText>
      ) : (
        <FormHelperText id={`${id}-helpText`}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export { PasswordFieldInput };
