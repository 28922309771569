import AWS from "aws-sdk";
import { IUploadDocument, IDeleteDocument } from "./types";

class S3Service {
  private S3;

  constructor() {
    // configure AWS
    AWS.config.update({
      accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_KEY}`,
    });

    // create aws S3 instace
    this.S3 = new AWS.S3({
      apiVersion: "2006-03-01",
    });
  }

  /*
    Task: get object / file from S3 bucket
  */
  async getObject(Key: string) {
    let params = {
      Bucket: `${process.env.REACT_APP_A4A_DOCUMENT_S3_BUCKET_NAME}`,
      Key: Key,
    };

    return new Promise((resolve, reject) => {
      this.S3.getObject(params, (err: any, data: any) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          // console.log("data", data);
          resolve(data);
        }
      });
    });
  }

  /*
    Task: get signed url to access s3 bucket file
  */
  getSignedUrl(Key: string) {
    let params = {
      Bucket: `${process.env.REACT_APP_A4A_DOCUMENT_S3_BUCKET_NAME}`,
      Key: Key,
    };

    // get pre signed s3 bucket url
    const preSignedUrl = this.S3.getSignedUrl("getObject", params);

    return preSignedUrl;
  }

  /*
    Task: upload file to s3 bucket
  */
  async putObject(Document: IUploadDocument) {
    let params = {
      Body: Document.Body,
      Bucket: `${process.env.REACT_APP_A4A_DOCUMENT_S3_BUCKET_NAME}`,
      Key: Document.Key,
      ServerSideEncryption: "AES256",
      Tagging: Document.Tagging,
    };

    return new Promise((resolve, reject) => {
      this.S3.putObject(params, (err: any, data: any) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          // console.log("data", data);
          resolve(data);
        }
      });
    });
  }

  /*
    Task: delete file to s3 bucket
  */
  async deleteObject(Document: IDeleteDocument) {
    let params = {
      Bucket: `${process.env.REACT_APP_A4A_DOCUMENT_S3_BUCKET_NAME}`,
      Key: Document.Key,
    };

    return new Promise((resolve, reject) => {
      this.S3.deleteObject(params, (err: any, data: any) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          // console.log("data", data);
          resolve(data);
        }
      });
    });
  }
}

export { S3Service };
