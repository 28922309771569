import React, { createContext, useEffect, useReducer } from "react";
import {
  ITrackingLogState,
  ITrackingLogContext,
  ITrackingLogProvider,
} from "./types";
import { useAuth } from "../AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";
import { Analytics } from "aws-amplify";

const trackingLogContextAction = {
  SET_TRACKING_LOG_LIST: "SET_TRACKING_LOG_LIST",
  SET_TRACKING_LOG_LIST_API_LOADING: "SET_TRACKING_LOG_LIST_API_LOADING",
  RESET_STATE: "RESET_STATE",
};

type Action = { type: string; payload: any };

const initialDataState: ITrackingLogState = {
  trackingLogList: [],
  isTrackingLogListApiLoading: true,
};

function dataReducer(
  state: ITrackingLogState,
  action: Action
): ITrackingLogState {
  switch (action.type) {
    case trackingLogContextAction.SET_TRACKING_LOG_LIST:
      return { ...state, trackingLogList: action.payload };
    case trackingLogContextAction.SET_TRACKING_LOG_LIST_API_LOADING:
      return { ...state, isTrackingLogListApiLoading: action.payload };
    case trackingLogContextAction.RESET_STATE:
      return { ...action.payload };
    default:
      return state;
  }
}

const TrackingLogContext = createContext<ITrackingLogContext | undefined>(
  undefined
);

const TrackingLogProvider: React.FC<ITrackingLogProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, initialDataState);
  const { state: authProviderState } = useAuth();
  const navigateTo = useNavigate();
  const location = useLocation();

  // fetch
  useEffect(() => {
    // IIFE
    (async () => {
      if (authProviderState?.isSignedIn) {
        if (!state.trackingLogList.length) {
          // await fetchTrackingLogList([]);
        }
      } else {
        // reset state
        dispatch({
          type: trackingLogContextAction.RESET_STATE,
          payload: initialDataState,
        });
      }
    })();
  }, [authProviderState?.isSignedIn]);

  useEffect(() => {
    if (location?.pathname !== "/auth/verify") {
      if (authProviderState?.isSignedIn) {
        try {
          const log = {
            name: "pageNavigation",
            attributes: {
              path: location?.pathname,
              id: authProviderState?.user?.dbUser?.id,
              name: `${authProviderState?.user?.dbUser?.firstName} ${authProviderState?.user?.dbUser?.lastName}`,
              email: authProviderState?.user?.dbUser?.email,
              cognitoUserId: authProviderState?.user?.dbUser?.cognitoUserId,
              createdAtTimestamp: Date.now().toString(),
              createdAt: new Date().toISOString(),
            },
          };

          Analytics.record(log);
        } catch (error) {
          console.log("Analytics log error: ", error);
        }
      }
    }
  }, [navigateTo]);

  return (
    <TrackingLogContext.Provider value={{ state, dispatch }}>
      {children}
    </TrackingLogContext.Provider>
  );
};

function useTrackingLogContext(): ITrackingLogContext {
  const context = React.useContext(TrackingLogContext);
  if (context === undefined) {
    throw new Error("useDataContext must be used within an DataProvider");
  }
  return context;
}

export { TrackingLogProvider, useTrackingLogContext, trackingLogContextAction };
