import React, { useState, useEffect, FC } from "react";

import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { CognitoService } from "../../services/CognitoService";
import { A4ALoader } from "../Common/A4ALoader";

interface IAddRole {
  closeSidebar: () => void;
  userEmail: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Admin", "Member", "Guest", "Chairman"];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddRole: FC<IAddRole> = ({ closeSidebar, userEmail }) => {
  // state
  const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);
  const [groupNames, setGroupNames] = React.useState<Array<string>>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const theme = useTheme();

  // componentDidMount
  useEffect(() => {
    // IFFE
    (async () => {
      const cs = new CognitoService();

      const data: any = await cs.getListOfUserGroupName();

      setGroupNames(data?.Groups?.map((item: any) => item?.GroupName));
    })();

    // cleanup
    return () => {};
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof selectedRoles>) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // handle assign roles API call
  const handleAssignRoles = async () => {
    const cs = new CognitoService();

    // start api loader
    setLoading(true);

    await Promise.all(
      selectedRoles?.map(async (role: string, idx: number) => {
        await cs.addUserToGroup(role, userEmail);
      })
    );

    // stop api loader
    setLoading(false);
  };

  return (
    <div>
      <h4>Assign roles to {userEmail}.</h4>
      <FormControl sx={{ m: 1, width: "100%" }}>
        <InputLabel id="demo-multiple-chip-label">Roles</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedRoles}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Roles" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {groupNames.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, selectedRoles, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {!loading && (
        <Button
          variant="outlined"
          color="primary"
          sx={{ textTransform: "none", float: "right", my: 2 }}
          onClick={handleAssignRoles}
        >
          Assign
        </Button>
      )}
      {loading && <A4ALoader />}
    </div>
  );
};

export { AddRole };
