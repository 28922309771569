import { useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

import { TextFieldInput } from "../Common/TextFieldInput";
import { AuthSchema } from "../../validations/schema";
import { helpers, constants } from "../../utils";
import { A4ALoader } from "../Common/A4ALoader";

import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { UpdatePassword } from "./UpdatePassword";
import { toast } from "react-toastify";

// default state
const resetPasswordDefaultState = {
  email: "",
};

const ResetPassword = () => {
  // state
  const [formData, setFormData] = useState<any>({
    ...resetPasswordDefaultState,
  });
  const [error, setError] = useState<any>({ ...resetPasswordDefaultState });
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [sendTo, setSendTo] = useState<string>("");
  const [authError, setAuthError] = useState<string>("");
  const [authStatus, setAuthStatus] = useState<string>("");

  // componentDidMount
  useEffect(() => {
    document.title = constants.PAGE_TITLE.FORGOT_PASSWORD;
    // componentWillUnmount
    return () => {};
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value.toLowerCase();

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setError({ ...signUpDefaultState });

    let result: any = {};

    result = AuthSchema.forgotPasswordValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    var isValidationError = false;

    // clear send to email address
    setSendTo("");
    setAuthError("");
    setAuthStatus("");

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      const isValid = handleValidation(key, formData[key]);

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    if (!isValidationError) {
      // start api loader
      setApiLoading(true);

      try {
        // send verification code to email
        await Auth.forgotPassword(formData.email);

        // set auth status to send
        setAuthStatus("send");

        // show toast message
        toast.success(`Verification code sent on ${formData.email} email.`, {
          theme: "colored",
        });
      } catch (error: any) {
        // set auth errors
        setAuthError(
          "Sorry, your email address is not registered with us. To request access, please email your Fuel Consortia Chair/GM or A4A Energy Council representative. Thank you."
        );
      } finally {
        // set send to email
        setSendTo(formData.email);

        // clear form data
        setFormData({ ...resetPasswordDefaultState });
        setError({ ...resetPasswordDefaultState });

        // stop api loader
        setApiLoading(false);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        elevation={0}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LoginIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box
          // component="form"
          // onSubmit={handleSubmit}
          // noValidate
          sx={{ mt: 1 }}
          elevation={0}
        >
          {authError !== "" && <Alert severity="error">{authError}</Alert>}
          {authStatus === "send" && <UpdatePassword userEmail={sendTo} />}
          {authStatus === "" && (
            <form onSubmit={handleSubmit}>
              <TextFieldInput
                id="email"
                name="email"
                placeholder="Email Address"
                label="Email Address"
                error={error.email}
                value={formData.email}
                helperText="Verification code will be sent to this registered email."
                autoComplete="email"
                margin="normal"
                handleChange={handleChange}
                onBlur={() => handleValidation("email", formData.email)}
                // autoFocus
                fullWidth
                required
              />
              {apiLoading && <A4ALoader />}
              {!apiLoading && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, textTransform: "none" }}
                  onClick={handleSubmit}
                  disabled={apiLoading}
                >
                  Request Code
                </Button>
              )}
              <Grid container>
                <Grid item>
                  <Link to="/auth/in">{"have an account? Sign-in"}</Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export { ResetPassword };
