import { API, Auth } from "aws-amplify";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import {
  Calendar,
  CalendarEventType,
  CreateCalendarEventTypeMutation,
  CreateCalendarMutation,
  DeleteCalendarMutation,
  ListCalendarEventTypesQuery,
  ListCalendarsQuery,
  UpdateCalendarMutation,
} from "../../API";
import { filterObjectByKeys } from "../../utils/helpers";

const __mock = false;

interface IListCalendarResponse {
  calendars: Calendar[];
  nextToken: string | null;
}

interface IListCalendarTypeResponse {
  listCalendarEventTypes: CalendarEventType[];
  nextToken: string | null;
}

const listCalendars = async (
  token: string,
  nextToken: string | null
): Promise<IListCalendarResponse> => {
  try {
    const response = (await API.graphql({
      query: queries.listCalendars,
      variables: {
        nextToken,
      },
    })) as { data: ListCalendarsQuery; errors: any[] };
    if (response.data.listCalendars?.items) {
      return {
        calendars: response.data.listCalendars?.items as Calendar[],
        nextToken: response.data.listCalendars?.nextToken ?? null,
      };
    }
    return { nextToken: null, calendars: [] };
  } catch (error) {
    console.log(error);
    return { nextToken: null, calendars: [] };
  }
};

const listCalendarTypes = async (
  token: string,
  nextToken: string | null
): Promise<IListCalendarTypeResponse> => {
  try {
    const response = (await API.graphql({
      query: queries.listCalendarEventTypes,
      variables: {
        nextToken,
      },
    })) as { data: ListCalendarEventTypesQuery; errors: any[] };
    if (response.data.listCalendarEventTypes?.items) {
      return {
        listCalendarEventTypes: response.data.listCalendarEventTypes
          ?.items as CalendarEventType[],
        nextToken: response.data.listCalendarEventTypes?.nextToken ?? null,
      };
    }
    return { nextToken: null, listCalendarEventTypes: [] };
  } catch (error) {
    console.log(error);
    return { nextToken: null, listCalendarEventTypes: [] };
  }
};

const createCalendarEvent = async (
  input: Partial<Calendar>
): Promise<Partial<Calendar> | string> => {
  try {
    const response = (await API.graphql({
      query: mutations.createCalendar,
      variables: { input },
    })) as { data: CreateCalendarMutation; errors: any[] };
    if (response.data.createCalendar) {
      return response.data.createCalendar as Calendar;
    } else {
      return response.errors[0];
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while adding event."
    );
  }
};

const updateCalendarEvent = async (
  id: string,
  updatedFields: Partial<Calendar>
): Promise<Partial<Calendar> | string> => {
  const inputVariables = {
    id,
    ...updatedFields,
  };
  try {
    const response = (await API.graphql({
      query: mutations.updateCalendar,
      variables: { input: inputVariables },
    })) as { data: UpdateCalendarMutation; errors: any[] };
    if (response.data.updateCalendar) {
      return filterObjectByKeys(
        {
          ...updatedFields,
          organization: {},
          calendarEventType: {},
          updatedAt: '',
          modifiedByUserId: '',
          modifiedBy: {},
        },
        response.data.updateCalendar
      );
    } else {
      return "An error occured while updating Event.";
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while updating information."
    );
  }
};

const deleteCalendarEvent = async (
  id: string
): Promise<{ type: "success" | "error"; message: string }> => {
  try {
    const response = (await API.graphql({
      query: mutations.deleteCalendar,
      variables: { input: { id } },
    })) as { data: DeleteCalendarMutation; errors: any[] };
    if (response.data.deleteCalendar) {
      return { type: "success", message: "Deleted event successfully." };
    } else {
      return { type: "error", message: response.errors[0] };
    }
  } catch (error) {
    let errResponse = error as any;
    return {
      type: "error",
      message:
        errResponse?.errors?.[0]?.message ??
        "An error occured while deleting event.",
    };
  }
};

const createCalendarEventType = async (
  input: Partial<CalendarEventType>
): Promise<Partial<CalendarEventType> | string> => {
  try {
    const response = (await API.graphql({
      query: mutations.createCalendarEventType,
      variables: { input },
    })) as { data: CreateCalendarEventTypeMutation; errors: any[] };
    if (response.data.createCalendarEventType) {
      return response.data.createCalendarEventType as CalendarEventType;
    } else {
      return response.errors[0];
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while adding event type."
    );
  }
};

export default {
  listCalendars,
  listCalendarTypes,
  createCalendarEvent,
  createCalendarEventType,
  updateCalendarEvent,
  deleteCalendarEvent,
};
