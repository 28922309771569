import joi from "joi";

// create company params
// validation schema
const createCompanyValidationSchema: any = {
  name: joi.string().trim().required().messages({
    "string.empty": "Company name cannot be empty.",
    "any.required": "Company name required.",
  }),
  type: joi
    .string()
    .trim()
    .required()
    // .valid("AIRLINE", "OPERATOR", "ENGINEER", "PIPELINE", "SUPPLIER", "INSURANCE", "LEGAL", "CONSULTANT",)
    .messages({
      "string.empty": "Company type cannot be empty.",
      "any.required": "Company type required.",
      // "any.only": "Invalid organization type.",
    }),
};

export { createCompanyValidationSchema };
