import joi from "joi";

// create document Category params
// validation schema
const createDocumentCategoryValidationSchema: any = {
  name: joi.string().trim().required().messages({
    "string.empty": "Document category name cannot be empty.",
    "any.required": "Document category name required.",
  }),
  type: joi.string().trim().required().messages({
    "string.empty": "Document category type cannot be empty.",
    "any.required": "Document category type required.",
  }),
  description: joi.string().trim().required().messages({
    "string.empty": "Document category description cannot be empty.",
    "any.required": "Document category description required.",
  }),
};

export { createDocumentCategoryValidationSchema };
