import React from "react";
import { Button, Box, Alert } from "@mui/material";
import { A4ALoader } from "../Common/A4ALoader";
import { MailService } from "../../services/MailService";
import { helpers } from "../../utils";
import { Auth } from "aws-amplify";
import { UserService } from "../../services/UserService";
import { v4 as uuidv4 } from "uuid";
import { InviteLogService } from "../../services/InviteLogService";
import { CognitoService } from "../../services/CognitoService";
import { toast } from "react-toastify";
import { EmailListInput } from "./../Common/EmailListInput";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import { useAuth } from "./../AuthProvider/AuthProvider";

// prop types
interface IInviteOrganizationAdmin {
  selectedCompany?: string;
  organization: any;
  closeSidebar: () => void;
}

const InviteOrganizationAdmin: React.FC<IInviteOrganizationAdmin> = ({
  organization,
  closeSidebar,
  selectedCompany,
}) => {
  const { state, dispatch } = useMapContext();
  const [organizationAdmins, setOrganizationAdmins] = React.useState<
    Array<string>
  >([]);
  const [successMessage, setSuccessMessage] = React.useState<string>("");
  const [organizationAdminsError, setOrganizationAdminsError] =
    React.useState<string>("");
  const [apiLoading, setApiLoading] = React.useState<boolean>(false);
  const { state: authProviderState } = useAuth();

  React.useEffect(() => {
    // cleanup
    return () => {};
  }, []);

  // Send organization admin invite emails
  const handleSendOrgAdminInvite = async () => {
    var isValidationError = false;

    // clear state
    setSuccessMessage("");
    setOrganizationAdminsError("");

    // if admin email list empty
    if (organizationAdmins.length === 0) {
      isValidationError = true;
      setOrganizationAdminsError("Add at least one admin email.");
    }

    if (!isValidationError) {
      const ms = new MailService();
      const cs = new CognitoService();

      // user service
      const us = new UserService();
      const ils = new InviteLogService();

      // start api loader
      setApiLoading(true);

      var inviteMaillist: any = [];

      if (organizationAdmins.length !== 0) {
        await Promise.all(
          organizationAdmins.map(async (tempUser: any) => {
            let tempData: any = {};

            try {
              // generate random password
              let password = helpers.getRandomPassword();

              // sign-up new user
              const data: any = await Auth.signUp({
                username: tempUser?.email,
                password: password,
                attributes: {
                  "custom:temporaryPassword": password,
                  "custom:isPasswordReset": "no",
                },
              });

              tempData = {
                ...tempData,
                cognitoUserId: `${data?.userSub}`,
                temporaryPassword: password,
                isPasswordReset: "no",
              };
            } catch (error: any) {
              // get error type
              const errorType = error.toString().split(":")[0];

              // if user already exists.
              if (errorType === "UsernameExistsException") {
                // get already exists cognito user data
                const u_data: any = await cs.adminGetUser(tempUser?.email);

                // get cognito user custom attributes
                const attributes: any =
                  helpers.getFormattedCognitoUserAttributes(
                    u_data?.UserAttributes
                  );

                tempData = {
                  ...tempData,
                  cognitoUserId: attributes["sub"],
                  temporaryPassword: attributes["custom:temporaryPassword"],
                  isPasswordReset: attributes["custom:isPasswordReset"],
                };
              }
            }

            // check user email already exist in User table
            const dbUser: any = await us.getUserByEmail(tempUser?.email);

            let emailPayload = {
              email: tempUser?.email,
              userId: "",
              temporaryPassword: tempData?.temporaryPassword,
              isPasswordReset: tempData?.isPasswordReset,
            };

            if (dbUser) {
              if (dbUser?.cognitoUserId === null) {
                const userParams = {
                  id: dbUser?.id,
                  cognitoUserId: tempData?.cognitoUserId,
                };

                // update user data
                await us.updateUserById(userParams);
              }

              emailPayload = {
                ...emailPayload,
                userId: dbUser?.id,
              };
            } else {
              const userParams = {
                cognitoUserId: tempData?.cognitoUserId,
                email: tempUser?.email,
                firstName: tempUser?.firstName,
                lastName: tempUser?.lastName,
                companyId: tempUser?.company !== "" ? tempUser?.company : null,
                mobile: tempUser?.mobile !== "" ? tempUser?.mobile : null,
                quarterlyEmailNotification: true
              };

              // create database entry for new user
              const user = await us.createUser(userParams);

              dispatch({
                type: mapContextAction.SET_USER_LIST,
                payload: [...state.userList, user],
              });

              emailPayload = {
                ...emailPayload,
                userId: user?.id,
              };
            }

            // add email params
            inviteMaillist.push(emailPayload);
          })
        );
      }

      // send invite mail to each user
      if (inviteMaillist.length !== 0) {
        await Promise.all(
          inviteMaillist.map(async (payload: any) => {
            try {
              // check invitation is already sent or not
              const filter = {
                organizationId: { eq: organization?.id },
                userId: { eq: payload?.userId },
                type: { eq: "ADMIN" },
                deleted: { ne: true },
              };

              const invites: any = await ils.recursiveFetchListInviteLogs(
                filter
              );
              let inviteId = "";

              if (invites?.length === 0) {
                // create invite uuid
                inviteId = uuidv4();

                // create invite log api payload
                const inviteLogParams = {
                  id: inviteId,
                  userId: payload?.userId,
                  organizationId: organization?.id,
                  type: "ADMIN",
                  isAccepted: false,
                  sendByUserId: authProviderState?.user?.dbUserId,
                };

                // create invite log
                await ils.createInviteLog(inviteLogParams);
              } else {
                // set invite id from database
                inviteId = invites[0]?.id;
              }

              // create api body
              const body = {
                email: payload?.email,
                organizationName: organization?.name,
                inviteType: "Admin",
                inviteId: inviteId,
                temporaryPassword: payload?.temporaryPassword,
                isPasswordReset: payload?.isPasswordReset,
                sender: {
                  firstName: authProviderState?.user?.dbUser?.firstName,
                  lastName: authProviderState?.user?.dbUser?.lastName,
                  company: authProviderState?.user?.dbUser?.company?.name ?? "",
                },
              };

              await ms.sendOrgInviteMail(body);
            } catch (error: any) {
              console.log(error);
            }
          })
        );
      }

      // final cleanup
      setOrganizationAdmins([]);
      // setSuccessMessage("Consortium admins invite sent.");
      setOrganizationAdminsError("");

      // stop api loader
      setApiLoading(false);

      // show toast message
      toast.success("Admin invitation sent.", { theme: "colored" });

      // close sidebar
      closeSidebar();
    }
  };

  return (
    <div>
      <p>Let's invite admins for {organization?.name}.</p>
      <EmailListInput
        selectedCompany={selectedCompany}
        emailList={organizationAdmins}
        setEmailList={setOrganizationAdmins}
        error={organizationAdminsError}
        placeholder="Add some admins."
      />
      {!apiLoading && organizationAdmins?.length !== 0 && (
        <Button
          onClick={handleSendOrgAdminInvite}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none" }}
        >
          {/* <InsertInvitationIcon /> */}
          Invite
        </Button>
      )}
      {!apiLoading && (
        <Button
          onClick={closeSidebar}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none", mx: 1 }}
          color="error"
        >
          {/* <InsertInvitationIcon /> */}
          Cancel
        </Button>
      )}
      {apiLoading ? (
        <Box sx={{ mt: 2 }}>
          <A4ALoader />
        </Box>
      ) : (
        ""
      )}

      {successMessage !== "" && (
        <Alert severity="success" sx={{ mt: 10 }}>
          {successMessage}
        </Alert>
      )}
    </div>
  );
};

export { InviteOrganizationAdmin };
