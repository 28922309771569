import React, { useState } from "react";
import {
  EventDetailSidebarContainer,
  EventDetailSidebarHeading,
} from "./style";
import { Calendar } from "../../API";
import dayjs from "dayjs";
import AirportDetailDrawerInfoCard from "../AiportDetailsDrawer/AirportDetailDrawerInfoCard";
import { generalTabFieldType, generalTabFields } from "../../constants/map";
import { Alert, AlertColor, Box, Grid } from "@mui/material";
import {
  calendarContextAction,
  useCalendarContext,
} from "../CalendarProvider/CalendarProvider";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { useDataContext } from "../DataProvider/DataProvider";
import {
  AirportDetailDrawerEditIcons,
  AirportDetailDrawerInfoCardCloseIcon,
  AirportDetailDrawerInfoCardEditIcon,
  AirportDetailDrawerInfoCardSaveButton,
} from "../AiportDetailsDrawer/style";
import CalendarService from "../../services/CalendarService";
import { useMapContext } from "../MapProvider/MapProvider";
import { DateTime } from "luxon";

type IEventDetailSidebar = {
  event: Calendar;
  setActiveEvent: Function;
};

export interface IEventEditInputs {
  name?: string;
  startDateTime?: string;
  endDateTime?: string;
  description?: string;
  calendarEventTypeId?: string;
  organizer?: string;
  // organizationId?: string;
  location?: string;
  venue?: string;
  venueWebSite?: string;
  website?: string;
  groupRateCode?: string;
  specialRates?: boolean;
}

export const fields = {
  NAME: "name",
  START_DATE_TIME: "startDateTime",
  END_DATE_TIME: "endDateTime",
  DESCRIPTION: "description",
  CALENDAR_EVENT_TYPE_ID: "calendarEventTypeId",
  CREATED_AT: "createdAt",
  CREATED_BY: "createdBy",
  MODIFIED_AT: "modifiedAt",
  MODIFIED_BY: "modifiedBy",
  ORGANIZER: "organizer",
  // ORGANIZATION_ID: "organizationId",
  LOCATION: "location",
  VENUE: "venue",
  VENUE_WEBSITE: "venueWebSite",
  WEBSITE: "website",
  GROUP_RATE_CODE: "groupRateCode",
  SPECIAL_RATES: "specialRates",
} as const;

const EventDetailSidebar = ({ event, setActiveEvent }: IEventDetailSidebar) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { state, dispatch } = useCalendarContext();
  const { state: mapState } = useMapContext();
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [eventEditInputs, setEventEditInputs] = useState<IEventEditInputs>({
    name: event?.name ?? "",
    startDateTime:
      DateTime.fromISO(event?.startDateTime ?? "", {
        zone: userTimeZone,
      })
        ?.toISO()
        ?.slice(0, 16) ?? "",
    endDateTime:
      DateTime.fromISO(event?.endDateTime ?? "", {
        zone: userTimeZone,
      })
        ?.toISO()
        ?.slice(0, 16) ?? "",
    description: event?.description ?? "",
    calendarEventTypeId: event?.calendarEventTypeId ?? "",
    organizer: event?.organizer ?? "",
    // organizationId: event?.organizationId ?? "",
    location: event?.location ?? "",
    venue: event?.venue ?? "",
    venueWebSite: event?.venueWebSite ?? "",
    website: event?.website ?? "",
    groupRateCode: event?.groupRateCode ?? "",
    specialRates: event?.specialRates ?? false,
  });
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: AlertColor;
  }>({
    message: "",
    severity: "success",
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const detailSectionData = [
    {
      heading: "Name",
      infoArray: [
        {
          name: fields.NAME,
          value: event?.name ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Start date time",
      infoArray: [
        {
          name: fields.START_DATE_TIME,
          value: event?.startDateTime
            ? dayjs(
                DateTime.fromISO(event?.startDateTime, {
                  zone: userTimeZone,
                }).toJSDate()
              )?.format("MMMM DD, YYYY hh:mm:ss A")
            : "",
          type: generalTabFieldType.DATE_TIME,
        },
      ],
    },
    {
      heading: "End date time",
      infoArray: [
        {
          name: fields.END_DATE_TIME,
          value: event?.endDateTime
            ? dayjs(
                DateTime.fromISO(event?.endDateTime, {
                  zone: userTimeZone,
                }).toJSDate()
              )?.format("MMMM DD, YYYY hh:mm:ss A")
            : "",
          type: generalTabFieldType.DATE_TIME,
        },
      ],
    },
    {
      heading: "Event description",
      infoArray: [
        {
          name: fields.DESCRIPTION,
          value: event?.description ?? "",
          type: generalTabFieldType.TEXT_MULTIPLE,
          placeHolder: "Enter description",
        },
      ],
    },
    {
      heading: "Type",
      infoArray: [
        {
          name: fields.CALENDAR_EVENT_TYPE_ID,
          value: event?.calendarEventType?.name ?? "",
          type: generalTabFieldType.ENUM,
          placeHolder: "Type",
          enumOptions: state.calendarTypeList
            .map((type) => ({
              name: type.name,
              value: type.id,
            }))
            .sort((a, b) => a.name.localeCompare(b.name)),
        },
      ],
    },
    // {
    //   heading: "Type description",
    //   isEditable: false,
    //   infoArray: [
    //     {
    //       name: "typeDescription",
    //       value: event?.calendarEventType?.description ?? "",
    //       type: generalTabFieldType.TEXT,
    //     },
    //   ],
    // },
    {
      heading: "Organizer",
      infoArray: [
        {
          name: fields.ORGANIZER,
          value: event?.organizer ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Enter organizer",
        },
      ],
    },
    // {
    //   heading: "Organization",
    //   infoArray: [
    //     {
    //       name: fields.ORGANIZATION_ID,
    //       value: event?.organization?.name ?? "",
    //       type: generalTabFieldType.ENUM,
    //       placeHolder: "Organization",
    //       enumOptions: dataState.organizationList.map((organization) => ({
    //         name: organization.name,
    //         value: organization.id,
    //       })),
    //     },
    //   ],
    // },
    {
      heading: "Location",
      infoArray: [
        {
          name: fields.LOCATION,
          value: event?.location ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Enter location",
        },
      ],
    },
    {
      heading: "Venue",
      infoArray: [
        {
          name: fields.VENUE,
          value: event?.venue ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Enter venue",
        },
      ],
    },
    {
      heading: "Venue website",
      infoArray: [
        {
          name: fields.VENUE_WEBSITE,
          value: event?.venueWebSite ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Enter venue website",
        },
      ],
    },
    {
      heading: "Event website",
      infoArray: [
        {
          name: fields.WEBSITE,
          value: event?.website ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Enter website",
        },
      ],
    },
    {
      heading: "Group rate code",
      infoArray: [
        {
          name: fields.GROUP_RATE_CODE,
          value: event?.groupRateCode ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Enter group rate code",
        },
      ],
    },
    {
      heading: "Special Rates",
      infoArray: [
        {
          name: fields.SPECIAL_RATES,
          value: event?.specialRates ?? false ? "Yes" : "No",
          type: generalTabFieldType.BOOLEAN,
          placeHolder: "Enter group rate code",
        },
      ],
    },
    {
      heading: "Created at",
      isEditable: false,
      infoArray: [
        {
          name: fields.CREATED_AT,
          value: event?.createdAt
            ? dayjs(event?.createdAt)?.format("MMMM DD, YYYY hh:mm:ss A")
            : "",
          type: generalTabFieldType.DATE_TIME,
        },
      ],
    },
    {
      heading: "Created by",
      isEditable: false,
      infoArray: [
        {
          name: fields.CREATED_BY,
          value: `${event?.createdBy?.firstName ?? ""} ${
            event?.createdBy?.lastName ?? ""
          }`,
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Modified at",
      isEditable: false,
      infoArray: [
        {
          name: fields.MODIFIED_AT,
          value: event?.updatedAt
            ? dayjs(event?.updatedAt)?.format("MMMM DD, YYYY hh:mm:ss A")
            : "",
          type: generalTabFieldType.DATE_TIME,
        },
      ],
    },
    {
      heading: "Modified by",
      isEditable: false,
      infoArray: [
        {
          name: fields.MODIFIED_BY,
          value: `${event?.modifiedBy?.firstName ?? ""} ${
            event?.modifiedBy?.lastName ?? ""
          }`,
          type: generalTabFieldType.TEXT,
        },
      ],
    },
  ];

  const editInputChangeHandler = (
    name: keyof any,
    value: string,
    type?: generalTabFieldType
  ) => {
    let finalValue: string = value;
    if (type === generalTabFieldType.DATE) {
      finalValue = new Date(finalValue as string).toISOString();
    }
    setEventEditInputs((prevState) => ({
      ...prevState,
      [name]: finalValue,
    }));
  };

  const formValidator = (): boolean => {
    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const websiteRegex = new RegExp(expression);
    if (!eventEditInputs?.name?.trim()) {
      setAlertMessage({
        message: "Name is required.",
        severity: "error",
      });
      return false;
    }
    if (!eventEditInputs?.startDateTime?.trim()) {
      setAlertMessage({
        message: "Start date time is required.",
        severity: "error",
      });
      return false;
    }
    if (!eventEditInputs?.endDateTime?.trim()) {
      setAlertMessage({
        message: "End date time is required.",
        severity: "error",
      });
      return false;
    }
    if (eventEditInputs?.endDateTime! < eventEditInputs?.startDateTime!) {
      setAlertMessage({
        message: "End date time can not be before start date.",
        severity: "error",
      });
      return false;
    }
    if (!eventEditInputs?.calendarEventTypeId?.trim()) {
      setAlertMessage({
        message: "Type is required.",
        severity: "error",
      });
      return false;
    }
    if (
      eventEditInputs.website &&
      eventEditInputs.website.toLowerCase() !== "none" &&
      !eventEditInputs.website.match(websiteRegex)
    ) {
      setAlertMessage({
        message: "Please enter valid event website URL.",
        severity: "error",
      });
      return false;
    }
    if (
      eventEditInputs.venueWebSite &&
      eventEditInputs.venueWebSite.toLowerCase() !== "none" &&
      !eventEditInputs.venueWebSite.match(websiteRegex)
    ) {
      setAlertMessage({
        message: "Please enter valid venue website URL.",
        severity: "error",
      });
      return false;
    }
    return true;
  };

  const editEventHandler = async () => {
    setIsEditing(true);
    try {
      if (!formValidator()) {
        return;
      }
      const response = await CalendarService.updateCalendarEvent(event.id, {
        ...eventEditInputs,
        startDateTime: new Date(
          eventEditInputs?.startDateTime ?? ""
        ).toISOString(),
        endDateTime: new Date(eventEditInputs?.endDateTime ?? "").toISOString(),
        modifiedByUserId: mapState?.currentUserDbData?.id ?? "",
      });
      if (typeof response !== "string") {
        dispatch({
          type: calendarContextAction.SET_CALENDAR_LIST,
          payload: state.calendarList.map((calendar) => {
            if (calendar.id === event.id) {
              return { ...calendar, ...response };
            }
            return calendar;
          }),
        });
        setActiveEvent({ ...event, ...response });
        setAlertMessage({
          message: "Updated event successfully",
          severity: "success",
        });
        setIsInEditMode(false);
      } else {
        setAlertMessage({
          message: response ?? "An error occured while updating event",
          severity: "error",
        });
      }
    } catch (e) {
      setAlertMessage({
        message: "An error occured while updating event",
        severity: "error",
      });
    } finally {
      setIsEditing(false);
    }
  };

  return (
    <EventDetailSidebarContainer>
      <EventDetailSidebarHeading>{event?.name}</EventDetailSidebarHeading>
      <AirportDetailDrawerEditIcons>
        {!isInEditMode && (
          <AirportDetailDrawerInfoCardEditIcon
            onClick={() => setIsInEditMode(true)}
          />
        )}

        {/* {isInEditMode && (
          <AirportDetailDrawerInfoCardCloseIcon
            onClick={!isEditing ? () => setIsInEditMode(false) : undefined}
          />
        )} */}
      </AirportDetailDrawerEditIcons>
      <Grid container spacing={2} mt={0}>
        {detailSectionData.map((sectionData) => {
          return (
            <Grid item sm={6} md={6} lg={4} xl={4} key={sectionData.heading}>
              <AirportDetailDrawerInfoCard
                key={sectionData.heading}
                role={sectionData.heading}
                infoArray={sectionData.infoArray}
                isEditable={sectionData.isEditable}
                isInEditMode={isInEditMode}
                editInputs={eventEditInputs}
                editInputChangeHandler={editInputChangeHandler}
                containerClassName="event-info-card"
              />
            </Grid>
          );
        })}
      </Grid>
      {isInEditMode && (
        <>
          <AirportDetailDrawerInfoCardSaveButton
            color="info"
            size="medium"
            variant="text"
            sx={{ float: "right", mt: 2 }}
            onClick={editEventHandler}
            disabled={isEditing}
          >
            {!isEditing ? "Save Changes" : "Saving Changes"}
          </AirportDetailDrawerInfoCardSaveButton>
          <AirportDetailDrawerInfoCardSaveButton
            color="info"
            size="medium"
            variant="text"
            sx={{ float: "right", mt: 2, mr: 2 }}
            onClick={() => setIsInEditMode(false)}
            disabled={isEditing}
          >
            Cancel
          </AirportDetailDrawerInfoCardSaveButton>
        </>
      )}
      {isInEditMode && <Box sx={{ width: "100%", height: "2rem" }} />}
      {!!alertMessage.message && (
        <Alert severity={alertMessage.severity} sx={{ mt: 10 }}>
          {alertMessage.message}
        </Alert>
      )}
    </EventDetailSidebarContainer>
  );
};

export default EventDetailSidebar;
