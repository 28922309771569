import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { CardContent, Typography } from "@mui/material";
import { constants } from "../../utils";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import IconButton from "@mui/material/IconButton";
import AppsIcon from "@mui/icons-material/Apps";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
import * as SkeletonLoader from "../SkeletonLoader";
import dayjs from "dayjs";
import { DataTableBase } from "./../Common/DataTableBase";
import {
  GridFolderContainer,
  GridFolderIcon,
  GridFolderTitle,
  ListFolderIcon,
  ListFolderTitle,
} from "./style";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AirportDetailsDrawerAddFolderModal from "../AiportDetailsDrawer/AirportDetailsDrawerAddFolderModal";
import { DocumentBucketTypeChoice } from "../../API";
import { useAuth } from "../AuthProvider";

interface IGetGridView {
  filteredFolderList: Array<any>;
  handleCheckNewContent: any;
  documentList: any;
  categoryIdNameMapping?: any;
}

interface IGetListView {
  filteredFolderList: Array<any>;
  tableTitle: string;
  handleCheckNewContent: any;
  documentList: any;
  categoryIdNameMapping?: any;
}

const GetGridView: React.FC<IGetGridView> = ({
  filteredFolderList = [],
  handleCheckNewContent,
  documentList,
  categoryIdNameMapping,
}) => {
  const navigate = useNavigate();
  return (
    <Grid sx={{ mt: 2 }} container gap={2}>
      {filteredFolderList.map((category: any, idx: number) => (
        <GridFolderContainer
          onClick={() => navigate(`/doc/category/${category}`)}
        >
          {handleCheckNewContent(category) ? (
            <Badge color="primary" badgeContent=" " variant="dot">
              <GridFolderIcon />
            </Badge>
          ) : (
            <GridFolderIcon />
          )}
          <GridFolderTitle>
            {categoryIdNameMapping[category] ?? "Unknown Folder"} (
            {documentList[category].length})
          </GridFolderTitle>
        </GridFolderContainer>
      ))}
    </Grid>
  );
};

const GetMemomizedListView: React.FC<IGetListView> = React.memo(
  ({
    filteredFolderList = [],
    handleCheckNewContent,
    documentList,
    tableTitle = "Table Data",
    categoryIdNameMapping,
  }) => {
    const navigate = useNavigate();
    const columns = [
      {
        name: "Category",
        selector: (row: any) => row?.category,
        cell: (row: any) =>
          handleCheckNewContent(row?.category) ? (
            <Box
              component={Paper}
              elevation={0}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                background: "transparent",
              }}
              onClick={() => navigate(`/doc/category/${row?.category}`)}
            >
              <Badge color="primary" badgeContent=" " variant="dot">
                <ListFolderIcon />
              </Badge>
              <ListFolderTitle>
                {categoryIdNameMapping?.[row?.category] ?? "Unknown Folder"}
              </ListFolderTitle>
            </Box>
          ) : (
            <Box
              component={Paper}
              elevation={0}
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                background: "transparent",
              }}
              onClick={() => navigate(`/doc/category/${row?.category}`)}
            >
              <ListFolderIcon />{" "}
              <ListFolderTitle>
                {categoryIdNameMapping?.[row?.category] ?? "Unknown Folder"}
              </ListFolderTitle>
            </Box>
          ),
        sortable: true,
      },
      {
        name: "Total Files",
        grow: 0.3,
        selector: (row: any) => row?.totalFiles,
        sortable: true,
      },
    ];

    let tableData: Array<any> = filteredFolderList?.map(
      (category: string, idx: number) => ({
        id: `${category}_tbl_${idx + 1}`,
        category: category,
        totalFiles: documentList[category]?.length,
      })
    );

    // sort user data
    tableData.sort((itemA: any, itemB: any) => {
      if (
        (categoryIdNameMapping?.[itemA.category] ?? "Unknown Folder") <
        (categoryIdNameMapping?.[itemB.category] ?? "Unknown Folder")
      ) {
        return -1;
      }
      if (
        (categoryIdNameMapping?.[itemA.category] ?? "Unknown Folder") >
        (categoryIdNameMapping?.[itemB.category] ?? "Unknown Folder")
      ) {
        return 1;
      }
      return 0;
    });

    return (
      <DataTableBase
        columns={columns}
        data={tableData}
        dense={false}
        fixedHeader
        pagination={false}
        persistTableHead
        highlightOnHover
      />
    );
  }
);

const ViewDocument = () => {
  const {
    state: { user },
  } = useAuth();
  // state
  const [filteredDocList, setFilteredDocList] = React.useState<Array<string>>(
    []
  );
  const [categoryIdNameMapping, setCategoryIdNameMapping] = useState<any>({});
  const [isAddFolderModalOpened, setIsAddFolderModalOpened] =
    useState<boolean>(false);
  // show data as List or Grid Item
  const [showDataAs, setShowDataAs] = React.useState<string>("list");

  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();

  useEffect(() => {
    if (dataProviderState.documentCategoryList.length) {
      const tempCategoryIdNameMapping: any = {};
      dataProviderState.documentCategoryList.forEach((category) => {
        tempCategoryIdNameMapping[category.id] = category.name;
      });
      setCategoryIdNameMapping(tempCategoryIdNameMapping);
    }
  }, [dataProviderState.documentCategoryList]);

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.DOCUMENT;

    // clear global search bar text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
      payload: "",
    });

    // set global search bar placeholder text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT,
      payload: "Search global documents...",
    });

    // cleanup
    return () => {};
  }, []);

  useEffect(() => {
    if (dataProviderState?.topBarSearchText.trim()) {
      let keys = Object.keys(dataProviderState?.globalDocumentList);

      // sort documents category
      keys.sort((itemA: any, itemB: any) => {
        if (itemA < itemB) {
          return -1;
        }
        if (itemA > itemB) {
          return 1;
        }
        return 0;
      });

      let searchText = dataProviderState?.topBarSearchText;

      if (searchText.trim() !== "") {
        let matchedKey: Array<any> = [];

        keys?.forEach((key) => {
          const isMatched =
            dataProviderState?.globalDocumentList[key as any]?.filter(
              (document: any) =>
                document?.url
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                document?.title
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                document?.description
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length !== 0;

          if (isMatched) {
            matchedKey.push(key);
          }
        });

        const folderMacthed = keys?.filter((item) =>
          item.toLowerCase().includes(searchText.toLowerCase())
        );

        const searchResults = [...matchedKey, ...folderMacthed];

        // remove duplicates from array
        const unique = searchResults.filter((key, index) => {
          return index === searchResults.findIndex((itm) => key === itm);
        });

        // udpate document category list
        setFilteredDocList([...unique]);
      } else {
        // udpate document list
        setFilteredDocList([...keys]);
      }
    } else {
      let keys = Object.keys(dataProviderState?.globalDocumentList);
      if (keys?.length) {
        setFilteredDocList([...keys]);
      } else {
        setFilteredDocList([]);
      }
    }
  }, [dataProviderState?.topBarSearchText]);

  useEffect(() => {
    let keys = Object.keys(dataProviderState?.globalDocumentList);

    // sort documents category
    keys.sort((itemA: any, itemB: any) => {
      if (itemA < itemB) {
        return -1;
      }
      if (itemA > itemB) {
        return 1;
      }
      return 0;
    });

    if (keys?.length) {
      setFilteredDocList([...keys]);
    } else {
      setFilteredDocList([]);
    }
  }, [dataProviderState?.globalDocumentList]);

  // console.log(dataProviderState.globalDocumentList)

  // check whether folder contains file which are uploaded in 90 days from today.
  const handleCheckNewContent = (category: string) => {
    if (category) {
      const isNewFileExists =
        dataProviderState?.globalDocumentList?.[category as any]?.filter(
          (document: any) => {
            const today = dayjs();
            const publishedDate = dayjs(new Date(document?.publishedDate));
            const diffInDays = today.diff(publishedDate, "days");
            // 90 days from published date
            // return diffInDays >= 0 && diffInDays <= 90;

            // 30 days from published date as per new change
            return diffInDays >= 0 && diffInDays <= 30;
          }
        ).length !== 0;

      return isNewFileExists;
    }
    return false;
  };

  return (
    <>
      <Grid sx={{ mt: 2 }}>
        <Card
          style={{
            padding: "20px 5px",
            margin: "0 auto",
            borderRadius: "16px",
            background: "rgb(96, 96, 96, 0.05)",
            minHeight: "75vh",
          }}
        >
          <CardContent>
            <Typography gutterBottom variant="h6">
              Global Documents
            </Typography>
            <IconButton
              aria-label="tab-view"
              sx={{ float: "right" }}
              onClick={() => setShowDataAs("tab")}
              color={showDataAs === "tab" ? "primary" : "default"}
            >
              <AppsIcon />
            </IconButton>
            <IconButton
              aria-label="list-view"
              sx={{ float: "right" }}
              onClick={() => setShowDataAs("list")}
              color={showDataAs === "list" ? "primary" : "default"}
            >
              <ListIcon />
            </IconButton>
            {user?.groups?.includes(constants.ROLES.A4A_ADMIN) && (
              <IconButton
                aria-label="add-folder"
                sx={{ float: "right" }}
                onClick={() => setIsAddFolderModalOpened(true)}
                color="default"
              >
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            )}
            {dataProviderState.isGlobalDocumentListApiLoading && (
              <SkeletonLoader.ViewDocument />
            )}
            {!dataProviderState.isGlobalDocumentListApiLoading &&
              showDataAs === "tab" && (
                <GetGridView
                  filteredFolderList={filteredDocList}
                  handleCheckNewContent={handleCheckNewContent}
                  documentList={dataProviderState?.globalDocumentList}
                  categoryIdNameMapping={categoryIdNameMapping}
                />
              )}
            {!dataProviderState.isGlobalDocumentListApiLoading &&
              showDataAs === "list" && (
                <GetMemomizedListView
                  filteredFolderList={filteredDocList}
                  handleCheckNewContent={handleCheckNewContent}
                  documentList={dataProviderState?.globalDocumentList}
                  tableTitle="Global Documents"
                  categoryIdNameMapping={categoryIdNameMapping}
                />
              )}
          </CardContent>
        </Card>
      </Grid>
      <AirportDetailsDrawerAddFolderModal
        isModalOpened={isAddFolderModalOpened}
        closeModal={() => setIsAddFolderModalOpened(false)}
        bucketType={DocumentBucketTypeChoice.GENERAL}
      />
    </>
  );
};

export { ViewDocument };
