import { useEffect, useState, FC } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { TextFieldInput } from "../Common/TextFieldInput";
import { AuthSchema } from "../../validations/schema";
import { helpers, constants } from "../../utils";
import { A4ALoader } from "../Common/A4ALoader";

import { useNavigate, Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { PasswordFieldInput } from "../Common/PasswordFieldInput";

interface IUpdatePassword {
  userEmail: string;
}

// default state
const updatePasswordDefaultState = {
  verificationCode: "",
  newPassword: "",
  confirmNewPassword: "",
};

const UpdatePassword: FC<IUpdatePassword> = ({ userEmail }) => {
  // state
  const [formData, setFormData] = useState<any>({
    ...updatePasswordDefaultState,
  });
  const [error, setError] = useState<any>({ ...updatePasswordDefaultState });
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  // react router dom navigate to hook
  const navigateTo = useNavigate();

  // componentDidMount
  useEffect(() => {
    document.title = constants.PAGE_TITLE.SIGN_IN;
    // componentWillUnmount
    return () => {};
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setError({ ...signUpDefaultState });

    let result: any = {};

    result = AuthSchema.resetPasswordValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    var isValidationError = false;

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      const isValid = handleValidation(key, formData[key]);

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    // check new password & confirm new password are same
    if (formData.newPassword !== formData.confirmNewPassword) {
      // set error
      setError((prev: any) => ({
        ...prev,
        confirmNewPassword: "Password don't match.",
      }));
      isValidationError = true;
    }

    if (!isValidationError) {
      // start api loader
      setApiLoading(true);

      try {
        // send verification code to email
        await Auth.forgotPasswordSubmit(
          userEmail,
          formData.verificationCode,
          formData.confirmNewPassword
        );

        // set auth status to send
        toast.success("Password changed successfully.", { theme: "colored" });

        // navigate to sign-in page
        navigateTo("/auth/in");
      } catch (error: any) {
        // show error message
        toast.error("Invalid verification code.", { theme: "colored" });
      } finally {
        // clear form data
        setFormData({ ...updatePasswordDefaultState });
        setError({ ...updatePasswordDefaultState });

        // stop api loader
        setApiLoading(false);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          // marginTop: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        elevation={0}
      >
        <Box
          // component="form"
          // onSubmit={handleSubmit}
          // noValidate
          sx={{ mt: 1 }}
          elevation={0}
        >
          <ul>
            {constants?.PasswordPolicyText?.map(
              (policy: string, idx: number) => (
                <li key={`password-policy-${idx}`}>{policy}</li>
              )
            )}
          </ul>
          <form onSubmit={handleSubmit}>
            <TextFieldInput
              id="email"
              name="email"
              placeholder="Email Address"
              label="Email Address"
              error={""}
              value={userEmail}
              autoComplete="email"
              margin="normal"
              handleChange={() => {}}
              // autoFocus
              fullWidth
              disabled
            />
            <TextFieldInput
              id="verificationCode"
              name="verificationCode"
              placeholder="Verification Code"
              label="Verification Code"
              error={error.verificationCode}
              value={formData.verificationCode}
              helperText="Enter verification code sent to your email."
              autoComplete="verificationCode"
              margin="normal"
              handleChange={handleChange}
              onBlur={() =>
                handleValidation("verificationCode", formData.verificationCode)
              }
              // autoFocus
              fullWidth
              required
            />
            <PasswordFieldInput
              id="newPassword"
              name="newPassword"
              placeholder="New Password"
              label="New Password"
              error={error.newPassword}
              value={formData.newPassword}
              handleChange={handleChange}
              onBlur={() =>
                handleValidation("newPassword", formData.newPassword)
              }
              fullWidth
              required
            />
            <PasswordFieldInput
              id="confirmNewPassword"
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              label="Confirm New Password"
              error={error.confirmNewPassword}
              value={formData.confirmNewPassword}
              handleChange={handleChange}
              onBlur={() =>
                handleValidation(
                  "confirmNewPassword",
                  formData.confirmNewPassword
                )
              }
              fullWidth
              required
            />
            {apiLoading && <A4ALoader />}
            {!apiLoading && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, textTransform: "none" }}
                onClick={handleSubmit}
                disabled={apiLoading}
              >
                Change Password
              </Button>
            )}
            <Grid container>
              <Grid item>
                <Link to="/auth/in">{"have an account? Sign-in"}</Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export { UpdatePassword };
