import React, { createContext, useEffect, useReducer } from "react";
import {
  ITransactionState,
  ITransactionContext,
  ITransactionProvider,
} from "./types";
import { useAuth } from "../AuthProvider";
import { constants } from "./../../utils";
import { DocumentService } from "./../../services/DocumentService";
import { InviteLogService } from "./../../services/InviteLogService";
import * as x_queries from "./../../graphql/customQueries";

const transactionContextAction = {
  SET_ALL_DOCUMENTS: "SET_ALL_DOCUMENTS",
  SET_ALL_INVITE_LOGS: "SET_ALL_INVITE_LOGS",
  SET_ALL_DOCUMENT_LIST_API_LOADING: "SET_ALL_DOCUMENT_LIST_API_LOADING",
  SET_ALL_INVITE_LOG_LIST_API_LOADING: "SET_ALL_INVITE_LOG_LIST_API_LOADING",
  RESET_STATE: "RESET_STATE",
};

type Action = { type: string; payload: any };

const initialDataState: ITransactionState = {
  allDocuments: [],
  allInviteLogs: [],
  allDocumentListApiLoading: true,
  allInviteLogApiLoading: true,
};

function dataReducer(
  state: ITransactionState,
  action: Action
): ITransactionState {
  switch (action.type) {
    case transactionContextAction.SET_ALL_DOCUMENTS:
      return { ...state, allDocuments: action.payload };
    case transactionContextAction.SET_ALL_INVITE_LOGS:
      return { ...state, allInviteLogs: action.payload };
    case transactionContextAction.SET_ALL_DOCUMENT_LIST_API_LOADING:
      return { ...state, allDocumentListApiLoading: action.payload };
    case transactionContextAction.SET_ALL_INVITE_LOG_LIST_API_LOADING:
      return { ...state, allInviteLogApiLoading: action.payload };
    case transactionContextAction.RESET_STATE:
      return { ...action.payload };
    default:
      return state;
  }
}

const TransactionContext = createContext<ITransactionContext | undefined>(
  undefined
);

const TransactionProvider: React.FC<ITransactionProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, initialDataState);
  const { state: authProviderState } = useAuth();

  const apiDocument = new DocumentService();
  const apiInviteLog = new InviteLogService();

  // fetch
  useEffect(() => {
    // IIFE
    (async () => {
      if (authProviderState?.isSignedIn) {
        if (
          authProviderState?.user?.groups?.includes(constants?.ROLES?.A4A_ADMIN)
        ) {
          if (!state.allDocuments.length) {
            await fetchAllDocumentsList([]);
          }
          if (!state.allInviteLogs.length) {
            await fetchAllInviteLogList([]);
          }
        }
      } else {
        // reset state
        dispatch({
          type: transactionContextAction.RESET_STATE,
          payload: initialDataState,
        });
      }
    })();
  }, [authProviderState?.isSignedIn]);

  const fetchAllDocumentsList = async (
    currentDocuments: Array<any>,
    reqNextToken: string | null = null
  ) => {
    // all document filter
    const filter = {};

    dispatch({
      type: transactionContextAction.SET_ALL_DOCUMENT_LIST_API_LOADING,
      payload: true,
    });

    const { documents, nextToken } = await apiDocument.getAllDocuments(
      filter,
      reqNextToken,
      x_queries?.sg_CustomTransactionDocumentList
    );

    let allDocuments = [...currentDocuments, ...documents];

    if (nextToken) {
      await fetchAllDocumentsList(allDocuments, nextToken);
    } else {
      // sort documents data
      allDocuments.sort((itemA: any, itemB: any) => {
        if (itemA.title < itemB.title) {
          return -1;
        }
        if (itemA.title > itemB.title) {
          return 1;
        }
        return 0;
      });

      dispatch({
        type: transactionContextAction.SET_ALL_DOCUMENTS,
        payload: allDocuments,
      });

      dispatch({
        type: transactionContextAction.SET_ALL_DOCUMENT_LIST_API_LOADING,
        payload: false,
      });
    }
  };

  const fetchAllInviteLogList = async (
    currentLogs: Array<any>,
    reqNextToken: string | null = null
  ) => {
    // all invite log list filter
    const filter = {};

    dispatch({
      type: transactionContextAction.SET_ALL_INVITE_LOG_LIST_API_LOADING,
      payload: true,
    });

    const { invites, nextToken } = await apiInviteLog.getAllInviteLogs(
      filter,
      reqNextToken,
      x_queries?.sg_CustomTransactionInviteLogList
    );

    let allLogs = [...currentLogs, ...invites];

    if (nextToken) {
      await fetchAllInviteLogList(allLogs, nextToken);
    } else {
      // sort logs data
      // allLogs.sort((itemA: any, itemB: any) => {
      //   if (itemA.title < itemB.title) {
      //     return -1;
      //   }
      //   if (itemA.title > itemB.title) {
      //     return 1;
      //   }
      //   return 0;
      // });

      dispatch({
        type: transactionContextAction.SET_ALL_INVITE_LOGS,
        payload: allLogs,
      });

      dispatch({
        type: transactionContextAction.SET_ALL_INVITE_LOG_LIST_API_LOADING,
        payload: false,
      });
    }
  };

  return (
    <TransactionContext.Provider value={{ state, dispatch }}>
      {children}
    </TransactionContext.Provider>
  );
};

function useTransactionContext(): ITransactionContext {
  const context = React.useContext(TransactionContext);
  if (context === undefined) {
    throw new Error(
      "useTransactionContext must be used within an TransactionProvider"
    );
  }
  return context;
}

export { TransactionProvider, useTransactionContext, transactionContextAction };
