import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  styled,
  Typography,
} from "@mui/material";

export const ParentGrid = styled(Grid)(({ theme }) => ({
  "& .public-doc-icon": {
    minWidth: "55px !important",
    minHeight: "55px !important",
    maxWidth: "55px !important",
    maxHeight: "55px !important",
    margin: "10px !important",
  },
  [theme.breakpoints.down("md")]: {
    "& .public-doc-icon": {
      minWidth: "30px !important",
      minHeight: "30px !important",
      maxWidth: "30px !important",
      maxHeight: "30px !important",
      margin: "10px !important",
    },
  },
}));

export const CurrentCategoryTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

export const FileUploadButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "16px",
  p: 1,
  float: "right",
  [theme.breakpoints.down("md")]: {
    padding: "5px",
    minWidth: "48px",
  },
}));

export const GridDocWrapper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
  m: 2,
  overflowWrap: "anywhere",
  background: "transparent",
}));

export const GridDocContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  mx: "auto",
}));

export const GridDocTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
  },
}));

export const GridMenuItem = styled(MenuItem)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
  },
}));


export const ListDocTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
  },
}));