import joi from "joi";

// create document params
// validation schema
const createDocumentValidationSchema: any = {
  title: joi.string().trim().required().messages({
    "string.empty": "Document title cannot be empty.",
    "any.required": "Document title required.",
  }),
};

// upload document params
// validation schema
const uploadDocumentValidationSchema: any = {
  fileName: joi.string().trim().required().messages({
    "string.empty": "File name cannot be empty.",
    "any.required": "File name required.",
  }),
  description: joi.string().trim().required().messages({
    "string.empty": "Description cannot be empty.",
    "any.required": "Description required.",
  }),
  publishedDate: joi.string().trim().required().messages({
    "string.empty": "Invalid document date.",
    "any.required": "Invalid document date.",
  }),
};

// edit document params
// validation schema
const editDocumentValidationSchema: any = {
  fileName: joi.string().trim().required().messages({
    "string.empty": "File name cannot be empty.",
    "any.required": "File name required.",
  }),
  description: joi.string().trim().required().messages({
    "string.empty": "Description cannot be empty.",
    "any.required": "Description required.",
  }),
  publishedDate: joi.string().trim().required().messages({
    "string.empty": "Invalid document date.",
    "any.required": "Invalid document date.",
  }),
};

export {
  createDocumentValidationSchema,
  uploadDocumentValidationSchema,
  editDocumentValidationSchema,
};
