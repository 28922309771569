import { LngLatBoundsLike } from "mapbox-gl";

export const ALL_US_STATES = "ALL";
export const CONTINENTAL = "CONTINENTAL";

export const mapType = {
  MAIN: "main",
  ALASKA: "alaska",
  HAWAII: "hawaii",
} as const;

export const mapBounds = {
  WHOLE: [-180, 5, -10, 80] as LngLatBoundsLike,
  MAIN: [-140, 10, -47, 52] as LngLatBoundsLike,
  ALASKA: [-190, 40, -115, 85] as LngLatBoundsLike,
  HAWAII: [-170, 10, -145, 35] as LngLatBoundsLike,
};

export const airportRegionName = {
  PADD_1A_NEW_ENGLAND: "PADD 1A (New England)",
  PADD_1B_CENTERAL_ATLANTIC: "PADD 1B (Central Atlantic)",
  PADD_1C_LOWER_ATLANTIC: "PADD 1C (Lower Atlantic)",
  PADD_2_MIDWEST: "PADD 2 (Midwest)",
  PADD_3_GULF_COAST: "PADD 3 (Gulf Coast)",
  PADD_4_ROCKY_MOUNTAIN: "PADD 4 (Rocky Mountain)",
  PADD_5_ALASKA: "PADD 5 (Alaska)",
  PADD_5_HAWAII: "PADD 5 (Hawaii)",
  PADD_5_WEST_COAST: "PADD 5 (West Coast)",
  PADD_NA_CARIBBEAN: "",
} as const;

export const airportRegionOptions = [
  { value: "PADD_1A_NEW_ENGLAND", name: "PADD 1A (New England)" },
  { value: "PADD_1B_CENTERAL_ATLANTIC", name: "PADD 1B (Central Atlantic)" },
  { value: "PADD_1C_LOWER_ATLANTIC", name: "PADD 1C (Lower Atlantic)" },
  { value: "PADD_2_MIDWEST", name: "PADD 2 (Midwest)" },
  { value: "PADD_3_GULF_COAST", name: "PADD 3 (Gulf Coast)" },
  { value: "PADD_4_ROCKY_MOUNTAIN", name: "PADD 4 (Rocky Mountain)" },
  { value: "PADD_5_ALASKA", name: "PADD 5 (Alaska)" },
  { value: "PADD_5_HAWAII", name: "PADD 5 (Hawaii)" },
  { value: "PADD_5_WEST_COAST", name: "PADD 5 (West Coast)" },
];

export const usStateCodeName = {
  AL: "Alabama (AL)",
  AK: "Alaska (AK)",
  AZ: "Arizona (AZ)",
  AR: "Arkansas (AR)",
  CA: "California (CA)",
  CO: "Colorado (CO)",
  CT: "Connecticut (CT)",
  DE: "Delaware (DE)",
  DC: "District of Columbia (DC)",
  FL: "Florida (FL)",
  GA: "Georgia (GA)",
  HI: "Hawaii (HI)",
  ID: "Idaho (ID)",
  IL: "Illinois (IL)",
  IN: "Indiana (IN)",
  IA: "Iowa (IA)",
  KS: "Kansas (KS)",
  KY: "Kentucky (KY)",
  LA: "Louisiana (LA)",
  ME: "Maine (ME)",
  MD: "Maryland (MD)",
  MA: "Massachusetts (MA)",
  MI: "Michigan (MI)",
  MN: "Minnesota (MN)",
  MS: "Mississippi (MS)",
  MO: "Missouri (MO)",
  MT: "Montana (MT)",
  NE: "Nebraska (NE)",
  NV: "Nevada (NV)",
  NH: "New Hampshire (NH)",
  NJ: "New Jersey (NJ)",
  NM: "New Mexico (NM)",
  NY: "New York (NY)",
  NC: "North Carolina (NC)",
  ND: "North Dakota (ND)",
  OH: "Ohio (OH)",
  OK: "Oklahoma (OK)",
  OR: "Oregon (OR)",
  PA: "Pennsylvania (PA)",
  PR: "Puerto Rico (PR)",
  RI: "Rhode Island (RI)",
  SC: "South Carolina (SC)",
  SD: "South Dakota (SD)",
  TN: "Tennessee (TN)",
  TX: "Texas (TX)",
  UT: "Utah (UT)",
  VT: "Vermont (VT)",
  VA: "Virginia (VA)",
  VI: "Virgin Islands (VI)",
  WA: "Washington (WA)",
  WV: "West Virginia (WV)",
  WI: "Wisconsin (WI)",
  WY: "Wyoming (WY)",
} as const;

export const usStateCodeOptions = [
  { value: "AL", name: "Alabama (AL)" },
  { value: "AK", name: "Alaska (AK)" },
  { value: "AZ", name: "Arizona (AZ)" },
  { value: "AR", name: "Arkansas (AR)" },
  { value: "CA", name: "California (CA)" },
  { value: "CO", name: "Colorado (CO)" },
  { value: "CT", name: "Connecticut (CT)" },
  { value: "DE", name: "Delaware (DE)" },
  { value: "DC", name: "District of Columbia (DC)" },
  { value: "FL", name: "Florida (FL)" },
  { value: "GA", name: "Georgia (GA)" },
  { value: "HI", name: "Hawaii (HI)" },
  { value: "ID", name: "Idaho (ID)" },
  { value: "IL", name: "Illinois (IL)" },
  { value: "IN", name: "Indiana (IN)" },
  { value: "IA", name: "Iowa (IA)" },
  { value: "KS", name: "Kansas (KS)" },
  { value: "KY", name: "Kentucky (KY)" },
  { value: "LA", name: "Louisiana (LA)" },
  { value: "ME", name: "Maine (ME)" },
  { value: "MD", name: "Maryland (MD)" },
  { value: "MA", name: "Massachusetts (MA)" },
  { value: "MI", name: "Michigan (MI)" },
  { value: "MN", name: "Minnesota (MN)" },
  { value: "MS", name: "Mississippi (MS)" },
  { value: "MO", name: "Missouri (MO)" },
  { value: "MT", name: "Montana (MT)" },
  { value: "NE", name: "Nebraska (NE)" },
  { value: "NV", name: "Nevada (NV)" },
  { value: "NH", name: "New Hampshire (NH)" },
  { value: "NJ", name: "New Jersey (NJ)" },
  { value: "NM", name: "New Mexico (NM)" },
  { value: "NY", name: "New York (NY)" },
  { value: "NC", name: "North Carolina (NC)" },
  { value: "ND", name: "North Dakota (ND)" },
  { value: "OH", name: "Ohio (OH)" },
  { value: "OK", name: "Oklahoma (OK)" },
  { value: "OR", name: "Oregon (OR)" },
  { value: "PA", name: "Pennsylvania (PA)" },
  { value: "PR", name: "Puerto Rico (PR)" },
  { value: "RI", name: "Rhode Island (RI)" },
  { value: "SC", name: "South Carolina (SC)" },
  { value: "SD", name: "South Dakota (SD)" },
  { value: "TN", name: "Tennessee (TN)" },
  { value: "TX", name: "Texas (TX)" },
  { value: "UT", name: "Utah (UT)" },
  { value: "VT", name: "Vermont (VT)" },
  { value: "VA", name: "Virginia (VA)" },
  { value: "VI", name: "Virgin Islands (VI)" },
  { value: "WA", name: "Washington (WA)" },
  { value: "WV", name: "West Virginia (WV)" },
  { value: "WI", name: "Wisconsin (WI)" },
  { value: "WY", name: "Wyoming (WY)" },
];

export const usStateCode = {
  ALABAMA: "AL",
  ALASKA: "AK",
  ARIZONA: "AZ",
  ARKANSAS: "AR",
  CALIFORNIA: "CA",
  COLORADO: "CO",
  CONNECTICUT: "CT",
  DELAWARE: "DE",
  DISTRICT_OF_COLUMBIA: "DC",
  FLORIDA: "FL",
  GEORGIA: "GA",
  HAWAII: "HI",
  IDAHO: "ID",
  ILLINOIS: "IL",
  INDIANA: "IN",
  IOWA: "IA",
  KANSAS: "KS",
  KENTUCKY: "KY",
  LOUSIANA: "LA",
  MAINE: "ME",
  MARYLAND: "MD",
  MASSACHUSETTS: "MA",
  MICHIGAN: "MI",
  MINNESOTA: "MN",
  MISSISSIPPI: "MS",
  MISSOURI: "MO",
  MONTANA: "MT",
  NEBRASKA: "NE",
  NEVADA: "NV",
  NEW_HAMPSHIRE: "NH",
  NEW_JERSEY: "NJ",
  NEW_MEXICO: "NM",
  NEW_YORK: "NY",
  NORTH_CAROLINA: "NC",
  NORTH_DAKOTA: "ND",
  OHIO: "OH",
  OKLAHOMA: "OK",
  OREGON: "OR",
  PENNSYLVANIA: "PA",
  PEURTO_RICO: "PR",
  RHODE_ISLAND: "RI",
  SOUTH_CAROLINA: "SC",
  SOUTH_DAKOTA: "SD",
  TENNESSEE: "TN",
  TEXAS: "TX",
  UTAH: "UT",
  VERMONT: "VT",
  VIRGINIA: "VA",
  VIRGIN_ISLANDS: "VI",
  WASHINGTON: "WA",
  WEST_VIRGINIA: "WV",
  WISCONSIN: "WI",
  WYOMING: "WY",
} as const;

export const usStatesFilterOptions = [
  { name: "All States", value: ALL_US_STATES },
  { name: "Lower 48", value: CONTINENTAL },
  { name: "Alabama (AL)", value: usStateCode.ALABAMA },
  { name: "Alaska (AK)", value: usStateCode.ALASKA },
  { name: "Arizona (AZ)", value: usStateCode.ARIZONA },
  { name: "Arkansas (AR)", value: usStateCode.ARKANSAS },
  { name: "California (CA)", value: usStateCode.CALIFORNIA },
  { name: "Colorado (CO)", value: usStateCode.COLORADO },
  { name: "Connecticut (CT)", value: usStateCode.CONNECTICUT },
  { name: "Delaware (DE)", value: usStateCode.DELAWARE },
  {
    name: "District of Columbia (DC)",
    value: usStateCode.DISTRICT_OF_COLUMBIA,
  },
  { name: "Florida (FL)", value: usStateCode.FLORIDA },
  { name: "Georgia (GA)", value: usStateCode.GEORGIA },
  { name: "Hawaii (HI)", value: usStateCode.HAWAII },
  { name: "Idaho (ID)", value: usStateCode.IDAHO },
  { name: "Illinois (IL)", value: usStateCode.ILLINOIS },
  { name: "Indiana (IN)", value: usStateCode.INDIANA },
  { name: "Iowa (IA)", value: usStateCode.IOWA },
  { name: "Kansas (KS)", value: usStateCode.KANSAS },
  { name: "Kentucky (KY)", value: usStateCode.KENTUCKY },
  { name: "Louisiana (LA)", value: usStateCode.LOUSIANA },
  { name: "Maine (ME)", value: usStateCode.MAINE },
  { name: "Maryland (MD)", value: usStateCode.MARYLAND },
  { name: "Massachusetts (MA)", value: usStateCode.MASSACHUSETTS },
  { name: "Michigan (MI)", value: usStateCode.MICHIGAN },
  { name: "Minnesota (MN)", value: usStateCode.MINNESOTA },
  { name: "Mississippi (MS)", value: usStateCode.MISSISSIPPI },
  { name: "Missouri (MO)", value: usStateCode.MISSOURI },
  { name: "Montana (MT)", value: usStateCode.MONTANA },
  { name: "Nebraska (NE)", value: usStateCode.NEBRASKA },
  { name: "Nevada (NV)", value: usStateCode.NEVADA },
  { name: "New Hampshire (NH)", value: usStateCode.NEW_HAMPSHIRE },
  { name: "New Jersey (NJ)", value: usStateCode.NEW_JERSEY },
  { name: "New Mexico (NM)", value: usStateCode.NEW_MEXICO },
  { name: "New York (NY)", value: usStateCode.NEW_YORK },
  { name: "North Carolina (NC)", value: usStateCode.NORTH_CAROLINA },
  { name: "North Dakota (ND)", value: usStateCode.NORTH_DAKOTA },
  { name: "Ohio (OH)", value: usStateCode.OHIO },
  { name: "Oklahoma (OK)", value: usStateCode.OKLAHOMA },
  { name: "Oregon (OR)", value: usStateCode.OREGON },
  { name: "Pennsylvania (PA)", value: usStateCode.PENNSYLVANIA },
  { name: "Puerto Rico (PR)", value: usStateCode.PEURTO_RICO },
  { name: "Rhode Island (RI)", value: usStateCode.RHODE_ISLAND },
  { name: "South Carolina (SC)", value: usStateCode.SOUTH_CAROLINA },
  { name: "South Dakota (SD)", value: usStateCode.SOUTH_DAKOTA },
  { name: "Tennessee (TN)", value: usStateCode.TENNESSEE },
  { name: "Texas (TX)", value: usStateCode.TEXAS },
  { name: "Utah (UT)", value: usStateCode.UTAH },
  { name: "Vermont (VT)", value: usStateCode.VERMONT },
  { name: "Virginia (VA)", value: usStateCode.VIRGINIA },
  { name: "Virgin Islands (VI)", value: usStateCode.VIRGIN_ISLANDS },
  { name: "Washington (WA)", value: usStateCode.WASHINGTON },
  { name: "West Virginia (WV)", value: usStateCode.WEST_VIRGINIA },
  { name: "Wisconsin (WI)", value: usStateCode.WISCONSIN },
  { name: "Wyoming (WY)", value: usStateCode.WYOMING },
];

export const usStatesBoundingBoxes = {
  AL: [
    [-88.473227, 30.144425],
    [-84.888247, 35.008028],
  ],
  AK: [
    [-179.148909, 51.214183],
    [-129.979536, 71.5388],
  ],
  AZ: [
    [-114.818269, 31.332177],
    [-109.045223, 37.004261],
  ],
  AR: [
    [-94.617919, 33.004106],
    [-89.644781, 36.499686],
  ],
  CA: [
    [-124.482003, 32.528832],
    [-114.131211, 42.009518],
  ],
  CO: [
    [-109.045223, 36.993076],
    [-102.041524, 41.003444],
  ],
  CT: [
    [-73.727775, 40.980144],
    [-71.786994, 42.050587],
  ],
  DC: [
    [-77.11976, 38.791644],
    [-76.909395, 38.995548],
  ],
  DE: [
    [-75.788658, 38.451013],
    [-74.984857, 39.839007],
  ],
  FL: [
    [-87.634896, 24.396308],
    [-80.031362, 31.001056],
  ],
  GA: [
    [-85.605165, 30.355644],
    [-80.751446, 35.000659],
  ],
  HI: [
    [-160.236347, 18.86546],
    [-154.755792, 22.305735],
  ],
  ID: [
    [-117.243027, 41.98818],
    [-111.043564, 49.001146],
  ],
  IL: [
    [-91.512974, 36.970298],
    [-87.494756, 42.508481],
  ],
  IN: [
    [-88.097888, 37.771742],
    [-84.78458, 41.760592],
  ],
  IA: [
    [-96.639485, 40.375501],
    [-90.140061, 43.501196],
  ],
  KS: [
    [-102.051744, 36.99308],
    [-94.588413, 40.003162],
  ],
  KY: [
    [-89.57151, 36.497129],
    [-81.964971, 39.147359],
  ],
  LA: [
    [-94.043036, 28.92861],
    [-88.758389, 33.019457],
  ],
  ME: [
    [-71.083924, 43.057759],
    [-66.954694, 47.459687],
  ],
  MD: [
    [-79.487651, 37.911717],
    [-75.048939, 39.723043],
  ],
  MA: [
    [-73.508142, 41.186837],
    [-69.858861, 42.886798],
  ],
  MI: [
    [-90.418136, 41.696118],
    [-82.413474, 48.304127],
  ],
  MN: [
    [-97.239209, 43.499363],
    [-89.491739, 49.384358],
  ],
  MS: [
    [-91.655009, 30.173943],
    [-88.097888, 34.996052],
  ],
  MO: [
    [-95.774704, 35.995683],
    [-89.098843, 40.61364],
  ],
  MT: [
    [-116.049866, 44.358221],
    [-104.039648, 49.001146],
  ],
  NE: [
    [-104.053514, 39.999998],
    [-95.30829, 43.001708],
  ],
  NV: [
    [-120.005746, 35.001857],
    [-114.039648, 42.002207],
  ],
  NH: [
    [-72.557247, 42.69699],
    [-70.534202, 45.305476],
  ],
  NJ: [
    [-75.563587, 38.928519],
    [-73.885058, 41.357423],
  ],
  NM: [
    [-109.050173, 31.332301],
    [-103.000154, 37.000232],
  ],
  NY: [
    [-79.762152, 40.477399],
    [-71.856214, 45.01585],
  ],
  NC: [
    [-84.321869, 33.842316],
    [-75.400119, 36.588117],
  ],
  ND: [
    [-104.0489, 45.935054],
    [-96.554507, 49.000574],
  ],
  OH: [
    [-84.820159, 38.403202],
    [-80.518693, 42.327132],
  ],
  OK: [
    [-103.002004, 33.615833],
    [-94.430662, 37.002206],
  ],
  OR: [
    [-124.566244, 41.991794],
    [-116.463262, 46.292035],
  ],
  PR: [
    [-67.998751, 17.831509],
    [-65.168503, 18.520601],
  ],
  PA: [
    [-80.519891, 39.719798],
    [-74.689517, 42.269079],
  ],
  RI: [
    [-71.907324, 41.064305],
    [-71.12057, 42.01938],
  ],
  SC: [
    [-83.35391, 32.0346],
    [-78.54203, 35.215402],
  ],
  SD: [
    [-104.057698, 42.479635],
    [-96.436589, 45.94545],
  ],
  TN: [
    [-90.310297, 34.982972],
    [-81.6469, 36.678118],
  ],
  TX: [
    [-106.645646, 25.837377],
    [-93.508292, 36.500704],
  ],
  UT: [
    [-114.053009, 36.997968],
    [-109.041058, 42.001618],
  ],
  VT: [
    [-73.43774, 42.72685],
    [-71.465052, 45.016659],
  ],
  VA: [
    [-83.67529, 36.540738],
    [-75.242266, 39.466012],
  ],
  VI: [
    [-65.040245, 17.674521],
    [-64.512674, 18.4197],
  ],
  WA: [
    [-124.848974, 45.543541],
    [-116.916071, 49.002494],
  ],
  WV: [
    [-82.644739, 37.201483],
    [-77.719519, 40.638801],
  ],
  WI: [
    [-92.889433, 42.491983],
    [-86.249548, 47.30975],
  ],
  WY: [
    [-111.056888, 40.994746],
    [-104.05216, 45.005904],
  ],
};

export const facilityLocationMapping = {
  ON_AIRPORT: "On-Airport",
  OFF_AIRPORT: "Off-Airport",
  UNKNOWN: "Unknown",
} as const;

export const facilityLocationOptions = [
  { value: "ON_AIRPORT", name: "On-Airport" },
  { value: "OFF_AIRPORT", name: "Off-Airport" },
  { value: "Unknown", name: "Unknown" },
];

export const heatMapRange = {
  Range0: 0,
  Range2: 500000000,
  Range4: 750000000,
  Range6: 1250000000,
} as const;

export const heatMapRangeColor = {
  Range0: "rgb(255, 0, 0)",
  Range2: "rgb(255, 170, 0)",
  Range4: "rgb(170, 255, 0)",
  Range6: "rgb(0, 255, 0)",
} as const;

export const heapMapRangeOptions = [
  { name: "All Categories", value: "" },
  { name: "Small", value: "0-499999999", color: "rgb(255, 0, 0)" },
  { name: "Medium", value: "500000000-749999999", color: "rgb(255, 170, 0)" },
  { name: "Large", value: "750000000-1249999999", color: "rgb(170, 255, 0)" },
  { name: "Mega", value: "1250000000", color: "rgb(0, 255, 0)" },
];

export const organizationGroupTypeColorMapping = {
  ADVISORY_COMMITTEE: "#B51E24", // red shade
  COMMITTEE: "#64B445", // green shade
  CORP_CO_LLC: "#0086BD", // blue shade
  NULL: "#727377", // grey shade
} as const;

export const organizationGroupType = {
  ADVISORY_COMMITTEE: "Advisory Committee",
  COMMITTEE: "Committee",
  CORP_CO_LLC: "Corp Co LLC",
  // NULL: "No Organization",
} as const;

export enum generalTabFieldType {
  TEXT,
  ENUM,
  BOOLEAN,
  DATE,
  ENUM_MULTIPLE,
  TEXT_MULTIPLE,
  DATE_TIME,
}

export const generalTabFields = {
  CHAIR_ID: "chairId",
  CHAIR_CONTACT_ID: "chairContactId",
  OPERATOR_ID: "operatorId",
  OPERATOR_GENERAL_MANAGER_ID: "operatorGeneralManagerId",
  FACILITY_OWNER: "facilityOwner",
  FACILITY_OWNER_CONTACT: "facilityOwnerContact",
  SECONDARY_CONTACT: "secondaryContact",
  CONSORTIUM_TYPE: "consortiumType",
  CONSORTIUM_GROUP: "consortiumGroup",
  CODE: "code",
  OLD_CODE: "oldCode",
  FACILITY: "facility",
  REGION: "region",
  FACILITY_LOCATION: "facilityLocation",
  STATE: "state",
  TOTAL_CAPACITY_DAYS: "totalCapacityDays",
  TOTAL_CAPACITY_GALLONS: "totalCapacityGallons",
  SECURED: "secured",
  MO_EXPIRATION: "moExpiration",
  MO_TYPE: "moType",
  MO_TYPE_CUSTOM_VALUE: "moTypeCustomValue",
  LEASE_EXPIRATION: "leaseExpiration",
  LEASE_TYPE: "leaseType",
  LEASE_TYPE_CUSTOM_VALUE: "leaseTypeCustomValue",
  LEGAL_COUNSEL: "legalCounsel",
  LEGAL_COUNSEL_CONTACT: "legalCounselContact",
  DATE_LAST_FINANCIAL_AUP: "dateLastFinancialAUP",
  DATE_LAST_FINANCIAL_SUP: "dateLastFinancialSUP",
  DATE_LAST_A4A_OPERATIONAL_REVIEW: "dateLastA4aOperationalReview",
  NET_INVENTORY_CALCULATION: "netInventoryCalculation",
  ENTRY_FEE: "entryFee",
  NON_MEMBER_RATE: "nonMemberRate",
  COST_SHARING_FORMULA: "costSharingFormula",
  RESERVE_DEPOSIT_PREBILL: "reserveDepositPrebill",
  THIRD_PARTY_DEBT: "thirdPartyDebt",
  MEMBER_LOANS: "memberLoans",
  TOTAL_OUTSTANDING_DEBT: "totalOutstandingDebt",
  DATE_LAST_PROPERTY_APPRAISAL: "dateLastPropertyAppraisal",
  AVIATION_LIABILITY_LIMIT: "aviationLiabilityLimit",
  ENVIRONMENTAL_LIABILITY_LIMIT: "environmentalLiabilityLimit",
  COMMENTS: "comments",
} as const;

export const deliveryToPlaneFields = {
  OB_FILTRATION: "obFiltration",
  HYDRANT: "hydrant",
  CLOSED_LOOP: "closedLoop",
  LENGTH: "length",
  HYDRANT_LOCATIONS: "hydrantLocations",
  PITS: "pits",
  REFUELER_LOCATIONS: "refuelerLocations",
  EFSO: "efso",
  EFSO_LOCATIONS: "efsoLocation",
  LEAK_DETECTION: "leakDetection",
  LEAK_DETECTION_DESCRIPTION: "leakDetectionDescription",
  FLOW_RATE_F1_PEAK: "flowRateF1Peak",
  FLOW_RATE_F1_MAX: "flowRateF1Max",
  FLOW_RATE_F2_PEAK: "flowRateF2Peak",
  FLOW_RATE_F2_MAX: "flowRateF2Max",
  FLOW_RATE_F3_PEAK: "flowRateF3Peak",
  FLOW_RATE_F3_MAX: "flowRateF3Max",
  PIPING_SIZE_MAX: "pipingSizeMax",
  PIPING_SIZE_MIN: "pipingSizeMin",
  CURRENT_INTO_PLANE_OPERATORS: "currentIntoPlaneOperators",
} as const;

export const closedLoopEnumMapping = {
  YES: "Yes",
  NO: "No",
  PARTIAL: "Partial",
  NA: "NA",
} as const;

export const closedLoopEnumOptions = [
  { value: "YES", name: "Yes" },
  { value: "NO", name: "No" },
  { value: "PARTIAL", name: "Partial" },
  { value: "NA", name: "Not Answered" },
];

export const hydrantChoiceEnumMapping = {
  YES: "Yes",
  NO: "No",
  PARTIAL: "Partial",
} as const;

export const hydrantChoiceEnumOptions = [
  { value: "YES", name: "Yes" },
  { value: "NO", name: "No" },
  { value: "PARTIAL", name: "Partial" },
];

export const deliveryToFacilityFields = {
  PROVIDER: "provider",
  METHOD: "method",
  PRIMARY: "primary",
  NUMBER_OF_LINES: "numberLines",
  FREQUENCY: "frequency",
  FREQUENCY_CUSTOM_VALUE: "frequencyCustomValue",
  FILTRATION_TYPE: "filtrationType",
  FILTRATION_CUSTOM_VALUE: "filtrationTypeCustomValue",
  FILTRATION_DESCRIPTION: "filtrationDescription",
  FLOW_RATE_TYPICAL: "flowRateTypical",
  FLOW_RATE_MAX: "flowRateMax",
  PIPING_SIZE: "pipingSize",
  OFF_ROAD_LOCATIONS: "offloadLocations",
} as const;

export const reservePrebillTypeChoiceMapping = {
  ONE_MONTH: "Reserve Deposit - 1 Month",
  TWO_MONTHS: "Reserve Deposit – 2 Months",
  PREBILL: "Prebill",
  OTHER: "Other",
} as const;

export const reservePrebillTypeOptions = [
  { value: "ONE_MONTH", name: "Reserve Deposit - 1 Month" },
  { value: "TWO_MONTHS", name: "Reserve Deposit – 2 Months" },
  { value: "PREBILL", name: "Prebill" },
  { value: "OTHER", name: "Other" },
];

export const MOTypeChoiceMapping = {
  EVERGREEN: "Evergreen",
  MONTH_TO_MONTH: "Month to Month",
  N_A: "Not Answered",
  CUSTOM: "Custom",
} as const;

export const MOTypeOptions = [
  { value: "EVERGREEN", name: "Evergreen" },
  { value: "MONTH_TO_MONTH", name: "Month to Month" },
  { value: "N_A", name: "Not Answered" },
  { value: "CUSTOM", name: "Custom" },
];

export const LeaseTypeChoiceMapping = {
  EVERGREEN: "Evergreen",
  MONTH_TO_MONTH: "Month to Month",
  N_A: "Not Answered",
  CUSTOM: "Custom",
} as const;

export const LeaseTypeOptions = [
  { value: "EVERGREEN", name: "Evergreen" },
  { value: "MONTH_TO_MONTH", name: "Month to Month" },
  { value: "N_A", name: "Not Answered" },
  { value: "CUSTOM", name: "Custom" },
];

export const DeliveryMethodChoiceMapping = {
  PIPELINE: "Pipeline",
  TRUCK: "Truck",
  BARGE: "Barge",
  RAIL: "Rail",
} as const;

export const DeliveryMethodOptions = [
  { value: "PIPELINE", name: "Pipeline" },
  { value: "TRUCK", name: "Truck" },
  { value: "BARGE", name: "Barge" },
  { value: "RAIL", name: "Rail" },
];

export const DeliveryFrequencyChoiceMapping = {
  ON_DEMAND: "On Demand",
  DAILY: "Daily",
  CUSTOM: "Custom",
} as const;

export const DeliveryFrequencyOptions = [
  { value: "ON_DEMAND", name: "On Demand" },
  { value: "DAILY", name: "Daily" },
  { value: "CUSTOM", name: "Custom" },
];

export const FiltrationTypeChoiceMapping = {
  ON_SITE_THREE_STAGE: "On Site Three Stage",
  OFF_SITE_THREE_STAGE: "Off Site Three Stage",
  ON_SITE_FILTER_SEPARATORS: "On Site Filter Separators",
  OFF_SITE_FILTER_SEPARATORS: "Off Site Filter Separators",
  ON_SITE_FOUR_STAGE: "On Site Four Stage",
  LEASED_OFF_SITE_FACILITY: "Leased Off Site Facility",
  NONE: "None",
  CUSTOM: "Custom",
} as const;

export const FiltrationTypeOptions = [
  { value: "ON_SITE_THREE_STAGE", name: "On Site Three Stage" },
  { value: "OFF_SITE_THREE_STAGE", name: "Off Site Three Stage" },
  { value: "ON_SITE_FOUR_STAGE", name: "On Site Four Stage" },
  { value: "ON_SITE_FILTER_SEPARATORS", name: "On Site Filter Separators" },
  { value: "LEASED_OFF_SITE_FACILITY", name: "Leased Off Site Facility" },
  { value: "NONE", name: "None" },
  { value: "CUSTOM", name: "Custom" },
];

export const storageFields = {
  TANK_ID: "tankId",
  YEAR_INSTALLED: "yearInstalled",
  PRODUCT: "product",
  STORAGE_LOCATION: "storageLocation",
  STORAGE_AST_UST: "storageASTUST",
  NEXT_API_653: "nextAPI653",
  STORAGE_FUNCTION: "storageFunction",
  TOTAL_BBL: "totalBBL",
  USABLE_BBL: "usableBBL",
  TOTAL_GALS: "totalGals",
  USABLE_GALS: "usableGals",
  OP_STATUS: "opStatus",
  OWNER: "owner",
  SECURITY: "security",
  SECONDARY_CONTAINMENT: "secondaryContainment",
  CATHODIC_PROTECTION: "cathodicProtection",
  LEAK_DETECTION: "leakDetection",
} as const;

export const StorageProductChoiceMapping = {
  JET_A: "Jet A",
  AVGAS: "Avgas",
  BONDED: "Bonded",
  DIESEL: "Diesel",
  GLYCOL: "Glycol",
  MOGAS: "Mogas",
  RECOVERED_FUEL: "Recovered Fuel",
  WASTE: "Waste",
} as const;

export const StorageProductOptions = [
  { value: "JET_A", name: "Jet A" },
  { value: "AVGAS", name: "Avgas" },
  { value: "BONDED", name: "Bonded" },
  { value: "DIESEL", name: "Diesel" },
  { value: "GLYCOL", name: "Glycol" },
  { value: "MOGAS", name: "Mogas" },
  { value: "RECOVERED_FUEL", name: "Recovered Fuel" },
  { value: "WASTE", name: "Waste" },
];

export const StorageLocationChoiceMapping = {
  ON_AIRPORT: "On Airport",
  OFF_AIRPORT: "Off Airport",
  UNKNOWN: "Unknown",
} as const;

export const StorageLocationOptions = [
  { value: "ON_AIRPORT", name: "On Airport" },
  { value: "OFF_AIRPORT", name: "Off Airport" },
  { value: "UNKNOWN", name: "Unknown" },
];

export const StorageASTUSTChoiceMapping = {
  AST: "AST",
  UST: "UST",
} as const;

export const StorageASTUSTOptions = [
  { value: "AST", name: "AST" },
  { value: "UST", name: "UST" },
];

export const StorageFunctionChoiceMapping = {
  ISSUE: "Issue",
  RECEIPT: "Receipt",
  RECOVERY: "Recovery",
  SETTLING: "Settling",
} as const;

export const StorageFunctionOptions = [
  { value: "ISSUE", name: "Issue" },
  { value: "RECEIPT", name: "Receipt" },
  { value: "RECOVERY", name: "Recovery" },
  { value: "SETTLING", name: "Settling" },
];

export const StorageOpStatusChoiceMapping = {
  OPERATING: "Operating",
  TEMP_NON_OP: "Temp Non Op",
  CLOSED_IN_PLACE: "Closed In Place",
  REMOVED: "Removed",
  AUXILIARY: "Auxiliary",
} as const;

export const StorageOpStatusOptions = [
  { value: "OPERATING", name: "Operating" },
  { value: "TEMP_NON_OP", name: "Temp Non Op" },
  { value: "CLOSED_IN_PLACE", name: "Closed In Place" },
  { value: "REMOVED", name: "Removed" },
  { value: "AUXILIARY", name: "Auxiliary" },
];

export const StorageSecondaryContainmentChoiceMapping = {
  DIKES: "Dikes",
  DOUBLE_BOTTOM: "Double Bottom",
  DOUBLE_WALL: "Double Wall",
  LINER: "Liner",
  MONITORING_WELLS: "Monitoring Wells",
  OTHER: "Other",
} as const;

export const StorageSecondaryContainmentOptions = [
  { value: "DIKES", name: "Dikes" },
  { value: "DOUBLE_BOTTOM", name: "Double Bottom" },
  { value: "DOUBLE_WALL", name: "Double Wall" },
  { value: "LINER", name: "Liner" },
  { value: "MONITORING_WELLS", name: "Monitoring Wells" },
  { value: "OTHER", name: "Other" },
];

export const StorageCathodicProtectionChoiceMapping = {
  IMPRESSED_CURRENT: "Impressed Current",
  SACRIFICIAL_ANODE: "Sacrificial Anode",
  OTHER: "Other",
  NA: "NA",
};

export const StorageCathodicProtectionOptions = [
  { value: "IMPRESSED_CURRENT", name: "Impressed Current" },
  { value: "SACRIFICIAL_ANODE", name: "Sacrificial Anode" },
  { value: "OTHER", name: "Other" },
  { value: "NA", name: "NA" },
];

export const DocumentCategoryTypeChoiceMapping = {
  BUDGET_FINANCE: "Budget Finance",
  ANNUAL_MEETING: "Annual Meeting",
  MO: "M&O",
  OTHER: "Other",
  INSURANCE: "Insurance",
  APPRAISAL: "Appraisal",
  AUDIT: "Audit",
  LEASE: "Lease",
  LOGISTICS: "Logistics",
  ENGINEERING: "Engineering",
  FUEL_QUALITY: "Fuel Quality",
  BYLAWS: "By laws",
  FUEL_CONSORTIUM_WORKSHOP: "Fuel Consortium Workshop",
  IATA_ALERTS: "Iata Alerts",
  ADMIN: "Admin",
  GUIDANCE: "Guidance",
  AUDIT_BUDGET_TAX: "Audit Budget Tax",
  ENVIRONMENTAL: "Environmental",
  INTERLINE: "Interline",
  INVENTORY: "Inventory",
  PORTAL_USER_DOCUMENTS: "Portal User Documents",
  DATABASE: "Database",
} as const;

export enum AirportFileView {
  GRID,
  LIST,
}

export const DocumentBucketTypeChoiceMapping = {
  AIRPORT: "Airport Docs",
  GENERAL: "General Docs",
} as const;

export enum AirportFieldValidatorType {
  POSITIVE_FLOAT,
  POSITIVE_INTEGAR,
  FLOAT,
  INTEGAR,
  STRING,
  CUSTOM,
  ARRAY,
}

export const DocumentFileType = {
  PDF: ".pdf",
  DOC: ".doc",
  XLS: ".xls",
  PPT: ".ppt",
};
