import React, { FC, useState, useEffect } from "react";
import { Button, Box, Alert, AlertColor, TextField } from "@mui/material";
import { A4ALoader } from "../Common/A4ALoader";
import { helpers } from "../../utils";
import { CompanyMemberSchema } from "../../validations/schema";
import { CompanyService } from "../../services/CompanyService";
import MultiSelectCheckboxDropdown from "../MultiSelectCheckboxDropdown";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import { UserService } from "../../services/UserService";
import SelectDropdown from "../SelectDropdown";

// prop type
interface IAddCompanyMember {
  closeSidebar: () => void;
  existingMembers: any[];
  companyId: string;
  setCurrentCompany?: any;
  allowExisting?: boolean;
}

interface IDefaultState {
  existing: boolean;
  memberId: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
}

// default state
const defaultState = {
  existing: true,
  memberId: "",
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
};

const AddCompanyMember: FC<IAddCompanyMember> = ({
  closeSidebar,
  existingMembers,
  companyId = "",
  setCurrentCompany,
  allowExisting = true,
}) => {
  const { state, dispatch } = useMapContext();
  // state
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<IDefaultState>({ ...defaultState });
  const [error, setError] = useState<IDefaultState>({ ...defaultState });
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: AlertColor;
  }>({
    message: "",
    severity: "success",
  });

  // componentDidMount
  useEffect(() => {
    // cleanup
    return () => {};
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setError({ ...defaultState });

    let result: any = {};

    result = CompanyMemberSchema.createCompanyMemberValidationSchema[
      key
    ].validate(value) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  // add company
  const handleAddCompanyMember = async () => {
    let isValidationError = false;
    let memberAdded: any = null;

    // clear state
    setAlertMessage({ message: "", severity: "success" });
    setError({ ...defaultState });

    const us = new UserService();

    // start api loader
    setApiLoading(true);

    try {
      if (formData.existing && allowExisting) {
        if (!formData.memberId) {
          setAlertMessage({
            message: "Please provide member id",
            severity: "error",
          });
          setApiLoading(false);
          return;
        }
        const updateMemberResponse = await us.updateUserById({
          id: formData.memberId,
          companyId,
        });

        memberAdded = updateMemberResponse;

        // stop api loader
      } else {
        const requiredFieldsNewMember = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          mobile: formData.mobile,
        };
        Object.keys(requiredFieldsNewMember).forEach((key) => {
          const isValid = handleValidation(
            key,
            requiredFieldsNewMember[key as keyof typeof requiredFieldsNewMember]
          );

          if (!isValidationError) {
            isValidationError = !isValid;
          }
        });
        if (!isValidationError) {
          const addMemberReqBody: any = {
            ...requiredFieldsNewMember,
            quarterlyEmailNotification: true
          };
          if (allowExisting) {
            addMemberReqBody.companyId = companyId;
          }
          const addMemberResponse = await us.createUser(addMemberReqBody);
          setFormData({ ...defaultState });
          setError({ ...defaultState });
          setAlertMessage({
            message: "Added member",
            severity: "success",
          });
          memberAdded = addMemberResponse;
          dispatch({
            type: mapContextAction.SET_USER_LIST,
            payload: [...state.userList, memberAdded],
          });
        }
      }
      if (memberAdded) {
        if (allowExisting) {
          setCurrentCompany((prevCurrentCompany: any) => ({
            ...prevCurrentCompany,
            members: {
              ...prevCurrentCompany.members,
              items: [...prevCurrentCompany.members.items, memberAdded],
            },
          }));
          dispatch({
            type: mapContextAction.SET_USER_LIST,
            payload: state.userList.map((user) => {
              if (user.id === memberAdded.id) {
                return {
                  ...user,
                  companyId: memberAdded.companyId,
                };
              }
              return user;
            }),
          });
          setAlertMessage({
            message: "Added member",
            severity: "success",
          });
        } else {
          closeSidebar();
        }
        setFormData({ ...defaultState });
        setError({ ...defaultState });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setApiLoading(false);
    }
  };

  const memberOptions = state.userList.map((user) => ({
    name: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  return (
    <div>
      <p>Let's add a new company member.</p>
      {allowExisting && (
        <SelectDropdown
          label="Action"
          options={[
            { name: "Existing Member", value: 1 },
            { name: "Add New Member", value: 0 },
          ]}
          selectedValue={Number(formData.existing)}
          selectHandler={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              existing: !!e.target.value,
            }))
          }
          sx={{
            width: "100%",
            "& .MuiFormControl-root": {
              width: "100%",
              margin: "0px",
            },
          }}
        />
      )}
      {formData.existing && allowExisting && (
        <SelectDropdown
          label="Member"
          options={memberOptions.filter(
            (member) =>
              !existingMembers.find(
                (currMember) => currMember.id === member.value
              )
          )}
          selectedValue={formData.memberId}
          selectHandler={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              memberId: e.target.value,
            }))
          }
          sx={{
            width: "100%",
            marginTop: "20px",
            "& .MuiFormControl-root": {
              width: "100%",
              margin: "0px",
            },
          }}
        />
      )}
      {(!formData.existing || !allowExisting) && (
        <>
          <TextField
            label="First Name"
            placeholder="Enter first name"
            variant="outlined"
            value={formData.firstName}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                firstName: e.target.value,
              }))
            }
            error={!!error.firstName}
            helperText={error.firstName}
            fullWidth
            sx={{
              mt: "20px",
            }}
            required
          />
          <TextField
            label="Last Name"
            placeholder="Enter last name"
            variant="outlined"
            value={formData.lastName}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                lastName: e.target.value,
              }))
            }
            error={!!error.lastName}
            helperText={error.lastName}
            fullWidth
            sx={{
              mt: "20px",
            }}
            required
          />
          <TextField
            label="Mobile"
            placeholder="Enter mobile no"
            variant="outlined"
            value={formData.mobile}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                mobile: e.target.value,
              }))
            }
            error={!!error.mobile}
            helperText={error.mobile}
            fullWidth
            sx={{
              mt: "20px",
            }}
          />
          <TextField
            label="Email"
            placeholder="Enter email address"
            variant="outlined"
            value={formData.email}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                email: e.target.value,
              }))
            }
            error={!!error.email}
            helperText={error.email}
            fullWidth
            sx={{
              mt: "20px",
            }}
            required
          />
        </>
      )}
      {!apiLoading && (
        <Button
          onClick={handleAddCompanyMember}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none" }}
        >
          {/* <InsertInvitationIcon /> */}
          Add
        </Button>
      )}
      {!apiLoading && (
        <Button
          onClick={closeSidebar}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none", mx: 1 }}
          color="error"
        >
          {/* <InsertInvitationIcon /> */}
          Cancel
        </Button>
      )}
      {apiLoading ? (
        <Box sx={{ mt: 2 }}>
          <A4ALoader />
        </Box>
      ) : (
        ""
      )}

      {!!alertMessage.message && (
        <Alert severity={alertMessage.severity} sx={{ mt: 10 }}>
          {alertMessage.message}
        </Alert>
      )}
    </div>
  );
};

export { AddCompanyMember };
