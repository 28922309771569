import { Button, CircularProgress, Modal, TextField } from "@mui/material";
import React, { ChangeEvent, useRef, useState } from "react";
import {
  AirportDetailDrawerFileUploadContainer,
  AirportDetailDrawerFileUploadContainerBtnContainer,
  AirportDetailDrawerFileUploadContainerBtnText,
  AirportDetailDrawerFileUploadContainerHeading,
  AirportDetailDrawerFileUploadContainerUploadBox,
  AirportDetailDrawerFileUploadContainerUploadedFileName,
  AirportDetailDrawerFileUploadContainerUploadIcon,
  AirportDetailDrawerFileUploadContainerUploadText,
  AirportDetailsDrawerFileEditModalContent,
  AirportDetailsDrawerFileEditModalHeading,
} from "./style";
import {
  IAirport,
  IAirportDetail,
  IAirportDocument,
} from "../MapProvider/types";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import { DocumentService } from "../../services/DocumentService";
import { useAuth } from "../AuthProvider";
import { constants, helpers } from "../../utils";
import { DocumentCategoryTypeChoiceMapping } from "../../constants/map";
import { S3Service } from "../../services/S3Service";
import { ICurrentFolder } from "./FilesTab";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";

interface IAirportDetailsDrawerFileEditModal {
  airport?: IAirportDetail;
  currentFolder?: ICurrentFolder | null;
  document?: IAirportDocument;
  isModalOpened: boolean;
  closeModal: Function;
  closeTooltip?: Function;
  isEditMode?: boolean;
  categoryIdNameMapping?: any;
}

const AirportDetailsDrawerFileEditModal = ({
  airport,
  currentFolder,
  document,
  isModalOpened,
  closeModal,
  closeTooltip,
  isEditMode = false,
  categoryIdNameMapping = {},
}: IAirportDetailsDrawerFileEditModal) => {
  const {
    state: { user },
  } = useAuth();
  const { state, dispatch } = useMapContext();
  const [title, setTitle] = useState<string>(document?.title ?? "");
  const [description, setDescription] = useState<string>(
    document?.description ?? ""
  );
  const [titleError, setTitleError] = useState<string>();
  const [publishedDate, setPublishedDate] = useState<string>(
    document?.publishedDate
      ? document?.publishedDate.slice(0, 10)
      : new Date().toISOString().slice(0, 10)
  );
  const [draggingFileOver, setDraggingFileOver] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.[0]) {
      setUploadedFile(event.target.files[0]);
    }
  };

  const handleFileDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDraggingFileOver(true);
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDraggingFileOver(false);
    if (event?.dataTransfer.files?.[0]) {
      setUploadedFile(event.dataTransfer.files[0]);
    }
  };

  const handleFileUploadBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUpload = async () => {
    // if no file selected then return
    if (!uploadedFile) return;

    if (!title.trim()) {
      setTitleError("Title is required");
      return;
    }

    try {
      // if not allowed MIME type
      if (!constants?.ALLOWED_MIME_TYPE.includes(uploadedFile?.type)) {
        alert(`Wrong MIME Type: ${uploadedFile?.type}`);
        return;
      }

      const [file_name, file_ext] = uploadedFile.name.includes(".")
        ? uploadedFile.name.split(".")
        : [uploadedFile.name, ""];

      const dbFileName = `${file_name.replaceAll(
        " ",
        "-"
      )}_${helpers.getRandomString(6)}.${file_ext}`;

      let Key = "";
      let db_params: any = {};

      Key = `Airport Docs/${dbFileName}`;

      db_params = {
        ...db_params,
        airportId: airport?.id,
        organizationId: state.activeConsortium?.organizationId,
      };

      let params = {
        Key: Key,
        // Key: `Test Docs/${dbFileName}`,
        Body: uploadedFile,
      };

      db_params = {
        ...db_params,
        publishedDate: new Date(publishedDate).toISOString(),
        bucketType: constants?.DocumentBucketTypeChoice.AIRPORT,
        documentCategoryId: currentFolder?.category,
        url: dbFileName,
        title,
        description,
        fileSize: uploadedFile?.size,
        companyId: state.activeConsortium?.operatorId,
        publishedByUserId: user?.dbUserId,
        createByUserId: user?.dbUserId,
      };

      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload:
          "Uploading file to folder " +
          categoryIdNameMapping[currentFolder!.category],
      });

      const s3s = new S3Service();
      const ds = new DocumentService();

      await s3s.putObject(params);
      const addedDoc = await ds.createDocument(db_params);

      setUploadedFile(undefined);

      dispatch({
        type: mapContextAction.SET_ACTIVE_AIRPORT,
        payload: {
          ...state.activeAirport,
          documents: {
            ...state.activeAirport?.documents,
            items: [...(state.activeAirport?.documents?.items ?? []), addedDoc],
          },
        },
      });

      // update data provider state
      dataProviderDispatch({
        type: dataContextAction.SET_AIRPORT_DOCUMENT_LIST,
        payload: [...dataProviderState?.airportDocumentList, addedDoc],
      });

      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "",
      });
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: "File uploaded successfully",
          type: "success",
        },
      });
    } catch (err) {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "",
      });
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: "Error occured while uploading File.",
          type: "error",
        },
      });
    } finally {
      closeModal();
      setTitle("");
      setDescription("");
      setTitleError("");
      setUploadedFile(undefined);
    }
  };

  const editHandler = async () => {
    if (!title.trim()) {
      setTitleError("Title is required");
      return;
    }
    try {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: `Updating ${document?.title}`,
      });
      const ds = new DocumentService();
      const updatedDoc = await ds.updateDocumentById({
        id: document?.id,
        title,
        description,
        publishedDate: publishedDate.endsWith("Z")
          ? publishedDate
          : publishedDate + "T00:00:00.000Z",
      });
      if (updatedDoc) {
        dispatch({
          type: mapContextAction.SET_ACTIVE_AIRPORT,
          payload: {
            ...state.activeAirport,
            documents: {
              ...state.activeAirport?.documents,
              items: state.activeAirport?.documents?.items.map((doc) => {
                if (doc?.id === updatedDoc?.id) {
                  return { ...doc, ...updatedDoc };
                }
                return doc;
              }),
            },
          },
        });

        // update data provider state
        dataProviderDispatch({
          type: dataContextAction.SET_AIRPORT_DOCUMENT_LIST,
          payload: dataProviderState?.airportDocumentList?.map((document) => {
            if (document?.id === updatedDoc?.id) {
              return updatedDoc;
            }

            return document;
          }),
        });
      }
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "",
      });
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: "File updated successfully",
          type: "success",
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: "An error occured while updating file",
          type: "error",
        },
      });
    } finally {
      closeModal();
      if (closeTooltip) closeTooltip();
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "",
      });
    }
  };

  return (
    <>
      <Modal
        sx={{ zIndex: "99999998" }}
        open={isModalOpened}
        onClose={() => closeModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AirportDetailsDrawerFileEditModalContent>
          <AirportDetailsDrawerFileEditModalHeading>
            {isEditMode ? "Edit document" : "Add document"}
          </AirportDetailsDrawerFileEditModalHeading>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            sx={{ mt: "20px" }}
            error={!!titleError}
            helperText={titleError}
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            sx={{ mt: "15px" }}
          />
          <TextField
            label="Document date"
            value={publishedDate}
            type="date"
            onChange={(e) => {
              setPublishedDate(e.target.value);
            }}
            fullWidth
            sx={{ mt: "15px" }}
          />
          {!isEditMode && (
            <AirportDetailDrawerFileUploadContainer>
              <AirportDetailDrawerFileUploadContainerHeading>
                Upload File
              </AirportDetailDrawerFileUploadContainerHeading>
              <AirportDetailDrawerFileUploadContainerUploadBox
                onDragOver={handleFileDragOver}
                onDragLeave={() => setDraggingFileOver(false)}
                onDrop={handleFileDrop}
                onClick={handleFileUploadBoxClick}
                sx={
                  draggingFileOver
                    ? {
                        backgroundColor: "#77777755",
                        border: "1px solid #77777755",
                        borderStyle: "solid",
                      }
                    : {}
                }
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{
                    display: "none",
                  }}
                />
                <AirportDetailDrawerFileUploadContainerUploadText>
                  Drag and drop a file here or click
                </AirportDetailDrawerFileUploadContainerUploadText>
                {!!uploadedFile && (
                  <AirportDetailDrawerFileUploadContainerUploadedFileName>
                    {uploadedFile?.name ?? ""}
                  </AirportDetailDrawerFileUploadContainerUploadedFileName>
                )}
                <AirportDetailDrawerFileUploadContainerUploadIcon />
              </AirportDetailDrawerFileUploadContainerUploadBox>
              <AirportDetailDrawerFileUploadContainerBtnContainer>
                <AirportDetailDrawerFileUploadContainerBtnText
                  onClick={() => {
                    setUploadedFile(undefined);
                  }}
                >
                  Cancel file
                </AirportDetailDrawerFileUploadContainerBtnText>
                {/* <AirportDetailDrawerFileUploadContainerBtnText
                  onClick={handleUpload}
                >
                  Upload
                </AirportDetailDrawerFileUploadContainerBtnText> */}
              </AirportDetailDrawerFileUploadContainerBtnContainer>
            </AirportDetailDrawerFileUploadContainer>
          )}
          <Button
            sx={{ marginTop: "20px" }}
            variant="contained"
            onClick={isEditMode ? editHandler : handleUpload}
          >
            {isEditMode ? "Save changes" : "Upload"}
          </Button>
        </AirportDetailsDrawerFileEditModalContent>
      </Modal>
    </>
  );
};

export default AirportDetailsDrawerFileEditModal;
