import React from "react";
import { S3Service } from "../../services/S3Service";
import { helpers } from "../../utils";
import { A4ALoader } from "../Common/A4ALoader";
import { constants } from "../../utils";
import { DocumentService } from "../../services/DocumentService";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
import { TextFieldInput } from "../Common/TextFieldInput";

import {
  FileUploadContainer,
  FileUploadContainerBtnContainer,
  FileUploadContainerBtnText,
  FileUploadContainerHeading,
  FileUploadContainerUploadBox,
  FileUploadContainerUploadIcon,
  FileUploadContainerUploadText,
  FileUploadContainerUploadedFileName,
} from "./style";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DocumentSchema } from "./../../validations/schema";
import dayjs from "dayjs";
import { useAuth } from "./../AuthProvider";

interface IUploadDocument {
  bucketType: string;
  categoryType: string;
  airportId?: string;
  organizationId?: string;
  closeSidebar?: () => void;
}

const defaultLoaderState = {
  data: false,
  upload: false,
};

const defaultFormState = {
  description: "",
  publishedDate: dayjs(new Date()),
  fileName: "",
};

const defaultFormErrorState = {
  description: "",
  publishedDate: "",
  fileName: "",
};

const UploadDocument: React.FC<IUploadDocument> = ({
  bucketType,
  categoryType,
  airportId,
  organizationId,
  closeSidebar = () => {},
}) => {
  const [loaderState, setLoaderState] = React.useState({
    ...defaultLoaderState,
  });

  const [uploadedFile, setUploadedFile] = React.useState<File>();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [draggingFileOver, setDraggingFileOver] =
    React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<any>({ ...defaultFormState });
  const [formError, setFormError] = React.useState({
    ...defaultFormErrorState,
  });

  const { state, dispatch } = useDataContext();
  const { state: auth } = useAuth();

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setFormError({ ...defaultFormErrorState });

    let result: any = {};

    result = DocumentSchema.uploadDocumentValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setFormError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setFormError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  // send files to the server // learn from my other video
  const handleUpload = async () => {
    var isValidationError = false;

    // if no file selected then return
    if (uploadedFile === null || uploadedFile === undefined) return;

    // if not allowed MIME type
    if (!constants?.ALLOWED_MIME_TYPE.includes(uploadedFile?.type)) {
      alert(`Wrong MIME Type: ${uploadedFile?.type}`);
      return;
    }

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      let isValid = handleValidation(key, formData[key]);

      if (key === "publishedDate" && formData[key] !== "") {
        isValid = handleValidation(key, new Date(formData[key]).toISOString());
      } else if (key === "publishedDate" && formData[key] === "") {
        isValid = handleValidation(key, "");
      } else {
        isValid = handleValidation(key, formData[key]);
      }

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    // if validation error
    if (isValidationError) return;

    const [file_name, file_ext] = uploadedFile?.name.includes(".")
      ? uploadedFile?.name.split(".")
      : [uploadedFile?.name, ""];

    // const dbFileName = `${file_name.replaceAll(
    //   " ",
    //   "-"
    // )}_${helpers.getRandomString(6)}.${file_ext}`;

    const dbFileName = `${formData?.fileName.replaceAll(
      " ",
      "-"
    )}_${helpers.getRandomString(6)}.${file_ext}`;

    let Key = "";
    let db_params: any = {};

    // if bucket is GENERAL
    if (bucketType === constants?.DocumentBucketTypeChoice.GENERAL) {
      Key = `General Docs/${dbFileName}`;

      db_params = {
        ...db_params,
        airportId: null,
        organizationId: null,
      };
    } else if (bucketType === constants?.DocumentBucketTypeChoice.AIRPORT) {
      Key = `Airport Docs/${dbFileName}`;

      db_params = {
        ...db_params,
        airportId: airportId,
        organizationId: organizationId,
      };
    }

    let params = {
      Key: Key,
      // Key: `Test Docs/${dbFileName}`,
      Body: uploadedFile,
    };

    db_params = {
      ...db_params,
      bucketType: bucketType,
      documentCategoryId: categoryType,
      url: dbFileName,
      // title: dbFileName,
      title: formData?.fileName,
      description: formData?.description,
      fileSize: uploadedFile?.size,
      publishedDate: new Date(formData?.publishedDate).toISOString(),
      publishedByUserId: auth?.user?.dbUserId,
      createByUserId: auth?.user?.dbUserId,
    };

    // start loader
    setLoaderState((prev) => ({ ...prev, upload: true }));

    const s3s = new S3Service();
    const ds = new DocumentService();

    const temp = await s3s.putObject(params);
    const t2 = await ds.createDocument(db_params);

    if (t2 !== null) {
      const payload = {
        ...state?.globalDocumentList,
        [t2?.documentCategoryId]: state.globalDocumentList[
          t2?.documentCategoryId
        ]?.length
          ? [...state.globalDocumentList[t2?.documentCategoryId], t2]
          : [t2],
      };

      dispatch({
        type: dataContextAction.SET_GLOBAL_DOCUMENT_LIST,
        payload,
      });
    }

    setUploadedFile(undefined);

    // stop loader
    setLoaderState((prev) => ({ ...prev, upload: false }));

    // close sidebar
    closeSidebar();
  };

  const handleFileUpload = (event: any) => {
    if (event?.target?.files?.[0]) {
      setUploadedFile(event.target.files[0]);

      // set file name
      setFormData((prev: any) => ({
        ...prev,
        fileName: getFileName(event.target.files[0]?.name),
      }));
    } else {
      // clear file name
      setFormData((prev: any) => ({
        ...prev,
        fileName: "",
      }));
    }
  };

  const handleFileDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDraggingFileOver(true);
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDraggingFileOver(false);
    if (event?.dataTransfer.files?.[0]) {
      setUploadedFile(event.dataTransfer.files[0]);
    }
  };

  const handleFileUploadBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getFileName = (name: string = "") => {
    if (name === "") return;

    const [file_name, file_ext] = name.includes(".")
      ? (name.split(".") as any)
      : ([name, ""] as any);

    return file_name;
  };

  const handleCancelUpload = () => {
    // clear form data
    setFormData({ ...defaultFormState });
    // clear form error
    setFormError({ ...defaultFormErrorState });
    // clearn file
    setUploadedFile(undefined);

    // close sidebar
    closeSidebar();
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {!state?.isGlobalDocumentListApiLoading && (
          <FileUploadContainer>
            <FileUploadContainerHeading>Upload File</FileUploadContainerHeading>
            <TextFieldInput
              id="fileName"
              name="fileName"
              placeholder="File Name"
              label="File Name"
              error={formError.fileName}
              value={formData.fileName}
              // margin="normal"
              handleChange={handleChange}
              onBlur={() => handleValidation("fileName", formData.fileName)}
              // autoFocus
              fullWidth
              required
              sx={{ my: 1 }}
            />
            <TextFieldInput
              id="description"
              name="description"
              placeholder="Description"
              label="Description"
              error={formError.description}
              value={formData.description}
              // margin="normal"
              handleChange={handleChange}
              onBlur={() =>
                handleValidation("description", formData.description)
              }
              // autoFocus
              fullWidth
              required
            />
            <DatePicker
              name="publishedDate"
              label="Document Date"
              defaultValue={dayjs(new Date())}
              value={
                formData?.publishedDate !== "" ? formData?.publishedDate : null
              }
              onChange={(selectedDate) => {
                if (
                  selectedDate !== null &&
                  `${selectedDate["$d"]}` !== "Invalid Date"
                ) {
                  setFormData((prev: any) => ({
                    ...prev,
                    publishedDate: selectedDate,
                  }));

                  handleValidation(
                    "publishedDate",
                    new Date(selectedDate).toISOString()
                  );
                } else {
                  // set validation error
                  handleValidation("publishedDate", "");

                  setFormData((prev: any) => ({
                    ...prev,
                    publishedDate: "",
                  }));
                }
              }}
              onError={(error) => {
                if (error !== null) {
                  // set validation error
                  handleValidation("publishedDate", "");
                  setFormData((prev: any) => ({
                    ...prev,
                    publishedDate: "",
                  }));
                }
              }}
              sx={{ width: "100%", mb: 1 }}
            />
            {formError.publishedDate !== "" && (
              <p style={{ color: "red" }}>{formError.publishedDate}</p>
            )}
            <FileUploadContainerUploadBox
              onDragOver={handleFileDragOver}
              onDragLeave={() => setDraggingFileOver(false)}
              onDrop={handleFileDrop}
              onClick={handleFileUploadBoxClick}
              sx={
                draggingFileOver
                  ? {
                      backgroundColor: "#77777755",
                      border: "1px solid #77777755",
                      borderStyle: "solid",
                    }
                  : {}
              }
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{
                  display: "none",
                }}
              />
              <FileUploadContainerUploadText>
                Drag and drop a file here or click
              </FileUploadContainerUploadText>
              {!!uploadedFile && (
                <FileUploadContainerUploadedFileName>
                  {uploadedFile?.name ?? ""}
                </FileUploadContainerUploadedFileName>
              )}
              <FileUploadContainerUploadIcon />
            </FileUploadContainerUploadBox>
            {!loaderState.upload && (
              <FileUploadContainerBtnContainer>
                <FileUploadContainerBtnText onClick={handleCancelUpload}>
                  Cancel
                </FileUploadContainerBtnText>
                <FileUploadContainerBtnText onClick={handleUpload}>
                  Upload
                </FileUploadContainerBtnText>
              </FileUploadContainerBtnContainer>
            )}
            {loaderState.upload && <A4ALoader />}
          </FileUploadContainer>
        )}
      </LocalizationProvider>
    </>
  );
};

export { UploadDocument };
