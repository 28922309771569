import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { CardContent, Typography, Button, ButtonGroup } from "@mui/material";
import { Sidebar } from "./../Common/Sidebar";
import { constants, helpers } from "./../../utils";
import { AddOrganization } from "./../AddOrganization";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "./../AuthProvider";

import * as SkeletonLoader from "./../SkeletonLoader";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
import {
  MapProvider,
  mapContextAction,
  useMapContext,
} from "../MapProvider/MapProvider";
import { v4 as uuidv4 } from "uuid";
import { DataTableBase } from "./../Common/DataTableBase";
import {
  OrganizationCellText,
  OrganizationCodeNameLink,
  OrganizationHeading,
  OrganizationNewButton,
  OrganizationTabButton,
  OrganizationTabButtonGroup,
} from "./style";
import { useScreenSize } from "../../hooks";

interface IGetListView {
  filteredOrgList: Array<any>;
  tableTitle: string;
  consortiumViewType: string;
}

const GetMemomizedListView: React.FC<IGetListView> = React.memo(
  ({ filteredOrgList = [], tableTitle = "Table Data", consortiumViewType }) => {
    const { dispatch: mapStateDispatch } = useMapContext();

    let columns = [];

    if (
      consortiumViewType === constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM
    ) {
      columns = [
        {
          name: "Code",
          grow: 0.1,
          selector: (row: any) => row?.code,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCodeNameLink
              to={"/main"}
              onClick={() => {
                mapStateDispatch({
                  type: mapContextAction.SET_CURRENT_FOCUSED_AIRPORT,
                  // payload: row.code,
                  payload: row.code,
                });
              }}
            >
              {row.code}
            </OrganizationCodeNameLink>
          ),
        },
        {
          name: "Name",
          grow: 1.5,
          selector: (row: any) => row?.name,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCodeNameLink to={`/org/${row?.orgId}`} title={row.name}>
              {row.name}
            </OrganizationCodeNameLink>
          ),
        },
        {
          name: "Type",
          selector: (row: any) => row?.type,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCellText>
              {row?.type ?? ''}
            </OrganizationCellText>
          ),
        },
        {
          name: "Chair Company",
          selector: (row: any) => row?.chair,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCellText>
              {row?.chair ?? ''}
            </OrganizationCellText>
          ),
        },
        {
          name: "Chair Contact",
          selector: (row: any) => row?.chair_email,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCellText>
              {row?.chair_email ?? ''}
            </OrganizationCellText>
          ),
        },
        {
          name: "GM Company",
          selector: (row: any) => row?.gm,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCellText>
              {row?.gm ?? ''}
            </OrganizationCellText>
          ),
        },
        {
          name: "GM Contact",
          selector: (row: any) => row?.gm_email,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCellText>
              {row?.gm_email ?? ''}
            </OrganizationCellText>
          ),
        },
      ];
    } else {
      columns = [
        {
          name: "Code",
          grow: 0.1,
          selector: (row: any) => row?.code,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCodeNameLink
              to={"/main"}
              onClick={() => {
                mapStateDispatch({
                  type: mapContextAction.SET_CURRENT_FOCUSED_AIRPORT,
                  // payload: row.code,
                  payload: row.code,
                });
              }}
            >
              {row.code}
            </OrganizationCodeNameLink>
          ),
        },
        {
          name: "Name",
          grow: 1.5,
          selector: (row: any) => row?.name,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCodeNameLink to={`/org/${row?.orgId}`} title={row.name}>
              {row.name}
            </OrganizationCodeNameLink>
          ),
        },
        {
          name: "Type",
          selector: (row: any) => row?.type,
          sortable: true,
          cell: (row: any) => (
            <OrganizationCellText>
              {row?.type ?? ''}
            </OrganizationCellText>
          ),
        },
      ];
    }

    const tableData: Array<any> = filteredOrgList?.map((item) => ({
      id: uuidv4(),
      // id: item?.mapId,
      // id: item?.id,
      // code: item?.airports?.items
      //   .map((item: any) => item?.airport?.code)
      //   .join(","),
      orgId: item?.id,
      code: item?.airport?.code,
      name: item?.name,
      type: constants?.OrganizationTypeChoiceLabelMapping[
        item?.organizationType
          ? item?.organizationType
          : constants?.OrganizationTypeChoice?.GENERAL
      ],
      chair: item?.chair?.name,
      // chair_email: item?.chairContact?.email,
      chair_email:
        item?.chairContact?.firstName !== null &&
        item?.chairContact?.firstName !== undefined &&
        item?.chairContact?.lastName !== null &&
        item?.chairContact?.lastName !== undefined
          ? `${item?.chairContact?.firstName} ${item?.chairContact?.lastName}`
          : "",
      // gm: item?.operator?.name,
      gm: item?.operator?.name,
      // gm_email: item?.operatorContact?.email,
      // gm_email: item?.operatorGeneralManager?.email,
      gm_email:
        item?.operatorGeneralManager?.firstName !== null &&
        item?.operatorGeneralManager?.lastName !== null &&
        item?.operatorGeneralManager?.firstName !== undefined &&
        item?.operatorGeneralManager?.lastName !== undefined
          ? `${item?.operatorGeneralManager?.firstName} ${item?.operatorGeneralManager?.lastName}`
          : "",
    }));

    return (
      <DataTableBase
        columns={columns}
        data={tableData}
        dense={false}
        fixedHeader
        pagination={false}
        persistTableHead
        highlightOnHover
      />
    );
  }
);

const ViewOrganization = () => {
  // state
  const [openAddOrganization, setOpenAddOrganization] =
    useState<boolean>(false);
  const [orgList, setOrgList] = React.useState<Array<any>>([]);
  const [filteredOrgList, setFilteredOrgList] = React.useState<Array<any>>([]);
  const [isSuperUser, setSuperUser] = React.useState<boolean>(false);

  const { state: auth } = useAuth();
  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();
  const navigateTo = useNavigate();
  const [selectedBtnGroup, setSelectedBtnGroup] = React.useState<string>(
    constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM
  );
  const { width } = useScreenSize();

  // if signed user is only member not admin to any org & also not A4A Admin
  const isUserOnlyOrgMember =
    auth?.user?.orgRole?.admin?.length === 0 &&
    !auth?.user?.groups?.includes(constants.ROLES.A4A_ADMIN) &&
    auth?.user?.orgRole?.member?.length !== 0 &&
    auth?.user?.orgRole?.supportAdmin?.length === 0;

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.ORGANIZATION;

    // is signed user is A4A Admin
    if (auth?.user?.groups?.includes(constants.ROLES.A4A_ADMIN)) {
      setSuperUser(true);
    }

    // if user is only org member then redirect to main page
    if (isUserOnlyOrgMember) {
      // navigate to main page
      navigateTo("/main");
    }

    // clear global search bar text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
      payload: "",
    });

    // set global search bar placeholder text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT,
      payload: "Search consortiums...",
    });

    // cleanup
    return () => {};
  }, []);

  useEffect(() => {
    // update state
    setOrgList([...dataProviderState?.organizationList]);

    // set filtered data
    setFilteredOrgList([...dataProviderState?.organizationList]);
  }, [dataProviderState?.organizationList]);

  useEffect(() => {
    // change page title
    if (openAddOrganization) {
      document.title = constants.PAGE_TITLE.ADD_ORGANIZATION;
    } else {
      document.title = constants.PAGE_TITLE.ORGANIZATION;
    }
  }, [openAddOrganization]);

  useEffect(() => {
    let timeoutId: any = null;

    let searchText = dataProviderState?.topBarSearchText;

    if (searchText.trim() !== "") {
      timeoutId = setTimeout(() => {
        const generalFilter = orgList?.filter(
          (item: any) =>
            item?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            // Chair filter
            item?.chair?.name
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            `${item?.chairContact?.firstName} ${item?.chairContact?.lastName}`
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item?.chairContact?.mobile
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item?.chairContact?.email
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            // Operator filter
            item?.operator?.name
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            // `${item?.operatorContact?.firstName} ${item?.operatorContact?.lastName}`
            //   .toLowerCase()
            //   .includes(searchText.toLowerCase()) ||
            `${item?.operatorGeneralManager?.firstName} ${item?.operatorContact?.lastName}`
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            // item?.operatorContact?.mobile
            //   ?.toLowerCase()
            //   .includes(searchText.toLowerCase()) ||
            item?.operatorGeneralManager?.mobile
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            // item?.operatorContact?.email
            //   ?.toLowerCase()
            //   .includes(searchText.toLowerCase())
            item?.operatorGeneralManager?.email
              ?.toLowerCase()
              .includes(searchText.toLowerCase())
        );

        let orgLoopFilter: any = [];

        orgList?.forEach((item: any) => {
          const isAdminFilterMatched =
            item?.admins?.items?.filter(
              (itm: any) =>
                `${itm?.user?.firstName} ${itm?.user?.lastName}`
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                itm?.user?.mobile
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                itm?.user?.email
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length !== 0;

          const isMemberFilterMatched =
            item?.members?.items?.filter(
              (itm: any) =>
                `${itm?.user?.firstName} ${itm?.user?.lastName}`
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                itm?.user?.mobile
                  ?.toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                itm?.user?.email
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
            ).length !== 0;

          // const isAirportFilterMatched =
          //   item?.airports?.items?.filter(
          //     (itm: any) =>
          //       itm?.airport?.name
          //         ?.toLowerCase()
          //         .includes(searchText.toLowerCase()) ||
          //       itm?.airport?.code
          //         ?.toLowerCase()
          //         .includes(searchText.toLowerCase()) ||
          //       itm?.airport?.oldCode
          //         ?.toLowerCase()
          //         .includes(searchText.toLowerCase())
          //   ).length !== 0;

          const isAirportFilterMatched =
            item?.airport?.name
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item?.airport?.code
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item?.airport?.oldCode
              ?.toLowerCase()
              .includes(searchText.toLowerCase());

          const isCompanyFilterMatched =
            item?.companies?.items?.filter((itm: any) =>
              itm?.name?.toLowerCase().includes(searchText.toLowerCase())
            ).length !== 0;

          const isPendingInviteFilterMatched =
            item?.pending?.filter(
              (itm: any) =>
                itm?.deleted !== true &&
                (`${itm?.user?.firstName} ${itm?.user?.lastName}`
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                  itm?.user?.email
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase()))
            ).length !== 0;

          if (isAdminFilterMatched) {
            orgLoopFilter = [...orgLoopFilter, item];
          }

          if (isMemberFilterMatched) {
            orgLoopFilter = [...orgLoopFilter, item];
          }

          if (isAirportFilterMatched) {
            orgLoopFilter = [...orgLoopFilter, item];
          }

          if (isCompanyFilterMatched) {
            orgLoopFilter = [...orgLoopFilter, item];
          }

          if (isPendingInviteFilterMatched) {
            orgLoopFilter = [...orgLoopFilter, item];
          }
        });

        // add all filtered data
        const filtered = [...generalFilter, ...orgLoopFilter];

        // remove duplicates from array
        const unique = filtered.filter((obj, index) => {
          return index === filtered.findIndex((o) => obj.id === o.id);
        });

        // udpate organization list
        setFilteredOrgList([...unique]);
      }, 1000);
    } else {
      // udpate organization list
      setFilteredOrgList([...dataProviderState?.organizationList]);
      // setFilteredOrgList([...dataProviderState?.airportConsortiumGroupList]);
    }

    return () => clearTimeout(timeoutId);
  }, [dataProviderState?.topBarSearchText]);

  return (
    <>
      <Grid sx={{ mt: 2 }}>
        <Card
          style={{
            padding: "20px 5px",
            margin: "0 auto",
            borderRadius: "16px",
            background: "rgb(96, 96, 96, 0.05)",
            minHeight: "75vh",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "baseline",
                mb: 3,
              }}
            >
              <OrganizationHeading gutterBottom variant="h6">
                User Access
              </OrganizationHeading>
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <OrganizationTabButtonGroup
                  variant="outlined"
                  color="primary"
                  // aria-label="outlined primary button group"
                  size="small"
                  disableElevation
                  orientation={width <= 600 ? "vertical" : "horizontal"}
                >
                  {constants?.DB_ENUM?.OrganizationTypeChoice?.map(
                    (type: string, idx: number) => (
                      <OrganizationTabButton
                        sx={{
                          borderTopLeftRadius: idx === 0 ? 50 : 0,
                          borderBottomLeftRadius: idx === 0 ? 50 : 0,
                          borderTopRightRadius:
                            idx ===
                            constants?.DB_ENUM?.OrganizationTypeChoice?.length -
                              1
                              ? 50
                              : 0,
                          borderBottomRightRadius:
                            idx ===
                            constants?.DB_ENUM?.OrganizationTypeChoice?.length -
                              1
                              ? 50
                              : 0,
                          textTransform: "none",
                        }}
                        variant={
                          selectedBtnGroup ===
                          constants?.DB_ENUM?.OrganizationTypeChoice[idx]
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() =>
                          setSelectedBtnGroup(
                            constants?.DB_ENUM?.OrganizationTypeChoice[idx]
                          )
                        }
                        key={`tabTxt_${type}_${idx}`}
                      >
                        {constants?.OrganizationTypeChoiceLabelMapping[type]}
                      </OrganizationTabButton>
                    )
                  )}
                </OrganizationTabButtonGroup>
              </Box>
              <Box sx={{ float: "right" }}>
                <OrganizationNewButton
                  variant="contained"
                  size="small"
                  onClick={() => setOpenAddOrganization((prev) => !prev)}
                  disabled={!isSuperUser}
                >
                  New
                </OrganizationNewButton>
              </Box>
            </Box>
            {dataProviderState.isOrganizationListApiLoading && (
              <SkeletonLoader.ViewOrganization />
            )}
            {!dataProviderState.isOrganizationListApiLoading && (
              <>
                {selectedBtnGroup ===
                  constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM && (
                  <GetMemomizedListView
                    filteredOrgList={filteredOrgList?.filter(
                      (item) =>
                        item?.organizationType ===
                        constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM
                    )}
                    tableTitle={helpers.capitalizeFirstLetterEveryWord(
                      constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM.replaceAll(
                        "_",
                        " "
                      ).toLowerCase()
                    )}
                    consortiumViewType={
                      constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM
                    }
                  />
                )}
                {selectedBtnGroup ===
                  constants?.OrganizationTypeChoice?.ENERGY_COUNCIL && (
                  <GetMemomizedListView
                    filteredOrgList={filteredOrgList?.filter(
                      (item) =>
                        item?.organizationType ===
                        constants?.OrganizationTypeChoice?.ENERGY_COUNCIL
                    )}
                    tableTitle={helpers.capitalizeFirstLetterEveryWord(
                      constants?.OrganizationTypeChoice?.ENERGY_COUNCIL.replaceAll(
                        "_",
                        " "
                      ).toLowerCase()
                    )}
                    consortiumViewType={
                      constants?.OrganizationTypeChoice?.ENERGY_COUNCIL
                    }
                  />
                )}
                {selectedBtnGroup ===
                  constants?.OrganizationTypeChoice?.GENERAL && (
                  <GetMemomizedListView
                    filteredOrgList={filteredOrgList?.filter(
                      (item) =>
                        item?.organizationType ===
                          constants?.OrganizationTypeChoice?.GENERAL ||
                        item?.organizationType === null ||
                        item?.organizationType === ""
                    )}
                    tableTitle={helpers.capitalizeFirstLetterEveryWord(
                      constants?.OrganizationTypeChoice?.GENERAL.replaceAll(
                        "_",
                        " "
                      ).toLowerCase()
                    )}
                    consortiumViewType={
                      constants?.OrganizationTypeChoice?.GENERAL
                    }
                  />
                )}
              </>
            )}
          </CardContent>
        </Card>
      </Grid>
      {openAddOrganization && (
        <Sidebar
          handleClose={() => setOpenAddOrganization(false)}
          open={openAddOrganization}
        >
          <AddOrganization closeSidebar={() => setOpenAddOrganization(false)} />
        </Sidebar>
      )}
    </>
  );
};

export { ViewOrganization };
