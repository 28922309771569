import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";

class OrgSupportAdminUserSubscription {
  /*
    Task: Get OrgSupportAdminUser subscription on create from amplify graphql
  */
  static getOnCreate() {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateOrgSupportAdminUser)
    ) as any;
  }

  /*
    Task: Get OrgSupportAdminUser subscription on update from amplify graphql
  */
  static getOnUpdate() {
    return API.graphql(
      graphqlOperation(subscriptions.onUpdateOrgSupportAdminUser)
    ) as any;
  }

  /*
    Task: Get OrgSupportAdminUser subscription on delete from amplify graphql
  */
  static getOnDelete() {
    return API.graphql(
      graphqlOperation(subscriptions.onDeleteOrgSupportAdminUser)
    ) as any;
  }
}

export { OrgSupportAdminUserSubscription };
