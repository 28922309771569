import {
  Alert,
  Backdrop,
  Box,
  Button,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AppsIcon from "@mui/icons-material/Apps";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ReactComponent as FolderIcon } from "../../assets/Folder-icon.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

export const AirportDetailDrawerWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  zIndex: 5,
  width: "100%",
  height: "calc(100vh - 100px)",
  backgroundColor: "#00000033",
  display: "flex",
  justifyContent: "flex-end",
}));

export const AirportDetailDrawerContainer = styled(Box)(({ theme }) => ({
  width: "40%",
  minWidth: "550px",
  maxWidth: "550px",
  backgroundColor: "white",
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto",
  padding: "15px",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    minWidth: "100%",
  },
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#A0A0A0",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#f1f1f1",
    borderRadius: "10px",
  },
  scrollbarWidth: "thin",
  scrollbarColor: "#888 #f1f1f1",
}));

export const AirportDetailDrawerOverlayLoader = styled(Backdrop)(
  ({ theme }) => ({
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "calc(100vh - 100px)",
    backgroundColor: "#FFFa",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "18px",
    zIndex: "999999999",
    [theme.breakpoints.down("md")]: {
      rowGap: "12px",
    },
  })
);

export const AirportDetailDrawerOverlayText = styled(Typography)(
  ({ theme }) => ({
    fontSize: "18px",
    color: "#0288d1",
    [theme.breakpoints.down("md")]: {
      rowGap: "14px",
    },
  })
);

export const AirportDetailDrawerAlertWrapper = styled(Snackbar)(() => ({
  position: "absolute",
  left: "50% !important",
  transform: "translateX(-50%) !important",
  top: "50px !important",
  zIndex: "10",
}));

export const AirportDetailDrawerAlert = styled(Alert)(({ theme }) => ({
  fontSize: "20px",
  zIndex: "10",
  "& svg": {
    fontSize: "30px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    "& svg": {
      fontSize: "22px",
    },
  },
}));

export const AirportDetailDrawerHeader = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const AirportDetailDrawerHeading = styled(Box)(({ theme }) => ({
  fontSize: "25px",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontSize: "21px",
  },
}));

export const AirportDetailDrawerSubHeading = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
  },
}));

export const AirportDetailDrawerCloseIcon = styled(Box)(({ theme }) => ({
  fontSize: "32px",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
}));

export const AirportDetailDrawerSubHeader = styled(Box)(({ theme }) => ({
  marginTop: "8px",
  display: "flex",
  alignItems: "center",
  columnGap: "10px",
  [theme.breakpoints.down("md")]: {
    marginTop: "5px",
    columnGap: "7.5px",
  },
}));

export const AirportDetailDrawerConsortiumHeader = styled(Box)(({ theme }) => ({
  marginTop: "25px",
  [theme.breakpoints.down("md")]: {
    marginTop: "15px",
  },
}));

export const AirportDetailDrawerConsortiumLabel = styled(Typography)(
  ({ theme }) => ({
    fontSize: "18px",
    color: "black",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  })
);

export const AirportDetailDrawerTabsContainer = styled(Tabs)(({ theme }) => ({
  width: "85%",
  borderRadius: "35px",
  border: "1px solid #A0A0A0",
  margin: "0 auto",
  marginTop: "25px",
  height: "38px",
  minHeight: "38px",
  "& .Mui-selected": {
    backgroundColor: "#c6bccbaa",
    color: "black !important",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
  [theme.breakpoints.down("md")]: {
    borderRadius: "25px",
    marginTop: "15px",
    height: "32px",
    minHeight: "32px",
  },
}));

export const AirportDetailDrawerTab = styled(Tab)(({ theme }) => ({
  flex: "1",
  borderRight: "1px solid #A0A0A0",
  height: "38px",
  minHeight: "38px",
  fontSize: "12px",
  fontWeight: "bold",
  color: "black !important",
  minWidth: "unset !important",
  "&:last-of-type": {
    borderRight: "none",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "10px",
    height: "32px",
    minHeight: "32px",
  },
}));

export const AirportDetailDrawerEditIcons = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  columnGap: "15px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    columnGap: "10px",
  },
}));

export const AirportDetailDrawerInfoCardEditIcon = styled(EditIcon)(
  ({ theme }) => ({
    fontSize: "25px",
    cursor: "pointer",
    color: "#555",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  })
);

export const AirportDetailDrawerInfoCardCloseIcon = styled(CloseIcon)(
  ({ theme }) => ({
    fontSize: "25px",
    cursor: "pointer",
    color: "#555",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  })
);

export const AirportDetailDrawerInfoCardDeleteIcon = styled(DeleteOutlineIcon)(
  ({ theme }) => ({
    fontSize: "25px",
    cursor: "pointer",
    color: "#555",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  })
);

export const AirportDetailDrawerInfoBtns = styled(Box)(({ theme }) => ({
  marginTop: "15px",
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    marginTop: "10px",
  },
}));

export const AiportDetailDrawerViewMoreBtn = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
  },
}));

export const AirportDetailDrawerInfoCardSaveButton = styled(Button)(
  ({ theme }) => ({
    fontSize: "13px",
    cursor: "pointer",
    color: "white",
    backgroundColor: "#0288d1",
    padding: "10px",
    ":hover": {
      backgroundColor: "#0288d1aa",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "11px",
      padding: "7px",
    },
  })
);

export const AirportDetailDrawerTabCustomPanel = styled(Box)(({ theme }) => ({
  marginTop: "12px",
  [theme.breakpoints.down("md")]: {
    marginTop: "9px",
  },
}));

export const AirportDetailDrawerContactSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
}));

export const AirportDetailDrawerInformationSection = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  // marginTop: "20px",
  flexWrap: "wrap",
}));

export const AirportDetailDrawerInfoCardContainer = styled(Box)(
  ({ theme }) => ({
    maxWidth: "138px",
    width: "138px",
    marginTop: "20px",
    "&.data-tab-card": {
      maxWidth: "110px",
      width: "110px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "118px",
      width: "118px",
      marginTop: "15px",
      "&.data-tab-card": {
        maxWidth: "90px",
        width: "90px",
      },
    },
  })
);

export const AirportDetailDrawerInfoCardHeading = styled(Box)(({ theme }) => ({
  fontSize: "13px",
  fontWeight: "bold",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    fontSize: "11px",
  },
}));

export const AirportDetailDrawerInfoCardTextField = styled(TextField)(() => ({
  maxWidth: "inherit",
  "& .MuiInput-root": {
    fontSize: "14px !important",
  },
}));

export const AirportDetailDrawerInfoCardSubHeading = styled(Box)(
  ({ theme }) => ({
    fontSize: "14px",
    marginTop: "3px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      marginTop: "2px",
    },
  })
);

export const AirportDetailDrawerDocumentsSection = styled(Box)(() => ({
  position: "relative",
}));

export const AirportDetailDrawerDocumentSearchContainer = styled(Box)(() => ({
  position: "absolute",
  right: "0px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
}));

export const AirportDetailDrawerDocumentsSectionHeading = styled(Typography)(
  ({ theme }) => ({
    fontSize: "24px",
    fontWeight: "400",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  })
);

export const AirportDetailDrawerFolderSectionHeadingSection = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    columnGap: "6px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      columnGap: "4px",
    },
  })
);

export const AirportDetailDrawerFolderSectionBackIcon = styled(
  KeyboardBackspaceIcon
)(({ theme }) => ({
  fontSize: "45px",
  marginRight: "10px",
  marginBottom: "5px",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    fontSize: "28px",
    marginRight: "5px",
    marginBottom: "2px",
  },
}));

export const AirportDetailDrawerDocumentsSectionSubHeading = styled(Typography)(
  ({ theme }) => ({
    fontSize: "22px",
    fontWeight: "400",
    color: "#378cb0",
    textWrap: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  })
);

export const AirportDetailDrawerFolderSectionSegment = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    marginTop: "20px",
    padding: "0px 20px",
    rowGap: "30px",
    columnGap: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: "14px",
      padding: "0px 10px",
      rowGap: "20px",
      columnGap: "15px",
    },
  })
);

export const AirportDetailsDrawerFolderCardContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "5px",
    width: "100px",
    maxWidth: "100px",
    minWidth: "100px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      rowGap: "3px",
      width: "65px",
      maxWidth: "65px",
      minWidth: "65px",
    },
  })
);

export const AirportDetailDrawerFilesSectionSegment = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: "0px 50px",
    marginTop: "15px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0px 35px",
      marginTop: "10px",
      width: "100%",
    },
  })
);

export const AirportDetailDrawerFilesSectionIconsContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "center",
    columnGap: "15px",
    "& .selected-view": {
      backgroundColor: "#89CFF055",
      borderRadius: "5px",
    },
    [theme.breakpoints.down("md")]: {
      columnGap: "10px",
      "& .selected-view": {
        borderRadius: "3px",
      },
    },
  })
);

export const AirportDetailDrawerFilesSectionGridIcon = styled(AppsIcon)(
  ({ theme }) => ({
    fontSize: "40px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
    },
  })
);

export const AirportDetailDrawerFilesSectionListIcon = styled(
  FormatListBulletedIcon
)(({ theme }) => ({
  fontSize: "40px",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    fontSize: "28px",
  },
}));

export const AirportDetailDrawerFilesSectionCardsSegment = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    marginTop: "30px",
    width: "100%",
    scrollbarWidth: "thin",
    scrollbarColor: "#888 #f1f1f1",
    overflow: "visible !important",
    "&::-webkit-scrollbar": {
      width: "2px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#A0A0A0",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
      borderRadius: "10px",
    },
    "&.grid-view": {
      rowGap: "30px",
      flexWrap: "wrap",
      columnGap: "30px",
    },
    "&.list-view": {
      rowGap: "30px",
      flexDirection: "column",
      paddingRight: "25px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "22px",
      "&.grid-view": {
        rowGap: "20px",
        flexWrap: "wrap",
        columnGap: "20px",
      },
      "&.list-view": {
        rowGap: "20px",
        flexDirection: "column",
        paddingRight: "18px",
      },
    },
    // "&::-webkit-scrollbar": {
    //   width: "6px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#A0A0A0",
    // },
    // "&::-webkit-scrollbar-track": {
    //   backgroundColor: "#f1f1f1",
    // },
  })
);

export const AirportDetailDrawerFileGridCardContainer = styled(Box)(
  ({ theme }) => ({
    maxWidth: "110px",
    minWidth: "110px",
    display: "flex",
    alignItems: "center",
    rowGap: "10px",
    // cursor: "pointer",
    flexDirection: "column",
    position: "relative",
    "& .custom-grid-icon": {
      minWidth: "55px",
      minHeight: "55px",
      maxWidth: "55px",
      maxHeight: "55px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "85px",
      minWidth: "85px",
      rowGap: "7px",
      "& .custom-grid-icon": {
        minWidth: "40px",
        minHeight: "40px",
        maxWidth: "40px",
        maxHeight: "40px",
      },
    },
  })
);

export const AirportDetailsDrawerFileGridCardNewCount = styled(Box)(
  ({ theme }) => ({
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "12px",
    height: "12px",
    backgroundColor: "lightseagreen",
    borderRadius: "50%",
    top: "-8px",
    right: "10px",
    overflow: "visible",
    [theme.breakpoints.down("md")]: {
      width: "9px",
      height: "9px",
    },
  })
);

export const AirportDetailsDrawerFileGridCardIcon = styled(InsertDriveFileIcon)(
  ({ theme }) => ({
    fontSize: "55px",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
    },
  })
);

export const AirportDetailsDrawerFileGridCardHeading = styled(Typography)(
  ({ theme }) => ({
    fontSize: "17px",
    textAlign: "center",
    fontWeight: "500",
    width: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
  })
);

export const AirportDetailsDrawerFileTooltipTitle = styled(Typography)(
  ({ theme }) => ({
    fontSize: "13px",
    fontWeight: "600",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
  })
);

export const AirportDetailsDrawerFileTooltipDescription = styled(Typography)(
  ({ theme }) => ({
    fontSize: "15px",
    fontWeight: "400",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  })
);

export const AirportDetailDrawerFileListCardContainer = styled(Box)(
  ({ theme }) => ({
    minWidth: "100%",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    columnGap: "15px",
    // cursor: "pointer",
    padding: "10px 0px",
    position: "relative",
    "& .custom-list-icon": {
      minWidth: "30px",
      minHeight: "30px",
      maxWidth: "30px",
      maxHeight: "30px",
    },
    [theme.breakpoints.down("md")]: {
      columnGap: "10px",
      padding: "7px 0px",
      "& .custom-list-icon": {
        minWidth: "22px",
        minHeight: "22px",
        maxWidth: "22px",
        maxHeight: "22px",
      },
    },
    // boxShadow: 'rgba(149, 157, 165, 0.1) 0px 4px 12px'
  })
);

export const AirportDetailsDrawerFileListCardNewCount = styled(Box)(
  ({ theme }) => ({
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "12px",
    height: "12px",
    backgroundColor: "lightseagreen",
    borderRadius: "50%",
    top: "5px",
    left: "0px",
    overflow: "visible",
    [theme.breakpoints.down("md")]: {
      width: "9px",
      height: "9px",
    },
  })
);

export const AirportDetailsDrawerFileListCardIcon = styled(InsertDriveFileIcon)(
  ({ theme }) => ({
    fontSize: "30px",
    [theme.breakpoints.down("md")]: {
      fontSize: "22px",
    },
  })
);

export const AirportDetailsDrawerFileListCardContentContainer = styled(Box)(
  () => ({
    display: "flex",
    flexDirection: "column",
    minWidth: "calc(100% - 50px - 10px - 32px)",
  })
);

export const AirportDetailsDrawerFileListCardHeading = styled(Typography)(
  ({ theme }) => ({
    fontSize: "17px",
    fontWeight: "500",
    width: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
  })
);

export const AirportDetailsDrawerFileListCardSubHeading = styled(Typography)(
  ({ theme }) => ({
    fontSize: "16px",
    fontWeight: "400",
    width: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  })
);

export const AirportDetailsDrawerFolderCardIconWrapper = styled(Box)(() => ({
  position: "relative",
}));

export const AirportDetailsDrawerFolderCardIcon = styled(FolderIcon)(
  ({ theme }) => ({
    width: "55px",
    height: "55px",
    [theme.breakpoints.down("md")]: {
      width: "40px",
      height: "40px",
    },
  })
);

export const AirportDetailsDrawerAddFolderCardIcon = styled(AddCircleOutlineOutlinedIcon)(
  ({ theme }) => ({
    fontSize: "65px",
    color:'black',
    [theme.breakpoints.down("md")]: {
    fontSize: "40px",
    },
  })
);

export const AirportDetailDrawerFileUploadContainer = styled(Box)(
  ({ theme }) => ({
    width: "100%",
    marginTop: "15px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
  })
);

export const AirportDetailDrawerFileUploadContainerHeading = styled(Typography)(
  ({ theme }) => ({
    fontSize: "14px",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "11px",
    },
  })
);

export const AirportDetailDrawerFileUploadContainerUploadBox = styled(Box)(
  ({ theme }) => ({
    width: "100%",
    marginTop: "10px",
    height: "100px",
    backgroundColor: "#77777722",
    border: "1px solid #77777755",
    borderStyle: "dashed",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    position: "relative",
    cursor: "pointer",
    padding: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "7px",
      height: "75px",
      padding: "7px",
    },
  })
);

export const AirportDetailDrawerFileUploadContainerUploadText = styled(
  Typography
)(({ theme }) => ({
  fontSize: "15px",
  color: "#777",
  [theme.breakpoints.down("md")]: {
    fontSize: "11px",
  },
}));

export const AirportDetailDrawerFileUploadContainerUploadedFileName = styled(
  Typography
)(({ theme }) => ({
  fontSize: "16px",
  color: "#aaa",
  overflow: "hidden",
  maxWidth: "100%",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  // marginTop: "10px",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));

export const AirportDetailDrawerFileUploadContainerUploadIcon = styled(
  CloudUploadIcon
)(({ theme }) => ({
  fontSize: "25px",
  color: "#777777cc",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
  // marginTop: "10px",
}));

export const AirportDetailDrawerFileUploadContainerBtnContainer = styled(Box)(
  ({ theme }) => ({
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-end",
    columnGap: "20px",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
      columnGap: "15px",
    },
  })
);

export const AirportDetailDrawerFileUploadContainerBtnText = styled(Typography)(
  ({ theme }) => ({
    fontSize: "13px",
    color: "#00CED1",
    cursor: "pointer",
    fontWeight: "bold",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  })
);

export const AirportDetailsDrawerFolderCardNewCount = styled(Box)(
  ({ theme }) => ({
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "12px",
    height: "12px",
    backgroundColor: "lightseagreen",
    borderRadius: "50%",
    top: "-2px",
    right: "-8px",
    [theme.breakpoints.down("md")]: {
      width: "9px",
      height: "9px",
    },
  })
);

export const AirportDetailsDrawerFolderCardHeading = styled(Typography)(
  ({ theme }) => ({
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "400",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
  })
);

export const AirportDetailDrawerDocumentsSectionSegment = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    marginTop: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "7px",
    },
  })
);

export const AirportDetailDrawerDocumentsSectionContent = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: "0px 45px",
    marginTop: "10px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0px 27px",
      marginTop: "7px",
    },
    // rowGap: "15px",
  })
);

export const AirportDetailDrawerExpandSegment = styled(Box)(({ theme }) => ({
  padding: "20px 0px 15px 0px",
  boxShadow: "0 5px 10px -10px gray",
  [theme.breakpoints.down("md")]: {
    padding: "15px 0px 10px 0px",
  },
}));

export const AirportDetailDrawerExpandSegmentHeader = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 20px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "0 15px",
    },
  })
);

export const AirportDetailDrawerExpandSegmentHeading = styled(Box)(
  ({ theme }) => ({
    fontSize: "18px",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
    },
  })
);

export const AirportDetailDrawerExpandSegmentIcon = styled(ArrowDropUpIcon)(
  () => ({
    transform: "rotate(180deg)",
    transition: "transform 200ms",
    "&.expanded": {
      transform: "rotate(0deg)",
    },
  })
);

export const AirportDetailDrawerDocumentsSectionBtnSegnment = styled(Box)(
  ({ theme }) => ({
    marginTop: "15px",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
  })
);

export const AirportDetailDrawerDocumentsInfoCard = styled(Box)(
  ({ theme }) => ({
    maxWidth: "110px",
    width: "110px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90px",
      width: "90px",
    },
  })
);

export const AirportDetailDrawerDocumentsInfoCardHeading = styled(
  AirportDetailDrawerInfoCardHeading
)(({ theme }) => ({
  fontSize: "14px",
  [theme.breakpoints.down("md")]: {
    fontSize: "11px",
  },
}));

export const AirportDetailDrawerDocumentsInfoCardSubHeading = styled(
  AirportDetailDrawerInfoCardSubHeading
)(() => ({
  marginTop: "0px",
}));

export const AirportDetailsDrawerDocCardContainer = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    columnGap: "8px",
    alignItems: "center",
    maxWidth: "80px",
    width: "80px",
    marginTop: "2px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      columnGap: "5px",
      maxWidth: "60px",
      width: "60px",
    },
  })
);

export const AirportDetailsDrawerDocCardIcon = styled(ExpandCircleDownIcon)(
  ({ theme }) => ({
    fontSize: "43px",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
  })
);

export const AirportDetailsDrawerDocCardName = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "500",
  textAlign: "center",
  "&.selected": {
    color: "#6495ED",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: "11px",
  },
}));

export const AirportDetailDrawerCalendarSection = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  "& .MuiButtonBase-root": {
    width: "32px",
    height: "32px",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "15px",
  },
}));

export const AirportDetailDrawerCalendarSectionHeading = styled(Box)(
  ({ theme }) => ({
    fontSize: "24px",
    fontWeight: "400",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  })
);

export const AirportDetailDrawerCalendarSectionSegment = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "7px",
    },
  })
);

export const AirportDetailDrawerCalendarDateSelectWrapper = styled(Box)(
  ({ theme }) => ({
    width: "250px",
    margin: "0 auto",
    "& .MuiYearCalendar-root": {
      width: "290px",
    },
    [theme.breakpoints.down("md")]: {
      width: "220px",
      margin: "0 auto",
      "& .MuiYearCalendar-root": {
        width: "250px",
      },
    },
  })
);

export const AirportDetailDrawerCalendarEventsContainer = styled(Box)(
  ({ theme }) => ({
    marginTop: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
    },
  })
);

export const AirportDetailDrawerCalendarEventsHeading = styled(Box)(
  ({ theme }) => ({
    fontSize: "20px",
    fontWeight: "500",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "17px",
    },
  })
);

export const AirportDetailDrawerCalendarEventsSegment = styled(Box)(
  ({ theme }) => ({
    marginTop: "10px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "15px",
    padding: "5px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      gap: "11px",
      padding: "3px",
    },
  })
);

export const AirportDetailDrawerCalendarEventCard = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    padding: "10px",
    backgroundColor: "#D6B6D522",
    width: "calc(50% - 7.5px)",
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      padding: "7px",
      gap: "7px",
    },
  })
);

export const AirportDetailDrawerCalendarEventCardIcon = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    Width: "40px",
    minWidth: "40px",
    height: "40px",
    minHeight: "40px",
    backgroundColor: "#5A255844",
    borderRadius: "50%",
    fontWeight: "bold",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      Width: "32px",
      minWidth: "32px",
      height: "32px",
      minHeight: "32px",
      fontSize: "13px",
    },
  })
);

export const AirportDetailDrawerCalendarEventCardContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const AirportDetailDrawerCalendarEventCardTitle = styled(Box)(
  ({ theme }) => ({
    fontSize: "14px",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: "11px",
    },
  })
);

export const AirportDetailDrawerCalendarEventCardText = styled(Box)(
  ({ theme }) => ({
    fontSize: "12px",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  })
);

export const AirportDetailsDrawerFileEditModalContent = styled(Box)(
  ({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    boxShadow: "24",
    borderRadius: "10px",
    border: "1px solid grey",
    padding: "15px 35px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "500px",
    [theme.breakpoints.down("md")]: {
      boxShadow: "18",
      borderRadius: "7px",
      padding: "10px 25px",
      maxWidth: "100%",
      left: "0%",
      transform: "translate(0%, -50%)",
    },
  })
);

export const AirportDetailsDrawerFileEditModalHeading = styled(Typography)(
  () => ({
    fontSize: "1.5rem",
    fontWeight: "bold",
  })
);

export const AirportDetailDrawerDataAddButton = styled(Button)(({theme}) => ({
  float: "right",
  marginBottom: "10px",
  [theme.breakpoints.down("md")]: {
  marginBottom: "7px",
  },
}));
