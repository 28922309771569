import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";

class OrgMemberUserSubscription {
  /*
    Task: Get OrgMemberUser subscription on create from amplify graphql
  */
  static getOnCreate() {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateOrgMemberUser)
    ) as any;
  }

  /*
    Task: Get OrgMemberUser subscription on update from amplify graphql
  */
  static getOnUpdate() {
    return API.graphql(
      graphqlOperation(subscriptions.onUpdateOrgMemberUser)
    ) as any;
  }

  /*
    Task: Get OrgMemberUser subscription on delete from amplify graphql
  */
  static getOnDelete() {
    return API.graphql(
      graphqlOperation(subscriptions.onDeleteOrgMemberUser)
    ) as any;
  }
}

export { OrgMemberUserSubscription };
