import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { ICreateOrgSupportAdminUserParams } from "./types";
import {
  CreateOrgSupportAdminUserMutation,
  ListOrgSupportAdminUsersQuery,
  GetOrgSupportAdminUserQuery,
  DeleteOrgSupportAdminUserMutation,
  UpdateOrgSupportAdminUserMutation,
} from "../../API";

class OrgSupportAdminUserService {
  /*
    Task: Create a new OrgSupportAdminUser using graphql
  */
  async createOrgSupportAdminUser(
    OrgSupportAdminUser: ICreateOrgSupportAdminUserParams
  ) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createOrgSupportAdminUser,
        variables: { input: OrgSupportAdminUser },
      })) as { data: CreateOrgSupportAdminUserMutation; errors: any[] };

      // send back newly created OrgSupportAdminUser details
      if (temp.data.createOrgSupportAdminUser) {
        return temp.data.createOrgSupportAdminUser;
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: get OrgSupportAdminUser by id using graphql
  */
  async getOrgSupportAdminUserById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: queries.getOrgSupportAdminUser,
        variables: { id },
      })) as { data: GetOrgSupportAdminUserQuery; errors: any[] };

      // send back OrgSupportAdminUser details
      if (temp.data.getOrgSupportAdminUser) {
        return temp.data.getOrgSupportAdminUser;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: delete OrgSupportAdminUser by id using graphql
  */
  async deleteOrgSupportAdminUserById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteOrgSupportAdminUser,
        variables: { input: { id } },
      })) as { data: DeleteOrgSupportAdminUserMutation; errors: any[] };

      // send back OrgSupportAdminUser details
      if (temp.data.deleteOrgSupportAdminUser) {
        return temp.data.deleteOrgSupportAdminUser;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: update OrgSupportAdminUser by id using graphql
  */
  async updateOrgSupportAdminUserById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateOrgSupportAdminUser,
        variables: { input },
      })) as { data: UpdateOrgSupportAdminUserMutation; errors: any[] };

      // send back technology details
      if (temp.data.updateOrgSupportAdminUser) {
        return temp.data.updateOrgSupportAdminUser;
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: list all OrgSupportAdminUser using graphql
  */
  async getAllOrgSupportAdminUsers(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listOrgSupportAdminUsers
  ) {
    try {
      // send grapql api call
      // const temp = (await API.graphql({
      //   query: query_src,
      // })) as { data: ListOrgSupportAdminUsersQuery; errors: any[] };

      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListOrgSupportAdminUsersQuery; errors: any[] };

      // send back all OrgSupportAdminUser details

      if (temp.data.listOrgSupportAdminUsers?.items) {
        return {
          relations: temp.data.listOrgSupportAdminUsers?.items,
          nextToken: temp.data.listOrgSupportAdminUsers?.nextToken,
        };
      }

      return { relations: [], nextToken: null };
    } catch (error) {
      console.log(error);
      return { relations: [], nextToken: null };
    }
  }

  /*
    Task: recursively list all OrgSupportAdminUser using graphql
  */
  async recursiveFetchListOrgSupportAdminUsers(
    filter: any = {},
    currentRelations: Array<any> = [],
    reqNextToken: string | null = null,
    query_src = queries.listOrgSupportAdminUsers
  ): Promise<any> {
    const { relations, nextToken } = await this.getAllOrgSupportAdminUsers(
      filter,
      reqNextToken,
      query_src
    );

    // merge data
    let allData = [...currentRelations, ...relations];

    if (nextToken) {
      return await this.recursiveFetchListOrgSupportAdminUsers(
        filter,
        allData,
        nextToken,
        query_src
      );
    } else {
      if (allData?.length) {
        return allData;
      }

      return [];
    }
  }
}

export { OrgSupportAdminUserService };
