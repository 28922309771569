import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";
import { ICreateOrganizationParams } from "./types";
import {
  CreateOrganizationMutation,
  ListOrganizationsQuery,
  GetOrganizationQuery,
  DeleteOrganizationMutation,
  UpdateOrganizationMutation,
} from "../../API";

class OrganizationService {
  /*
    Task: Create a new organization using graphql
  */
  async createOrganization(organization: ICreateOrganizationParams) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createOrganization,
        variables: { input: organization },
      })) as { data: CreateOrganizationMutation; errors: any[] };

      // send back newly created organization details
      return temp.data.createOrganization;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: get organization by id using graphql
  */
  async getOrganizationById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: customQueries.customGetOrganization,
        variables: { id },
      })) as { data: GetOrganizationQuery; errors: any[] };

      // send back organization details
      return temp.data.getOrganization;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: delete Organization by id using graphql
  */
  async deleteOrganizationById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteOrganization,
        variables: { input: { id } },
      })) as { data: DeleteOrganizationMutation; errors: any[] };

      // send back organization details
      return temp.data.deleteOrganization;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: update organization by id using graphql
  */
  async updateOrganizationById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateOrganization,
        variables: { input },
      })) as { data: UpdateOrganizationMutation; errors: any[] };

      // send back technology details
      return temp.data.updateOrganization;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: list all organization using graphql
  */
  async getAllOrganizations(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listOrganizations
  ) {
    try {
      // send grapql api call
      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListOrganizationsQuery; errors: any[] };

      if (temp.data.listOrganizations?.items) {
        // send back all Organization details
        return {
          organizations: temp.data.listOrganizations?.items,
          nextToken: temp.data.listOrganizations?.nextToken,
        };
      }
      return { organizations: [], nextToken: null };
    } catch (error) {
      return { organizations: [], nextToken: null };
    }
  }
}

export { OrganizationService };
