import React, { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import { useScreenSize } from "../../../hooks";
import IconButton from "@mui/material/IconButton";

// prop types
interface ISidebarProps {
  open: boolean;
  handleClose: () => void;
  position?: any;
  children: React.ReactNode;
}

const Sidebar: FC<ISidebarProps> = (props) => {
  // state
  const [calcWidth, setCalcWidth] = useState<number>(0);

  // props de structure
  const { open, handleClose, position = "right", children } = props;
  const { width: screenWidth } = useScreenSize();

  // componentDidMount
  useEffect(() => {
    // calc width on page load
    calculateWidth();

    // cleanup
    return () => {};
  }, []);

  // re - calculate drawer / sidebar width when screen resize
  useEffect(() => {
    calculateWidth();
  }, [screenWidth]);

  // calculate dynamically drawer width
  const calculateWidth = () => {
    let tempWidth: number = 0;

    if (position === "left" || position === "right") {
      // if screen width greater than 500
      if (screenWidth > 800) {
        tempWidth = screenWidth / 2.5;
      } else {
        // if screen width less than equal to 500
        // take 80% of screen
        tempWidth = (screenWidth * 80) / 100;
      }
    } else {
      tempWidth = screenWidth;
    }

    // update calculated with
    setCalcWidth(tempWidth);
  };
  // handleClose
  return (
    <Drawer
      variant="temporary"
      anchor={position}
      open={open}
      onClose={(_, reason) => {
        reason !== "backdropClick" && handleClose();
      }}
    >
      <Box
        sx={{
          width:
            position === "top" || position === "bottom" ? "auto" : calcWidth,
          overflowX: "hidden",
        }}
      >
        <Box sx={{ p: 5 }}>
          <Grid container spacing={3}>
            <Grid xs={12} item>
              <IconButton
                onClick={handleClose}
                sx={{ cursor: "pointer", float: "right" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

export { Sidebar };
