import React, { useState } from "react";
import {
  AirportDetailDrawerDocumentsSectionBtnSegnment,
  AirportDetailDrawerDocumentsSectionContent,
  AirportDetailDrawerEditIcons,
  AirportDetailDrawerInfoCardCloseIcon,
  AirportDetailDrawerInfoCardDeleteIcon,
  AirportDetailDrawerInfoCardEditIcon,
  AirportDetailDrawerInfoCardSaveButton,
} from "./style";
import { AirportFieldValidator, IStorage } from "../MapProvider/types";
import dayJs from "dayjs";
import {
  AirportFieldValidatorType,
  StorageASTUSTChoiceMapping,
  StorageASTUSTOptions,
  StorageCathodicProtectionChoiceMapping,
  StorageCathodicProtectionOptions,
  StorageFunctionChoiceMapping,
  StorageFunctionOptions,
  StorageLocationChoiceMapping,
  StorageLocationOptions,
  StorageOpStatusChoiceMapping,
  StorageOpStatusOptions,
  StorageProductChoiceMapping,
  StorageProductOptions,
  StorageSecondaryContainmentChoiceMapping,
  StorageSecondaryContainmentOptions,
  generalTabFieldType,
  generalTabFields,
  storageFields,
} from "../../constants/map";
import {
  StorageASTUSTChoice,
  StorageCathodicProtectionChoice,
  StorageFunctionChoice,
  StorageLocationChoice,
  StorageOpStatusChoice,
  StorageProductChoice,
  StorageSecondaryContainmentChoice,
} from "../../API";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import AirportDetailDrawerInfoCard from "./AirportDetailDrawerInfoCard";
import MapService from "../../services/MapService";
import ConfirmDeleteModal from "../ViewCalendar/ConfirmDeleteModal";
import { airportFieldValidator } from "../../utils/helpers";

type IStorageTab = {
  airportId: string;
  organizationId: string;
  airportCode: string;
  oldCode: string;
  facility: string;
  storage?: IStorage;
  // canUserEditOrgInfo: boolean;
  isAdding?: boolean;
  setIsAdding?: any;
};

export interface IEditStorageInput {
  tankId: string;
  yearInstalled: string;
  product: Array<StorageProductChoice | null> | null;
  storageLocation: StorageLocationChoice;
  storageASTUST: StorageASTUSTChoice;
  nextAPI653?: string | null;
  storageFunction: Array<StorageFunctionChoice | null> | null;
  totalBBL?: number | null;
  usableBBL?: number | null;
  totalGals?: number | null;
  usableGals?: number | null;
  opStatus: StorageOpStatusChoice;
  owner?: string | null;
  security?: string | null;
  secondaryContainment?: Array<StorageSecondaryContainmentChoice | null> | null;
  cathodicProtection?: Array<StorageCathodicProtectionChoice | null> | null;
  leakDetection?: string | null;
}

const defaultStorageObj = {
  tankId: "",
  yearInstalled: "",
  product: [],
  storageLocation: StorageLocationChoice.OFF_AIRPORT,
  storageASTUST: StorageASTUSTChoice.AST,
  nextAPI653: null,
  storageFunction: [],
  totalBBL: null,
  usableBBL: null,
  totalGals: null,
  usableGals: null,
  opStatus: StorageOpStatusChoice.OPERATING,
  owner: "",
  security: "",
  secondaryContainment: [],
  cathodicProtection: [],
  leakDetection: "",
};

const storageFieldValidations: AirportFieldValidator[] = [
  {
    field: storageFields.TANK_ID,
    errorMessage: "Tank ID is required",
    type: AirportFieldValidatorType.STRING,
    isRequired: true,
  },
  {
    field: storageFields.PRODUCT,
    errorMessage: "Product is required",
    type: AirportFieldValidatorType.ARRAY,
    isRequired: true,
  },
  {
    field: storageFields.STORAGE_FUNCTION,
    errorMessage: "Function is required",
    type: AirportFieldValidatorType.ARRAY,
    isRequired: true,
  },
  {
    field: storageFields.TOTAL_BBL,
    errorMessage: "Total BBLs must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: storageFields.USABLE_BBL,
    errorMessage: "Usable BBLs must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: storageFields.TOTAL_GALS,
    errorMessage: "Total Gals must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: storageFields.USABLE_GALS,
    errorMessage: "Usable Gals must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
];

const StorageTab = ({
  airportId,
  airportCode,
  organizationId,
  oldCode,
  facility,
  storage,
  // canUserEditOrgInfo,
  isAdding = false,
  setIsAdding,
}: IStorageTab) => {
  const { state, dispatch } = useMapContext();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(isAdding);
  const [editInputs, setEditInputs] = useState<IEditStorageInput>(
    storage
      ? {
          tankId: storage.tankId,
          yearInstalled: storage.yearInstalled,
          product: storage.product,
          storageLocation: storage.storageLocation,
          storageASTUST: storage.storageASTUST,
          nextAPI653: storage.nextAPI653,
          storageFunction: storage.storageFunction,
          totalBBL: storage.totalBBL,
          usableBBL: storage.usableBBL,
          totalGals: storage.totalGals,
          usableGals: storage.usableGals,
          owner: storage.owner,
          opStatus: storage.opStatus,
          security: storage.security,
          secondaryContainment: storage.secondaryContainment,
          cathodicProtection: storage.cathodicProtection,
          leakDetection: storage.leakDetection,
        }
      : defaultStorageObj
  );

  const companyOptions = state.companyList
    .map((company) => ({
      name: company.name,
      value: company.id,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const storageData = [
    {
      heading: "Tank ID",
      infoArray: [
        {
          name: storageFields.TANK_ID,
          value: storage?.[storageFields.TANK_ID] ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Year Installed",
      infoArray: [
        {
          name: storageFields.YEAR_INSTALLED,
          value: storage?.[storageFields.YEAR_INSTALLED] ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Product",
      infoArray: [
        {
          name: storageFields.PRODUCT,
          value:
            storage?.[storageFields.PRODUCT]
              ?.map((product) => StorageProductChoiceMapping[product!])
              ?.join(", ") ?? "",
          type: generalTabFieldType.ENUM_MULTIPLE,
          enumOptions: StorageProductOptions,
        },
      ],
    },
    {
      heading: "Location",
      infoArray: [
        {
          name: storageFields.STORAGE_LOCATION,
          value: storage?.[storageFields.STORAGE_LOCATION]
            ? StorageLocationChoiceMapping[
                storage?.[storageFields.STORAGE_LOCATION]
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: StorageLocationOptions,
        },
      ],
    },
    {
      heading: "AST/UST",
      infoArray: [
        {
          name: storageFields.STORAGE_AST_UST,
          value: storage?.[storageFields.STORAGE_AST_UST]
            ? StorageASTUSTChoiceMapping[
                storage?.[storageFields.STORAGE_AST_UST]
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: StorageASTUSTOptions,
        },
      ],
    },
    {
      heading: "Next API 653",
      infoArray: [
        {
          name: storageFields.NEXT_API_653,
          value: storage?.[storageFields.NEXT_API_653]
            ? dayJs(storage?.[storageFields.NEXT_API_653]).format("MM/DD/YYYY")
            : "",
          type: generalTabFieldType.DATE,
        },
      ],
      infoText:
        "For ALL above-ground tanks, please indicate date of next scheduled internal API 653 inspection.",
    },
    {
      heading: "Function(s)",
      infoArray: [
        {
          name: storageFields.STORAGE_FUNCTION,
          value:
            storage?.[storageFields.STORAGE_FUNCTION]
              ?.map(
                (storageFunction) =>
                  StorageFunctionChoiceMapping[storageFunction!]
              )
              ?.join(", ") ?? "",
          type: generalTabFieldType.ENUM_MULTIPLE,
          enumOptions: StorageFunctionOptions,
        },
      ],
      infoText: "Please check all that apply",
    },
    {
      heading: "Total BBLs",
      infoArray: [
        {
          name: storageFields.TOTAL_BBL,
          value: storage?.[storageFields.TOTAL_BBL]?.toLocaleString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Useable BBLs",
      infoArray: [
        {
          name: storageFields.USABLE_BBL,
          value: storage?.[storageFields.USABLE_BBL]?.toLocaleString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "OP Status",
      infoArray: [
        {
          name: storageFields.OP_STATUS,
          value: storage?.[storageFields.OP_STATUS]
            ? StorageOpStatusChoiceMapping[storage?.[storageFields.OP_STATUS]]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: StorageOpStatusOptions,
        },
      ],
    },
    {
      heading: "Owner",
      infoArray: [
        {
          name: storageFields.OWNER,
          value: storage?.owner ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Security",
      infoArray: [
        {
          name: storageFields.SECURITY,
          value: storage?.[storageFields.SECURITY] ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Secondary Containment",
      infoArray: [
        {
          name: storageFields.SECONDARY_CONTAINMENT,
          value:
            storage?.[storageFields.SECONDARY_CONTAINMENT]
              ?.map(
                (secondaryContainment) =>
                  StorageSecondaryContainmentChoiceMapping[
                    secondaryContainment!
                  ]
              )
              ?.join(", ") ?? "",
          type: generalTabFieldType.ENUM_MULTIPLE,
          enumOptions: StorageSecondaryContainmentOptions,
        },
      ],
      infoText: "Please check all that apply",
    },
    {
      heading: "Cathodic Protection",
      infoArray: [
        {
          name: storageFields.CATHODIC_PROTECTION,
          value:
            storage?.[storageFields.CATHODIC_PROTECTION]
              ?.map(
                (cathodicProtection) =>
                  StorageCathodicProtectionChoiceMapping[cathodicProtection!]
              )
              ?.join(", ") ?? "",
          type: generalTabFieldType.ENUM_MULTIPLE,
          enumOptions: StorageCathodicProtectionOptions,
        },
      ],
      infoText: "Please check all that apply",
    },
    {
      heading: "Leak Detection",
      infoArray: [
        {
          name: storageFields.LEAK_DETECTION,
          value: storage?.[storageFields.LEAK_DETECTION] ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Total Gals",
      infoArray: [
        {
          name: storageFields.TOTAL_GALS,
          value: storage?.[storageFields.TOTAL_GALS]?.toLocaleString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Usable Gals",
      infoArray: [
        {
          name: storageFields.USABLE_GALS,
          value: storage?.[storageFields.USABLE_GALS]?.toLocaleString() ?? "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
  ];

  const editInputChangeHandler = (
    name: keyof any,
    value: string,
    type?: generalTabFieldType
  ) => {
    let finalValue: string = value;
    if (type === generalTabFieldType.DATE) {
      finalValue = new Date(finalValue as string).toISOString();
    }
    setEditInputs((prevState) => ({ ...prevState, [name]: finalValue }));
  };

  const updateHandler = async (): Promise<void> => {
    // if (!canUserEditOrgInfo) return;
    const errorMessage = airportFieldValidator(
      storageFieldValidations.map((validation) => ({
        ...validation,
        field:
          editInputs[validation.field as keyof IEditStorageInput]?.toString() ??
          "",
      }))
    );
    if (!!errorMessage) {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: errorMessage,
          type: "error",
        },
      });
      return;
    }
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: `${
        isAdding ? "Adding" : "Updating"
      } "Updating Storage Information`,
    });
    const editInputsCopy = { ...editInputs };
    editInputsCopy.totalBBL = isNaN(
      parseInt(editInputsCopy?.totalBBL?.toString() ?? "")
    )
      ? null
      : editInputsCopy.totalBBL;
    editInputsCopy.usableBBL = isNaN(
      parseInt(editInputsCopy?.usableBBL?.toString() ?? "")
    )
      ? null
      : editInputsCopy.usableBBL;
    editInputsCopy.totalGals = isNaN(
      parseInt(editInputsCopy?.totalGals?.toString() ?? "")
    )
      ? null
      : editInputsCopy.totalGals;
    editInputsCopy.usableGals = isNaN(
      parseInt(editInputsCopy?.usableGals?.toString() ?? "")
    )
      ? null
      : editInputsCopy.usableGals;
    let response: any;
    if (isAdding) {
      response = await MapService.createStorage({
        ...editInputsCopy,
        airportId,
        organizationId,
      });
    } else {
      response = await MapService.updateStorage(storage!.id, editInputsCopy);
    }
    if (typeof response !== "string") {
      let updatedAirport = {
        ...state.activeAirport,
        storage: {
          ...state.activeAirport?.storage,
          items: isAdding
            ? [...(state.activeAirport?.storage?.items ?? []), response]
            : state.activeAirport?.storage?.items?.map((currentStorage) => {
                if (currentStorage?.id === storage!.id) {
                  return { ...currentStorage, ...response };
                }
                return currentStorage;
              }),
        },
      };
      setEditInputs(editInputsCopy)
      dispatch({
        type: mapContextAction.SET_ACTIVE_AIRPORT,
        payload: updatedAirport,
      });
      setIsInEditMode(false);
      if (isAdding) setIsAdding(false);
    } else {
      alert(response);
    }
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "",
    });
  };

  const deleteHandler = async () => {
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "Deleting storage entry",
    });
    const { message, type: severity } = await MapService.deleteStorage(
      storage?.id ?? ""
    );
    if (severity === "success") {
      let updatedAirport = {
        ...state.activeAirport,
        storage: {
          ...state.activeAirport?.storage,
          items: state.activeAirport?.storage?.items?.filter(
            (currentStorage) => currentStorage?.id !== storage!.id
          ),
        },
      };
      setIsDeleting(false);
      dispatch({
        type: mapContextAction.SET_ACTIVE_AIRPORT,
        payload: updatedAirport,
      });
    } else {
      alert(message);
    }
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "",
    });
  };

  return (
    <>
      <AirportDetailDrawerDocumentsSectionContent>
        {/* {canUserEditOrgInfo && ( */}
        <AirportDetailDrawerEditIcons>
          {!isInEditMode && (
            <AirportDetailDrawerInfoCardEditIcon
              onClick={() => setIsInEditMode(true)}
            />
          )}
          {!isInEditMode && !isAdding && (
            <AirportDetailDrawerInfoCardDeleteIcon
              onClick={() => setIsDeleting(true)}
            />
          )}
          {isInEditMode && (
            <AirportDetailDrawerInfoCardCloseIcon
              onClick={() => {
                isAdding ? setIsAdding(false) : setIsInEditMode(false);
              }}
            />
          )}
        </AirportDetailDrawerEditIcons>
        {/* )} */}
        {storageData.map((data) => {
          return (
            <AirportDetailDrawerInfoCard
              key={data.heading}
              role={data.heading}
              isEditable={true}
              infoArray={data.infoArray}
              isInEditMode={isInEditMode}
              editInputs={editInputs}
              editInputChangeHandler={editInputChangeHandler}
              containerClassName="data-tab-card"
              infoText={data.infoText}
            />
          );
        })}
        {isInEditMode && (
          <AirportDetailDrawerDocumentsSectionBtnSegnment>
            <AirportDetailDrawerInfoCardSaveButton
              color="info"
              onClick={updateHandler}
            >
              Save Changes
            </AirportDetailDrawerInfoCardSaveButton>
          </AirportDetailDrawerDocumentsSectionBtnSegnment>
        )}
      </AirportDetailDrawerDocumentsSectionContent>
      <ConfirmDeleteModal
        open={isDeleting}
        handleClose={() => setIsDeleting(false)}
        handleDelete={deleteHandler}
      />
    </>
  );
};

export default StorageTab;
