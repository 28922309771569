import { Button, CircularProgress, Modal, TextField } from "@mui/material";
import React, { ChangeEvent, useRef, useState } from "react";
import {
  AirportDetailsDrawerFileEditModalContent,
  AirportDetailsDrawerFileEditModalHeading,
} from "./style";
import { IAirportDetail, IAirportDocument } from "../MapProvider/types";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import { DocumentService } from "../../services/DocumentService";
import { useAuth } from "../AuthProvider";
import { ICurrentFolder } from "./FilesTab";
import { DocumentCategoryService } from "../../services/DocumentCategoryService";
import {
  dataContextAction,
  useDataContext,
} from "../DataProvider/DataProvider";
import { DocumentBucketTypeChoice } from "../../API";

interface IAirportDetailsDrawerAddFolderModal {
  isModalOpened: boolean;
  closeModal: Function;
  bucketType?: DocumentBucketTypeChoice;
}

const AirportDetailsDrawerAddFolderModal = ({
  isModalOpened,
  closeModal,
  bucketType = DocumentBucketTypeChoice.AIRPORT,
}: IAirportDetailsDrawerAddFolderModal) => {
  const {
    state: { user },
  } = useAuth();
  const { state: dataState, dispatch: dataDispatch } = useDataContext();
  const { state, dispatch } = useMapContext();
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleAddFolder = async () => {
    if (!name.trim()) {
      setNameError("Name is required");
      return;
    }

    try {
      const dcs = new DocumentCategoryService();

      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "Adding folder " + name,
      });

      const addedDocCat = await dcs.createDocumentCategory({
        name,
        description,
        bucketType: bucketType,
        type: bucketType,
      });

      if (addedDocCat) {
        dataDispatch({
          type: dataContextAction.SET_DOCUMENT_CATEGORY_LIST,
          payload: [...dataState.documentCategoryList, addedDocCat],
        });
      }
      if (bucketType === DocumentBucketTypeChoice.GENERAL) {
        dataDispatch({
          type: dataContextAction.SET_GLOBAL_DOCUMENT_LIST,
          payload: { ...dataState.globalDocumentList, [addedDocCat.id]: [] },
        });
      }

      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "",
      });
      if (bucketType === DocumentBucketTypeChoice.AIRPORT) {
        dispatch({
          type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
          payload: {
            text: "File folder successfully",
            type: "success",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "",
      });
      if (bucketType === DocumentBucketTypeChoice.AIRPORT) {
        dispatch({
          type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
          payload: {
            text: "Error occured while adding Folder.",
            type: "error",
          },
        });
      }
    } finally {
      closeModal();
    }
  };

  return (
    <>
      <Modal
        sx={{ zIndex: "99999998" }}
        open={isModalOpened}
        onClose={() => closeModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AirportDetailsDrawerFileEditModalContent>
          <AirportDetailsDrawerFileEditModalHeading>
            Add Folder
          </AirportDetailsDrawerFileEditModalHeading>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            sx={{ mt: "20px" }}
            error={!!nameError}
            helperText={nameError}
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            sx={{ mt: "15px" }}
          />
          <Button
            sx={{ marginTop: "20px" }}
            variant="contained"
            onClick={handleAddFolder}
          >
            Add
          </Button>
        </AirportDetailsDrawerFileEditModalContent>
      </Modal>
    </>
  );
};

export default AirportDetailsDrawerAddFolderModal;
