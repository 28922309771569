import React, { FC, useState, useEffect } from "react";
import { Button, Box } from "@mui/material";
import { A4ALoader } from "../Common/A4ALoader";
import { TextFieldInput } from "../Common/TextFieldInput";
import { AutoCompleteInput } from "../Common/AutoCompleteInput";
import { constants, helpers } from "../../utils";
import { AirportSchema } from "../../validations/schema";
import { AirportService } from "../../services/AirportService";
import { toast } from "react-toastify";

// prop type
interface IAddAirport {
  closeSidebar: () => void;
}

interface IDefaultState {
  name: string;
  code: string;
  region: string;
  state: string;
  lat: string;
  lon: string;
}

// default state
const defaultState = {
  name: "",
  code: "",
  region: "",
  state: "",
  lat: "",
  lon: "",
};

const AddAirport: FC<IAddAirport> = ({ closeSidebar }) => {
  // state
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({ ...defaultState });
  const [error, setError] = useState<IDefaultState>({ ...defaultState });

  // componentDidMount
  useEffect(() => {
    // cleanup
    return () => {};
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setError({ ...defaultState });

    let result: any = {};

    result = AirportSchema.createAirportValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  // add airport
  const handleAddAirport = async () => {
    var isValidationError = false;

    // clear error state
    setError({ ...defaultState });

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      const isValid = handleValidation(key, formData[key]);

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    if (!isValidationError) {
      const apiAirport = new AirportService();

      // start api loader
      setApiLoading(true);

      const apiPayload = {
        name: formData?.name,
        code: formData?.code,
        region: formData?.region,
        state: formData?.state,
        place: {
          lat: formData?.lat,
          lon: formData?.lon,
        },
      };

      // create airport
      const response = await apiAirport.createAirport(apiPayload);

      if (response !== null) {
        // show toast message
        toast.success("Airport created.", { theme: "colored" });

        // close side bar
        closeSidebar();
      } else {
        // show error toast message
        toast.error("Fail to create airport.", { theme: "colored" });

        // close side bar
        closeSidebar();
      }
    }
  };

  return (
    <div>
      <p>Let's add a new airport.</p>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextFieldInput
          id="name"
          name="name"
          placeholder="Name"
          label="Name"
          error={error.name}
          value={formData.name}
          handleChange={handleChange}
          onBlur={() => handleValidation("name", formData.name)}
          // autoFocus
          fullWidth
        />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextFieldInput
            id="code"
            name="code"
            placeholder="Code"
            label="Code"
            error={error.code}
            value={formData.code}
            handleChange={handleChange}
            onBlur={() => handleValidation("code", formData.code)}
            // autoFocus
            fullWidth
            sx={{ mr: 1 }}
          />
          <TextFieldInput
            id="state"
            name="state"
            placeholder="State"
            label="State"
            error={error.state}
            value={formData.state}
            handleChange={handleChange}
            onBlur={() => handleValidation("state", formData.state)}
            // autoFocus
            fullWidth
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <TextFieldInput
            id="lat"
            name="lat"
            placeholder="Latitude"
            label="Latitude"
            error={error.lat}
            value={formData.lat}
            handleChange={handleChange}
            onBlur={() => handleValidation("lat", formData.lat)}
            // autoFocus
            fullWidth
            sx={{ mr: 1 }}
          />
          <TextFieldInput
            id="lon"
            name="lon"
            placeholder="Longitude"
            label="Longitude"
            error={error.lon}
            value={formData.lon}
            handleChange={handleChange}
            onBlur={() => handleValidation("lon", formData.lon)}
            // autoFocus
            fullWidth
          />
        </Box>
        <AutoCompleteInput
          id="region"
          name="region"
          placeholder="Select Airport Region"
          label="Airport Region"
          options={constants.DB_ENUM.AirportRegionChoice?.map(
            (item: string) => ({
              label: helpers.capitalizeFirstLetterEveryWord(
                item.replaceAll("_", " ").toLocaleLowerCase()
              ),
              value: item,
            })
          )}
          error={error.region}
          value={formData.region}
          fullWidth={true}
          handleChange={handleChange}
          onBlur={() => handleValidation("region", formData.region)}
        />
      </Box>

      {!apiLoading && (
        <Button
          onClick={handleAddAirport}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none" }}
        >
          {/* <InsertInvitationIcon /> */}
          Add
        </Button>
      )}
      {!apiLoading && (
        <Button
          onClick={closeSidebar}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none", mx: 1 }}
          color="error"
        >
          {/* <InsertInvitationIcon /> */}
          Cancel
        </Button>
      )}
      {apiLoading ? (
        <Box sx={{ mt: 2 }}>
          <A4ALoader />
        </Box>
      ) : (
        ""
      )}
    </div>
  );
};

export { AddAirport };
