/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      organizationType
      groupType
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorContactId
      operatorContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      admins {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      airports {
        items {
          id
          organizationId
          airportId
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContact
          facilityOwner
          facilityOwnerContact
          legalCounselContact
          legalCounsel
          facilityLocation
          totalCapacityDays
          totalCapacityGallons
          secured
          moExpiration
          moType
          moTypeCustomValue
          leaseExpiration
          leaseType
          leaseTypeCustomValue
          dateLastFinancialSUP
          dateLastA4aOperationalReview
          netInventoryCalculation
          entryFee
          nonMemberRate
          costSharingFormula
          reserveDepositPrebill
          thirdPartyDebt
          memberLoans
          dateLastPropertyAppraisal
          aviationLiabilityLimit
          environmentalLiabilityLimit
          comments
          deleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companies {
        items {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        nextToken
        __typename
      }
      members {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      calendar {
        items {
          id
          organizationId
          calendarEventTypeId
          name
          startDateTime
          endDateTime
          description
          location
          website
          groupRateCode
          organizer
          specialRates
          venue
          venueWebSite
          createdAt
          createdByUserId
          modifiedByUserId
          updatedAt
          organizationCalendarId
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      supportAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      updatedAt
      companyOrganizationsId
      userOrganizationId
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      type
      organizations {
        items {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        nextToken
        __typename
      }
      members {
        items {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationCompaniesId
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $id: ID
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoUserId
      termsAgree
      termsAgreeDate
      participationAgreement
      participationAgreementDate
      organization {
        items {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        nextToken
        __typename
      }
      roles
      companyId
      company {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      firstName
      lastName
      email
      office
      mobile
      linkedInUrl
      imageUrl
      timeZone
      monthlyUpdates
      generalAnnouncements
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      organizationAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationMember {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      lastLoginDate
      weeklyDigestNotification
      monthlyDigestNotification
      quarterlyEmailNotification
      annualDigestNotification
      membershipAlertsNotification
      generalEmailNotification
      organizationSupportAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      organizationContactsId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      categoryType
      documentCategoryId
      documentCategory {
        id
        name
        type
        bucketType
        documents {
          nextToken
          __typename
        }
        description
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      bucketType
      title
      description
      publishedByUserId
      publishedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      publishedDate
      url
      fileSize
      fileType {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      companyId
      createdAt
      createByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      categoryTypeId
      category {
        id
        name
        type
        bucketType
        documents {
          nextToken
          __typename
        }
        description
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationDocumentsId
      documentCategoryDocumentsId
      documentFileTypeId
      __typename
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $id: ID
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        categoryType
        documentCategoryId
        documentCategory {
          id
          name
          type
          bucketType
          description
          createdAt
          createdByUserId
          deleted
          updatedAt
          __typename
        }
        bucketType
        title
        description
        publishedByUserId
        publishedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        publishedDate
        url
        fileSize
        fileType {
          id
          name
          description
          createdAt
          updatedAt
          __typename
        }
        companyId
        createdAt
        createByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        categoryTypeId
        category {
          id
          name
          type
          bucketType
          description
          createdAt
          createdByUserId
          deleted
          updatedAt
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationDocumentsId
        documentCategoryDocumentsId
        documentFileTypeId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFileType = /* GraphQL */ `
  query GetFileType($id: ID!) {
    getFileType(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFileTypes = /* GraphQL */ `
  query ListFileTypes(
    $filter: ModelFileTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocumentCategory = /* GraphQL */ `
  query GetDocumentCategory($id: ID!) {
    getDocumentCategory(id: $id) {
      id
      name
      type
      bucketType
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      description
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listDocumentCategories = /* GraphQL */ `
  query ListDocumentCategories(
    $id: ID
    $filter: ModelDocumentCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocumentCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        bucketType
        documents {
          nextToken
          __typename
        }
        description
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendar = /* GraphQL */ `
  query GetCalendar($id: ID!) {
    getCalendar(id: $id) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      calendarEventTypeId
      calendarEventType {
        id
        name
        description
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      name
      startDateTime
      endDateTime
      description
      location
      website
      groupRateCode
      organizer
      specialRates
      venue
      venueWebSite
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifiedByUserId
      modifiedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationCalendarId
      __typename
    }
  }
`;
export const listCalendars = /* GraphQL */ `
  query ListCalendars(
    $id: ID
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCalendars(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        calendarEventTypeId
        calendarEventType {
          id
          name
          description
          createdAt
          updatedAt
          __typename
        }
        name
        startDateTime
        endDateTime
        description
        location
        website
        groupRateCode
        organizer
        specialRates
        venue
        venueWebSite
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifiedByUserId
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCalendarId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCalendarEventType = /* GraphQL */ `
  query GetCalendarEventType($id: ID!) {
    getCalendarEventType(id: $id) {
      id
      name
      description
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listCalendarEventTypes = /* GraphQL */ `
  query ListCalendarEventTypes(
    $id: ID
    $filter: ModelCalendarEventTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCalendarEventTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAirportVolume = /* GraphQL */ `
  query GetAirportVolume($id: ID!) {
    getAirportVolume(id: $id) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      year
      volume
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listAirportVolumes = /* GraphQL */ `
  query ListAirportVolumes(
    $id: ID
    $filter: ModelAirportVolumeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAirportVolumes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        year
        volume
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAirport = /* GraphQL */ `
  query GetAirport($id: ID!) {
    getAirport(id: $id) {
      id
      name
      code
      oldCode
      facility
      region
      state
      place {
        terminal
        gate
        lat
        lon
        __typename
      }
      consortiumGroup {
        items {
          id
          organizationId
          airportId
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContact
          facilityOwner
          facilityOwnerContact
          legalCounselContact
          legalCounsel
          facilityLocation
          totalCapacityDays
          totalCapacityGallons
          secured
          moExpiration
          moType
          moTypeCustomValue
          leaseExpiration
          leaseType
          leaseTypeCustomValue
          dateLastFinancialSUP
          dateLastA4aOperationalReview
          netInventoryCalculation
          entryFee
          nonMemberRate
          costSharingFormula
          reserveDepositPrebill
          thirdPartyDebt
          memberLoans
          dateLastPropertyAppraisal
          aviationLiabilityLimit
          environmentalLiabilityLimit
          comments
          deleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      consortiumType
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorGeneralManagerId
      operatorGeneralManager {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      secondaryContactId
      secondaryContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      facilityOwnerId
      facilityOwner {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      facilityOwnerContactId
      facilityOwnerContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      inventoryAccounting
      deliveryToPlane {
        items {
          id
          airportId
          organizationId
          airportDeliveryToPlaneId
          obFiltration
          hydrant
          closedLoop
          length
          hydrantLocations
          pits
          refuelerLocations
          efso
          efsoLocation
          leakDetection
          leakDetectionDescription
          flowRateF1Peak
          flowRateF1Max
          flowRateF2Peak
          flowRateF2Max
          flowRateF3Peak
          flowRateF3Max
          pipingSizeMin
          pipingSizeMax
          currentIntoPlaneOperators
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deliveryToFacility {
        items {
          id
          airportId
          provider
          organizationId
          method
          primary
          numberLines
          frequency
          frequencyCustomValue
          filtrationType
          filtrationTypeCustomValue
          filtrationDescription
          flowRateTypical
          flowRateMax
          pipingSize
          offloadLocations
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      storage {
        items {
          id
          airportId
          organizationId
          tankId
          yearInstalled
          product
          storageLocation
          storageASTUST
          nextAPI653
          storageFunction
          totalBBL
          usableBBL
          opStatus
          owner
          security
          secondaryContainment
          cathodicProtection
          leakDetection
          totalGals
          usableGals
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      organizationAirportsId
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listAirports = /* GraphQL */ `
  query ListAirports(
    $id: ID
    $filter: ModelAirportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAirports(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAirportConsortiumGroup = /* GraphQL */ `
  query GetAirportConsortiumGroup($id: ID!) {
    getAirportConsortiumGroup(id: $id) {
      id
      organizationId
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorGeneralManagerId
      operatorGeneralManager {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      secondaryContact
      facilityOwner
      facilityOwnerContact
      legalCounselContact
      legalCounsel
      facilityLocation
      totalCapacityDays
      totalCapacityGallons
      secured
      moExpiration
      moType
      moTypeCustomValue
      leaseExpiration
      leaseType
      leaseTypeCustomValue
      dateLastFinancialSUP
      dateLastA4aOperationalReview
      netInventoryCalculation
      entryFee
      nonMemberRate
      costSharingFormula
      reserveDepositPrebill
      thirdPartyDebt
      memberLoans
      dateLastPropertyAppraisal
      aviationLiabilityLimit
      environmentalLiabilityLimit
      comments
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAirportConsortiumGroups = /* GraphQL */ `
  query ListAirportConsortiumGroups(
    $id: ID
    $filter: ModelAirportConsortiumGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAirportConsortiumGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContact
        facilityOwner
        facilityOwnerContact
        legalCounselContact
        legalCounsel
        facilityLocation
        totalCapacityDays
        totalCapacityGallons
        secured
        moExpiration
        moType
        moTypeCustomValue
        leaseExpiration
        leaseType
        leaseTypeCustomValue
        dateLastFinancialSUP
        dateLastA4aOperationalReview
        netInventoryCalculation
        entryFee
        nonMemberRate
        costSharingFormula
        reserveDepositPrebill
        thirdPartyDebt
        memberLoans
        dateLastPropertyAppraisal
        aviationLiabilityLimit
        environmentalLiabilityLimit
        comments
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDeliveryToPlane = /* GraphQL */ `
  query GetDeliveryToPlane($id: ID!) {
    getDeliveryToPlane(id: $id) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      airportDeliveryToPlaneId
      obFiltration
      hydrant
      closedLoop
      length
      hydrantLocations
      pits
      refuelerLocations
      efso
      efsoLocation
      leakDetection
      leakDetectionDescription
      flowRateF1Peak
      flowRateF1Max
      flowRateF2Peak
      flowRateF2Max
      flowRateF3Peak
      flowRateF3Max
      pipingSizeMin
      pipingSizeMax
      currentIntoPlaneOperators
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDeliveryToPlanes = /* GraphQL */ `
  query ListDeliveryToPlanes(
    $id: ID
    $filter: ModelDeliveryToPlaneFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeliveryToPlanes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        airportDeliveryToPlaneId
        obFiltration
        hydrant
        closedLoop
        length
        hydrantLocations
        pits
        refuelerLocations
        efso
        efsoLocation
        leakDetection
        leakDetectionDescription
        flowRateF1Peak
        flowRateF1Max
        flowRateF2Peak
        flowRateF2Max
        flowRateF3Peak
        flowRateF3Max
        pipingSizeMin
        pipingSizeMax
        currentIntoPlaneOperators
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDeliveryToFacility = /* GraphQL */ `
  query GetDeliveryToFacility($id: ID!) {
    getDeliveryToFacility(id: $id) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      provider
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      method
      primary
      numberLines
      frequency
      frequencyCustomValue
      filtrationType
      filtrationTypeCustomValue
      filtrationDescription
      flowRateTypical
      flowRateMax
      pipingSize
      offloadLocations
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDeliveryToFacilities = /* GraphQL */ `
  query ListDeliveryToFacilities(
    $id: ID
    $filter: ModelDeliveryToFacilityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDeliveryToFacilities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        provider
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        method
        primary
        numberLines
        frequency
        frequencyCustomValue
        filtrationType
        filtrationTypeCustomValue
        filtrationDescription
        flowRateTypical
        flowRateMax
        pipingSize
        offloadLocations
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStorage = /* GraphQL */ `
  query GetStorage($id: ID!) {
    getStorage(id: $id) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      tankId
      yearInstalled
      product
      storageLocation
      storageASTUST
      nextAPI653
      storageFunction
      totalBBL
      usableBBL
      opStatus
      owner
      security
      secondaryContainment
      cathodicProtection
      leakDetection
      totalGals
      usableGals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStorages = /* GraphQL */ `
  query ListStorages(
    $id: ID
    $filter: ModelStorageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStorages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        tankId
        yearInstalled
        product
        storageLocation
        storageASTUST
        nextAPI653
        storageFunction
        totalBBL
        usableBBL
        opStatus
        owner
        security
        secondaryContainment
        cathodicProtection
        leakDetection
        totalGals
        usableGals
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      method
      scope {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      notificationDate
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        method
        scope {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        notificationDate
        title
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getModifyRecord = /* GraphQL */ `
  query GetModifyRecord($id: ID!) {
    getModifyRecord(id: $id) {
      fieldName
      fieldValue
      dataOperation
      modifiedAt
      modifiedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listModifyRecords = /* GraphQL */ `
  query ListModifyRecords(
    $filter: ModelModifyRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModifyRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInviteLog = /* GraphQL */ `
  query GetInviteLog($id: ID!) {
    getInviteLog(id: $id) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      userId
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      isAccepted
      type
      sendByUserId
      sendBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      acceptedAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const listInviteLogs = /* GraphQL */ `
  query ListInviteLogs(
    $id: ID
    $filter: ModelInviteLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInviteLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        userId
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        isAccepted
        type
        sendByUserId
        sendBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        acceptedAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgAdminUser = /* GraphQL */ `
  query GetOrgAdminUser($id: ID!) {
    getOrgAdminUser(id: $id) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgAdminUsers = /* GraphQL */ `
  query ListOrgAdminUsers(
    $filter: ModelOrgAdminUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgAdminUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        userId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgMemberUser = /* GraphQL */ `
  query GetOrgMemberUser($id: ID!) {
    getOrgMemberUser(id: $id) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgMemberUsers = /* GraphQL */ `
  query ListOrgMemberUsers(
    $filter: ModelOrgMemberUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgMemberUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        userId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrgSupportAdminUser = /* GraphQL */ `
  query GetOrgSupportAdminUser($id: ID!) {
    getOrgSupportAdminUser(id: $id) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrgSupportAdminUsers = /* GraphQL */ `
  query ListOrgSupportAdminUsers(
    $filter: ModelOrgSupportAdminUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgSupportAdminUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        userId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByCompanyId = /* GraphQL */ `
  query UsersByCompanyId(
    $companyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const documentsByAirportId = /* GraphQL */ `
  query DocumentsByAirportId(
    $airportId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByAirportId(
      airportId: $airportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        categoryType
        documentCategoryId
        documentCategory {
          id
          name
          type
          bucketType
          description
          createdAt
          createdByUserId
          deleted
          updatedAt
          __typename
        }
        bucketType
        title
        description
        publishedByUserId
        publishedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        publishedDate
        url
        fileSize
        fileType {
          id
          name
          description
          createdAt
          updatedAt
          __typename
        }
        companyId
        createdAt
        createByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        categoryTypeId
        category {
          id
          name
          type
          bucketType
          description
          createdAt
          createdByUserId
          deleted
          updatedAt
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationDocumentsId
        documentCategoryDocumentsId
        documentFileTypeId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const volumeByAirportidAndYear = /* GraphQL */ `
  query VolumeByAirportidAndYear(
    $airportId: ID!
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAirportVolumeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    volumeByAirportidAndYear(
      airportId: $airportId
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        year
        volume
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const airportConsortiumGroupsByOrganizationIdAndAirportId = /* GraphQL */ `
  query AirportConsortiumGroupsByOrganizationIdAndAirportId(
    $organizationId: ID!
    $airportId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAirportConsortiumGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airportConsortiumGroupsByOrganizationIdAndAirportId(
      organizationId: $organizationId
      airportId: $airportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContact
        facilityOwner
        facilityOwnerContact
        legalCounselContact
        legalCounsel
        facilityLocation
        totalCapacityDays
        totalCapacityGallons
        secured
        moExpiration
        moType
        moTypeCustomValue
        leaseExpiration
        leaseType
        leaseTypeCustomValue
        dateLastFinancialSUP
        dateLastA4aOperationalReview
        netInventoryCalculation
        entryFee
        nonMemberRate
        costSharingFormula
        reserveDepositPrebill
        thirdPartyDebt
        memberLoans
        dateLastPropertyAppraisal
        aviationLiabilityLimit
        environmentalLiabilityLimit
        comments
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const airportConsortiumGroupsByAirportIdAndOrganizationId = /* GraphQL */ `
  query AirportConsortiumGroupsByAirportIdAndOrganizationId(
    $airportId: ID!
    $organizationId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAirportConsortiumGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    airportConsortiumGroupsByAirportIdAndOrganizationId(
      airportId: $airportId
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContact
        facilityOwner
        facilityOwnerContact
        legalCounselContact
        legalCounsel
        facilityLocation
        totalCapacityDays
        totalCapacityGallons
        secured
        moExpiration
        moType
        moTypeCustomValue
        leaseExpiration
        leaseType
        leaseTypeCustomValue
        dateLastFinancialSUP
        dateLastA4aOperationalReview
        netInventoryCalculation
        entryFee
        nonMemberRate
        costSharingFormula
        reserveDepositPrebill
        thirdPartyDebt
        memberLoans
        dateLastPropertyAppraisal
        aviationLiabilityLimit
        environmentalLiabilityLimit
        comments
        deleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deliveryToPlanesByAirportId = /* GraphQL */ `
  query DeliveryToPlanesByAirportId(
    $airportId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryToPlaneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryToPlanesByAirportId(
      airportId: $airportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        airportDeliveryToPlaneId
        obFiltration
        hydrant
        closedLoop
        length
        hydrantLocations
        pits
        refuelerLocations
        efso
        efsoLocation
        leakDetection
        leakDetectionDescription
        flowRateF1Peak
        flowRateF1Max
        flowRateF2Peak
        flowRateF2Max
        flowRateF3Peak
        flowRateF3Max
        pipingSizeMin
        pipingSizeMax
        currentIntoPlaneOperators
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const deliveryToFacilitiesByAirportId = /* GraphQL */ `
  query DeliveryToFacilitiesByAirportId(
    $airportId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryToFacilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryToFacilitiesByAirportId(
      airportId: $airportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        provider
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        method
        primary
        numberLines
        frequency
        frequencyCustomValue
        filtrationType
        filtrationTypeCustomValue
        filtrationDescription
        flowRateTypical
        flowRateMax
        pipingSize
        offloadLocations
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const storagesByAirportId = /* GraphQL */ `
  query StoragesByAirportId(
    $airportId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStorageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storagesByAirportId(
      airportId: $airportId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          inventoryAccounting
          createdAt
          organizationAirportsId
          deleted
          updatedAt
          __typename
        }
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        tankId
        yearInstalled
        product
        storageLocation
        storageASTUST
        nextAPI653
        storageFunction
        totalBBL
        usableBBL
        opStatus
        owner
        security
        secondaryContainment
        cathodicProtection
        leakDetection
        totalGals
        usableGals
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orgAdminUsersByOrganizationId = /* GraphQL */ `
  query OrgAdminUsersByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgAdminUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgAdminUsersByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        userId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orgAdminUsersByUserId = /* GraphQL */ `
  query OrgAdminUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgAdminUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgAdminUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        userId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orgMemberUsersByOrganizationId = /* GraphQL */ `
  query OrgMemberUsersByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgMemberUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgMemberUsersByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        userId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orgMemberUsersByUserId = /* GraphQL */ `
  query OrgMemberUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgMemberUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgMemberUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        userId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orgSupportAdminUsersByOrganizationId = /* GraphQL */ `
  query OrgSupportAdminUsersByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgSupportAdminUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgSupportAdminUsersByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        userId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orgSupportAdminUsersByUserId = /* GraphQL */ `
  query OrgSupportAdminUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgSupportAdminUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgSupportAdminUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        userId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
