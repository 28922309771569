import React, {
  useCallback,
  useState,
  useEffect,
  createContext,
  useReducer,
} from "react";
import { Auth, Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core/lib/Hub";
import { useNavigate } from "react-router-dom";
import { IAuthContext, IAuthState, IAuthProvider } from "./interface";
import { useSearchParams } from "react-router-dom";
import { UserService } from "./../../services/UserService";
import { constants, helpers } from "./../../utils";
import { UserSubscription } from "./../../services/UserService";
import { InviteLogService } from "./../../services/InviteLogService";
import {
  OrgAdminUserService,
  OrgAdminUserSubscription,
} from "./../../services/OrgAdminUserService";
import {
  OrgMemberUserService,
  OrgMemberUserSubscription,
} from "./../../services/OrgMemberUserService";
import {
  OrgSupportAdminUserService,
  OrgSupportAdminUserSubscription,
} from "./../../services/OrgSupportAdminUserService";

// actions
const authContextAction = {
  SET_USER: "SET_USER",
  RESET_STATE: "RESET_STATE",
  UPDATE_USER: "UPDATE_USER",
  SET_SIGNED_IN_FLAG: "SET_SIGNED_IN_FLAG",
  SET_SIGN_IN_STATUS: "SET_SIGN_IN_STATUS",
  SET_PENDING_INVITE: "SET_PENDING_INVITE",
  UPDATE_PENDING_INVITE: "UPDATE_PENDING_INVITE",
  CLEAR_PENDING_INVITE: "CLEAR_PENDING_INVITE",
};

type Action = { type: string; payload?: any };

// initial auth state
const initialAuthState: IAuthState = {
  user: {
    id: undefined,
    username: undefined,
    signedUp: false,
    confirmed: false,
    token: undefined,
    idToken: undefined,
    code: "",
    errMsg: "",
    groups: [],
    isPasswordReset: undefined,
    email: undefined,
    dbUserId: undefined,
    orgRole: {
      admin: [],
      member: [],
      supportAdmin: [],
    },
    dbUser: null,
  },
  isSignedIn: false,
  signInStatus: constants.SIGN_IN_STATUS.CHECKING,
  pendingInvites: [],
};

const checkSession = (): Promise<boolean> =>
  Auth.currentSession().then((response) => response.isValid());

function authReducer(state: IAuthState, action: Action): IAuthState {
  switch (action.type) {
    case authContextAction.SET_USER:
      return { ...state, user: action.payload };
    case authContextAction.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload } };
    case authContextAction.SET_SIGNED_IN_FLAG:
      return { ...state, isSignedIn: action.payload };
    case authContextAction.SET_SIGN_IN_STATUS:
      return { ...state, signInStatus: action.payload };
    case authContextAction.SET_PENDING_INVITE:
      return { ...state, pendingInvites: action.payload };
    case authContextAction.UPDATE_PENDING_INVITE:
      return { ...state, pendingInvites: action.payload };
    case authContextAction.CLEAR_PENDING_INVITE:
      return { ...state, pendingInvites: [] };
    case authContextAction.RESET_STATE:
      return { ...initialAuthState };
    default:
      return state;
  }
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

const AuthProvider: React.FC<IAuthProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialAuthState);
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const navigate = useNavigate();

  // url search params
  const [searchParams] = useSearchParams();
  const apiUser = new UserService();
  const apiInviteLog = new InviteLogService();
  const apiOrgAdminUser = new OrgAdminUserService();
  const apiOrgMemberUser = new OrgMemberUserService();
  const apiOrgSupportAdminUser = new OrgSupportAdminUserService();

  // LocalStorage event handler
  const handleLocalStorage = async () => {
    const hasToken =
      Object.keys(localStorage).filter((item: string) =>
        item.includes("idToken")
      ).length !== 0;

    // if localStorage don't have token signout user
    if (!hasToken) {
      await Auth.signOut();
      // navigate to login page
      navigate("/auth/in");
    }
  };

  // Subscription useEffect
  useEffect(() => {
    // user table amplify subscription
    // create user subscription for real time updates
    const updateUserSubscription = UserSubscription.getOnUpdate();
    const createOrgAdminUserSubscription =
      OrgAdminUserSubscription.getOnCreate();
    const createOrgMemberUserSubscription =
      OrgMemberUserSubscription.getOnCreate();
    const createOrgSupportAdminUserSubscription =
      OrgSupportAdminUserSubscription.getOnCreate();
    const deleteOrgAdminUserSubscription =
      OrgAdminUserSubscription.getOnDelete();
    const deleteOrgSupportAdminUserSubscription =
      OrgSupportAdminUserSubscription.getOnDelete();
    const deleteOrgMemberUserSubscription =
      OrgMemberUserSubscription.getOnDelete();

    if (state?.isSignedIn) {
      // Subscribe
      updateUserSubscription.subscribe({
        next: async (data: any) => {
          // get user data
          const tempUser = data?.value?.data?.onUpdateUser;

          // sign-out user
          if (
            tempUser?.email === state?.user?.email &&
            tempUser?.deleted &&
            !state?.user?.groups?.includes(constants?.ROLES?.A4A_ADMIN)
          ) {
            await Auth.signOut();
            // navigate to login page
            navigate("/auth/in");
          }
        },
      });

      // create org admin user relation subscription
      createOrgAdminUserSubscription.subscribe({
        next: async (data: any) => {
          // get user data
          const relation = data?.value?.data?.onCreateOrgAdminUser;

          // update orgRole
          if (relation?.userId === state?.user?.dbUserId) {
            dispatch({
              type: authContextAction.UPDATE_USER,
              payload: {
                orgRole: {
                  ...state?.user?.orgRole,
                  admin: [...(state?.user?.orgRole?.admin as any), relation],
                },
              },
            });
          }
        },
      });

      // create org member user relation subscription
      createOrgMemberUserSubscription.subscribe({
        next: async (data: any) => {
          // get user org data
          const relation = data?.value?.data?.onCreateOrgMemberUser;

          // update orgRole
          if (relation?.userId === state?.user?.dbUserId) {
            dispatch({
              type: authContextAction.UPDATE_USER,
              payload: {
                orgRole: {
                  ...state?.user?.orgRole,
                  member: [...(state?.user?.orgRole?.member as any), relation],
                },
              },
            });
          }
        },
      });

      // create org support admin user relation subscription
      createOrgSupportAdminUserSubscription.subscribe({
        next: async (data: any) => {
          // get user org data
          const relation = data?.value?.data?.onCreateOrgSupportAdminUser;

          // update orgRole
          if (relation?.userId === state?.user?.dbUserId) {
            dispatch({
              type: authContextAction.UPDATE_USER,
              payload: {
                orgRole: {
                  ...state?.user?.orgRole,
                  supportAdmin: [
                    ...(state?.user?.orgRole?.supportAdmin as any),
                    relation,
                  ],
                },
              },
            });
          }
        },
      });

      // delete org admin user relation subscription
      deleteOrgAdminUserSubscription.subscribe({
        next: async (data: any) => {
          // get user data
          const relation = data?.value?.data?.onDeleteOrgAdminUser;

          // update orgRole
          if (relation?.userId === state?.user?.dbUserId) {
            const newAdminRole: any = state?.user?.orgRole?.admin?.filter(
              (item) => item?.organizationId !== relation?.organizationId
            );

            dispatch({
              type: authContextAction.UPDATE_USER,
              payload: {
                orgRole: {
                  ...state?.user?.orgRole,
                  admin: [...newAdminRole],
                },
              },
            });
          }
        },
      });

      // delete org member user relation subscription
      deleteOrgMemberUserSubscription.subscribe({
        next: async (data: any) => {
          // get user org data
          const relation = data?.value?.data?.onDeleteOrgMemberUser;

          // update orgRole
          if (relation?.userId === state?.user?.dbUserId) {
            // remove org
            const newMemberRole: any = state?.user?.orgRole?.member?.filter(
              (item) => item?.organizationId !== relation?.organizationId
            );

            dispatch({
              type: authContextAction.UPDATE_USER,
              payload: {
                orgRole: {
                  ...state?.user?.orgRole,
                  member: [...newMemberRole],
                },
              },
            });
          }
        },
      });

      // delete org support admin user relation subscription
      deleteOrgSupportAdminUserSubscription.subscribe({
        next: async (data: any) => {
          // get user data
          const relation = data?.value?.data?.onDeleteOrgSupportAdminUser;

          // update orgRole
          if (relation?.userId === state?.user?.dbUserId) {
            const newSupportAdminRole: any =
              state?.user?.orgRole?.supportAdmin?.filter(
                (item) => item?.organizationId !== relation?.organizationId
              );

            dispatch({
              type: authContextAction.UPDATE_USER,
              payload: {
                orgRole: {
                  ...state?.user?.orgRole,
                  supportAdmin: [...newSupportAdminRole],
                },
              },
            });
          }
        },
      });

      // add localStorage event handler
      window.addEventListener("storage", handleLocalStorage);
    } else {
      // cleanup subscription on logout
      return () => {
        // cleanup subscription on component unmount
        if ("unsubscribe" in updateUserSubscription) {
          updateUserSubscription.unsubscribe();
        }

        // cleanup subscription on component unmount
        if ("unsubscribe" in createOrgAdminUserSubscription) {
          createOrgAdminUserSubscription.unsubscribe();
        }

        // cleanup subscription on component unmount
        if ("unsubscribe" in createOrgSupportAdminUserSubscription) {
          createOrgSupportAdminUserSubscription.unsubscribe();
        }

        // cleanup subscription on component unmount
        if ("unsubscribe" in createOrgMemberUserSubscription) {
          createOrgMemberUserSubscription.unsubscribe();
        }

        // cleanup subscription on component unmount
        if ("unsubscribe" in deleteOrgAdminUserSubscription) {
          deleteOrgAdminUserSubscription.unsubscribe();
        }

        // cleanup subscription on component unmount
        if ("unsubscribe" in deleteOrgSupportAdminUserSubscription) {
          deleteOrgSupportAdminUserSubscription.unsubscribe();
        }

        // cleanup subscription on component unmount
        if ("unsubscribe" in deleteOrgMemberUserSubscription) {
          deleteOrgMemberUserSubscription.unsubscribe();
        }

        // remove localStorage event handler
        window.removeEventListener("storage", handleLocalStorage);
      };
    }
  }, [state?.isSignedIn]);

  useEffect(() => {
    // update sign in status
    dispatch({
      type: authContextAction.SET_SIGN_IN_STATUS,
      payload: constants.SIGN_IN_STATUS.CHECKING,
    });

    Auth.currentAuthenticatedUser()
      .then(async (userResult) => {
        const groups =
          userResult?.signInUserSession?.idToken?.payload["cognito:groups"] !==
          undefined
            ? userResult?.signInUserSession?.idToken?.payload["cognito:groups"]
            : [];

        const temp = await apiUser.getUserByEmail(
          userResult?.attributes["email"]
        );

        // if user data exist in database
        if (temp !== null) {
          const filter = { userId: { eq: temp?.id } };
          const adminRoles = await fetchUserOrgAdminRoles(filter);
          const memberRoles = await fetchUserOrgMemberRoles(filter);
          const supportAdminRoles = await fetchUserOrgSupportAdminRoles(filter);

          const payload = {
            id: userResult.username,
            username: userResult.username,
            signedUp: true,
            confirmed: true,
            token: userResult.signInUserSession.accessToken,
            idToken: userResult.signInUserSession.idToken,
            code: "",
            errMsg: "",
            groups: groups,
            isPasswordReset: userResult?.attributes["custom:isPasswordReset"],
            email: userResult?.attributes["email"],
            dbUserId: temp !== null ? temp?.id : "",
            orgRole: {
              admin: adminRoles,
              member: memberRoles,
              supportAdmin: supportAdminRoles,
            },
            dbUser: temp,
          };

          dispatch({ type: authContextAction.SET_USER, payload: payload });
          dispatch({
            type: authContextAction.SET_SIGN_IN_STATUS,
            payload: constants.SIGN_IN_STATUS.SIGNED_IN,
          });
        } else {
          dispatch({
            type: authContextAction.SET_SIGN_IN_STATUS,
            payload: constants.SIGN_IN_STATUS.SIGNED_OUT,
          });

          // sign-out user
          await Auth.signOut();
          // navigate to sign-in page
          navigate("/auth/in");
        }
      })
      .catch((error) => {
        console.log(error);
        // update sign in status
        dispatch({ type: authContextAction.RESET_STATE });
        dispatch({
          type: authContextAction.SET_SIGNED_IN_FLAG,
          payload: false,
        });
        dispatch({
          type: authContextAction.SET_SIGN_IN_STATUS,
          payload: constants.SIGN_IN_STATUS.SIGNED_OUT,
        });
      });

    // cleanup
    return () => {};
  }, []);

  // Amplify Hub
  const authListener: HubCallback = useCallback(
    async ({ payload: { event, data } }) => {
      const groups =
        data?.signInUserSession?.idToken?.payload["cognito:groups"] !==
        undefined
          ? data?.signInUserSession?.idToken?.payload["cognito:groups"]
          : [];

      switch (event) {
        case "signIn":
          dispatch({
            type: authContextAction?.SET_SIGN_IN_STATUS,
            payload: constants.SIGN_IN_STATUS.CHECKING,
          });

          const temp = await apiUser.getUserByEmail(data?.attributes?.email);

          // if user exist in database
          if (temp !== null) {
            const filter = { userId: { eq: temp?.id } };
            const adminRoles = await fetchUserOrgAdminRoles(filter);
            const memberRoles = await fetchUserOrgMemberRoles(filter);
            const supportAdminRoles = await fetchUserOrgSupportAdminRoles(
              filter
            );

            const payload_x = {
              id: data.username,
              username: data.username,
              signedUp: true,
              confirmed: true,
              token: data.signInUserSession.accessToken,
              idToken: data.signInUserSession.idToken,
              code: "",
              errMsg: "",
              groups: groups,
              isPasswordReset: data?.attributes["custom:isPasswordReset"],
              email: data?.attributes?.email,
              dbUserId: temp !== null ? temp?.id : "",
              orgRole: {
                admin: adminRoles,
                member: memberRoles,
                supportAdmin: supportAdminRoles,
              },
              dbUser: temp,
            };

            dispatch({ type: authContextAction?.SET_USER, payload: payload_x });
            dispatch({
              type: authContextAction?.SET_SIGN_IN_STATUS,
              payload: constants.SIGN_IN_STATUS.SIGNED_IN,
            });
            dispatch({
              type: authContextAction?.SET_SIGNED_IN_FLAG,
              payload: true,
            });
          } else {
            dispatch({
              type: authContextAction?.SET_SIGN_IN_STATUS,
              payload: constants.SIGN_IN_STATUS.SIGNED_OUT,
            });

            // sign-out user
            await Auth.signOut();
            // navigate to sign-in page
            navigate("/auth/in");
          }

          break;
        case "updateUserAttributes":
          const payload_y = {
            isPasswordReset: data["custom:isPasswordReset"]["isUpdated"]
              ? "yes"
              : state?.user?.isPasswordReset,
          };
          dispatch({
            type: authContextAction?.UPDATE_USER,
            payload: payload_y,
          });
          break;
        case "signOut":
          dispatch({ type: authContextAction?.RESET_STATE });
          dispatch({
            type: authContextAction?.SET_SIGN_IN_STATUS,
            payload: constants.SIGN_IN_STATUS.SIGNED_OUT,
          });
          dispatch({
            type: authContextAction?.SET_SIGNED_IN_FLAG,
            payload: false,
          });

          // remove localStorage event handler
          window.removeEventListener("storage", handleLocalStorage);

          break;
        case "signIn_failure":
          dispatch({ type: authContextAction?.RESET_STATE });
          dispatch({
            type: authContextAction?.SET_SIGN_IN_STATUS,
            payload: constants.SIGN_IN_STATUS.SIGNED_OUT,
          });
          dispatch({
            type: authContextAction?.SET_SIGNED_IN_FLAG,
            payload: false,
          });
          break;
      }
    },
    [state?.user]
  );

  // setup amplify auth listener
  useEffect(() => {
    Hub.listen("auth", authListener);
    return () => Hub.remove("auth", authListener);
  }, [authListener]);

  // check user session status
  useEffect(() => {
    const getSessionStatus = async () => {
      const isValidSession = await checkSession();
      dispatch({
        type: authContextAction.SET_SIGNED_IN_FLAG,
        payload: isValidSession,
      });
    };

    if (state?.user?.signedUp && state?.user?.confirmed && state?.user?.token) {
      getSessionStatus();
    }
  }, [state?.user?.confirmed, state?.user?.signedUp, state?.user?.token]);

  useEffect(() => {
    // if temporary password is not changed
    if (state?.user?.isPasswordReset === "no") {
      if (searchParams.get("redirect") !== null) {
        setRedirectUrl(`${searchParams.get("redirect")}`);

        navigate(
          `/auth/password/?redirect=${encodeURIComponent(
            `${searchParams.get("redirect")}`
          )}`
        );
      } else {
        if (redirectUrl !== "") {
          navigate(
            `/auth/password/?redirect=${encodeURIComponent(`${redirectUrl}`)}`
          );
        } else {
          navigate("/auth/password");
        }
      }
    }

    if (state?.isSignedIn) {
      if (state?.pendingInvites?.length) {
        // navigate(`/invite/accept/org?id=${state?.pendingInvites[0].id}`);
        navigate("/invite/accept/all");
      }
    }
  }, [navigate]);

  // check pending invites
  useEffect(() => {
    if (state?.isSignedIn) {
      // IIFE
      (async () => {
        helpers.startPageLoader();

        const filter = {
          userId: { eq: state?.user?.dbUserId },
          isAccepted: { eq: false },
          deleted: { ne: true },
        };

        const invites = await apiInviteLog.recursiveFetchListInviteLogs(filter);

        let formattedData = invites?.map((item: any) => ({
          id: item?.id,
          organization: item?.organization,
          user: item?.user,
          isAccepted: item?.isAccepted,
          type: item?.type,
        }));

        if (formattedData?.length) {
          // set pending invites
          dispatch({
            type: authContextAction.SET_PENDING_INVITE,
            payload: formattedData,
          });

          // stop page loader
          helpers.stopPageLoader();
        }

        // stop page loader
        helpers.stopPageLoader();
      })();
    }
  }, [state?.isSignedIn]);

  // run pending invite loop
  useEffect(() => {
    if (state?.pendingInvites?.length) {
      // navigate(`/invite/accept/org?id=${state?.pendingInvites[0].id}`);
      navigate("/invite/accept/all");
    }
  }, [state?.pendingInvites]);

  const fetchUserOrgMemberRoles = async (
    filter: any = {},
    currentRoles: Array<any> = [],
    reqNextToken: string | null = null
  ): Promise<any> => {
    const { relations, nextToken } =
      await apiOrgMemberUser.getAllOrgMemberUsers(filter, reqNextToken);

    let allData = [...currentRoles, ...relations];

    if (nextToken) {
      return await fetchUserOrgMemberRoles(filter, allData, nextToken);
    } else {
      // return relations array
      return allData;
    }
  };

  const fetchUserOrgAdminRoles = async (
    filter: any = {},
    currentRoles: Array<any> = [],
    reqNextToken: string | null = null
  ): Promise<any> => {
    const { relations, nextToken } = await apiOrgAdminUser.getAllOrgAdminUsers(
      filter,
      reqNextToken
    );

    let allData = [...currentRoles, ...relations];

    if (nextToken) {
      return await fetchUserOrgAdminRoles(filter, allData, nextToken);
    } else {
      // return relations array
      return allData;
    }
  };

  const fetchUserOrgSupportAdminRoles = async (
    filter: any = {},
    currentRoles: Array<any> = [],
    reqNextToken: string | null = null
  ): Promise<any> => {
    const { relations, nextToken } =
      await apiOrgSupportAdminUser.getAllOrgSupportAdminUsers(
        filter,
        reqNextToken
      );

    let allData = [...currentRoles, ...relations];

    if (nextToken) {
      return await fetchUserOrgSupportAdminRoles(filter, allData, nextToken);
    } else {
      // return relations array
      return allData;
    }
  };

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth, authContextAction };
