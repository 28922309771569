import { FC } from "react";
import { usStateCodeName } from "../../constants/map";
import { Box } from "@mui/material";

interface IAirportVolumePopup {
  volume: string;
  last5YearRecord: any;
  state: string;
  name: string;
  code: string;
  selectedYear: number
}

const AirportVolumePopup: FC<IAirportVolumePopup> = ({
  selectedYear,
  volume,
  last5YearRecord,
  state,
  name,
  code,
}: IAirportVolumePopup) => {
  const formattedVolume = (parseFloat(volume) / 1000000)
    .toFixed(2)
    .toLocaleString();
  return (
    <>
      {!!code && <Box className="popup-heading">{code}</Box>}
      <Box className="volume-airport-heading">
        {name}, {usStateCodeName[state as keyof typeof usStateCodeName]}
      </Box>
      <Box className="volume-popup-operator">
        {<h3>Selected year: {formattedVolume} M</h3>}
      </Box>
      <Box className="volume-popup-operator">
        <h3>Last 5 year gallons:</h3>
      </Box>
      <Box className="volume-last-5-year-box">
        {Object.getOwnPropertyNames(last5YearRecord)
          .sort((a, b) => parseInt(b) - parseInt(a))
          .map((key, index) => {
            return (
              <Box className="volume-last-5-year-reading" key={`volume-pop-up-${index}`}>
                <Box>{key}:</Box>
                <span>
                  {(parseFloat((last5YearRecord[key] as any).volume) / 1000000)
                    .toFixed(2)
                    .toLocaleString() ?? ""}{" "}
                  M
                </span>
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default AirportVolumePopup;
