import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./components/App";
import reportWebVitals from "./reportWebVitals";

import CssBaseline from "@mui/material/CssBaseline";
// MUI Roboto Fonts
// import "@fontsource/roboto/300.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";

import { Amplify } from "aws-amplify";
import amplifyconfig from "./amplifyconfiguration.json";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Configure AWS Amplify
// Amplify.configure(awsExports);
Amplify.configure(amplifyconfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <>
    <CssBaseline />
    <App />
    <ToastContainer />
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
