import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  CardContent,
  Typography,
  Breadcrumbs,
  Button,
  Badge,
} from "@mui/material";
import { constants } from "../../utils";

import * as SkeletonLoader from "../SkeletonLoader";
import { Link as RRDLink, useParams } from "react-router-dom";
import {
  DocumentSubscription,
  DocumentService,
} from "./../../services/DocumentService";
import DescriptionIcon from "@mui/icons-material/Description";
import { S3Service } from "../../services/S3Service";
import { UploadDocument } from "../UploadDocument";
import { EditDocument } from "../EditDocument";

import ListIcon from "@mui/icons-material/List";
import IconButton from "@mui/material/IconButton";
import AppsIcon from "@mui/icons-material/Apps";
import { useAuth } from "../AuthProvider";

import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";

import { ReactComponent as DocIcon } from "../../assets/DOC-Icon.svg";
import { ReactComponent as PdfIcon } from "../../assets/PDF-Icon.svg";
import { ReactComponent as PptIcon } from "../../assets/PPT-Icon.svg";
import { ReactComponent as XlsIcon } from "../../assets/XLS-Icon.svg";
import { DocumentFileType } from "../../constants/map";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Sidebar } from "./../Common/Sidebar";
import Popover from "@mui/material/Popover";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { DataTableBase } from "./../Common/DataTableBase";
import DangerousIcon from "@mui/icons-material/Dangerous";
import EditIcon from "@mui/icons-material/Edit";
import {
  CurrentCategoryTitle,
  FileUploadButton,
  GridDocContainer,
  GridDocTitle,
  GridDocWrapper,
  GridMenuItem,
  ListDocTitle,
  ParentGrid,
} from "./style";

interface IIsolatedDocumentMenu {
  document: any;
  setPopverAnchorEl: any;
  setPopoverDocument: any;
  getPreSignedURL: any;
  handleDeleteDocument: any;
  fileIconMapping: any;
  handleEditDocument: any;
  handleCheckNewFile: any;
}

const IsolatedDocumentMenu: React.FC<IIsolatedDocumentMenu> = ({
  document,
  setPopverAnchorEl,
  setPopoverDocument,
  getPreSignedURL,
  handleDeleteDocument,
  fileIconMapping,
  handleEditDocument,
  handleCheckNewFile,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { state } = useAuth();

  return (
    <React.Fragment>
      <GridDocWrapper
        elevation={0}
        onMouseEnter={(event: any) => {
          setPopverAnchorEl(event.currentTarget);
          setPopoverDocument(document);
        }}
        onMouseLeave={(event: any) => {
          setPopverAnchorEl(null);
          setPopoverDocument(null);
        }}
        onClick={(event: any) => setAnchorEl(event.currentTarget)}
      >
        <GridDocContainer>
          {handleCheckNewFile(document) ? (
            <Badge color="primary" badgeContent=" " variant="dot">
              {fileIconMapping(document?.url)}
            </Badge>
          ) : (
            fileIconMapping(document?.url)
          )}

          <GridDocTitle>
            {document?.title.length > 20
              ? `${document?.title.substr(0, 20)}...`
              : document?.title}
          </GridDocTitle>
        </GridDocContainer>
      </GridDocWrapper>
      <Menu
        elevation={2}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <GridMenuItem
          onClick={() => {
            // open document link in new page
            window.open(
              getPreSignedURL(document?.url, document?.bucketType),
              "_blank"
            );
            // close menu item
            setAnchorEl(null);
          }}
        >
          View
        </GridMenuItem>
        <GridMenuItem
          onClick={() => handleEditDocument(document, setAnchorEl)}
          disabled={!state?.user?.groups?.includes(constants?.ROLES?.A4A_ADMIN)}
        >
          Edit
        </GridMenuItem>
        <GridMenuItem
          onClick={() => handleDeleteDocument(document, setAnchorEl)}
          disabled={!state?.user?.groups?.includes(constants?.ROLES?.A4A_ADMIN)}
        >
          Delete
        </GridMenuItem>
      </Menu>
    </React.Fragment>
  );
};

interface IGetListView {
  documentList: Array<any>;
  tableTitle?: string;
  handleCheckNewFile: any;
  fileIconMapping: any;
  getPreSignedURL: any;
  handleDeleteDocument: any;
  handleEditDocument: any;
}

const GetMemomizedListView: React.FC<IGetListView> = React.memo(
  ({
    documentList = [],
    handleCheckNewFile,
    fileIconMapping,
    getPreSignedURL,
    tableTitle = "Table Data",
    handleDeleteDocument,
    handleEditDocument,
  }) => {
    // const navigate = useNavigate();
    const { state: auth } = useAuth();
    const isSuperUser = auth?.user?.groups?.includes(
      constants?.ROLES?.A4A_ADMIN
    );

    // pass it to function as setAnchorEl replacement
    const emptyFunction = () => {};

    const columns = [
      {
        name: "Title",
        selector: (row: any) => row?.title,
        sortable: true,
        cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              background: "transparent",
            }}
            component={Paper}
            elevation={0}
            onClick={() => {
              // open document link in new page
              window.open(getPreSignedURL(row?.url, row?.bucketType), "_blank");
            }}
          >
            {handleCheckNewFile(row) ? (
              <Badge color="primary" badgeContent=" " variant="dot">
                {fileIconMapping(row?.url)}
              </Badge>
            ) : (
              fileIconMapping(row?.url)
            )}

            <ListDocTitle>{row?.title}</ListDocTitle>
          </Box>
        ),
      },
      {
        name: "Description",
        selector: (row: any) => row?.description,
        sortable: true,
        wrap: true,
      },
      {
        name: "Document Date",
        grow: 0.4,
        selector: (row: any) =>
          new Date(row?.publishedDate).toLocaleDateString("en-us", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        sortable: true,
        wrap: true,
      },
      {
        name: "Action",
        grow: 0.4,
        cell: (row: any) => (
          <>
            <IconButton
              disabled={!isSuperUser}
              title="Edit"
              onClick={(event) => {
                handleEditDocument(
                  documentList?.filter((doc) => doc?.id === row?.id)[0],
                  emptyFunction
                );
              }}
            >
              <EditIcon color={isSuperUser ? "success" : "disabled"} />
            </IconButton>
            <IconButton
              disabled={!isSuperUser}
              title="Delete"
              onClick={(event) => {
                handleDeleteDocument(
                  documentList?.filter((doc) => doc?.id === row?.id)[0],
                  emptyFunction
                );
              }}
            >
              <DangerousIcon color={isSuperUser ? "error" : "disabled"} />
            </IconButton>
          </>
        ),
      },
    ];

    let tableData: Array<any> = documentList?.map(
      (document: any, idx: number) => ({
        id: document?.id,
        title: document?.title,
        url: document?.url,
        description: document?.description,
        publishedDate: document?.publishedDate,
        bucketType: document?.bucketType,
      })
    );

    // sort document by publishedDate or Document Date most recent at top
    tableData.sort((itemA: any, itemB: any) => {
      if (itemA.publishedDate > itemB.publishedDate) {
        return -1;
      }
      if (itemA.publishedDate < itemB.publishedDate) {
        return 1;
      }
      return 0;
    });

    return (
      <DataTableBase
        columns={columns}
        data={tableData}
        dense={false}
        fixedHeader
        pagination={false}
        persistTableHead
        highlightOnHover
      />
    );
  }
);

const ViewDocumentByCategory = () => {
  // state
  const [docByCategoryList, setDocByCategoryList] = React.useState<Array<any>>(
    []
  );
  const [filteredDocByCategoryList, setFilteredDocByCategoryList] =
    React.useState<Array<any>>([]);

  // show data as List or Grid Item
  const [showDataAs, setShowDataAs] = React.useState<string>("list");
  const [isSuperUser, setSuperUser] = React.useState<boolean>(false);

  const { docCategory } = useParams();
  const { state: auth } = useAuth();
  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();
  const [openUploadForm, setOpenUploadForm] = React.useState<boolean>(false);
  const [popoverDocument, setPopoverDocument] = React.useState<any>(null);

  const [popoverAnchorEl, setPopverAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [categoryIdNameMapping, setCategoryIdNameMapping] = React.useState<any>(
    {}
  );

  const openPopover = Boolean(popoverAnchorEl);
  const apiDocument = new DocumentService();
  const apiAwsS3 = new S3Service();
  const [documentToEdit, setDocumentToEdit] = React.useState<any>(null);
  const [openEditDocumentForm, setOpenEditDocumentForm] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (dataProviderState.documentCategoryList.length) {
      const tempCategoryIdNameMapping: any = {};
      dataProviderState.documentCategoryList.forEach((category) => {
        tempCategoryIdNameMapping[category.id] = category.name;
      });
      setCategoryIdNameMapping(tempCategoryIdNameMapping);
    }
  }, [dataProviderState.documentCategoryList]);

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.DOCUMENT_CATEGORY;

    // if signed user is A4A Admin
    if (auth?.user?.groups.includes(constants?.ROLES?.A4A_ADMIN)) {
      setSuperUser(true);
    }

    // create document subscription for real time updates
    const createDocumentSubscription = DocumentSubscription.getOnCreate();

    // subscribe
    createDocumentSubscription.subscribe({
      next: (data: any) => {
        // get document by Category list
        (async () => {
          // console.log("I am from subscription.");
          // console.log(data);
        })();
      },
    });

    // clear global search bar text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
      payload: "",
    });

    // set global search bar placeholder text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT,
      payload: "Search documents...",
    });

    // cleanup
    return () => {
      // cleanup subscription on component unmount
      if ("unsubscribe" in createDocumentSubscription) {
        createDocumentSubscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    let searchText = dataProviderState?.topBarSearchText;

    if (searchText.trim() !== "") {
      // udpate document by Category list
      setFilteredDocByCategoryList([
        ...docByCategoryList?.filter(
          (item) =>
            item?.url?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.title?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.description?.toLowerCase().includes(searchText.toLowerCase())
        ),
      ]);
    } else {
      // udpate document list
      setFilteredDocByCategoryList([...docByCategoryList]);
    }
  }, [dataProviderState?.topBarSearchText]);

  useEffect(() => {
    const keys = Object.keys(dataProviderState?.globalDocumentList);

    if (
      keys?.length &&
      keys.indexOf(docCategory as any) !== -1 &&
      docCategory !== undefined
    ) {
      const tempArr = dataProviderState?.globalDocumentList[docCategory as any];

      // sort document by document date in ascending order
      tempArr.sort(
        (itemA: any, itemB: any) =>
          // {
          // if (
          //   Date.parse(itemA?.publishedDate) < Date.parse(itemB?.publishedDate)
          // ) {
          //   return -1;
          // }
          // if (
          //   Date.parse(itemA?.publishedDate) > Date.parse(itemB?.publishedDate)
          // ) {
          //   return 1;
          // }
          // return 0;
          // }
          new Date(itemB.publishedDate!).getTime() -
          new Date(itemA.publishedDate!).getTime()
      );

      setFilteredDocByCategoryList([...tempArr]);
      setDocByCategoryList([...tempArr]);
    } else {
      setFilteredDocByCategoryList([]);
      setDocByCategoryList([]);
    }
  }, [dataProviderState?.globalDocumentList]);

  // reset editable document
  useEffect(() => {
    if (!openEditDocumentForm) {
      setDocumentToEdit(null);
    }
  }, [openEditDocumentForm]);

  const fileIconMapping = (url: string) => {
    let component = (
      <DescriptionIcon className="public-doc-icon" fontSize="large" />
    );

    // const style = {
    //   minWidth: "55px",
    //   minHeight: "55px",
    //   maxWidth: "55px",
    //   maxHeight: "55px",
    //   margin: "10px",
    // };

    if (url.toLocaleLowerCase().includes(DocumentFileType.DOC)) {
      component = <DocIcon className="public-doc-icon" />;
    } else if (url.toLocaleLowerCase().includes(DocumentFileType.PDF)) {
      component = <PdfIcon className="public-doc-icon" />;
    } else if (url.toLocaleLowerCase().includes(DocumentFileType.PPT)) {
      component = <PptIcon className="public-doc-icon" />;
    } else if (url.toLocaleLowerCase().includes(DocumentFileType.XLS)) {
      component = <XlsIcon className="public-doc-icon" />;
    }

    return component;
  };

  const getPreSignedURL = (file_name: string, bucket_type: string) => {
    const s3s = new S3Service();

    const t_buck_type = bucket_type === "GENERAL" ? "General" : "Airport";

    // generate pre signed url
    const url = s3s.getSignedUrl(`${t_buck_type} Docs/${file_name}`);

    return url;
  };

  // delete global document
  const handleDeleteDocument = (document: any, closeMenuItem: any) => {
    Swal.fire({
      title: "Do you want to delete ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      text: "NOTE: This operation will permanently delete document from storage.",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        const newDocumentList = docByCategoryList.filter(
          (itm) => itm?.id !== document?.id
        );
        // file to be deleted from S3 bucket
        const fileName = document?.fileName;

        // make api call to delete document
        const response = await apiDocument.deleteDocumentById(document?.id);

        if (response !== null) {
          // Delete document from S3 bucket
          // await apiAwsS3.deleteObject({ Key: `General Docs/${fileName}` })

          // update component state
          setDocByCategoryList([...newDocumentList]);
          setFilteredDocByCategoryList([...newDocumentList]);

          // update data provider state
          dataProviderDispatch({
            type: dataContextAction?.SET_GLOBAL_DOCUMENT_LIST,
            payload: {
              ...dataProviderState?.globalDocumentList,
              [docCategory as any]: [...newDocumentList],
            },
          });
        }
      }
    });

    // close menu item
    closeMenuItem();
  };

  // edit global document
  const handleEditDocument = (document: any, closeMenuItem: any) => {
    setDocumentToEdit(document);
    setOpenEditDocumentForm(true);
    // close menu item
    closeMenuItem();
  };

  // check whether file publishedDate or Document Date under 90 days from today.
  const handleCheckNewFile = (doc: any = null) => {
    if (doc) {
      const today = dayjs();
      const publishedDate = dayjs(new Date(doc?.publishedDate));
      const diffInDays = today.diff(publishedDate, "days");
      // 90 days from published date
      // return diffInDays >= 0 && diffInDays <= 90;

      // 30 days from published date as per change
      return diffInDays >= 0 && diffInDays <= 30;
    }
    return false;
  };

  return (
    <>
      <ParentGrid sx={{ mt: 2 }}>
        <Card
          style={{
            padding: "20px 5px",
            margin: "0 auto",
            borderRadius: "16px",
            background: "rgb(96, 96, 96, 0.05)",
            minHeight: "75vh",
          }}
        >
          <CardContent>
            <Breadcrumbs aria-label="breadcrumb">
              <RRDLink to="/doc" style={{ textDecoration: "none" }}>
                <CurrentCategoryTitle variant="h6">
                  Global Document
                </CurrentCategoryTitle>
              </RRDLink>
              <CurrentCategoryTitle color="text.primary" variant="h6">
                {categoryIdNameMapping?.[`${docCategory}`] ?? "Unknown Folder"}
              </CurrentCategoryTitle>
            </Breadcrumbs>
            <FileUploadButton
              variant="contained"
              size="small"
              onClick={() => setOpenUploadForm((prev) => !prev)}
              disabled={
                !isSuperUser ||
                dataProviderState?.isGlobalDocumentListApiLoading
              }
            >
              <CloudUploadIcon />
            </FileUploadButton>
            <IconButton
              aria-label="tab-view"
              sx={{ float: "right" }}
              onClick={() => setShowDataAs("tab")}
              color={showDataAs === "tab" ? "primary" : "default"}
            >
              <AppsIcon />
            </IconButton>
            <IconButton
              aria-label="list-view"
              sx={{ float: "right" }}
              onClick={() => setShowDataAs("list")}
              color={showDataAs === "list" ? "primary" : "default"}
            >
              <ListIcon />
            </IconButton>
            {dataProviderState?.isGlobalDocumentListApiLoading && (
              <SkeletonLoader.ViewDocumentCategory />
            )}
            {!dataProviderState?.isGlobalDocumentListApiLoading &&
              showDataAs === "tab" && (
                <Grid sx={{ my: 2 }} container>
                  {filteredDocByCategoryList.map((itm: any, idx: number) => (
                    <Grid
                      xs={6}
                      sm={6}
                      md={2}
                      key={`itm-glb-doc-${itm?.id}-${idx}`}
                      item
                    >
                      <IsolatedDocumentMenu
                        document={itm}
                        setPopverAnchorEl={setPopverAnchorEl}
                        setPopoverDocument={setPopoverDocument}
                        getPreSignedURL={getPreSignedURL}
                        handleDeleteDocument={handleDeleteDocument}
                        fileIconMapping={fileIconMapping}
                        handleEditDocument={handleEditDocument}
                        handleCheckNewFile={handleCheckNewFile}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            {!dataProviderState?.isGlobalDocumentListApiLoading &&
              showDataAs === "list" && (
                <GetMemomizedListView
                  handleCheckNewFile={handleCheckNewFile}
                  documentList={filteredDocByCategoryList}
                  tableTitle="Global Document"
                  fileIconMapping={fileIconMapping}
                  getPreSignedURL={getPreSignedURL}
                  handleEditDocument={handleEditDocument}
                  handleDeleteDocument={handleDeleteDocument}
                />
              )}
          </CardContent>
        </Card>
      </ParentGrid>
      {openUploadForm && isSuperUser && (
        <Sidebar
          handleClose={() => setOpenUploadForm(false)}
          open={openUploadForm}
        >
          <UploadDocument
            bucketType={constants?.DocumentBucketTypeChoice?.GENERAL}
            categoryType={`${docCategory}`}
            closeSidebar={() => setOpenUploadForm(false)}
          />
        </Sidebar>
      )}

      {openEditDocumentForm && isSuperUser && (
        <Sidebar
          handleClose={() => setOpenEditDocumentForm(false)}
          open={openEditDocumentForm}
        >
          <EditDocument
            bucketType={constants?.DocumentBucketTypeChoice?.GENERAL}
            categoryType={`${docCategory}`}
            closeSidebar={() => setOpenEditDocumentForm(false)}
            document={documentToEdit}
          />
        </Sidebar>
      )}
      <Popover
        // id={id}
        sx={{
          pointerEvents: "none",
        }}
        open={openPopover}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopverAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        disableRestoreFocus
      >
        <Typography sx={{ px: 2, fontWeight: "bold" }}>
          Title: {popoverDocument !== null ? popoverDocument?.title : ""}
        </Typography>
        <Typography sx={{ px: 2 }}>
          Description:{" "}
          {popoverDocument !== null ? popoverDocument?.description : ""}
        </Typography>
        <Typography sx={{ px: 2 }}>
          Document Date:{" "}
          {popoverDocument !== null
            ? new Date(popoverDocument?.publishedDate).toLocaleDateString(
                "en-us",
                {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }
              )
            : ""}
        </Typography>
      </Popover>
    </>
  );
};

export { ViewDocumentByCategory };
