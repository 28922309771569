import React, { FC, useState, useEffect } from "react";
import { Button, Box, Alert } from "@mui/material";
import { A4ALoader } from "./../Common/A4ALoader";
import { Sidebar } from "../Common/Sidebar";
import { TextFieldInput } from "./../Common/TextFieldInput";
import { AutoCompleteInput } from "./../Common/AutoCompleteInput";
import { constants, helpers } from "./../../utils";
import { CompanySchema } from "./../../validations/schema";
import { CompanyService } from "../../services/CompanyService";
import MultiSelectCheckboxDropdown from "../MultiSelectCheckboxDropdown";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import { UserService } from "../../services/UserService";
import { AddCompanyMember } from "../AddCompanyMember";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useAuth } from "./../AuthProvider/AuthProvider";

// prop type
interface IAddCompany {
  closeSidebar: () => void;
}

interface IDefaultState {
  name: string;
  type: string;
}

// default state
const defaultState = {
  name: "",
  type: "",
  members: [],
};

const AddCompany: FC<IAddCompany> = ({ closeSidebar }) => {
  const { state, dispatch } = useMapContext();
  // state
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({ ...defaultState });
  const [error, setError] = useState<IDefaultState>({ ...defaultState });
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [openAddCompanyMember, setOpenAddCompanyMember] =
    useState<boolean>(false);
  const { state: authProviderState } = useAuth();

  // componentDidMount
  useEffect(() => {
    // cleanup
    return () => {};
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setError({ ...defaultState });

    let result: any = {};

    result = CompanySchema.createCompanyValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  // add company
  const handleAddCompany = async () => {
    var isValidationError = false;

    // clear state
    setSuccessMessage("");
    setError({ ...defaultState });

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      if (key !== "members") {
        const isValid = handleValidation(key, formData[key]);

        if (!isValidationError) {
          isValidationError = !isValid;
        }
      }
    });

    if (!isValidationError) {
      const cs = new CompanyService();
      const us = new UserService();

      // start api loader
      setApiLoading(true);

      const companyFields = {
        ...formData,
        createdByUserId: authProviderState?.user?.dbUserId,
      };
      delete companyFields.members;

      try {
        // create compnay
        const companyResponse = await cs.createCompany(companyFields);
        for (const memberId of formData.members) {
          await us.updateUserById({
            id: memberId,
            companyId: companyResponse.id,
          });
        }
        dispatch({
          type: mapContextAction.SET_COMPANY_LIST,
          payload: [...state.companyList, companyResponse],
        });
      } catch (e) {
        console.log(e);
      }

      // final cleanup
      setFormData({ ...defaultState });
      setError({ ...defaultState });
      setSuccessMessage("Company created.");

      // stop api loader
      setApiLoading(false);
    }
  };

  const memberOptions = state.userList.map((user) => ({
    name: `${user.firstName} ${user.lastName}`,
    value: user.id,
  }));

  return (
    <>
      <div>
        <p>Let's add a new company.</p>
        <TextFieldInput
          id="name"
          name="name"
          placeholder="Company Name"
          label="Company Name"
          error={error.name}
          value={formData.name}
          margin="normal"
          handleChange={handleChange}
          onBlur={() => handleValidation("name", formData.name)}
          // autoFocus
          fullWidth
        />
        <AutoCompleteInput
          id="type"
          name="type"
          placeholder="Select Company Type"
          label="Company Type"
          options={constants.DB_ENUM.CompanyTypeChoice?.map((item: string) => ({
            label: item,
            value: item,
          }))}
          error={error.type}
          value={formData.type}
          fullWidth={true}
          handleChange={handleChange}
          onBlur={() => handleValidation("type", formData.type)}
        />
        <Box
          sx={{
            width: "100%",
            mt: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MultiSelectCheckboxDropdown
            sx={{
              width: "calc(100% - 60px)",
              "& .MuiFormControl-root": {
                width: "100%",
                margin: "0px",
              },
            }}
            label="Members"
            options={memberOptions}
            selectHandler={(event) => {
              const {
                target: { value },
              } = event;
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                members: typeof value === "string" ? value.split(",") : value,
              }));
            }}
            selectedValue={formData.members}
            renderValue={formData.members
              .map(
                (member: string) =>
                  memberOptions.find((curMember) => curMember.value === member)
                    ?.name
              )
              .join(", ")}
          />
          <AddCircleOutlineIcon
            onClick={() => setOpenAddCompanyMember(true)}
            sx={{ cursor: "pointer", color: "#1976d2", fontSize: "30px" }}
          />
        </Box>
        {!apiLoading && (
          <Button
            onClick={handleAddCompany}
            size="small"
            variant="outlined"
            sx={{ my: 2, float: "right", textTransform: "none" }}
          >
            {/* <InsertInvitationIcon /> */}
            Add
          </Button>
        )}
        {!apiLoading && (
          <Button
            onClick={closeSidebar}
            size="small"
            variant="outlined"
            sx={{ my: 2, float: "right", textTransform: "none", mx: 1 }}
            color="error"
          >
            {/* <InsertInvitationIcon /> */}
            Cancel
          </Button>
        )}
        {apiLoading ? (
          <Box sx={{ mt: 2 }}>
            <A4ALoader />
          </Box>
        ) : (
          ""
        )}

        {successMessage !== "" && (
          <Alert severity="success" sx={{ mt: 10 }}>
            {successMessage}
          </Alert>
        )}
      </div>
      {openAddCompanyMember && (
        <Sidebar handleClose={() => setOpenAddCompanyMember(false)} open={true}>
          <AddCompanyMember
            closeSidebar={() => setOpenAddCompanyMember(false)}
            existingMembers={[]}
            companyId={""}
            allowExisting={false}
          />
        </Sidebar>
      )}
    </>
  );
};

export { AddCompany };
