// Page Title Constant Values
export const PAGE_TITLE = {
  HOME: "Airline Fuel Portal",
  SIGN_IN: "Sign In | Airline Fuel Portal",
  SIGN_UP: "Sign Up | Airline Fuel Portal",
  FORGOT_PASSWORD: "Forgot Password | Airline Fuel Portal",
  CHANGE_PASSWORD: "Change Password | Airline Fuel Portal",
  DASHBOARD: "Dashboard | Airline Fuel Portal",
  USERS: "Users | Airline Fuel Portal",
  CONFIRM_USER: "Confirm User | Airline Fuel Portal",
  ORGANIZATION: "Consortium | Airline Fuel Portal",
  ADD_ORGANIZATION: "Add Consortium | Airline Fuel Portal",
  ASSIGN_ROLES: "Assign Roles | Airline Fuel Portal",
  VIEW_NOTIFICATION: "View Notification | Airline Fuel Portal",
  AUTH_VERIFY: "Sign-In Verification | Airline Fuel Portal",
  ORGANIZATION_DETAIL: "Consortium Detail | Airline Fuel Portal",
  INVITE_USER: "Invite User | Airline Fuel Portal",
  // COMPANY: "Companies | Airline Fuel Portal",
  COMPANY: "Contacts | Airline Fuel Portal",
  ADD_COMPANY: "Add Company | Airline Fuel Portal",
  COMPANY_DETAIL: "Company Detail | Airline Fuel Portal",
  ACCEPT_ORG_INVITE: "Accept Consortium Invite | Airline Fuel Portal",
  ADD_DOCUMENT: "Add Document | Airline Fuel Portal",
  DOCUMENT: "Documents | Airline Fuel Portal",
  DOCUMENT_DETAIL: "Document Detail | Airline Fuel Portal",
  DOCUMENT_TYPE: "Document Types | Airline Fuel Portal",
  DOCUMENT_CATEGORY: "Document Categories | Airline Fuel Portal",
  ADD_DOCUMENT_TYPE: "Add Document Type | Airline Fuel Portal",
  ADD_DOCUMENT_CATEGORY: "Add Document Category | Airline Fuel Portal",
  AIRPORT: "Airport | Airline Fuel Portal",
  AIRPORT_DETAIL: "Airport Detail | Airline Fuel Portal",
  ADD_AIRPORT: "Add Airport | Airline Fuel Portal",
  CALENDAR: "Calendar | Airline Fuel Portal",
  DATA_EXPORT: "Data Export | Airline Fuel Portal",
};

// Color constant value
export const COLORS = {
  BLACK: "#000000",
  WHITE: "#FFFFFF",
  RED: "#b82028",
  YELLOW: "#f8ae19",
  GREY: "#949599",
  GREEN: "#64b445",
  BLUE: "#0088bc",
};

// Database Schema ENUMs
export const DB_ENUM = {
  OrganizationTypeChoice: [
    // "A4A_ADMIN",
    "FUEL_CONSORTIUM",
    "ENERGY_COUNCIL",
    "GENERAL",
  ],
  GroupTypeChoice: ["ADVISORY_COMMITTEE", "COMMITTEE", "CORP_CO_LLC"],
  CompanyTypeChoice: [
    "AIRLINE",
    "OPERATOR",
    "ENGINEER",
    "PIPELINE",
    "SUPPLIER",
    "INSURANCE",
    "LEGAL",
    "CONSULTANT",
  ],
  DataOperation: ["UPDATE", "DELETE"],
  DocumentCategoryTypeChoice: [
    "BUDGET_FINANCE",
    "ANNUAL_MEETING",
    "MO",
    "OTHER",
    "INSURANCE",
    "APPRAISAL",
    "AUDIT",
    "LEASE",
    "LOGISTICS",
    "ENGINEERING",
    "FUEL_QUALITY",
    "BYLAWS",
    "FUEL_CONSORTIUM_WORKSHOP",
    "IATA_ALERTS",
    "ADMIN",
    "GUIDANCE",
    "AUDIT_BUDGET_TAX",
    "ENVIRONMENTAL",
    "INTERLINE",
    "INVENTORY",
    "PORTAL_USER_DOCUMENTS",
    "DATABASE",
  ],
  DocumentBucketTypeChoice: ["AIRPORT", "GENERAL"],
  AirportRegionChoice: [
    "PADD_1A_NEW_ENGLAND",
    "PADD_1B_CENTERAL_ATLANTIC",
    "PADD_1C_LOWER_ATLANTIC",
    "PADD_2_MIDWEST",
    "PADD_3_GULF_COAST",
    "PADD_4_ROCKY_MOUNTAIN",
    "PADD_5_ALASKA",
    "PADD_5_HAWAII",
    "PADD_5_WEST_COAST",
    "PADD_NA_CARIBBEAN",
  ],
  FacilityLocationTypeChoice: ["ON_AIRPORT", "OFF_AIRPORT"],
  MOTypeChoice: ["EVERGREEN", "MONTH_TO_MONTH", "N_A", "CUSTOM"],
};

// Signed-in user roles
export const ROLES = {
  A4A_ADMIN: "A4A_ADMIN",
  ORG_ADMIN: "ORG_ADMIN",
  ORG_MEMBER: "ORG_MEMBER",
  SITE_ADMIN: "SITE_ADMIN",
};

// Sign in Status
export const SIGN_IN_STATUS = {
  CHECKING: "CHECKING",
  SIGNED_IN: "SIGNED_IN",
  REFRESHING_SIGN_IN: "REFRESHING_SIGN_IN",
  SIGNING_IN: "SIGNING_IN",
  NOT_SIGNED_IN: "NOT_SIGNED_IN",
  SIGNING_OUT: "SIGNING_OUT",
  SIGNED_OUT: "SIGNED_OUT",
};

// Document bucket type choice
export const DocumentBucketTypeChoice = {
  AIRPORT: "AIRPORT",
  GENERAL: "GENERAL",
  HELP: "help",
};

// Allowed MIME Type
export const ALLOWED_MIME_TYPE = [
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/epub+zip",
  "image/gif",
  "image/jpeg",
  "application/json",
  "application/ld+json",
  "audio/mpeg",
  "video/mp4",
  "video/mpeg",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "image/png",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "image/svg+xml",
  "image/tiff",
  "text/plain",
  "application/vnd.visio",
  "audio/wav",
  "audio/webm",
  "video/webm",
  "image/webp",
  "font/woff",
  "font/woff2",
  "application/xhtml+xml",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xml",
];

export const OrganizationTypeChoice = {
  // A4A_ADMIN: "A4A_ADMIN",
  FUEL_CONSORTIUM: "FUEL_CONSORTIUM",
  ENERGY_COUNCIL: "ENERGY_COUNCIL",
  GENERAL: "GENERAL",
  FUEL_COUNCIL: "FUEL_COUNCIL",
};

export const DocumentCategoryTypeLabelMap: any = {
  BUDGET_FINANCE: "Budget Finance",
  ANNUAL_MEETING: "Annual Meeting",
  MO: "Mo",
  OTHER: "Other",
  INSURANCE: "Insurance",
  APPRAISAL: "Appraisal",
  AUDIT: "Audit / Budget / Tax",
  LEASE: "Lease",
  LOGISTICS: "Logistics",
  ENGINEERING: "Engineering / Technical",
  FUEL_QUALITY: "Fuel Quality",
  BYLAWS: "Bylaws",
  FUEL_CONSORTIUM_WORKSHOP: "Fuel Consortium Workshop",
  IATA_ALERTS: "IATA Alerts",
  ADMIN: "Admin",
  GUIDANCE: "Guidance",
  AUDIT_BUDGET_TAX: "Audit Budget Tax",
  ENVIRONMENTAL: "Environmental",
  INTERLINE: "Interline",
  INVENTORY: "Inventory",
  PORTAL_USER_DOCUMENTS: "Portal User Documents",
  DATABASE: "Database",
};

export const OrganizationTypeChoiceLabelMapping: any = {
  FUEL_CONSORTIUM: "Fuel Consortium",
  ENERGY_COUNCIL: "A4A Energy Council",
  GENERAL: "General",
};

export const PasswordPolicyText: Array<string> = [
  "Require at least 8 characters long.",
  "Require at least one uppercase letter.",
  "Require at least one lowercase letter.",
  "Require at least one digit.",
  "Require at least one special character.",
];
