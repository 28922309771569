import React, { FC, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { Link as RRDLink } from "react-router-dom";
import { constants, helpers } from "../../utils";

// prop type
interface IHome {
  content?: React.ReactNode;
}

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://www.airlinefuelportal.com/">
        Airline Fuel Portal
      </Link>
      {". "}
      All rights reserved.
    </Typography>
  );
}

const Home: FC<IHome> = ({ content }) => {
  // componentDidMount
  useEffect(() => {
    document.title = constants.PAGE_TITLE.HOME;

    // start page loader
    // helpers.startPageLoader();

    setTimeout(() => {
      // stop page loader
      helpers.stopPageLoader();
    }, 1000);
    // componentWillUnmount
    return () => {};
  }, []);

  return (
    <Grid
      sx={{ minHeight: "100vh" }}
      direction="column"
      alignItems="center"
      justifyContent="center"
      container
    >
      <Grid md={12} p={3} sx={{ textAlign: "center" }} item>
        <RRDLink to="/">
          <img
            src={`${process.env.REACT_APP_BASE_URL}/assets/img/a4a_header_logo.png`}
            alt="A4A Header Logo"
            width="20%"
          />
        </RRDLink>
      </Grid>
      <Grid md={12} item>
        {content && content}
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Grid>
    </Grid>
  );
};

export { Home };
