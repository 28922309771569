import React from "react";
import {
  MapLegendCloseIcon,
  MapLegendCollapseBox,
  MapLegendContainer,
  MapLegendHeading,
  MapLegendHeatmapLegendCard,
  MapLegendHeatmapLegendColor,
  MapLegendHeatmapLegendContainer,
  MapLegendHeatmapLegendName,
  MapLegendIcon,
  MapLegendIconContainer,
  MapLegendText,
  MapLegendTextBox,
  MapOrgTypeLegendContainer,
  MapOrgTypeLegendValueCard,
  MapOrgTypeLegendValueColor,
  MapOrgTypeLegendValueText,
  MapTitle,
  VolumeMapLegendContainer,
} from "./style";
import {
  heatMapRange,
  heatMapRangeColor,
  organizationGroupType,
  organizationGroupTypeColorMapping,
} from "../../constants/map";
import { Box, ClickAwayListener, Collapse } from "@mui/material";

interface IMapLegend {
  isVolumesMap: boolean;
  markersHidden: boolean;
  isLegendExpanded: boolean;
  setIsLegendExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const MapLegend = ({
  isVolumesMap,
  markersHidden,
  isLegendExpanded,
  setIsLegendExpanded,
}: IMapLegend) => {
  return (
    // <ClickAwayListener onClickAway={() => setIsLegendExpanded(false)}>
      <Box>
        <MapLegendCollapseBox>
          <MapTitle>
            {isVolumesMap
              ? "US Airport Jet Fuel Throughput"
              : "US Airport Jet Fuel Facilities"}
          </MapTitle>
          <Collapse in={isLegendExpanded} orientation="horizontal">
            {markersHidden && (
              <VolumeMapLegendContainer
                sx={{
                  height: isLegendExpanded ? "auto" : "60px",
                }}
              >
                {isLegendExpanded && (
                  <>
                    <MapLegendTextBox>
                      <MapLegendHeading>Gallons (in Millions)</MapLegendHeading>
                      <MapLegendCloseIcon
                        onClick={() => setIsLegendExpanded(false)}
                      />
                    </MapLegendTextBox>
                    <MapLegendHeatmapLegendContainer>
                      {Object.keys(heatMapRange).map((heatMapKey) => (
                        <MapLegendHeatmapLegendCard key={heatMapKey}>
                          <MapLegendHeatmapLegendColor
                            sx={{
                              backgroundColor:
                                heatMapRangeColor[
                                  heatMapKey as keyof typeof heatMapRangeColor
                                ],
                            }}
                          />
                          <MapLegendHeatmapLegendName>
                            {heatMapRange[
                              heatMapKey as keyof typeof heatMapRange
                            ] /
                              (1000 * 1000)}
                          </MapLegendHeatmapLegendName>
                        </MapLegendHeatmapLegendCard>
                      ))}
                    </MapLegendHeatmapLegendContainer>
                  </>
                )}
              </VolumeMapLegendContainer>
            )}
            {!markersHidden && (
              <MapLegendContainer
                sx={{
                  height: isLegendExpanded ? "auto" : "60px",
                }}
              >
                {isLegendExpanded && (
                  <>
                    <MapLegendTextBox>
                      <MapLegendHeading>Organization Type</MapLegendHeading>
                      <MapLegendCloseIcon
                        onClick={() => setIsLegendExpanded(false)}
                      />
                    </MapLegendTextBox>
                    <MapOrgTypeLegendContainer>
                      {Object.keys(organizationGroupType).map((groupType) => (
                        <MapOrgTypeLegendValueCard key={groupType}>
                          <MapOrgTypeLegendValueColor
                            sx={{
                              backgroundColor:
                                organizationGroupTypeColorMapping[
                                  groupType as keyof typeof organizationGroupTypeColorMapping
                                ],
                            }}
                          />
                          <MapOrgTypeLegendValueText>
                            {
                              organizationGroupType[
                                groupType as keyof typeof organizationGroupType
                              ]
                            }
                          </MapOrgTypeLegendValueText>
                        </MapOrgTypeLegendValueCard>
                      ))}
                    </MapOrgTypeLegendContainer>
                  </>
                )}
              </MapLegendContainer>
            )}
          </Collapse>
        </MapLegendCollapseBox>
        {!isLegendExpanded && (
          <MapLegendIconContainer
            onClick={() => {
              setIsLegendExpanded(true);
            }}
          >
            <MapLegendIcon />
            <MapLegendText>Map Legend</MapLegendText>
          </MapLegendIconContainer>
        )}
      </Box>
    // </ClickAwayListener>
  );
};

export default MapLegend;
