import React, { FC, ReactNode, useState } from "react";
import {
  AirportDetailDrawerExpandSegment,
  AirportDetailDrawerExpandSegmentHeader,
  AirportDetailDrawerExpandSegmentHeading,
  AirportDetailDrawerExpandSegmentIcon,
} from "./style";

type IDataItemCollapseExpandSegment = {
  title: string;
  children: ReactNode;
  defaultExpanded?: boolean
};

const DataItemCollapseExpandSegment: FC<IDataItemCollapseExpandSegment> = ({
  title,
  children,
  defaultExpanded = false
}: IDataItemCollapseExpandSegment) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);
  return (
    <AirportDetailDrawerExpandSegment>
      <AirportDetailDrawerExpandSegmentHeader
        onClick={() => setIsExpanded((prevState) => !prevState)}
      >
        <AirportDetailDrawerExpandSegmentHeading>
          {title}
        </AirportDetailDrawerExpandSegmentHeading>
        <AirportDetailDrawerExpandSegmentIcon
          className={isExpanded ? "expanded" : ""}
          fontSize="large"
        />
      </AirportDetailDrawerExpandSegmentHeader>
      {isExpanded ? children : <></>}
    </AirportDetailDrawerExpandSegment>
  );
};

export default DataItemCollapseExpandSegment;
