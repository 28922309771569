import React from "react";
import {
  AirportDetailsDrawerFolderCardContainer,
  AirportDetailsDrawerFolderCardHeading,
  AirportDetailsDrawerFolderCardIcon,
  AirportDetailsDrawerFolderCardIconWrapper,
  AirportDetailsDrawerFolderCardNewCount,
} from "./style";
import { DocumentCategoryTypeChoiceMapping } from "../../constants/map";
import { DocumentCategoryTypeChoice } from "../../API";

interface IAirportDetailsDrawerFolderCard {
  category: DocumentCategoryTypeChoice;
  folderSelectHandler: (category: DocumentCategoryTypeChoice) => void;
  filesCount?: number;
  hasNewFile?: boolean;
  categoryIdNameMapping?: any
}

const AirportDetailsDrawerFolderCard = ({
  category,
  folderSelectHandler,
  filesCount,
  hasNewFile = false,
  categoryIdNameMapping
}: IAirportDetailsDrawerFolderCard) => {
  const isFileCountGreater = filesCount && filesCount > 9;
  return (
    <AirportDetailsDrawerFolderCardContainer
      onClick={() => folderSelectHandler(category)}
    >
      <AirportDetailsDrawerFolderCardIconWrapper>
        <AirportDetailsDrawerFolderCardIcon />
        {hasNewFile && (
          <AirportDetailsDrawerFolderCardNewCount/>
        )}
      </AirportDetailsDrawerFolderCardIconWrapper>
      <AirportDetailsDrawerFolderCardHeading>
        {`${categoryIdNameMapping[category] ?? 'Unknown Folder'} (${isFileCountGreater ? "9+" : filesCount})`}
      </AirportDetailsDrawerFolderCardHeading>
    </AirportDetailsDrawerFolderCardContainer>
  );
};

export default AirportDetailsDrawerFolderCard;
