import * as APITypes from "../API";
import * as CustomAPITypes from "../CustomAPI";

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const customListAirports = /* GraphQL */ `query CustomListAirports(
    $id: ID
    $filter: ModelAirportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAirports(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        code
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          items {
            organization {
              name
              organizationType
              groupType
            }
            id
            organizationId
            chairId
            chair {
              id
              name
              type
              createdAt
              updatedAt
              organizationCompaniesId
              __typename
            }
            chairContactId
            chairContact {
              id
              cognitoUserId
              roles
              companyId
              firstName
              lastName
              email
              office
              mobile
              createdAt
              updatedAt
              __typename
            }
            operatorId
            operator {
              id
              name
              type
              createdAt
              updatedAt
              organizationCompaniesId
              __typename
            }
            operatorGeneralManagerId
            operatorGeneralManager {
              id
              cognitoUserId
              roles
              firstName
              lastName
              email
              office
              mobile
              createdAt
              updatedAt
              __typename
            }
            airportId
            facilityLocation
            totalCapacityDays
            totalCapacityGallons
            secured
            moExpiration
            moType
            moTypeCustomValue
            leaseExpiration
            leaseType
            leaseTypeCustomValue
            netInventoryCalculation
            entryFee
            nonMemberRate
            costSharingFormula
            reserveDepositPrebill
            thirdPartyDebt
            memberLoans
            dateLastPropertyAppraisal
            aviationLiabilityLimit
            environmentalLiabilityLimit
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        consortiumType
        deliveryToFacility {
          items {
            id
            airportId
            method
          }
        }
        deleted
        createdAt
        updatedAt
        organizationAirportsId
        __typename
      }
      nextToken
      __typename
    }
  }
  ` as GeneratedQuery<
  APITypes.ListAirportsQueryVariables,
  CustomAPITypes.CustomListAirportsQuery
>;

export const customListAirportVolumes = /* GraphQL */ `
  query CustomListAirportVolumes(
    $id: ID
    $filter: ModelAirportVolumeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAirportVolumes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        airportId
        airport {
          id
          name
          code
          state
          place {
            terminal
            gate
            lat
            lon
            __typename
          }
          __typename
        }
        year
        volume
        createdAt
        createdBy {
          id
          cognitoUserId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
` as GeneratedQuery<
  APITypes.ListAirportsQueryVariables,
  CustomAPITypes.CustomListAirportVolumesQuery
>;

export const customGetAirport = /* GraphQL */ `
  query CustomGetAirport($id: ID!) {
    getAirport(id: $id) {
      id
      name
      code
      oldCode
      facility
      region
      state
      place {
        terminal
        gate
        lat
        lon
        __typename
      }
      consortiumGroup {
        items {
          id
          organization {
            name
            organizationType
            groupType
          }
          organizationId
          airportId
          chairId
          chair {
            id
            name
            type
            organizations {
              nextToken
              __typename
            }
            members {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            organizationCompaniesId
            __typename
          }
          chairContactId
          chairContact {
            id
            cognitoUserId
            termsAgree
            termsAgreeDate
            participationAgreement
            participationAgreementDate
            organization {
              nextToken
              __typename
            }
            roles
            companyId
            company {
              id
              name
              type
              createdAt
              updatedAt
              organizationCompaniesId
              __typename
            }
            firstName
            lastName
            email
            office
            mobile
            linkedInUrl
            imageUrl
            timeZone
            monthlyUpdates
            generalAnnouncements
            createdAt
            updatedAt
            organizationContactsId
            __typename
          }
          operatorId
          operator {
            id
            name
            type
            organizations {
              nextToken
              __typename
            }
            members {
              nextToken
              __typename
            }
            createdAt
            updatedAt
            organizationCompaniesId
            __typename
          }
          operatorGeneralManagerId
          operatorGeneralManager {
            id
            cognitoUserId
            termsAgree
            termsAgreeDate
            participationAgreement
            participationAgreementDate
            organization {
              nextToken
              __typename
            }
            roles
            companyId
            company {
              id
              name
              type
              createdAt
              updatedAt
              organizationCompaniesId
              __typename
            }
            firstName
            lastName
            email
            office
            mobile
            linkedInUrl
            imageUrl
            timeZone
            monthlyUpdates
            generalAnnouncements
            createdAt
            updatedAt
            organizationContactsId
            __typename
          }
          secondaryContact
          facilityOwner
          facilityOwnerContact
          createdAt
          legalCounsel
          legalCounselContact
          facilityLocation
          totalCapacityDays
          totalCapacityGallons
          secured
          moExpiration
          moType
          moTypeCustomValue
          leaseExpiration
          leaseType
          leaseTypeCustomValue
          netInventoryCalculation
          nonMemberRate
          costSharingFormula
          reserveDepositPrebill
          thirdPartyDebt
          memberLoans
          dateLastPropertyAppraisal
          dateLastFinancialSUP
          dateLastA4aOperationalReview
          entryFee
          aviationLiabilityLimit
          environmentalLiabilityLimit
          comments
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          bucketType
          companyId
          title
          description
          publishedDate
          url
          fileSize
          documentCategoryId
          documentCategory {
            name
            bucketType
            description
          }
          fileType {
            id
            name
            description
            createdAt
            updatedAt
            __typename
          }
          createdAt
          createdBy {
            id
            cognitoUserId
            companyId
          }
          updatedAt
          organizationDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      consortiumType
      deliveryToPlane {
        items {
          id
          airportId
          airportDeliveryToPlaneId
          organizationId
          obFiltration
          hydrant
          hydrantLocations
          refuelerLocations
          closedLoop
          length
          pits
          efso
          efsoLocation
          leakDetection
          leakDetectionDescription
          flowRateF1Peak
          flowRateF1Max
          flowRateF2Peak
          flowRateF2Max
          flowRateF3Peak
          flowRateF3Max
          pipingSizeMin
          pipingSizeMax
          currentIntoPlaneOperators
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deliveryToFacility {
        items {
          id
          airportId
          provider
          organizationId
          method
          primary
          numberLines
          frequency
          frequencyCustomValue
          filtrationType
          filtrationTypeCustomValue
          filtrationDescription
          flowRateTypical
          flowRateMax
          pipingSize
          offloadLocations
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      storage {
        items {
          id
          airportId
          organizationId
          tankId
          yearInstalled
          product
          storageLocation
          storageASTUST
          nextAPI653
          storageFunction
          totalBBL
          usableBBL
          opStatus
          security
          secondaryContainment
          cathodicProtection
          leakDetection
          createdAt
          updatedAt
          totalGals
          usableGals
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      organizationAirportsId
      updatedAt
      __typename
    }
  }
`;

export const customListCompanies = /* GraphQL */ `
  query CustomListCompanies(
    $id: ID
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          items {
            id
            firstName
            lastName
            mobile
            email
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        organizationCompaniesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const customListUsers = /* GraphQL */ `
  query CustomListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        organization {
          nextToken
          __typename
        }
        company {
          id
          name
          type
          __typename
        }
        roles
        companyId
        firstName
        lastName
        email
        office
        mobile
        updatedAt
        lastLoginDate
        organizationAdmin {
          items {
            id
            organization {
              id
              name
            }
          }
        }
        organizationMember {
          items {
            id
            organization {
              id
              name
            }
          }
        }
        organizationSupportAdmin {
          items {
            id
            organization {
              id
              name
            }
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const customGetOrganization = /* GraphQL */ `
  query CustomGetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      organizationType
      groupType
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorContactId
      operatorContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      admins {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          user {
            email
            id
          }
          __typename
        }
        nextToken
        __typename
      }
      supportAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          user {
            email
            id
          }
          __typename
        }
        nextToken
        __typename
      }
      airports {
        items {
          id
          organizationId
          airportId
          airport {
            code
            name
          }
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContact
          facilityOwner
          facilityOwnerContact
          legalCounselContact
          legalCounsel
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companies {
        items {
          id
          name
          type
          createdAt
          updatedAt
          organizationCompaniesId
          __typename
        }
        nextToken
        __typename
      }
      members {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          user {
            email
            id
          }
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          bucketType
          title
          description
          publishedDate
          url
          fileSize
          createdAt
          updatedAt
          organizationDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      calendar {
        items {
          id
          organizationId
          calendarEventTypeId
          name
          startDateTime
          endDateTime
          description
          website
          groupRateCode
          organizer
          specialRates
          venue
          venueWebSite
          createdAt
          updatedAt
          organizationCalendarId
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      companyOrganizationsId
      userOrganizationId
      __typename
    }
  }
`;

export const customGetCompany =
  /* GraphQL */ `query CustomGetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    name
    type
    organizations {
      nextToken
      __typename
    }
    members {
      items {
        id
        cognitoUserId
        companyId
        firstName
        lastName
        email
        mobile
        deleted
      }
      nextToken
      __typename
    }
    createdAt
    createdBy {
      id
      cognitoUserId
      termsAgree
      termsAgreeDate
      participationAgreement
      participationAgreementDate
      roles
      companyId
      firstName
      lastName
      email
      office
      mobile
      linkedInUrl
      imageUrl
      timeZone
      monthlyUpdates
      generalAnnouncements
      createdAt
      updatedAt
      organizationContactsId
      __typename
    }
    modifications {
      fieldName
      fieldValue
      dataOperation
      modifiedAt
      id
      createdAt
      updatedAt
      __typename
    }
    updatedAt
    organizationCompaniesId
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetCompanyQueryVariables,
    APITypes.GetCompanyQuery
  >;

export const sg_CustomListOrganizations = /* GraphQL */ `
  query CustomListOrganizations(
    $id: ID
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          company {
            id
            name
            type
          }
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          items {
            id
            userId
            organizationId
            user {
              id
              firstName
              lastName
              mobile
              email
            }
          }
          nextToken
          __typename
        }
        airports {
          items {
            id
            airport {
              id
              name
              code
              oldCode
            }
          }
          nextToken
          __typename
        }
        companies {
          items {
            id
            name
          }
          nextToken
          __typename
        }
        members {
          items {
            id
            userId
            organizationId
            user {
              id
              firstName
              lastName
              mobile
              email
            }
          }
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      nextToken
      __typename
    }
  }
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;

export const sg_CustomlistInviteLogs = /* GraphQL */ `
  query CustomListInviteLogs(
    $id: ID
    $filter: ModelInviteLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInviteLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        userId
        user {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          company {
            id
            name
          }
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationContactsId
          organizationSupportAdmin {
            items {
              id
              organizationId
              userId
            }
          }
          __typename
        }
        isAccepted
        type
        createdAt
        acceptedAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
` as GeneratedQuery<
  APITypes.ListInviteLogsQueryVariables,
  APITypes.ListInviteLogsQuery
>;

export const sg_CustomListAirportConsortiumGroups = /* GraphQL */ `
  query CustomListAirportConsortiumGroups(
    $id: ID
    $filter: ModelAirportConsortiumGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAirportConsortiumGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        airportId
        airport {
          id
          name
          code
          oldCode
          facility
          region
          state
          consortiumType
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContactId
          facilityOwnerId
          facilityOwnerContactId
          createdAt
          organizationAirportsId
          updatedAt
          __typename
        }
        organization {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          updatedAt
          companyOrganizationsId
          userOrganizationId
          admins {
            items {
              id
              userId
              organizationId
              user {
                id
                firstName
                lastName
                mobile
                email
              }
            }
            nextToken
            __typename
          }
          members {
            items {
              id
              userId
              organizationId
              user {
                id
                firstName
                lastName
                mobile
                email
              }
            }
            nextToken
            __typename
          }
          companies {
            items {
              id
              name
            }
            nextToken
            __typename
          }
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          company {
            id
            name
          }
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContact
        facilityOwner
        facilityOwnerContact
        legalCounselContact
        legalCounsel
        facilityLocation
        totalCapacityDays
        totalCapacityGallons
        secured
        moExpiration
        moType
        moTypeCustomValue
        leaseExpiration
        leaseType
        leaseTypeCustomValue
        netInventoryCalculation
        nonMemberRate
        costSharingFormula
        reserveDepositPrebill
        thirdPartyDebt
        memberLoans
        dateLastPropertyAppraisal
        dateLastA4aOperationalReview
        entryFee
        aviationLiabilityLimit
        environmentalLiabilityLimit
        comments
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
` as GeneratedQuery<
  APITypes.ListAirportConsortiumGroupsQueryVariables,
  APITypes.ListAirportConsortiumGroupsQuery
>;

export const sg_CustomTransactionInviteLogList = /* GraphQL */ `
  query CustomTransactionInviteLogList(
    $id: ID
    $filter: ModelInviteLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInviteLogs(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organization {
          id
          name
        }
        sendBy {
          id
          cognitoUserId
          company {
            id
            name
          }
          firstName
          lastName
          email
          mobile
          createdAt
          createdByUserId
          deleted
          updatedAt
        }
        userId
        user {
          id
          cognitoUserId
          company {
            id
            name
          }
          firstName
          lastName
          email
          mobile
          createdAt
          createdByUserId
          deleted
          updatedAt
        }
        isAccepted
        type
        createdAt
        acceptedAt
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
` as GeneratedQuery<
  APITypes.ListInviteLogsQueryVariables,
  APITypes.ListInviteLogsQuery
>;

export const sg_CustomTransactionDocumentList = /* GraphQL */ `
  query CustomTransactionDocumentList(
    $id: ID
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        organization {
          id
          name
        }
        airportId
        airport {
          id
          name
          code
          oldCode
          __typename
        }
        documentCategoryId
        documentCategory {
          id
          name
          type
          __typename
        }
        bucketType
        title
        description
        publishedByUserId
        publishedBy {
          id
          cognitoUserId
          firstName
          lastName
          email
          mobile
        }
        publishedDate
        url
        fileSize
        createdAt
        createByUserId
        createdBy {
          id
          cognitoUserId
          firstName
          lastName
          email
          mobile
        }
        categoryTypeId
        category {
          id
          name
          type
          bucketType
          description
        }
        deleted
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
` as GeneratedQuery<
  APITypes.ListDocumentsQueryVariables,
  APITypes.ListDocumentsQuery
>;

export const sg_CustomAirportDocumentList = /* GraphQL */ `
  query CustomAirportDocumentList(
    $id: ID
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDocuments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        organizationId
        airportId
        categoryType
        bucketType
        companyId
        title
        description
        publishedDate
        url
        fileSize
        documentCategoryId
        documentCategory {
          name
          bucketType
          description
        }
        fileType {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          companyId
        }
        updatedAt
        organizationDocumentsId
        documentFileTypeId
      }
    nextToken
    __typename
    }
  }
` as GeneratedQuery<
  APITypes.ListDocumentsQueryVariables,
  APITypes.ListDocumentsQuery
>;
