import React from "react";
import {
  AirportDetailsDrawerDocCardContainer,
  AirportDetailsDrawerDocCardIcon,
  AirportDetailsDrawerDocCardName,
} from "./style";
import { dataTabIndex } from "./DataTab";

interface IAirportDetailsDrawerDocCard {
  name: string;
  selected: boolean;
  index: dataTabIndex;
  selectHandler: (index: dataTabIndex) => void
}

const AirportDetailsDrawerDocCard = ({
  name,
  selected,
  index,
  selectHandler
}: IAirportDetailsDrawerDocCard) => {
  return (
    <AirportDetailsDrawerDocCardContainer onClick={() => selectHandler(index)}>
      <AirportDetailsDrawerDocCardIcon />
      <AirportDetailsDrawerDocCardName className={selected ? "selected" : ""}>
        {name}
      </AirportDetailsDrawerDocCardName>
    </AirportDetailsDrawerDocCardContainer>
  );
};

export default AirportDetailsDrawerDocCard;
