import { useEffect, useState, FC } from "react";
import { Button, Box } from "@mui/material";
import { Auth } from "aws-amplify";
import { A4ALoader } from "../Common/A4ALoader";
import { TagsInput } from "../Common/TagsInput";
import { helpers } from "../../utils";

interface IInviteUsers {
  closeSidebar: () => void;
}

const InviteUsers: FC<IInviteUsers> = ({ closeSidebar }) => {
  // state
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [authStatus, setAuthStatus] = useState<Array<object>>([]);
  const [users, setUsers] = useState<Array<string>>([]);
  const [userListError, setUserListError] = useState<string>("");

  // componentDidMount
  useEffect(() => {
    // cleanup
    return () => {};
  }, []);

  const handleSendInvite = async () => {
    // clear auth status
    setAuthStatus([]);
    setUserListError("");

    // if users array is empty
    if (users.length === 0) {
      setUserListError("Add at least one email.");
      return;
    }

    // start api loader
    setApiLoading(true);

    let t_data: Array<object> = [];

    await Promise.all(
      users.map(async (item: string) => {
        try {
          // generate random password
          const password = helpers.getRandomPassword();

          // sign up new user with random password
          await Auth.signUp(item, password);

          const temp = {
            email: item,
            status: "done",
          };

          t_data.push(temp);
        } catch (error: any) {
          const temp = {
            email: item,
            status: error.toString(),
          };

          t_data.push(temp);
        }
      })
    );

    // stop api loader
    setApiLoading(false);

    setAuthStatus([...t_data]);
  };

  const handleClearInvite = () => {
    // clear users list
    setUsers([]);

    // clear auth status messages
    setAuthStatus([]);

    // clear email list error message
    setUserListError("");
  };

  return (
    <div>
      <p>Let's add some team members.</p>
      <TagsInput
        tags={users}
        setTags={setUsers}
        placeholder="Email here..."
        error={userListError}
      />

      {!apiLoading && (
        <Button
          onClick={handleSendInvite}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none" }}
        >
          {/* <InsertInvitationIcon /> */}
          Invite
        </Button>
      )}
      {!apiLoading && (
        <Button
          onClick={handleClearInvite}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none", mx: 1 }}
          color="error"
        >
          {/* <InsertInvitationIcon /> */}
          Clear
        </Button>
      )}
      {apiLoading ? (
        <Box sx={{ mt: 2 }}>
          <A4ALoader />
        </Box>
      ) : (
        ""
      )}
      <br />
      <br />
      <hr />
      {!apiLoading && (
        <ul>
          {authStatus.map((item: any, idx: number) => (
            <li key={idx}>
              {item?.email} - {item?.status}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { InviteUsers };
