import { useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

import { TextFieldInput } from "../Common/TextFieldInput";
import { AuthSchema } from "../../validations/schema";
import { helpers, constants } from "../../utils";
import { A4ALoader } from "../Common/A4ALoader";
import { PasswordFieldInput } from "../Common/PasswordFieldInput";

import {
  useNavigate,
  Link,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuth } from "../AuthProvider";

// default state
const signInDefaultState = {
  email: "",
  password: "",
};

const SignIn = () => {
  // state
  const [formData, setFormData] = useState<any>({ ...signInDefaultState });
  const [error, setError] = useState<any>({ ...signInDefaultState });
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string>("");

  // react router dom navigate
  const navigateTo = useNavigate();
  const { state: auth } = useAuth();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  // componentDidMount
  useEffect(() => {
    document.title = constants.PAGE_TITLE.SIGN_IN;
    // componentWillUnmount
    return () => {};
  }, []);

  useEffect(() => {
    if (auth?.isSignedIn) {
      let profileParams = "";

      if (
        searchParams.get("show") !== null &&
        searchParams.get("tab") != null
      ) {
        profileParams = `show=${searchParams.get(
          "show"
        )}&tab=${searchParams.get("tab")}`;
      }

      if (auth?.user?.isPasswordReset === "no") {
        if (searchParams.get("redirect") !== null) {
          navigateTo(
            `/auth/password/?redirect=${searchParams.get(
              "redirect"
            )}${profileParams !== "" ? `&${profileParams}` : ""}`
          );
        } else {
          navigateTo(`/auth/password/?${profileParams}`);
        }
      } else {
        // console.log(searchParams.get("redirect"));
        if (searchParams.get("redirect") !== null) {
          navigateTo(`/${searchParams.get("redirect")}${profileParams !== "" ? `&${profileParams}` : ""}`);
        } else {
          if (state !== null) {
            navigateTo(state?.from);
          } else {
            navigateTo(`/main/?${profileParams}`);
          }
        }
      }
    }
  }, [auth?.isSignedIn]);

  useEffect(() => {
    // if auth checking
    if (auth?.signInStatus === constants?.SIGN_IN_STATUS?.CHECKING) {
      // start page loader
      helpers.startPageLoader();
    } else {
      // stop page loader
      helpers.stopPageLoader();
    }
  }, [auth?.signInStatus]);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({
      ...prev,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setError({ ...signInDefaultState });

    let result: any = {};

    result = AuthSchema.signInValidationSchema[key].validate(value) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    var isValidationError = false;

    // clear send to email address
    setAuthError("");

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      const isValid = handleValidation(key, formData[key]);

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    if (!isValidationError) {
      // start api loader
      setApiLoading(true);

      try {
        // sign-in user
        await Auth.signIn(formData.email, formData.password);
      } catch (error: any) {
        // set auth errors
        // setAuthError(error.toString());
        const [type, err] = error.toString().split(":");

        if (type === "NotAuthorizedException") {
          setAuthError(err);
        } else {
          setAuthError(
            "Sorry, your email address is not registered with us. To request access, please email your Fuel Consortia Chair/GM or A4A Energy Council representative. Thank you."
          );
        }
      } finally {
        // clear form data
        setFormData({ ...signInDefaultState });
        setError({ ...signInDefaultState });

        // stop api loader
        setApiLoading(false);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        elevation={0}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LoginIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign-in
        </Typography>
        <Box
          // component="form"
          // onSubmit={handleSubmit}
          // noValidate
          sx={{ mt: 1 }}
          elevation={0}
        >
          {authError !== "" && <Alert severity="error">{authError}</Alert>}
          <form onSubmit={handleSubmit}>
            <TextFieldInput
              id="email"
              name="email"
              placeholder="Email Address"
              label="Email Address"
              error={error.email}
              value={formData.email}
              autoComplete="email"
              margin="normal"
              handleChange={handleChange}
              onBlur={() => handleValidation("email", formData.email)}
              // autoFocus
              fullWidth
              required
            />
            <PasswordFieldInput
              id="password"
              name="password"
              placeholder="Password"
              label="Password"
              error={error.password}
              value={formData.password}
              handleChange={handleChange}
              onBlur={() => handleValidation("password", formData.password)}
              fullWidth
              required
            />
            {apiLoading && <A4ALoader />}
            {!apiLoading && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, textTransform: "none" }}
                onClick={handleSubmit}
                disabled={apiLoading}
              >
                Sign In
              </Button>
            )}
            <Grid container>
              <Grid item>
                <Link to="/auth/reset">{"Forgot password?"}</Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export { SignIn };
