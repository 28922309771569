import React, { useState, useEffect, FC } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useAuth, authContextAction } from "./../AuthProvider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { constants } from "./../../utils";
import EditIcon from "@mui/icons-material/Edit";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { AuthSchema, UserSchema } from "../../validations/schema";
import { helpers } from "../../utils";
import { A4ALoader } from "../Common/A4ALoader";

import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { PasswordFieldInput } from "../Common/PasswordFieldInput";
import { TextFieldInput } from "./../Common/TextFieldInput";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import { UserService } from "./../../services/UserService";
import Switch from "@mui/material/Switch";
import { IAuthState } from "../AuthProvider/interface";
import { useSearchParams } from "react-router-dom";

interface IUserProfile {
  selectedTab: any;
}

const GeneralTab = () => {
  const { state: authProviderState } = useAuth();
  const adminRoleList: any = authProviderState?.user?.orgRole?.admin?.map(
    (item: any, idx: number) => `* ${item?.organization?.name} as Admin`
  );
  const memberRoleList: any = authProviderState?.user?.orgRole?.member?.map(
    (item: any, idx: number) => `* ${item?.organization?.name} as Member`
  );
  const supportAdminRoleList: any =
    authProviderState?.user?.orgRole?.supportAdmin?.map(
      (item: any, idx: number) =>
        `* ${item?.organization?.name} as Support Admin`
    );

  let finalList: any = [
    ...adminRoleList,
    ...memberRoleList,
    ...supportAdminRoleList,
  ];

  // alpha sort Roles
  finalList.sort((itemA: any, itemB: any) => {
    if (itemA < itemB) {
      return -1;
    }
    if (itemA > itemB) {
      return 1;
    }
    return 0;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "2px solid grey",
        py: 3,
      }}
      component="fieldset"
    >
      <legend>Membership</legend>
      {finalList.map((item: any, idx: number) => (
        <Box key={`org-roles-${idx}`}>
          <p style={{ margin: 0 }}>{item}</p>
        </Box>
      ))}
    </Box>
  );
};

// default state
const changePasswordDefaultState = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const PasswordTab = () => {
  // state
  const [formData, setFormData] = useState<any>({
    ...changePasswordDefaultState,
  });
  const [error, setError] = useState<any>({ ...changePasswordDefaultState });
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  // auth context api
  const { state: authProviderState } = useAuth();

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setError({ ...changePasswordDefaultState });

    let result: any = {};

    result = AuthSchema.updatePasswordValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    var isValidationError = false;

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      const isValid = handleValidation(key, formData[key]);

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    // if old password is same as new password
    if (formData.oldPassword === formData.newPassword) {
      // error message
      setError((prev: any) => ({
        ...prev,
        newPassword: "New password cannot be same as old password.",
      }));

      // set error flag
      isValidationError = true;
    }

    if (formData.newPassword !== formData.confirmNewPassword) {
      // error message
      setError((prev: any) => ({
        ...prev,
        confirmNewPassword: "Password don't match.",
      }));

      // set error flag
      isValidationError = true;
    }

    if (!isValidationError) {
      // start api loader
      setApiLoading(true);

      try {
        // get current authenticated user
        const user = await Auth.currentAuthenticatedUser();

        // send verification code to email
        await Auth.changePassword(
          user,
          formData.oldPassword,
          formData.newPassword
        );

        // update change password flag it is false
        if (authProviderState?.user?.isPasswordReset === "no") {
          // update custom attribute flag
          await Auth.updateUserAttributes(user, {
            "custom:isPasswordReset": "yes",
          });
        }

        // show toast message
        toast.success("Password changed.", {
          theme: "colored",
        });
      } catch (error: any) {
        const [type, err] = error.toString().split(":");

        // show toast error message
        toast.error(err, {
          theme: "colored",
        });
      } finally {
        // clear form data
        setFormData({ ...changePasswordDefaultState });
        setError({ ...changePasswordDefaultState });

        // stop api loader
        setApiLoading(false);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        elevation={0}
        component={Paper}
      >
        <Box
          // component="form"
          // onSubmit={handleSubmit}
          // noValidate
          sx={{ mt: 1 }}
          elevation={0}
          component={Paper}
        >
          <ul>
            {constants?.PasswordPolicyText?.map(
              (policy: string, idx: number) => (
                <li key={`password-policy-${idx}`}>{policy}</li>
              )
            )}
          </ul>
          <form onSubmit={handleSubmit}>
            <PasswordFieldInput
              id="oldPassword"
              name="oldPassword"
              placeholder="Old Password"
              label="Old Password"
              error={error.oldPassword}
              value={formData.oldPassword}
              handleChange={handleChange}
              onBlur={() =>
                handleValidation("oldPassword", formData.oldPassword)
              }
              fullWidth
              required
            />
            <PasswordFieldInput
              id="newPassword"
              name="newPassword"
              placeholder="New Password"
              label="New Password"
              error={error.newPassword}
              value={formData.newPassword}
              handleChange={handleChange}
              onBlur={() =>
                handleValidation("newPassword", formData.newPassword)
              }
              fullWidth
              required
            />
            <PasswordFieldInput
              id="confirmNewPassword"
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              label="Confirm New Password"
              error={error.confirmNewPassword}
              value={formData.confirmNewPassword}
              handleChange={handleChange}
              onBlur={() =>
                handleValidation(
                  "confirmNewPassword",
                  formData.confirmNewPassword
                )
              }
              fullWidth
              required
            />
            {apiLoading && <A4ALoader />}
            {!apiLoading && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  textTransform: "none",
                  width: "125px",
                  float: "right",
                }}
                onClick={handleSubmit}
                disabled={apiLoading}
              >
                Update
              </Button>
            )}
          </form>
        </Box>
      </Box>
    </Container>
  );
};

interface INotificationsTab {
  authProviderState: IAuthState;
  authProviderDispatch: React.Dispatch<any>;
}

const NotificationsTab = ({
  authProviderState: { user },
  authProviderDispatch,
}: INotificationsTab) => {
  const apiUser = new UserService();
  const [isEditingNotificationSettings, setIsEditingNotificationSettings] =
    useState<boolean>(false);
  const [notificationsToggleState, setNotificationsToggleState] = useState({
    // weeklyDigestNotification: user?.dbUser.weeklyDigestNotification,
    // monthlyDigestNotification: user?.dbUser.monthlyDigestNotification,
    // annualDigestNotification: user?.dbUser.annualDigestNotification,
    // membershipAlertsNotification: user?.dbUser.membershipAlertsNotification,
    // generalEmailNotification: user?.dbUser.generalEmailNotification,
    quarterlyEmailNotification: user?.dbUser.quarterlyEmailNotification,
  });
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const resetToggles = () => {
    setNotificationsToggleState({
      // weeklyDigestNotification: user?.dbUser.weeklyDigestNotification,
      // monthlyDigestNotification: user?.dbUser.monthlyDigestNotification,
      // annualDigestNotification: user?.dbUser.annualDigestNotification,
      // membershipAlertsNotification: user?.dbUser.membershipAlertsNotification,
      // generalEmailNotification: user?.dbUser.generalEmailNotification,
      quarterlyEmailNotification: user?.dbUser.quarterlyEmailNotification,
    });
    setIsEditingNotificationSettings(false);
  };

  const saveHandler = async () => {
    try {
      await apiUser.updateUserById({
        id: user?.dbUser?.id,
        ...notificationsToggleState,
      });
      setIsEditingNotificationSettings(false);
      authProviderDispatch({
        type: authContextAction?.UPDATE_USER,
        payload: { dbUser: { ...user?.dbUser, ...notificationsToggleState } },
      });
      toast.success("Notification settings updated.", {
        theme: "colored",
      });
    } catch (error) {
      console.error(error);
      toast.error("Error occured while updating notification settings.", {
        theme: "colored",
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "2px solid grey",
        py: 3,
      }}
      component="fieldset"
    >
      <legend>Notifications</legend>
      <Grid container>
        <Grid sm={12} item>
          <Button
            size="small"
            variant="outlined"
            sx={{ float: "right", mb: 2 }}
            onClick={() => setIsEditingNotificationSettings(true)}
          >
            {isEditingNotificationSettings ? (
              <SaveIcon
                titleAccess="Save contact info."
                onClick={saveHandler}
              />
            ) : (
              <EditIcon titleAccess="Edit contact info." />
            )}
          </Button>
          {isEditingNotificationSettings && (
            <Button
              size="small"
              variant="outlined"
              sx={{ float: "right", mb: 2, mx: 1 }}
              onClick={resetToggles}
            >
              <ClearIcon titleAccess="Clear form." />
            </Button>
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Receive quarterly email notifications.
          </Typography>
          <Switch
            {...label}
            disabled={!isEditingNotificationSettings}
            checked={notificationsToggleState.quarterlyEmailNotification}
            onChange={(e) =>
              setNotificationsToggleState((prevState) => ({
                ...prevState,
                quarterlyEmailNotification: e.target.checked,
              }))
            }
          />
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Receive a weekly digest.
          </Typography>
          <Switch
            {...label}
            disabled={!isEditingNotificationSettings}
            checked={notificationsToggleState.weeklyDigestNotification}
            onChange={(e) =>
              setNotificationsToggleState((prevState) => ({
                ...prevState,
                weeklyDigestNotification: e.target.checked,
              }))
            }
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Receive a monthly digest.
          </Typography>
          <Switch
            {...label}
            disabled={!isEditingNotificationSettings}
            checked={notificationsToggleState.monthlyDigestNotification}
            onChange={(e) =>
              setNotificationsToggleState((prevState) => ({
                ...prevState,
                monthlyDigestNotification: e.target.checked,
              }))
            }
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Receive a annual report.
          </Typography>
          <Switch
            {...label}
            disabled={!isEditingNotificationSettings}
            checked={notificationsToggleState.annualDigestNotification}
            onChange={(e) =>
              setNotificationsToggleState((prevState) => ({
                ...prevState,
                annualDigestNotification: e.target.checked,
              }))
            }
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Receive a membership alerts.
          </Typography>
          <Switch
            {...label}
            disabled={!isEditingNotificationSettings}
            checked={notificationsToggleState.membershipAlertsNotification}
            onChange={(e) =>
              setNotificationsToggleState((prevState) => ({
                ...prevState,
                membershipAlertsNotification: e.target.checked,
              }))
            }
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Receive general emails.
          </Typography>
          <Switch
            {...label}
            disabled={!isEditingNotificationSettings}
            checked={notificationsToggleState.generalEmailNotification}
            onChange={(e) =>
              setNotificationsToggleState((prevState) => ({
                ...prevState,
                generalEmailNotification: e.target.checked,
              }))
            }
          />
        </Box> */}
      </Box>
    </Box>
  );
};
// default state
const contactFormDefaultState = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
};

const UserProfile: FC<IUserProfile> = ({ selectedTab }) => {
  const [selectedBtnGroup, setSelectedBtnGroup] = useState<string>("General");
  const { state: authProviderState, dispatch: authProviderDispatch } =
    useAuth();
  const [isEditingContactInfo, setEditingContactInfo] =
    useState<boolean>(false);

  const [contactFormData, setContactFormData] = useState<any>({
    ...contactFormDefaultState,
  });
  const [contactFormError, setContactFormError] = useState<any>({
    ...contactFormDefaultState,
  });
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const apiUser = new UserService();

  // componentDidMount
  useEffect(() => {
    const formData = {
      firstName: authProviderState?.user?.dbUser?.firstName,
      lastName: authProviderState?.user?.dbUser?.lastName,
      email: authProviderState?.user?.dbUser?.email,
      mobile:
        authProviderState?.user?.dbUser?.mobile !== null
          ? authProviderState?.user?.dbUser?.mobile
          : "",
    };

    // set contact form data
    setContactFormData({ ...formData });

    // open notification tab props value
    if (selectedTab === "notification") {
      setSelectedBtnGroup("Notifications");
    }

    // cleanup
    return () => {};
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setContactFormData((prev: any) => ({ ...prev, [name]: value }));

    if (name !== "mobile") {
      // validate form data
      handleValidation(name, value);
    }
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setContactFormError({ ...contactFormDefaultState });

    let result: any = {};

    result = UserSchema.updateContactInfoValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setContactFormError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setContactFormError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  const handleClearContactForm = () => {
    const formData = {
      firstName: authProviderState?.user?.dbUser?.firstName,
      lastName: authProviderState?.user?.dbUser?.lastName,
      email: authProviderState?.user?.dbUser?.email,
      mobile:
        authProviderState?.user?.dbUser?.mobile !== null
          ? authProviderState?.user?.dbUser?.mobile
          : "",
    };

    // set contact form data
    setContactFormData({ ...formData });
    setContactFormError({ ...contactFormDefaultState });

    setEditingContactInfo(false);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // clear form error messages
    setContactFormError({ ...contactFormDefaultState });

    var isValidationError = false;

    // check validation error
    Object.keys(contactFormData).forEach((key: any) => {
      if (key !== "mobile") {
        const isValid = handleValidation(key, contactFormData[key]);

        if (!isValidationError) {
          isValidationError = !isValid;
        }
      } else if (key === "mobile" && contactFormData[key] !== "") {
        const isValid = handleValidation(key, contactFormData[key]);

        if (!isValidationError) {
          isValidationError = !isValid;
        }
      }
    });

    if (!isValidationError) {
      // start api loader
      setApiLoading(true);

      const apiParams = {
        id: authProviderState?.user?.dbUserId,
        firstName: contactFormData?.firstName,
        lastName: contactFormData?.lastName,
        mobile: contactFormData?.mobile,
      };

      // update user contact info api call
      const response = await apiUser.updateUserById(apiParams);

      if (response !== null) {
        // show toast message
        toast.success("Contact info updated.", {
          theme: "colored",
        });

        // update Auth Provider state
        authProviderDispatch({
          type: authContextAction?.UPDATE_USER,
          payload: { dbUser: response },
        });
      } else {
        // show toast message
        toast.error("Contact info updated failed.", {
          theme: "colored",
        });

        const formData = {
          firstName: authProviderState?.user?.dbUser?.firstName,
          lastName: authProviderState?.user?.dbUser?.lastName,
          email: authProviderState?.user?.dbUser?.email,
          mobile:
            authProviderState?.user?.dbUser?.mobile !== null
              ? authProviderState?.user?.dbUser?.mobile
              : "",
        };

        // set contact form data
        setContactFormData({ ...formData });
      }

      // stop api loader
      setApiLoading(false);

      // clear form data
      setContactFormError({ ...contactFormDefaultState });

      setEditingContactInfo(false);
    }
  };

  return (
    <>
      <h3>
        Profile{" "}
        {authProviderState?.user?.groups?.includes(
          constants?.ROLES?.A4A_ADMIN
        ) && (
          <GppGoodIcon
            color="success"
            titleAccess="A4A Admin"
            sx={{ verticalAlign: "text-bottom" }}
          />
        )}
      </h3>

      <Box
        sx={{ display: "flex", flexDirection: "row", border: "2px solid grey" }}
        component="fieldset"
      >
        <legend>Contact</legend>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AccountCircleIcon
            color="primary"
            sx={{ fontSize: "100px", mx: "auto", mt: 3 }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
          <Grid container>
            <Grid sm={12} item>
              <Button
                size="small"
                variant="outlined"
                sx={{ float: "right", mb: 2 }}
                onClick={() => setEditingContactInfo(true)}
              >
                {isEditingContactInfo ? (
                  <SaveIcon
                    titleAccess="Save contact info."
                    onClick={handleSubmit}
                  />
                ) : (
                  <EditIcon titleAccess="Edit contact info." />
                )}
              </Button>
              {isEditingContactInfo && (
                <Button
                  size="small"
                  variant="outlined"
                  sx={{ float: "right", mb: 2, mx: 1 }}
                  onClick={handleClearContactForm}
                >
                  <ClearIcon titleAccess="Clear form." />
                </Button>
              )}
            </Grid>
            <Grid md={6} item>
              <TextFieldInput
                id="firstName"
                name="firstName"
                placeholder="First Name"
                label="First Name"
                error={contactFormError.firstName}
                value={contactFormData.firstName}
                autoComplete="firstName"
                handleChange={handleChange}
                onBlur={() =>
                  handleValidation("firstName", contactFormData.firstName)
                }
                fullWidth
                required
                disabled={!isEditingContactInfo}
              />
            </Grid>
            <Grid md={6} item>
              <TextFieldInput
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                label="Last Name"
                error={contactFormError.lastName}
                value={contactFormData.lastName}
                autoComplete="lastName"
                handleChange={handleChange}
                onBlur={() =>
                  handleValidation("lastName", contactFormData.lastName)
                }
                fullWidth
                required
                disabled={!isEditingContactInfo}
              />
            </Grid>
            <Grid md={12} item>
              <TextFieldInput
                id="email"
                name="email"
                placeholder="Email"
                label="Email"
                error={contactFormError.email}
                value={contactFormData.email}
                autoComplete="email"
                handleChange={handleChange}
                onBlur={() => handleValidation("email", contactFormData.email)}
                fullWidth
                required
                disabled={true}
              />
            </Grid>
            {authProviderState?.user?.dbUser?.company !== null && (
              <Grid md={12} item>
                <TextFieldInput
                  id="companyName"
                  name="companyName"
                  placeholder="Company Name"
                  label="Company Name"
                  error=""
                  value={authProviderState?.user?.dbUser?.company?.name}
                  handleChange={() => {}}
                  fullWidth
                  disabled={true}
                />
              </Grid>
            )}
            <Grid md={12} item>
              <TextFieldInput
                id="mobile"
                name="mobile"
                placeholder="Mobile"
                label="Mobile"
                error={contactFormError.mobile}
                value={contactFormData.mobile}
                autoComplete="mobile"
                handleChange={handleChange}
                // onBlur={() =>
                //   handleValidation("mobile", contactFormData.mobile)
                // }
                fullWidth
                disabled={!isEditingContactInfo}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <ButtonGroup
          variant="outlined"
          color="primary"
          aria-label="outlined primary button group"
          disableElevation
        >
          <Button
            sx={{
              borderTopLeftRadius: 50,
              borderBottomLeftRadius: 50,
              textTransform: "none",
            }}
            variant={selectedBtnGroup === "General" ? "contained" : "outlined"}
            onClick={() => setSelectedBtnGroup("General")}
          >
            General
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            variant={selectedBtnGroup === "Password" ? "contained" : "outlined"}
            onClick={() => setSelectedBtnGroup("Password")}
          >
            Password
          </Button>
          <Button
            sx={{
              borderTopRightRadius: 50,
              borderBottomRightRadius: 50,
              textTransform: "none",
            }}
            variant={
              selectedBtnGroup === "Notifications" ? "contained" : "outlined"
            }
            onClick={() => setSelectedBtnGroup("Notifications")}
          >
            Notifications
          </Button>
        </ButtonGroup>
      </Box>
      <div style={{ width: "100%", marginTop: "30px" }}>
        {selectedBtnGroup === "General" && <GeneralTab />}
        {selectedBtnGroup === "Password" && <PasswordTab />}
        {selectedBtnGroup === "Notifications" && (
          <NotificationsTab
            authProviderState={authProviderState}
            authProviderDispatch={authProviderDispatch}
          />
        )}
      </div>
    </>
  );
};

export { UserProfile };
