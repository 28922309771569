import React from "react";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  InputAdornment,
} from "@mui/material";
import InfoWithPopup from "../InfoIconWithPopup";

type IDrawerSelectInput = {
  name: string;
  placeholder: string;
  options: Array<{ name: string; value: number | string | any }>;
  selectedValue: string | number | boolean | string[];
  selectHandler: (event: SelectChangeEvent<any>) => void;
  sx?: SxProps;
  isMultiple?: boolean;
  disabled?: boolean;
  infoText?: string;
};

const DrawerSelectInput = ({
  name,
  placeholder,
  selectedValue,
  selectHandler,
  options,
  sx,
  isMultiple = false,
  disabled = false,
  infoText,
}: IDrawerSelectInput) => {
  return (
    <Select
      variant="standard"
      sx={sx}
      value={selectedValue}
      onChange={selectHandler}
      fullWidth
      placeholder={placeholder}
      name={name}
      multiple={isMultiple}
      disabled={disabled}
      // endAdornment={
      //   !!infoText && (
      //     <InputAdornment position="end" sx={{ mr: "12.5px" }}>
      //       <InfoWithPopup infoText={infoText} />
      //     </InputAdornment>
      //   )
      // }
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DrawerSelectInput;
