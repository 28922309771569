import joi from "joi";

// update user contact info
// validation schema
const updateContactInfoValidationSchema: any = {
  email: joi
    .string()
    .trim()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "Enter a valid email.",
      "string.empty": "Email cannot be empty.",
    }),
  firstName: joi.string().trim().messages({
    "string.empty": "Cannot be empty.",
    "any.required": "First name required.",
  }),
  lastName: joi.string().trim().messages({
    "string.empty": "Cannot be empty.",
    "any.required": "Last name required.",
  }),
  mobile: joi
    .string()
    .regex(/^[0-9]{10}$/)
    .trim()
    .messages({
      "string.empty": "Cannot be empty.",
      "string.pattern.base": "Mobile number must have 10 digits.",
    }),
};

export { updateContactInfoValidationSchema };
