import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  MenuItem,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import {  Link } from "react-router-dom";

export const OrganizationHeading = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

export const OrganizationTabButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    marginRight: "10px",
  },
}));

export const OrganizationTabButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "10px",
    borderRadius: "0px !important",
    borderRightColor: "-moz-initial",
  },
}));

export const OrganizationNewButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  borderRadius: "16px",
  [theme.breakpoints.down("md")]: {
    borderRadius: "0px",
  },
}));

export const OrganizationCodeNameLink = styled(Link)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: '12px'
  },
}));

export const OrganizationCellText = styled('p')(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: '12px'
  },
}));
