import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";

class OrgAdminUserSubscription {
  /*
    Task: Get OrgAdminUser subscription on create from amplify graphql
  */
  static getOnCreate() {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateOrgAdminUser)
    ) as any;
  }

  /*
    Task: Get OrgAdminUser subscription on update from amplify graphql
  */
  static getOnUpdate() {
    return API.graphql(
      graphqlOperation(subscriptions.onUpdateOrgAdminUser)
    ) as any;
  }

  /*
    Task: Get OrgAdminUser subscription on delete from amplify graphql
  */
  static getOnDelete() {
    return API.graphql(
      graphqlOperation(subscriptions.onDeleteOrgAdminUser)
    ) as any;
  }
}

export { OrgAdminUserSubscription };
