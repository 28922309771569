import { Box, Typography, styled } from "@mui/material";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export const FileUploadContainer = styled(Box)(() => ({
  width: "100%",
  marginTop: "20px",
}));

export const FileUploadContainerHeading = styled(Typography)(() => ({
  fontSize: "20px",
  fontWeight: "bold",
}));

export const FileUploadContainerUploadBox = styled(Box)(() => ({
  width: "100%",
  marginTop: "10px",
  height: "100px",
  backgroundColor: "#77777722",
  border: "1px solid #77777755",
  borderStyle: "dashed",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  flexDirection: "column",
  position: "relative",
  cursor: "pointer",
  padding: "10px",
}));

export const FileUploadContainerUploadText = styled(Typography)(() => ({
  fontSize: "15px",
  color: "#777",
}));

export const FileUploadContainerUploadedFileName = styled(Typography)(() => ({
  fontSize: "16px",
  color: "#aaa",
  overflow: "hidden",
  maxWidth: "100%",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  // marginTop: "10px",
}));

export const FileUploadContainerUploadIcon = styled(CloudUploadIcon)(() => ({
  fontSize: "25px",
  color: "#777777cc",
  // marginTop: "10px",
}));

export const FileUploadContainerBtnContainer = styled(Box)(() => ({
  marginTop: "15px",
  display: "flex",
  alignItems: "center",
  alignSelf: "flex-end",
  columnGap: "20px",
  justifyContent: "flex-end",
}));

export const FileUploadContainerBtnText = styled(Typography)(() => ({
  fontSize: "20px",
  color: "#00CED1",
  cursor: "pointer",
  fontWeight: "bold",
}));
