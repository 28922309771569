import React, { MouseEventHandler } from "react";
import {
  CalendarEventCardContainer,
  CalendarEventCardContent,
  CalendarEventCardTitle,
  CalendarEventCardIcon,
  CalendarEventCardText,
  CalendarEventDeleteIcon,
} from "./style";
import { Box, Button, Grid } from "@mui/material";

interface ICalendarEventCard {
  icon: string;
  title: string;
  text: string;
  isDeleting: boolean;
  clickHandler: MouseEventHandler<HTMLDivElement>;
  deleteHandler: Function;
  viewMode?: "list" | "grid";
}

const CalendarEventCard = ({
  icon,
  title,
  text,
  isDeleting,
  clickHandler,
  deleteHandler,
  viewMode = "grid",
}: ICalendarEventCard) => {
  return (
    <Grid
      item
      lg={viewMode === "grid" ? 4 : 12}
      xl={viewMode === "grid" ? 3 : 12}
    >
      <CalendarEventCardContainer
        onClick={isDeleting ? undefined : clickHandler}
      >
        <Box display="flex" gap={1.5}>
          <CalendarEventCardIcon>{icon}</CalendarEventCardIcon>
          <CalendarEventCardContent>
            <CalendarEventCardTitle>{title}</CalendarEventCardTitle>
            <CalendarEventCardText>{text}</CalendarEventCardText>
          </CalendarEventCardContent>
          {viewMode === "list" && (
            <CalendarEventDeleteIcon
              className="event-delete-icon"
              onClick={(e) => {
                e.stopPropagation();
                if (!isDeleting) deleteHandler();
              }}
            />
          )}
        </Box>
        {viewMode === "grid" && (
          <CalendarEventDeleteIcon
            className="event-delete-icon"
            onClick={(e) => {
              e.stopPropagation();
              if (!isDeleting) deleteHandler();
            }}
          />
        )}
      </CalendarEventCardContainer>
    </Grid>
  );
};

export default CalendarEventCard;
