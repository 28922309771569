import { useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { constants } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import CircularProgress from "@mui/material/CircularProgress";

const AuthVerify = () => {
  const { state } = useLocation();
  const navigateTo = useNavigate();
  const { state: authProviderState } = useAuth();

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.AUTH_VERIFY;

    // componentWillUnmount
    return () => {};
  }, []);

  useEffect(() => {
    if (
      authProviderState.signInStatus === constants.SIGN_IN_STATUS.SIGNED_OUT
    ) {
      if (state !== null) {
        navigateTo("/auth/in", { state: state });
      } else {
        navigateTo("/auth/in");
      }
    }

    // if user signed-in
    if (authProviderState.signInStatus === constants.SIGN_IN_STATUS.SIGNED_IN) {
      if (state !== null) {
        navigateTo(state?.from);
      } else {
        navigateTo("/main");
      }
    }
  }, [authProviderState?.signInStatus]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        elevation={0}
      >
        <Box sx={{ mb: 5, textAlign: "center" }} elevation={0}>
          {/* <img
            src={`${process.env.REACT_APP_BASE_URL}/assets/img/afp-img-loader.gif`}
            alt="A4A Header Logo"
            width="50%"
          /> */}
          <CircularProgress />
        </Box>

        <Typography component="h1" variant="h5">
          Verifying sign-in process...
        </Typography>
      </Box>
    </Container>
  );
};

export { AuthVerify };
