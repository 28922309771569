import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";
import { ICreateAirportConsortiumGroupParams } from "./types";
import {
  CreateAirportConsortiumGroupMutation,
  ListAirportConsortiumGroupsQuery,
  GetAirportConsortiumGroupQuery,
  DeleteAirportConsortiumGroupMutation,
  UpdateAirportConsortiumGroupMutation,
} from "../../API";

class AirportConsortiumGroupService {
  /*
    Task: Create a new AirportConsortiumGroup using graphql
  */
  async createAirportConsortiumGroup(
    airportConsortiumGroup: ICreateAirportConsortiumGroupParams
  ) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createAirportConsortiumGroup,
        variables: { input: airportConsortiumGroup },
      })) as { data: CreateAirportConsortiumGroupMutation; errors: any[] };

      // send back newly created AirportConsortiumGroup details
      return temp.data.createAirportConsortiumGroup;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: get AirportConsortiumGroup by id using graphql
  */
  async getAirportConsortiumGroupById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        // query: customQueries.customGetAirportConsortiumGroup,
        query: queries.getAirportConsortiumGroup,
        variables: { id },
      })) as { data: GetAirportConsortiumGroupQuery; errors: any[] };

      // send back AirportConsortiumGroup details
      return temp.data.getAirportConsortiumGroup;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: delete AirportConsortiumGroup by id using graphql
  */
  async deleteAirportConsortiumGroupById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteAirportConsortiumGroup,
        variables: { input: { id } },
      })) as { data: DeleteAirportConsortiumGroupMutation; errors: any[] };

      // send back AirportConsortiumGroup details
      return temp.data.deleteAirportConsortiumGroup;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: update AirportConsortiumGroup by id using graphql
  */
  async updateAirportConsortiumGroupById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateAirportConsortiumGroup,
        variables: { input },
      })) as { data: UpdateAirportConsortiumGroupMutation; errors: any[] };

      // send back technology details
      return temp.data.updateAirportConsortiumGroup;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: list all AirportConsortiumGroup using graphql
  */
  async getAllAirportConsortiumGroups(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listAirportConsortiumGroups
  ) {
    try {
      // send grapql api call
      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListAirportConsortiumGroupsQuery; errors: any[] };

      if (temp.data.listAirportConsortiumGroups?.items) {
        // send back all AirportConsortiumGroup details
        return {
          airportConsortiumGroups: temp.data.listAirportConsortiumGroups?.items,
          nextToken: temp.data.listAirportConsortiumGroups?.nextToken,
        };
      }
      return { airportConsortiumGroups: [], nextToken: null };
    } catch (error) {
      return { airportConsortiumGroups: [], nextToken: null };
    }
  }

  /*
    Task: recursively list all airport consortium group mapping using graphql
  */
  async recursiveFetchAirportConsortiumGroup(
    filter: any = {},
    currentAirportConsortiumGroups: Array<any> = [],
    reqNextToken: string | null = null,
    query_src = queries.listAirportConsortiumGroups
  ): Promise<any> {
    const { airportConsortiumGroups, nextToken } =
      await this.getAllAirportConsortiumGroups(filter, reqNextToken, query_src);

    // merge old and new data
    let allData = [
      ...currentAirportConsortiumGroups,
      ...airportConsortiumGroups,
    ];

    if (nextToken) {
      return await this.recursiveFetchAirportConsortiumGroup(
        filter,
        allData,
        nextToken,
        query_src
      );
    } else {
      if (allData?.length) {
        return allData;
      }

      return [];
    }
  }
}

export { AirportConsortiumGroupService };
