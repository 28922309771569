import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  CardContent,
  Typography,
  ButtonGroup,
  Button,
  Divider,
} from "@mui/material";
import { constants } from "../../utils";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useParams, Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { CompanyService } from "../../services/CompanyService";
import * as SkeletonLoader from "../SkeletonLoader";
import { Sidebar } from "../Common/Sidebar";
import { AddCompanyMember } from "../AddCompanyMember";
import { useAuth } from "./../AuthProvider";
import Swal from "sweetalert2";
import { UserService } from "./../../services/UserService";
import { useMapContext, mapContextAction } from "./../MapProvider/MapProvider";
import { DataTableBase } from "./../Common/DataTableBase";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";

interface ILoading {
  data: boolean;
  submit: boolean;
}

const options = ["Remove"];

const ITEM_HEIGHT = 48;

interface IIsolatedTblMenuItem {
  disabled: boolean;
  handleTblMenuItemClick: any;
  row: any;
}

const IsolatedTblMenuItem: React.FC<IIsolatedTblMenuItem> = ({
  disabled = false,
  handleTblMenuItemClick,
  row,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {options.map((option: string, idx: number) => (
          <MenuItem
            disabled={disabled}
            key={`${option}_${idx}`}
            onClick={(event: any) => {
              handleTblMenuItemClick(option, row?.id);
              // close drop down menu
              setAnchorEl(null);
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const CompanyDetail = () => {
  // state
  const [openAddCompanyMember, setOpenAddCompanyMember] =
    useState<boolean>(false);

  const [item, setItem] = React.useState<any>(null);
  const [tblUserData, setTblUserData] = React.useState<Array<any>>([]);
  const [filteredItem, setFilteredItem] = React.useState<Array<any>>([]);
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<ILoading>({
    data: false,
    submit: false,
  });
  const [selectedBtnGroup, setSelectedBtnGroup] =
    React.useState<string>("Members");

  const { companyId } = useParams();
  const { state: auth } = useAuth();
  const { state: mapProviderState, dispatch } = useMapContext();
  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();
  const apiUser = new UserService();

  // check if signedin user is any fuel consortium or energy council admin
  const isUserFCorECOrgAdmin =
    auth?.user?.orgRole?.admin?.filter(
      (item) =>
        item?.organization?.organizationType ===
          constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM ||
        item?.organization?.organizationType ===
          constants?.OrganizationTypeChoice?.ENERGY_COUNCIL
    ).length !== 0;

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.COMPANY_DETAIL;

    // clear global search bar text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
      payload: "",
    });

    // set global search bar placeholder text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT,
      payload: "Search company...",
    });

    //IIFE
    (async () => {
      // state loader
      setLoadingState((prev) => ({ ...prev, data: true }));

      await getCompanyById();

      // stop loader
      setLoadingState((prev) => ({ ...prev, data: false }));
    })();

    // cleanup
    return () => {};
  }, []);

  // set filtered items
  useEffect(() => {
    if (item !== null) {
      // table items
      // remove deleted member from list
      let tempItems = item?.members?.items?.filter(
        (member: any) => member?.deleted !== true
      );

      tempItems =
        tempItems?.map((member: any) => ({
          id: member.id,
          name: member.firstName + " " + member.lastName,
          email: member.email,
          contact: member.mobile,
        })) ?? [];

      // set filtered items
      setFilteredItem([...tempItems]);
      setTblUserData([...tempItems]);
    }
  }, [item, setItem]);

  useEffect(() => {
    let timeoutId: any = null;

    let searchText = dataProviderState?.topBarSearchText;

    if (searchText.trim() !== "") {
      timeoutId = setTimeout(() => {
        // filter for user
        const filtered = tblUserData?.filter(
          (item: any) =>
            item?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.contact?.toLowerCase().includes(searchText.toLowerCase())
        );

        // udpate user list
        setFilteredItem([...filtered]);
      }, 1000);
    } else {
      // udpate admin & members list
      setFilteredItem([...tblUserData]);
    }

    return () => clearTimeout(timeoutId);
  }, [dataProviderState?.topBarSearchText]);

  const handleTblMenuItemClick = (option: string, userId: string) => {
    if (option === "Remove") {
      Swal.fire({
        title: "Remove member ?",
        showCancelButton: true,
        confirmButtonText: "Remove",
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          // set user companyId to null
          await apiUser.updateUserById({ id: userId, companyId: null });

          // update state
          setItem((prevItem: any) => ({
            ...prevItem,
            members: {
              ...prevItem.members,
              items: prevItem?.members?.items?.filter(
                (temp: any) => temp?.id !== userId
              ),
            },
          }));

          // update map provider user list
          dispatch({
            type: mapContextAction.SET_USER_LIST,
            payload: mapProviderState.userList.map((user) => {
              if (user.id === userId) {
                return {
                  ...user,
                  companyId: null,
                };
              }
              return user;
            }),
          });
        }
      });
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row: any) => row?.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: any) => row?.email,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row: any) => row?.contact,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row: any) => (
        <IsolatedTblMenuItem
          row={row}
          disabled={
            !auth?.user?.groups.includes(constants?.ROLES?.A4A_ADMIN) &&
            !isUserFCorECOrgAdmin
          }
          handleTblMenuItemClick={handleTblMenuItemClick}
        />
      ),
    },
  ];

  const getCompanyById = async () => {
    const cs = new CompanyService();

    // start api loading
    setApiLoading(true);

    const data = await cs.getCompanyById(companyId as any);

    // if organization don't exist then return
    if (data === null) return;

    // update state
    setItem({ ...data });

    // start api loading
    setApiLoading(false);
  };

  return (
    <>
      {loadingState.data && <SkeletonLoader.CompanyDetail />}
      {!loadingState.data && (
        <Grid sx={{ my: 2 }}>
          <Card
            style={{
              padding: "20px 5px",
              margin: "0 auto",
              borderRadius: "16px",
              background: "rgb(96, 96, 96, 0.05)",
              minHeight: "75vh",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  mb: 3,
                }}
              >
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                  <Link to="/company" style={{ textDecoration: "none" }}>
                    <Typography variant="h6">Contact</Typography>
                  </Link>
                  <Typography color="text.primary" variant="h6">
                    {item?.name}
                  </Typography>
                </Breadcrumbs>
                <Box sx={{ textAlign: "center", mt: 2, mx: "auto" }}>
                  <ButtonGroup
                    variant="outlined"
                    color="primary"
                    size="small"
                    disableElevation
                  >
                    <Button
                      sx={{
                        borderTopLeftRadius: 50,
                        borderBottomLeftRadius: 50,
                        borderTopRightRadius: 50,
                        borderBottomRightRadius: 50,
                        textTransform: "none",
                      }}
                      variant={
                        selectedBtnGroup === "Members"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => setSelectedBtnGroup("Members")}
                    >
                      Members
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                {selectedBtnGroup === "Members" && (
                  <>
                    <Divider sx={{ mb: 2 }} />
                    <IconButton
                      sx={{
                        float: "right",
                        textTransform: "none",
                        borderRadius: "16px",
                        mb: 2,
                      }}
                      onClick={() => setOpenAddCompanyMember(true)}
                      disabled={
                        !auth?.user?.groups?.includes(
                          constants?.ROLES?.A4A_ADMIN
                        )
                      }
                    >
                      <AddCircleOutlineIcon
                        sx={{
                          cursor: "pointer",
                          color: "#1976d2",
                          fontSize: "30px",
                        }}
                      />
                    </IconButton>
                    <DataTableBase
                      columns={columns}
                      data={filteredItem}
                      dense={false}
                      fixedHeader
                      pagination={false}
                      persistTableHead
                      highlightOnHover
                    />
                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
      {openAddCompanyMember && (
        <Sidebar
          handleClose={() => setOpenAddCompanyMember(false)}
          open={openAddCompanyMember}
        >
          <AddCompanyMember
            closeSidebar={() => setOpenAddCompanyMember(false)}
            existingMembers={item?.members?.items ?? []}
            companyId={item?.id ?? ""}
            setCurrentCompany={setItem}
          />
        </Sidebar>
      )}
    </>
  );
};

export { CompanyDetail };
