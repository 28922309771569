/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelAirportFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  oldCode?: ModelStringInput | null,
  facility?: ModelStringInput | null,
  region?: ModelAirportRegionChoiceInput | null,
  state?: ModelStringInput | null,
  consortiumType?: ModelGroupTypeChoiceInput | null,
  chairId?: ModelIDInput | null,
  chairContactId?: ModelIDInput | null,
  operatorId?: ModelIDInput | null,
  operatorGeneralManagerId?: ModelIDInput | null,
  secondaryContactId?: ModelIDInput | null,
  facilityOwnerId?: ModelIDInput | null,
  facilityOwnerContactId?: ModelIDInput | null,
  inventoryAccounting?: ModelInventoryAccountingTypeChoiceInput | null,
  createdAt?: ModelStringInput | null,
  organizationAirportsId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelAirportFilterInput | null > | null,
  or?: Array< ModelAirportFilterInput | null > | null,
  not?: ModelAirportFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelAirportRegionChoiceInput = {
  eq?: AirportRegionChoice | null,
  ne?: AirportRegionChoice | null,
};

export enum AirportRegionChoice {
  PADD_1A_NEW_ENGLAND = "PADD_1A_NEW_ENGLAND",
  PADD_1B_CENTERAL_ATLANTIC = "PADD_1B_CENTERAL_ATLANTIC",
  PADD_1C_LOWER_ATLANTIC = "PADD_1C_LOWER_ATLANTIC",
  PADD_2_MIDWEST = "PADD_2_MIDWEST",
  PADD_3_GULF_COAST = "PADD_3_GULF_COAST",
  PADD_4_ROCKY_MOUNTAIN = "PADD_4_ROCKY_MOUNTAIN",
  PADD_5_ALASKA = "PADD_5_ALASKA",
  PADD_5_HAWAII = "PADD_5_HAWAII",
  PADD_5_WEST_COAST = "PADD_5_WEST_COAST",
  PADD_NA_CARIBBEAN = "PADD_NA_CARIBBEAN",
}


export type ModelGroupTypeChoiceInput = {
  eq?: GroupTypeChoice | null,
  ne?: GroupTypeChoice | null,
};

export enum GroupTypeChoice {
  ADVISORY_COMMITTEE = "ADVISORY_COMMITTEE",
  COMMITTEE = "COMMITTEE",
  CORP_CO_LLC = "CORP_CO_LLC",
}


export type ModelInventoryAccountingTypeChoiceInput = {
  eq?: InventoryAccountingTypeChoice | null,
  ne?: InventoryAccountingTypeChoice | null,
};

export enum InventoryAccountingTypeChoice {
  GROSS = "GROSS",
  NET = "NET",
}


export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelAirportConnection = {
  __typename: "ModelAirportConnection",
  items:  Array<Airport | null >,
  nextToken?: string | null,
};

export type Airport = {
  __typename: "Airport",
  id: string,
  name: string,
  code: string,
  oldCode?: string | null,
  facility?: string | null,
  region?: AirportRegionChoice | null,
  state: string,
  place?: Place | null,
  consortiumGroup?: ModelAirportConsortiumGroupConnection | null,
  consortiumType?: GroupTypeChoice | null,
  documents?: ModelDocumentConnection | null,
  chairId?: string | null,
  chair?: Company | null,
  chairContactId?: string | null,
  chairContact?: User | null,
  operatorId?: string | null,
  operator?: Company | null,
  operatorGeneralManagerId?: string | null,
  operatorGeneralManager?: User | null,
  secondaryContactId?: string | null,
  secondaryContact?: User | null,
  facilityOwnerId?: string | null,
  facilityOwner?: Company | null,
  facilityOwnerContactId?: string | null,
  facilityOwnerContact?: User | null,
  inventoryAccounting?: InventoryAccountingTypeChoice | null,
  deliveryToPlane?: ModelDeliveryToPlaneConnection | null,
  deliveryToFacility?: ModelDeliveryToFacilityConnection | null,
  storage?: ModelStorageConnection | null,
  createdAt?: string | null,
  createdBy?: User | null,
  modifications?:  Array<ModifyRecord | null > | null,
  organizationAirportsId?: string | null,
  deleted?: boolean | null,
  updatedAt: string,
};

export type Place = {
  __typename: "Place",
  terminal?: string | null,
  gate?: string | null,
  lat?: string | null,
  lon?: string | null,
};

export type ModelAirportConsortiumGroupConnection = {
  __typename: "ModelAirportConsortiumGroupConnection",
  items:  Array<AirportConsortiumGroup | null >,
  nextToken?: string | null,
};

export type AirportConsortiumGroup = {
  __typename: "AirportConsortiumGroup",
  id: string,
  organizationId: string,
  airportId: string,
  airport?: Airport | null,
  organization?: Organization | null,
  chairId?: string | null,
  chair?: Company | null,
  chairContactId?: string | null,
  chairContact?: User | null,
  operatorId?: string | null,
  operator?: Company | null,
  operatorGeneralManagerId?: string | null,
  operatorGeneralManager?: User | null,
  secondaryContact?: string | null,
  facilityOwner?: string | null,
  facilityOwnerContact?: string | null,
  legalCounselContact?: string | null,
  legalCounsel?: string | null,
  facilityLocation?: FacilityLocationTypeChoice | null,
  totalCapacityDays?: number | null,
  totalCapacityGallons?: number | null,
  secured?: boolean | null,
  moExpiration?: string | null,
  moType?: MOTypeChoice | null,
  moTypeCustomValue?: string | null,
  leaseExpiration?: string | null,
  leaseType?: LeaseTypeChoice | null,
  leaseTypeCustomValue?: string | null,
  dateLastFinancialSUP?: string | null,
  dateLastA4aOperationalReview?: string | null,
  netInventoryCalculation?: string | null,
  entryFee?: string | null,
  nonMemberRate?: string | null,
  costSharingFormula?: string | null,
  reserveDepositPrebill?: ReservePrebillTypeChoice | null,
  thirdPartyDebt?: number | null,
  memberLoans?: number | null,
  dateLastPropertyAppraisal?: string | null,
  aviationLiabilityLimit?: number | null,
  environmentalLiabilityLimit?: number | null,
  comments?: string | null,
  deleted?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name: string,
  organizationType?: OrganizationTypeChoice | null,
  groupType?: GroupTypeChoice | null,
  chairId?: string | null,
  chair?: Company | null,
  chairContactId?: string | null,
  chairContact?: User | null,
  operatorId?: string | null,
  operator?: Company | null,
  operatorContactId?: string | null,
  operatorContact?: User | null,
  admins?: ModelOrgAdminUserConnection | null,
  airports?: ModelAirportConsortiumGroupConnection | null,
  companies?: ModelCompanyConnection | null,
  members?: ModelOrgMemberUserConnection | null,
  documents?: ModelDocumentConnection | null,
  calendar?: ModelCalendarConnection | null,
  contacts?: ModelUserConnection | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  createdBy?: User | null,
  modifications?:  Array<ModifyRecord | null > | null,
  supportAdmin?: ModelOrgSupportAdminUserConnection | null,
  deleted?: boolean | null,
  updatedAt: string,
  companyOrganizationsId?: string | null,
  userOrganizationId?: string | null,
};

export enum OrganizationTypeChoice {
  A4A_ADMIN = "A4A_ADMIN",
  FUEL_CONSORTIUM = "FUEL_CONSORTIUM",
  ENERGY_COUNCIL = "ENERGY_COUNCIL",
  GENERAL = "GENERAL",
}


export type Company = {
  __typename: "Company",
  id: string,
  name: string,
  type?: CompanyTypeChoice | null,
  organizations?: ModelOrganizationConnection | null,
  members?: ModelUserConnection | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  createdBy?: User | null,
  deleted?: boolean | null,
  modifications?:  Array<ModifyRecord | null > | null,
  updatedAt: string,
  organizationCompaniesId?: string | null,
};

export enum CompanyTypeChoice {
  AIRLINE = "AIRLINE",
  OPERATOR = "OPERATOR",
  ENGINEER = "ENGINEER",
  PIPELINE = "PIPELINE",
  SUPPLIER = "SUPPLIER",
  INSURANCE = "INSURANCE",
  LEGAL = "LEGAL",
  CONSULTANT = "CONSULTANT",
}


export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type User = {
  __typename: "User",
  id: string,
  cognitoUserId?: string | null,
  termsAgree?: boolean | null,
  termsAgreeDate?: string | null,
  participationAgreement?: boolean | null,
  participationAgreementDate?: string | null,
  organization?: ModelOrganizationConnection | null,
  roles?: Array< string | null > | null,
  companyId?: string | null,
  company?: Company | null,
  firstName: string,
  lastName: string,
  email: string,
  office?: string | null,
  mobile?: string | null,
  linkedInUrl?: string | null,
  imageUrl?: string | null,
  timeZone?: string | null,
  monthlyUpdates?: boolean | null,
  generalAnnouncements?: boolean | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  createdBy?: User | null,
  modifications?:  Array<ModifyRecord | null > | null,
  organizationAdmin?: ModelOrgAdminUserConnection | null,
  organizationMember?: ModelOrgMemberUserConnection | null,
  deleted?: boolean | null,
  lastLoginDate?: string | null,
  weeklyDigestNotification?: boolean | null,
  monthlyDigestNotification?: boolean | null,
  quarterlyEmailNotification?: boolean | null,
  annualDigestNotification?: boolean | null,
  membershipAlertsNotification?: boolean | null,
  generalEmailNotification?: boolean | null,
  organizationSupportAdmin?: ModelOrgSupportAdminUserConnection | null,
  updatedAt: string,
  organizationContactsId?: string | null,
};

export type ModifyRecord = {
  __typename: "ModifyRecord",
  fieldName?: string | null,
  fieldValue?: string | null,
  dataOperation?: DataOperation | null,
  modifiedAt?: string | null,
  modifiedBy?: User | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export enum DataOperation {
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}


export type ModelOrgAdminUserConnection = {
  __typename: "ModelOrgAdminUserConnection",
  items:  Array<OrgAdminUser | null >,
  nextToken?: string | null,
};

export type OrgAdminUser = {
  __typename: "OrgAdminUser",
  id: string,
  organizationId: string,
  userId: string,
  organization: Organization,
  user: User,
  createdAt: string,
  updatedAt: string,
};

export type ModelOrgMemberUserConnection = {
  __typename: "ModelOrgMemberUserConnection",
  items:  Array<OrgMemberUser | null >,
  nextToken?: string | null,
};

export type OrgMemberUser = {
  __typename: "OrgMemberUser",
  id: string,
  organizationId: string,
  userId: string,
  organization: Organization,
  user: User,
  createdAt: string,
  updatedAt: string,
};

export type ModelOrgSupportAdminUserConnection = {
  __typename: "ModelOrgSupportAdminUserConnection",
  items:  Array<OrgSupportAdminUser | null >,
  nextToken?: string | null,
};

export type OrgSupportAdminUser = {
  __typename: "OrgSupportAdminUser",
  id: string,
  organizationId: string,
  userId: string,
  organization: Organization,
  user: User,
  createdAt: string,
  updatedAt: string,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type ModelDocumentConnection = {
  __typename: "ModelDocumentConnection",
  items:  Array<Document | null >,
  nextToken?: string | null,
};

export type Document = {
  __typename: "Document",
  id: string,
  organizationId?: string | null,
  organization?: Organization | null,
  airportId?: string | null,
  airport?: Airport | null,
  categoryType?: DocumentCategoryTypeChoice | null,
  documentCategoryId?: string | null,
  documentCategory?: DocumentCategory | null,
  bucketType?: DocumentBucketTypeChoice | null,
  title: string,
  description?: string | null,
  publishedByUserId?: string | null,
  publishedBy?: User | null,
  publishedDate?: string | null,
  url: string,
  fileSize?: number | null,
  fileType?: FileType | null,
  companyId?: string | null,
  createdAt?: string | null,
  createByUserId?: string | null,
  createdBy?: User | null,
  categoryTypeId?: string | null,
  category?: DocumentCategory | null,
  deleted?: boolean | null,
  modifications?:  Array<ModifyRecord | null > | null,
  updatedAt: string,
  organizationDocumentsId?: string | null,
  documentCategoryDocumentsId?: string | null,
  documentFileTypeId?: string | null,
};

export enum DocumentCategoryTypeChoice {
  BUDGET_FINANCE = "BUDGET_FINANCE",
  ANNUAL_MEETING = "ANNUAL_MEETING",
  MO = "MO",
  OTHER = "OTHER",
  INSURANCE = "INSURANCE",
  APPRAISAL = "APPRAISAL",
  AUDIT = "AUDIT",
  LEASE = "LEASE",
  LOGISTICS = "LOGISTICS",
  ENGINEERING = "ENGINEERING",
  FUEL_QUALITY = "FUEL_QUALITY",
  BYLAWS = "BYLAWS",
  FUEL_CONSORTIUM_WORKSHOP = "FUEL_CONSORTIUM_WORKSHOP",
  IATA_ALERTS = "IATA_ALERTS",
  ADMIN = "ADMIN",
  GUIDANCE = "GUIDANCE",
  AUDIT_BUDGET_TAX = "AUDIT_BUDGET_TAX",
  ENVIRONMENTAL = "ENVIRONMENTAL",
  INTERLINE = "INTERLINE",
  INVENTORY = "INVENTORY",
  PORTAL_USER_DOCUMENTS = "PORTAL_USER_DOCUMENTS",
  DATABASE = "DATABASE",
}


export type DocumentCategory = {
  __typename: "DocumentCategory",
  id: string,
  name: string,
  type?: string | null,
  bucketType?: DocumentBucketTypeChoice | null,
  documents?: ModelDocumentConnection | null,
  description?: string | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  createdBy?: User | null,
  deleted?: boolean | null,
  modifications?:  Array<ModifyRecord | null > | null,
  updatedAt: string,
};

export enum DocumentBucketTypeChoice {
  AIRPORT = "AIRPORT",
  GENERAL = "GENERAL",
}


export type FileType = {
  __typename: "FileType",
  id?: string | null,
  name?: string | null,
  description?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelCalendarConnection = {
  __typename: "ModelCalendarConnection",
  items:  Array<Calendar | null >,
  nextToken?: string | null,
};

export type Calendar = {
  __typename: "Calendar",
  id: string,
  organizationId?: string | null,
  organization?: Organization | null,
  calendarEventTypeId?: string | null,
  calendarEventType?: CalendarEventType | null,
  name: string,
  startDateTime?: string | null,
  endDateTime?: string | null,
  description?: string | null,
  location?: string | null,
  website?: string | null,
  groupRateCode?: string | null,
  organizer?: string | null,
  specialRates?: boolean | null,
  venue?: string | null,
  venueWebSite?: string | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  createdBy?: User | null,
  modifiedByUserId?: string | null,
  modifiedBy?: User | null,
  modifications?:  Array<ModifyRecord | null > | null,
  updatedAt: string,
  organizationCalendarId?: string | null,
};

export type CalendarEventType = {
  __typename: "CalendarEventType",
  id: string,
  name: string,
  description?: string | null,
  createdAt?: string | null,
  createdBy?: User | null,
  modifications?:  Array<ModifyRecord | null > | null,
  updatedAt: string,
};

export enum FacilityLocationTypeChoice {
  ON_AIRPORT = "ON_AIRPORT",
  OFF_AIRPORT = "OFF_AIRPORT",
  UNKNOWN = "UNKNOWN",
}


export enum MOTypeChoice {
  EVERGREEN = "EVERGREEN",
  MONTH_TO_MONTH = "MONTH_TO_MONTH",
  N_A = "N_A",
  CUSTOM = "CUSTOM",
}


export enum LeaseTypeChoice {
  EVERGREEN = "EVERGREEN",
  MONTH_TO_MONTH = "MONTH_TO_MONTH",
  N_A = "N_A",
  CUSTOM = "CUSTOM",
}


export enum ReservePrebillTypeChoice {
  ONE_MONTH = "ONE_MONTH",
  TWO_MONTHS = "TWO_MONTHS",
  PREBILL = "PREBILL",
  OTHER = "OTHER",
}


export type ModelDeliveryToPlaneConnection = {
  __typename: "ModelDeliveryToPlaneConnection",
  items:  Array<DeliveryToPlane | null >,
  nextToken?: string | null,
};

export type DeliveryToPlane = {
  __typename: "DeliveryToPlane",
  id: string,
  airportId?: string | null,
  airport: Airport,
  organizationId?: string | null,
  organization?: Organization | null,
  airportDeliveryToPlaneId?: string | null,
  obFiltration?: boolean | null,
  hydrant?: HydrantChoice | null,
  closedLoop: ClosedLoopChoice,
  length?: number | null,
  hydrantLocations?: string | null,
  pits?: number | null,
  refuelerLocations?: string | null,
  efso?: boolean | null,
  efsoLocation?: string | null,
  leakDetection?: boolean | null,
  leakDetectionDescription?: string | null,
  flowRateF1Peak?: number | null,
  flowRateF1Max?: number | null,
  flowRateF2Peak?: number | null,
  flowRateF2Max?: number | null,
  flowRateF3Peak?: number | null,
  flowRateF3Max?: number | null,
  pipingSizeMin?: number | null,
  pipingSizeMax?: number | null,
  currentIntoPlaneOperators?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum HydrantChoice {
  YES = "YES",
  NO = "NO",
  PARTIAL = "PARTIAL",
}


export enum ClosedLoopChoice {
  YES = "YES",
  NO = "NO",
  PARTIAL = "PARTIAL",
  NA = "NA",
}


export type ModelDeliveryToFacilityConnection = {
  __typename: "ModelDeliveryToFacilityConnection",
  items:  Array<DeliveryToFacility | null >,
  nextToken?: string | null,
};

export type DeliveryToFacility = {
  __typename: "DeliveryToFacility",
  id: string,
  airportId?: string | null,
  airport: Airport,
  provider?: string | null,
  organizationId?: string | null,
  organization?: Organization | null,
  method?: DeliveryMethodChoice | null,
  primary?: boolean | null,
  numberLines?: number | null,
  frequency?: DeliveryFrequencyChoice | null,
  frequencyCustomValue?: number | null,
  filtrationType?: FiltrationTypeChoice | null,
  filtrationTypeCustomValue?: string | null,
  filtrationDescription?: string | null,
  flowRateTypical?: number | null,
  flowRateMax?: number | null,
  pipingSize?: number | null,
  offloadLocations?: number | null,
  createdAt: string,
  updatedAt: string,
};

export enum DeliveryMethodChoice {
  PIPELINE = "PIPELINE",
  TRUCK = "TRUCK",
  BARGE = "BARGE",
  RAIL = "RAIL",
}


export enum DeliveryFrequencyChoice {
  ON_DEMAND = "ON_DEMAND",
  DAILY = "DAILY",
  CUSTOM = "CUSTOM",
}


export enum FiltrationTypeChoice {
  ON_SITE_THREE_STAGE = "ON_SITE_THREE_STAGE",
  OFF_SITE_THREE_STAGE = "OFF_SITE_THREE_STAGE",
  ON_SITE_FOUR_STAGE = "ON_SITE_FOUR_STAGE",
  ON_SITE_FILTER_SEPARATORS = "ON_SITE_FILTER_SEPARATORS",
  OFF_SITE_FILTER_SEPARATORS = "OFF_SITE_FILTER_SEPARATORS",
  LEASED_OFF_SITE_FACILITY = "LEASED_OFF_SITE_FACILITY",
  MICRONIC_FILTERS = "MICRONIC_FILTERS",
  NONE = "NONE",
  CUSTOM = "CUSTOM",
}


export type ModelStorageConnection = {
  __typename: "ModelStorageConnection",
  items:  Array<Storage | null >,
  nextToken?: string | null,
};

export type Storage = {
  __typename: "Storage",
  id: string,
  airportId?: string | null,
  airport: Airport,
  organizationId?: string | null,
  organization?: Organization | null,
  tankId: string,
  yearInstalled: string,
  product: Array< StorageProductChoice | null >,
  storageLocation: StorageLocationChoice,
  storageASTUST: StorageASTUSTChoice,
  nextAPI653?: string | null,
  storageFunction: Array< StorageFunctionChoice | null >,
  totalBBL?: number | null,
  usableBBL?: number | null,
  opStatus: StorageOpStatusChoice,
  owner?: string | null,
  security?: string | null,
  secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
  cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
  leakDetection?: string | null,
  totalGals?: number | null,
  usableGals?: number | null,
  createdAt: string,
  updatedAt: string,
};

export enum StorageProductChoice {
  JET_A = "JET_A",
  AVGAS = "AVGAS",
  BONDED = "BONDED",
  DIESEL = "DIESEL",
  GLYCOL = "GLYCOL",
  MOGAS = "MOGAS",
  RECOVERED_FUEL = "RECOVERED_FUEL",
  WASTE = "WASTE",
}


export enum StorageLocationChoice {
  ON_AIRPORT = "ON_AIRPORT",
  OFF_AIRPORT = "OFF_AIRPORT",
  UNKNOWN = "UNKNOWN",
}


export enum StorageASTUSTChoice {
  AST = "AST",
  UST = "UST",
}


export enum StorageFunctionChoice {
  ISSUE = "ISSUE",
  RECEIPT = "RECEIPT",
  RECOVERY = "RECOVERY",
  SETTLING = "SETTLING",
}


export enum StorageOpStatusChoice {
  OPERATING = "OPERATING",
  TEMP_NON_OP = "TEMP_NON_OP",
  CLOSED_IN_PLACE = "CLOSED_IN_PLACE",
  REMOVED = "REMOVED",
  AUXILIARY = "AUXILIARY",
}


export enum StorageSecondaryContainmentChoice {
  DIKES = "DIKES",
  DOUBLE_BOTTOM = "DOUBLE_BOTTOM",
  DOUBLE_WALL = "DOUBLE_WALL",
  LINER = "LINER",
  MONITORING_WELLS = "MONITORING_WELLS",
  OTHER = "OTHER",
}


export enum StorageCathodicProtectionChoice {
  IMPRESSED_CURRENT = "IMPRESSED_CURRENT",
  SACRIFICIAL_ANODE = "SACRIFICIAL_ANODE",
  OTHER = "OTHER",
  NA = "NA",
}


export type ModelAirportVolumeFilterInput = {
  id?: ModelIDInput | null,
  airportId?: ModelIDInput | null,
  year?: ModelIntInput | null,
  volume?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAirportVolumeFilterInput | null > | null,
  or?: Array< ModelAirportVolumeFilterInput | null > | null,
  not?: ModelAirportVolumeFilterInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAirportVolumeConnection = {
  __typename: "ModelAirportVolumeConnection",
  items:  Array<AirportVolume | null >,
  nextToken?: string | null,
};

export type AirportVolume = {
  __typename: "AirportVolume",
  id: string,
  airportId?: string | null,
  airport?: Airport | null,
  year?: number | null,
  volume?: number | null,
  createdAt?: string | null,
  createdBy?: User | null,
  modifications?:  Array<ModifyRecord | null > | null,
  updatedAt: string,
};

export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelCompanyTypeChoiceInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
  organizationCompaniesId?: ModelIDInput | null,
};

export type ModelCompanyTypeChoiceInput = {
  eq?: CompanyTypeChoice | null,
  ne?: CompanyTypeChoice | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  cognitoUserId?: ModelStringInput | null,
  termsAgree?: ModelBooleanInput | null,
  termsAgreeDate?: ModelStringInput | null,
  participationAgreement?: ModelBooleanInput | null,
  participationAgreementDate?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  office?: ModelStringInput | null,
  mobile?: ModelStringInput | null,
  linkedInUrl?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  timeZone?: ModelStringInput | null,
  monthlyUpdates?: ModelBooleanInput | null,
  generalAnnouncements?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  lastLoginDate?: ModelStringInput | null,
  weeklyDigestNotification?: ModelBooleanInput | null,
  monthlyDigestNotification?: ModelBooleanInput | null,
  quarterlyEmailNotification?: ModelBooleanInput | null,
  annualDigestNotification?: ModelBooleanInput | null,
  membershipAlertsNotification?: ModelBooleanInput | null,
  generalEmailNotification?: ModelBooleanInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  organizationContactsId?: ModelIDInput | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  organizationType?: ModelOrganizationTypeChoiceInput | null,
  groupType?: ModelGroupTypeChoiceInput | null,
  chairId?: ModelIDInput | null,
  chairContactId?: ModelIDInput | null,
  operatorId?: ModelIDInput | null,
  operatorContactId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
  companyOrganizationsId?: ModelIDInput | null,
  userOrganizationId?: ModelIDInput | null,
};

export type ModelOrganizationTypeChoiceInput = {
  eq?: OrganizationTypeChoice | null,
  ne?: OrganizationTypeChoice | null,
};

export type ModelInviteLogFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  isAccepted?: ModelBooleanInput | null,
  type?: ModelInviteTypeInput | null,
  sendByUserId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  acceptedAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelInviteLogFilterInput | null > | null,
  or?: Array< ModelInviteLogFilterInput | null > | null,
  not?: ModelInviteLogFilterInput | null,
};

export type ModelInviteTypeInput = {
  eq?: InviteType | null,
  ne?: InviteType | null,
};

export enum InviteType {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
}


export type ModelInviteLogConnection = {
  __typename: "ModelInviteLogConnection",
  items:  Array<InviteLog | null >,
  nextToken?: string | null,
};

export type InviteLog = {
  __typename: "InviteLog",
  id: string,
  organizationId?: string | null,
  organization?: Organization | null,
  userId?: string | null,
  user?: User | null,
  isAccepted?: boolean | null,
  type?: InviteType | null,
  sendByUserId?: string | null,
  sendBy?: User | null,
  createdAt?: string | null,
  acceptedAt?: string | null,
  deleted?: boolean | null,
  updatedAt: string,
};

export type ModelAirportConsortiumGroupFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  airportId?: ModelIDInput | null,
  chairId?: ModelIDInput | null,
  chairContactId?: ModelIDInput | null,
  operatorId?: ModelIDInput | null,
  operatorGeneralManagerId?: ModelIDInput | null,
  secondaryContact?: ModelStringInput | null,
  facilityOwner?: ModelStringInput | null,
  facilityOwnerContact?: ModelStringInput | null,
  legalCounselContact?: ModelStringInput | null,
  legalCounsel?: ModelStringInput | null,
  facilityLocation?: ModelFacilityLocationTypeChoiceInput | null,
  totalCapacityDays?: ModelFloatInput | null,
  totalCapacityGallons?: ModelFloatInput | null,
  secured?: ModelBooleanInput | null,
  moExpiration?: ModelStringInput | null,
  moType?: ModelMOTypeChoiceInput | null,
  moTypeCustomValue?: ModelStringInput | null,
  leaseExpiration?: ModelStringInput | null,
  leaseType?: ModelLeaseTypeChoiceInput | null,
  leaseTypeCustomValue?: ModelStringInput | null,
  dateLastFinancialSUP?: ModelStringInput | null,
  dateLastA4aOperationalReview?: ModelStringInput | null,
  netInventoryCalculation?: ModelStringInput | null,
  entryFee?: ModelStringInput | null,
  nonMemberRate?: ModelStringInput | null,
  costSharingFormula?: ModelStringInput | null,
  reserveDepositPrebill?: ModelReservePrebillTypeChoiceInput | null,
  thirdPartyDebt?: ModelFloatInput | null,
  memberLoans?: ModelFloatInput | null,
  dateLastPropertyAppraisal?: ModelStringInput | null,
  aviationLiabilityLimit?: ModelFloatInput | null,
  environmentalLiabilityLimit?: ModelFloatInput | null,
  comments?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelAirportConsortiumGroupFilterInput | null > | null,
  or?: Array< ModelAirportConsortiumGroupFilterInput | null > | null,
  not?: ModelAirportConsortiumGroupFilterInput | null,
};

export type ModelFacilityLocationTypeChoiceInput = {
  eq?: FacilityLocationTypeChoice | null,
  ne?: FacilityLocationTypeChoice | null,
};

export type ModelMOTypeChoiceInput = {
  eq?: MOTypeChoice | null,
  ne?: MOTypeChoice | null,
};

export type ModelLeaseTypeChoiceInput = {
  eq?: LeaseTypeChoice | null,
  ne?: LeaseTypeChoice | null,
};

export type ModelReservePrebillTypeChoiceInput = {
  eq?: ReservePrebillTypeChoice | null,
  ne?: ReservePrebillTypeChoice | null,
};

export type CreateOrganizationInput = {
  id?: string | null,
  name: string,
  organizationType?: OrganizationTypeChoice | null,
  groupType?: GroupTypeChoice | null,
  chairId?: string | null,
  chairContactId?: string | null,
  operatorId?: string | null,
  operatorContactId?: string | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  deleted?: boolean | null,
  companyOrganizationsId?: string | null,
  userOrganizationId?: string | null,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput | null,
  organizationType?: ModelOrganizationTypeChoiceInput | null,
  groupType?: ModelGroupTypeChoiceInput | null,
  chairId?: ModelIDInput | null,
  chairContactId?: ModelIDInput | null,
  operatorId?: ModelIDInput | null,
  operatorContactId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
  companyOrganizationsId?: ModelIDInput | null,
  userOrganizationId?: ModelIDInput | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  organizationType?: OrganizationTypeChoice | null,
  groupType?: GroupTypeChoice | null,
  chairId?: string | null,
  chairContactId?: string | null,
  operatorId?: string | null,
  operatorContactId?: string | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  deleted?: boolean | null,
  companyOrganizationsId?: string | null,
  userOrganizationId?: string | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateCompanyInput = {
  id?: string | null,
  name: string,
  type?: CompanyTypeChoice | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  deleted?: boolean | null,
  organizationCompaniesId?: string | null,
};

export type ModelCompanyConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelCompanyTypeChoiceInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
  organizationCompaniesId?: ModelIDInput | null,
};

export type UpdateCompanyInput = {
  id: string,
  name?: string | null,
  type?: CompanyTypeChoice | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  deleted?: boolean | null,
  organizationCompaniesId?: string | null,
};

export type DeleteCompanyInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  cognitoUserId?: string | null,
  termsAgree?: boolean | null,
  termsAgreeDate?: string | null,
  participationAgreement?: boolean | null,
  participationAgreementDate?: string | null,
  roles?: Array< string | null > | null,
  companyId?: string | null,
  firstName: string,
  lastName: string,
  email: string,
  office?: string | null,
  mobile?: string | null,
  linkedInUrl?: string | null,
  imageUrl?: string | null,
  timeZone?: string | null,
  monthlyUpdates?: boolean | null,
  generalAnnouncements?: boolean | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  deleted?: boolean | null,
  lastLoginDate?: string | null,
  weeklyDigestNotification?: boolean | null,
  monthlyDigestNotification?: boolean | null,
  quarterlyEmailNotification?: boolean | null,
  annualDigestNotification?: boolean | null,
  membershipAlertsNotification?: boolean | null,
  generalEmailNotification?: boolean | null,
  organizationContactsId?: string | null,
};

export type ModelUserConditionInput = {
  cognitoUserId?: ModelStringInput | null,
  termsAgree?: ModelBooleanInput | null,
  termsAgreeDate?: ModelStringInput | null,
  participationAgreement?: ModelBooleanInput | null,
  participationAgreementDate?: ModelStringInput | null,
  roles?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  office?: ModelStringInput | null,
  mobile?: ModelStringInput | null,
  linkedInUrl?: ModelStringInput | null,
  imageUrl?: ModelStringInput | null,
  timeZone?: ModelStringInput | null,
  monthlyUpdates?: ModelBooleanInput | null,
  generalAnnouncements?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  lastLoginDate?: ModelStringInput | null,
  weeklyDigestNotification?: ModelBooleanInput | null,
  monthlyDigestNotification?: ModelBooleanInput | null,
  quarterlyEmailNotification?: ModelBooleanInput | null,
  annualDigestNotification?: ModelBooleanInput | null,
  membershipAlertsNotification?: ModelBooleanInput | null,
  generalEmailNotification?: ModelBooleanInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  organizationContactsId?: ModelIDInput | null,
};

export type UpdateUserInput = {
  id: string,
  cognitoUserId?: string | null,
  termsAgree?: boolean | null,
  termsAgreeDate?: string | null,
  participationAgreement?: boolean | null,
  participationAgreementDate?: string | null,
  roles?: Array< string | null > | null,
  companyId?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  office?: string | null,
  mobile?: string | null,
  linkedInUrl?: string | null,
  imageUrl?: string | null,
  timeZone?: string | null,
  monthlyUpdates?: boolean | null,
  generalAnnouncements?: boolean | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  deleted?: boolean | null,
  lastLoginDate?: string | null,
  weeklyDigestNotification?: boolean | null,
  monthlyDigestNotification?: boolean | null,
  quarterlyEmailNotification?: boolean | null,
  annualDigestNotification?: boolean | null,
  membershipAlertsNotification?: boolean | null,
  generalEmailNotification?: boolean | null,
  organizationContactsId?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateDocumentInput = {
  id?: string | null,
  organizationId?: string | null,
  airportId?: string | null,
  categoryType?: DocumentCategoryTypeChoice | null,
  documentCategoryId?: string | null,
  bucketType?: DocumentBucketTypeChoice | null,
  title: string,
  description?: string | null,
  publishedByUserId?: string | null,
  publishedDate?: string | null,
  url: string,
  fileSize?: number | null,
  companyId?: string | null,
  createdAt?: string | null,
  createByUserId?: string | null,
  categoryTypeId?: string | null,
  deleted?: boolean | null,
  organizationDocumentsId?: string | null,
  documentCategoryDocumentsId?: string | null,
  documentFileTypeId?: string | null,
};

export type ModelDocumentConditionInput = {
  organizationId?: ModelIDInput | null,
  airportId?: ModelIDInput | null,
  categoryType?: ModelDocumentCategoryTypeChoiceInput | null,
  documentCategoryId?: ModelIDInput | null,
  bucketType?: ModelDocumentBucketTypeChoiceInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  publishedByUserId?: ModelIDInput | null,
  publishedDate?: ModelStringInput | null,
  url?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createByUserId?: ModelIDInput | null,
  categoryTypeId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelDocumentConditionInput | null > | null,
  or?: Array< ModelDocumentConditionInput | null > | null,
  not?: ModelDocumentConditionInput | null,
  organizationDocumentsId?: ModelIDInput | null,
  documentCategoryDocumentsId?: ModelIDInput | null,
  documentFileTypeId?: ModelIDInput | null,
};

export type ModelDocumentCategoryTypeChoiceInput = {
  eq?: DocumentCategoryTypeChoice | null,
  ne?: DocumentCategoryTypeChoice | null,
};

export type ModelDocumentBucketTypeChoiceInput = {
  eq?: DocumentBucketTypeChoice | null,
  ne?: DocumentBucketTypeChoice | null,
};

export type UpdateDocumentInput = {
  id: string,
  organizationId?: string | null,
  airportId?: string | null,
  categoryType?: DocumentCategoryTypeChoice | null,
  documentCategoryId?: string | null,
  bucketType?: DocumentBucketTypeChoice | null,
  title?: string | null,
  description?: string | null,
  publishedByUserId?: string | null,
  publishedDate?: string | null,
  url?: string | null,
  fileSize?: number | null,
  companyId?: string | null,
  createdAt?: string | null,
  createByUserId?: string | null,
  categoryTypeId?: string | null,
  deleted?: boolean | null,
  organizationDocumentsId?: string | null,
  documentCategoryDocumentsId?: string | null,
  documentFileTypeId?: string | null,
};

export type DeleteDocumentInput = {
  id: string,
};

export type CreateFileTypeInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
};

export type ModelFileTypeConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelFileTypeConditionInput | null > | null,
  or?: Array< ModelFileTypeConditionInput | null > | null,
  not?: ModelFileTypeConditionInput | null,
};

export type UpdateFileTypeInput = {
  id: string,
  name?: string | null,
  description?: string | null,
};

export type DeleteFileTypeInput = {
  id: string,
};

export type CreateDocumentCategoryInput = {
  id?: string | null,
  name: string,
  type?: string | null,
  bucketType?: DocumentBucketTypeChoice | null,
  description?: string | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  deleted?: boolean | null,
};

export type ModelDocumentCategoryConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  bucketType?: ModelDocumentBucketTypeChoiceInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelDocumentCategoryConditionInput | null > | null,
  or?: Array< ModelDocumentCategoryConditionInput | null > | null,
  not?: ModelDocumentCategoryConditionInput | null,
};

export type UpdateDocumentCategoryInput = {
  id: string,
  name?: string | null,
  type?: string | null,
  bucketType?: DocumentBucketTypeChoice | null,
  description?: string | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  deleted?: boolean | null,
};

export type DeleteDocumentCategoryInput = {
  id: string,
};

export type CreateCalendarInput = {
  id?: string | null,
  organizationId?: string | null,
  calendarEventTypeId?: string | null,
  name: string,
  startDateTime?: string | null,
  endDateTime?: string | null,
  description?: string | null,
  location?: string | null,
  website?: string | null,
  groupRateCode?: string | null,
  organizer?: string | null,
  specialRates?: boolean | null,
  venue?: string | null,
  venueWebSite?: string | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  modifiedByUserId?: string | null,
  organizationCalendarId?: string | null,
};

export type ModelCalendarConditionInput = {
  organizationId?: ModelIDInput | null,
  calendarEventTypeId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  description?: ModelStringInput | null,
  location?: ModelStringInput | null,
  website?: ModelStringInput | null,
  groupRateCode?: ModelStringInput | null,
  organizer?: ModelStringInput | null,
  specialRates?: ModelBooleanInput | null,
  venue?: ModelStringInput | null,
  venueWebSite?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  modifiedByUserId?: ModelIDInput | null,
  and?: Array< ModelCalendarConditionInput | null > | null,
  or?: Array< ModelCalendarConditionInput | null > | null,
  not?: ModelCalendarConditionInput | null,
  organizationCalendarId?: ModelIDInput | null,
};

export type UpdateCalendarInput = {
  id: string,
  organizationId?: string | null,
  calendarEventTypeId?: string | null,
  name?: string | null,
  startDateTime?: string | null,
  endDateTime?: string | null,
  description?: string | null,
  location?: string | null,
  website?: string | null,
  groupRateCode?: string | null,
  organizer?: string | null,
  specialRates?: boolean | null,
  venue?: string | null,
  venueWebSite?: string | null,
  createdAt?: string | null,
  createdByUserId?: string | null,
  modifiedByUserId?: string | null,
  organizationCalendarId?: string | null,
};

export type DeleteCalendarInput = {
  id: string,
};

export type CreateCalendarEventTypeInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  createdAt?: string | null,
};

export type ModelCalendarEventTypeConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCalendarEventTypeConditionInput | null > | null,
  or?: Array< ModelCalendarEventTypeConditionInput | null > | null,
  not?: ModelCalendarEventTypeConditionInput | null,
};

export type UpdateCalendarEventTypeInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  createdAt?: string | null,
};

export type DeleteCalendarEventTypeInput = {
  id: string,
};

export type CreateAirportVolumeInput = {
  id?: string | null,
  airportId?: string | null,
  year?: number | null,
  volume?: number | null,
  createdAt?: string | null,
};

export type ModelAirportVolumeConditionInput = {
  airportId?: ModelIDInput | null,
  year?: ModelIntInput | null,
  volume?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAirportVolumeConditionInput | null > | null,
  or?: Array< ModelAirportVolumeConditionInput | null > | null,
  not?: ModelAirportVolumeConditionInput | null,
};

export type UpdateAirportVolumeInput = {
  id: string,
  airportId?: string | null,
  year?: number | null,
  volume?: number | null,
  createdAt?: string | null,
};

export type DeleteAirportVolumeInput = {
  id: string,
};

export type CreateAirportInput = {
  id?: string | null,
  name: string,
  code: string,
  oldCode?: string | null,
  facility?: string | null,
  region?: AirportRegionChoice | null,
  state: string,
  place?: PlaceInput | null,
  consortiumType?: GroupTypeChoice | null,
  chairId?: string | null,
  chairContactId?: string | null,
  operatorId?: string | null,
  operatorGeneralManagerId?: string | null,
  secondaryContactId?: string | null,
  facilityOwnerId?: string | null,
  facilityOwnerContactId?: string | null,
  inventoryAccounting?: InventoryAccountingTypeChoice | null,
  createdAt?: string | null,
  organizationAirportsId?: string | null,
  deleted?: boolean | null,
};

export type PlaceInput = {
  terminal?: string | null,
  gate?: string | null,
  lat?: string | null,
  lon?: string | null,
};

export type ModelAirportConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  oldCode?: ModelStringInput | null,
  facility?: ModelStringInput | null,
  region?: ModelAirportRegionChoiceInput | null,
  state?: ModelStringInput | null,
  consortiumType?: ModelGroupTypeChoiceInput | null,
  chairId?: ModelIDInput | null,
  chairContactId?: ModelIDInput | null,
  operatorId?: ModelIDInput | null,
  operatorGeneralManagerId?: ModelIDInput | null,
  secondaryContactId?: ModelIDInput | null,
  facilityOwnerId?: ModelIDInput | null,
  facilityOwnerContactId?: ModelIDInput | null,
  inventoryAccounting?: ModelInventoryAccountingTypeChoiceInput | null,
  createdAt?: ModelStringInput | null,
  organizationAirportsId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelAirportConditionInput | null > | null,
  or?: Array< ModelAirportConditionInput | null > | null,
  not?: ModelAirportConditionInput | null,
};

export type UpdateAirportInput = {
  id: string,
  name?: string | null,
  code?: string | null,
  oldCode?: string | null,
  facility?: string | null,
  region?: AirportRegionChoice | null,
  state?: string | null,
  place?: PlaceInput | null,
  consortiumType?: GroupTypeChoice | null,
  chairId?: string | null,
  chairContactId?: string | null,
  operatorId?: string | null,
  operatorGeneralManagerId?: string | null,
  secondaryContactId?: string | null,
  facilityOwnerId?: string | null,
  facilityOwnerContactId?: string | null,
  inventoryAccounting?: InventoryAccountingTypeChoice | null,
  createdAt?: string | null,
  organizationAirportsId?: string | null,
  deleted?: boolean | null,
};

export type DeleteAirportInput = {
  id: string,
};

export type CreateAirportConsortiumGroupInput = {
  id?: string | null,
  organizationId: string,
  airportId: string,
  chairId?: string | null,
  chairContactId?: string | null,
  operatorId?: string | null,
  operatorGeneralManagerId?: string | null,
  secondaryContact?: string | null,
  facilityOwner?: string | null,
  facilityOwnerContact?: string | null,
  legalCounselContact?: string | null,
  legalCounsel?: string | null,
  facilityLocation?: FacilityLocationTypeChoice | null,
  totalCapacityDays?: number | null,
  totalCapacityGallons?: number | null,
  secured?: boolean | null,
  moExpiration?: string | null,
  moType?: MOTypeChoice | null,
  moTypeCustomValue?: string | null,
  leaseExpiration?: string | null,
  leaseType?: LeaseTypeChoice | null,
  leaseTypeCustomValue?: string | null,
  dateLastFinancialSUP?: string | null,
  dateLastA4aOperationalReview?: string | null,
  netInventoryCalculation?: string | null,
  entryFee?: string | null,
  nonMemberRate?: string | null,
  costSharingFormula?: string | null,
  reserveDepositPrebill?: ReservePrebillTypeChoice | null,
  thirdPartyDebt?: number | null,
  memberLoans?: number | null,
  dateLastPropertyAppraisal?: string | null,
  aviationLiabilityLimit?: number | null,
  environmentalLiabilityLimit?: number | null,
  comments?: string | null,
  deleted?: boolean | null,
};

export type ModelAirportConsortiumGroupConditionInput = {
  organizationId?: ModelIDInput | null,
  airportId?: ModelIDInput | null,
  chairId?: ModelIDInput | null,
  chairContactId?: ModelIDInput | null,
  operatorId?: ModelIDInput | null,
  operatorGeneralManagerId?: ModelIDInput | null,
  secondaryContact?: ModelStringInput | null,
  facilityOwner?: ModelStringInput | null,
  facilityOwnerContact?: ModelStringInput | null,
  legalCounselContact?: ModelStringInput | null,
  legalCounsel?: ModelStringInput | null,
  facilityLocation?: ModelFacilityLocationTypeChoiceInput | null,
  totalCapacityDays?: ModelFloatInput | null,
  totalCapacityGallons?: ModelFloatInput | null,
  secured?: ModelBooleanInput | null,
  moExpiration?: ModelStringInput | null,
  moType?: ModelMOTypeChoiceInput | null,
  moTypeCustomValue?: ModelStringInput | null,
  leaseExpiration?: ModelStringInput | null,
  leaseType?: ModelLeaseTypeChoiceInput | null,
  leaseTypeCustomValue?: ModelStringInput | null,
  dateLastFinancialSUP?: ModelStringInput | null,
  dateLastA4aOperationalReview?: ModelStringInput | null,
  netInventoryCalculation?: ModelStringInput | null,
  entryFee?: ModelStringInput | null,
  nonMemberRate?: ModelStringInput | null,
  costSharingFormula?: ModelStringInput | null,
  reserveDepositPrebill?: ModelReservePrebillTypeChoiceInput | null,
  thirdPartyDebt?: ModelFloatInput | null,
  memberLoans?: ModelFloatInput | null,
  dateLastPropertyAppraisal?: ModelStringInput | null,
  aviationLiabilityLimit?: ModelFloatInput | null,
  environmentalLiabilityLimit?: ModelFloatInput | null,
  comments?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelAirportConsortiumGroupConditionInput | null > | null,
  or?: Array< ModelAirportConsortiumGroupConditionInput | null > | null,
  not?: ModelAirportConsortiumGroupConditionInput | null,
};

export type UpdateAirportConsortiumGroupInput = {
  id: string,
  organizationId?: string | null,
  airportId?: string | null,
  chairId?: string | null,
  chairContactId?: string | null,
  operatorId?: string | null,
  operatorGeneralManagerId?: string | null,
  secondaryContact?: string | null,
  facilityOwner?: string | null,
  facilityOwnerContact?: string | null,
  legalCounselContact?: string | null,
  legalCounsel?: string | null,
  facilityLocation?: FacilityLocationTypeChoice | null,
  totalCapacityDays?: number | null,
  totalCapacityGallons?: number | null,
  secured?: boolean | null,
  moExpiration?: string | null,
  moType?: MOTypeChoice | null,
  moTypeCustomValue?: string | null,
  leaseExpiration?: string | null,
  leaseType?: LeaseTypeChoice | null,
  leaseTypeCustomValue?: string | null,
  dateLastFinancialSUP?: string | null,
  dateLastA4aOperationalReview?: string | null,
  netInventoryCalculation?: string | null,
  entryFee?: string | null,
  nonMemberRate?: string | null,
  costSharingFormula?: string | null,
  reserveDepositPrebill?: ReservePrebillTypeChoice | null,
  thirdPartyDebt?: number | null,
  memberLoans?: number | null,
  dateLastPropertyAppraisal?: string | null,
  aviationLiabilityLimit?: number | null,
  environmentalLiabilityLimit?: number | null,
  comments?: string | null,
  deleted?: boolean | null,
};

export type DeleteAirportConsortiumGroupInput = {
  id: string,
};

export type CreateDeliveryToPlaneInput = {
  id?: string | null,
  airportId?: string | null,
  organizationId?: string | null,
  airportDeliveryToPlaneId?: string | null,
  obFiltration?: boolean | null,
  hydrant?: HydrantChoice | null,
  closedLoop: ClosedLoopChoice,
  length?: number | null,
  hydrantLocations?: string | null,
  pits?: number | null,
  refuelerLocations?: string | null,
  efso?: boolean | null,
  efsoLocation?: string | null,
  leakDetection?: boolean | null,
  leakDetectionDescription?: string | null,
  flowRateF1Peak?: number | null,
  flowRateF1Max?: number | null,
  flowRateF2Peak?: number | null,
  flowRateF2Max?: number | null,
  flowRateF3Peak?: number | null,
  flowRateF3Max?: number | null,
  pipingSizeMin?: number | null,
  pipingSizeMax?: number | null,
  currentIntoPlaneOperators?: string | null,
};

export type ModelDeliveryToPlaneConditionInput = {
  airportId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  airportDeliveryToPlaneId?: ModelStringInput | null,
  obFiltration?: ModelBooleanInput | null,
  hydrant?: ModelHydrantChoiceInput | null,
  closedLoop?: ModelClosedLoopChoiceInput | null,
  length?: ModelFloatInput | null,
  hydrantLocations?: ModelStringInput | null,
  pits?: ModelIntInput | null,
  refuelerLocations?: ModelStringInput | null,
  efso?: ModelBooleanInput | null,
  efsoLocation?: ModelStringInput | null,
  leakDetection?: ModelBooleanInput | null,
  leakDetectionDescription?: ModelStringInput | null,
  flowRateF1Peak?: ModelFloatInput | null,
  flowRateF1Max?: ModelFloatInput | null,
  flowRateF2Peak?: ModelFloatInput | null,
  flowRateF2Max?: ModelFloatInput | null,
  flowRateF3Peak?: ModelFloatInput | null,
  flowRateF3Max?: ModelFloatInput | null,
  pipingSizeMin?: ModelFloatInput | null,
  pipingSizeMax?: ModelFloatInput | null,
  currentIntoPlaneOperators?: ModelStringInput | null,
  and?: Array< ModelDeliveryToPlaneConditionInput | null > | null,
  or?: Array< ModelDeliveryToPlaneConditionInput | null > | null,
  not?: ModelDeliveryToPlaneConditionInput | null,
};

export type ModelHydrantChoiceInput = {
  eq?: HydrantChoice | null,
  ne?: HydrantChoice | null,
};

export type ModelClosedLoopChoiceInput = {
  eq?: ClosedLoopChoice | null,
  ne?: ClosedLoopChoice | null,
};

export type UpdateDeliveryToPlaneInput = {
  id: string,
  airportId?: string | null,
  organizationId?: string | null,
  airportDeliveryToPlaneId?: string | null,
  obFiltration?: boolean | null,
  hydrant?: HydrantChoice | null,
  closedLoop?: ClosedLoopChoice | null,
  length?: number | null,
  hydrantLocations?: string | null,
  pits?: number | null,
  refuelerLocations?: string | null,
  efso?: boolean | null,
  efsoLocation?: string | null,
  leakDetection?: boolean | null,
  leakDetectionDescription?: string | null,
  flowRateF1Peak?: number | null,
  flowRateF1Max?: number | null,
  flowRateF2Peak?: number | null,
  flowRateF2Max?: number | null,
  flowRateF3Peak?: number | null,
  flowRateF3Max?: number | null,
  pipingSizeMin?: number | null,
  pipingSizeMax?: number | null,
  currentIntoPlaneOperators?: string | null,
};

export type DeleteDeliveryToPlaneInput = {
  id: string,
};

export type CreateDeliveryToFacilityInput = {
  id?: string | null,
  airportId?: string | null,
  provider?: string | null,
  organizationId?: string | null,
  method?: DeliveryMethodChoice | null,
  primary?: boolean | null,
  numberLines?: number | null,
  frequency?: DeliveryFrequencyChoice | null,
  frequencyCustomValue?: number | null,
  filtrationType?: FiltrationTypeChoice | null,
  filtrationTypeCustomValue?: string | null,
  filtrationDescription?: string | null,
  flowRateTypical?: number | null,
  flowRateMax?: number | null,
  pipingSize?: number | null,
  offloadLocations?: number | null,
};

export type ModelDeliveryToFacilityConditionInput = {
  airportId?: ModelIDInput | null,
  provider?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  method?: ModelDeliveryMethodChoiceInput | null,
  primary?: ModelBooleanInput | null,
  numberLines?: ModelIntInput | null,
  frequency?: ModelDeliveryFrequencyChoiceInput | null,
  frequencyCustomValue?: ModelIntInput | null,
  filtrationType?: ModelFiltrationTypeChoiceInput | null,
  filtrationTypeCustomValue?: ModelStringInput | null,
  filtrationDescription?: ModelStringInput | null,
  flowRateTypical?: ModelFloatInput | null,
  flowRateMax?: ModelFloatInput | null,
  pipingSize?: ModelFloatInput | null,
  offloadLocations?: ModelIntInput | null,
  and?: Array< ModelDeliveryToFacilityConditionInput | null > | null,
  or?: Array< ModelDeliveryToFacilityConditionInput | null > | null,
  not?: ModelDeliveryToFacilityConditionInput | null,
};

export type ModelDeliveryMethodChoiceInput = {
  eq?: DeliveryMethodChoice | null,
  ne?: DeliveryMethodChoice | null,
};

export type ModelDeliveryFrequencyChoiceInput = {
  eq?: DeliveryFrequencyChoice | null,
  ne?: DeliveryFrequencyChoice | null,
};

export type ModelFiltrationTypeChoiceInput = {
  eq?: FiltrationTypeChoice | null,
  ne?: FiltrationTypeChoice | null,
};

export type UpdateDeliveryToFacilityInput = {
  id: string,
  airportId?: string | null,
  provider?: string | null,
  organizationId?: string | null,
  method?: DeliveryMethodChoice | null,
  primary?: boolean | null,
  numberLines?: number | null,
  frequency?: DeliveryFrequencyChoice | null,
  frequencyCustomValue?: number | null,
  filtrationType?: FiltrationTypeChoice | null,
  filtrationTypeCustomValue?: string | null,
  filtrationDescription?: string | null,
  flowRateTypical?: number | null,
  flowRateMax?: number | null,
  pipingSize?: number | null,
  offloadLocations?: number | null,
};

export type DeleteDeliveryToFacilityInput = {
  id: string,
};

export type CreateStorageInput = {
  id?: string | null,
  airportId?: string | null,
  organizationId?: string | null,
  tankId: string,
  yearInstalled: string,
  product: Array< StorageProductChoice | null >,
  storageLocation: StorageLocationChoice,
  storageASTUST: StorageASTUSTChoice,
  nextAPI653?: string | null,
  storageFunction: Array< StorageFunctionChoice | null >,
  totalBBL?: number | null,
  usableBBL?: number | null,
  opStatus: StorageOpStatusChoice,
  owner?: string | null,
  security?: string | null,
  secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
  cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
  leakDetection?: string | null,
  totalGals?: number | null,
  usableGals?: number | null,
};

export type ModelStorageConditionInput = {
  airportId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  tankId?: ModelStringInput | null,
  yearInstalled?: ModelStringInput | null,
  product?: ModelStorageProductChoiceInput | null,
  storageLocation?: ModelStorageLocationChoiceInput | null,
  storageASTUST?: ModelStorageASTUSTChoiceInput | null,
  nextAPI653?: ModelStringInput | null,
  storageFunction?: ModelStorageFunctionChoiceInput | null,
  totalBBL?: ModelIntInput | null,
  usableBBL?: ModelIntInput | null,
  opStatus?: ModelStorageOpStatusChoiceInput | null,
  owner?: ModelStringInput | null,
  security?: ModelStringInput | null,
  secondaryContainment?: ModelStorageSecondaryContainmentChoiceListInput | null,
  cathodicProtection?: ModelStorageCathodicProtectionChoiceListInput | null,
  leakDetection?: ModelStringInput | null,
  totalGals?: ModelFloatInput | null,
  usableGals?: ModelFloatInput | null,
  and?: Array< ModelStorageConditionInput | null > | null,
  or?: Array< ModelStorageConditionInput | null > | null,
  not?: ModelStorageConditionInput | null,
};

export type ModelStorageProductChoiceInput = {
  eq?: StorageProductChoice | null,
  ne?: StorageProductChoice | null,
};

export type ModelStorageLocationChoiceInput = {
  eq?: StorageLocationChoice | null,
  ne?: StorageLocationChoice | null,
};

export type ModelStorageASTUSTChoiceInput = {
  eq?: StorageASTUSTChoice | null,
  ne?: StorageASTUSTChoice | null,
};

export type ModelStorageFunctionChoiceInput = {
  eq?: StorageFunctionChoice | null,
  ne?: StorageFunctionChoice | null,
};

export type ModelStorageOpStatusChoiceInput = {
  eq?: StorageOpStatusChoice | null,
  ne?: StorageOpStatusChoice | null,
};

export type ModelStorageSecondaryContainmentChoiceListInput = {
  eq?: Array< StorageSecondaryContainmentChoice | null > | null,
  ne?: Array< StorageSecondaryContainmentChoice | null > | null,
  contains?: StorageSecondaryContainmentChoice | null,
  notContains?: StorageSecondaryContainmentChoice | null,
};

export type ModelStorageCathodicProtectionChoiceListInput = {
  eq?: Array< StorageCathodicProtectionChoice | null > | null,
  ne?: Array< StorageCathodicProtectionChoice | null > | null,
  contains?: StorageCathodicProtectionChoice | null,
  notContains?: StorageCathodicProtectionChoice | null,
};

export type UpdateStorageInput = {
  id: string,
  airportId?: string | null,
  organizationId?: string | null,
  tankId?: string | null,
  yearInstalled?: string | null,
  product?: Array< StorageProductChoice | null > | null,
  storageLocation?: StorageLocationChoice | null,
  storageASTUST?: StorageASTUSTChoice | null,
  nextAPI653?: string | null,
  storageFunction?: Array< StorageFunctionChoice | null > | null,
  totalBBL?: number | null,
  usableBBL?: number | null,
  opStatus?: StorageOpStatusChoice | null,
  owner?: string | null,
  security?: string | null,
  secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
  cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
  leakDetection?: string | null,
  totalGals?: number | null,
  usableGals?: number | null,
};

export type DeleteStorageInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  type?: NotificationType | null,
  method?: NotificationMethod | null,
  notificationDate?: string | null,
  title?: string | null,
  description?: string | null,
};

export enum NotificationType {
  GENERAL = "GENERAL",
}


export enum NotificationMethod {
  EMAIL = "EMAIL",
  APP_ORGANIZATION = "APP_ORGANIZATION",
  APP_GLOBAL = "APP_GLOBAL",
}


export type ModelNotificationConditionInput = {
  type?: ModelNotificationTypeInput | null,
  method?: ModelNotificationMethodInput | null,
  notificationDate?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type ModelNotificationMethodInput = {
  eq?: NotificationMethod | null,
  ne?: NotificationMethod | null,
};

export type Notification = {
  __typename: "Notification",
  id?: string | null,
  type?: NotificationType | null,
  method?: NotificationMethod | null,
  scope?:  Array<Organization | null > | null,
  notificationDate?: string | null,
  title?: string | null,
  description?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNotificationInput = {
  id: string,
  type?: NotificationType | null,
  method?: NotificationMethod | null,
  notificationDate?: string | null,
  title?: string | null,
  description?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateModifyRecordInput = {
  fieldName?: string | null,
  fieldValue?: string | null,
  dataOperation?: DataOperation | null,
  modifiedAt?: string | null,
  id?: string | null,
};

export type ModelModifyRecordConditionInput = {
  fieldName?: ModelStringInput | null,
  fieldValue?: ModelStringInput | null,
  dataOperation?: ModelDataOperationInput | null,
  modifiedAt?: ModelStringInput | null,
  and?: Array< ModelModifyRecordConditionInput | null > | null,
  or?: Array< ModelModifyRecordConditionInput | null > | null,
  not?: ModelModifyRecordConditionInput | null,
};

export type ModelDataOperationInput = {
  eq?: DataOperation | null,
  ne?: DataOperation | null,
};

export type UpdateModifyRecordInput = {
  fieldName?: string | null,
  fieldValue?: string | null,
  dataOperation?: DataOperation | null,
  modifiedAt?: string | null,
  id: string,
};

export type DeleteModifyRecordInput = {
  id: string,
};

export type CreateInviteLogInput = {
  id?: string | null,
  organizationId?: string | null,
  userId?: string | null,
  isAccepted?: boolean | null,
  type?: InviteType | null,
  sendByUserId?: string | null,
  createdAt?: string | null,
  acceptedAt?: string | null,
  deleted?: boolean | null,
};

export type ModelInviteLogConditionInput = {
  organizationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  isAccepted?: ModelBooleanInput | null,
  type?: ModelInviteTypeInput | null,
  sendByUserId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  acceptedAt?: ModelStringInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelInviteLogConditionInput | null > | null,
  or?: Array< ModelInviteLogConditionInput | null > | null,
  not?: ModelInviteLogConditionInput | null,
};

export type UpdateInviteLogInput = {
  id: string,
  organizationId?: string | null,
  userId?: string | null,
  isAccepted?: boolean | null,
  type?: InviteType | null,
  sendByUserId?: string | null,
  createdAt?: string | null,
  acceptedAt?: string | null,
  deleted?: boolean | null,
};

export type DeleteInviteLogInput = {
  id: string,
};

export type CreateOrgAdminUserInput = {
  id?: string | null,
  organizationId: string,
  userId: string,
};

export type ModelOrgAdminUserConditionInput = {
  organizationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelOrgAdminUserConditionInput | null > | null,
  or?: Array< ModelOrgAdminUserConditionInput | null > | null,
  not?: ModelOrgAdminUserConditionInput | null,
};

export type UpdateOrgAdminUserInput = {
  id: string,
  organizationId?: string | null,
  userId?: string | null,
};

export type DeleteOrgAdminUserInput = {
  id: string,
};

export type CreateOrgMemberUserInput = {
  id?: string | null,
  organizationId: string,
  userId: string,
};

export type ModelOrgMemberUserConditionInput = {
  organizationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelOrgMemberUserConditionInput | null > | null,
  or?: Array< ModelOrgMemberUserConditionInput | null > | null,
  not?: ModelOrgMemberUserConditionInput | null,
};

export type UpdateOrgMemberUserInput = {
  id: string,
  organizationId?: string | null,
  userId?: string | null,
};

export type DeleteOrgMemberUserInput = {
  id: string,
};

export type CreateOrgSupportAdminUserInput = {
  id?: string | null,
  organizationId: string,
  userId: string,
};

export type ModelOrgSupportAdminUserConditionInput = {
  organizationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelOrgSupportAdminUserConditionInput | null > | null,
  or?: Array< ModelOrgSupportAdminUserConditionInput | null > | null,
  not?: ModelOrgSupportAdminUserConditionInput | null,
};

export type UpdateOrgSupportAdminUserInput = {
  id: string,
  organizationId?: string | null,
  userId?: string | null,
};

export type DeleteOrgSupportAdminUserInput = {
  id: string,
};

export type ModelDocumentFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  airportId?: ModelIDInput | null,
  categoryType?: ModelDocumentCategoryTypeChoiceInput | null,
  documentCategoryId?: ModelIDInput | null,
  bucketType?: ModelDocumentBucketTypeChoiceInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  publishedByUserId?: ModelIDInput | null,
  publishedDate?: ModelStringInput | null,
  url?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  companyId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  createByUserId?: ModelIDInput | null,
  categoryTypeId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelDocumentFilterInput | null > | null,
  or?: Array< ModelDocumentFilterInput | null > | null,
  not?: ModelDocumentFilterInput | null,
  organizationDocumentsId?: ModelIDInput | null,
  documentCategoryDocumentsId?: ModelIDInput | null,
  documentFileTypeId?: ModelIDInput | null,
};

export type ModelFileTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelFileTypeFilterInput | null > | null,
  or?: Array< ModelFileTypeFilterInput | null > | null,
  not?: ModelFileTypeFilterInput | null,
};

export type ModelFileTypeConnection = {
  __typename: "ModelFileTypeConnection",
  items:  Array<FileType | null >,
  nextToken?: string | null,
};

export type ModelDocumentCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelStringInput | null,
  bucketType?: ModelDocumentBucketTypeChoiceInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  deleted?: ModelBooleanInput | null,
  and?: Array< ModelDocumentCategoryFilterInput | null > | null,
  or?: Array< ModelDocumentCategoryFilterInput | null > | null,
  not?: ModelDocumentCategoryFilterInput | null,
};

export type ModelDocumentCategoryConnection = {
  __typename: "ModelDocumentCategoryConnection",
  items:  Array<DocumentCategory | null >,
  nextToken?: string | null,
};

export type ModelCalendarFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  calendarEventTypeId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  startDateTime?: ModelStringInput | null,
  endDateTime?: ModelStringInput | null,
  description?: ModelStringInput | null,
  location?: ModelStringInput | null,
  website?: ModelStringInput | null,
  groupRateCode?: ModelStringInput | null,
  organizer?: ModelStringInput | null,
  specialRates?: ModelBooleanInput | null,
  venue?: ModelStringInput | null,
  venueWebSite?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByUserId?: ModelIDInput | null,
  modifiedByUserId?: ModelIDInput | null,
  and?: Array< ModelCalendarFilterInput | null > | null,
  or?: Array< ModelCalendarFilterInput | null > | null,
  not?: ModelCalendarFilterInput | null,
  organizationCalendarId?: ModelIDInput | null,
};

export type ModelCalendarEventTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCalendarEventTypeFilterInput | null > | null,
  or?: Array< ModelCalendarEventTypeFilterInput | null > | null,
  not?: ModelCalendarEventTypeFilterInput | null,
};

export type ModelCalendarEventTypeConnection = {
  __typename: "ModelCalendarEventTypeConnection",
  items:  Array<CalendarEventType | null >,
  nextToken?: string | null,
};

export type ModelDeliveryToPlaneFilterInput = {
  id?: ModelIDInput | null,
  airportId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  airportDeliveryToPlaneId?: ModelStringInput | null,
  obFiltration?: ModelBooleanInput | null,
  hydrant?: ModelHydrantChoiceInput | null,
  closedLoop?: ModelClosedLoopChoiceInput | null,
  length?: ModelFloatInput | null,
  hydrantLocations?: ModelStringInput | null,
  pits?: ModelIntInput | null,
  refuelerLocations?: ModelStringInput | null,
  efso?: ModelBooleanInput | null,
  efsoLocation?: ModelStringInput | null,
  leakDetection?: ModelBooleanInput | null,
  leakDetectionDescription?: ModelStringInput | null,
  flowRateF1Peak?: ModelFloatInput | null,
  flowRateF1Max?: ModelFloatInput | null,
  flowRateF2Peak?: ModelFloatInput | null,
  flowRateF2Max?: ModelFloatInput | null,
  flowRateF3Peak?: ModelFloatInput | null,
  flowRateF3Max?: ModelFloatInput | null,
  pipingSizeMin?: ModelFloatInput | null,
  pipingSizeMax?: ModelFloatInput | null,
  currentIntoPlaneOperators?: ModelStringInput | null,
  and?: Array< ModelDeliveryToPlaneFilterInput | null > | null,
  or?: Array< ModelDeliveryToPlaneFilterInput | null > | null,
  not?: ModelDeliveryToPlaneFilterInput | null,
};

export type ModelDeliveryToFacilityFilterInput = {
  id?: ModelIDInput | null,
  airportId?: ModelIDInput | null,
  provider?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  method?: ModelDeliveryMethodChoiceInput | null,
  primary?: ModelBooleanInput | null,
  numberLines?: ModelIntInput | null,
  frequency?: ModelDeliveryFrequencyChoiceInput | null,
  frequencyCustomValue?: ModelIntInput | null,
  filtrationType?: ModelFiltrationTypeChoiceInput | null,
  filtrationTypeCustomValue?: ModelStringInput | null,
  filtrationDescription?: ModelStringInput | null,
  flowRateTypical?: ModelFloatInput | null,
  flowRateMax?: ModelFloatInput | null,
  pipingSize?: ModelFloatInput | null,
  offloadLocations?: ModelIntInput | null,
  and?: Array< ModelDeliveryToFacilityFilterInput | null > | null,
  or?: Array< ModelDeliveryToFacilityFilterInput | null > | null,
  not?: ModelDeliveryToFacilityFilterInput | null,
};

export type ModelStorageFilterInput = {
  id?: ModelIDInput | null,
  airportId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  tankId?: ModelStringInput | null,
  yearInstalled?: ModelStringInput | null,
  product?: ModelStorageProductChoiceInput | null,
  storageLocation?: ModelStorageLocationChoiceInput | null,
  storageASTUST?: ModelStorageASTUSTChoiceInput | null,
  nextAPI653?: ModelStringInput | null,
  storageFunction?: ModelStorageFunctionChoiceInput | null,
  totalBBL?: ModelIntInput | null,
  usableBBL?: ModelIntInput | null,
  opStatus?: ModelStorageOpStatusChoiceInput | null,
  owner?: ModelStringInput | null,
  security?: ModelStringInput | null,
  secondaryContainment?: ModelStorageSecondaryContainmentChoiceListInput | null,
  cathodicProtection?: ModelStorageCathodicProtectionChoiceListInput | null,
  leakDetection?: ModelStringInput | null,
  totalGals?: ModelFloatInput | null,
  usableGals?: ModelFloatInput | null,
  and?: Array< ModelStorageFilterInput | null > | null,
  or?: Array< ModelStorageFilterInput | null > | null,
  not?: ModelStorageFilterInput | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelNotificationTypeInput | null,
  method?: ModelNotificationMethodInput | null,
  notificationDate?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelModifyRecordFilterInput = {
  fieldName?: ModelStringInput | null,
  fieldValue?: ModelStringInput | null,
  dataOperation?: ModelDataOperationInput | null,
  modifiedAt?: ModelStringInput | null,
  and?: Array< ModelModifyRecordFilterInput | null > | null,
  or?: Array< ModelModifyRecordFilterInput | null > | null,
  not?: ModelModifyRecordFilterInput | null,
};

export type ModelModifyRecordConnection = {
  __typename: "ModelModifyRecordConnection",
  items:  Array<ModifyRecord | null >,
  nextToken?: string | null,
};

export type ModelOrgAdminUserFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelOrgAdminUserFilterInput | null > | null,
  or?: Array< ModelOrgAdminUserFilterInput | null > | null,
  not?: ModelOrgAdminUserFilterInput | null,
};

export type ModelOrgMemberUserFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelOrgMemberUserFilterInput | null > | null,
  or?: Array< ModelOrgMemberUserFilterInput | null > | null,
  not?: ModelOrgMemberUserFilterInput | null,
};

export type ModelOrgSupportAdminUserFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  and?: Array< ModelOrgSupportAdminUserFilterInput | null > | null,
  or?: Array< ModelOrgSupportAdminUserFilterInput | null > | null,
  not?: ModelOrgSupportAdminUserFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSubscriptionOrganizationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  organizationType?: ModelSubscriptionStringInput | null,
  groupType?: ModelSubscriptionStringInput | null,
  chairId?: ModelSubscriptionIDInput | null,
  chairContactId?: ModelSubscriptionIDInput | null,
  operatorId?: ModelSubscriptionIDInput | null,
  operatorContactId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByUserId?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionCompanyFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByUserId?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
  or?: Array< ModelSubscriptionCompanyFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  cognitoUserId?: ModelSubscriptionStringInput | null,
  termsAgree?: ModelSubscriptionBooleanInput | null,
  termsAgreeDate?: ModelSubscriptionStringInput | null,
  participationAgreement?: ModelSubscriptionBooleanInput | null,
  participationAgreementDate?: ModelSubscriptionStringInput | null,
  roles?: ModelSubscriptionStringInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  office?: ModelSubscriptionStringInput | null,
  mobile?: ModelSubscriptionStringInput | null,
  linkedInUrl?: ModelSubscriptionStringInput | null,
  imageUrl?: ModelSubscriptionStringInput | null,
  timeZone?: ModelSubscriptionStringInput | null,
  monthlyUpdates?: ModelSubscriptionBooleanInput | null,
  generalAnnouncements?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByUserId?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  lastLoginDate?: ModelSubscriptionStringInput | null,
  weeklyDigestNotification?: ModelSubscriptionBooleanInput | null,
  monthlyDigestNotification?: ModelSubscriptionBooleanInput | null,
  quarterlyEmailNotification?: ModelSubscriptionBooleanInput | null,
  annualDigestNotification?: ModelSubscriptionBooleanInput | null,
  membershipAlertsNotification?: ModelSubscriptionBooleanInput | null,
  generalEmailNotification?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionDocumentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  airportId?: ModelSubscriptionIDInput | null,
  categoryType?: ModelSubscriptionStringInput | null,
  documentCategoryId?: ModelSubscriptionIDInput | null,
  bucketType?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  publishedByUserId?: ModelSubscriptionIDInput | null,
  publishedDate?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  fileSize?: ModelSubscriptionIntInput | null,
  companyId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createByUserId?: ModelSubscriptionIDInput | null,
  categoryTypeId?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFileTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFileTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionFileTypeFilterInput | null > | null,
};

export type ModelSubscriptionDocumentCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  bucketType?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByUserId?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionDocumentCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentCategoryFilterInput | null > | null,
};

export type ModelSubscriptionCalendarFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  calendarEventTypeId?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  startDateTime?: ModelSubscriptionStringInput | null,
  endDateTime?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  website?: ModelSubscriptionStringInput | null,
  groupRateCode?: ModelSubscriptionStringInput | null,
  organizer?: ModelSubscriptionStringInput | null,
  specialRates?: ModelSubscriptionBooleanInput | null,
  venue?: ModelSubscriptionStringInput | null,
  venueWebSite?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByUserId?: ModelSubscriptionIDInput | null,
  modifiedByUserId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionCalendarFilterInput | null > | null,
  or?: Array< ModelSubscriptionCalendarFilterInput | null > | null,
};

export type ModelSubscriptionCalendarEventTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCalendarEventTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionCalendarEventTypeFilterInput | null > | null,
};

export type ModelSubscriptionAirportVolumeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  airportId?: ModelSubscriptionIDInput | null,
  year?: ModelSubscriptionIntInput | null,
  volume?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAirportVolumeFilterInput | null > | null,
  or?: Array< ModelSubscriptionAirportVolumeFilterInput | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionAirportFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  code?: ModelSubscriptionStringInput | null,
  oldCode?: ModelSubscriptionStringInput | null,
  facility?: ModelSubscriptionStringInput | null,
  region?: ModelSubscriptionStringInput | null,
  state?: ModelSubscriptionStringInput | null,
  consortiumType?: ModelSubscriptionStringInput | null,
  chairId?: ModelSubscriptionIDInput | null,
  chairContactId?: ModelSubscriptionIDInput | null,
  operatorId?: ModelSubscriptionIDInput | null,
  operatorGeneralManagerId?: ModelSubscriptionIDInput | null,
  secondaryContactId?: ModelSubscriptionIDInput | null,
  facilityOwnerId?: ModelSubscriptionIDInput | null,
  facilityOwnerContactId?: ModelSubscriptionIDInput | null,
  inventoryAccounting?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  organizationAirportsId?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionAirportFilterInput | null > | null,
  or?: Array< ModelSubscriptionAirportFilterInput | null > | null,
};

export type ModelSubscriptionAirportConsortiumGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  airportId?: ModelSubscriptionIDInput | null,
  chairId?: ModelSubscriptionIDInput | null,
  chairContactId?: ModelSubscriptionIDInput | null,
  operatorId?: ModelSubscriptionIDInput | null,
  operatorGeneralManagerId?: ModelSubscriptionIDInput | null,
  secondaryContact?: ModelSubscriptionStringInput | null,
  facilityOwner?: ModelSubscriptionStringInput | null,
  facilityOwnerContact?: ModelSubscriptionStringInput | null,
  legalCounselContact?: ModelSubscriptionStringInput | null,
  legalCounsel?: ModelSubscriptionStringInput | null,
  facilityLocation?: ModelSubscriptionStringInput | null,
  totalCapacityDays?: ModelSubscriptionFloatInput | null,
  totalCapacityGallons?: ModelSubscriptionFloatInput | null,
  secured?: ModelSubscriptionBooleanInput | null,
  moExpiration?: ModelSubscriptionStringInput | null,
  moType?: ModelSubscriptionStringInput | null,
  moTypeCustomValue?: ModelSubscriptionStringInput | null,
  leaseExpiration?: ModelSubscriptionStringInput | null,
  leaseType?: ModelSubscriptionStringInput | null,
  leaseTypeCustomValue?: ModelSubscriptionStringInput | null,
  dateLastFinancialSUP?: ModelSubscriptionStringInput | null,
  dateLastA4aOperationalReview?: ModelSubscriptionStringInput | null,
  netInventoryCalculation?: ModelSubscriptionStringInput | null,
  entryFee?: ModelSubscriptionStringInput | null,
  nonMemberRate?: ModelSubscriptionStringInput | null,
  costSharingFormula?: ModelSubscriptionStringInput | null,
  reserveDepositPrebill?: ModelSubscriptionStringInput | null,
  thirdPartyDebt?: ModelSubscriptionFloatInput | null,
  memberLoans?: ModelSubscriptionFloatInput | null,
  dateLastPropertyAppraisal?: ModelSubscriptionStringInput | null,
  aviationLiabilityLimit?: ModelSubscriptionFloatInput | null,
  environmentalLiabilityLimit?: ModelSubscriptionFloatInput | null,
  comments?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionAirportConsortiumGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionAirportConsortiumGroupFilterInput | null > | null,
};

export type ModelSubscriptionDeliveryToPlaneFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  airportId?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  airportDeliveryToPlaneId?: ModelSubscriptionStringInput | null,
  obFiltration?: ModelSubscriptionBooleanInput | null,
  hydrant?: ModelSubscriptionStringInput | null,
  closedLoop?: ModelSubscriptionStringInput | null,
  length?: ModelSubscriptionFloatInput | null,
  hydrantLocations?: ModelSubscriptionStringInput | null,
  pits?: ModelSubscriptionIntInput | null,
  refuelerLocations?: ModelSubscriptionStringInput | null,
  efso?: ModelSubscriptionBooleanInput | null,
  efsoLocation?: ModelSubscriptionStringInput | null,
  leakDetection?: ModelSubscriptionBooleanInput | null,
  leakDetectionDescription?: ModelSubscriptionStringInput | null,
  flowRateF1Peak?: ModelSubscriptionFloatInput | null,
  flowRateF1Max?: ModelSubscriptionFloatInput | null,
  flowRateF2Peak?: ModelSubscriptionFloatInput | null,
  flowRateF2Max?: ModelSubscriptionFloatInput | null,
  flowRateF3Peak?: ModelSubscriptionFloatInput | null,
  flowRateF3Max?: ModelSubscriptionFloatInput | null,
  pipingSizeMin?: ModelSubscriptionFloatInput | null,
  pipingSizeMax?: ModelSubscriptionFloatInput | null,
  currentIntoPlaneOperators?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDeliveryToPlaneFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeliveryToPlaneFilterInput | null > | null,
};

export type ModelSubscriptionDeliveryToFacilityFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  airportId?: ModelSubscriptionIDInput | null,
  provider?: ModelSubscriptionStringInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  method?: ModelSubscriptionStringInput | null,
  primary?: ModelSubscriptionBooleanInput | null,
  numberLines?: ModelSubscriptionIntInput | null,
  frequency?: ModelSubscriptionStringInput | null,
  frequencyCustomValue?: ModelSubscriptionIntInput | null,
  filtrationType?: ModelSubscriptionStringInput | null,
  filtrationTypeCustomValue?: ModelSubscriptionStringInput | null,
  filtrationDescription?: ModelSubscriptionStringInput | null,
  flowRateTypical?: ModelSubscriptionFloatInput | null,
  flowRateMax?: ModelSubscriptionFloatInput | null,
  pipingSize?: ModelSubscriptionFloatInput | null,
  offloadLocations?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionDeliveryToFacilityFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeliveryToFacilityFilterInput | null > | null,
};

export type ModelSubscriptionStorageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  airportId?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  tankId?: ModelSubscriptionStringInput | null,
  yearInstalled?: ModelSubscriptionStringInput | null,
  product?: ModelSubscriptionStringInput | null,
  storageLocation?: ModelSubscriptionStringInput | null,
  storageASTUST?: ModelSubscriptionStringInput | null,
  nextAPI653?: ModelSubscriptionStringInput | null,
  storageFunction?: ModelSubscriptionStringInput | null,
  totalBBL?: ModelSubscriptionIntInput | null,
  usableBBL?: ModelSubscriptionIntInput | null,
  opStatus?: ModelSubscriptionStringInput | null,
  owner?: ModelSubscriptionStringInput | null,
  security?: ModelSubscriptionStringInput | null,
  secondaryContainment?: ModelSubscriptionStringInput | null,
  cathodicProtection?: ModelSubscriptionStringInput | null,
  leakDetection?: ModelSubscriptionStringInput | null,
  totalGals?: ModelSubscriptionFloatInput | null,
  usableGals?: ModelSubscriptionFloatInput | null,
  and?: Array< ModelSubscriptionStorageFilterInput | null > | null,
  or?: Array< ModelSubscriptionStorageFilterInput | null > | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  method?: ModelSubscriptionStringInput | null,
  notificationDate?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
};

export type ModelSubscriptionModifyRecordFilterInput = {
  fieldName?: ModelSubscriptionStringInput | null,
  fieldValue?: ModelSubscriptionStringInput | null,
  dataOperation?: ModelSubscriptionStringInput | null,
  modifiedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionModifyRecordFilterInput | null > | null,
  or?: Array< ModelSubscriptionModifyRecordFilterInput | null > | null,
};

export type ModelSubscriptionInviteLogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  isAccepted?: ModelSubscriptionBooleanInput | null,
  type?: ModelSubscriptionStringInput | null,
  sendByUserId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  acceptedAt?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionInviteLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionInviteLogFilterInput | null > | null,
};

export type ModelSubscriptionOrgAdminUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionOrgAdminUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrgAdminUserFilterInput | null > | null,
};

export type ModelSubscriptionOrgMemberUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionOrgMemberUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrgMemberUserFilterInput | null > | null,
};

export type ModelSubscriptionOrgSupportAdminUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  organizationId?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionOrgSupportAdminUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrgSupportAdminUserFilterInput | null > | null,
};

export type CustomListAirportsQueryVariables = {
  id?: string | null,
  filter?: ModelAirportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type CustomListAirportsQuery = {
  listAirports?:  {
    __typename: "ModelAirportConnection",
    items:  Array< {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        items:  Array< {
          __typename: "AirportConsortiumGroup",
          organization?:  {
            __typename: "Organization",
            name: string,
            organizationType?: OrganizationTypeChoice | null,
            groupType?: GroupTypeChoice | null,
          } | null,
          id: string,
          organizationId: string,
          chairId?: string | null,
          chair?:  {
            __typename: "Company",
            id: string,
            name: string,
            type?: CompanyTypeChoice | null,
            createdAt?: string | null,
            updatedAt: string,
            organizationCompaniesId?: string | null,
          } | null,
          chairContactId?: string | null,
          chairContact?:  {
            __typename: "User",
            id: string,
            cognitoUserId?: string | null,
            roles?: Array< string | null > | null,
            companyId?: string | null,
            firstName: string,
            lastName: string,
            email: string,
            office?: string | null,
            mobile?: string | null,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
          operatorId?: string | null,
          operator?:  {
            __typename: "Company",
            id: string,
            name: string,
            type?: CompanyTypeChoice | null,
            createdAt?: string | null,
            updatedAt: string,
            organizationCompaniesId?: string | null,
          } | null,
          operatorGeneralManagerId?: string | null,
          operatorGeneralManager?:  {
            __typename: "User",
            id: string,
            cognitoUserId?: string | null,
            roles?: Array< string | null > | null,
            firstName: string,
            lastName: string,
            email: string,
            office?: string | null,
            mobile?: string | null,
            createdAt?: string | null,
            updatedAt: string,
          } | null,
          airportId: string,
          facilityLocation?: FacilityLocationTypeChoice | null,
          totalCapacityDays?: number | null,
          totalCapacityGallons?: number | null,
          secured?: boolean | null,
          moExpiration?: string | null,
          moType?: MOTypeChoice | null,
          moTypeCustomValue?: string | null,
          leaseExpiration?: string | null,
          leaseType?: LeaseTypeChoice | null,
          leaseTypeCustomValue?: string | null,
          netInventoryCalculation?: string | null,
          entryFee?: string | null,
          nonMemberRate?: string | null,
          costSharingFormula?: string | null,
          reserveDepositPrebill?: ReservePrebillTypeChoice | null,
          thirdPartyDebt?: number | null,
          memberLoans?: number | null,
          dateLastPropertyAppraisal?: string | null,
          aviationLiabilityLimit?: number | null,
          environmentalLiabilityLimit?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        items:  Array< {
          __typename: "DeliveryToFacility",
          id: string,
          airportId?: string | null,
          method?: DeliveryMethodChoice | null,
        } | null >,
      } | null,
      deleted?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      organizationAirportsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListAirportVolumesQueryVariables = {
  id?: string | null,
  filter?: ModelAirportVolumeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type CustomListAirportVolumesQuery = {
  listAirportVolumes?:  {
    __typename: "ModelAirportVolumeConnection",
    items:  Array< {
      __typename: "AirportVolume",
      id: string,
      airportId?: string | null,
      airport?:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        state: string,
        place?:  {
          __typename: "Place",
          terminal?: string | null,
          gate?: string | null,
          lat?: string | null,
          lon?: string | null,
        } | null,
      } | null,
      year?: number | null,
      volume?: number | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetAirportQueryVariables = {
  id: string,
};

export type CustomGetAirportQuery = {
  getAirport?:  {
    __typename: "Airport",
    id: string,
    name: string,
    code: string,
    oldCode?: string | null,
    facility?: string | null,
    region?: AirportRegionChoice | null,
    state: string,
    place?:  {
      __typename: "Place",
      terminal?: string | null,
      gate?: string | null,
      lat?: string | null,
      lon?: string | null,
    } | null,
    consortiumGroup?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organization?:  {
          __typename: "Organization",
          name: string,
          organizationType?: OrganizationTypeChoice | null,
          groupType?: GroupTypeChoice | null,
        } | null,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chair?:  {
          __typename: "Company",
          id: string,
          name: string,
          type?: CompanyTypeChoice | null,
          organizations?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt: string,
          organizationCompaniesId?: string | null,
        } | null,
        chairContactId?: string | null,
        chairContact?:  {
          __typename: "User",
          id: string,
          cognitoUserId?: string | null,
          termsAgree?: boolean | null,
          termsAgreeDate?: string | null,
          participationAgreement?: boolean | null,
          participationAgreementDate?: string | null,
          organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
          } | null,
          roles?: Array< string | null > | null,
          companyId?: string | null,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            type?: CompanyTypeChoice | null,
            createdAt?: string | null,
            updatedAt: string,
            organizationCompaniesId?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          office?: string | null,
          mobile?: string | null,
          linkedInUrl?: string | null,
          imageUrl?: string | null,
          timeZone?: string | null,
          monthlyUpdates?: boolean | null,
          generalAnnouncements?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
          organizationContactsId?: string | null,
        } | null,
        operatorId?: string | null,
        operator?:  {
          __typename: "Company",
          id: string,
          name: string,
          type?: CompanyTypeChoice | null,
          organizations?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
          } | null,
          members?:  {
            __typename: "ModelUserConnection",
            nextToken?: string | null,
          } | null,
          createdAt?: string | null,
          updatedAt: string,
          organizationCompaniesId?: string | null,
        } | null,
        operatorGeneralManagerId?: string | null,
        operatorGeneralManager?:  {
          __typename: "User",
          id: string,
          cognitoUserId?: string | null,
          termsAgree?: boolean | null,
          termsAgreeDate?: string | null,
          participationAgreement?: boolean | null,
          participationAgreementDate?: string | null,
          organization?:  {
            __typename: "ModelOrganizationConnection",
            nextToken?: string | null,
          } | null,
          roles?: Array< string | null > | null,
          companyId?: string | null,
          company?:  {
            __typename: "Company",
            id: string,
            name: string,
            type?: CompanyTypeChoice | null,
            createdAt?: string | null,
            updatedAt: string,
            organizationCompaniesId?: string | null,
          } | null,
          firstName: string,
          lastName: string,
          email: string,
          office?: string | null,
          mobile?: string | null,
          linkedInUrl?: string | null,
          imageUrl?: string | null,
          timeZone?: string | null,
          monthlyUpdates?: boolean | null,
          generalAnnouncements?: boolean | null,
          createdAt?: string | null,
          updatedAt: string,
          organizationContactsId?: string | null,
        } | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        createdAt: string,
        legalCounsel?: string | null,
        legalCounselContact?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        netInventoryCalculation?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        entryFee?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        bucketType?: DocumentBucketTypeChoice | null,
        companyId?: string | null,
        title: string,
        description?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        documentCategoryId?: string | null,
        documentCategory?:  {
          __typename: "DocumentCategory",
          name: string,
          bucketType?: DocumentBucketTypeChoice | null,
          description?: string | null,
        } | null,
        fileType?:  {
          __typename: "FileType",
          id?: string | null,
          name?: string | null,
          description?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt?: string | null,
        createdBy?:  {
          __typename: "User",
          id: string,
          cognitoUserId?: string | null,
          companyId?: string | null,
        } | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumType?: GroupTypeChoice | null,
    deliveryToPlane?:  {
      __typename: "ModelDeliveryToPlaneConnection",
      items:  Array< {
        __typename: "DeliveryToPlane",
        id: string,
        airportId?: string | null,
        airportDeliveryToPlaneId?: string | null,
        organizationId?: string | null,
        obFiltration?: boolean | null,
        hydrant?: HydrantChoice | null,
        hydrantLocations?: string | null,
        refuelerLocations?: string | null,
        closedLoop: ClosedLoopChoice,
        length?: number | null,
        pits?: number | null,
        efso?: boolean | null,
        efsoLocation?: string | null,
        leakDetection?: boolean | null,
        leakDetectionDescription?: string | null,
        flowRateF1Peak?: number | null,
        flowRateF1Max?: number | null,
        flowRateF2Peak?: number | null,
        flowRateF2Max?: number | null,
        flowRateF3Peak?: number | null,
        flowRateF3Max?: number | null,
        pipingSizeMin?: number | null,
        pipingSizeMax?: number | null,
        currentIntoPlaneOperators?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryToFacility?:  {
      __typename: "ModelDeliveryToFacilityConnection",
      items:  Array< {
        __typename: "DeliveryToFacility",
        id: string,
        airportId?: string | null,
        provider?: string | null,
        organizationId?: string | null,
        method?: DeliveryMethodChoice | null,
        primary?: boolean | null,
        numberLines?: number | null,
        frequency?: DeliveryFrequencyChoice | null,
        frequencyCustomValue?: number | null,
        filtrationType?: FiltrationTypeChoice | null,
        filtrationTypeCustomValue?: string | null,
        filtrationDescription?: string | null,
        flowRateTypical?: number | null,
        flowRateMax?: number | null,
        pipingSize?: number | null,
        offloadLocations?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    storage?:  {
      __typename: "ModelStorageConnection",
      items:  Array< {
        __typename: "Storage",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        tankId: string,
        yearInstalled: string,
        product: Array< StorageProductChoice | null >,
        storageLocation: StorageLocationChoice,
        storageASTUST: StorageASTUSTChoice,
        nextAPI653?: string | null,
        storageFunction: Array< StorageFunctionChoice | null >,
        totalBBL?: number | null,
        usableBBL?: number | null,
        opStatus: StorageOpStatusChoice,
        security?: string | null,
        secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
        cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
        leakDetection?: string | null,
        createdAt: string,
        updatedAt: string,
        totalGals?: number | null,
        usableGals?: number | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAirportsId?: string | null,
    updatedAt: string,
  } | null,
};

export type CustomListCompaniesQueryVariables = {
  id?: string | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type CustomListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          mobile?: string | null,
          email: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CustomListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      updatedAt: string,
      lastLoginDate?: string | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        items:  Array< {
          __typename: "OrgAdminUser",
          id: string,
          organization:  {
            __typename: "Organization",
            id: string,
            name: string,
          },
        } | null >,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        items:  Array< {
          __typename: "OrgMemberUser",
          id: string,
          organization:  {
            __typename: "Organization",
            id: string,
            name: string,
          },
        } | null >,
      } | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        items:  Array< {
          __typename: "OrgSupportAdminUser",
          id: string,
          organization:  {
            __typename: "Organization",
            id: string,
            name: string,
          },
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomGetOrganizationQueryVariables = {
  id: string,
};

export type CustomGetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    organizationType?: OrganizationTypeChoice | null,
    groupType?: GroupTypeChoice | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorContactId?: string | null,
    operatorContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          email: string,
          id: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    supportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          email: string,
          id: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    airports?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        airport?:  {
          __typename: "Airport",
          code: string,
          name: string,
        } | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          email: string,
          id: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    calendar?:  {
      __typename: "ModelCalendarConnection",
      items:  Array< {
        __typename: "Calendar",
        id: string,
        organizationId?: string | null,
        calendarEventTypeId?: string | null,
        name: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        description?: string | null,
        website?: string | null,
        groupRateCode?: string | null,
        organizer?: string | null,
        specialRates?: boolean | null,
        venue?: string | null,
        venueWebSite?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCalendarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    companyOrganizationsId?: string | null,
    userOrganizationId?: string | null,
  } | null,
};

export type CustomGetCompanyQueryVariables = {
  id: string,
};

export type CustomGetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    type?: CompanyTypeChoice | null,
    organizations?:  {
      __typename: "ModelOrganizationConnection",
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        mobile?: string | null,
        deleted?: boolean | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCompaniesId?: string | null,
  } | null,
};

export type CustomListOrganizationsQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type CustomListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        items:  Array< {
          __typename: "OrgAdminUser",
          id: string,
          userId: string,
          organizationId: string,
          user:  {
            __typename: "User",
            id: string,
            firstName: string,
            lastName: string,
            mobile?: string | null,
            email: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        items:  Array< {
          __typename: "AirportConsortiumGroup",
          id: string,
          airport?:  {
            __typename: "Airport",
            id: string,
            name: string,
            code: string,
            oldCode?: string | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        items:  Array< {
          __typename: "Company",
          id: string,
          name: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        items:  Array< {
          __typename: "OrgMemberUser",
          id: string,
          userId: string,
          organizationId: string,
          user:  {
            __typename: "User",
            id: string,
            firstName: string,
            lastName: string,
            mobile?: string | null,
            email: string,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListInviteLogsQueryVariables = {
  id?: string | null,
  filter?: ModelInviteLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type CustomListInviteLogsQuery = {
  listInviteLogs?:  {
    __typename: "ModelInviteLogConnection",
    items:  Array< {
      __typename: "InviteLog",
      id: string,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      userId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        company?:  {
          __typename: "Company",
          id: string,
          name: string,
        } | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
        organizationSupportAdmin?:  {
          __typename: "ModelOrgSupportAdminUserConnection",
          items:  Array< {
            __typename: "OrgSupportAdminUser",
            id: string,
            organizationId: string,
            userId: string,
          } | null >,
        } | null,
      } | null,
      isAccepted?: boolean | null,
      type?: InviteType | null,
      createdAt?: string | null,
      acceptedAt?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CustomListAirportConsortiumGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelAirportConsortiumGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type CustomListAirportConsortiumGroupsQuery = {
  listAirportConsortiumGroups?:  {
    __typename: "ModelAirportConsortiumGroupConnection",
    items:  Array< {
      __typename: "AirportConsortiumGroup",
      id: string,
      organizationId: string,
      airportId: string,
      airport?:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
        admins?:  {
          __typename: "ModelOrgAdminUserConnection",
          items:  Array< {
            __typename: "OrgAdminUser",
            id: string,
            userId: string,
            organizationId: string,
            user:  {
              __typename: "User",
              id: string,
              firstName: string,
              lastName: string,
              mobile?: string | null,
              email: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        members?:  {
          __typename: "ModelOrgMemberUserConnection",
          items:  Array< {
            __typename: "OrgMemberUser",
            id: string,
            userId: string,
            organizationId: string,
            user:  {
              __typename: "User",
              id: string,
              firstName: string,
              lastName: string,
              mobile?: string | null,
              email: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        companies?:  {
          __typename: "ModelCompanyConnection",
          items:  Array< {
            __typename: "Company",
            id: string,
            name: string,
          } | null >,
          nextToken?: string | null,
        } | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContact?: string | null,
      facilityOwner?: string | null,
      facilityOwnerContact?: string | null,
      legalCounselContact?: string | null,
      legalCounsel?: string | null,
      facilityLocation?: FacilityLocationTypeChoice | null,
      totalCapacityDays?: number | null,
      totalCapacityGallons?: number | null,
      secured?: boolean | null,
      moExpiration?: string | null,
      moType?: MOTypeChoice | null,
      moTypeCustomValue?: string | null,
      leaseExpiration?: string | null,
      leaseType?: LeaseTypeChoice | null,
      leaseTypeCustomValue?: string | null,
      netInventoryCalculation?: string | null,
      nonMemberRate?: string | null,
      costSharingFormula?: string | null,
      reserveDepositPrebill?: ReservePrebillTypeChoice | null,
      thirdPartyDebt?: number | null,
      memberLoans?: number | null,
      dateLastPropertyAppraisal?: string | null,
      dateLastA4aOperationalReview?: string | null,
      entryFee?: string | null,
      aviationLiabilityLimit?: number | null,
      environmentalLiabilityLimit?: number | null,
      comments?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    organizationType?: OrganizationTypeChoice | null,
    groupType?: GroupTypeChoice | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorContactId?: string | null,
    operatorContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    airports?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    calendar?:  {
      __typename: "ModelCalendarConnection",
      items:  Array< {
        __typename: "Calendar",
        id: string,
        organizationId?: string | null,
        calendarEventTypeId?: string | null,
        name: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        description?: string | null,
        location?: string | null,
        website?: string | null,
        groupRateCode?: string | null,
        organizer?: string | null,
        specialRates?: boolean | null,
        venue?: string | null,
        venueWebSite?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        modifiedByUserId?: string | null,
        updatedAt: string,
        organizationCalendarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    supportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    updatedAt: string,
    companyOrganizationsId?: string | null,
    userOrganizationId?: string | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    organizationType?: OrganizationTypeChoice | null,
    groupType?: GroupTypeChoice | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorContactId?: string | null,
    operatorContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    airports?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    calendar?:  {
      __typename: "ModelCalendarConnection",
      items:  Array< {
        __typename: "Calendar",
        id: string,
        organizationId?: string | null,
        calendarEventTypeId?: string | null,
        name: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        description?: string | null,
        location?: string | null,
        website?: string | null,
        groupRateCode?: string | null,
        organizer?: string | null,
        specialRates?: boolean | null,
        venue?: string | null,
        venueWebSite?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        modifiedByUserId?: string | null,
        updatedAt: string,
        organizationCalendarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    supportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    updatedAt: string,
    companyOrganizationsId?: string | null,
    userOrganizationId?: string | null,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    organizationType?: OrganizationTypeChoice | null,
    groupType?: GroupTypeChoice | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorContactId?: string | null,
    operatorContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    airports?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    calendar?:  {
      __typename: "ModelCalendarConnection",
      items:  Array< {
        __typename: "Calendar",
        id: string,
        organizationId?: string | null,
        calendarEventTypeId?: string | null,
        name: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        description?: string | null,
        location?: string | null,
        website?: string | null,
        groupRateCode?: string | null,
        organizer?: string | null,
        specialRates?: boolean | null,
        venue?: string | null,
        venueWebSite?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        modifiedByUserId?: string | null,
        updatedAt: string,
        organizationCalendarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    supportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    updatedAt: string,
    companyOrganizationsId?: string | null,
    userOrganizationId?: string | null,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    type?: CompanyTypeChoice | null,
    organizations?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCompaniesId?: string | null,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    type?: CompanyTypeChoice | null,
    organizations?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCompaniesId?: string | null,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    type?: CompanyTypeChoice | null,
    organizations?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCompaniesId?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId?: string | null,
    termsAgree?: boolean | null,
    termsAgreeDate?: string | null,
    participationAgreement?: boolean | null,
    participationAgreementDate?: string | null,
    organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    roles?: Array< string | null > | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    office?: string | null,
    mobile?: string | null,
    linkedInUrl?: string | null,
    imageUrl?: string | null,
    timeZone?: string | null,
    monthlyUpdates?: boolean | null,
    generalAnnouncements?: boolean | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAdmin?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationMember?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    lastLoginDate?: string | null,
    weeklyDigestNotification?: boolean | null,
    monthlyDigestNotification?: boolean | null,
    quarterlyEmailNotification?: boolean | null,
    annualDigestNotification?: boolean | null,
    membershipAlertsNotification?: boolean | null,
    generalEmailNotification?: boolean | null,
    organizationSupportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    organizationContactsId?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId?: string | null,
    termsAgree?: boolean | null,
    termsAgreeDate?: string | null,
    participationAgreement?: boolean | null,
    participationAgreementDate?: string | null,
    organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    roles?: Array< string | null > | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    office?: string | null,
    mobile?: string | null,
    linkedInUrl?: string | null,
    imageUrl?: string | null,
    timeZone?: string | null,
    monthlyUpdates?: boolean | null,
    generalAnnouncements?: boolean | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAdmin?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationMember?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    lastLoginDate?: string | null,
    weeklyDigestNotification?: boolean | null,
    monthlyDigestNotification?: boolean | null,
    quarterlyEmailNotification?: boolean | null,
    annualDigestNotification?: boolean | null,
    membershipAlertsNotification?: boolean | null,
    generalEmailNotification?: boolean | null,
    organizationSupportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    organizationContactsId?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId?: string | null,
    termsAgree?: boolean | null,
    termsAgreeDate?: string | null,
    participationAgreement?: boolean | null,
    participationAgreementDate?: string | null,
    organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    roles?: Array< string | null > | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    office?: string | null,
    mobile?: string | null,
    linkedInUrl?: string | null,
    imageUrl?: string | null,
    timeZone?: string | null,
    monthlyUpdates?: boolean | null,
    generalAnnouncements?: boolean | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAdmin?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationMember?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    lastLoginDate?: string | null,
    weeklyDigestNotification?: boolean | null,
    monthlyDigestNotification?: boolean | null,
    quarterlyEmailNotification?: boolean | null,
    annualDigestNotification?: boolean | null,
    membershipAlertsNotification?: boolean | null,
    generalEmailNotification?: boolean | null,
    organizationSupportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    organizationContactsId?: string | null,
  } | null,
};

export type CreateDocumentMutationVariables = {
  input: CreateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "Document",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    categoryType?: DocumentCategoryTypeChoice | null,
    documentCategoryId?: string | null,
    documentCategory?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    bucketType?: DocumentBucketTypeChoice | null,
    title: string,
    description?: string | null,
    publishedByUserId?: string | null,
    publishedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    publishedDate?: string | null,
    url: string,
    fileSize?: number | null,
    fileType?:  {
      __typename: "FileType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    companyId?: string | null,
    createdAt?: string | null,
    createByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    categoryTypeId?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    documentCategoryDocumentsId?: string | null,
    documentFileTypeId?: string | null,
  } | null,
};

export type UpdateDocumentMutationVariables = {
  input: UpdateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type UpdateDocumentMutation = {
  updateDocument?:  {
    __typename: "Document",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    categoryType?: DocumentCategoryTypeChoice | null,
    documentCategoryId?: string | null,
    documentCategory?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    bucketType?: DocumentBucketTypeChoice | null,
    title: string,
    description?: string | null,
    publishedByUserId?: string | null,
    publishedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    publishedDate?: string | null,
    url: string,
    fileSize?: number | null,
    fileType?:  {
      __typename: "FileType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    companyId?: string | null,
    createdAt?: string | null,
    createByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    categoryTypeId?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    documentCategoryDocumentsId?: string | null,
    documentFileTypeId?: string | null,
  } | null,
};

export type DeleteDocumentMutationVariables = {
  input: DeleteDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type DeleteDocumentMutation = {
  deleteDocument?:  {
    __typename: "Document",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    categoryType?: DocumentCategoryTypeChoice | null,
    documentCategoryId?: string | null,
    documentCategory?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    bucketType?: DocumentBucketTypeChoice | null,
    title: string,
    description?: string | null,
    publishedByUserId?: string | null,
    publishedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    publishedDate?: string | null,
    url: string,
    fileSize?: number | null,
    fileType?:  {
      __typename: "FileType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    companyId?: string | null,
    createdAt?: string | null,
    createByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    categoryTypeId?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    documentCategoryDocumentsId?: string | null,
    documentFileTypeId?: string | null,
  } | null,
};

export type CreateFileTypeMutationVariables = {
  input: CreateFileTypeInput,
  condition?: ModelFileTypeConditionInput | null,
};

export type CreateFileTypeMutation = {
  createFileType?:  {
    __typename: "FileType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFileTypeMutationVariables = {
  input: UpdateFileTypeInput,
  condition?: ModelFileTypeConditionInput | null,
};

export type UpdateFileTypeMutation = {
  updateFileType?:  {
    __typename: "FileType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFileTypeMutationVariables = {
  input: DeleteFileTypeInput,
  condition?: ModelFileTypeConditionInput | null,
};

export type DeleteFileTypeMutation = {
  deleteFileType?:  {
    __typename: "FileType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDocumentCategoryMutationVariables = {
  input: CreateDocumentCategoryInput,
  condition?: ModelDocumentCategoryConditionInput | null,
};

export type CreateDocumentCategoryMutation = {
  createDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    name: string,
    type?: string | null,
    bucketType?: DocumentBucketTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type UpdateDocumentCategoryMutationVariables = {
  input: UpdateDocumentCategoryInput,
  condition?: ModelDocumentCategoryConditionInput | null,
};

export type UpdateDocumentCategoryMutation = {
  updateDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    name: string,
    type?: string | null,
    bucketType?: DocumentBucketTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type DeleteDocumentCategoryMutationVariables = {
  input: DeleteDocumentCategoryInput,
  condition?: ModelDocumentCategoryConditionInput | null,
};

export type DeleteDocumentCategoryMutation = {
  deleteDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    name: string,
    type?: string | null,
    bucketType?: DocumentBucketTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type CreateCalendarMutationVariables = {
  input: CreateCalendarInput,
  condition?: ModelCalendarConditionInput | null,
};

export type CreateCalendarMutation = {
  createCalendar?:  {
    __typename: "Calendar",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    calendarEventTypeId?: string | null,
    calendarEventType?:  {
      __typename: "CalendarEventType",
      id: string,
      name: string,
      description?: string | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    name: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    description?: string | null,
    location?: string | null,
    website?: string | null,
    groupRateCode?: string | null,
    organizer?: string | null,
    specialRates?: boolean | null,
    venue?: string | null,
    venueWebSite?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifiedByUserId?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCalendarId?: string | null,
  } | null,
};

export type UpdateCalendarMutationVariables = {
  input: UpdateCalendarInput,
  condition?: ModelCalendarConditionInput | null,
};

export type UpdateCalendarMutation = {
  updateCalendar?:  {
    __typename: "Calendar",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    calendarEventTypeId?: string | null,
    calendarEventType?:  {
      __typename: "CalendarEventType",
      id: string,
      name: string,
      description?: string | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    name: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    description?: string | null,
    location?: string | null,
    website?: string | null,
    groupRateCode?: string | null,
    organizer?: string | null,
    specialRates?: boolean | null,
    venue?: string | null,
    venueWebSite?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifiedByUserId?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCalendarId?: string | null,
  } | null,
};

export type DeleteCalendarMutationVariables = {
  input: DeleteCalendarInput,
  condition?: ModelCalendarConditionInput | null,
};

export type DeleteCalendarMutation = {
  deleteCalendar?:  {
    __typename: "Calendar",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    calendarEventTypeId?: string | null,
    calendarEventType?:  {
      __typename: "CalendarEventType",
      id: string,
      name: string,
      description?: string | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    name: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    description?: string | null,
    location?: string | null,
    website?: string | null,
    groupRateCode?: string | null,
    organizer?: string | null,
    specialRates?: boolean | null,
    venue?: string | null,
    venueWebSite?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifiedByUserId?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCalendarId?: string | null,
  } | null,
};

export type CreateCalendarEventTypeMutationVariables = {
  input: CreateCalendarEventTypeInput,
  condition?: ModelCalendarEventTypeConditionInput | null,
};

export type CreateCalendarEventTypeMutation = {
  createCalendarEventType?:  {
    __typename: "CalendarEventType",
    id: string,
    name: string,
    description?: string | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type UpdateCalendarEventTypeMutationVariables = {
  input: UpdateCalendarEventTypeInput,
  condition?: ModelCalendarEventTypeConditionInput | null,
};

export type UpdateCalendarEventTypeMutation = {
  updateCalendarEventType?:  {
    __typename: "CalendarEventType",
    id: string,
    name: string,
    description?: string | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type DeleteCalendarEventTypeMutationVariables = {
  input: DeleteCalendarEventTypeInput,
  condition?: ModelCalendarEventTypeConditionInput | null,
};

export type DeleteCalendarEventTypeMutation = {
  deleteCalendarEventType?:  {
    __typename: "CalendarEventType",
    id: string,
    name: string,
    description?: string | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type CreateAirportVolumeMutationVariables = {
  input: CreateAirportVolumeInput,
  condition?: ModelAirportVolumeConditionInput | null,
};

export type CreateAirportVolumeMutation = {
  createAirportVolume?:  {
    __typename: "AirportVolume",
    id: string,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    year?: number | null,
    volume?: number | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type UpdateAirportVolumeMutationVariables = {
  input: UpdateAirportVolumeInput,
  condition?: ModelAirportVolumeConditionInput | null,
};

export type UpdateAirportVolumeMutation = {
  updateAirportVolume?:  {
    __typename: "AirportVolume",
    id: string,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    year?: number | null,
    volume?: number | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type DeleteAirportVolumeMutationVariables = {
  input: DeleteAirportVolumeInput,
  condition?: ModelAirportVolumeConditionInput | null,
};

export type DeleteAirportVolumeMutation = {
  deleteAirportVolume?:  {
    __typename: "AirportVolume",
    id: string,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    year?: number | null,
    volume?: number | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type CreateAirportMutationVariables = {
  input: CreateAirportInput,
  condition?: ModelAirportConditionInput | null,
};

export type CreateAirportMutation = {
  createAirport?:  {
    __typename: "Airport",
    id: string,
    name: string,
    code: string,
    oldCode?: string | null,
    facility?: string | null,
    region?: AirportRegionChoice | null,
    state: string,
    place?:  {
      __typename: "Place",
      terminal?: string | null,
      gate?: string | null,
      lat?: string | null,
      lon?: string | null,
    } | null,
    consortiumGroup?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumType?: GroupTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContactId?: string | null,
    secondaryContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    facilityOwnerId?: string | null,
    facilityOwner?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    facilityOwnerContactId?: string | null,
    facilityOwnerContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    inventoryAccounting?: InventoryAccountingTypeChoice | null,
    deliveryToPlane?:  {
      __typename: "ModelDeliveryToPlaneConnection",
      items:  Array< {
        __typename: "DeliveryToPlane",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        airportDeliveryToPlaneId?: string | null,
        obFiltration?: boolean | null,
        hydrant?: HydrantChoice | null,
        closedLoop: ClosedLoopChoice,
        length?: number | null,
        hydrantLocations?: string | null,
        pits?: number | null,
        refuelerLocations?: string | null,
        efso?: boolean | null,
        efsoLocation?: string | null,
        leakDetection?: boolean | null,
        leakDetectionDescription?: string | null,
        flowRateF1Peak?: number | null,
        flowRateF1Max?: number | null,
        flowRateF2Peak?: number | null,
        flowRateF2Max?: number | null,
        flowRateF3Peak?: number | null,
        flowRateF3Max?: number | null,
        pipingSizeMin?: number | null,
        pipingSizeMax?: number | null,
        currentIntoPlaneOperators?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryToFacility?:  {
      __typename: "ModelDeliveryToFacilityConnection",
      items:  Array< {
        __typename: "DeliveryToFacility",
        id: string,
        airportId?: string | null,
        provider?: string | null,
        organizationId?: string | null,
        method?: DeliveryMethodChoice | null,
        primary?: boolean | null,
        numberLines?: number | null,
        frequency?: DeliveryFrequencyChoice | null,
        frequencyCustomValue?: number | null,
        filtrationType?: FiltrationTypeChoice | null,
        filtrationTypeCustomValue?: string | null,
        filtrationDescription?: string | null,
        flowRateTypical?: number | null,
        flowRateMax?: number | null,
        pipingSize?: number | null,
        offloadLocations?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    storage?:  {
      __typename: "ModelStorageConnection",
      items:  Array< {
        __typename: "Storage",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        tankId: string,
        yearInstalled: string,
        product: Array< StorageProductChoice | null >,
        storageLocation: StorageLocationChoice,
        storageASTUST: StorageASTUSTChoice,
        nextAPI653?: string | null,
        storageFunction: Array< StorageFunctionChoice | null >,
        totalBBL?: number | null,
        usableBBL?: number | null,
        opStatus: StorageOpStatusChoice,
        owner?: string | null,
        security?: string | null,
        secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
        cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
        leakDetection?: string | null,
        totalGals?: number | null,
        usableGals?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAirportsId?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type UpdateAirportMutationVariables = {
  input: UpdateAirportInput,
  condition?: ModelAirportConditionInput | null,
};

export type UpdateAirportMutation = {
  updateAirport?:  {
    __typename: "Airport",
    id: string,
    name: string,
    code: string,
    oldCode?: string | null,
    facility?: string | null,
    region?: AirportRegionChoice | null,
    state: string,
    place?:  {
      __typename: "Place",
      terminal?: string | null,
      gate?: string | null,
      lat?: string | null,
      lon?: string | null,
    } | null,
    consortiumGroup?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumType?: GroupTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContactId?: string | null,
    secondaryContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    facilityOwnerId?: string | null,
    facilityOwner?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    facilityOwnerContactId?: string | null,
    facilityOwnerContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    inventoryAccounting?: InventoryAccountingTypeChoice | null,
    deliveryToPlane?:  {
      __typename: "ModelDeliveryToPlaneConnection",
      items:  Array< {
        __typename: "DeliveryToPlane",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        airportDeliveryToPlaneId?: string | null,
        obFiltration?: boolean | null,
        hydrant?: HydrantChoice | null,
        closedLoop: ClosedLoopChoice,
        length?: number | null,
        hydrantLocations?: string | null,
        pits?: number | null,
        refuelerLocations?: string | null,
        efso?: boolean | null,
        efsoLocation?: string | null,
        leakDetection?: boolean | null,
        leakDetectionDescription?: string | null,
        flowRateF1Peak?: number | null,
        flowRateF1Max?: number | null,
        flowRateF2Peak?: number | null,
        flowRateF2Max?: number | null,
        flowRateF3Peak?: number | null,
        flowRateF3Max?: number | null,
        pipingSizeMin?: number | null,
        pipingSizeMax?: number | null,
        currentIntoPlaneOperators?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryToFacility?:  {
      __typename: "ModelDeliveryToFacilityConnection",
      items:  Array< {
        __typename: "DeliveryToFacility",
        id: string,
        airportId?: string | null,
        provider?: string | null,
        organizationId?: string | null,
        method?: DeliveryMethodChoice | null,
        primary?: boolean | null,
        numberLines?: number | null,
        frequency?: DeliveryFrequencyChoice | null,
        frequencyCustomValue?: number | null,
        filtrationType?: FiltrationTypeChoice | null,
        filtrationTypeCustomValue?: string | null,
        filtrationDescription?: string | null,
        flowRateTypical?: number | null,
        flowRateMax?: number | null,
        pipingSize?: number | null,
        offloadLocations?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    storage?:  {
      __typename: "ModelStorageConnection",
      items:  Array< {
        __typename: "Storage",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        tankId: string,
        yearInstalled: string,
        product: Array< StorageProductChoice | null >,
        storageLocation: StorageLocationChoice,
        storageASTUST: StorageASTUSTChoice,
        nextAPI653?: string | null,
        storageFunction: Array< StorageFunctionChoice | null >,
        totalBBL?: number | null,
        usableBBL?: number | null,
        opStatus: StorageOpStatusChoice,
        owner?: string | null,
        security?: string | null,
        secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
        cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
        leakDetection?: string | null,
        totalGals?: number | null,
        usableGals?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAirportsId?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type DeleteAirportMutationVariables = {
  input: DeleteAirportInput,
  condition?: ModelAirportConditionInput | null,
};

export type DeleteAirportMutation = {
  deleteAirport?:  {
    __typename: "Airport",
    id: string,
    name: string,
    code: string,
    oldCode?: string | null,
    facility?: string | null,
    region?: AirportRegionChoice | null,
    state: string,
    place?:  {
      __typename: "Place",
      terminal?: string | null,
      gate?: string | null,
      lat?: string | null,
      lon?: string | null,
    } | null,
    consortiumGroup?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumType?: GroupTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContactId?: string | null,
    secondaryContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    facilityOwnerId?: string | null,
    facilityOwner?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    facilityOwnerContactId?: string | null,
    facilityOwnerContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    inventoryAccounting?: InventoryAccountingTypeChoice | null,
    deliveryToPlane?:  {
      __typename: "ModelDeliveryToPlaneConnection",
      items:  Array< {
        __typename: "DeliveryToPlane",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        airportDeliveryToPlaneId?: string | null,
        obFiltration?: boolean | null,
        hydrant?: HydrantChoice | null,
        closedLoop: ClosedLoopChoice,
        length?: number | null,
        hydrantLocations?: string | null,
        pits?: number | null,
        refuelerLocations?: string | null,
        efso?: boolean | null,
        efsoLocation?: string | null,
        leakDetection?: boolean | null,
        leakDetectionDescription?: string | null,
        flowRateF1Peak?: number | null,
        flowRateF1Max?: number | null,
        flowRateF2Peak?: number | null,
        flowRateF2Max?: number | null,
        flowRateF3Peak?: number | null,
        flowRateF3Max?: number | null,
        pipingSizeMin?: number | null,
        pipingSizeMax?: number | null,
        currentIntoPlaneOperators?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryToFacility?:  {
      __typename: "ModelDeliveryToFacilityConnection",
      items:  Array< {
        __typename: "DeliveryToFacility",
        id: string,
        airportId?: string | null,
        provider?: string | null,
        organizationId?: string | null,
        method?: DeliveryMethodChoice | null,
        primary?: boolean | null,
        numberLines?: number | null,
        frequency?: DeliveryFrequencyChoice | null,
        frequencyCustomValue?: number | null,
        filtrationType?: FiltrationTypeChoice | null,
        filtrationTypeCustomValue?: string | null,
        filtrationDescription?: string | null,
        flowRateTypical?: number | null,
        flowRateMax?: number | null,
        pipingSize?: number | null,
        offloadLocations?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    storage?:  {
      __typename: "ModelStorageConnection",
      items:  Array< {
        __typename: "Storage",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        tankId: string,
        yearInstalled: string,
        product: Array< StorageProductChoice | null >,
        storageLocation: StorageLocationChoice,
        storageASTUST: StorageASTUSTChoice,
        nextAPI653?: string | null,
        storageFunction: Array< StorageFunctionChoice | null >,
        totalBBL?: number | null,
        usableBBL?: number | null,
        opStatus: StorageOpStatusChoice,
        owner?: string | null,
        security?: string | null,
        secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
        cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
        leakDetection?: string | null,
        totalGals?: number | null,
        usableGals?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAirportsId?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type CreateAirportConsortiumGroupMutationVariables = {
  input: CreateAirportConsortiumGroupInput,
  condition?: ModelAirportConsortiumGroupConditionInput | null,
};

export type CreateAirportConsortiumGroupMutation = {
  createAirportConsortiumGroup?:  {
    __typename: "AirportConsortiumGroup",
    id: string,
    organizationId: string,
    airportId: string,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContact?: string | null,
    facilityOwner?: string | null,
    facilityOwnerContact?: string | null,
    legalCounselContact?: string | null,
    legalCounsel?: string | null,
    facilityLocation?: FacilityLocationTypeChoice | null,
    totalCapacityDays?: number | null,
    totalCapacityGallons?: number | null,
    secured?: boolean | null,
    moExpiration?: string | null,
    moType?: MOTypeChoice | null,
    moTypeCustomValue?: string | null,
    leaseExpiration?: string | null,
    leaseType?: LeaseTypeChoice | null,
    leaseTypeCustomValue?: string | null,
    dateLastFinancialSUP?: string | null,
    dateLastA4aOperationalReview?: string | null,
    netInventoryCalculation?: string | null,
    entryFee?: string | null,
    nonMemberRate?: string | null,
    costSharingFormula?: string | null,
    reserveDepositPrebill?: ReservePrebillTypeChoice | null,
    thirdPartyDebt?: number | null,
    memberLoans?: number | null,
    dateLastPropertyAppraisal?: string | null,
    aviationLiabilityLimit?: number | null,
    environmentalLiabilityLimit?: number | null,
    comments?: string | null,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAirportConsortiumGroupMutationVariables = {
  input: UpdateAirportConsortiumGroupInput,
  condition?: ModelAirportConsortiumGroupConditionInput | null,
};

export type UpdateAirportConsortiumGroupMutation = {
  updateAirportConsortiumGroup?:  {
    __typename: "AirportConsortiumGroup",
    id: string,
    organizationId: string,
    airportId: string,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContact?: string | null,
    facilityOwner?: string | null,
    facilityOwnerContact?: string | null,
    legalCounselContact?: string | null,
    legalCounsel?: string | null,
    facilityLocation?: FacilityLocationTypeChoice | null,
    totalCapacityDays?: number | null,
    totalCapacityGallons?: number | null,
    secured?: boolean | null,
    moExpiration?: string | null,
    moType?: MOTypeChoice | null,
    moTypeCustomValue?: string | null,
    leaseExpiration?: string | null,
    leaseType?: LeaseTypeChoice | null,
    leaseTypeCustomValue?: string | null,
    dateLastFinancialSUP?: string | null,
    dateLastA4aOperationalReview?: string | null,
    netInventoryCalculation?: string | null,
    entryFee?: string | null,
    nonMemberRate?: string | null,
    costSharingFormula?: string | null,
    reserveDepositPrebill?: ReservePrebillTypeChoice | null,
    thirdPartyDebt?: number | null,
    memberLoans?: number | null,
    dateLastPropertyAppraisal?: string | null,
    aviationLiabilityLimit?: number | null,
    environmentalLiabilityLimit?: number | null,
    comments?: string | null,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAirportConsortiumGroupMutationVariables = {
  input: DeleteAirportConsortiumGroupInput,
  condition?: ModelAirportConsortiumGroupConditionInput | null,
};

export type DeleteAirportConsortiumGroupMutation = {
  deleteAirportConsortiumGroup?:  {
    __typename: "AirportConsortiumGroup",
    id: string,
    organizationId: string,
    airportId: string,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContact?: string | null,
    facilityOwner?: string | null,
    facilityOwnerContact?: string | null,
    legalCounselContact?: string | null,
    legalCounsel?: string | null,
    facilityLocation?: FacilityLocationTypeChoice | null,
    totalCapacityDays?: number | null,
    totalCapacityGallons?: number | null,
    secured?: boolean | null,
    moExpiration?: string | null,
    moType?: MOTypeChoice | null,
    moTypeCustomValue?: string | null,
    leaseExpiration?: string | null,
    leaseType?: LeaseTypeChoice | null,
    leaseTypeCustomValue?: string | null,
    dateLastFinancialSUP?: string | null,
    dateLastA4aOperationalReview?: string | null,
    netInventoryCalculation?: string | null,
    entryFee?: string | null,
    nonMemberRate?: string | null,
    costSharingFormula?: string | null,
    reserveDepositPrebill?: ReservePrebillTypeChoice | null,
    thirdPartyDebt?: number | null,
    memberLoans?: number | null,
    dateLastPropertyAppraisal?: string | null,
    aviationLiabilityLimit?: number | null,
    environmentalLiabilityLimit?: number | null,
    comments?: string | null,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeliveryToPlaneMutationVariables = {
  input: CreateDeliveryToPlaneInput,
  condition?: ModelDeliveryToPlaneConditionInput | null,
};

export type CreateDeliveryToPlaneMutation = {
  createDeliveryToPlane?:  {
    __typename: "DeliveryToPlane",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportDeliveryToPlaneId?: string | null,
    obFiltration?: boolean | null,
    hydrant?: HydrantChoice | null,
    closedLoop: ClosedLoopChoice,
    length?: number | null,
    hydrantLocations?: string | null,
    pits?: number | null,
    refuelerLocations?: string | null,
    efso?: boolean | null,
    efsoLocation?: string | null,
    leakDetection?: boolean | null,
    leakDetectionDescription?: string | null,
    flowRateF1Peak?: number | null,
    flowRateF1Max?: number | null,
    flowRateF2Peak?: number | null,
    flowRateF2Max?: number | null,
    flowRateF3Peak?: number | null,
    flowRateF3Max?: number | null,
    pipingSizeMin?: number | null,
    pipingSizeMax?: number | null,
    currentIntoPlaneOperators?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeliveryToPlaneMutationVariables = {
  input: UpdateDeliveryToPlaneInput,
  condition?: ModelDeliveryToPlaneConditionInput | null,
};

export type UpdateDeliveryToPlaneMutation = {
  updateDeliveryToPlane?:  {
    __typename: "DeliveryToPlane",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportDeliveryToPlaneId?: string | null,
    obFiltration?: boolean | null,
    hydrant?: HydrantChoice | null,
    closedLoop: ClosedLoopChoice,
    length?: number | null,
    hydrantLocations?: string | null,
    pits?: number | null,
    refuelerLocations?: string | null,
    efso?: boolean | null,
    efsoLocation?: string | null,
    leakDetection?: boolean | null,
    leakDetectionDescription?: string | null,
    flowRateF1Peak?: number | null,
    flowRateF1Max?: number | null,
    flowRateF2Peak?: number | null,
    flowRateF2Max?: number | null,
    flowRateF3Peak?: number | null,
    flowRateF3Max?: number | null,
    pipingSizeMin?: number | null,
    pipingSizeMax?: number | null,
    currentIntoPlaneOperators?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeliveryToPlaneMutationVariables = {
  input: DeleteDeliveryToPlaneInput,
  condition?: ModelDeliveryToPlaneConditionInput | null,
};

export type DeleteDeliveryToPlaneMutation = {
  deleteDeliveryToPlane?:  {
    __typename: "DeliveryToPlane",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportDeliveryToPlaneId?: string | null,
    obFiltration?: boolean | null,
    hydrant?: HydrantChoice | null,
    closedLoop: ClosedLoopChoice,
    length?: number | null,
    hydrantLocations?: string | null,
    pits?: number | null,
    refuelerLocations?: string | null,
    efso?: boolean | null,
    efsoLocation?: string | null,
    leakDetection?: boolean | null,
    leakDetectionDescription?: string | null,
    flowRateF1Peak?: number | null,
    flowRateF1Max?: number | null,
    flowRateF2Peak?: number | null,
    flowRateF2Max?: number | null,
    flowRateF3Peak?: number | null,
    flowRateF3Max?: number | null,
    pipingSizeMin?: number | null,
    pipingSizeMax?: number | null,
    currentIntoPlaneOperators?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeliveryToFacilityMutationVariables = {
  input: CreateDeliveryToFacilityInput,
  condition?: ModelDeliveryToFacilityConditionInput | null,
};

export type CreateDeliveryToFacilityMutation = {
  createDeliveryToFacility?:  {
    __typename: "DeliveryToFacility",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    provider?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    method?: DeliveryMethodChoice | null,
    primary?: boolean | null,
    numberLines?: number | null,
    frequency?: DeliveryFrequencyChoice | null,
    frequencyCustomValue?: number | null,
    filtrationType?: FiltrationTypeChoice | null,
    filtrationTypeCustomValue?: string | null,
    filtrationDescription?: string | null,
    flowRateTypical?: number | null,
    flowRateMax?: number | null,
    pipingSize?: number | null,
    offloadLocations?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeliveryToFacilityMutationVariables = {
  input: UpdateDeliveryToFacilityInput,
  condition?: ModelDeliveryToFacilityConditionInput | null,
};

export type UpdateDeliveryToFacilityMutation = {
  updateDeliveryToFacility?:  {
    __typename: "DeliveryToFacility",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    provider?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    method?: DeliveryMethodChoice | null,
    primary?: boolean | null,
    numberLines?: number | null,
    frequency?: DeliveryFrequencyChoice | null,
    frequencyCustomValue?: number | null,
    filtrationType?: FiltrationTypeChoice | null,
    filtrationTypeCustomValue?: string | null,
    filtrationDescription?: string | null,
    flowRateTypical?: number | null,
    flowRateMax?: number | null,
    pipingSize?: number | null,
    offloadLocations?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeliveryToFacilityMutationVariables = {
  input: DeleteDeliveryToFacilityInput,
  condition?: ModelDeliveryToFacilityConditionInput | null,
};

export type DeleteDeliveryToFacilityMutation = {
  deleteDeliveryToFacility?:  {
    __typename: "DeliveryToFacility",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    provider?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    method?: DeliveryMethodChoice | null,
    primary?: boolean | null,
    numberLines?: number | null,
    frequency?: DeliveryFrequencyChoice | null,
    frequencyCustomValue?: number | null,
    filtrationType?: FiltrationTypeChoice | null,
    filtrationTypeCustomValue?: string | null,
    filtrationDescription?: string | null,
    flowRateTypical?: number | null,
    flowRateMax?: number | null,
    pipingSize?: number | null,
    offloadLocations?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateStorageMutationVariables = {
  input: CreateStorageInput,
  condition?: ModelStorageConditionInput | null,
};

export type CreateStorageMutation = {
  createStorage?:  {
    __typename: "Storage",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    tankId: string,
    yearInstalled: string,
    product: Array< StorageProductChoice | null >,
    storageLocation: StorageLocationChoice,
    storageASTUST: StorageASTUSTChoice,
    nextAPI653?: string | null,
    storageFunction: Array< StorageFunctionChoice | null >,
    totalBBL?: number | null,
    usableBBL?: number | null,
    opStatus: StorageOpStatusChoice,
    owner?: string | null,
    security?: string | null,
    secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
    cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
    leakDetection?: string | null,
    totalGals?: number | null,
    usableGals?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateStorageMutationVariables = {
  input: UpdateStorageInput,
  condition?: ModelStorageConditionInput | null,
};

export type UpdateStorageMutation = {
  updateStorage?:  {
    __typename: "Storage",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    tankId: string,
    yearInstalled: string,
    product: Array< StorageProductChoice | null >,
    storageLocation: StorageLocationChoice,
    storageASTUST: StorageASTUSTChoice,
    nextAPI653?: string | null,
    storageFunction: Array< StorageFunctionChoice | null >,
    totalBBL?: number | null,
    usableBBL?: number | null,
    opStatus: StorageOpStatusChoice,
    owner?: string | null,
    security?: string | null,
    secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
    cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
    leakDetection?: string | null,
    totalGals?: number | null,
    usableGals?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteStorageMutationVariables = {
  input: DeleteStorageInput,
  condition?: ModelStorageConditionInput | null,
};

export type DeleteStorageMutation = {
  deleteStorage?:  {
    __typename: "Storage",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    tankId: string,
    yearInstalled: string,
    product: Array< StorageProductChoice | null >,
    storageLocation: StorageLocationChoice,
    storageASTUST: StorageASTUSTChoice,
    nextAPI653?: string | null,
    storageFunction: Array< StorageFunctionChoice | null >,
    totalBBL?: number | null,
    usableBBL?: number | null,
    opStatus: StorageOpStatusChoice,
    owner?: string | null,
    security?: string | null,
    secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
    cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
    leakDetection?: string | null,
    totalGals?: number | null,
    usableGals?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id?: string | null,
    type?: NotificationType | null,
    method?: NotificationMethod | null,
    scope?:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null > | null,
    notificationDate?: string | null,
    title?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id?: string | null,
    type?: NotificationType | null,
    method?: NotificationMethod | null,
    scope?:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null > | null,
    notificationDate?: string | null,
    title?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id?: string | null,
    type?: NotificationType | null,
    method?: NotificationMethod | null,
    scope?:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null > | null,
    notificationDate?: string | null,
    title?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateModifyRecordMutationVariables = {
  input: CreateModifyRecordInput,
  condition?: ModelModifyRecordConditionInput | null,
};

export type CreateModifyRecordMutation = {
  createModifyRecord?:  {
    __typename: "ModifyRecord",
    fieldName?: string | null,
    fieldValue?: string | null,
    dataOperation?: DataOperation | null,
    modifiedAt?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateModifyRecordMutationVariables = {
  input: UpdateModifyRecordInput,
  condition?: ModelModifyRecordConditionInput | null,
};

export type UpdateModifyRecordMutation = {
  updateModifyRecord?:  {
    __typename: "ModifyRecord",
    fieldName?: string | null,
    fieldValue?: string | null,
    dataOperation?: DataOperation | null,
    modifiedAt?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteModifyRecordMutationVariables = {
  input: DeleteModifyRecordInput,
  condition?: ModelModifyRecordConditionInput | null,
};

export type DeleteModifyRecordMutation = {
  deleteModifyRecord?:  {
    __typename: "ModifyRecord",
    fieldName?: string | null,
    fieldValue?: string | null,
    dataOperation?: DataOperation | null,
    modifiedAt?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInviteLogMutationVariables = {
  input: CreateInviteLogInput,
  condition?: ModelInviteLogConditionInput | null,
};

export type CreateInviteLogMutation = {
  createInviteLog?:  {
    __typename: "InviteLog",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    isAccepted?: boolean | null,
    type?: InviteType | null,
    sendByUserId?: string | null,
    sendBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    createdAt?: string | null,
    acceptedAt?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type UpdateInviteLogMutationVariables = {
  input: UpdateInviteLogInput,
  condition?: ModelInviteLogConditionInput | null,
};

export type UpdateInviteLogMutation = {
  updateInviteLog?:  {
    __typename: "InviteLog",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    isAccepted?: boolean | null,
    type?: InviteType | null,
    sendByUserId?: string | null,
    sendBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    createdAt?: string | null,
    acceptedAt?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type DeleteInviteLogMutationVariables = {
  input: DeleteInviteLogInput,
  condition?: ModelInviteLogConditionInput | null,
};

export type DeleteInviteLogMutation = {
  deleteInviteLog?:  {
    __typename: "InviteLog",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    isAccepted?: boolean | null,
    type?: InviteType | null,
    sendByUserId?: string | null,
    sendBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    createdAt?: string | null,
    acceptedAt?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type CreateOrgAdminUserMutationVariables = {
  input: CreateOrgAdminUserInput,
  condition?: ModelOrgAdminUserConditionInput | null,
};

export type CreateOrgAdminUserMutation = {
  createOrgAdminUser?:  {
    __typename: "OrgAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrgAdminUserMutationVariables = {
  input: UpdateOrgAdminUserInput,
  condition?: ModelOrgAdminUserConditionInput | null,
};

export type UpdateOrgAdminUserMutation = {
  updateOrgAdminUser?:  {
    __typename: "OrgAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrgAdminUserMutationVariables = {
  input: DeleteOrgAdminUserInput,
  condition?: ModelOrgAdminUserConditionInput | null,
};

export type DeleteOrgAdminUserMutation = {
  deleteOrgAdminUser?:  {
    __typename: "OrgAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrgMemberUserMutationVariables = {
  input: CreateOrgMemberUserInput,
  condition?: ModelOrgMemberUserConditionInput | null,
};

export type CreateOrgMemberUserMutation = {
  createOrgMemberUser?:  {
    __typename: "OrgMemberUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrgMemberUserMutationVariables = {
  input: UpdateOrgMemberUserInput,
  condition?: ModelOrgMemberUserConditionInput | null,
};

export type UpdateOrgMemberUserMutation = {
  updateOrgMemberUser?:  {
    __typename: "OrgMemberUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrgMemberUserMutationVariables = {
  input: DeleteOrgMemberUserInput,
  condition?: ModelOrgMemberUserConditionInput | null,
};

export type DeleteOrgMemberUserMutation = {
  deleteOrgMemberUser?:  {
    __typename: "OrgMemberUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateOrgSupportAdminUserMutationVariables = {
  input: CreateOrgSupportAdminUserInput,
  condition?: ModelOrgSupportAdminUserConditionInput | null,
};

export type CreateOrgSupportAdminUserMutation = {
  createOrgSupportAdminUser?:  {
    __typename: "OrgSupportAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateOrgSupportAdminUserMutationVariables = {
  input: UpdateOrgSupportAdminUserInput,
  condition?: ModelOrgSupportAdminUserConditionInput | null,
};

export type UpdateOrgSupportAdminUserMutation = {
  updateOrgSupportAdminUser?:  {
    __typename: "OrgSupportAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrgSupportAdminUserMutationVariables = {
  input: DeleteOrgSupportAdminUserInput,
  condition?: ModelOrgSupportAdminUserConditionInput | null,
};

export type DeleteOrgSupportAdminUserMutation = {
  deleteOrgSupportAdminUser?:  {
    __typename: "OrgSupportAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    organizationType?: OrganizationTypeChoice | null,
    groupType?: GroupTypeChoice | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorContactId?: string | null,
    operatorContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    airports?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    calendar?:  {
      __typename: "ModelCalendarConnection",
      items:  Array< {
        __typename: "Calendar",
        id: string,
        organizationId?: string | null,
        calendarEventTypeId?: string | null,
        name: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        description?: string | null,
        location?: string | null,
        website?: string | null,
        groupRateCode?: string | null,
        organizer?: string | null,
        specialRates?: boolean | null,
        venue?: string | null,
        venueWebSite?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        modifiedByUserId?: string | null,
        updatedAt: string,
        organizationCalendarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    supportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    updatedAt: string,
    companyOrganizationsId?: string | null,
    userOrganizationId?: string | null,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  id?: string | null,
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    type?: CompanyTypeChoice | null,
    organizations?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCompaniesId?: string | null,
  } | null,
};

export type ListCompaniesQueryVariables = {
  id?: string | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCompaniesQuery = {
  listCompanies?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId?: string | null,
    termsAgree?: boolean | null,
    termsAgreeDate?: string | null,
    participationAgreement?: boolean | null,
    participationAgreementDate?: string | null,
    organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    roles?: Array< string | null > | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    office?: string | null,
    mobile?: string | null,
    linkedInUrl?: string | null,
    imageUrl?: string | null,
    timeZone?: string | null,
    monthlyUpdates?: boolean | null,
    generalAnnouncements?: boolean | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAdmin?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationMember?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    lastLoginDate?: string | null,
    weeklyDigestNotification?: boolean | null,
    monthlyDigestNotification?: boolean | null,
    quarterlyEmailNotification?: boolean | null,
    annualDigestNotification?: boolean | null,
    membershipAlertsNotification?: boolean | null,
    generalEmailNotification?: boolean | null,
    organizationSupportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    organizationContactsId?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentQueryVariables = {
  id: string,
};

export type GetDocumentQuery = {
  getDocument?:  {
    __typename: "Document",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    categoryType?: DocumentCategoryTypeChoice | null,
    documentCategoryId?: string | null,
    documentCategory?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    bucketType?: DocumentBucketTypeChoice | null,
    title: string,
    description?: string | null,
    publishedByUserId?: string | null,
    publishedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    publishedDate?: string | null,
    url: string,
    fileSize?: number | null,
    fileType?:  {
      __typename: "FileType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    companyId?: string | null,
    createdAt?: string | null,
    createByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    categoryTypeId?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    documentCategoryDocumentsId?: string | null,
    documentFileTypeId?: string | null,
  } | null,
};

export type ListDocumentsQueryVariables = {
  id?: string | null,
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDocumentsQuery = {
  listDocuments?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      airportId?: string | null,
      airport?:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      categoryType?: DocumentCategoryTypeChoice | null,
      documentCategoryId?: string | null,
      documentCategory?:  {
        __typename: "DocumentCategory",
        id: string,
        name: string,
        type?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        description?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      bucketType?: DocumentBucketTypeChoice | null,
      title: string,
      description?: string | null,
      publishedByUserId?: string | null,
      publishedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      publishedDate?: string | null,
      url: string,
      fileSize?: number | null,
      fileType?:  {
        __typename: "FileType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      companyId?: string | null,
      createdAt?: string | null,
      createByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      categoryTypeId?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        name: string,
        type?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        description?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationDocumentsId?: string | null,
      documentCategoryDocumentsId?: string | null,
      documentFileTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFileTypeQueryVariables = {
  id: string,
};

export type GetFileTypeQuery = {
  getFileType?:  {
    __typename: "FileType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFileTypesQueryVariables = {
  filter?: ModelFileTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFileTypesQuery = {
  listFileTypes?:  {
    __typename: "ModelFileTypeConnection",
    items:  Array< {
      __typename: "FileType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentCategoryQueryVariables = {
  id: string,
};

export type GetDocumentCategoryQuery = {
  getDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    name: string,
    type?: string | null,
    bucketType?: DocumentBucketTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type ListDocumentCategoriesQueryVariables = {
  id?: string | null,
  filter?: ModelDocumentCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDocumentCategoriesQuery = {
  listDocumentCategories?:  {
    __typename: "ModelDocumentCategoryConnection",
    items:  Array< {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCalendarQueryVariables = {
  id: string,
};

export type GetCalendarQuery = {
  getCalendar?:  {
    __typename: "Calendar",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    calendarEventTypeId?: string | null,
    calendarEventType?:  {
      __typename: "CalendarEventType",
      id: string,
      name: string,
      description?: string | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    name: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    description?: string | null,
    location?: string | null,
    website?: string | null,
    groupRateCode?: string | null,
    organizer?: string | null,
    specialRates?: boolean | null,
    venue?: string | null,
    venueWebSite?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifiedByUserId?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCalendarId?: string | null,
  } | null,
};

export type ListCalendarsQueryVariables = {
  id?: string | null,
  filter?: ModelCalendarFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCalendarsQuery = {
  listCalendars?:  {
    __typename: "ModelCalendarConnection",
    items:  Array< {
      __typename: "Calendar",
      id: string,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      calendarEventTypeId?: string | null,
      calendarEventType?:  {
        __typename: "CalendarEventType",
        id: string,
        name: string,
        description?: string | null,
        createdAt?: string | null,
        updatedAt: string,
      } | null,
      name: string,
      startDateTime?: string | null,
      endDateTime?: string | null,
      description?: string | null,
      location?: string | null,
      website?: string | null,
      groupRateCode?: string | null,
      organizer?: string | null,
      specialRates?: boolean | null,
      venue?: string | null,
      venueWebSite?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifiedByUserId?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCalendarId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCalendarEventTypeQueryVariables = {
  id: string,
};

export type GetCalendarEventTypeQuery = {
  getCalendarEventType?:  {
    __typename: "CalendarEventType",
    id: string,
    name: string,
    description?: string | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type ListCalendarEventTypesQueryVariables = {
  id?: string | null,
  filter?: ModelCalendarEventTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCalendarEventTypesQuery = {
  listCalendarEventTypes?:  {
    __typename: "ModelCalendarEventTypeConnection",
    items:  Array< {
      __typename: "CalendarEventType",
      id: string,
      name: string,
      description?: string | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAirportVolumeQueryVariables = {
  id: string,
};

export type GetAirportVolumeQuery = {
  getAirportVolume?:  {
    __typename: "AirportVolume",
    id: string,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    year?: number | null,
    volume?: number | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type ListAirportVolumesQueryVariables = {
  id?: string | null,
  filter?: ModelAirportVolumeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAirportVolumesQuery = {
  listAirportVolumes?:  {
    __typename: "ModelAirportVolumeConnection",
    items:  Array< {
      __typename: "AirportVolume",
      id: string,
      airportId?: string | null,
      airport?:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      year?: number | null,
      volume?: number | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAirportQueryVariables = {
  id: string,
};

export type GetAirportQuery = {
  getAirport?:  {
    __typename: "Airport",
    id: string,
    name: string,
    code: string,
    oldCode?: string | null,
    facility?: string | null,
    region?: AirportRegionChoice | null,
    state: string,
    place?:  {
      __typename: "Place",
      terminal?: string | null,
      gate?: string | null,
      lat?: string | null,
      lon?: string | null,
    } | null,
    consortiumGroup?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumType?: GroupTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContactId?: string | null,
    secondaryContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    facilityOwnerId?: string | null,
    facilityOwner?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    facilityOwnerContactId?: string | null,
    facilityOwnerContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    inventoryAccounting?: InventoryAccountingTypeChoice | null,
    deliveryToPlane?:  {
      __typename: "ModelDeliveryToPlaneConnection",
      items:  Array< {
        __typename: "DeliveryToPlane",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        airportDeliveryToPlaneId?: string | null,
        obFiltration?: boolean | null,
        hydrant?: HydrantChoice | null,
        closedLoop: ClosedLoopChoice,
        length?: number | null,
        hydrantLocations?: string | null,
        pits?: number | null,
        refuelerLocations?: string | null,
        efso?: boolean | null,
        efsoLocation?: string | null,
        leakDetection?: boolean | null,
        leakDetectionDescription?: string | null,
        flowRateF1Peak?: number | null,
        flowRateF1Max?: number | null,
        flowRateF2Peak?: number | null,
        flowRateF2Max?: number | null,
        flowRateF3Peak?: number | null,
        flowRateF3Max?: number | null,
        pipingSizeMin?: number | null,
        pipingSizeMax?: number | null,
        currentIntoPlaneOperators?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryToFacility?:  {
      __typename: "ModelDeliveryToFacilityConnection",
      items:  Array< {
        __typename: "DeliveryToFacility",
        id: string,
        airportId?: string | null,
        provider?: string | null,
        organizationId?: string | null,
        method?: DeliveryMethodChoice | null,
        primary?: boolean | null,
        numberLines?: number | null,
        frequency?: DeliveryFrequencyChoice | null,
        frequencyCustomValue?: number | null,
        filtrationType?: FiltrationTypeChoice | null,
        filtrationTypeCustomValue?: string | null,
        filtrationDescription?: string | null,
        flowRateTypical?: number | null,
        flowRateMax?: number | null,
        pipingSize?: number | null,
        offloadLocations?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    storage?:  {
      __typename: "ModelStorageConnection",
      items:  Array< {
        __typename: "Storage",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        tankId: string,
        yearInstalled: string,
        product: Array< StorageProductChoice | null >,
        storageLocation: StorageLocationChoice,
        storageASTUST: StorageASTUSTChoice,
        nextAPI653?: string | null,
        storageFunction: Array< StorageFunctionChoice | null >,
        totalBBL?: number | null,
        usableBBL?: number | null,
        opStatus: StorageOpStatusChoice,
        owner?: string | null,
        security?: string | null,
        secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
        cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
        leakDetection?: string | null,
        totalGals?: number | null,
        usableGals?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAirportsId?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type ListAirportsQueryVariables = {
  id?: string | null,
  filter?: ModelAirportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAirportsQuery = {
  listAirports?:  {
    __typename: "ModelAirportConnection",
    items:  Array< {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAirportConsortiumGroupQueryVariables = {
  id: string,
};

export type GetAirportConsortiumGroupQuery = {
  getAirportConsortiumGroup?:  {
    __typename: "AirportConsortiumGroup",
    id: string,
    organizationId: string,
    airportId: string,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContact?: string | null,
    facilityOwner?: string | null,
    facilityOwnerContact?: string | null,
    legalCounselContact?: string | null,
    legalCounsel?: string | null,
    facilityLocation?: FacilityLocationTypeChoice | null,
    totalCapacityDays?: number | null,
    totalCapacityGallons?: number | null,
    secured?: boolean | null,
    moExpiration?: string | null,
    moType?: MOTypeChoice | null,
    moTypeCustomValue?: string | null,
    leaseExpiration?: string | null,
    leaseType?: LeaseTypeChoice | null,
    leaseTypeCustomValue?: string | null,
    dateLastFinancialSUP?: string | null,
    dateLastA4aOperationalReview?: string | null,
    netInventoryCalculation?: string | null,
    entryFee?: string | null,
    nonMemberRate?: string | null,
    costSharingFormula?: string | null,
    reserveDepositPrebill?: ReservePrebillTypeChoice | null,
    thirdPartyDebt?: number | null,
    memberLoans?: number | null,
    dateLastPropertyAppraisal?: string | null,
    aviationLiabilityLimit?: number | null,
    environmentalLiabilityLimit?: number | null,
    comments?: string | null,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAirportConsortiumGroupsQueryVariables = {
  id?: string | null,
  filter?: ModelAirportConsortiumGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListAirportConsortiumGroupsQuery = {
  listAirportConsortiumGroups?:  {
    __typename: "ModelAirportConsortiumGroupConnection",
    items:  Array< {
      __typename: "AirportConsortiumGroup",
      id: string,
      organizationId: string,
      airportId: string,
      airport?:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContact?: string | null,
      facilityOwner?: string | null,
      facilityOwnerContact?: string | null,
      legalCounselContact?: string | null,
      legalCounsel?: string | null,
      facilityLocation?: FacilityLocationTypeChoice | null,
      totalCapacityDays?: number | null,
      totalCapacityGallons?: number | null,
      secured?: boolean | null,
      moExpiration?: string | null,
      moType?: MOTypeChoice | null,
      moTypeCustomValue?: string | null,
      leaseExpiration?: string | null,
      leaseType?: LeaseTypeChoice | null,
      leaseTypeCustomValue?: string | null,
      dateLastFinancialSUP?: string | null,
      dateLastA4aOperationalReview?: string | null,
      netInventoryCalculation?: string | null,
      entryFee?: string | null,
      nonMemberRate?: string | null,
      costSharingFormula?: string | null,
      reserveDepositPrebill?: ReservePrebillTypeChoice | null,
      thirdPartyDebt?: number | null,
      memberLoans?: number | null,
      dateLastPropertyAppraisal?: string | null,
      aviationLiabilityLimit?: number | null,
      environmentalLiabilityLimit?: number | null,
      comments?: string | null,
      deleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeliveryToPlaneQueryVariables = {
  id: string,
};

export type GetDeliveryToPlaneQuery = {
  getDeliveryToPlane?:  {
    __typename: "DeliveryToPlane",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportDeliveryToPlaneId?: string | null,
    obFiltration?: boolean | null,
    hydrant?: HydrantChoice | null,
    closedLoop: ClosedLoopChoice,
    length?: number | null,
    hydrantLocations?: string | null,
    pits?: number | null,
    refuelerLocations?: string | null,
    efso?: boolean | null,
    efsoLocation?: string | null,
    leakDetection?: boolean | null,
    leakDetectionDescription?: string | null,
    flowRateF1Peak?: number | null,
    flowRateF1Max?: number | null,
    flowRateF2Peak?: number | null,
    flowRateF2Max?: number | null,
    flowRateF3Peak?: number | null,
    flowRateF3Max?: number | null,
    pipingSizeMin?: number | null,
    pipingSizeMax?: number | null,
    currentIntoPlaneOperators?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDeliveryToPlanesQueryVariables = {
  id?: string | null,
  filter?: ModelDeliveryToPlaneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDeliveryToPlanesQuery = {
  listDeliveryToPlanes?:  {
    __typename: "ModelDeliveryToPlaneConnection",
    items:  Array< {
      __typename: "DeliveryToPlane",
      id: string,
      airportId?: string | null,
      airport:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      },
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      airportDeliveryToPlaneId?: string | null,
      obFiltration?: boolean | null,
      hydrant?: HydrantChoice | null,
      closedLoop: ClosedLoopChoice,
      length?: number | null,
      hydrantLocations?: string | null,
      pits?: number | null,
      refuelerLocations?: string | null,
      efso?: boolean | null,
      efsoLocation?: string | null,
      leakDetection?: boolean | null,
      leakDetectionDescription?: string | null,
      flowRateF1Peak?: number | null,
      flowRateF1Max?: number | null,
      flowRateF2Peak?: number | null,
      flowRateF2Max?: number | null,
      flowRateF3Peak?: number | null,
      flowRateF3Max?: number | null,
      pipingSizeMin?: number | null,
      pipingSizeMax?: number | null,
      currentIntoPlaneOperators?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeliveryToFacilityQueryVariables = {
  id: string,
};

export type GetDeliveryToFacilityQuery = {
  getDeliveryToFacility?:  {
    __typename: "DeliveryToFacility",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    provider?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    method?: DeliveryMethodChoice | null,
    primary?: boolean | null,
    numberLines?: number | null,
    frequency?: DeliveryFrequencyChoice | null,
    frequencyCustomValue?: number | null,
    filtrationType?: FiltrationTypeChoice | null,
    filtrationTypeCustomValue?: string | null,
    filtrationDescription?: string | null,
    flowRateTypical?: number | null,
    flowRateMax?: number | null,
    pipingSize?: number | null,
    offloadLocations?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDeliveryToFacilitiesQueryVariables = {
  id?: string | null,
  filter?: ModelDeliveryToFacilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDeliveryToFacilitiesQuery = {
  listDeliveryToFacilities?:  {
    __typename: "ModelDeliveryToFacilityConnection",
    items:  Array< {
      __typename: "DeliveryToFacility",
      id: string,
      airportId?: string | null,
      airport:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      },
      provider?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      method?: DeliveryMethodChoice | null,
      primary?: boolean | null,
      numberLines?: number | null,
      frequency?: DeliveryFrequencyChoice | null,
      frequencyCustomValue?: number | null,
      filtrationType?: FiltrationTypeChoice | null,
      filtrationTypeCustomValue?: string | null,
      filtrationDescription?: string | null,
      flowRateTypical?: number | null,
      flowRateMax?: number | null,
      pipingSize?: number | null,
      offloadLocations?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStorageQueryVariables = {
  id: string,
};

export type GetStorageQuery = {
  getStorage?:  {
    __typename: "Storage",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    tankId: string,
    yearInstalled: string,
    product: Array< StorageProductChoice | null >,
    storageLocation: StorageLocationChoice,
    storageASTUST: StorageASTUSTChoice,
    nextAPI653?: string | null,
    storageFunction: Array< StorageFunctionChoice | null >,
    totalBBL?: number | null,
    usableBBL?: number | null,
    opStatus: StorageOpStatusChoice,
    owner?: string | null,
    security?: string | null,
    secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
    cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
    leakDetection?: string | null,
    totalGals?: number | null,
    usableGals?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListStoragesQueryVariables = {
  id?: string | null,
  filter?: ModelStorageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListStoragesQuery = {
  listStorages?:  {
    __typename: "ModelStorageConnection",
    items:  Array< {
      __typename: "Storage",
      id: string,
      airportId?: string | null,
      airport:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      },
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      tankId: string,
      yearInstalled: string,
      product: Array< StorageProductChoice | null >,
      storageLocation: StorageLocationChoice,
      storageASTUST: StorageASTUSTChoice,
      nextAPI653?: string | null,
      storageFunction: Array< StorageFunctionChoice | null >,
      totalBBL?: number | null,
      usableBBL?: number | null,
      opStatus: StorageOpStatusChoice,
      owner?: string | null,
      security?: string | null,
      secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
      cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
      leakDetection?: string | null,
      totalGals?: number | null,
      usableGals?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id?: string | null,
    type?: NotificationType | null,
    method?: NotificationMethod | null,
    scope?:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null > | null,
    notificationDate?: string | null,
    title?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id?: string | null,
      type?: NotificationType | null,
      method?: NotificationMethod | null,
      scope?:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null > | null,
      notificationDate?: string | null,
      title?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetModifyRecordQueryVariables = {
  id: string,
};

export type GetModifyRecordQuery = {
  getModifyRecord?:  {
    __typename: "ModifyRecord",
    fieldName?: string | null,
    fieldValue?: string | null,
    dataOperation?: DataOperation | null,
    modifiedAt?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListModifyRecordsQueryVariables = {
  filter?: ModelModifyRecordFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListModifyRecordsQuery = {
  listModifyRecords?:  {
    __typename: "ModelModifyRecordConnection",
    items:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInviteLogQueryVariables = {
  id: string,
};

export type GetInviteLogQuery = {
  getInviteLog?:  {
    __typename: "InviteLog",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    isAccepted?: boolean | null,
    type?: InviteType | null,
    sendByUserId?: string | null,
    sendBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    createdAt?: string | null,
    acceptedAt?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type ListInviteLogsQueryVariables = {
  id?: string | null,
  filter?: ModelInviteLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInviteLogsQuery = {
  listInviteLogs?:  {
    __typename: "ModelInviteLogConnection",
    items:  Array< {
      __typename: "InviteLog",
      id: string,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      userId?: string | null,
      user?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      isAccepted?: boolean | null,
      type?: InviteType | null,
      sendByUserId?: string | null,
      sendBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      createdAt?: string | null,
      acceptedAt?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrgAdminUserQueryVariables = {
  id: string,
};

export type GetOrgAdminUserQuery = {
  getOrgAdminUser?:  {
    __typename: "OrgAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrgAdminUsersQueryVariables = {
  filter?: ModelOrgAdminUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrgAdminUsersQuery = {
  listOrgAdminUsers?:  {
    __typename: "ModelOrgAdminUserConnection",
    items:  Array< {
      __typename: "OrgAdminUser",
      id: string,
      organizationId: string,
      userId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      },
      user:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrgMemberUserQueryVariables = {
  id: string,
};

export type GetOrgMemberUserQuery = {
  getOrgMemberUser?:  {
    __typename: "OrgMemberUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrgMemberUsersQueryVariables = {
  filter?: ModelOrgMemberUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrgMemberUsersQuery = {
  listOrgMemberUsers?:  {
    __typename: "ModelOrgMemberUserConnection",
    items:  Array< {
      __typename: "OrgMemberUser",
      id: string,
      organizationId: string,
      userId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      },
      user:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrgSupportAdminUserQueryVariables = {
  id: string,
};

export type GetOrgSupportAdminUserQuery = {
  getOrgSupportAdminUser?:  {
    __typename: "OrgSupportAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListOrgSupportAdminUsersQueryVariables = {
  filter?: ModelOrgSupportAdminUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrgSupportAdminUsersQuery = {
  listOrgSupportAdminUsers?:  {
    __typename: "ModelOrgSupportAdminUserConnection",
    items:  Array< {
      __typename: "OrgSupportAdminUser",
      id: string,
      organizationId: string,
      userId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      },
      user:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByCompanyIdQueryVariables = {
  companyId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByCompanyIdQuery = {
  usersByCompanyId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByEmailQueryVariables = {
  email: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailQuery = {
  usersByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DocumentsByAirportIdQueryVariables = {
  airportId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DocumentsByAirportIdQuery = {
  documentsByAirportId?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      id: string,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      airportId?: string | null,
      airport?:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      categoryType?: DocumentCategoryTypeChoice | null,
      documentCategoryId?: string | null,
      documentCategory?:  {
        __typename: "DocumentCategory",
        id: string,
        name: string,
        type?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        description?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      bucketType?: DocumentBucketTypeChoice | null,
      title: string,
      description?: string | null,
      publishedByUserId?: string | null,
      publishedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      publishedDate?: string | null,
      url: string,
      fileSize?: number | null,
      fileType?:  {
        __typename: "FileType",
        id?: string | null,
        name?: string | null,
        description?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      companyId?: string | null,
      createdAt?: string | null,
      createByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      categoryTypeId?: string | null,
      category?:  {
        __typename: "DocumentCategory",
        id: string,
        name: string,
        type?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        description?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationDocumentsId?: string | null,
      documentCategoryDocumentsId?: string | null,
      documentFileTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VolumeByAirportidAndYearQueryVariables = {
  airportId: string,
  year?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAirportVolumeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VolumeByAirportidAndYearQuery = {
  volumeByAirportidAndYear?:  {
    __typename: "ModelAirportVolumeConnection",
    items:  Array< {
      __typename: "AirportVolume",
      id: string,
      airportId?: string | null,
      airport?:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      year?: number | null,
      volume?: number | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AirportConsortiumGroupsByOrganizationIdAndAirportIdQueryVariables = {
  organizationId: string,
  airportId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAirportConsortiumGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AirportConsortiumGroupsByOrganizationIdAndAirportIdQuery = {
  airportConsortiumGroupsByOrganizationIdAndAirportId?:  {
    __typename: "ModelAirportConsortiumGroupConnection",
    items:  Array< {
      __typename: "AirportConsortiumGroup",
      id: string,
      organizationId: string,
      airportId: string,
      airport?:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContact?: string | null,
      facilityOwner?: string | null,
      facilityOwnerContact?: string | null,
      legalCounselContact?: string | null,
      legalCounsel?: string | null,
      facilityLocation?: FacilityLocationTypeChoice | null,
      totalCapacityDays?: number | null,
      totalCapacityGallons?: number | null,
      secured?: boolean | null,
      moExpiration?: string | null,
      moType?: MOTypeChoice | null,
      moTypeCustomValue?: string | null,
      leaseExpiration?: string | null,
      leaseType?: LeaseTypeChoice | null,
      leaseTypeCustomValue?: string | null,
      dateLastFinancialSUP?: string | null,
      dateLastA4aOperationalReview?: string | null,
      netInventoryCalculation?: string | null,
      entryFee?: string | null,
      nonMemberRate?: string | null,
      costSharingFormula?: string | null,
      reserveDepositPrebill?: ReservePrebillTypeChoice | null,
      thirdPartyDebt?: number | null,
      memberLoans?: number | null,
      dateLastPropertyAppraisal?: string | null,
      aviationLiabilityLimit?: number | null,
      environmentalLiabilityLimit?: number | null,
      comments?: string | null,
      deleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AirportConsortiumGroupsByAirportIdAndOrganizationIdQueryVariables = {
  airportId: string,
  organizationId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAirportConsortiumGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AirportConsortiumGroupsByAirportIdAndOrganizationIdQuery = {
  airportConsortiumGroupsByAirportIdAndOrganizationId?:  {
    __typename: "ModelAirportConsortiumGroupConnection",
    items:  Array< {
      __typename: "AirportConsortiumGroup",
      id: string,
      organizationId: string,
      airportId: string,
      airport?:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContact?: string | null,
      facilityOwner?: string | null,
      facilityOwnerContact?: string | null,
      legalCounselContact?: string | null,
      legalCounsel?: string | null,
      facilityLocation?: FacilityLocationTypeChoice | null,
      totalCapacityDays?: number | null,
      totalCapacityGallons?: number | null,
      secured?: boolean | null,
      moExpiration?: string | null,
      moType?: MOTypeChoice | null,
      moTypeCustomValue?: string | null,
      leaseExpiration?: string | null,
      leaseType?: LeaseTypeChoice | null,
      leaseTypeCustomValue?: string | null,
      dateLastFinancialSUP?: string | null,
      dateLastA4aOperationalReview?: string | null,
      netInventoryCalculation?: string | null,
      entryFee?: string | null,
      nonMemberRate?: string | null,
      costSharingFormula?: string | null,
      reserveDepositPrebill?: ReservePrebillTypeChoice | null,
      thirdPartyDebt?: number | null,
      memberLoans?: number | null,
      dateLastPropertyAppraisal?: string | null,
      aviationLiabilityLimit?: number | null,
      environmentalLiabilityLimit?: number | null,
      comments?: string | null,
      deleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DeliveryToPlanesByAirportIdQueryVariables = {
  airportId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeliveryToPlaneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DeliveryToPlanesByAirportIdQuery = {
  deliveryToPlanesByAirportId?:  {
    __typename: "ModelDeliveryToPlaneConnection",
    items:  Array< {
      __typename: "DeliveryToPlane",
      id: string,
      airportId?: string | null,
      airport:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      },
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      airportDeliveryToPlaneId?: string | null,
      obFiltration?: boolean | null,
      hydrant?: HydrantChoice | null,
      closedLoop: ClosedLoopChoice,
      length?: number | null,
      hydrantLocations?: string | null,
      pits?: number | null,
      refuelerLocations?: string | null,
      efso?: boolean | null,
      efsoLocation?: string | null,
      leakDetection?: boolean | null,
      leakDetectionDescription?: string | null,
      flowRateF1Peak?: number | null,
      flowRateF1Max?: number | null,
      flowRateF2Peak?: number | null,
      flowRateF2Max?: number | null,
      flowRateF3Peak?: number | null,
      flowRateF3Max?: number | null,
      pipingSizeMin?: number | null,
      pipingSizeMax?: number | null,
      currentIntoPlaneOperators?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DeliveryToFacilitiesByAirportIdQueryVariables = {
  airportId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeliveryToFacilityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DeliveryToFacilitiesByAirportIdQuery = {
  deliveryToFacilitiesByAirportId?:  {
    __typename: "ModelDeliveryToFacilityConnection",
    items:  Array< {
      __typename: "DeliveryToFacility",
      id: string,
      airportId?: string | null,
      airport:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      },
      provider?: string | null,
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      method?: DeliveryMethodChoice | null,
      primary?: boolean | null,
      numberLines?: number | null,
      frequency?: DeliveryFrequencyChoice | null,
      frequencyCustomValue?: number | null,
      filtrationType?: FiltrationTypeChoice | null,
      filtrationTypeCustomValue?: string | null,
      filtrationDescription?: string | null,
      flowRateTypical?: number | null,
      flowRateMax?: number | null,
      pipingSize?: number | null,
      offloadLocations?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type StoragesByAirportIdQueryVariables = {
  airportId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelStorageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type StoragesByAirportIdQuery = {
  storagesByAirportId?:  {
    __typename: "ModelStorageConnection",
    items:  Array< {
      __typename: "Storage",
      id: string,
      airportId?: string | null,
      airport:  {
        __typename: "Airport",
        id: string,
        name: string,
        code: string,
        oldCode?: string | null,
        facility?: string | null,
        region?: AirportRegionChoice | null,
        state: string,
        consortiumType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContactId?: string | null,
        facilityOwnerId?: string | null,
        facilityOwnerContactId?: string | null,
        inventoryAccounting?: InventoryAccountingTypeChoice | null,
        createdAt?: string | null,
        organizationAirportsId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
      },
      organizationId?: string | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null,
      tankId: string,
      yearInstalled: string,
      product: Array< StorageProductChoice | null >,
      storageLocation: StorageLocationChoice,
      storageASTUST: StorageASTUSTChoice,
      nextAPI653?: string | null,
      storageFunction: Array< StorageFunctionChoice | null >,
      totalBBL?: number | null,
      usableBBL?: number | null,
      opStatus: StorageOpStatusChoice,
      owner?: string | null,
      security?: string | null,
      secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
      cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
      leakDetection?: string | null,
      totalGals?: number | null,
      usableGals?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrgAdminUsersByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrgAdminUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrgAdminUsersByOrganizationIdQuery = {
  orgAdminUsersByOrganizationId?:  {
    __typename: "ModelOrgAdminUserConnection",
    items:  Array< {
      __typename: "OrgAdminUser",
      id: string,
      organizationId: string,
      userId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      },
      user:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrgAdminUsersByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrgAdminUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrgAdminUsersByUserIdQuery = {
  orgAdminUsersByUserId?:  {
    __typename: "ModelOrgAdminUserConnection",
    items:  Array< {
      __typename: "OrgAdminUser",
      id: string,
      organizationId: string,
      userId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      },
      user:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrgMemberUsersByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrgMemberUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrgMemberUsersByOrganizationIdQuery = {
  orgMemberUsersByOrganizationId?:  {
    __typename: "ModelOrgMemberUserConnection",
    items:  Array< {
      __typename: "OrgMemberUser",
      id: string,
      organizationId: string,
      userId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      },
      user:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrgMemberUsersByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrgMemberUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrgMemberUsersByUserIdQuery = {
  orgMemberUsersByUserId?:  {
    __typename: "ModelOrgMemberUserConnection",
    items:  Array< {
      __typename: "OrgMemberUser",
      id: string,
      organizationId: string,
      userId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      },
      user:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrgSupportAdminUsersByOrganizationIdQueryVariables = {
  organizationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrgSupportAdminUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrgSupportAdminUsersByOrganizationIdQuery = {
  orgSupportAdminUsersByOrganizationId?:  {
    __typename: "ModelOrgSupportAdminUserConnection",
    items:  Array< {
      __typename: "OrgSupportAdminUser",
      id: string,
      organizationId: string,
      userId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      },
      user:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OrgSupportAdminUsersByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOrgSupportAdminUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrgSupportAdminUsersByUserIdQuery = {
  orgSupportAdminUsersByUserId?:  {
    __typename: "ModelOrgSupportAdminUserConnection",
    items:  Array< {
      __typename: "OrgSupportAdminUser",
      id: string,
      organizationId: string,
      userId: string,
      organization:  {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      },
      user:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    organizationType?: OrganizationTypeChoice | null,
    groupType?: GroupTypeChoice | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorContactId?: string | null,
    operatorContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    airports?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    calendar?:  {
      __typename: "ModelCalendarConnection",
      items:  Array< {
        __typename: "Calendar",
        id: string,
        organizationId?: string | null,
        calendarEventTypeId?: string | null,
        name: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        description?: string | null,
        location?: string | null,
        website?: string | null,
        groupRateCode?: string | null,
        organizer?: string | null,
        specialRates?: boolean | null,
        venue?: string | null,
        venueWebSite?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        modifiedByUserId?: string | null,
        updatedAt: string,
        organizationCalendarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    supportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    updatedAt: string,
    companyOrganizationsId?: string | null,
    userOrganizationId?: string | null,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    organizationType?: OrganizationTypeChoice | null,
    groupType?: GroupTypeChoice | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorContactId?: string | null,
    operatorContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    airports?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    calendar?:  {
      __typename: "ModelCalendarConnection",
      items:  Array< {
        __typename: "Calendar",
        id: string,
        organizationId?: string | null,
        calendarEventTypeId?: string | null,
        name: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        description?: string | null,
        location?: string | null,
        website?: string | null,
        groupRateCode?: string | null,
        organizer?: string | null,
        specialRates?: boolean | null,
        venue?: string | null,
        venueWebSite?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        modifiedByUserId?: string | null,
        updatedAt: string,
        organizationCalendarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    supportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    updatedAt: string,
    companyOrganizationsId?: string | null,
    userOrganizationId?: string | null,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    organizationType?: OrganizationTypeChoice | null,
    groupType?: GroupTypeChoice | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorContactId?: string | null,
    operatorContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    admins?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    airports?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    calendar?:  {
      __typename: "ModelCalendarConnection",
      items:  Array< {
        __typename: "Calendar",
        id: string,
        organizationId?: string | null,
        calendarEventTypeId?: string | null,
        name: string,
        startDateTime?: string | null,
        endDateTime?: string | null,
        description?: string | null,
        location?: string | null,
        website?: string | null,
        groupRateCode?: string | null,
        organizer?: string | null,
        specialRates?: boolean | null,
        venue?: string | null,
        venueWebSite?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        modifiedByUserId?: string | null,
        updatedAt: string,
        organizationCalendarId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    contacts?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    supportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    updatedAt: string,
    companyOrganizationsId?: string | null,
    userOrganizationId?: string | null,
  } | null,
};

export type OnCreateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    type?: CompanyTypeChoice | null,
    organizations?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCompaniesId?: string | null,
  } | null,
};

export type OnUpdateCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    type?: CompanyTypeChoice | null,
    organizations?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCompaniesId?: string | null,
  } | null,
};

export type OnDeleteCompanySubscriptionVariables = {
  filter?: ModelSubscriptionCompanyFilterInput | null,
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "Company",
    id: string,
    name: string,
    type?: CompanyTypeChoice | null,
    organizations?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    members?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCompaniesId?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId?: string | null,
    termsAgree?: boolean | null,
    termsAgreeDate?: string | null,
    participationAgreement?: boolean | null,
    participationAgreementDate?: string | null,
    organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    roles?: Array< string | null > | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    office?: string | null,
    mobile?: string | null,
    linkedInUrl?: string | null,
    imageUrl?: string | null,
    timeZone?: string | null,
    monthlyUpdates?: boolean | null,
    generalAnnouncements?: boolean | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAdmin?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationMember?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    lastLoginDate?: string | null,
    weeklyDigestNotification?: boolean | null,
    monthlyDigestNotification?: boolean | null,
    quarterlyEmailNotification?: boolean | null,
    annualDigestNotification?: boolean | null,
    membershipAlertsNotification?: boolean | null,
    generalEmailNotification?: boolean | null,
    organizationSupportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    organizationContactsId?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId?: string | null,
    termsAgree?: boolean | null,
    termsAgreeDate?: string | null,
    participationAgreement?: boolean | null,
    participationAgreementDate?: string | null,
    organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    roles?: Array< string | null > | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    office?: string | null,
    mobile?: string | null,
    linkedInUrl?: string | null,
    imageUrl?: string | null,
    timeZone?: string | null,
    monthlyUpdates?: boolean | null,
    generalAnnouncements?: boolean | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAdmin?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationMember?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    lastLoginDate?: string | null,
    weeklyDigestNotification?: boolean | null,
    monthlyDigestNotification?: boolean | null,
    quarterlyEmailNotification?: boolean | null,
    annualDigestNotification?: boolean | null,
    membershipAlertsNotification?: boolean | null,
    generalEmailNotification?: boolean | null,
    organizationSupportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    organizationContactsId?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    cognitoUserId?: string | null,
    termsAgree?: boolean | null,
    termsAgreeDate?: string | null,
    participationAgreement?: boolean | null,
    participationAgreementDate?: string | null,
    organization?:  {
      __typename: "ModelOrganizationConnection",
      items:  Array< {
        __typename: "Organization",
        id: string,
        name: string,
        organizationType?: OrganizationTypeChoice | null,
        groupType?: GroupTypeChoice | null,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorContactId?: string | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        companyOrganizationsId?: string | null,
        userOrganizationId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    roles?: Array< string | null > | null,
    companyId?: string | null,
    company?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    firstName: string,
    lastName: string,
    email: string,
    office?: string | null,
    mobile?: string | null,
    linkedInUrl?: string | null,
    imageUrl?: string | null,
    timeZone?: string | null,
    monthlyUpdates?: boolean | null,
    generalAnnouncements?: boolean | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAdmin?:  {
      __typename: "ModelOrgAdminUserConnection",
      items:  Array< {
        __typename: "OrgAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organizationMember?:  {
      __typename: "ModelOrgMemberUserConnection",
      items:  Array< {
        __typename: "OrgMemberUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deleted?: boolean | null,
    lastLoginDate?: string | null,
    weeklyDigestNotification?: boolean | null,
    monthlyDigestNotification?: boolean | null,
    quarterlyEmailNotification?: boolean | null,
    annualDigestNotification?: boolean | null,
    membershipAlertsNotification?: boolean | null,
    generalEmailNotification?: boolean | null,
    organizationSupportAdmin?:  {
      __typename: "ModelOrgSupportAdminUserConnection",
      items:  Array< {
        __typename: "OrgSupportAdminUser",
        id: string,
        organizationId: string,
        userId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    updatedAt: string,
    organizationContactsId?: string | null,
  } | null,
};

export type OnCreateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
};

export type OnCreateDocumentSubscription = {
  onCreateDocument?:  {
    __typename: "Document",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    categoryType?: DocumentCategoryTypeChoice | null,
    documentCategoryId?: string | null,
    documentCategory?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    bucketType?: DocumentBucketTypeChoice | null,
    title: string,
    description?: string | null,
    publishedByUserId?: string | null,
    publishedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    publishedDate?: string | null,
    url: string,
    fileSize?: number | null,
    fileType?:  {
      __typename: "FileType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    companyId?: string | null,
    createdAt?: string | null,
    createByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    categoryTypeId?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    documentCategoryDocumentsId?: string | null,
    documentFileTypeId?: string | null,
  } | null,
};

export type OnUpdateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
};

export type OnUpdateDocumentSubscription = {
  onUpdateDocument?:  {
    __typename: "Document",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    categoryType?: DocumentCategoryTypeChoice | null,
    documentCategoryId?: string | null,
    documentCategory?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    bucketType?: DocumentBucketTypeChoice | null,
    title: string,
    description?: string | null,
    publishedByUserId?: string | null,
    publishedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    publishedDate?: string | null,
    url: string,
    fileSize?: number | null,
    fileType?:  {
      __typename: "FileType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    companyId?: string | null,
    createdAt?: string | null,
    createByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    categoryTypeId?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    documentCategoryDocumentsId?: string | null,
    documentFileTypeId?: string | null,
  } | null,
};

export type OnDeleteDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
};

export type OnDeleteDocumentSubscription = {
  onDeleteDocument?:  {
    __typename: "Document",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    categoryType?: DocumentCategoryTypeChoice | null,
    documentCategoryId?: string | null,
    documentCategory?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    bucketType?: DocumentBucketTypeChoice | null,
    title: string,
    description?: string | null,
    publishedByUserId?: string | null,
    publishedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    publishedDate?: string | null,
    url: string,
    fileSize?: number | null,
    fileType?:  {
      __typename: "FileType",
      id?: string | null,
      name?: string | null,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    companyId?: string | null,
    createdAt?: string | null,
    createByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    categoryTypeId?: string | null,
    category?:  {
      __typename: "DocumentCategory",
      id: string,
      name: string,
      type?: string | null,
      bucketType?: DocumentBucketTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      description?: string | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    documentCategoryDocumentsId?: string | null,
    documentFileTypeId?: string | null,
  } | null,
};

export type OnCreateFileTypeSubscriptionVariables = {
  filter?: ModelSubscriptionFileTypeFilterInput | null,
};

export type OnCreateFileTypeSubscription = {
  onCreateFileType?:  {
    __typename: "FileType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFileTypeSubscriptionVariables = {
  filter?: ModelSubscriptionFileTypeFilterInput | null,
};

export type OnUpdateFileTypeSubscription = {
  onUpdateFileType?:  {
    __typename: "FileType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFileTypeSubscriptionVariables = {
  filter?: ModelSubscriptionFileTypeFilterInput | null,
};

export type OnDeleteFileTypeSubscription = {
  onDeleteFileType?:  {
    __typename: "FileType",
    id?: string | null,
    name?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDocumentCategorySubscriptionVariables = {
  filter?: ModelSubscriptionDocumentCategoryFilterInput | null,
};

export type OnCreateDocumentCategorySubscription = {
  onCreateDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    name: string,
    type?: string | null,
    bucketType?: DocumentBucketTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateDocumentCategorySubscriptionVariables = {
  filter?: ModelSubscriptionDocumentCategoryFilterInput | null,
};

export type OnUpdateDocumentCategorySubscription = {
  onUpdateDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    name: string,
    type?: string | null,
    bucketType?: DocumentBucketTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteDocumentCategorySubscriptionVariables = {
  filter?: ModelSubscriptionDocumentCategoryFilterInput | null,
};

export type OnDeleteDocumentCategorySubscription = {
  onDeleteDocumentCategory?:  {
    __typename: "DocumentCategory",
    id: string,
    name: string,
    type?: string | null,
    bucketType?: DocumentBucketTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    description?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    deleted?: boolean | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnCreateCalendarSubscriptionVariables = {
  filter?: ModelSubscriptionCalendarFilterInput | null,
};

export type OnCreateCalendarSubscription = {
  onCreateCalendar?:  {
    __typename: "Calendar",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    calendarEventTypeId?: string | null,
    calendarEventType?:  {
      __typename: "CalendarEventType",
      id: string,
      name: string,
      description?: string | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    name: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    description?: string | null,
    location?: string | null,
    website?: string | null,
    groupRateCode?: string | null,
    organizer?: string | null,
    specialRates?: boolean | null,
    venue?: string | null,
    venueWebSite?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifiedByUserId?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCalendarId?: string | null,
  } | null,
};

export type OnUpdateCalendarSubscriptionVariables = {
  filter?: ModelSubscriptionCalendarFilterInput | null,
};

export type OnUpdateCalendarSubscription = {
  onUpdateCalendar?:  {
    __typename: "Calendar",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    calendarEventTypeId?: string | null,
    calendarEventType?:  {
      __typename: "CalendarEventType",
      id: string,
      name: string,
      description?: string | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    name: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    description?: string | null,
    location?: string | null,
    website?: string | null,
    groupRateCode?: string | null,
    organizer?: string | null,
    specialRates?: boolean | null,
    venue?: string | null,
    venueWebSite?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifiedByUserId?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCalendarId?: string | null,
  } | null,
};

export type OnDeleteCalendarSubscriptionVariables = {
  filter?: ModelSubscriptionCalendarFilterInput | null,
};

export type OnDeleteCalendarSubscription = {
  onDeleteCalendar?:  {
    __typename: "Calendar",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    calendarEventTypeId?: string | null,
    calendarEventType?:  {
      __typename: "CalendarEventType",
      id: string,
      name: string,
      description?: string | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
    } | null,
    name: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    description?: string | null,
    location?: string | null,
    website?: string | null,
    groupRateCode?: string | null,
    organizer?: string | null,
    specialRates?: boolean | null,
    venue?: string | null,
    venueWebSite?: string | null,
    createdAt?: string | null,
    createdByUserId?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifiedByUserId?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
    organizationCalendarId?: string | null,
  } | null,
};

export type OnCreateCalendarEventTypeSubscriptionVariables = {
  filter?: ModelSubscriptionCalendarEventTypeFilterInput | null,
};

export type OnCreateCalendarEventTypeSubscription = {
  onCreateCalendarEventType?:  {
    __typename: "CalendarEventType",
    id: string,
    name: string,
    description?: string | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateCalendarEventTypeSubscriptionVariables = {
  filter?: ModelSubscriptionCalendarEventTypeFilterInput | null,
};

export type OnUpdateCalendarEventTypeSubscription = {
  onUpdateCalendarEventType?:  {
    __typename: "CalendarEventType",
    id: string,
    name: string,
    description?: string | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteCalendarEventTypeSubscriptionVariables = {
  filter?: ModelSubscriptionCalendarEventTypeFilterInput | null,
};

export type OnDeleteCalendarEventTypeSubscription = {
  onDeleteCalendarEventType?:  {
    __typename: "CalendarEventType",
    id: string,
    name: string,
    description?: string | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAirportVolumeSubscriptionVariables = {
  filter?: ModelSubscriptionAirportVolumeFilterInput | null,
};

export type OnCreateAirportVolumeSubscription = {
  onCreateAirportVolume?:  {
    __typename: "AirportVolume",
    id: string,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    year?: number | null,
    volume?: number | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAirportVolumeSubscriptionVariables = {
  filter?: ModelSubscriptionAirportVolumeFilterInput | null,
};

export type OnUpdateAirportVolumeSubscription = {
  onUpdateAirportVolume?:  {
    __typename: "AirportVolume",
    id: string,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    year?: number | null,
    volume?: number | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAirportVolumeSubscriptionVariables = {
  filter?: ModelSubscriptionAirportVolumeFilterInput | null,
};

export type OnDeleteAirportVolumeSubscription = {
  onDeleteAirportVolume?:  {
    __typename: "AirportVolume",
    id: string,
    airportId?: string | null,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    year?: number | null,
    volume?: number | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAirportSubscriptionVariables = {
  filter?: ModelSubscriptionAirportFilterInput | null,
};

export type OnCreateAirportSubscription = {
  onCreateAirport?:  {
    __typename: "Airport",
    id: string,
    name: string,
    code: string,
    oldCode?: string | null,
    facility?: string | null,
    region?: AirportRegionChoice | null,
    state: string,
    place?:  {
      __typename: "Place",
      terminal?: string | null,
      gate?: string | null,
      lat?: string | null,
      lon?: string | null,
    } | null,
    consortiumGroup?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumType?: GroupTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContactId?: string | null,
    secondaryContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    facilityOwnerId?: string | null,
    facilityOwner?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    facilityOwnerContactId?: string | null,
    facilityOwnerContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    inventoryAccounting?: InventoryAccountingTypeChoice | null,
    deliveryToPlane?:  {
      __typename: "ModelDeliveryToPlaneConnection",
      items:  Array< {
        __typename: "DeliveryToPlane",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        airportDeliveryToPlaneId?: string | null,
        obFiltration?: boolean | null,
        hydrant?: HydrantChoice | null,
        closedLoop: ClosedLoopChoice,
        length?: number | null,
        hydrantLocations?: string | null,
        pits?: number | null,
        refuelerLocations?: string | null,
        efso?: boolean | null,
        efsoLocation?: string | null,
        leakDetection?: boolean | null,
        leakDetectionDescription?: string | null,
        flowRateF1Peak?: number | null,
        flowRateF1Max?: number | null,
        flowRateF2Peak?: number | null,
        flowRateF2Max?: number | null,
        flowRateF3Peak?: number | null,
        flowRateF3Max?: number | null,
        pipingSizeMin?: number | null,
        pipingSizeMax?: number | null,
        currentIntoPlaneOperators?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryToFacility?:  {
      __typename: "ModelDeliveryToFacilityConnection",
      items:  Array< {
        __typename: "DeliveryToFacility",
        id: string,
        airportId?: string | null,
        provider?: string | null,
        organizationId?: string | null,
        method?: DeliveryMethodChoice | null,
        primary?: boolean | null,
        numberLines?: number | null,
        frequency?: DeliveryFrequencyChoice | null,
        frequencyCustomValue?: number | null,
        filtrationType?: FiltrationTypeChoice | null,
        filtrationTypeCustomValue?: string | null,
        filtrationDescription?: string | null,
        flowRateTypical?: number | null,
        flowRateMax?: number | null,
        pipingSize?: number | null,
        offloadLocations?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    storage?:  {
      __typename: "ModelStorageConnection",
      items:  Array< {
        __typename: "Storage",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        tankId: string,
        yearInstalled: string,
        product: Array< StorageProductChoice | null >,
        storageLocation: StorageLocationChoice,
        storageASTUST: StorageASTUSTChoice,
        nextAPI653?: string | null,
        storageFunction: Array< StorageFunctionChoice | null >,
        totalBBL?: number | null,
        usableBBL?: number | null,
        opStatus: StorageOpStatusChoice,
        owner?: string | null,
        security?: string | null,
        secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
        cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
        leakDetection?: string | null,
        totalGals?: number | null,
        usableGals?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAirportsId?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateAirportSubscriptionVariables = {
  filter?: ModelSubscriptionAirportFilterInput | null,
};

export type OnUpdateAirportSubscription = {
  onUpdateAirport?:  {
    __typename: "Airport",
    id: string,
    name: string,
    code: string,
    oldCode?: string | null,
    facility?: string | null,
    region?: AirportRegionChoice | null,
    state: string,
    place?:  {
      __typename: "Place",
      terminal?: string | null,
      gate?: string | null,
      lat?: string | null,
      lon?: string | null,
    } | null,
    consortiumGroup?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumType?: GroupTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContactId?: string | null,
    secondaryContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    facilityOwnerId?: string | null,
    facilityOwner?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    facilityOwnerContactId?: string | null,
    facilityOwnerContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    inventoryAccounting?: InventoryAccountingTypeChoice | null,
    deliveryToPlane?:  {
      __typename: "ModelDeliveryToPlaneConnection",
      items:  Array< {
        __typename: "DeliveryToPlane",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        airportDeliveryToPlaneId?: string | null,
        obFiltration?: boolean | null,
        hydrant?: HydrantChoice | null,
        closedLoop: ClosedLoopChoice,
        length?: number | null,
        hydrantLocations?: string | null,
        pits?: number | null,
        refuelerLocations?: string | null,
        efso?: boolean | null,
        efsoLocation?: string | null,
        leakDetection?: boolean | null,
        leakDetectionDescription?: string | null,
        flowRateF1Peak?: number | null,
        flowRateF1Max?: number | null,
        flowRateF2Peak?: number | null,
        flowRateF2Max?: number | null,
        flowRateF3Peak?: number | null,
        flowRateF3Max?: number | null,
        pipingSizeMin?: number | null,
        pipingSizeMax?: number | null,
        currentIntoPlaneOperators?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryToFacility?:  {
      __typename: "ModelDeliveryToFacilityConnection",
      items:  Array< {
        __typename: "DeliveryToFacility",
        id: string,
        airportId?: string | null,
        provider?: string | null,
        organizationId?: string | null,
        method?: DeliveryMethodChoice | null,
        primary?: boolean | null,
        numberLines?: number | null,
        frequency?: DeliveryFrequencyChoice | null,
        frequencyCustomValue?: number | null,
        filtrationType?: FiltrationTypeChoice | null,
        filtrationTypeCustomValue?: string | null,
        filtrationDescription?: string | null,
        flowRateTypical?: number | null,
        flowRateMax?: number | null,
        pipingSize?: number | null,
        offloadLocations?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    storage?:  {
      __typename: "ModelStorageConnection",
      items:  Array< {
        __typename: "Storage",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        tankId: string,
        yearInstalled: string,
        product: Array< StorageProductChoice | null >,
        storageLocation: StorageLocationChoice,
        storageASTUST: StorageASTUSTChoice,
        nextAPI653?: string | null,
        storageFunction: Array< StorageFunctionChoice | null >,
        totalBBL?: number | null,
        usableBBL?: number | null,
        opStatus: StorageOpStatusChoice,
        owner?: string | null,
        security?: string | null,
        secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
        cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
        leakDetection?: string | null,
        totalGals?: number | null,
        usableGals?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAirportsId?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteAirportSubscriptionVariables = {
  filter?: ModelSubscriptionAirportFilterInput | null,
};

export type OnDeleteAirportSubscription = {
  onDeleteAirport?:  {
    __typename: "Airport",
    id: string,
    name: string,
    code: string,
    oldCode?: string | null,
    facility?: string | null,
    region?: AirportRegionChoice | null,
    state: string,
    place?:  {
      __typename: "Place",
      terminal?: string | null,
      gate?: string | null,
      lat?: string | null,
      lon?: string | null,
    } | null,
    consortiumGroup?:  {
      __typename: "ModelAirportConsortiumGroupConnection",
      items:  Array< {
        __typename: "AirportConsortiumGroup",
        id: string,
        organizationId: string,
        airportId: string,
        chairId?: string | null,
        chairContactId?: string | null,
        operatorId?: string | null,
        operatorGeneralManagerId?: string | null,
        secondaryContact?: string | null,
        facilityOwner?: string | null,
        facilityOwnerContact?: string | null,
        legalCounselContact?: string | null,
        legalCounsel?: string | null,
        facilityLocation?: FacilityLocationTypeChoice | null,
        totalCapacityDays?: number | null,
        totalCapacityGallons?: number | null,
        secured?: boolean | null,
        moExpiration?: string | null,
        moType?: MOTypeChoice | null,
        moTypeCustomValue?: string | null,
        leaseExpiration?: string | null,
        leaseType?: LeaseTypeChoice | null,
        leaseTypeCustomValue?: string | null,
        dateLastFinancialSUP?: string | null,
        dateLastA4aOperationalReview?: string | null,
        netInventoryCalculation?: string | null,
        entryFee?: string | null,
        nonMemberRate?: string | null,
        costSharingFormula?: string | null,
        reserveDepositPrebill?: ReservePrebillTypeChoice | null,
        thirdPartyDebt?: number | null,
        memberLoans?: number | null,
        dateLastPropertyAppraisal?: string | null,
        aviationLiabilityLimit?: number | null,
        environmentalLiabilityLimit?: number | null,
        comments?: string | null,
        deleted?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    consortiumType?: GroupTypeChoice | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      items:  Array< {
        __typename: "Document",
        id: string,
        organizationId?: string | null,
        airportId?: string | null,
        categoryType?: DocumentCategoryTypeChoice | null,
        documentCategoryId?: string | null,
        bucketType?: DocumentBucketTypeChoice | null,
        title: string,
        description?: string | null,
        publishedByUserId?: string | null,
        publishedDate?: string | null,
        url: string,
        fileSize?: number | null,
        companyId?: string | null,
        createdAt?: string | null,
        createByUserId?: string | null,
        categoryTypeId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationDocumentsId?: string | null,
        documentCategoryDocumentsId?: string | null,
        documentFileTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContactId?: string | null,
    secondaryContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    facilityOwnerId?: string | null,
    facilityOwner?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    facilityOwnerContactId?: string | null,
    facilityOwnerContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    inventoryAccounting?: InventoryAccountingTypeChoice | null,
    deliveryToPlane?:  {
      __typename: "ModelDeliveryToPlaneConnection",
      items:  Array< {
        __typename: "DeliveryToPlane",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        airportDeliveryToPlaneId?: string | null,
        obFiltration?: boolean | null,
        hydrant?: HydrantChoice | null,
        closedLoop: ClosedLoopChoice,
        length?: number | null,
        hydrantLocations?: string | null,
        pits?: number | null,
        refuelerLocations?: string | null,
        efso?: boolean | null,
        efsoLocation?: string | null,
        leakDetection?: boolean | null,
        leakDetectionDescription?: string | null,
        flowRateF1Peak?: number | null,
        flowRateF1Max?: number | null,
        flowRateF2Peak?: number | null,
        flowRateF2Max?: number | null,
        flowRateF3Peak?: number | null,
        flowRateF3Max?: number | null,
        pipingSizeMin?: number | null,
        pipingSizeMax?: number | null,
        currentIntoPlaneOperators?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    deliveryToFacility?:  {
      __typename: "ModelDeliveryToFacilityConnection",
      items:  Array< {
        __typename: "DeliveryToFacility",
        id: string,
        airportId?: string | null,
        provider?: string | null,
        organizationId?: string | null,
        method?: DeliveryMethodChoice | null,
        primary?: boolean | null,
        numberLines?: number | null,
        frequency?: DeliveryFrequencyChoice | null,
        frequencyCustomValue?: number | null,
        filtrationType?: FiltrationTypeChoice | null,
        filtrationTypeCustomValue?: string | null,
        filtrationDescription?: string | null,
        flowRateTypical?: number | null,
        flowRateMax?: number | null,
        pipingSize?: number | null,
        offloadLocations?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    storage?:  {
      __typename: "ModelStorageConnection",
      items:  Array< {
        __typename: "Storage",
        id: string,
        airportId?: string | null,
        organizationId?: string | null,
        tankId: string,
        yearInstalled: string,
        product: Array< StorageProductChoice | null >,
        storageLocation: StorageLocationChoice,
        storageASTUST: StorageASTUSTChoice,
        nextAPI653?: string | null,
        storageFunction: Array< StorageFunctionChoice | null >,
        totalBBL?: number | null,
        usableBBL?: number | null,
        opStatus: StorageOpStatusChoice,
        owner?: string | null,
        security?: string | null,
        secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
        cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
        leakDetection?: string | null,
        totalGals?: number | null,
        usableGals?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt?: string | null,
    createdBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    modifications?:  Array< {
      __typename: "ModifyRecord",
      fieldName?: string | null,
      fieldValue?: string | null,
      dataOperation?: DataOperation | null,
      modifiedAt?: string | null,
      modifiedBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    organizationAirportsId?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnCreateAirportConsortiumGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAirportConsortiumGroupFilterInput | null,
};

export type OnCreateAirportConsortiumGroupSubscription = {
  onCreateAirportConsortiumGroup?:  {
    __typename: "AirportConsortiumGroup",
    id: string,
    organizationId: string,
    airportId: string,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContact?: string | null,
    facilityOwner?: string | null,
    facilityOwnerContact?: string | null,
    legalCounselContact?: string | null,
    legalCounsel?: string | null,
    facilityLocation?: FacilityLocationTypeChoice | null,
    totalCapacityDays?: number | null,
    totalCapacityGallons?: number | null,
    secured?: boolean | null,
    moExpiration?: string | null,
    moType?: MOTypeChoice | null,
    moTypeCustomValue?: string | null,
    leaseExpiration?: string | null,
    leaseType?: LeaseTypeChoice | null,
    leaseTypeCustomValue?: string | null,
    dateLastFinancialSUP?: string | null,
    dateLastA4aOperationalReview?: string | null,
    netInventoryCalculation?: string | null,
    entryFee?: string | null,
    nonMemberRate?: string | null,
    costSharingFormula?: string | null,
    reserveDepositPrebill?: ReservePrebillTypeChoice | null,
    thirdPartyDebt?: number | null,
    memberLoans?: number | null,
    dateLastPropertyAppraisal?: string | null,
    aviationLiabilityLimit?: number | null,
    environmentalLiabilityLimit?: number | null,
    comments?: string | null,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAirportConsortiumGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAirportConsortiumGroupFilterInput | null,
};

export type OnUpdateAirportConsortiumGroupSubscription = {
  onUpdateAirportConsortiumGroup?:  {
    __typename: "AirportConsortiumGroup",
    id: string,
    organizationId: string,
    airportId: string,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContact?: string | null,
    facilityOwner?: string | null,
    facilityOwnerContact?: string | null,
    legalCounselContact?: string | null,
    legalCounsel?: string | null,
    facilityLocation?: FacilityLocationTypeChoice | null,
    totalCapacityDays?: number | null,
    totalCapacityGallons?: number | null,
    secured?: boolean | null,
    moExpiration?: string | null,
    moType?: MOTypeChoice | null,
    moTypeCustomValue?: string | null,
    leaseExpiration?: string | null,
    leaseType?: LeaseTypeChoice | null,
    leaseTypeCustomValue?: string | null,
    dateLastFinancialSUP?: string | null,
    dateLastA4aOperationalReview?: string | null,
    netInventoryCalculation?: string | null,
    entryFee?: string | null,
    nonMemberRate?: string | null,
    costSharingFormula?: string | null,
    reserveDepositPrebill?: ReservePrebillTypeChoice | null,
    thirdPartyDebt?: number | null,
    memberLoans?: number | null,
    dateLastPropertyAppraisal?: string | null,
    aviationLiabilityLimit?: number | null,
    environmentalLiabilityLimit?: number | null,
    comments?: string | null,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAirportConsortiumGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAirportConsortiumGroupFilterInput | null,
};

export type OnDeleteAirportConsortiumGroupSubscription = {
  onDeleteAirportConsortiumGroup?:  {
    __typename: "AirportConsortiumGroup",
    id: string,
    organizationId: string,
    airportId: string,
    airport?:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    chairId?: string | null,
    chair?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    chairContactId?: string | null,
    chairContact?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    operatorId?: string | null,
    operator?:  {
      __typename: "Company",
      id: string,
      name: string,
      type?: CompanyTypeChoice | null,
      organizations?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      deleted?: boolean | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      updatedAt: string,
      organizationCompaniesId?: string | null,
    } | null,
    operatorGeneralManagerId?: string | null,
    operatorGeneralManager?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    secondaryContact?: string | null,
    facilityOwner?: string | null,
    facilityOwnerContact?: string | null,
    legalCounselContact?: string | null,
    legalCounsel?: string | null,
    facilityLocation?: FacilityLocationTypeChoice | null,
    totalCapacityDays?: number | null,
    totalCapacityGallons?: number | null,
    secured?: boolean | null,
    moExpiration?: string | null,
    moType?: MOTypeChoice | null,
    moTypeCustomValue?: string | null,
    leaseExpiration?: string | null,
    leaseType?: LeaseTypeChoice | null,
    leaseTypeCustomValue?: string | null,
    dateLastFinancialSUP?: string | null,
    dateLastA4aOperationalReview?: string | null,
    netInventoryCalculation?: string | null,
    entryFee?: string | null,
    nonMemberRate?: string | null,
    costSharingFormula?: string | null,
    reserveDepositPrebill?: ReservePrebillTypeChoice | null,
    thirdPartyDebt?: number | null,
    memberLoans?: number | null,
    dateLastPropertyAppraisal?: string | null,
    aviationLiabilityLimit?: number | null,
    environmentalLiabilityLimit?: number | null,
    comments?: string | null,
    deleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeliveryToPlaneSubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryToPlaneFilterInput | null,
};

export type OnCreateDeliveryToPlaneSubscription = {
  onCreateDeliveryToPlane?:  {
    __typename: "DeliveryToPlane",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportDeliveryToPlaneId?: string | null,
    obFiltration?: boolean | null,
    hydrant?: HydrantChoice | null,
    closedLoop: ClosedLoopChoice,
    length?: number | null,
    hydrantLocations?: string | null,
    pits?: number | null,
    refuelerLocations?: string | null,
    efso?: boolean | null,
    efsoLocation?: string | null,
    leakDetection?: boolean | null,
    leakDetectionDescription?: string | null,
    flowRateF1Peak?: number | null,
    flowRateF1Max?: number | null,
    flowRateF2Peak?: number | null,
    flowRateF2Max?: number | null,
    flowRateF3Peak?: number | null,
    flowRateF3Max?: number | null,
    pipingSizeMin?: number | null,
    pipingSizeMax?: number | null,
    currentIntoPlaneOperators?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeliveryToPlaneSubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryToPlaneFilterInput | null,
};

export type OnUpdateDeliveryToPlaneSubscription = {
  onUpdateDeliveryToPlane?:  {
    __typename: "DeliveryToPlane",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportDeliveryToPlaneId?: string | null,
    obFiltration?: boolean | null,
    hydrant?: HydrantChoice | null,
    closedLoop: ClosedLoopChoice,
    length?: number | null,
    hydrantLocations?: string | null,
    pits?: number | null,
    refuelerLocations?: string | null,
    efso?: boolean | null,
    efsoLocation?: string | null,
    leakDetection?: boolean | null,
    leakDetectionDescription?: string | null,
    flowRateF1Peak?: number | null,
    flowRateF1Max?: number | null,
    flowRateF2Peak?: number | null,
    flowRateF2Max?: number | null,
    flowRateF3Peak?: number | null,
    flowRateF3Max?: number | null,
    pipingSizeMin?: number | null,
    pipingSizeMax?: number | null,
    currentIntoPlaneOperators?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeliveryToPlaneSubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryToPlaneFilterInput | null,
};

export type OnDeleteDeliveryToPlaneSubscription = {
  onDeleteDeliveryToPlane?:  {
    __typename: "DeliveryToPlane",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    airportDeliveryToPlaneId?: string | null,
    obFiltration?: boolean | null,
    hydrant?: HydrantChoice | null,
    closedLoop: ClosedLoopChoice,
    length?: number | null,
    hydrantLocations?: string | null,
    pits?: number | null,
    refuelerLocations?: string | null,
    efso?: boolean | null,
    efsoLocation?: string | null,
    leakDetection?: boolean | null,
    leakDetectionDescription?: string | null,
    flowRateF1Peak?: number | null,
    flowRateF1Max?: number | null,
    flowRateF2Peak?: number | null,
    flowRateF2Max?: number | null,
    flowRateF3Peak?: number | null,
    flowRateF3Max?: number | null,
    pipingSizeMin?: number | null,
    pipingSizeMax?: number | null,
    currentIntoPlaneOperators?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeliveryToFacilitySubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryToFacilityFilterInput | null,
};

export type OnCreateDeliveryToFacilitySubscription = {
  onCreateDeliveryToFacility?:  {
    __typename: "DeliveryToFacility",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    provider?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    method?: DeliveryMethodChoice | null,
    primary?: boolean | null,
    numberLines?: number | null,
    frequency?: DeliveryFrequencyChoice | null,
    frequencyCustomValue?: number | null,
    filtrationType?: FiltrationTypeChoice | null,
    filtrationTypeCustomValue?: string | null,
    filtrationDescription?: string | null,
    flowRateTypical?: number | null,
    flowRateMax?: number | null,
    pipingSize?: number | null,
    offloadLocations?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeliveryToFacilitySubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryToFacilityFilterInput | null,
};

export type OnUpdateDeliveryToFacilitySubscription = {
  onUpdateDeliveryToFacility?:  {
    __typename: "DeliveryToFacility",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    provider?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    method?: DeliveryMethodChoice | null,
    primary?: boolean | null,
    numberLines?: number | null,
    frequency?: DeliveryFrequencyChoice | null,
    frequencyCustomValue?: number | null,
    filtrationType?: FiltrationTypeChoice | null,
    filtrationTypeCustomValue?: string | null,
    filtrationDescription?: string | null,
    flowRateTypical?: number | null,
    flowRateMax?: number | null,
    pipingSize?: number | null,
    offloadLocations?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeliveryToFacilitySubscriptionVariables = {
  filter?: ModelSubscriptionDeliveryToFacilityFilterInput | null,
};

export type OnDeleteDeliveryToFacilitySubscription = {
  onDeleteDeliveryToFacility?:  {
    __typename: "DeliveryToFacility",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    provider?: string | null,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    method?: DeliveryMethodChoice | null,
    primary?: boolean | null,
    numberLines?: number | null,
    frequency?: DeliveryFrequencyChoice | null,
    frequencyCustomValue?: number | null,
    filtrationType?: FiltrationTypeChoice | null,
    filtrationTypeCustomValue?: string | null,
    filtrationDescription?: string | null,
    flowRateTypical?: number | null,
    flowRateMax?: number | null,
    pipingSize?: number | null,
    offloadLocations?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateStorageSubscriptionVariables = {
  filter?: ModelSubscriptionStorageFilterInput | null,
};

export type OnCreateStorageSubscription = {
  onCreateStorage?:  {
    __typename: "Storage",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    tankId: string,
    yearInstalled: string,
    product: Array< StorageProductChoice | null >,
    storageLocation: StorageLocationChoice,
    storageASTUST: StorageASTUSTChoice,
    nextAPI653?: string | null,
    storageFunction: Array< StorageFunctionChoice | null >,
    totalBBL?: number | null,
    usableBBL?: number | null,
    opStatus: StorageOpStatusChoice,
    owner?: string | null,
    security?: string | null,
    secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
    cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
    leakDetection?: string | null,
    totalGals?: number | null,
    usableGals?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateStorageSubscriptionVariables = {
  filter?: ModelSubscriptionStorageFilterInput | null,
};

export type OnUpdateStorageSubscription = {
  onUpdateStorage?:  {
    __typename: "Storage",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    tankId: string,
    yearInstalled: string,
    product: Array< StorageProductChoice | null >,
    storageLocation: StorageLocationChoice,
    storageASTUST: StorageASTUSTChoice,
    nextAPI653?: string | null,
    storageFunction: Array< StorageFunctionChoice | null >,
    totalBBL?: number | null,
    usableBBL?: number | null,
    opStatus: StorageOpStatusChoice,
    owner?: string | null,
    security?: string | null,
    secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
    cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
    leakDetection?: string | null,
    totalGals?: number | null,
    usableGals?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteStorageSubscriptionVariables = {
  filter?: ModelSubscriptionStorageFilterInput | null,
};

export type OnDeleteStorageSubscription = {
  onDeleteStorage?:  {
    __typename: "Storage",
    id: string,
    airportId?: string | null,
    airport:  {
      __typename: "Airport",
      id: string,
      name: string,
      code: string,
      oldCode?: string | null,
      facility?: string | null,
      region?: AirportRegionChoice | null,
      state: string,
      place?:  {
        __typename: "Place",
        terminal?: string | null,
        gate?: string | null,
        lat?: string | null,
        lon?: string | null,
      } | null,
      consortiumGroup?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      consortiumType?: GroupTypeChoice | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorGeneralManagerId?: string | null,
      operatorGeneralManager?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      secondaryContactId?: string | null,
      secondaryContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      facilityOwnerId?: string | null,
      facilityOwner?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      facilityOwnerContactId?: string | null,
      facilityOwnerContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      inventoryAccounting?: InventoryAccountingTypeChoice | null,
      deliveryToPlane?:  {
        __typename: "ModelDeliveryToPlaneConnection",
        nextToken?: string | null,
      } | null,
      deliveryToFacility?:  {
        __typename: "ModelDeliveryToFacilityConnection",
        nextToken?: string | null,
      } | null,
      storage?:  {
        __typename: "ModelStorageConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAirportsId?: string | null,
      deleted?: boolean | null,
      updatedAt: string,
    },
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    tankId: string,
    yearInstalled: string,
    product: Array< StorageProductChoice | null >,
    storageLocation: StorageLocationChoice,
    storageASTUST: StorageASTUSTChoice,
    nextAPI653?: string | null,
    storageFunction: Array< StorageFunctionChoice | null >,
    totalBBL?: number | null,
    usableBBL?: number | null,
    opStatus: StorageOpStatusChoice,
    owner?: string | null,
    security?: string | null,
    secondaryContainment?: Array< StorageSecondaryContainmentChoice | null > | null,
    cathodicProtection?: Array< StorageCathodicProtectionChoice | null > | null,
    leakDetection?: string | null,
    totalGals?: number | null,
    usableGals?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id?: string | null,
    type?: NotificationType | null,
    method?: NotificationMethod | null,
    scope?:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null > | null,
    notificationDate?: string | null,
    title?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id?: string | null,
    type?: NotificationType | null,
    method?: NotificationMethod | null,
    scope?:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null > | null,
    notificationDate?: string | null,
    title?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id?: string | null,
    type?: NotificationType | null,
    method?: NotificationMethod | null,
    scope?:  Array< {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null > | null,
    notificationDate?: string | null,
    title?: string | null,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateModifyRecordSubscriptionVariables = {
  filter?: ModelSubscriptionModifyRecordFilterInput | null,
};

export type OnCreateModifyRecordSubscription = {
  onCreateModifyRecord?:  {
    __typename: "ModifyRecord",
    fieldName?: string | null,
    fieldValue?: string | null,
    dataOperation?: DataOperation | null,
    modifiedAt?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateModifyRecordSubscriptionVariables = {
  filter?: ModelSubscriptionModifyRecordFilterInput | null,
};

export type OnUpdateModifyRecordSubscription = {
  onUpdateModifyRecord?:  {
    __typename: "ModifyRecord",
    fieldName?: string | null,
    fieldValue?: string | null,
    dataOperation?: DataOperation | null,
    modifiedAt?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteModifyRecordSubscriptionVariables = {
  filter?: ModelSubscriptionModifyRecordFilterInput | null,
};

export type OnDeleteModifyRecordSubscription = {
  onDeleteModifyRecord?:  {
    __typename: "ModifyRecord",
    fieldName?: string | null,
    fieldValue?: string | null,
    dataOperation?: DataOperation | null,
    modifiedAt?: string | null,
    modifiedBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateInviteLogSubscriptionVariables = {
  filter?: ModelSubscriptionInviteLogFilterInput | null,
};

export type OnCreateInviteLogSubscription = {
  onCreateInviteLog?:  {
    __typename: "InviteLog",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    isAccepted?: boolean | null,
    type?: InviteType | null,
    sendByUserId?: string | null,
    sendBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    createdAt?: string | null,
    acceptedAt?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateInviteLogSubscriptionVariables = {
  filter?: ModelSubscriptionInviteLogFilterInput | null,
};

export type OnUpdateInviteLogSubscription = {
  onUpdateInviteLog?:  {
    __typename: "InviteLog",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    isAccepted?: boolean | null,
    type?: InviteType | null,
    sendByUserId?: string | null,
    sendBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    createdAt?: string | null,
    acceptedAt?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteInviteLogSubscriptionVariables = {
  filter?: ModelSubscriptionInviteLogFilterInput | null,
};

export type OnDeleteInviteLogSubscription = {
  onDeleteInviteLog?:  {
    __typename: "InviteLog",
    id: string,
    organizationId?: string | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    } | null,
    userId?: string | null,
    user?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    isAccepted?: boolean | null,
    type?: InviteType | null,
    sendByUserId?: string | null,
    sendBy?:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    } | null,
    createdAt?: string | null,
    acceptedAt?: string | null,
    deleted?: boolean | null,
    updatedAt: string,
  } | null,
};

export type OnCreateOrgAdminUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrgAdminUserFilterInput | null,
};

export type OnCreateOrgAdminUserSubscription = {
  onCreateOrgAdminUser?:  {
    __typename: "OrgAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrgAdminUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrgAdminUserFilterInput | null,
};

export type OnUpdateOrgAdminUserSubscription = {
  onUpdateOrgAdminUser?:  {
    __typename: "OrgAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrgAdminUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrgAdminUserFilterInput | null,
};

export type OnDeleteOrgAdminUserSubscription = {
  onDeleteOrgAdminUser?:  {
    __typename: "OrgAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrgMemberUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrgMemberUserFilterInput | null,
};

export type OnCreateOrgMemberUserSubscription = {
  onCreateOrgMemberUser?:  {
    __typename: "OrgMemberUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrgMemberUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrgMemberUserFilterInput | null,
};

export type OnUpdateOrgMemberUserSubscription = {
  onUpdateOrgMemberUser?:  {
    __typename: "OrgMemberUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrgMemberUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrgMemberUserFilterInput | null,
};

export type OnDeleteOrgMemberUserSubscription = {
  onDeleteOrgMemberUser?:  {
    __typename: "OrgMemberUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateOrgSupportAdminUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrgSupportAdminUserFilterInput | null,
};

export type OnCreateOrgSupportAdminUserSubscription = {
  onCreateOrgSupportAdminUser?:  {
    __typename: "OrgSupportAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateOrgSupportAdminUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrgSupportAdminUserFilterInput | null,
};

export type OnUpdateOrgSupportAdminUserSubscription = {
  onUpdateOrgSupportAdminUser?:  {
    __typename: "OrgSupportAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteOrgSupportAdminUserSubscriptionVariables = {
  filter?: ModelSubscriptionOrgSupportAdminUserFilterInput | null,
};

export type OnDeleteOrgSupportAdminUserSubscription = {
  onDeleteOrgSupportAdminUser?:  {
    __typename: "OrgSupportAdminUser",
    id: string,
    organizationId: string,
    userId: string,
    organization:  {
      __typename: "Organization",
      id: string,
      name: string,
      organizationType?: OrganizationTypeChoice | null,
      groupType?: GroupTypeChoice | null,
      chairId?: string | null,
      chair?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      chairContactId?: string | null,
      chairContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      operatorId?: string | null,
      operator?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      operatorContactId?: string | null,
      operatorContact?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      admins?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      airports?:  {
        __typename: "ModelAirportConsortiumGroupConnection",
        nextToken?: string | null,
      } | null,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      members?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      documents?:  {
        __typename: "ModelDocumentConnection",
        nextToken?: string | null,
      } | null,
      calendar?:  {
        __typename: "ModelCalendarConnection",
        nextToken?: string | null,
      } | null,
      contacts?:  {
        __typename: "ModelUserConnection",
        nextToken?: string | null,
      } | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      supportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      updatedAt: string,
      companyOrganizationsId?: string | null,
      userOrganizationId?: string | null,
    },
    user:  {
      __typename: "User",
      id: string,
      cognitoUserId?: string | null,
      termsAgree?: boolean | null,
      termsAgreeDate?: string | null,
      participationAgreement?: boolean | null,
      participationAgreementDate?: string | null,
      organization?:  {
        __typename: "ModelOrganizationConnection",
        nextToken?: string | null,
      } | null,
      roles?: Array< string | null > | null,
      companyId?: string | null,
      company?:  {
        __typename: "Company",
        id: string,
        name: string,
        type?: CompanyTypeChoice | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        updatedAt: string,
        organizationCompaniesId?: string | null,
      } | null,
      firstName: string,
      lastName: string,
      email: string,
      office?: string | null,
      mobile?: string | null,
      linkedInUrl?: string | null,
      imageUrl?: string | null,
      timeZone?: string | null,
      monthlyUpdates?: boolean | null,
      generalAnnouncements?: boolean | null,
      createdAt?: string | null,
      createdByUserId?: string | null,
      createdBy?:  {
        __typename: "User",
        id: string,
        cognitoUserId?: string | null,
        termsAgree?: boolean | null,
        termsAgreeDate?: string | null,
        participationAgreement?: boolean | null,
        participationAgreementDate?: string | null,
        roles?: Array< string | null > | null,
        companyId?: string | null,
        firstName: string,
        lastName: string,
        email: string,
        office?: string | null,
        mobile?: string | null,
        linkedInUrl?: string | null,
        imageUrl?: string | null,
        timeZone?: string | null,
        monthlyUpdates?: boolean | null,
        generalAnnouncements?: boolean | null,
        createdAt?: string | null,
        createdByUserId?: string | null,
        deleted?: boolean | null,
        lastLoginDate?: string | null,
        weeklyDigestNotification?: boolean | null,
        monthlyDigestNotification?: boolean | null,
        quarterlyEmailNotification?: boolean | null,
        annualDigestNotification?: boolean | null,
        membershipAlertsNotification?: boolean | null,
        generalEmailNotification?: boolean | null,
        updatedAt: string,
        organizationContactsId?: string | null,
      } | null,
      modifications?:  Array< {
        __typename: "ModifyRecord",
        fieldName?: string | null,
        fieldValue?: string | null,
        dataOperation?: DataOperation | null,
        modifiedAt?: string | null,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      organizationAdmin?:  {
        __typename: "ModelOrgAdminUserConnection",
        nextToken?: string | null,
      } | null,
      organizationMember?:  {
        __typename: "ModelOrgMemberUserConnection",
        nextToken?: string | null,
      } | null,
      deleted?: boolean | null,
      lastLoginDate?: string | null,
      weeklyDigestNotification?: boolean | null,
      monthlyDigestNotification?: boolean | null,
      quarterlyEmailNotification?: boolean | null,
      annualDigestNotification?: boolean | null,
      membershipAlertsNotification?: boolean | null,
      generalEmailNotification?: boolean | null,
      organizationSupportAdmin?:  {
        __typename: "ModelOrgSupportAdminUserConnection",
        nextToken?: string | null,
      } | null,
      updatedAt: string,
      organizationContactsId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
