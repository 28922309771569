import React, { createContext, useEffect, useReducer } from "react";
import * as x_quries from "./../../graphql/customQueries";
import { IDataState, IDataContext, IDataProvider } from "./types";
import { useAuth } from "../AuthProvider";
import { constants } from "../../utils";
import { DocumentService } from "./../../services/DocumentService";
import { OrganizationService } from "./../../services/OrganizationService";
import { AirportConsortiumGroupService } from "./../../services/AirportConsortiumGroupService";
import { InviteLogService } from "./../../services/InviteLogService";
import { DocumentCategoryService } from "./../../services/DocumentCategoryService";
import { DocumentBucketTypeChoice } from "../../API";

const dataContextAction = {
  SET_ORGANIZATION_LIST: "SET_ORGANIZATION_LIST",
  SET_GLOBAL_DOCUMENT_LIST: "SET_GLOBAL_DOCUMENT_LIST",
  SET_GLOBAL_DOCUMENT_CATEGORY: "SET_GLOBAL_DOCUMENT_CATEGORY",
  SET_GLOBAL_DOCUMENT_LIST_API_LOADING: "SET_GLOBAL_DOCUMENT_LIST_API_LOADING",
  SET_ORGANIZATION_LIST_API_LOADING: "SET_ORGANIZATION_LIST_API_LOADING",
  RESET_STATE: "RESET_STATE",
  SET_AIRPORT_CONSORTIUM_GROUP_LIST: "SET_AIRPORT_CONSORTIUM_GROUP_LIST",
  SET_AIRPORT_CONSORTIUM_GROUP_API_LOADING:
    "SET_AIRPORT_CONSORTIUM_GROUP_API_LOADING",
  SET_INVITE_LOG_LIST: "SET_INVITE_LOG_LIST",
  SET_INVITE_LOG_LIST_API_LOADING: "SET_INVITE_LOG_LIST_API_LOADING",
  SET_TOP_BAR_SEARCH_TEXT: "SET_TOP_BAR_SEARCH_TEXT",
  SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT: "SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT",
  SET_DOCUMENT_CATEGORY_LIST: "SET_DOCUMENT_CATEGORY_LIST",
  SET_DOCUMENT_CATEGORY_LIST_API_LOADING:
    "SET_DOCUMENT_CATEGORY_LIST_API_LOADING",
  SET_ALL_PENDING_INVITE_LIST: "SET_ALL_PENDING_INVITE_LIST",
  SET_ALL_PENDING_INVITE_LIST_API_LOADING:
    "SET_ALL_PENDING_INVITE_LIST_API_LOADING",
  SET_AIRPORT_DOCUMENT_LIST: "SET_AIRPORT_DOCUMENT_LIST",
  SET_AIRPORT_DOCUMENT_LIST_API_LOADING:
    "SET_AIRPORT_DOCUMENT_LIST_API_LOADING",
};

type Action = { type: string; payload: any };

const initialDataState: IDataState = {
  organizationList: [],
  globalDocumentList: [],
  isGlobalDocumentListApiLoading: true,
  isOrganizationListApiLoading: true,
  isUserA4AAdmin: false,
  airportConsortiumGroupList: [],
  isAirportConsortiumGroupListApiLoading: true,
  inviteLogList: [],
  isInviteLogListApiLoading: true,
  topBarSearchText: "",
  topBarSearchPlaceholderText: "Search...",
  documentCategoryList: [],
  isDocumentCategoryListApiLoading: true,
  allPendingInviteList: [],
  isAllPendingInviteListApiLoading: true,
  airportDocumentList: [],
  isAirportDocumentListApiLoading: true,
};

function dataReducer(state: IDataState, action: Action): IDataState {
  switch (action.type) {
    case dataContextAction.SET_ORGANIZATION_LIST:
      return { ...state, organizationList: action.payload };
    case dataContextAction.SET_GLOBAL_DOCUMENT_LIST:
      return { ...state, globalDocumentList: action.payload };
    case dataContextAction.SET_GLOBAL_DOCUMENT_LIST_API_LOADING:
      return { ...state, isGlobalDocumentListApiLoading: action.payload };
    case dataContextAction.SET_ORGANIZATION_LIST_API_LOADING:
      return { ...state, isOrganizationListApiLoading: action.payload };
    case dataContextAction.RESET_STATE:
      return { ...action.payload };
    case dataContextAction.SET_AIRPORT_CONSORTIUM_GROUP_LIST:
      return { ...state, airportConsortiumGroupList: action.payload };
    case dataContextAction.SET_TOP_BAR_SEARCH_TEXT:
      return { ...state, topBarSearchText: action.payload };
    case dataContextAction.SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT:
      return { ...state, topBarSearchPlaceholderText: action.payload };
    case dataContextAction.SET_DOCUMENT_CATEGORY_LIST:
      return { ...state, documentCategoryList: action.payload };
    case dataContextAction.SET_DOCUMENT_CATEGORY_LIST_API_LOADING:
      return { ...state, isDocumentCategoryListApiLoading: action.payload };
    case dataContextAction.SET_ALL_PENDING_INVITE_LIST:
      return { ...state, allPendingInviteList: action.payload };
    case dataContextAction.SET_ALL_PENDING_INVITE_LIST_API_LOADING:
      return { ...state, isAllPendingInviteListApiLoading: action.payload };
    case dataContextAction.SET_AIRPORT_DOCUMENT_LIST:
      return { ...state, airportDocumentList: action.payload };
    case dataContextAction.SET_AIRPORT_DOCUMENT_LIST_API_LOADING:
      return { ...state, isAirportDocumentListApiLoading: action.payload };
    default:
      return state;
  }
}

const DataContext = createContext<IDataContext | undefined>(undefined);

const DataProvider: React.FC<IDataProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(dataReducer, initialDataState);
  const { state: authProviderState } = useAuth();

  const apiDocument = new DocumentService();
  const apiOrganization = new OrganizationService();
  const apiAirportConsortiumGroup = new AirportConsortiumGroupService();
  const apiInviteLog = new InviteLogService();
  const apiDocumentCategories = new DocumentCategoryService();

  const fetchOrganizations = async (
    filter: any = {},
    airConGrpFilter: any = {},
    currentOrganizations: Array<any>,
    reqNextToken: string | null = null
  ) => {
    dispatch({
      type: dataContextAction.SET_ORGANIZATION_LIST_API_LOADING,
      payload: true,
    });

    const { organizations, nextToken } =
      await apiOrganization.getAllOrganizations(
        filter,
        reqNextToken,
        x_quries.sg_CustomListOrganizations
      );

    let allOrganization = [...currentOrganizations, ...organizations];

    if (nextToken) {
      await fetchOrganizations(
        filter,
        airConGrpFilter,
        allOrganization,
        nextToken
      );
    } else {
      // sort org data
      allOrganization.sort((itemA: any, itemB: any) => {
        if (itemA.name < itemB.name) {
          return -1;
        }
        if (itemA.name > itemB.name) {
          return 1;
        }
        return 0;
      });

      // dispatch({
      //   type: dataContextAction.SET_ORGANIZATION_LIST,
      //   payload: allOrganization,
      // });

      // dispatch({
      //   type: dataContextAction.SET_ORGANIZATION_LIST_API_LOADING,
      //   payload: false,
      // });

      // if organization array not empty
      if (allOrganization?.length) {
        // await fetchInviteLogs(allOrganization, []);
        await fetchAirportConsortiumGroup(allOrganization, airConGrpFilter, []);
      }
    }
  };

  const fetchGlobalDocuments = async (
    currentGlobalDocuments: Array<any>,
    reqNextToken: string | null = null,
    documentCategories: any[] = []
  ) => {
    // global document filter
    const filter = {
      bucketType: { eq: constants.DocumentBucketTypeChoice.GENERAL },
    };

    dispatch({
      type: dataContextAction.SET_GLOBAL_DOCUMENT_LIST_API_LOADING,
      payload: true,
    });

    const { documents, nextToken } = await apiDocument.getAllDocuments(
      filter,
      reqNextToken
    );

    let allDocuments = [...currentGlobalDocuments, ...documents];

    if (nextToken) {
      await fetchGlobalDocuments(allDocuments, nextToken, documentCategories);
    } else {
      let filtered: any = {};

      documentCategories.forEach((category) => {
        if (category.bucketType !== DocumentBucketTypeChoice.AIRPORT) {
          filtered[category.id] = [];
        }
      });

      // sort documents data
      allDocuments.sort((itemA: any, itemB: any) => {
        if (itemA.title < itemB.title) {
          return -1;
        }
        if (itemA.title > itemB.title) {
          return 1;
        }
        return 0;
      });

      // filter documents by category
      allDocuments?.forEach((document) => {
        filtered[document?.documentCategoryId] = filtered[
          document?.documentCategoryId
        ]?.length
          ? [...filtered[document?.documentCategoryId], document]
          : [document];
      });

      dispatch({
        type: dataContextAction.SET_GLOBAL_DOCUMENT_LIST,
        payload: filtered,
      });

      dispatch({
        type: dataContextAction.SET_GLOBAL_DOCUMENT_LIST_API_LOADING,
        payload: false,
      });
    }
  };

  const fetchAirportDocuments = async (
    currentAirportDocuments: Array<any>,
    reqNextToken: string | null = null
  ) => {
    // global document filter
    const filter = {
      bucketType: { eq: constants.DocumentBucketTypeChoice.AIRPORT },
    };

    dispatch({
      type: dataContextAction.SET_AIRPORT_DOCUMENT_LIST_API_LOADING,
      payload: true,
    });

    const { documents, nextToken } = await apiDocument.getAllDocuments(
      filter,
      reqNextToken,
      x_quries.sg_CustomAirportDocumentList
    );

    let allDocuments = [...currentAirportDocuments, ...documents];

    if (nextToken) {
      await fetchAirportDocuments(allDocuments, nextToken);
    } else {
      // let filtered: any = {};

      // documentCategories.forEach((category) => {
      //   if(category.bucketType !== DocumentBucketTypeChoice.AIRPORT){
      //     filtered[category.id] = []
      //   }
      // })

      // sort documents data
      allDocuments.sort((itemA: any, itemB: any) => {
        if (itemA.title < itemB.title) {
          return -1;
        }
        if (itemA.title > itemB.title) {
          return 1;
        }
        return 0;
      });

      // filter documents by category
      // allDocuments?.forEach((document) => {
      //   filtered[document?.documentCategoryId] = filtered[
      //     document?.documentCategoryId
      //   ]?.length
      //     ? [...filtered[document?.documentCategoryId], document]
      //     : [document];
      // });

      dispatch({
        type: dataContextAction.SET_AIRPORT_DOCUMENT_LIST,
        payload: allDocuments,
      });

      dispatch({
        type: dataContextAction.SET_AIRPORT_DOCUMENT_LIST_API_LOADING,
        payload: false,
      });
    }
  };

  const fetchDocumentCategories = async (
    currentDocumentCategories: Array<any>,
    reqNextToken: string | null = null
  ) => {
    // empty graphql filter
    const filter = {};

    dispatch({
      type: dataContextAction.SET_DOCUMENT_CATEGORY_LIST_API_LOADING,
      payload: true,
    });

    const { categories, nextToken } =
      await apiDocumentCategories.getAllDocumentCategories(
        filter,
        reqNextToken
      );

    let allDocumentCategories = [...currentDocumentCategories, ...categories];

    if (nextToken) {
      await fetchDocumentCategories(allDocumentCategories, nextToken);
    } else {
      // sort documents categories data
      allDocumentCategories.sort((itemA: any, itemB: any) => {
        if (itemA.name < itemB.name) {
          return -1;
        }
        if (itemA.name > itemB.name) {
          return 1;
        }
        return 0;
      });

      dispatch({
        type: dataContextAction.SET_DOCUMENT_CATEGORY_LIST,
        payload: allDocumentCategories,
      });

      dispatch({
        type: dataContextAction.SET_DOCUMENT_CATEGORY_LIST_API_LOADING,
        payload: false,
      });
      return allDocumentCategories;
    }
  };

  const fetchOrganizationToDisplay = async () => {
    const adminIds: any = authProviderState?.user?.orgRole?.admin?.map(
      (item) => item?.organizationId
    );
    const memberIds: any = authProviderState?.user?.orgRole?.member?.map(
      (item) => item?.organizationId
    );

    const finalIds = [...adminIds, ...memberIds];

    // remove duplicates
    const uniqueIds = finalIds.filter((obj, index) => {
      return index === finalIds.findIndex((o) => obj === o);
    });

    // if user has some org related to them
    if (uniqueIds.length !== 0) {
      // create graphql filter
      const filter = {
        or: uniqueIds?.map((t_id: string) => ({ id: { eq: t_id } })),
      };

      const filter_two = {
        or: uniqueIds?.map((t_id: string) => ({
          organizationId: { eq: t_id },
        })),
      };

      await fetchOrganizations(filter, filter_two, []);
      // await fetchAirportConsortiumGroup([], filter_two, []);
    }
  };

  const fetchAirportConsortiumGroup = async (
    organizations: Array<any>,
    filter: any = {},
    currentAirportConsortiumGroup: Array<any>,
    reqNextToken: string | null = null
  ) => {
    dispatch({
      type: dataContextAction.SET_AIRPORT_CONSORTIUM_GROUP_API_LOADING,
      payload: true,
    });

    const { airportConsortiumGroups, nextToken } =
      await apiAirportConsortiumGroup.getAllAirportConsortiumGroups(
        filter,
        reqNextToken,
        x_quries.sg_CustomListAirportConsortiumGroups
      );

    let allData = [
      ...currentAirportConsortiumGroup,
      ...airportConsortiumGroups,
    ];

    if (nextToken) {
      await fetchAirportConsortiumGroup(
        organizations,
        filter,
        allData,
        nextToken
      );
    } else {
      // console.log(allData)
      // console.log(
      //   allData?.map((item: any) => ({
      //     ...item?.organization,
      //     mapId: item?.id,
      //     chair: item?.chair,
      //     operator: item?.operator,
      //     airport: item?.airport,
      //     chairContact: item?.chairContact,
      //     operatorGeneralManager: item?.operatorGeneralManager,
      //     secondaryContact: item?.secondaryContact,
      //     facilityOwner: item?.facilityOwner,
      //     facilityOwnerContact: item?.facilityOwnerContact,
      //     legalCounselContact: item?.legalCounselContact,
      //     legalCounsel: item?.legalCounsel,
      //   }))
      // );

      let formatted = allData?.map((item: any) => ({
        ...item?.organization,
        mapId: item?.id,
        chair: item?.chair,
        operator: item?.operator,
        airport: item?.airport,
        chairContact: item?.chairContact,
        operatorGeneralManager: item?.operatorGeneralManager,
        secondaryContact: item?.secondaryContact,
        facilityOwner: item?.facilityOwner,
        facilityOwnerContact: item?.facilityOwnerContact,
        legalCounselContact: item?.legalCounselContact,
        legalCounsel: item?.legalCounsel,
        pending: [],
      }));

      const tempArr = [...organizations, ...formatted];

      // remove duplicates
      let tempUnique = tempArr.filter((obj, index) => {
        return index === tempArr.findIndex((o) => obj.id === o.id);
      });

      // remove org having value in airports array
      tempUnique = tempUnique?.filter(
        (item) => item?.airports?.items?.length === 0
      );

      tempUnique = tempUnique?.map((item) => ({
        ...item,
        airport: null,
        operatorGeneralManager: null,
      }));

      let tempArr_x = [...tempUnique, ...formatted];

      // sort org data
      tempArr_x.sort((itemA: any, itemB: any) => {
        if (itemA.airport?.code < itemB.airport?.code) {
          return -1;
        }
        if (itemA.airport?.code > itemB.airport?.code) {
          return 1;
        }
        return 0;
      });

      dispatch({
        type: dataContextAction.SET_AIRPORT_CONSORTIUM_GROUP_LIST,
        // payload: allData,
        payload: formatted,
      });

      dispatch({
        type: dataContextAction.SET_AIRPORT_CONSORTIUM_GROUP_API_LOADING,
        payload: false,
      });

      await fetchInviteLogs(tempArr_x, []);
    }
  };

  const fetchInviteLogs = async (
    organizations: Array<any>,
    currentLogs: Array<any>,
    reqNextToken: string | null = null
  ) => {
    dispatch({
      type: dataContextAction.SET_INVITE_LOG_LIST_API_LOADING,
      payload: true,
    });

    // only select not accepted invite
    const filter = {
      isAccepted: { ne: true },
    };

    const { invites, nextToken } = await apiInviteLog.getAllInviteLogs(
      filter,
      reqNextToken
    );

    let allData = [...currentLogs, ...invites];

    if (nextToken) {
      await fetchInviteLogs(organizations, allData, nextToken);
    } else {
      let obj: any = {};

      allData?.forEach((item: any) => {
        obj = {
          ...obj,
          [item?.organizationId]: obj[item?.organizationId]
            ? [...obj[item?.organizationId], item]
            : [item],
        };
      });

      const temp_org = organizations?.map((item) => ({
        ...item,
        pending: obj[item?.id] ? obj[item?.id] : [],
      }));

      dispatch({
        type: dataContextAction.SET_ORGANIZATION_LIST,
        payload: temp_org,
      });

      dispatch({
        type: dataContextAction.SET_INVITE_LOG_LIST,
        payload: allData,
      });

      dispatch({
        type: dataContextAction.SET_INVITE_LOG_LIST_API_LOADING,
        payload: false,
      });

      dispatch({
        type: dataContextAction.SET_ORGANIZATION_LIST_API_LOADING,
        payload: false,
      });
    }
  };

  const fetchAllPendingInviteLogs = async (
    currentLogs: Array<any>,
    reqNextToken: string | null = null
  ) => {
    dispatch({
      type: dataContextAction.SET_ALL_PENDING_INVITE_LIST_API_LOADING,
      payload: true,
    });

    // only select not accepted & not deleted invite
    const filter = {
      isAccepted: { ne: true },
      deleted: { ne: true },
    };

    const { invites, nextToken } = await apiInviteLog.getAllInviteLogs(
      filter,
      reqNextToken
    );

    let allData = [...currentLogs, ...invites];

    if (nextToken) {
      await fetchAllPendingInviteLogs(allData, nextToken);
    } else {
      dispatch({
        type: dataContextAction.SET_ALL_PENDING_INVITE_LIST,
        payload: allData,
      });

      dispatch({
        type: dataContextAction.SET_ALL_PENDING_INVITE_LIST_API_LOADING,
        payload: false,
      });
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     if (authProviderState?.isSignedIn) {
  //       if (!state.globalDocumentList.length) {
  //         await fetchGlobalDocuments([]);
  //       }
  //       if (
  //         authProviderState?.user?.groups.includes(constants?.ROLES?.A4A_ADMIN)
  //       ) {
  //         if (!state.organizationList.length) {
  //           await fetchOrganizations({}, {}, []);
  //           // await fetchAirportConsortiumGroup([], {}, []);
  //         }
  //       } else {
  //         await fetchOrganizationToDisplay();
  //       }
  //     } else {
  //       // reset state
  //       dispatch({
  //         type: dataContextAction.RESET_STATE,
  //         payload: initialDataState,
  //       });
  //     }
  //   })();
  // }, [authProviderState?.isSignedIn]);

  // fetch organization based on subscription updates
  useEffect(() => {
    // IIFE
    (async () => {
      if (authProviderState?.isSignedIn) {
        let localCategories: any = [];
        if (!state.documentCategoryList.length) {
          localCategories = await fetchDocumentCategories([]);
          // console.log(localCategories)
        }
        if (!state.globalDocumentList.length) {
          await fetchGlobalDocuments([], null, localCategories);
        }
        if (!state.airportDocumentList.length) {
          await fetchAirportDocuments([], null);
        }
        if (
          authProviderState?.user?.groups?.includes(constants?.ROLES?.A4A_ADMIN)
        ) {
          if (!state.organizationList.length) {
            await fetchOrganizations({}, {}, []);
            // await fetchAirportConsortiumGroup([], {}, []);
          }
          if (!state.allPendingInviteList.length) {
            await fetchAllPendingInviteLogs([]);
          }
        } else {
          await fetchOrganizationToDisplay();
        }
      } else {
        // reset state
        dispatch({
          type: dataContextAction.RESET_STATE,
          payload: initialDataState,
        });
      }
    })();
  }, [
    authProviderState?.isSignedIn,
    authProviderState?.user?.orgRole?.admin.length,
    authProviderState?.user?.orgRole?.member.length,
    authProviderState?.user?.orgRole?.supportAdmin.length,
  ]);

  return (
    <DataContext.Provider value={{ state, dispatch }}>
      {children}
    </DataContext.Provider>
  );
};

function useDataContext(): IDataContext {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error("useDataContext must be used within an DataProvider");
  }
  return context;
}

export { DataProvider, useDataContext, dataContextAction };
