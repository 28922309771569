import React, { useEffect, FC, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { CardContent, Typography, Button } from "@mui/material";
import { DataTable } from "../Common/DataTable";
import { GridColDef } from "@mui/x-data-grid";
import { Sidebar } from "../Common/Sidebar";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { InviteUsers } from "../InviteUsers";

import { CognitoService } from "../../services/CognitoService";
import { constants } from "../../utils";
import { AddRole } from "../AddRole";

// prop types
interface IUserAdministration {}

const options = ["Assign Roles", "Disable Account", "Remove Account"];

const ITEM_HEIGHT = 48;

const UserAdministration: FC<IUserAdministration> = ({}) => {
  // state
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [openInviteUser, setOpenInviteUser] = useState<boolean>(false);
  const [openAddUserRole, setOpenAddUserRole] = useState<boolean>(false);
  const [users, setUsers] = useState<any>([]);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>("");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // componentDidMount
  useEffect(() => {
    // IIFE
    (async () => {
      await getListOfCognitoUsers();
    })();

    // set page title
    document.title = constants.PAGE_TITLE.USERS;
    // cleanup
    return () => {};
  }, []);

  useEffect(() => {
    // set page title
    if (openAddUserRole) {
      document.title = constants.PAGE_TITLE.ASSIGN_ROLES;
    } else if (openInviteUser) {
      document.title = constants.PAGE_TITLE.INVITE_USER;
    } else {
      document.title = constants.PAGE_TITLE.USERS;
    }
  }, [openAddUserRole, openInviteUser]);

  // get list of cognito users
  const getListOfCognitoUsers = async () => {
    const cs = new CognitoService();

    const data: any = await cs.getUserList();

    const tdx = data?.Users?.map((user: any) => {
      let temp = {};

      user?.Attributes?.forEach((item: any) => {
        temp = { ...temp, [item?.Name]: item?.Value };
      });

      return {
        id: user?.Username,
        status: user?.UserStatus,
        key: temp,
        enabled: user?.Enabled,
      };
    });

    // update user states
    setUsers([...tdx]);

    // stop table loader
    setTableLoading(false);
  };

  const handleMenuItemClick = async (
    event: any,
    option: string,
    email: string,
    params: any
  ) => {
    console.log(params);
    // console.log(email)
    if (option === "Assign Roles") {
      // open add user role sidebar
      setOpenAddUserRole(true);
    }

    if (option === "Disable Account") {
      const cs = new CognitoService();
      await cs.disableUser(email);
    }

    if (option === "Enable Account") {
      const cs = new CognitoService();
      await cs.enableUser(email);
    }

    if (option === "Remove Account") {
      const cs = new CognitoService();
      await cs.deleteUser(email);
    }

    setAnchorEl(null);

    // set selected user email
    setSelectedUserEmail(email);

    handleRefreshTableData();
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "roles", headerName: "Roles", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            {options.map((option: string, idx: number) => (
              <MenuItem
                key={`${option.replaceAll(" ", "_")}_${idx}`}
                // selected={option === "Add Roles"}
                onClick={(event: any) =>
                  handleMenuItemClick(
                    event,
                    option === "Disable Account" && !params?.row?.enabled
                      ? "Enable Account"
                      : option,
                    params?.row?.name,
                    params
                  )
                }
              >
                {option === "Disable Account" && !params?.row?.enabled
                  ? "Enable Account"
                  : option}
              </MenuItem>
            ))}
          </Menu>
        </>
      ),
    },
  ];

  const rows = users?.map((item: any) => ({
    id: item?.id,
    name: item?.key?.email,
    roles: "Admin",
    status: item?.status,
    enabled: item?.enabled,
  }));

  // refresh table data
  const handleRefreshTableData = async () => {
    await getListOfCognitoUsers();
  };

  // maxWidth: 650,
  return (
    <>
      <Grid sx={{ my: 2 }}>
        <Card style={{ padding: "20px 5px", margin: "0 auto" }}>
          <CardContent>
            <Typography gutterBottom variant="h5">
              User Administration
            </Typography>
            <Grid container spacing={1}>
              <Grid xs={12} item>
                <Button
                  // type="submit"
                  variant="contained"
                  size="small"
                  // color="primary"
                  sx={{
                    float: "right",
                    textTransform: "none",
                    borderRadius: 8,
                  }}
                  onClick={() => setOpenInviteUser((prev) => !prev)}
                >
                  New
                </Button>
                <Button
                  // type="submit"
                  variant="outlined"
                  size="small"
                  color="warning"
                  sx={{
                    float: "right",
                    textTransform: "none",
                    borderRadius: 8,
                    mx: 1,
                  }}
                  onClick={handleRefreshTableData}
                >
                  <RefreshIcon />
                </Button>
              </Grid>
            </Grid>
            <DataTable
              title=""
              rows={rows}
              columns={columns}
              loading={tableLoading}
              showFilter={false}
              checkboxSelection={true}
            />
          </CardContent>
        </Card>
      </Grid>
      {openInviteUser && (
        <Sidebar
          handleClose={() => setOpenInviteUser(false)}
          open={openInviteUser}
        >
          <InviteUsers closeSidebar={() => setOpenInviteUser(false)} />
        </Sidebar>
      )}
      {openAddUserRole && (
        <Sidebar
          handleClose={() => setOpenAddUserRole(false)}
          open={openAddUserRole}
        >
          <AddRole
            closeSidebar={() => setOpenAddUserRole(false)}
            userEmail={selectedUserEmail}
          />
        </Sidebar>
      )}
    </>
  );
};

export { UserAdministration };
