import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box, FormHelperText, ListItemIcon, SxProps } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ISelectDropdown {
  label: string;
  options: Array<{
    name: string;
    value: number | string;
    prefix?: React.ReactNode;
  }>;
  selectedValue: string | number;
  selectHandler: (event: SelectChangeEvent<string>) => void;
  sx?: SxProps;
  disabled?: boolean;
  hasPrefixes?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const SelectDropdown: React.FC<ISelectDropdown> = ({
  label,
  selectedValue,
  selectHandler,
  options,
  sx,
  disabled = false,
  hasPrefixes = false,
  required = false,
  error = false,
  helperText,
}: ISelectDropdown) => {
  return (
    <Box sx={sx}>
      <FormControl
        sx={{ m: 0, width: "100%" }}
        required={required}
        error={error}
      >
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId={`${label}-multiple-checkbox-label`}
          id={`${label}-multiple-checkbox`}
          value={selectedValue.toString()}
          onChange={selectHandler}
          input={<OutlinedInput label={label} />}
          renderValue={
            hasPrefixes
              ? (selected) => (
                  <>
                    {options?.find((option) => option.value === selected)
                      ?.name ?? ""}
                  </>
                )
              : undefined
          }
          MenuProps={MenuProps}
          disabled={disabled}
        >
          {selectedValue.toString() === "" && (
            <MenuItem value={""}>
              {/* {!!prefix && <ListItemIcon>{prefix}</ListItemIcon>} */}
              <ListItemText primary={"Select"} />
            </MenuItem>
          )}
          {options.map(({ name, value, prefix }) => (
            <MenuItem key={value} value={value}>
              {!!prefix && <ListItemIcon>{prefix}</ListItemIcon>}
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
        {!!helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default SelectDropdown;
