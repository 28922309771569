import React from "react";
import { S3Service } from "../../services/S3Service";
import { helpers } from "../../utils";
import { A4ALoader } from "../Common/A4ALoader";
import { constants } from "../../utils";
import { DocumentService } from "../../services/DocumentService";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
import { TextFieldInput } from "../Common/TextFieldInput";

import {
  FileUploadContainer,
  FileUploadContainerBtnContainer,
  FileUploadContainerBtnText,
  FileUploadContainerHeading,
} from "./style";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DocumentSchema } from "./../../validations/schema";
import dayjs from "dayjs";
import { useAuth } from "./../AuthProvider";

interface IEditDocument {
  bucketType: string;
  categoryType: string;
  airportId?: string;
  organizationId?: string;
  closeSidebar?: () => void;
  document: any;
}

const defaultLoaderState = {
  data: false,
  upload: false,
};

const defaultFormState = {
  description: "",
  publishedDate: dayjs(new Date()),
  fileName: "",
};

const defaultFormErrorState = {
  description: "",
  publishedDate: "",
  fileName: "",
};

const EditDocument: React.FC<IEditDocument> = ({
  bucketType,
  categoryType,
  airportId,
  organizationId,
  closeSidebar = () => {},
  document,
}) => {
  const [loaderState, setLoaderState] = React.useState({
    ...defaultLoaderState,
  });

  const [formData, setFormData] = React.useState<any>({ ...defaultFormState });
  const [formError, setFormError] = React.useState({
    ...defaultFormErrorState,
  });

  const { state, dispatch } = useDataContext();
  const { state: auth } = useAuth();

  // componentDidMount
  React.useEffect(() => {
    // set form data
    const tempFormState = {
      description: document?.description,
      publishedDate: dayjs(new Date(document?.publishedDate)),
      fileName: document?.title,
    };

    // update form state
    setFormData({ ...tempFormState });

    // cleanup
    return () => {};
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setFormError({ ...defaultFormErrorState });

    let result: any = {};

    result = DocumentSchema.editDocumentValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setFormError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setFormError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  // handle edit document api call
  const handleEditDocument = async () => {
    var isValidationError = false;

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      let isValid = handleValidation(key, formData[key]);

      if (key === "publishedDate" && formData[key] !== "") {
        isValid = handleValidation(key, new Date(formData[key]).toISOString());
      } else if (key === "publishedDate" && formData[key] === "") {
        isValid = handleValidation(key, "");
      } else {
        isValid = handleValidation(key, formData[key]);
      }

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    // if validation error
    if (isValidationError) return;

    let db_params = {
      id: document?.id,
      title: formData?.fileName,
      description: formData?.description,
      publishedDate: new Date(formData?.publishedDate).toISOString(),
    };

    // start loader
    setLoaderState((prev) => ({ ...prev, upload: true }));

    const ds = new DocumentService();

    const t2 = await ds.updateDocumentById(db_params);

    if (t2 !== null) {
      // remove updated document from list
      let newList = state.globalDocumentList[categoryType as any].filter(
        (item: any) => item?.id !== t2?.id
      );

      const payload = {
        ...state?.globalDocumentList,
        [categoryType]: state.globalDocumentList[categoryType as any]?.length
          ? [...newList, t2]
          : [t2],
      };

      dispatch({
        type: dataContextAction.SET_GLOBAL_DOCUMENT_LIST,
        payload,
      });
    }

    // stop loader
    setLoaderState((prev) => ({ ...prev, upload: false }));

    // close sidebar
    closeSidebar();
  };

  const handleCancelEditDocument = () => {
    // clear form data
    setFormData({ ...defaultFormState });
    // clear form error
    setFormError({ ...defaultFormErrorState });

    // close sidebar
    closeSidebar();
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {!state?.isGlobalDocumentListApiLoading && (
          <FileUploadContainer>
            <FileUploadContainerHeading>
              Edit Document
            </FileUploadContainerHeading>
            <TextFieldInput
              id="fileName"
              name="fileName"
              placeholder="File Name"
              label="File Name"
              error={formError.fileName}
              value={formData.fileName}
              // margin="normal"
              handleChange={handleChange}
              onBlur={() => handleValidation("fileName", formData.fileName)}
              // autoFocus
              fullWidth
              required
              sx={{ my: 1 }}
            />
            <TextFieldInput
              id="description"
              name="description"
              placeholder="Description"
              label="Description"
              error={formError.description}
              value={formData.description}
              // margin="normal"
              handleChange={handleChange}
              onBlur={() =>
                handleValidation("description", formData.description)
              }
              // autoFocus
              fullWidth
              required
            />
            <DatePicker
              name="publishedDate"
              label="Document Date"
              defaultValue={dayjs(new Date())}
              value={
                formData?.publishedDate !== "" ? formData?.publishedDate : null
              }
              onChange={(selectedDate) => {
                if (
                  selectedDate !== null &&
                  `${selectedDate["$d"]}` !== "Invalid Date"
                ) {
                  setFormData((prev: any) => ({
                    ...prev,
                    publishedDate: selectedDate,
                  }));

                  handleValidation(
                    "publishedDate",
                    new Date(selectedDate).toISOString()
                  );
                } else {
                  // set validation error
                  handleValidation("publishedDate", "");

                  setFormData((prev: any) => ({
                    ...prev,
                    publishedDate: "",
                  }));
                }
              }}
              onError={(error) => {
                if (error !== null) {
                  // set validation error
                  handleValidation("publishedDate", "");
                  setFormData((prev: any) => ({
                    ...prev,
                    publishedDate: "",
                  }));
                }
              }}
              sx={{ width: "100%", mb: 1 }}
            />
            {formError.publishedDate !== "" && (
              <p style={{ color: "red" }}>{formError.publishedDate}</p>
            )}
            {!loaderState.upload && (
              <FileUploadContainerBtnContainer>
                <FileUploadContainerBtnText onClick={handleCancelEditDocument}>
                  Cancel
                </FileUploadContainerBtnText>
                <FileUploadContainerBtnText onClick={handleEditDocument}>
                  Update
                </FileUploadContainerBtnText>
              </FileUploadContainerBtnContainer>
            )}
            {loaderState.upload && <A4ALoader />}
          </FileUploadContainer>
        )}
      </LocalizationProvider>
    </>
  );
};

export { EditDocument };
