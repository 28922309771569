import { useState, useEffect } from "react";

const useScreenSize = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  // componentDidMount
  useEffect(() => {
    // add on resize event listener
    window.addEventListener("resize", updateScreenSize);

    // cleanup
    return () => {
      // remove on resize event listener
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  // update screen width & height values
  const updateScreenSize = () => {
    // update window width & height
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  return { width, height };
};

export { useScreenSize };
