import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { ICreateOrgMemberUserParams } from "./types";
import {
  CreateOrgMemberUserMutation,
  ListOrgMemberUsersQuery,
  GetOrgMemberUserQuery,
  DeleteOrgMemberUserMutation,
  UpdateOrgMemberUserMutation,
} from "../../API";

class OrgMemberUserService {
  /*
    Task: Create a new OrgMemberUser using graphql
  */
  async createOrgMemberUser(OrgMemberUser: ICreateOrgMemberUserParams) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createOrgMemberUser,
        variables: { input: OrgMemberUser },
      })) as { data: CreateOrgMemberUserMutation; errors: any[] };

      // send back newly created OrgMemberUser details
      if (temp.data.createOrgMemberUser) {
        return temp.data.createOrgMemberUser;
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: get OrgMemberUser by id using graphql
  */
  async getOrgMemberUserById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: queries.getOrgMemberUser,
        variables: { id },
      })) as { data: GetOrgMemberUserQuery; errors: any[] };

      // send back OrgMemberUser details
      if (temp.data.getOrgMemberUser) {
        return temp.data.getOrgMemberUser;
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: delete OrgMemberUser by id using graphql
  */
  async deleteOrgMemberUserById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteOrgMemberUser,
        variables: { input: { id } },
      })) as { data: DeleteOrgMemberUserMutation; errors: any[] };

      // send back OrgMemberUser details
      if (temp.data.deleteOrgMemberUser) {
        return temp.data.deleteOrgMemberUser;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: update OrgMemberUser by id using graphql
  */
  async updateOrgMemberUserById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateOrgMemberUser,
        variables: { input },
      })) as { data: UpdateOrgMemberUserMutation; errors: any[] };

      // send back technology details
      if (temp.data.updateOrgMemberUser) {
        return temp.data.updateOrgMemberUser;
      }

      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /*
    Task: list all OrgMemberUser using graphql
  */
  async getAllOrgMemberUsers(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listOrgMemberUsers
  ) {
    try {
      // send grapql api call
      // const temp = (await API.graphql({
      //   query: query_src,
      // })) as { data: ListOrgMemberUsersQuery; errors: any[] };

      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListOrgMemberUsersQuery; errors: any[] };

      // send back all OrgMemberUser details
      if (temp.data.listOrgMemberUsers?.items) {
        return {
          relations: temp.data.listOrgMemberUsers?.items,
          nextToken: temp.data.listOrgMemberUsers?.nextToken,
        };
      }

      return { relations: [], nextToken: null };
    } catch (error) {
      console.log(error);
      return { relations: [], nextToken: null };
    }
  }

  /*
    Task: recursively list all OrgMemberUser using graphql
  */
  async recursiveFetchListOrgMemberUsers(
    filter = {},
    currentRelations: Array<any> = [],
    reqNextToken: string | null = null,
    query_src = queries.listOrgMemberUsers
  ): Promise<any> {
    const { relations, nextToken } = await this.getAllOrgMemberUsers(
      filter,
      reqNextToken,
      query_src
    );

    // merge data
    let allData = [...currentRelations, ...relations];

    if (nextToken) {
      return await this.recursiveFetchListOrgMemberUsers(
        filter,
        allData,
        nextToken,
        query_src
      );
    } else {
      if (allData?.length) {
        return allData;
      }

      return [];
    }
  }
}

export { OrgMemberUserService };
