import { useEffect, useState } from "react";

import Container from "@mui/material/Container";
import Box from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import LoginIcon from "@mui/icons-material/Login";

import { AuthSchema } from "../../validations/schema";
import { helpers, constants } from "../../utils";
import { A4ALoader } from "../Common/A4ALoader";

import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { useAuth } from "../AuthProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PasswordFieldInput } from "../Common/PasswordFieldInput";

// default state
const changePasswordDefaultState = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const ChangePassword = () => {
  // state
  const [formData, setFormData] = useState<any>({
    ...changePasswordDefaultState,
  });
  const [error, setError] = useState<any>({ ...changePasswordDefaultState });
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  // auth context api
  const { state: authProviderState } = useAuth();
  const navigateTo = useNavigate();
  const [searchParams] = useSearchParams();

  // componentDidMount
  useEffect(() => {
    document.title = constants.PAGE_TITLE.CHANGE_PASSWORD;
    // componentWillUnmount
    return () => {};
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setError({ ...changePasswordDefaultState });

    let result: any = {};

    result = AuthSchema.updatePasswordValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    var isValidationError = false;

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      const isValid = handleValidation(key, formData[key]);

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    // if old password is same as new password
    if (formData.oldPassword === formData.newPassword) {
      // error message
      setError((prev: any) => ({
        ...prev,
        newPassword: "New password cannot be same as old password.",
      }));

      // set error flag
      isValidationError = true;
    }

    if (formData.newPassword !== formData.confirmNewPassword) {
      // error message
      setError((prev: any) => ({
        ...prev,
        confirmNewPassword: "Password don't match.",
      }));

      // set error flag
      isValidationError = true;
    }

    if (!isValidationError) {
      // start api loader
      setApiLoading(true);

      try {
        // get current authenticated user
        const user = await Auth.currentAuthenticatedUser();

        // send verification code to email
        await Auth.changePassword(
          user,
          formData.oldPassword,
          formData.newPassword
        );

        // update change password flag it is false
        if (authProviderState?.user?.isPasswordReset === "no") {
          // update custom attribute flag
          await Auth.updateUserAttributes(user, {
            "custom:isPasswordReset": "yes",
          });
        }

        // show toast message
        toast.success("Password changed.", {
          theme: "colored",
        });

        if (searchParams.get("redirect") !== null) {
          // navigate to redirect url
          navigateTo(`/${searchParams.get("redirect")}`);
        } else {
          // navigate to main
          navigateTo("/main");
        }
      } catch (error: any) {
        const [type, err] = error.toString().split(":");

        // show toast error message
        toast.error(err, {
          theme: "colored",
        });
      } finally {
        // clear form data
        setFormData({ ...changePasswordDefaultState });
        setError({ ...changePasswordDefaultState });

        // stop api loader
        setApiLoading(false);
      }
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Box
        component={Paper}
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "rgb(96, 96, 96, 0.05)",
          padding: 10,
          borderRadius: "16px",
        }}
        elevation={0}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LoginIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <Box
          component={Paper}
          // onSubmit={handleSubmit}
          // noValidate
          sx={{ mt: 1, background: "rgb(96, 96, 96, 0.01)" }}
          elevation={0}
        >
          <ul>
            {constants?.PasswordPolicyText?.map(
              (policy: string, idx: number) => (
                <li key={`password-policy-${idx}`}>{policy}</li>
              )
            )}
          </ul>
          <form onSubmit={handleSubmit}>
            <PasswordFieldInput
              id="oldPassword"
              name="oldPassword"
              placeholder="Old Password"
              label="Old Password"
              error={error.oldPassword}
              value={formData.oldPassword}
              handleChange={handleChange}
              onBlur={() =>
                handleValidation("oldPassword", formData.oldPassword)
              }
              fullWidth
              required
            />
            <PasswordFieldInput
              id="newPassword"
              name="newPassword"
              placeholder="New Password"
              label="New Password"
              error={error.newPassword}
              value={formData.newPassword}
              handleChange={handleChange}
              onBlur={() =>
                handleValidation("newPassword", formData.newPassword)
              }
              fullWidth
              required
            />
            <PasswordFieldInput
              id="confirmNewPassword"
              name="confirmNewPassword"
              placeholder="Confirm New Password"
              label="Confirm New Password"
              error={error.confirmNewPassword}
              value={formData.confirmNewPassword}
              handleChange={handleChange}
              onBlur={() =>
                handleValidation(
                  "confirmNewPassword",
                  formData.confirmNewPassword
                )
              }
              fullWidth
              required
            />
            {apiLoading && <A4ALoader />}
            {!apiLoading && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  textTransform: "none",
                  width: "125px",
                  float: "right",
                }}
                onClick={handleSubmit}
                disabled={apiLoading}
              >
                Update
              </Button>
            )}
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export { ChangePassword };
