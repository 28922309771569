import joi from "joi";

// create document type params
// validation schema
const createDocumentTypeValidationSchema: any = {
  name: joi.string().trim().required().messages({
    "string.empty": "Document type name cannot be empty.",
    "any.required": "Document type name required.",
  }),
  description: joi.string().trim().required().messages({
    "string.empty": "Document type description cannot be empty.",
    "any.required": "Document type description required.",
  }),
};

export { createDocumentTypeValidationSchema };
