import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { constants } from "./../../utils";

interface IRequireAuth {
  children: any;
}

const RequireAuth: React.FC<IRequireAuth> = ({ children }) => {
  const location = useLocation();
  const { state: auth } = useAuth();

  if (auth?.signInStatus === constants.SIGN_IN_STATUS.CHECKING) {
    return <Navigate to="/auth/verify" state={{ from: location }} />;
  } else if (auth?.signInStatus === constants.SIGN_IN_STATUS.SIGNED_IN) {
    return children;
  } else {
    return <Navigate to="/auth/in" state={{ from: location }} />;
  }

  // if (auth.isSignedIn) {
  //   return children;
  // } else {
  //   return <Navigate to="/auth/verify" state={{ from: location }} />;
  // }
};

export { RequireAuth };
