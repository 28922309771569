import AWS from "aws-sdk";
import { IAttibutes } from "./types";

class CognitoService {
  private cognitoidentityserviceprovider;
  private UserPoolId;

  constructor() {
    // configure AWS
    AWS.config.update({
      region: `${process.env.REACT_APP_COGNITO_USER_POOL_REGION}`,
      accessKeyId: `${process.env.REACT_APP_AWS_ACCESS_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_SECRET_KEY}`,
    });

    // create aws cognito instace
    this.cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();

    // get UserPoolId
    this.UserPoolId = `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`;
  }

  /*
    Task: get list of users in aws cognito user pool
  */
  async getUserList(AttributesToGet?: IAttibutes) {
    let params = {
      UserPoolId: this.UserPoolId,
      // AttributesToGet: AttributesToGet,
    };

    return new Promise((resolve, reject) => {
      this.cognitoidentityserviceprovider.listUsers(
        params,
        (err: any, data: any) => {
          if (err) {
            console.log(err);
            reject(err);
          } else {
            // console.log("data", data);
            resolve(data);
          }
        }
      );
    });
  }

  /*
    Task: disable user in aws cognito user pool
  */
  async disableUser(Username: string) {
    let params = {
      UserPoolId: this.UserPoolId,
      Username: Username,
    };

    return new Promise((resolve, reject) => {
      this.cognitoidentityserviceprovider.adminDisableUser(
        params,
        function (err, data) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject(err);
          } else {
            // console.log(data); // successful response
            resolve(data);
          }
        }
      );
    });
  }

  /*
    Task: enable user in aws cognito user pool
  */
  async enableUser(Username: string) {
    let params = {
      UserPoolId: this.UserPoolId,
      Username: Username,
    };

    return new Promise((resolve, reject) => {
      this.cognitoidentityserviceprovider.adminEnableUser(
        params,
        function (err, data) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject(err);
          } else {
            // console.log(data); // successful response
            resolve(data);
          }
        }
      );
    });
  }

  /*
    Task: delete / remove user from aws cognito user pool
  */
  async deleteUser(Username: string) {
    let params = {
      UserPoolId: this.UserPoolId,
      Username: Username,
    };

    return new Promise((resolve, reject) => {
      this.cognitoidentityserviceprovider.adminDeleteUser(
        params,
        function (err, data) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject(err);
          } else {
            // console.log(data); // successful response
            resolve(data);
          }
        }
      );
    });
  }

  /*
    Task: create new user group in aws cognito user pool
  */
  async createUserGroup(GroupName: string, Description: string) {
    let params = {
      UserPoolId: this.UserPoolId,
      GroupName: GroupName /* required */,
      Description: Description,
      // Precedence: 'NUMBER_VALUE',
      // RoleArn: 'STRING_VALUE'
    };

    return new Promise((resolve, reject) => {
      this.cognitoidentityserviceprovider.createGroup(
        params,
        function (err, data) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject(err);
          } else {
            // console.log(data); // successful response
            resolve(data);
          }
        }
      );
    });
  }

  /*
    Task: add user to user group in aws cognito user pool
  */
  async addUserToGroup(GroupName: string, Username: string) {
    let params = {
      UserPoolId: this.UserPoolId,
      GroupName: GroupName /* required */,
      Username: Username /* required */,
    };

    return new Promise((resolve, reject) => {
      this.cognitoidentityserviceprovider.adminAddUserToGroup(
        params,
        function (err, data) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject(err);
          } else {
            // console.log(data); // successful response
            resolve(data);
          }
        }
      );
    });
  }

  /*
    Task: get list of user in a user group of a aws cognito pool
  */
  async getListofUserInGroup(GroupName: string) {
    let params = {
      UserPoolId: this.UserPoolId,
      GroupName: GroupName /* required */,
      // Username: Username /* required */,
    };

    return new Promise((resolve, reject) => {
      this.cognitoidentityserviceprovider.listUsersInGroup(
        params,
        function (err, data) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject(err);
          } else {
            // console.log(data); // successful response
            resolve(data);
          }
        }
      );
    });
  }

  /*
    Task: get list of user group name in aws cognito pool
  */
  async getListOfUserGroupName() {
    let params = {
      UserPoolId: this.UserPoolId,
      // GroupName: GroupName /* required */,
      // Username: Username /* required */,
    };

    return new Promise((resolve, reject) => {
      this.cognitoidentityserviceprovider.listGroups(
        params,
        function (err, data) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject(err);
          } else {
            // console.log(data); // successful response
            resolve(data);
          }
        }
      );
    });
  }

  /*
    Task: get user detail from aws cognito pool
  */
  async adminGetUser(Username: string) {
    let params = {
      UserPoolId: this.UserPoolId,
      Username: Username,
    };

    return new Promise((resolve, reject) => {
      this.cognitoidentityserviceprovider.adminGetUser(
        params,
        function (err, data) {
          if (err) {
            console.log(err, err.stack); // an error occurred
            reject(err);
          } else {
            // console.log(data); // successful response
            resolve(data);
          }
        }
      );
    });
  }
}

export { CognitoService };
