import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { CardContent, Typography } from "@mui/material";
import { constants, helpers } from "../../utils";
import { useParams, Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { AirportService } from "../../services/AirportService";
import { TextFieldInput } from "../Common/TextFieldInput";
import EditIcon from "@mui/icons-material/Edit";
import { AutoCompleteInput } from "../Common/AutoCompleteInput";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { AirportSchema } from "./../../validations/schema";
import Swal from "sweetalert2";
import { useAuth } from "./../AuthProvider/AuthProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const defaultFormState = {
  name: "",
  code: "",
  state: "",
  region: "",
  lat: "",
  lon: "",
};

const AirportDetail = () => {
  // state
  const [airport, setAirport] = React.useState<any>(null);
  const [formData, setFormData] = useState<any>({ ...defaultFormState });
  const [errorData, setErrorData] = useState<any>({ ...defaultFormState });
  const [editable, setEditable] = useState<boolean>(false);

  const { airportId } = useParams();
  const apiAirport = new AirportService();
  const { state: authProviderState } = useAuth();
  const isSuperUser = authProviderState?.user?.groups?.includes(
    constants?.ROLES?.A4A_ADMIN
  );

  const navigateTo = useNavigate();

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.AIRPORT_DETAIL;

    // if user not A4A Admin
    if (!isSuperUser) {
      // redirect to main page
      navigateTo("/main");
    }

    //IIFE
    (async () => {
      await getAirportById();
    })();

    // cleanup
    return () => {};
  }, []);

  const getAirportById = async () => {
    const data = await apiAirport.getAirportById(airportId as any);

    // if organization don't exist then return
    if (data === null) return;

    // update state
    setAirport({ ...data });

    setFormData((prev: any) => ({
      ...prev,
      name: data?.name ?? "",
      code: data?.code ?? "",
      state: data?.state ?? "",
      region: data?.region ?? "",
      lat: data?.place?.lat ?? 0,
      lon: data?.place?.lon ?? 0,
    }));
  };

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setErrorData({ ...defaultFormState });

    let result: any = {};

    result = AirportSchema.updateAirportValidationSchema[key].validate(
      value
    ) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setErrorData(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setErrorData(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  // handle update airport
  const handleUpdateAirport = async () => {
    // clear form validation error
    setErrorData({ ...defaultFormState });

    let isValidationError = false;

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      const isValid = handleValidation(key, formData[key]);

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    if (!isValidationError) {
      const promptResult = await Swal.fire({
        title: "Do you want to update airport details ?",
        showCancelButton: true,
        confirmButtonText: "Update",
      });

      // If prompt not confirmed
      if (!promptResult?.isConfirmed) {
        handleCancelUpdateAirport();
        return;
      }

      const apiPayload = {
        id: airport?.id,
        name: formData?.name,
        code: formData?.code,
        state: formData?.state,
        region: formData?.region,
        place: {
          lat: formData?.lat,
          lon: formData?.lon,
        },
      };

      // start page loader
      helpers?.startPageLoader();

      // update airport api call
      const response = await apiAirport.updateAirportById(apiPayload);

      if (response !== null) {
        const responsePayload = {
          name: response?.name,
          code: response?.code,
          state: response?.state,
          region: response?.region,
          lat: response?.place?.lat,
          lon: response?.place?.lon,
        };

        // update airport & form data
        setAirport({ ...response });
        setFormData({ ...responsePayload });

        // disable form
        setEditable((prev: boolean) => !prev);

        // show toast message
        toast.success("Airport detail updated.", { theme: "colored" });
      } else {
        handleCancelUpdateAirport();
      }

      // stop page loader
      helpers?.stopPageLoader();
    }
  };

  // handle cancel update airport
  const handleCancelUpdateAirport = async () => {
    // clear form validation error
    setErrorData({ ...defaultFormState });

    setFormData((prev: any) => ({
      ...prev,
      name: airport?.name ?? "",
      code: airport?.code ?? "",
      state: airport?.state ?? "",
      region: airport?.region ?? "",
      lat: airport?.place?.lat ?? 0,
      lon: airport?.place?.lon ?? 0,
    }));

    setEditable((prev: boolean) => !prev);
  };

  return (
    <>
      <Card
        sx={{
          padding: "20px 5px",
          margin: "0 auto",
          borderRadius: "16px",
          background: "rgb(96, 96, 96, 0.05)",
          minHeight: "75vh",
          my: 2,
        }}
      >
        <CardContent>
          <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
            <Link to="/airport" style={{ textDecoration: "none" }}>
              <Typography variant="h6">Airports</Typography>
            </Link>
            <Typography color="text.primary" variant="h6">
              {airport?.name}
            </Typography>
          </Breadcrumbs>
          <Box sx={{ display: "flex", flexDirection: "column", width: "60%" }}>
            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              {!editable && (
                <IconButton
                  sx={{ borderRadius: "16px", mb: 3 }}
                  color="primary"
                  onClick={() => setEditable((prev: boolean) => !prev)}
                  disabled={!isSuperUser}
                >
                  <EditIcon />
                </IconButton>
              )}
              {editable && (
                <IconButton
                  sx={{ borderRadius: "16px", mb: 3 }}
                  color="primary"
                  onClick={handleUpdateAirport}
                >
                  <SaveIcon />
                </IconButton>
              )}
              {editable && (
                <IconButton
                  sx={{ borderRadius: "16px", mb: 3 }}
                  color="error"
                  onClick={handleCancelUpdateAirport}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TextFieldInput
                id="name"
                name="name"
                placeholder="Airport Name"
                label="Airport Name"
                error={errorData.name}
                value={formData.name}
                handleChange={handleChange}
                onBlur={() => handleValidation("name", formData.name)}
                fullWidth
                disabled={!editable}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TextFieldInput
                id="code"
                name="code"
                placeholder="Airport Code"
                label="Airport Code"
                error={errorData.code}
                value={formData.code}
                handleChange={handleChange}
                onBlur={() => handleValidation("code", formData.code)}
                fullWidth
                sx={{ mr: 1 }}
                disabled={!editable}
              />
              <TextFieldInput
                id="state"
                name="state"
                placeholder="Airport State"
                label="Airport State"
                error={errorData.state}
                value={formData.state}
                handleChange={handleChange}
                onBlur={() => handleValidation("state", formData.state)}
                fullWidth
                disabled={!editable}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TextFieldInput
                id="lat"
                name="lat"
                placeholder="Airport Latitude"
                label="Airport Latitude"
                error={errorData.lat}
                value={formData.lat}
                handleChange={handleChange}
                onBlur={() => handleValidation("lat", formData.lat)}
                fullWidth
                disabled={!editable}
                sx={{ mr: 1 }}
              />
              <TextFieldInput
                id="lon"
                name="lon"
                placeholder="Airport Longitude"
                label="Airport Longitude"
                error={errorData.lon}
                value={formData.lon}
                handleChange={handleChange}
                onBlur={() => handleValidation("lon", formData.lon)}
                fullWidth
                disabled={!editable}
              />
            </Box>
            <AutoCompleteInput
              id="region"
              name="region"
              placeholder="Select Airport Region"
              label="Airport Region"
              options={constants.DB_ENUM.AirportRegionChoice?.map(
                (item: string) => ({
                  label: helpers?.capitalizeFirstLetterEveryWord(
                    item?.replaceAll("_", " ").toLocaleLowerCase()
                  ),
                  value: item,
                })
              )}
              error={errorData.region}
              value={formData.region}
              fullWidth={true}
              handleChange={handleChange}
              onBlur={() => handleValidation("region", formData.region)}
              disabled={!editable}
            />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export { AirportDetail };
