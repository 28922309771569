import joi from "joi";

// create organization params
// validation schema
const createOrgValidationSchema: any = {
  name: joi.string().trim().required().messages({
    "string.empty": "Consortium name cannot be empty.",
    "any.required": "Consortium name required.",
  }),
  type: joi
    .string()
    .trim()
    .required()
    // .valid("A4A_ADMIN", "FUEL_CONSORTIUM", "ENERGY_COUNCIL", "GENERAL")
    .messages({
      "string.empty": "Consortium type cannot be empty.",
      "any.required": "Consortium type required.",
      // "any.only": "Invalid consortium type.",
    }),
  groupType: joi
    .string()
    .trim()
    .required()
    // .valid("ADVISORY_COMMITTEE", "COMMITTEE", "CORP_CO_LLC")
    .messages({
      "string.empty": "Group type cannot be empty.",
      "any.required": "Group type required.",
      // "any.only": "Invalid Group type.",
    }),
};

// update organization params
// validation schema
const updateOrgValidationSchema: any = {
  name: joi.string().trim().required().messages({
    "string.empty": "Consortium name cannot be empty.",
    "any.required": "Consortium name required.",
    // "any.only": "Invalid consortium name.",
  }),
  groupType: joi
    .string()
    .trim()
    .required()
    // .valid("ADVISORY_COMMITTEE", "COMMITTEE", "CORP_CO_LLC")
    .messages({
      "string.empty": "Group type cannot be empty.",
      "any.required": "Group type required.",
      // "any.only": "Invalid Group type.",
    }),
};

export { createOrgValidationSchema, updateOrgValidationSchema };
