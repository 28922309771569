import { Box, styled } from "@mui/material";
import { ReactComponent as FolderIcon } from "../../assets/Folder-icon.svg";

export const GridFolderContainer = styled(Box)(({ theme }) => ({
  m: 1,
  p: 1,
  cursor: "pointer",
  textAlign: "center",
  wordWrap: "break-word",
  [theme.breakpoints.down("md")]: {
    width: "45%",
  },
}));

export const GridFolderIcon = styled(FolderIcon)(({ theme }) => ({
  width: "55px",
  height: "55px",
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
  },
}));

export const GridFolderTitle = styled("p")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));

export const ListFolderIcon = styled(FolderIcon)(({ theme }) => ({
  width: "55px",
  height: "55px",
  marginRight: "10px",
  [theme.breakpoints.down("md")]: {
    width: "30px",
    height: "30px",
    minWidth: "30px",
    minHeight: "30px",
  },
}));

export const ListFolderTitle = styled("p")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  }));
  
