import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import { IAirportDetail } from "../MapProvider/types";
import {
  AirportDetailDrawerAlert,
  AirportDetailDrawerAlertWrapper,
  AirportDetailDrawerCloseIcon,
  AirportDetailDrawerConsortiumHeader,
  AirportDetailDrawerConsortiumLabel,
  AirportDetailDrawerContainer,
  AirportDetailDrawerHeader,
  AirportDetailDrawerHeading,
  AirportDetailDrawerOverlayLoader,
  AirportDetailDrawerOverlayText,
  AirportDetailDrawerSubHeader,
  AirportDetailDrawerSubHeading,
  AirportDetailDrawerTab,
  AirportDetailDrawerTabsContainer,
  AirportDetailDrawerWrapper,
} from "./style";
import DoneIcon from "@mui/icons-material/Done";
import CustomTabPanel from "./CustomTabPanel";
import GeneralTab from "./GeneralTab";
import DataTab from "./DataTab";
import FilesTab from "./FilesTab";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import { Box, CircularProgress, IconButton } from "@mui/material";
import DrawerSelectInput from "./DrawerSelectInput";
import { canUserEditOrganizationInfo } from "../../utils/helpers";
import { airportRegionName, usStateCodeName } from "../../constants/map";
import { OrganizationTypeChoice } from "../../API";
import CloseIcon from "@mui/icons-material/Close";

enum tabIndexes {
  GENERAL,
  FILES,
  DATA,
  Calendar,
}

function a11yProps(index: tabIndexes) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface IAiportDetailsDrawer {
  airport: IAirportDetail;
}

const AiportDetailsDrawer: FC<IAiportDetailsDrawer> = ({
  airport,
}: IAiportDetailsDrawer) => {
  const drawerWrapperRef = useRef<null | HTMLDivElement>(null);
  const { dispatch, state } = useMapContext();
  const [selectedCalendarDate, setSelectedCalendarDate] =
    useState<Dayjs | null>(dayjs(new Date()));
  const [currentTab, setCurrentTab] = React.useState(0);
  // const [canUserEditOrgInfo, setCanUserEditOrgInfo] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const availableConsortiumOptions =
    airport.consortiumGroup?.items?.reduce(
      (currentConsortiums: any[], consortium) => {
        if (
          state.isUserA4AAdmin ||
          // consortium?.organization?.organizationType ===
          // OrganizationTypeChoice.ENERGY_COUNCIL &&
          state.userOrganizationRoles.find(
            (item) =>
              item.organizationType === OrganizationTypeChoice.ENERGY_COUNCIL
          ) ||
          (consortium?.organization?.organizationType ===
            OrganizationTypeChoice.FUEL_CONSORTIUM &&
            state.userOrganizationRoles.find(
              (userRole) =>
                userRole.organizationId === consortium.organizationId
            ))
        ) {
          currentConsortiums.push({
            name: consortium?.organization.name ?? "",
            value: consortium?.organizationId,
          });
        }
        return currentConsortiums;
      },
      []
    ) ?? [];

  const handleCloseDrawer = (): void => {
    dispatch({
      type: mapContextAction.SET_ACTIVE_AIRPORT,
      payload: null,
    });
    dispatch({
      type: mapContextAction.SET_ACTIVE_CONSORTIUM,
      payload: null,
    });
  };

  const clickAwayListener: MouseEventHandler<HTMLDivElement> = (event) => {
    if (event.target === drawerWrapperRef.current) {
      handleCloseDrawer();
    }
  };

  const CalendarDateChangeHandler = (value: Dayjs) => {
    setSelectedCalendarDate(value);
  };

  useEffect(() => {
    if (!state.activeConsortium) {
      dispatch({
        type: mapContextAction.SET_ACTIVE_CONSORTIUM,
        payload: airport.consortiumGroup?.items.find(
          (item) => availableConsortiumOptions[0].value === item?.organizationId
        ),
      });
    }
  }, []);

  const consortiumSelectHandler = (value: string) => {
    let selectedConsortium = null;
    airport.consortiumGroup?.items.every((consortium) => {
      if (consortium?.organizationId === value) {
        selectedConsortium = consortium;
        return false;
      }
      return true;
    });
    dispatch({
      type: mapContextAction.SET_ACTIVE_CONSORTIUM,
      payload: selectedConsortium,
    });
  };

  // useEffect(() => {
  //   setCanUserEditOrgInfo(
  //     state.isUserA4AAdmin ||
  //       !!state.userOrganizationRoles.find(
  //         (role) =>
  //           role.organizationType === OrganizationTypeChoice.ENERGY_COUNCIL
  //       ) ||
  //       canUserEditOrganizationInfo(
  //         state.activeConsortium?.organizationId ?? "",
  //         state.userOrganizationRoles
  //       )
  //   );
  // }, [state.activeConsortium]);

  return (
    <>
      <AirportDetailDrawerWrapper
        ref={drawerWrapperRef}
        onClick={clickAwayListener}
      >
        <AirportDetailDrawerContainer>
          <AirportDetailDrawerHeader>
            <AirportDetailDrawerHeading>
              {airport.code}
            </AirportDetailDrawerHeading>
            <IconButton onClick={() => handleCloseDrawer()}>
              <CloseIcon sx={{ fontSize: "32px", color: "black" }} />
            </IconButton>
          </AirportDetailDrawerHeader>
          <AirportDetailDrawerSubHeader>
            <AirportDetailDrawerSubHeading>Name:</AirportDetailDrawerSubHeading>
            <AirportDetailDrawerSubHeading>
              {airport.name}
            </AirportDetailDrawerSubHeading>
          </AirportDetailDrawerSubHeader>
          <AirportDetailDrawerSubHeader>
            <AirportDetailDrawerSubHeading>
              State:
            </AirportDetailDrawerSubHeading>
            <AirportDetailDrawerSubHeading>
              {usStateCodeName[airport.state as keyof typeof usStateCodeName]}
            </AirportDetailDrawerSubHeading>
          </AirportDetailDrawerSubHeader>
          <AirportDetailDrawerSubHeader>
            <AirportDetailDrawerSubHeading>
              Region:
            </AirportDetailDrawerSubHeading>
            <AirportDetailDrawerSubHeading>
              {
                airportRegionName[
                  airport.region as keyof typeof airportRegionName
                ]
              }
            </AirportDetailDrawerSubHeading>
          </AirportDetailDrawerSubHeader>
          <AirportDetailDrawerConsortiumHeader>
            <AirportDetailDrawerConsortiumLabel>
              Consortium
            </AirportDetailDrawerConsortiumLabel>
            {availableConsortiumOptions.length > 1 ? (
              <DrawerSelectInput
                sx={{ width: "50%" }}
                name="Consortium"
                options={availableConsortiumOptions}
                placeholder="Consortium"
                selectHandler={(e) => consortiumSelectHandler(e.target.value)}
                selectedValue={state.activeConsortium?.organizationId ?? ""}
                disabled={availableConsortiumOptions.length <= 1}
              />
            ) : availableConsortiumOptions.length === 1 ? (
              availableConsortiumOptions[0].name
            ) : (
              ""
            )}
          </AirportDetailDrawerConsortiumHeader>
          <AirportDetailDrawerTabsContainer
            value={currentTab}
            onChange={handleChange}
          >
            <AirportDetailDrawerTab
              icon={
                currentTab === tabIndexes.GENERAL ? (
                  <DoneIcon fontSize="small" />
                ) : undefined
              }
              iconPosition="start"
              label="General"
              {...a11yProps(tabIndexes.GENERAL)}
            />
            <AirportDetailDrawerTab
              icon={
                currentTab === tabIndexes.FILES ? (
                  <DoneIcon fontSize="small" />
                ) : undefined
              }
              iconPosition="start"
              label="Files"
              {...a11yProps(tabIndexes.FILES)}
            />
            <AirportDetailDrawerTab
              icon={
                currentTab === tabIndexes.DATA ? (
                  <DoneIcon fontSize="small" />
                ) : undefined
              }
              iconPosition="start"
              label="Data"
              {...a11yProps(tabIndexes.DATA)}
            />
            {/* <AirportDetailDrawerTab
              icon={
                currentTab === tabIndexes.Calendar ? (
                  <DoneIcon fontSize="small" />
                ) : undefined
              }
              iconPosition="start"
              label="Calendar"
              {...a11yProps(tabIndexes.Calendar)}
            /> */}
          </AirportDetailDrawerTabsContainer>
          <CustomTabPanel value={currentTab} index={tabIndexes.GENERAL}>
            <GeneralTab
              airport={airport}
              // canUserEditOrgInfo={canUserEditOrgInfo}
            />
          </CustomTabPanel>
          <CustomTabPanel value={currentTab} index={tabIndexes.FILES}>
            <FilesTab
              airport={airport}
              // canUserEditOrgInfo={canUserEditOrgInfo}
            />
          </CustomTabPanel>
          <CustomTabPanel value={currentTab} index={tabIndexes.DATA}>
            <DataTab
              airport={airport}
              // canUserEditOrgInfo={canUserEditOrgInfo}
            />
          </CustomTabPanel>
          {/* <CustomTabPanel value={currentTab} index={tabIndexes.Calendar}>
            <CalendarTab
              airport={airport}
              selectedCalendarDate={selectedCalendarDate}
              CalendarDateChangeHandler={CalendarDateChangeHandler}
            />
          </CustomTabPanel> */}
        </AirportDetailDrawerContainer>
      </AirportDetailDrawerWrapper>
      <AirportDetailDrawerOverlayLoader open={!!state.airportUpdateText}>
        <CircularProgress color="info" />
        <AirportDetailDrawerOverlayText>
          {state.airportUpdateText}
        </AirportDetailDrawerOverlayText>
      </AirportDetailDrawerOverlayLoader>
      <AirportDetailDrawerAlertWrapper
        open={!!state.airportUpdateAlert.text}
        autoHideDuration={1000}
        onClose={() =>
          dispatch({
            type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
            payload: {
              text: "",
              type: undefined,
            },
          })
        }
      >
        {!!state.airportUpdateAlert.type ? (
          <AirportDetailDrawerAlert
            severity={state.airportUpdateAlert.type}
            variant="standard"
          >
            {state.airportUpdateAlert.text}
          </AirportDetailDrawerAlert>
        ) : (
          <Box></Box>
        )}
      </AirportDetailDrawerAlertWrapper>
    </>
  );
};

export default AiportDetailsDrawer;
