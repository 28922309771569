import React, { useState } from "react";
import { IAirportDocument } from "../MapProvider/types";
import {
  AirportFileView,
  DocumentBucketTypeChoiceMapping,
  DocumentFileType,
} from "../../constants/map";
import {
  AirportDetailDrawerFileGridCardContainer,
  AirportDetailDrawerFileListCardContainer,
  AirportDetailsDrawerFileGridCardHeading,
  AirportDetailsDrawerFileGridCardIcon,
  AirportDetailsDrawerFileGridCardNewCount,
  AirportDetailsDrawerFileListCardContentContainer,
  AirportDetailsDrawerFileListCardHeading,
  AirportDetailsDrawerFileListCardIcon,
  AirportDetailsDrawerFileListCardNewCount,
  AirportDetailsDrawerFileListCardSubHeading,
  AirportDetailsDrawerFileTooltipDescription,
  AirportDetailsDrawerFileTooltipTitle,
} from "./style";
import styled from "@emotion/styled";
import {
  Box,
  Menu,
  MenuItem,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { S3Service } from "../../services/S3Service";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import { ReactComponent as DocIcon } from "../../assets/DOC-Icon.svg";
import { ReactComponent as PdfIcon } from "../../assets/PDF-Icon.svg";
import { ReactComponent as PptIcon } from "../../assets/PPT-Icon.svg";
import { ReactComponent as XlsIcon } from "../../assets/XLS-Icon.svg";
import { DocumentService } from "../../services/DocumentService";
import AirportDetailsDrawerFileEditModal from "./AirportDetailsDrawerFileEditModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";

interface IAirportDetailsDrawerFileCard {
  document: IAirportDocument;
  view: AirportFileView;
  isNewFile?: boolean;
}

export const AirportDetailDrawerFileGridCardTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #aaa",
    fontSize: 15,
  },
}));

const fileIconMapping = (url: string, view: AirportFileView) => {
  const className =
    view === AirportFileView.GRID ? "custom-grid-icon" : "custom-list-icon";
  if (url.toLocaleLowerCase().includes(DocumentFileType.DOC)) {
    return <DocIcon className={className} />;
  } else if (url.toLocaleLowerCase().includes(DocumentFileType.PDF)) {
    return <PdfIcon className={className} />;
  } else if (url.toLocaleLowerCase().includes(DocumentFileType.PPT)) {
    return <PptIcon className={className} />;
  } else if (url.toLocaleLowerCase().includes(DocumentFileType.XLS)) {
    return <XlsIcon className={className} />;
  } else {
    return view === AirportFileView.GRID ? (
      <AirportDetailsDrawerFileGridCardIcon />
    ) : (
      <AirportDetailsDrawerFileListCardIcon />
    );
  }
};

const AirportDetailsDrawerFileCard = ({
  document,
  view,
  isNewFile = false,
}: IAirportDetailsDrawerFileCard) => {
  const { state, dispatch } = useMapContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isEditModalOpened, setIsEditModalOpened] = useState<boolean>(false);

  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpened(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpened(false);
  };

  const fileViewHandler = async () => {
    try {
      const signedUrl = await new S3Service().getSignedUrl(
        `${
          DocumentBucketTypeChoiceMapping[
            document.bucketType as keyof typeof DocumentBucketTypeChoiceMapping
          ]
        }/${document.url}`
      );

      window.open(signedUrl, "_blank");
    } catch (error) {
      console.log(error);
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: "An error occured while opening file",
          type: "error",
        },
      });
    } finally {
      handleClose();
    }
  };

  const fileDownloadHandler = async () => {
    try {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: `Downloading ${document.title}`,
      });
      const fileData = (await new S3Service().getObject(
        `${
          DocumentBucketTypeChoiceMapping[
            document.bucketType as keyof typeof DocumentBucketTypeChoiceMapping
          ]
        }/${document.url}`
      )) as AWS.S3.GetObjectOutput;
      if (fileData.Body) {
        const blob = new Blob([fileData.Body as Blob], {
          type: fileData.ContentType,
        });

        const link = window.document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = document.url;

        window.document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: "An error occured while downloading file",
          type: "error",
        },
      });
    } finally {
      handleClose();
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "",
      });
    }
  };

  const fileDeleteHandler = async () => {
    try {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: `Deleting ${document.title}`,
      });
      const ds = new DocumentService();
      const deletedDoc = await ds.deleteDocumentById(document.id);
      if (deletedDoc) {
        dispatch({
          type: mapContextAction.SET_ACTIVE_AIRPORT,
          payload: {
            ...state.activeAirport,
            documents: {
              ...state.activeAirport?.documents,
              items: state.activeAirport?.documents?.items.filter(
                (document) => document?.id !== deletedDoc.id
              ),
            },
          },
        });

        // update data provider state
        dataProviderDispatch({
          type: dataContextAction.SET_AIRPORT_DOCUMENT_LIST,
          payload: dataProviderState?.airportDocumentList?.filter(
            (document) => document?.id !== deletedDoc.id
          ),
        });
      }
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "",
      });
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: "File deleted successfully",
          type: "success",
        },
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: "An error occured while deleting file",
          type: "error",
        },
      });
    } finally {
      handleClose();
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "",
      });
    }
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={fileViewHandler}>View File</MenuItem>
          <MenuItem onClick={fileDownloadHandler}>Download File</MenuItem>
          <MenuItem onClick={handleEditModalOpen}>Edit File</MenuItem>
          <MenuItem onClick={fileDeleteHandler}>Delete File</MenuItem>
        </Menu>
        <AirportDetailDrawerFileGridCardTooltip
          key={document.id}
          title={
            <>
              <AirportDetailsDrawerFileTooltipTitle>
                Title: {document.title}
              </AirportDetailsDrawerFileTooltipTitle>
              {!!document.description && (
                <AirportDetailsDrawerFileTooltipDescription>
                  Description: {document.description}
                </AirportDetailsDrawerFileTooltipDescription>
              )}
              {!!document.publishedDate && (
                <AirportDetailsDrawerFileTooltipDescription>
                  Document Date:{" "}
                  {document.publishedDate
                    ? new Date(document.publishedDate).toLocaleDateString()
                    : ""}
                </AirportDetailsDrawerFileTooltipDescription>
              )}
            </>
          }
          placement="top"
          enterDelay={350}
          leaveDelay={350}
        >
          {view === AirportFileView.GRID ? (
            <AirportDetailDrawerFileGridCardContainer id="basic-button">
              <>
                {isNewFile && <AirportDetailsDrawerFileGridCardNewCount />}
                {fileIconMapping(document.url, view)}
                <AirportDetailsDrawerFileGridCardHeading>
                  {document?.title ?? ""}
                </AirportDetailsDrawerFileGridCardHeading>
                <MoreVertIcon
                  onClick={(e) => handleClick(e as any)}
                  style={{
                    fontSize: "32px",
                    marginLeft: "auto",
                    cursor: "pointer",
                    position: "absolute",
                    top: "0",
                    right: "-10px",
                  }}
                />
              </>
            </AirportDetailDrawerFileGridCardContainer>
          ) : (
            <AirportDetailDrawerFileListCardContainer>
              {isNewFile && <AirportDetailsDrawerFileListCardNewCount />}
              {fileIconMapping(document.url, view)}
              <AirportDetailsDrawerFileListCardContentContainer>
                <AirportDetailsDrawerFileListCardHeading>
                  {document?.title ?? ""}
                </AirportDetailsDrawerFileListCardHeading>
                <AirportDetailsDrawerFileListCardSubHeading>
                  {document?.description ?? ""}
                </AirportDetailsDrawerFileListCardSubHeading>
                <AirportDetailsDrawerFileListCardSubHeading>
                  {document.publishedDate
                    ? new Date(document.publishedDate).toDateString()
                    : ""}
                </AirportDetailsDrawerFileListCardSubHeading>
              </AirportDetailsDrawerFileListCardContentContainer>
              <MoreVertIcon
                onClick={(e) => handleClick(e as any)}
                style={{
                  fontSize: "32px",
                  marginLeft: "auto",
                  cursor: "pointer",
                }}
              />
            </AirportDetailDrawerFileListCardContainer>
          )}
        </AirportDetailDrawerFileGridCardTooltip>
      </Box>
      <AirportDetailsDrawerFileEditModal
        document={document}
        isModalOpened={isEditModalOpened}
        closeModal={handleEditModalClose}
        closeTooltip={handleClose}
        isEditMode={true}
      />
    </>
  );
};

export default AirportDetailsDrawerFileCard;
