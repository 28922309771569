import joi from "joi";

// create company params
// validation schema
const createCompanyMemberValidationSchema: any = {
  firstName: joi.string().trim().required().messages({
    "string.empty": "First name cannot be empty.",
    "any.required": "First name required.",
  }),
  lastName: joi.string().trim().required().messages({
    "string.empty": "Last name cannot be empty.",
    "any.required": "Last name required.",
  }),
  mobile: joi
    .string()
    .regex(/^[0-9]{10}$/)
    .trim()
    .messages({
      "string.pattern.base": "Mobile number must have 10 digits.",
    }),
  email: joi
    .string()
    .trim()
    .required()
    .email({ tlds: { allow: false } })
    .messages({
      "string.email": "Enter valid email.",
      "string.empty": "Email cannot be empty.",
      "any.required": "Email required.",
    }),
};

export { createCompanyMemberValidationSchema };
