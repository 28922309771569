import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const DateSelectCalendarContainer = styled(Box)((theme) => ({
  width: "inherit",
  height: "inherit",
  backgroundColor: "#E0E0E0",
  borderRadius: "15px",
  "& .MuiDateCalendar-root": {
    // width: "350px",
    height: "auto",
    maxHeight: "300px",
    width: "250px",
  },
  "& .MuiYearCalendar-root": {
    // width: "350px",
    height: "auto",
    maxHeight: "300px",
    width: "265px",

  },
  "& .MuiPickersCalendarHeader-root": {
    paddingLeft: "15px",
    paddingRight: "0px",
    marginTop: "12px",
    marginBottom: "6px",
  },
  "& .MuiPickersCalendarHeader-label": {
    fontSize: "12px",
  },
  "& .Mui-custom-highlighted": {
    backgroundColor: "#1976d222",
  },
  "& .MuiPickersDay-root": {
    width: "30px",
    margin: "2px",
    height: "30px",
    fontSize: "12px",
  },
  "& .MuiDayCalendar-weekDayLabel": {
    width: "30px",
    height: "30px",
    margin: "2px",
    fontSize: "12px",
  },
  "& .MuiPickersSlideTransition-root": {
    minHeight: "300px",
  },
  "& .css-innj4t-MuiPickersYear-yearButton": {
    height: "32px",
    width: "64px",
    fontSize: '0.75rem'
  },
}));

export const DateSelectCalendarHeader = styled(Box)(() => ({
  padding: "15px",
  fontWeight: 500,
  borderBottom: "1px solid #C0C0C0",
}));

export const DateSelectCalendarHeading = styled(Box)(() => ({
  fontSize: "12px",
}));

export const DateSelectCalendarDateContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}));

export const DateSelectCalendarDate = styled(Box)(() => ({
  fontSize: "24px",
  fontWeight: "500",
}));
