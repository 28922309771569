import React, { FC } from "react";
import TextField from "@mui/material/TextField";

interface ITextFieldInputProps {
  value: any;
  type?: string;
  error: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onKeyDown?: (e: any) => void;
  onKeyDownCapture?: (e: any) => void;
  label: string;
  disabled?: boolean;
  name: string;
  id: string;
  variant?: any;
  required?: boolean;
  helperText?: string;
  color?: any;
  fullWidth?: boolean;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  autoComplete?: string;
  margin?: any;
  sx?: any;
}

const TextFieldInput: FC<ITextFieldInputProps> = (props) => {
  const {
    name,
    id,
    value,
    type = "text",
    error,
    handleChange = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
    onKeyDownCapture = () => {},
    label = "",
    disabled = false,
    variant = "outlined",
    required = false,
    helperText = " ",
    color = "",
    fullWidth = false,
    placeholder = "",
    className = "",
    readOnly = false,
    autoFocus = false,
    autoComplete = "",
    margin = "none",
    sx = {},
  } = props;

  // check error flag
  const errorFlag = error !== "" && error !== undefined ? true : false;

  return (
    <TextField
      id={id}
      name={name}
      type={type}
      value={value}
      label={label}
      error={errorFlag}
      variant={variant}
      required={required}
      disabled={disabled}
      helperText={error !== "" ? error : helperText}
      color={color}
      fullWidth={fullWidth}
      placeholder={placeholder}
      className={className}
      onChange={handleChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onKeyDownCapture={onKeyDownCapture}
      inputProps={{
        readOnly: readOnly,
      }}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      margin={margin}
      sx={{ mb: 0, pb: 0, ...sx }}
    />
  );
};

export { TextFieldInput };
