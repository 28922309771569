import React, { FormEvent, useState } from "react";
import { Button, Alert, TextField, AlertColor } from "@mui/material";
import { useMapContext } from "../MapProvider/MapProvider";
import { Organization } from "../../API";
import { API } from "aws-amplify";
import { useAuth } from "../AuthProvider";

// prop types
interface ISendConsortiumEmailSidebar {
  consortium?: Organization;
  closeSidebar: () => void;
}

const SendConsortiumEmailSidebar: React.FC<ISendConsortiumEmailSidebar> = ({
  consortium,
}) => {
  const { state } = useAuth();
  const [sendingEmail, setSendingEmail] = useState(false);
  const [formData, setFormData] = useState({
    subject: {
      value: "",
      error: "",
    },
    body: {
      value: "",
      error: "",
    },
  });
  const [alertInfo, setAlertInfo] = useState({
    severity: "success" as AlertColor,
    message: "",
  });

  const emailSendHandler = async (e: FormEvent) => {
    e.preventDefault();
    if (!formData.subject.value.trim()) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        subject: {
          value: prevFormData.subject.value,
          error: "Email subject is required field.",
        },
      }));
      return;
    }
    if (!formData.body.value.trim()) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        body: {
          value: prevFormData.body.value,
          error: "Email body is required field.",
        },
      }));
      return;
    }
    setSendingEmail(true);
    try {
      const body = {
        emails: [
          ...(consortium?.members?.items?.map(
            (member) => member?.user?.email ?? ""
          ) ?? []),
          ...(consortium?.admins?.items?.map(
            (admin) => admin?.user?.email ?? ""
          ) ?? []),
          ...(consortium?.supportAdmin?.items?.map(
            (supportAdmin) => supportAdmin?.user?.email ?? ""
          ) ?? []),
        ],
        consortiumName: consortium?.name,
        subject: formData.subject.value,
        username: `${state.user?.dbUser.firstName} ${state.user?.dbUser.lastName}`,
        body: formData.body.value,
        // subject: `Airline Fuel Portal [${consortium?.name}] Notification: ${formData.subject.value}`,
        //         body: `${formData.body.value}

        // This message was sent by the Administrator of  [${consortium?.name}] group: ${state.user?.dbUser.firstName} ${state.user?.dbUser.lastName}. You are receiving this message because you are a member of [${consortium?.name}]. If you no longer wish to be a part of the group, please notify your administrator.`,
      };

      const res = await API.post("mailapi", `/mail/consortium`, {
        headers: {},
        response: true,
        body,
      });
      if (res.data.success) {
        setAlertInfo({
          message: "Emails sent successfully.",
          severity: "success",
        });
      } else {
        throw new Error(res.data.message);
      }
    } catch (e) {
      console.error(e);
      const err = e as any;
      setAlertInfo({
        message:
          err?.response?.error?.message ??
          "An error occured while sending emails.",
        severity: "error",
      });
    } finally {
      setSendingEmail(false);
    }
  };

  return (
    <div>
      <p>Let's send email to admins and members.</p>
      <form onSubmit={emailSendHandler}>
        <TextField
          id="email-subject"
          label="Email subject"
          value={formData.subject.value}
          error={!!formData.subject.error}
          helperText={formData.subject.error}
          onChange={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              subject: {
                value: e.target.value,
                error: "",
              },
            }))
          }
          fullWidth
          required
          sx={{ mt: "15px" }}
        />
        <TextField
          id="email-body"
          // error=""
          label="Email body"
          value={formData.body.value}
          error={!!formData.body.error}
          helperText={formData.body.error}
          onChange={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              body: {
                value: e.target.value,
                error: "",
              },
            }))
          }
          fullWidth
          required
          multiline
          rows={3}
          sx={{ mt: "15px" }}
        />
        <Button
          type="submit"
          sx={{ mt: "20px", float: "right" }}
          variant="outlined"
          disabled={sendingEmail}
        >
          Send
        </Button>
      </form>
      {!!alertInfo.message && (
        <Alert severity={alertInfo.severity} sx={{ mt: 10 }}>
          {alertInfo.message}
        </Alert>
      )}
    </div>
  );
};

export { SendConsortiumEmailSidebar };
