import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { ICreateInviteLogParams } from "./types";
import {
  CreateInviteLogMutation,
  ListInviteLogsQuery,
  GetInviteLogQuery,
  DeleteInviteLogMutation,
  UpdateInviteLogMutation,
} from "../../API";

class InviteLogService {
  /*
    Task: Create a new InviteLog using graphql
  */
  async createInviteLog(InviteLog: ICreateInviteLogParams) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createInviteLog,
        variables: { input: InviteLog },
      })) as { data: CreateInviteLogMutation; errors: any[] };

      // send back newly created InviteLog details
      return temp.data.createInviteLog;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: get InviteLog by id using graphql
  */
  async getInviteLogById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: queries.getInviteLog,
        variables: { id },
      })) as { data: GetInviteLogQuery; errors: any[] };

      // send back InviteLog details
      return temp.data.getInviteLog;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: delete InviteLog by id using graphql
  */
  async deleteInviteLogById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteInviteLog,
        variables: { input: { id } },
      })) as { data: DeleteInviteLogMutation; errors: any[] };

      // send back InviteLog details
      return temp.data.deleteInviteLog;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: update InviteLog by id using graphql
  */
  async updateInviteLogById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateInviteLog,
        variables: { input },
      })) as { data: UpdateInviteLogMutation; errors: any[] };

      // send back technology details
      return temp.data.updateInviteLog;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: list all InviteLog using graphql
  */
  async getAllInviteLogs(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listInviteLogs
  ) {
    try {
      // send grapql api call
      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListInviteLogsQuery; errors: any[] };

      if (temp.data.listInviteLogs?.items) {
        // send back all InviteLog details
        return {
          invites: temp.data.listInviteLogs?.items,
          nextToken: temp.data.listInviteLogs?.nextToken,
        };
      }

      return { invites: [], nextToken: null };
    } catch (error) {
      return { invites: [], nextToken: null };
    }
  }

  /*
    Task: recursively list all InviteLog using graphql
  */
  async recursiveFetchListInviteLogs(
    filter: any = {},
    currentLogs: Array<any> = [],
    reqNextToken: string | null = null,
    query_src = queries.listInviteLogs
  ): Promise<any> {
    const { invites, nextToken } = await this.getAllInviteLogs(
      filter,
      reqNextToken,
      query_src
    );

    // merge old and new data
    let allData = [...currentLogs, ...invites];

    if (nextToken) {
      return await this.recursiveFetchListInviteLogs(
        filter,
        allData,
        nextToken,
        query_src
      );
    } else {
      if (allData?.length) {
        return allData;
      }

      return [];
    }
  }
}

export { InviteLogService };
