import React from "react";
import {
  AirportDetailDrawerInfoCardContainer,
  AirportDetailDrawerInfoCardHeading,
  AirportDetailDrawerInfoCardSubHeading,
  AirportDetailDrawerInfoCardTextField,
} from "./style";
import { Box, InputAdornment } from "@mui/material";
import DrawerSelectInput from "./DrawerSelectInput";
import { generalTabFieldType } from "../../constants/map";
import { IEditConsortiumInput, IgeneralInfoCard } from "./GeneralTab";
import InfoWithPopup from "../InfoIconWithPopup";

interface IAirportDetailDrawerInfoCard {
  role: string;
  isEditable?: boolean;
  isInEditMode?: boolean;
  editInputs?: any;
  editInputChangeHandler?: (
    name: keyof any,
    value: string,
    type?: generalTabFieldType
  ) => void;
  infoArray: IgeneralInfoCard[];
  containerClassName?: string;
  infoText?: string;
}

const booleanOptions = [
  { value: true, name: "Yes" },
  { value: false, name: "No" },
];

const AirportDetailDrawerInfoCard = ({
  role,
  isEditable = true,
  isInEditMode,
  editInputs,
  editInputChangeHandler,
  infoArray,
  containerClassName = "",
  infoText,
}: IAirportDetailDrawerInfoCard) => {
  return (
    <AirportDetailDrawerInfoCardContainer className={containerClassName}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* <InputLabel>{name}</InputLabel> */}
        <AirportDetailDrawerInfoCardHeading mr='5px'>
          {role}
        </AirportDetailDrawerInfoCardHeading>
          {!!infoText && isInEditMode && <InfoWithPopup infoText={infoText} />}
      </Box>
      {infoArray.map((info) => (
        <React.Fragment key={info.name}>
          {info.visibleOnEdit !== false &&
            isEditable &&
            isInEditMode &&
            (info.type === generalTabFieldType.DATE ||
              info.type === generalTabFieldType.TEXT ||
              info.type === generalTabFieldType.TEXT_MULTIPLE ||
              info.type === generalTabFieldType.DATE_TIME) && (
              <AirportDetailDrawerInfoCardTextField
                type={(() => {
                  switch (info.type) {
                    case generalTabFieldType.DATE:
                      return "date";
                    case generalTabFieldType.DATE_TIME:
                      return "datetime-local";
                    default:
                      return "text";
                  }
                })()}
                placeholder={info.placeHolder}
                variant="standard"
                name={info.name}
                value={
                  info.type === generalTabFieldType.DATE
                    ? editInputs?.[info.name!]?.toString().split("T")[0]
                    : editInputs?.[info.name!]
                }
                onChange={(e) =>
                  editInputChangeHandler!(
                    e.target.name,
                    e.target.value,
                    info.type
                  )
                }
                multiline={info.type === generalTabFieldType.TEXT_MULTIPLE}
                maxRows={Infinity}
                // InputProps={{
                //   endAdornment: !!infoText && (
                //     <InputAdornment position="end">
                //       <InfoWithPopup infoText={infoText} />
                //     </InputAdornment>
                //   ),
                // }}
                disabled={info.disabled}
              />
            )}
          {info.visibleOnEdit !== false &&
            isEditable &&
            isInEditMode &&
            (info.type === generalTabFieldType.ENUM ||
              info.type === generalTabFieldType.ENUM_MULTIPLE) && (
              <DrawerSelectInput
                name={info.name!}
                options={info.enumOptions!}
                selectedValue={editInputs?.[info.name!]!}
                selectHandler={(e) =>
                  editInputChangeHandler!(
                    e.target.name,
                    e.target.value,
                    info.type
                  )
                }
                placeholder={info.placeHolder ?? ""}
                sx={{ "& .MuiSelect-select": { fontSize: "14px !important" } }}
                isMultiple={info.type === generalTabFieldType.ENUM_MULTIPLE}
                infoText={infoText}
                disabled={info.disabled}
              />
            )}
          {info.visibleOnEdit !== false &&
            isEditable &&
            isInEditMode &&
            info.type === generalTabFieldType.BOOLEAN && (
              <DrawerSelectInput
                name={info.name!}
                options={booleanOptions}
                selectedValue={
                  editInputs?.[info.name! as keyof IEditConsortiumInput]!
                }
                selectHandler={(e) =>
                  editInputChangeHandler!(
                    e.target.name as keyof IEditConsortiumInput,
                    e.target.value,
                    info.type
                  )
                }
                placeholder={info.placeHolder ?? ""}
                sx={{ "& .MuiSelect-select": { fontSize: "14px !important" } }}
                infoText={infoText}
                disabled={info.disabled}
              />
            )}
          {(!isEditable || !isInEditMode) && info.value !== undefined && (
            <AirportDetailDrawerInfoCardSubHeading>
              {info.value}
            </AirportDetailDrawerInfoCardSubHeading>
          )}
        </React.Fragment>
      ))}
    </AirportDetailDrawerInfoCardContainer>
  );
};

export default AirportDetailDrawerInfoCard;
