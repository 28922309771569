import { AirportRegionChoice, FacilityLocationTypeChoice, MOTypeChoice } from "../../API";

const airports = [
  {
    id: "1",
    name: "Dulles International (IAD)",
    code: "code1",
    state: "Washington D.C.",
    place: {
      lat: "38.9523404",
      lon: "-77.4586344",
    },
    updatedAt: "2023-12-01T09:59:46.868Z",
    chairId: "1ch",
    chair: {
      id: "1ch",
      name: "ABC Corporation",
    },
    chairContactId: "1chcn",
    chairContact: {
      id: "1chcn",
      firstName: "Jane",
      lastName: "Smith",
      mobile: '+1 123 123 1234'
    },
    operatorId: "1op",
    operator: {
      id: "1op",
      name: "BCE Corporation",
    },
    operatorGeneralManagerId: "1opgm",
    operatorGeneralManager: {
      id: "1opgm",
      firstName: "Joe",
      lastName: "Doe",
      mobile: '+1 234 234 2345'
    },
    facilityOwnerId: "1fo",
    facilityOwner: {
      id: "1fo",
      name: "CDE Corporation",
    },
    facilityOwnerContactId: "1focn",
    facilityOwnerContact: {
      id: "1focn",
      firstName: "Joe",
      lastName: "Doe",
      mobile: '+1 234 234 2345'
    },
    region: AirportRegionChoice.PADD_1A_NEW_ENGLAND,
    facilityLocation: FacilityLocationTypeChoice.ON_AIRPORT,
    moExpiration: "1/1/2024",
    totalCapacityDays: 10,
    totalCapacityGallons: 1000,
    moType: MOTypeChoice.EVERGREEN,
    secured: true,
    leaseExpiration: "1/1/2024",
    // details: {
    //   consortium: {
    //     name: "ABC",
    //     fullName: "ABC Corporation",
    //     person: "Jane Smith (Chair)",
    //     contact: "+1 123 123 1234",
    //   },
    //   operator: {
    //     name: "BCE",
    //     fullName: "BCE Corporation",
    //     person: "Joe Doe (GM)",
    //     contact: "+1 234 234 2345",
    //   },
    //   facilityOwner: {
    //     name: "CDE",
    //     fullName: "CDE Corporation",
    //     person: "Joe Doe (GManager)",
    //     contact: "+1 234 234 2345",
    //   },
    //   airportRegion: "RegioAnnual Meetingn 1",
    //   facilityLocation: "On Airport",
    //   mNOExpiration: "1/1/2024",
    //   airportState: "Washington D.C.",
    //   capicityDays: "10 Days",
    //   capicityGallons: "1000 Gallons",
    //   mNOType: "Evergreen",
    //   secured: "Yes",
    //   leaseExpiration: "1/1/2024",
    // },
  },
  {
    id: "2",
    name: "Dulles International 2 (IAD)",
    code: "code2",
    state: "Washington D.C.",
    place: {
      lat: "38.8523404",
      lon: "-77.3586344",
    },
    updatedAt: "2023-12-01T09:59:46.868Z",
    chairId: "2ch",
    chair: {
      id: "2ch",
      name: "XYZ Corporation",
    },
    chairContactId: "2chcn",
    chairContact: {
      id: "2chcn",
      firstName: "Carl",
      lastName: "John",
      mobile: '+1 456 456 4567'
    },
    operatorId: "2op",
    operator: {
      id: "2op",
      name: "WXY Corporation",
    },
    operatorGeneralManagerId: "1opgm",
    operatorGeneralManager: {
      id: "2opgm",
      firstName: "Will",
      lastName: "Smith",
      mobile: '+1 567 567 5678'
    },
    facilityOwnerId: "2fo",
    facilityOwner: {
      id: "2fo",
      name: "VWX Corporation",
    },
    facilityOwnerContactId: "2focn",
    facilityOwnerContact: {
      id: "2focn",
      firstName: "Will",
      lastName: "Smith",
      mobile: '+1 567 567 5678'
    },
    region: AirportRegionChoice.PADD_1B_CENTERAL_ATLANTIC,
    facilityLocation: FacilityLocationTypeChoice.OFF_AIRPORT,
    moExpiration: "2/2/2024",
    totalCapacityDays: 20,
    totalCapacityGallons: 2000,
    moType: MOTypeChoice.MONTH_TO_MONTH,
    secured: false,
    leaseExpiration: "2/2/2024",
  },
];

const airportDetail = 
  {
    id: "1",
    name: "Dulles International (IAD)",
    code: "code1",
    oldCode: "code1",
    facility: "code1",
    state: "Washington D.C.",
    place: {
      lat: "38.9523404",
      lon: "-77.4586344",
    },
    chairId: "1ch",
    chair: {
      id: "1ch",
      name: "ABC Corporation",
      updatedAt: '2023-12-06T09:37:26.694Z'
    },
    chairContactId: "1chcn",
    chairContact: {
      id: "1chcn",
      cognitoUserId: 'someId',
      firstName: "Jane",
      lastName: "Smith",
      email: 'jane.smith@example.com',
      mobile: '+1 123 123 1234',
      updatedAt: '2023-12-06T09:37:26.694Z'
    },
    operatorId: "1op",
    operator: {
      id: "1op",
      name: "BCE Corporation",
      updatedAt: '2023-12-06T09:37:26.694Z'
    },
    operatorGeneralManagerId: "1opgm",
    operatorGeneralManager: {
      id: "1opgm",
      cognitoUserId: 'someId',
      firstName: "Joe",
      lastName: "Doe",
      mobile: '+1 234 234 2345',
      email: 'joe.doe@example.com',
      updatedAt: '2023-12-06T09:37:26.694Z'
    },
    facilityOwnerId: "1fo",
    facilityOwner: {
      id: "1fo",
      name: "CDE Corporation",
      updatedAt: '2023-12-06T09:37:26.694Z'
    },
    facilityOwnerContactId: "1focn",
    facilityOwnerContact: {
      id: "1focn",
      cognitoUserId: 'someId',
      firstName: "Joe",
      lastName: "Doe",
      mobile: '+1 234 234 2345',
      email: 'joe.doe@example.com',
      updatedAt: '2023-12-06T09:37:26.694Z'
    },
    region: AirportRegionChoice.PADD_1A_NEW_ENGLAND,
    facilityLocation: FacilityLocationTypeChoice.ON_AIRPORT,
    moExpiration: "1/1/2024",
    totalCapacityDays: 10,
    totalCapacityGallons: 1000,
    moType: MOTypeChoice.EVERGREEN,
    secured: true,
    leaseExpiration: "1/1/2024",
    updatedAt: '2023-12-06T09:37:26.694Z'
  }

export { airports, airportDetail };
