import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import { constants, helpers } from "../../utils";
import { A4ALoader } from "../Common/A4ALoader";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useAuth, authContextAction } from "../AuthProvider";
import { OrgAdminUserService } from "../../services/OrgAdminUserService";
import { OrgMemberUserService } from "../../services/OrgMemberUserService";
import { InviteLogService } from "../../services/InviteLogService";
import { ParticipationAgreement } from "../ParticipationAgreement";
import { toast } from "react-toastify";
import { Auth } from "aws-amplify";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { DataTable } from "./../Common/DataTable";

// prop types
interface IAcceptAllOrganizationInvite {}

interface IGetListView {
  inviteList: Array<any>;
  tableTitle?: string;
}

const AcceptAllOrganizationInvite: React.FC<
  IAcceptAllOrganizationInvite
> = () => {
  const [apiLoading, setApiLoading] = React.useState<boolean>(false);

  let { state: authProviderState, dispatch } = useAuth();
  let navigate = useNavigate();

  const apiInviteLogs = new InviteLogService();
  const apiOrgAdminUsers = new OrgAdminUserService();
  const apiOrgMemberUsers = new OrgMemberUserService();

  // componentDidMount
  React.useEffect(() => {
    // update page title
    document.title = constants.PAGE_TITLE.ACCEPT_ORG_INVITE;

    if (
      !authProviderState?.isSignedIn &&
      !authProviderState?.pendingInvites?.length
    ) {
      navigate("/main");
    }

    // cleanup
    return () => {};
  }, []);

  const handleAcceptInvite = async () => {
    // start api loading
    setApiLoading(true);

    if (!authProviderState?.isSignedIn) {
      // stop api loading
      setApiLoading(false);
      return;
    }

    // create pending invite copy
    let pendingInvites = authProviderState?.pendingInvites;

    // accept invite one by one
    await Promise.all(
      authProviderState?.pendingInvites?.map(async (item: any) => {
        let isRelationCreated = false;
        let isInviteLogUpdated = false;

        if (item?.type === "ADMIN") {
          // 4. create organization admin relationship
          const orgAdminUserParams = {
            organizationId: `${item?.organization?.id}`,
            userId: `${item?.user?.id}`,
          };

          const response = await apiOrgAdminUsers.createOrgAdminUser(
            orgAdminUserParams
          );

          // set relation created flag
          isRelationCreated = response !== null;
        } else if (item?.type === "MEMBER") {
          // 4. create organization member relationship
          const orgMemberUserParams = {
            organizationId: `${item?.organization?.id}`,
            userId: `${item?.user?.id}`,
          };

          const response = await apiOrgMemberUsers.createOrgMemberUser(
            orgMemberUserParams
          );

          // set relation created flag
          isRelationCreated = response !== null;
        }

        // 6. update invite log data
        const apiParams = {
          isAccepted: true,
          id: item?.id,
          acceptedAt: new Date().toISOString(),
        };

        // update invite log
        const response = await apiInviteLogs.updateInviteLogById(apiParams);

        // set update invite log flag
        isInviteLogUpdated = response !== null;

        if (isRelationCreated && isInviteLogUpdated) {
          // update state value
          pendingInvites = pendingInvites?.filter(
            (itm) => itm?.id !== item?.id
          );

          dispatch({
            type: authContextAction?.UPDATE_PENDING_INVITE,
            payload: pendingInvites,
          });
        }
      })
    );

    // show toast message
    toast.success("All invitation accepted.", { theme: "colored" });

    // redirect to main page
    navigate(`/main`);
  };

  const handleDeclineInvite = async () => {
    // if loggedin user not A4A member
    if (
      !authProviderState?.user?.groups?.includes(constants?.ROLES?.A4A_ADMIN)
    ) {
      // if user decline logout user & redirect to login page
      await Auth.signOut();
      navigate(`/auth/in`);
    } else {
      // clear all pending invites
      dispatch({
        type: authContextAction?.CLEAR_PENDING_INVITE,
        payload: [],
      });
      navigate(`/main`);
    }
  };

  const GetMemomizedListView: React.FC<IGetListView> = React.memo(
    ({ inviteList = [], tableTitle = "" }) => {
      const columns: GridColDef[] = [
        { field: "name", headerName: "Name", flex: 1 },
        { field: "type", headerName: "Type", flex: 0.3 },
        { field: "status", headerName: "Status", flex: 0.3 },
      ];

      const tableData: Array<any> = inviteList?.map((item: any) => ({
        id: item?.id,
        name: item?.organization?.name,
        type: helpers.capitalizeFirstLetterEveryWord(item?.type?.toLowerCase()),
        status: item?.isAccepted ? "Accepted" : "Pending",
      }));

      return (
        <DataTable
          title={tableTitle}
          rows={tableData}
          columns={columns}
          // loading={tableLoading}
          loading={false}
          showFilter={false}
          checkboxSelection={true}
        />
      );
    }
  );

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        elevation={0}
      >
        <ParticipationAgreement />

        <GetMemomizedListView inviteList={authProviderState?.pendingInvites} />

        {!authProviderState?.isSignedIn && (
          <Alert severity="error" sx={{ width: "100vh", my: 2 }}>
            Error: Sign-in first then click again on invitation accept link
            which is send in email.
          </Alert>
        )}

        {apiLoading && (
          <Box sx={{ mt: 3 }} elevation={0}>
            <A4ALoader />
          </Box>
        )}
        {!authProviderState?.isSignedIn && (
          <Link to="/auth/in">Sign-in here.</Link>
        )}
        {!apiLoading && authProviderState?.isSignedIn && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
              my: 2,
            }}
            elevation={0}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: 1, textTransform: "none" }}
              onClick={handleAcceptInvite}
            >
              Accept All
            </Button>
            <Button
              variant="contained"
              sx={{ mx: 1, textTransform: "none" }}
              color="inherit"
              onClick={handleDeclineInvite}
            >
              Decline All
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export { AcceptAllOrganizationInvite };
