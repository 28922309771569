import React, { FC, useState, useEffect } from "react";
import { AirportFieldValidator, IAirportDetail } from "../MapProvider/types";
import {
  AirportDetailDrawerEditIcons,
  AirportDetailDrawerInfoBtns,
  AirportDetailDrawerInfoCardCloseIcon,
  AirportDetailDrawerInfoCardEditIcon,
  AirportDetailDrawerInfoCardSaveButton,
  AirportDetailDrawerInformationSection,
} from "./style";
import AirportDetailDrawerInfoCard from "./AirportDetailDrawerInfoCard";
import {
  AirportFieldValidatorType,
  LeaseTypeChoiceMapping,
  LeaseTypeOptions,
  MOTypeChoiceMapping,
  MOTypeOptions,
  facilityLocationMapping,
  facilityLocationOptions,
  generalTabFieldType,
  generalTabFields,
  organizationGroupType,
  reservePrebillTypeChoiceMapping,
  reservePrebillTypeOptions,
} from "../../constants/map";
import dayJs from "dayjs";
import {
  CompanyTypeChoice,
  FacilityLocationTypeChoice,
  LeaseTypeChoice,
  MOTypeChoice,
  ReservePrebillTypeChoice,
} from "../../API";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import MapService from "../../services/MapService";
import { airportFieldValidator } from "../../utils/helpers";
import { Button } from "@mui/material";
import GeneralTabConfirmationModal from "./GeneralTabConfirmationModal";

interface IGeneralTab {
  airport: IAirportDetail;
  // canUserEditOrgInfo: boolean;
}

// export interface IEditAirportInput {
//
// }

export interface IEditConsortiumInput {
  chairId?: string | null;
  chairContactId?: string | null;
  operatorId?: string | null;
  operatorGeneralManagerId?: string | null;
  facilityOwner?: string | null;
  facilityOwnerContact?: string | null;
  secondaryContact?: string | null;
  legalCounsel?: string | null;
  facilityLocation?: FacilityLocationTypeChoice | null;
  totalCapacityDays?: number | null;
  totalCapacityGallons?: number | null;
  secured?: boolean | null;
  moExpiration?: string | null;
  moType?: MOTypeChoice | null;
  moTypeCustomValue?: string | null;
  leaseExpiration?: string | null;
  leaseType?: LeaseTypeChoice | null;
  leaseTypeCustomValue?: string | null;
  dateLastFinancialSUP?: string | null;
  dateLastA4aOperationalReview?: string | null;
  netInventoryCalculation?: string | null;
  entryFee?: string | null;
  nonMemberRate?: string | null;
  costSharingFormula?: string | null;
  reserveDepositPrebill?: ReservePrebillTypeChoice | null;
  thirdPartyDebt?: number | null;
  memberLoans?: number | null;
  dateLastPropertyAppraisal?: string | null;
  aviationLiabilityLimit?: number | null;
  environmentalLiabilityLimit?: number | null;
  comments?: string | null;
}

export interface IgeneralInfoCard {
  name: string;
  value: string;
  type: generalTabFieldType;
  placeHolder?: string;
  enumOptions?: Array<{ name: string; value: number | string }>;
  originalValue?: string | number | boolean;
  visibleOnEdit?: boolean;
  disabled?: boolean;
}

const generalTabFieldValidations: AirportFieldValidator[] = [
  {
    field: generalTabFields.TOTAL_CAPACITY_DAYS,
    errorMessage: "Total Capacity Days must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: true,
  },
  {
    field: generalTabFields.TOTAL_CAPACITY_GALLONS,
    errorMessage: "Total Capacity Gallons must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: true,
  },
  {
    field: generalTabFields.THIRD_PARTY_DEBT,
    errorMessage: "Third Part Debt must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  // {
  //   field: generalTabFields.TOTAL_OUTSTANDING_DEBT,
  //   errorMessage: "Total Outstanding Dept must be positive number",
  //   type: AirportFieldValidatorType.POSITIVE_FLOAT,
  //   isRequired: true,
  // },
  {
    field: generalTabFields.MEMBER_LOANS,
    errorMessage: "Member Loans must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: generalTabFields.AVIATION_LIABILITY_LIMIT,
    errorMessage: "Aviation Liability Limit must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
  {
    field: generalTabFields.ENVIRONMENTAL_LIABILITY_LIMIT,
    errorMessage: "Environmental Liability Limit must be positive number",
    type: AirportFieldValidatorType.POSITIVE_FLOAT,
    isRequired: false,
  },
];

const GeneralTab: FC<IGeneralTab> = ({
  airport,
}: // canUserEditOrgInfo,
IGeneralTab) => {
  const { state, dispatch } = useMapContext();
  const [enableViewMore, setEnableViewMore] = useState<boolean>();
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  // const [airportEditInputs, setAirportEditInputs] = useState<IEditAirportInput>(
  //   {
  //   }
  // );

  const [consortiumEditInputs, setConsortiumEditInputs] =
    useState<IEditConsortiumInput>({});

  useEffect(() => {
    setConsortiumEditInputs({
      chairId: state.activeConsortium?.chairId,
      chairContactId: state.activeConsortium?.chairContactId,
      operatorId: state.activeConsortium?.operatorId,
      operatorGeneralManagerId:
        state.activeConsortium?.operatorGeneralManagerId,
      facilityOwner: state.activeConsortium?.facilityOwner,
      facilityOwnerContact: state.activeConsortium?.facilityOwnerContact,
      secondaryContact: state.activeConsortium?.secondaryContact,
      legalCounsel: state.activeConsortium?.legalCounsel,
      facilityLocation: state.activeConsortium?.facilityLocation,
      totalCapacityDays: state.activeConsortium?.totalCapacityDays,
      totalCapacityGallons: state.activeConsortium?.totalCapacityGallons,
      secured: state.activeConsortium?.secured,
      moExpiration: state.activeConsortium?.moExpiration,
      moType: state.activeConsortium?.moType,
      moTypeCustomValue: state.activeConsortium?.moTypeCustomValue,
      leaseExpiration: state.activeConsortium?.leaseExpiration,
      leaseType: state.activeConsortium?.leaseType,
      leaseTypeCustomValue: state.activeConsortium?.leaseTypeCustomValue,
      dateLastFinancialSUP: state.activeConsortium?.dateLastFinancialSUP,
      dateLastA4aOperationalReview:
        state.activeConsortium?.dateLastA4aOperationalReview,
      netInventoryCalculation: state.activeConsortium?.netInventoryCalculation,
      entryFee: state.activeConsortium?.entryFee,
      nonMemberRate: state.activeConsortium?.nonMemberRate,
      costSharingFormula: state.activeConsortium?.costSharingFormula,
      reserveDepositPrebill: state.activeConsortium?.reserveDepositPrebill,
      thirdPartyDebt: state.activeConsortium?.thirdPartyDebt,
      memberLoans: state.activeConsortium?.memberLoans,
      dateLastPropertyAppraisal:
        state.activeConsortium?.dateLastPropertyAppraisal,
      aviationLiabilityLimit: state.activeConsortium?.aviationLiabilityLimit,
      environmentalLiabilityLimit:
        state.activeConsortium?.environmentalLiabilityLimit,
      comments: state.activeConsortium?.comments,
    });
    setIsInEditMode(false);
  }, [state.activeConsortium]);

  const editInputChangeHandler = (
    name: keyof any,
    value: string,
    type?: generalTabFieldType
  ) => {
    let finalValue: string = value;
    if (type === generalTabFieldType.DATE) {
      finalValue = new Date(finalValue as string).toISOString();
    }
    // if (name in airportEditInputs) {
    //   setAirportEditInputs((prevState) => ({
    //     ...prevState,
    //     [name]: finalValue,
    //   }));
    // }
    if (name in consortiumEditInputs) {
      setConsortiumEditInputs((prevState) => ({
        ...prevState,
        [name]: finalValue,
      }));
    }
  };

  useEffect(() => {
    if (
      consortiumEditInputs.moType !== MOTypeChoice.CUSTOM &&
      consortiumEditInputs.moTypeCustomValue?.length
    ) {
      setConsortiumEditInputs((prevState) => ({
        ...prevState,
        moTypeCustomValue: "",
      }));
    }
    if (
      consortiumEditInputs.leaseType !== LeaseTypeChoice.CUSTOM &&
      consortiumEditInputs.leaseTypeCustomValue?.length
    ) {
      setConsortiumEditInputs((prevState) => ({
        ...prevState,
        leaseTypeCustomValue: "",
      }));
    }
  }, [consortiumEditInputs]);

  const updateHandler = async (): Promise<void> => {
    // if (!canUserEditOrgInfo) return;

    const errorMessage = airportFieldValidator(
      generalTabFieldValidations.map((validation) => ({
        ...validation,
        field:
          consortiumEditInputs[
            validation.field as keyof IEditConsortiumInput
          ]?.toString() ?? "",
      }))
    );
    if (!!errorMessage) {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
        payload: {
          text: errorMessage,
          type: "error",
        },
      });
      setIsConfirmModalOpen(false);
      return;
    }
    if (state.activeConsortium) {
      const consortiumEditInputsCopy = { ...consortiumEditInputs };
      consortiumEditInputsCopy.thirdPartyDebt = isNaN(
        parseInt(consortiumEditInputsCopy?.thirdPartyDebt?.toString() ?? "")
      )
        ? null
        : consortiumEditInputsCopy.thirdPartyDebt;
      consortiumEditInputsCopy.memberLoans = isNaN(
        parseInt(consortiumEditInputsCopy?.memberLoans?.toString() ?? "")
      )
        ? null
        : consortiumEditInputsCopy.memberLoans;
      consortiumEditInputsCopy.aviationLiabilityLimit = isNaN(
        parseInt(
          consortiumEditInputsCopy?.aviationLiabilityLimit?.toString() ?? ""
        )
      )
        ? null
        : consortiumEditInputsCopy.aviationLiabilityLimit;
      consortiumEditInputsCopy.environmentalLiabilityLimit = isNaN(
        parseInt(
          consortiumEditInputsCopy?.environmentalLiabilityLimit?.toString() ??
            ""
        )
      )
        ? null
        : consortiumEditInputsCopy.environmentalLiabilityLimit;
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "Updating Consortium Information",
      });
      const consortiumResponse = await MapService.updateAirportConsortiumGroup(
        state.activeConsortium.id,
        consortiumEditInputsCopy
      );
      if (typeof consortiumResponse !== "string") {
        dispatch({
          type: mapContextAction.SET_ACTIVE_CONSORTIUM,
          payload: { ...state.activeConsortium, ...consortiumResponse },
        });
        dispatch({
          type: mapContextAction.SET_ACTIVE_AIRPORT,
          payload: {
            ...state.activeAirport,
            consortiumGroup: {
              ...state.activeAirport?.consortiumGroup,
              items: state.activeAirport?.consortiumGroup?.items.map(
                (consortiumGroup) => {
                  if (consortiumGroup?.id === state.activeConsortium?.id) {
                    return { ...state.activeConsortium, ...consortiumResponse };
                  }
                  return consortiumGroup;
                }
              ),
            },
          },
        });
      } else {
        alert(consortiumResponse);
        setIsInEditMode(false);
        return;
      }
    }
    // dispatch({
    //   type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
    //   payload: "Updating Airport Information",
    // });
    // const response = await MapService.updateAirport(
    //   airport.id,
    //   airportEditInputsCopy
    // );
    // if (typeof response !== "string") {
    //   dispatch({
    //     type: mapContextAction.SET_ACTIVE_AIRPORT,
    //     payload: { ...state.activeAirport, ...response },
    //   });
    //   setIsInEditMode(false);
    //   setAirportEditInputs(airportEditInputsCopy);
    // } else {
    //   alert(response);
    // }
    setIsConfirmModalOpen(false);
    dispatch({
      type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      payload: "",
    });
  };

  const companyOptions = state.companyList
    .map((company) => ({
      name: company.name,
      value: company.id,
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  const infoSectionData = [
    {
      heading: "Chair Company",
      editInput: consortiumEditInputs,
      isEditable: false,
      infoArray: [
        {
          name: generalTabFields.CHAIR_ID,
          value: state.activeConsortium?.chair?.name ?? "",
          type: generalTabFieldType.ENUM,
          placeHolder: "Name",
          enumOptions: state.companyList
            .reduce(
              (
                currentCompanyList: Array<{ name: string; value: string }>,
                company
              ) => {
                if (company.type === CompanyTypeChoice.AIRLINE) {
                  currentCompanyList.push({
                    name: company.name,
                    value: company.id,
                  });
                }
                return currentCompanyList;
              },
              []
            )
            .sort((a, b) => a.name.localeCompare(b.name)),
        },
      ],
    },
    {
      heading: "Chair Contact",
      editInput: consortiumEditInputs,
      isEditable: false,
      infoArray: [
        {
          name: generalTabFields.CHAIR_CONTACT_ID,
          value: state.activeConsortium?.chairContact
            ? `${state.activeConsortium?.chairContact?.firstName ?? ""} ${
                state.activeConsortium?.chairContact?.lastName ?? ""
              } (Chair)`
            : "",
          type: generalTabFieldType.ENUM,
          placeHolder: "Person",
          enumOptions: state.userList.reduce(
            (currentUserList: Array<{ name: string; value: string }>, user) => {
              if (user.companyId === consortiumEditInputs.chairId) {
                currentUserList.push({
                  name: `${user.firstName} ${user.lastName}`,
                  value: user.id,
                });
              }
              return currentUserList;
            },
            []
          ),
        },
        {
          name: "Contact",
          value: state.activeConsortium?.chairContact?.mobile ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Contact",
          visibleOnEdit: false,
        },
      ],
    },
    {
      heading: "Consortium Type",
      isEditable: false,
      infoArray: [
        {
          name: generalTabFields.CONSORTIUM_TYPE,
          value: airport?.[generalTabFields.CONSORTIUM_TYPE]
            ? organizationGroupType[
                airport?.[
                  generalTabFields.CONSORTIUM_TYPE
                ] as keyof typeof organizationGroupType
              ]
            : "",
          type: generalTabFieldType.TEXT,
        },
      ],
    },
    {
      heading: "Operator Company",
      editInput: consortiumEditInputs,
      isEditable: false,
      infoArray: [
        {
          name: generalTabFields.OPERATOR_ID,
          value: state.activeConsortium?.operator?.name ?? "",
          type: generalTabFieldType.ENUM,
          placeHolder: "Name",
          enumOptions: state.companyList
            .reduce(
              (
                currentCompanyList: Array<{ name: string; value: string }>,
                company
              ) => {
                if (company.type === CompanyTypeChoice.OPERATOR) {
                  currentCompanyList.push({
                    name: company.name,
                    value: company.id,
                  });
                }
                return currentCompanyList;
              },
              []
            )
            .sort((a, b) => a.name.localeCompare(b.name)),
        },
      ],
    },
    {
      heading: "General Manager",
      editInput: consortiumEditInputs,
      isEditable: false,
      infoArray: [
        {
          name: generalTabFields.OPERATOR_GENERAL_MANAGER_ID,
          value: state.activeConsortium?.operatorGeneralManager
            ? `${
                state.activeConsortium?.operatorGeneralManager?.firstName ?? ""
              } ${
                state.activeConsortium?.operatorGeneralManager?.lastName ?? ""
              } (GM)`
            : "",
          type: generalTabFieldType.ENUM,
          placeHolder: "Person",
          enumOptions: state.userList.reduce(
            (currentUserList: Array<{ name: string; value: string }>, user) => {
              if (user.companyId === consortiumEditInputs.operatorId) {
                currentUserList.push({
                  name: `${user.firstName} ${user.lastName}`,
                  value: user.id,
                });
              }
              return currentUserList;
            },
            []
          ),
        },
        {
          name: "Contact",
          value: state.activeConsortium?.operatorGeneralManager?.mobile ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Contact",
          visibleOnEdit: false,
        },
      ],
    },
    {
      heading: "Secondary Contact",
      editInput: consortiumEditInputs,
      isEditable: false,
      infoArray: [
        {
          name: generalTabFields.SECONDARY_CONTACT,
          value: state.activeConsortium?.secondaryContact
            ? `${state.activeConsortium?.secondaryContact ?? ""}`
            : "",
          type: generalTabFieldType.ENUM,
          placeHolder: "Person",
          enumOptions: state.userList.reduce(
            (currentUserList: Array<{ name: string; value: string }>, user) => {
              if (user.companyId === consortiumEditInputs.operatorId) {
                currentUserList.push({
                  name: `${user.firstName} ${user.lastName}`,
                  value: user.id,
                });
              }
              return currentUserList;
            },
            []
          ),
        },
      ],
    },
    {
      heading: "Facility Owner",
      editInput: consortiumEditInputs,
      isEditable: false,
      infoArray: [
        {
          name: generalTabFields.FACILITY_OWNER,
          value: state.activeConsortium?.facilityOwner ?? "",
          type: generalTabFieldType.ENUM,
          placeHolder: "Name",
          enumOptions: state.companyList
            .reduce(
              (
                currentCompanyList: Array<{ name: string; value: string }>,
                company
              ) => {
                if (company.type === CompanyTypeChoice.SUPPLIER) {
                  currentCompanyList.push({
                    name: company.name,
                    value: company.id,
                  });
                }
                return currentCompanyList;
              },
              []
            )
            .sort((a, b) => a.name.localeCompare(b.name)),
        },
      ],
    },
    {
      heading: "Facility Location",
      // isEditable: false,
      editInput: consortiumEditInputs,
      infoArray: [
        {
          name: generalTabFields.FACILITY_LOCATION,
          value: state.activeConsortium?.[generalTabFields.FACILITY_LOCATION]
            ? facilityLocationMapping[
                state.activeConsortium?.[
                  generalTabFields.FACILITY_LOCATION
                ] as keyof typeof facilityLocationMapping
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: facilityLocationOptions,
          placeHolder: "Location",
        },
      ],
    },
    {
      heading: "Total Capacity",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.TOTAL_CAPACITY_DAYS,
          value:
            state.activeConsortium?.[generalTabFields.TOTAL_CAPACITY_DAYS] !==
              undefined &&
            state.activeConsortium?.[generalTabFields.TOTAL_CAPACITY_DAYS] !==
              null
              ? `${
                  state.activeConsortium?.[generalTabFields.TOTAL_CAPACITY_DAYS]
                } Days`
              : "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Days",
        },
        {
          name: generalTabFields.TOTAL_CAPACITY_GALLONS,
          value:
            state.activeConsortium?.[
              generalTabFields.TOTAL_CAPACITY_GALLONS
            ] !== undefined &&
            state.activeConsortium?.[
              generalTabFields.TOTAL_CAPACITY_GALLONS
            ] !== null
              ? `${
                  state.activeConsortium?.[
                    generalTabFields.TOTAL_CAPACITY_GALLONS
                  ]
                } Gallons`
              : "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Gallons",
        },
      ],
    },
    {
      heading: "Secured",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.SECURED,
          value: state.activeConsortium?.[generalTabFields.SECURED]
            ? "Yes"
            : "No",
          type: generalTabFieldType.BOOLEAN,
        },
      ],
    },
    // {
    //   heading: "M&O Expiration",
    //   isEditable: true,
    //   infoArray: [
    //     {
    //       name: generalTabFields.MO_EXPIRATION,
    //       value: airport?.[generalTabFields.MO_EXPIRATION]
    //         ? dayJs(airport[generalTabFields.MO_EXPIRATION]).format(
    //             "MM/DD/YYYY"
    //           )
    //         : "",
    //       type: generalTabFieldType.DATE,
    //     },
    //   ],
    //   inViewMoreSection: true,
    // },
    {
      heading: "M&O Type",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.MO_TYPE,
          value: state.activeConsortium?.[generalTabFields.MO_TYPE]
            ? MOTypeChoiceMapping[
                state.activeConsortium?.[
                  generalTabFields.MO_TYPE
                ] as keyof typeof MOTypeChoiceMapping
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: MOTypeOptions,
          placeHolder: "Type",
        },
      ],
      inViewMoreSection: true,
    },
    {
      heading: "M&O Type Custom Value",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.MO_TYPE_CUSTOM_VALUE,
          value:
            state.activeConsortium?.[generalTabFields.MO_TYPE_CUSTOM_VALUE] ??
            "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Custom Type",
          disabled:
            consortiumEditInputs[generalTabFields.MO_TYPE] !==
            MOTypeChoice.CUSTOM,
        },
      ],
      inViewMoreSection: true,
    },
    // {
    //   heading: "Lease Expiration",
    //   isEditable: true,
    //   infoArray: [
    //     {
    //       name: generalTabFields.LEASE_EXPIRATION,
    //       value: airport?.[generalTabFields.LEASE_EXPIRATION]
    //         ? dayJs(airport[generalTabFields.LEASE_EXPIRATION]).format(
    //             "MM/DD/YYYY"
    //           )
    //         : "",
    //       type: generalTabFieldType.DATE,
    //       placeHolder: "Date",
    //     },
    //   ],
    //   inViewMoreSection: true,
    // },
    {
      heading: "Lease Type",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.LEASE_TYPE,
          value: state.activeConsortium?.[generalTabFields.LEASE_TYPE]
            ? LeaseTypeChoiceMapping[
                state.activeConsortium?.[
                  generalTabFields.LEASE_TYPE
                ] as keyof typeof LeaseTypeChoiceMapping
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: LeaseTypeOptions,
          placeHolder: "Type",
        },
      ],
      inViewMoreSection: true,
    },
    {
      heading: "Lease Type Custom Value",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.LEASE_TYPE_CUSTOM_VALUE,
          value:
            state.activeConsortium?.[
              generalTabFields.LEASE_TYPE_CUSTOM_VALUE
            ] ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: 'Custom Type"',
          disabled:
            consortiumEditInputs[generalTabFields.LEASE_TYPE] !==
            LeaseTypeChoice.CUSTOM,
        },
      ],
      inViewMoreSection: true,
    },
    {
      heading: "Legal Counsel Company",
      editInput: consortiumEditInputs,
      isEditable: false,
      infoArray: [
        {
          name: generalTabFields.LEGAL_COUNSEL,
          value: state.activeConsortium?.legalCounsel ?? "",
          type: generalTabFieldType.ENUM,
          placeHolder: "Name",
          enumOptions: state.companyList
            .reduce(
              (
                currentCompanyList: Array<{ name: string; value: string }>,
                company
              ) => {
                if (company.type === CompanyTypeChoice.LEGAL) {
                  currentCompanyList.push({
                    name: company.name,
                    value: company.id,
                  });
                }
                return currentCompanyList;
              },
              []
            )
            .sort((a, b) => a.name.localeCompare(b.name)),
        },
      ],
      inViewMoreSection: true,
    },
    {
      heading: "Legal Counsel Contact",
      editInput: consortiumEditInputs,
      isEditable: false,
      infoArray: [
        {
          name: generalTabFields.LEGAL_COUNSEL_CONTACT,
          value: state.activeConsortium?.legalCounselContact ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Name",
        },
      ],
      inViewMoreSection: true,
    },
    {
      heading: "Date of Last Financial Statement Audit",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.DATE_LAST_FINANCIAL_SUP,
          value: state.activeConsortium?.[
            generalTabFields.DATE_LAST_FINANCIAL_SUP
          ]
            ? dayJs(
                state.activeConsortium?.[
                  generalTabFields.DATE_LAST_FINANCIAL_SUP
                ]
              ).format("MM/DD/YYYY")
            : "",
          type: generalTabFieldType.DATE,
          placeHolder: "Date",
        },
      ],
      inViewMoreSection: true,
    },
    // {
    //   heading: "Date of Last A4A Operational Review",
    //   isEditable: true,
    //   infoArray: [
    //     {
    //       name: generalTabFields.DATE_LAST_A4A_OPERATIONAL_REVIEW,
    //       value: airport?.[generalTabFields.DATE_LAST_A4A_OPERATIONAL_REVIEW]
    //         ? dayJs(
    //             airport[generalTabFields.DATE_LAST_A4A_OPERATIONAL_REVIEW]
    //           ).format("MM/DD/YYYY")
    //         : "",
    //       type: generalTabFieldType.DATE,
    //       placeHolder: "Date",
    //     },
    //   ],
    //   inViewMoreSection: true,
    // },
    {
      heading: "Net Inventory Calculation",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.NET_INVENTORY_CALCULATION,
          value:
            state.activeConsortium?.[
              generalTabFields.NET_INVENTORY_CALCULATION
            ] ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Calculation",
        },
      ],
      inViewMoreSection: true,
      infoText: `If a “net” inventory system is utilized, identify and describe the methodology and equipment used in performing the net inventory calculations, including the frequency of testing the fuel for temperature adjustment. In addition, identify and specify (to the extent tested or`,
    },
    {
      heading: "Entry Fee",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.ENTRY_FEE,
          value: state.activeConsortium?.[generalTabFields.ENTRY_FEE] ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Fee",
        },
      ],
      inViewMoreSection: true,
    },
    {
      heading: "Nonmember Rate",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.NON_MEMBER_RATE,
          value:
            state.activeConsortium?.[generalTabFields.NON_MEMBER_RATE] ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Rate",
        },
      ],
      inViewMoreSection: true,
    },
    {
      heading: "Cost Sharing Formula",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.COST_SHARING_FORMULA,
          value:
            state.activeConsortium?.[generalTabFields.COST_SHARING_FORMULA] ??
            "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Formula",
        },
      ],
      inViewMoreSection: true,
    },
    {
      heading: "Reserve Deposit or Prebill",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.RESERVE_DEPOSIT_PREBILL,
          value: state.activeConsortium?.[
            generalTabFields.RESERVE_DEPOSIT_PREBILL
          ]
            ? reservePrebillTypeChoiceMapping[
                state.activeConsortium?.[
                  generalTabFields.RESERVE_DEPOSIT_PREBILL
                ] as keyof typeof reservePrebillTypeChoiceMapping
              ]
            : "",
          type: generalTabFieldType.ENUM,
          enumOptions: reservePrebillTypeOptions,
          placeHolder: "Deposit or Prebill",
        },
      ],
      inViewMoreSection: true,
    },
    {
      heading: "Third Party Debt",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.THIRD_PARTY_DEBT,
          value:
            state.activeConsortium?.[
              generalTabFields.THIRD_PARTY_DEBT
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Debt",
        },
      ],
      inViewMoreSection: true,
      infoText: "Total outstanding as of 12/31 of previous year",
    },
    // {
    //   heading: "Total Outstanding Debt",
    //   isEditable: true,
    //   infoArray: [
    //     {
    //       name: generalTabFields.TOTAL_OUTSTANDING_DEBT,
    //       value:
    //         airport?.[generalTabFields.TOTAL_OUTSTANDING_DEBT]?.toString() ??
    //         "",
    //       type: generalTabFieldType.TEXT,
    //       placeHolder: "Debt",
    //     },
    //   ],
    //   inViewMoreSection: true,
    // },
    {
      heading: "Member Loans",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.MEMBER_LOANS,
          value:
            state.activeConsortium?.[
              generalTabFields.MEMBER_LOANS
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Loans",
        },
      ],
      inViewMoreSection: true,
      infoText: "Total outstanding as of 12/31 of previous year",
    },
    {
      heading: "Date of Last Property Appraisal",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.DATE_LAST_PROPERTY_APPRAISAL,
          value: state.activeConsortium?.[
            generalTabFields.DATE_LAST_PROPERTY_APPRAISAL
          ]
            ? dayJs(
                state.activeConsortium?.[
                  generalTabFields.DATE_LAST_PROPERTY_APPRAISAL
                ]
              ).format("MM/DD/YYYY")
            : "",
          type: generalTabFieldType.DATE,
          placeHolder: "Appraisal",
        },
      ],
      inViewMoreSection: true,
      infoText: "What is the effective date of the current property",
    },
    {
      heading: "Aviation Liability Limit",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.AVIATION_LIABILITY_LIMIT,
          value:
            state.activeConsortium?.[
              generalTabFields.AVIATION_LIABILITY_LIMIT
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Limit",
        },
      ],
      inViewMoreSection: true,
      infoText:
        "What is the limit (in millions USD) for aviation liability as insured, or otherwise as required by the airport authority?",
    },
    {
      heading: "Environmental Liability Limit",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.ENVIRONMENTAL_LIABILITY_LIMIT,
          value:
            state.activeConsortium?.[
              generalTabFields.ENVIRONMENTAL_LIABILITY_LIMIT
            ]?.toString() ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Limit",
        },
      ],
      inViewMoreSection: true,
      infoText:
        "What is the purchased environmental coverage limit (in millions USD) per occurrence?",
    },
    {
      heading: "Comments",
      editInput: consortiumEditInputs,
      isEditable: true,
      infoArray: [
        {
          name: generalTabFields.COMMENTS,
          value: state.activeConsortium?.[generalTabFields.COMMENTS] ?? "",
          type: generalTabFieldType.TEXT,
          placeHolder: "Comments",
        },
      ],
      inViewMoreSection: true,
    },
  ];

  return (
    <>
      {/* {canUserEditOrgInfo && ( */}
      <>
        <AirportDetailDrawerEditIcons>
          {!isInEditMode && (
            <AirportDetailDrawerInfoCardEditIcon
              onClick={() => setIsInEditMode(true)}
            />
          )}

          {isInEditMode && (
            <AirportDetailDrawerInfoCardCloseIcon
              onClick={() => setIsInEditMode(false)}
            />
          )}
        </AirportDetailDrawerEditIcons>
      </>
      {/* )} */}
      <AirportDetailDrawerInformationSection>
        {infoSectionData.map((sectionData) => {
          if (
            !sectionData.inViewMoreSection ||
            (sectionData.inViewMoreSection && enableViewMore)
          ) {
            return (
              <AirportDetailDrawerInfoCard
                key={sectionData.heading}
                role={sectionData.heading}
                isEditable={sectionData.isEditable}
                infoArray={sectionData.infoArray}
                isInEditMode={isInEditMode}
                editInputs={sectionData.editInput ?? consortiumEditInputs}
                editInputChangeHandler={editInputChangeHandler}
                infoText={sectionData.infoText}
              />
            );
          }
        })}
      </AirportDetailDrawerInformationSection>
      <AirportDetailDrawerInfoBtns>
        <Button
          variant="text"
          onClick={() => setEnableViewMore((prevValue) => !prevValue)}
        >
          View {enableViewMore ? "Less" : "More"} Info
        </Button>
        {isInEditMode && (
          <AirportDetailDrawerInfoCardSaveButton
            color="info"
            onClick={() => setIsConfirmModalOpen(true)}
          >
            Save Changes
          </AirportDetailDrawerInfoCardSaveButton>
        )}
      </AirportDetailDrawerInfoBtns>
      <GeneralTabConfirmationModal
        open={isConfirmModalOpen}
        handleClose={() => setIsConfirmModalOpen(false)}
        confirmUpdate={() => updateHandler()}
      />
    </>
  );
};

export default GeneralTab;
