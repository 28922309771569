import React, { ChangeEvent, useRef, useState } from "react";
import readXlsxFile from "read-excel-file";
import {
  AddVolumeButton,
  AddVolumeFailReasonBox,
  AddVolumeModalBackdrop,
  AddVolumeModalContent,
  AddVolumeModalHeading,
  AddVolumeModalProgressBox,
  AddVolumeModalProgressTitle,
  AddVolumeModalProgressValue,
  AddVolumeModalReportBox,
  AddVolumeModalReportContent,
  AddVolumeModalReportHeading,
  AddVolumeModalReportText,
  AddVolumeReportContent,
} from "./style";
import {
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  AirportDetailDrawerFileUploadContainer,
  AirportDetailDrawerFileUploadContainerBtnContainer,
  AirportDetailDrawerFileUploadContainerBtnText,
  AirportDetailDrawerFileUploadContainerUploadBox,
  AirportDetailDrawerFileUploadContainerUploadIcon,
  AirportDetailDrawerFileUploadContainerUploadText,
  AirportDetailDrawerFileUploadContainerUploadedFileName,
  AirportDetailDrawerOverlayText,
} from "../AiportDetailsDrawer/style";
import { mapContextAction, useMapContext } from "../MapProvider/MapProvider";
import MapService from "../../services/MapService";
import { IAirportVolume } from "../MapProvider/types";
import { API } from "aws-amplify";

interface IAddVolumeModal {
  open: boolean;
  handleClose: Function;
}

const AddVolumeModal = ({ open, handleClose }: IAddVolumeModal) => {
  const { state, dispatch } = useMapContext();
  const [isReportModalOpened, setIsReportModalOpened] =
    useState<boolean>(false);
  const [draggingFileOver, setDraggingFileOver] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState<{
    heading: string;
    total: number;
    updated: number;
    added: number;
    failed: number;
    isFileRejected: boolean;
    failReasons: { row: number; reason: string }[];
  }>({
    heading: "",
    total: 0,
    updated: 0,
    added: 0,
    failed: 0,
    isFileRejected: false,
    failReasons: [],
  });

  const handleFileDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDraggingFileOver(true);
  };

  const handleFileDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDraggingFileOver(false);
    if (event?.dataTransfer.files?.[0]) {
      if (
        ![
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ]?.includes(event?.dataTransfer.files?.[0].type)
      ) {
        alert(`Wrong MIME Type: ${event?.dataTransfer.files?.[0].type}`);
        return;
      }
      setUploadedFile(event.dataTransfer.files[0]);
    }
  };

  const handleFileUploadBoxClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files?.[0]) {
      if (
        ![
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ]?.includes(event?.target?.files?.[0].type)
      ) {
        alert(`Wrong MIME Type: ${event?.target?.files?.[0].type}`);
        return;
      }
      setUploadedFile(event.target.files[0]);
    }
  };

  const validateFileRows = (
    rows: any[],
    airportCodeIdMapping: any[]
  ): { reason: string; row: number }[] => {
    const errorsArray: { reason: string; row: number }[] = [];

    if (rows[0].length !== 3) {
      errorsArray.push({
        reason: "Invalid columns count, file must contain only 3 columns.",
        row: 1,
      });
      return errorsArray;
    }

    const uniqueObj: any = {};

    rows.forEach((volumeRow: any, rowIndex: number) => {
      if (
        typeof volumeRow[1] !== "number" ||
        volumeRow[1] < 2019 ||
        volumeRow[1] > 9999
      ) {
        errorsArray.push({
          reason:
            "Invalid year, Year must be greater than 2019 and must be in 4 digits.",
          row: rowIndex + 2,
        });
      }
      if (typeof volumeRow[2] !== "number" || volumeRow[2] < 0) {
        errorsArray.push({
          reason: "Invalid volume, Volume must be valid positive number.",
          row: rowIndex + 2,
        });
      }
      if (volumeRow[0].length !== 3) {
        errorsArray.push({
          reason: "Invalid airport code, Code must be of 3 character.",
          row: rowIndex + 2,
        });
      }
      if (uniqueObj[`${volumeRow[0]}-${volumeRow[1]}`]) {
        errorsArray.push({
          reason: `Duplicate row, This record already exists on row ${
            uniqueObj[`${volumeRow[0]}-${volumeRow[1]}`]
          }`,
          row: rowIndex + 2,
        });
      } else {
        uniqueObj[`${volumeRow[0]}-${volumeRow[1]}`] = rowIndex + 2;
      }
      if (!airportCodeIdMapping[volumeRow[0]]) {
        errorsArray.push({
          reason: `Corresponding airport doesnt exist.`,
          row: rowIndex + 2,
        });
      }
    });
    return errorsArray;
  };

  const handleUpload = async () => {
    // if no file selected then return
    if (!uploadedFile) return;
    let fileRejected = false;

    try {
      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "Validating file rows.",
      });
      // if not allowed MIME type
      if (
        ![
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ]?.includes(uploadedFile?.type)
      ) {
        alert(`Wrong MIME Type: ${uploadedFile?.type}`);
        return;
      }

      // const [file_name, file_ext] = uploadedFile.name.includes(".")
      //   ? uploadedFile.name.split(".")
      //   : [uploadedFile.name, ""];

      const rows = await readXlsxFile(uploadedFile);

      const airportCodeIdMapping: any = {};
      state.aiportList.forEach((airport) => {
        airportCodeIdMapping[airport.code] = airport.id;
      });
      const promises: any = [];
      rows.shift();
      const rowsValidity = validateFileRows(rows, airportCodeIdMapping);
      if (rowsValidity.length) {
        fileRejected = true;
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          heading: "File Rejected",
          failReasons: rowsValidity,
          isFileRejected: true,
        }));
        return;
      }

      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "Deleting existing volumes data.",
      });

      await API.del("mailapi", `/mail/volume/all`, {
        headers: {},
        response: true,
      });

      dispatch({
        type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
        payload: "Uploading airport volumes data.",
      });

      rows.forEach((volumeRow: any[]) => {
        promises.push(
          new Promise(async (resolve, reject) => {
            try {
              const [code, year, volume] = volumeRow;
              if (!code || !year || !volume) {
                reject("Invalid data row.");
              }
              if (!airportCodeIdMapping[code]) {
                reject("Corresponding airport doesnt exist.");
              }
              const existingData =
                await MapService.findAndGetAirportVolumeByCodeYear(
                  airportCodeIdMapping[code],
                  year
                );
              if (existingData) {
                const updatedData = await MapService.updateAirportVolume(
                  existingData.id,
                  { volume }
                );
                resolve({ type: "updated", data: updatedData });
              } else {
                const addedData = await MapService.createAirportVolume({
                  airportId: airportCodeIdMapping[code],
                  volume,
                  year,
                });
                resolve({ type: "added", data: addedData });
              }
            } catch (error) {
              reject(error); // Reject the promise if there's an error
            }
          })
        );
      });
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        total: promises.length,
      }));
      await Promise.allSettled(promises).then((results: any[]) => {
        let updated = 0;
        let added = 0;
        let failed = 0;
        let failReasons: { row: number; reason: string }[] = [];
        const updatedVolumes: IAirportVolume[] = [];
        results.forEach((result: any, index) => {
          if (result?.value?.type === "updated") {
            updated += 1;
          }
          if (result?.value?.type === "added") {
            added += 1;
          }
          if (result.status !== "fulfilled") {
            failReasons.push({ reason: result.reason, row: index + 2 });
            failed += 1;
          } else {
            updatedVolumes.push(result.value.data);
          }
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            heading: "Upload report",
            updated,
            added,
            failed,
            failReasons,
            isFileRejected: false,
          }));
        });

        // const oldVolumesArrayObj = state.airportVolumeList.reduce(
        //   (currentVolumes: any, volume: IAirportVolume) => {
        //     currentVolumes[volume.id] = volume;
        //     return currentVolumes;
        //   },
        //   {}
        // );

        // updatedVolumes.forEach((volume: IAirportVolume) => {
        //   oldVolumesArrayObj[volume.id] = volume;
        // });

        // const mergedVolumesArray = Object.values(oldVolumesArrayObj);

        dispatch({
          type: mapContextAction.SET_AIPORT_VOLUME_LIST,
          payload: updatedVolumes,
        });
      });
    } catch (err) {
      // dispatch({
      //   type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
      //   payload: "",
      // });
      // dispatch({
      //   type: mapContextAction.SET_AIRPORT_UPDATE_ALERT,
      //   payload: {
      //     text: "Error occured while uploading File.",
      //     type: "error",
      //   },
      // });
    } finally {
      setTimeout(
        () => {
          dispatch({
            type: mapContextAction.SET_AIRPORT_UPDATE_TEXT,
            payload: "",
          });

          setIsReportModalOpened(true);
        },
        fileRejected ? 0 : 2500
      );
    }
  };

  return (
    <>
      <AddVolumeModalBackdrop open={!!state.airportUpdateText}>
        <CircularProgress color="inherit" />
        <AirportDetailDrawerOverlayText
          sx={{ color: "inherit", marginTop: "10px" }}
        >
          {state.airportUpdateText}
        </AirportDetailDrawerOverlayText>
        <AddVolumeModalProgressBox>
          <AddVolumeModalProgressTitle>Total:</AddVolumeModalProgressTitle>
          <AddVolumeModalProgressValue>
            {uploadProgress.total}
          </AddVolumeModalProgressValue>
          <AddVolumeModalProgressTitle>Added:</AddVolumeModalProgressTitle>
          <AddVolumeModalProgressValue>
            {uploadProgress.added}
          </AddVolumeModalProgressValue>
          <AddVolumeModalProgressTitle>Updated:</AddVolumeModalProgressTitle>
          <AddVolumeModalProgressValue>
            {uploadProgress.updated}
          </AddVolumeModalProgressValue>
          <AddVolumeModalProgressTitle>Failed:</AddVolumeModalProgressTitle>
          <AddVolumeModalProgressValue>
            {uploadProgress.failed}
          </AddVolumeModalProgressValue>
        </AddVolumeModalProgressBox>
      </AddVolumeModalBackdrop>
      <Modal
        open={open && !state.airportUpdateText}
        onClose={() => handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddVolumeModalContent>
          <AddVolumeModalHeading>Import Volumes</AddVolumeModalHeading>
          <AirportDetailDrawerFileUploadContainer
            sx={{ width: "350px", marginTop: "0px" }}
          >
            <AirportDetailDrawerFileUploadContainerUploadBox
              onDragOver={handleFileDragOver}
              onDragLeave={() => setDraggingFileOver(false)}
              onDrop={handleFileDrop}
              onClick={handleFileUploadBoxClick}
              sx={{
                ...(draggingFileOver
                  ? {
                      backgroundColor: "#77777755",
                      border: "1px solid #77777755",
                      borderStyle: "solid",
                    }
                  : {}),
                height: "125px",
              }}
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{
                  display: "none",
                }}
                accept=".xlsx, .xls"
              />
              <AirportDetailDrawerFileUploadContainerUploadText>
                Drag and drop an xls file here or click
              </AirportDetailDrawerFileUploadContainerUploadText>
              {!!uploadedFile && (
                <AirportDetailDrawerFileUploadContainerUploadedFileName>
                  {uploadedFile?.name ?? ""}
                </AirportDetailDrawerFileUploadContainerUploadedFileName>
              )}
              <AirportDetailDrawerFileUploadContainerUploadIcon />
            </AirportDetailDrawerFileUploadContainerUploadBox>
            <AirportDetailDrawerFileUploadContainerBtnContainer
              sx={{ marginTop: "15px" }}
            >
              <AirportDetailDrawerFileUploadContainerBtnText
                sx={{ fontSize: "1rem", mr: "auto" }}
                onClick={() => handleClose()}
              >
                Close
              </AirportDetailDrawerFileUploadContainerBtnText>
              <AirportDetailDrawerFileUploadContainerBtnText
                sx={{ fontSize: "1rem" }}
                onClick={() => setUploadedFile(undefined)}
              >
                Cancel
              </AirportDetailDrawerFileUploadContainerBtnText>
              <AirportDetailDrawerFileUploadContainerBtnText
                sx={{ fontSize: "1rem" }}
                onClick={handleUpload}
              >
                Upload
              </AirportDetailDrawerFileUploadContainerBtnText>
            </AirportDetailDrawerFileUploadContainerBtnContainer>
          </AirportDetailDrawerFileUploadContainer>
        </AddVolumeModalContent>
      </Modal>

      <Modal
        open={isReportModalOpened}
        onClose={() => {
          setIsReportModalOpened(false);
          setUploadProgress({
            heading: "",
            total: 0,
            updated: 0,
            added: 0,
            failed: 0,
            failReasons: [],
            isFileRejected: false,
          });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddVolumeReportContent>
          <AddVolumeModalHeading>
            {uploadProgress.heading}
          </AddVolumeModalHeading>
          <AddVolumeModalReportContent>
            {!uploadProgress.isFileRejected && (
              <>
                <AddVolumeModalReportBox>
                  <AddVolumeModalReportHeading>
                    Total
                  </AddVolumeModalReportHeading>
                  <AddVolumeModalReportText>
                    {uploadProgress.total}
                  </AddVolumeModalReportText>
                </AddVolumeModalReportBox>
                <AddVolumeModalReportBox>
                  <AddVolumeModalReportHeading>
                    Added
                  </AddVolumeModalReportHeading>
                  <AddVolumeModalReportText>
                    {uploadProgress.added}
                  </AddVolumeModalReportText>
                </AddVolumeModalReportBox>
                <AddVolumeModalReportBox>
                  <AddVolumeModalReportHeading>
                    Updated
                  </AddVolumeModalReportHeading>
                  <AddVolumeModalReportText>
                    {uploadProgress.updated}
                  </AddVolumeModalReportText>
                </AddVolumeModalReportBox>
                <AddVolumeModalReportBox>
                  <AddVolumeModalReportHeading>
                    Failed
                  </AddVolumeModalReportHeading>
                  <AddVolumeModalReportText>
                    {uploadProgress.failed}
                  </AddVolumeModalReportText>
                </AddVolumeModalReportBox>
              </>
            )}
            <AddVolumeModalReportHeading fontWeight="bold">
              Failure reason(s):
            </AddVolumeModalReportHeading>
            <AddVolumeFailReasonBox>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr.</TableCell>
                      <TableCell>Row</TableCell>
                      <TableCell>Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uploadProgress.failReasons.map((reason, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{reason.row}</TableCell>
                        <TableCell>{reason.reason}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AddVolumeFailReasonBox>
          </AddVolumeModalReportContent>
        </AddVolumeReportContent>
      </Modal>
    </>
  );
};

export default AddVolumeModal;
