import React, { FC } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

// select list option type
interface IOption {
  label: string;
  value: string | any;
}

interface IAutoCompleteInputProps {
  value: any;
  error: string;
  handleChange?: (e: any) => void;
  label: string;
  disabled?: boolean;
  name: string;
  id: string;
  variant?: any;
  color?: any;
  fullWidth?: boolean;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  options: Array<IOption>;
  helperText?: string;
  onBlur?: (e?: any) => void;
}

const AutoCompleteInput: FC<IAutoCompleteInputProps> = (props) => {
  const {
    name,
    id,
    value,
    options,
    error,
    handleChange = () => {},
    label = "",
    disabled = false,
    color = "",
    fullWidth = false,
    placeholder = "",
    className = "",
    readOnly = false,
    helperText = "",
    onBlur = () => {},
  } = props;

  // value of selected item from dropdown menu
  const getSelectedValue = (selected: string) => {
    // find index of selected item in options array
    const atIndex = options?.findIndex((item) => item?.label === selected);

    // { label: '', value: '' }
    if (atIndex >= 0) {
      // return value key data of selected item
      return options[atIndex]?.value;
    } else {
      // return empty string
      return "";
    }
  };

  // get selected item label in list
  const getSelectedLabel = (selected: any) => {
    // find index of selected item in options array
    const atIndex = options?.findIndex((item) => item?.value === selected);

    // { label: '', value: '' }
    if (atIndex >= 0) {
      // return label key data of selected item
      return options[atIndex]?.label;
    } else {
      // return empty string
      return "";
    }
  };

  // check error flag
  const errorFlag = error !== "" && error !== undefined ? true : false;

  return (
    <Box sx={{ mb: 2 }}>
      <Autocomplete
        disablePortal
        id={id}
        value={value !== "" ? getSelectedLabel(value) : null}
        className={className}
        options={options?.map((item) => item?.label)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={errorFlag}
            helperText={error !== "" ? error : helperText}
            label={label}
          />
        )}
        onChange={(event: any, selected: any) =>
          handleChange({
            target: {
              name: name,
              value: selected !== null ? getSelectedValue(selected) : "",
            },
          })
        }
        disabled={disabled}
        readOnly={readOnly}
        color={color}
        fullWidth={fullWidth}
        placeholder={placeholder}
        onBlur={onBlur}
      />
    </Box>
  );
};

export { AutoCompleteInput };
