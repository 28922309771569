import React, { FC, MouseEventHandler, useState } from "react";
import {
  Box,
  Typography,
  FormGroup,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import LinearProgress from "@mui/material/LinearProgress";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// props types
interface IDataTable {
  columns: Array<any>;
  rows: Array<any>;
  title?: string;
  loading?: boolean;
  checkboxSelection?: boolean;
  onRowSelectionModelChange?: (data: Array<any>) => void;
  showFilter?: boolean;
  addButton?: boolean;
  addButtonHandler?: MouseEventHandler<SVGSVGElement>;
  pageSize?: number;
}

interface IFilterInput {
  searchColumn: string;
  handleSearchColumnChange: (e: any) => void;
  columnList: Array<string>;
  handleSearchTextChange: (e: any) => void;
  searchText: string;
}

const FilterInput: FC<IFilterInput> = ({
  searchColumn,
  handleSearchColumnChange,
  columnList,
  handleSearchTextChange,
  searchText,
}) => {
  return (
    <Box sx={{ my: 2 }}>
      <FormGroup row>
        <FormControl>
          <InputLabel id="searchByColumnLabel">Col</InputLabel>
          <Select
            labelId="searchByColumnLabel"
            id="searchByColumn"
            value={searchColumn}
            label={searchColumn}
            onChange={handleSearchColumnChange}
            size="small"
          >
            {columnList?.map((item: string, idx: number) => (
              <MenuItem value={item} key={idx}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          placeholder={`Search by ${searchColumn}`}
          size="small"
          value={searchText}
          onChange={handleSearchTextChange}
        />
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => handleSearchTextChange({ target: { value: "" } })}
        >
          <ClearIcon />
        </Button>
      </FormGroup>
    </Box>
  );
};

const DataTable: FC<IDataTable> = (props) => {
  // de structure
  const {
    rows,
    columns,
    title = "",
    loading,
    checkboxSelection = false,
    showFilter = true,
    onRowSelectionModelChange,
    addButton = false,
    addButtonHandler,
    pageSize = 10,
  } = props;

  // state
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("");

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          mb: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {addButton && (
          <AddCircleOutlineIcon
            onClick={addButtonHandler}
            sx={{ cursor: "pointer", color: "#1976d2", fontSize: "30px" }}
          />
        )}
      </Box>
      {showFilter && (
        <FilterInput
          searchColumn={searchColumn}
          handleSearchColumnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchColumn(e.target.value)
          }
          columnList={rows.length !== 0 ? Object.keys(rows[0]) : []}
          handleSearchTextChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearchText(e.target.value)
          }
          searchText={searchText}
        />
      )}
      <DataGrid
        rows={
          searchColumn !== "" && showFilter
            ? rows?.filter(
                (item: any) =>
                  item[searchColumn].toLowerCase().includes(searchText) === true
              )
            : rows
        }
        columns={columns}
        // initialState={{
        //   pagination: {
        //     paginationModel: { page: 0, pageSize: pageSize },
        //   },
        // }}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={loading}
        // checkboxSelection={checkboxSelection}
        onRowSelectionModelChange={onRowSelectionModelChange}
        autoHeight={true}
        slots={{
          loadingOverlay: LinearProgress,
        }}
      />
    </Box>
  );
};

export { DataTable };
