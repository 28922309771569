import RandomString from "randomstring";
import { AirportFieldValidator } from "../components/MapProvider/types";
import { AirportFieldValidatorType } from "../constants/map";
import { IUserOrganizationRole } from "../services/MapService/MapService";
import { constants } from ".";

// create right error format
// of joi errors
/*
  {
    "error_key": "error_message"
  }
*/

export const getFormattedValidationError = (error: any) => {
  let errors = {};

  // if error object is undefined
  if (error === undefined) {
    return errors;
  }

  error.details.forEach((err: any) => {
    errors = {
      ...errors,
      [err.context.key]: err.message,
    };
  });

  // return error new format
  return errors;
};

// check whether a object is empty or not
export const isEmptyObject = (obj: any) => {
  // if object is undefined
  if (obj === undefined) {
    return true;
  }

  // check object has any key or not
  return Object.keys(obj).length === 0;
};

// Start page loader
export const startPageLoader = () => {
  // start loader
  document.body.classList.add("loading");
};

// stop page loader
export const stopPageLoader = () => {
  // stop loader
  document.body.classList.remove("loading");
};

// get random number
export const getRandomNumber = (max: number, min = 0, isInt = true) => {
  if (isInt) {
    return Math.floor(Math.random() * (max - min) + min);
  } else {
    return Math.random() * (max - min) + min;
  }
};

// Get random strong password for cognito user
// Password policy rules:
// 	* Require at least 8 characters long.
// 	* Require at least one uppercase letter.
// 	* Require at least one lowercase letter.
// 	* Require at least one digit.
// 	* Require at least one special character.
export const getRandomPassword = () => {
  const lowerCaseCharset = "abcdefghijklmnopqrstuvwxyz";
  const upperCaseCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numericCaseCharset = "0123456789";
  const specialCharset = "!@#$%^&*";

  let randomPassword = RandomString.generate({
    length: 3,
    charset: lowerCaseCharset,
  });

  randomPassword += RandomString.generate({
    length: 3,
    charset: upperCaseCharset,
  });
  randomPassword += RandomString.generate({
    length: 3,
    charset: numericCaseCharset,
  });
  randomPassword += RandomString.generate({
    length: 3,
    charset: specialCharset,
  });

  // shuffle random password string
  return randomPassword
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");
};

export function filterObjectByKeys(obj1: any, obj2: any) {
  return Object.fromEntries(
    Object.entries(obj2).filter(([key]) => key in obj1)
  );
}

// get random string
export const getRandomString = (length: number = 0) => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let rndString = "";

  if (length !== 0) {
    rndString = RandomString.generate({ length: length, charset: charset });
  } else {
    rndString = RandomString.generate({ charset: charset });
  }
  return rndString;
};

export const airportFieldValidator = (
  fieldValidations: AirportFieldValidator[]
): string => {
  let validationError = "";
  fieldValidations.every((validation) => {
    switch (validation.type) {
      case AirportFieldValidatorType.POSITIVE_FLOAT: {
        if (!validation.isRequired && !validation.field) {
          break;
        }
        if (validation.isRequired && !validation.field) {
          validationError = validation.errorMessage;
          break;
        }
        const posFloatNumber = parseFloat(validation.field);
        if (
          isNaN(posFloatNumber) ||
          posFloatNumber < 0 ||
          posFloatNumber === Infinity
        ) {
          validationError = validation.errorMessage;
        }
        break;
      }
      case AirportFieldValidatorType.FLOAT: {
        if (!validation.isRequired && !validation.field) {
          break;
        }
        if (validation.isRequired && !validation.field) {
          validationError = validation.errorMessage;
          break;
        }
        const floatNumber = parseFloat(validation.field);
        if (isNaN(floatNumber) || floatNumber === Infinity) {
          validationError = validation.errorMessage;
        }
        break;
      }
      case AirportFieldValidatorType.STRING: {
        if (!validation.isRequired && !validation.field) {
          break;
        }
        if (validation.isRequired && !validation.field.trim().length) {
          validationError = validation.errorMessage;
          break;
        }
        break;
      }
      case AirportFieldValidatorType.ARRAY: {
        if (!validation.isRequired && !validation.field.length) {
          break;
        }
        if (validation.isRequired && !validation.field.length) {
          validationError = validation.errorMessage;
          break;
        }
        break;
      }
    }
    if (!!validationError) return false;
    return true;
  });

  return validationError;
};

// Format cognito user attributes in object instead of array
export const getFormattedCognitoUserAttributes = (attributes: any) => {
  let obj = {};

  if (attributes.length !== 0) {
    attributes?.forEach((attribute: any) => {
      obj = {
        ...obj,
        [attribute["Name"]]: attribute["Value"],
      };
    });
  }

  return obj;
};

export const canUserEditOrganizationInfo = (
  organizationId: string,
  userOrganizationRoles: IUserOrganizationRole[]
): boolean =>
  !!userOrganizationRoles.find(
    ({ organizationId: currentOrganizationId, roles }) =>
      roles.includes(
        constants.ROLES.A4A_ADMIN as keyof typeof constants.ROLES
      ) ||
      (currentOrganizationId === organizationId &&
        roles.includes(
          constants.ROLES.ORG_ADMIN as keyof typeof constants.ROLES
        ))
  );

// Capitalize first letter of every word in string
export const capitalizeFirstLetterEveryWord = (txt: string) => {
  if (txt !== "" && txt !== null && txt !== undefined) {
    const words = txt.split(" ");

    const processed = words?.map(
      (item: string) => item[0].toUpperCase() + item.slice(1)
    );

    return processed.join(" ");
  } else {
    return "";
  }
};
