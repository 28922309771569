import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface IParticipationAgreement {
  sx?: any;
  organizationName?: string;
}

const ParticipationAgreement: React.FC<IParticipationAgreement> = ({
  sx,
  organizationName = "",
}) => {
  return (
    <Box sx={sx}>
      <Typography component="p" sx={{ fontWeight: "bold", mb: 2 }}>
        1. Participation Agreement{" "}
        {organizationName !== "" ? `(${organizationName})` : ""}
      </Typography>
      <Typography component="p" sx={{ pl: 3, fontWeight: "bold" }}>
        1.1 Scope
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        This Fuel Portal Participation Agreement (“Agreement”) describes the
        conditions for access to the Fuel Portal (“Portal”) and use of the
        information contained therein.
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        <b>a. Purpose of the Portal:</b> The Airline Fuel Portal is a repository
        for pertinent U.S. fuel consortia information and includes interactive
        airport maps and a communications tool for use by the commercial
        aviation fuel community and its designees.
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        <b>b. Authorized Personnel Only:</b> Authorized Personnel pertains to
        A4A Energy Council Members and their designees, A4A Energy Council
        Liaisons and their designees, U.S. Fuel Consortia Chairs and their
        designees, U.S. Fuel Consortia General Managers and their designees, and
        U.S. Fuel Consortia Members.
      </Typography>
      <Typography component="p" sx={{ pl: 3, fontWeight: "bold" }}>
        1.2 Access
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        <b>a. Access by Authorized Personnel Only.</b> Access to the Portal is
        exclusively limited to Participants who are authorized to have such
        access under section 1 of this document. No other persons are authorized
        to have access to or use information contained in the Portal.
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        <b>b. Restrictions on Access.</b> Each Participant acknowledges that her
        or his access to the Portal is governed by the limitations stated in
        this Agreement.
      </Typography>
      <Typography component="p" sx={{ pl: 3 }}>
        <b>1.3. Content Limitations.</b> Only information that is relevant to
        the purposes of the Portal shall be included therein.
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        <b>a. Confidential Information.</b> “Confidential Information” refers to
        all documents and information placed in the Portal that is not in the
        public domain. Participants agree, whether by access or use, that they
        will neither disclose Confidential Information regarding their parent
        company, affiliates, subsidiaries and each of their employees, officers,
        directors, agents, successors and assigns nor make use, disclose,
        disseminate or otherwise make available to any third-party outside the
        Portal without prior written consent, either directly or indirectly from
        the rightful owner of the Confidential Information both during the term
        of this Agreement and after its termination.
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        <b>b. Authorized Forwarding of Documents and Information.</b> A
        Participant may forward documents and other information contained in the
        Portal to individuals whom the Participant’s company employs and,
        consistent with this Agreement and the purposes of the Portal, have a
        need for access to such documents and other information. Such forwarded
        documents or information shall be subject to the use and handling
        requirements of Subsection 1.3(a).
      </Typography>
      <Typography component="p" sx={{ pl: 3, fontWeight: "bold" }}>
        1.4. Intellectual Property Rights
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        Providers of documents and other information contained in the Portal
        retain their entire intellectual property rights—including, without
        limitation of the foregoing, their copyrights—in such documents and
        other information. A Participant is granted a limited, worldwide,
        non-exclusive license for the duration of its authorized access to the
        Portal to use such documents or other information in conformance with
        this Agreement. Any Participant that provides documents and information
        to the Portal grants this license royalty free to every other
        Participant that is authorized access to such documents and information.
      </Typography>
      <Typography component="p" sx={{ pl: 3, my: 2 }}>
        <b>1.5. Portal Maintenance</b> The Portal will be managed by InterVision
        that may perform periodic maintenance on its systems at its discretion.
        Participants acknowledge that the services and systems may not be
        available (“Maintenance Window”).
      </Typography>
      <Typography component="p" sx={{ fontWeight: "bold" }}>
        1.6. Warranty Exclusion
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        THE PARTICIPANT ACKNOWLEDGES THAT ANY DOCUMENTS, OTHER INFORMATION OR
        OTHER MATERIAL PROVIDED IN, AS PART OF, OR IN CONNECTION WITH THE FUEL
        PORTAL AND THE LICENSE HEREUNDER ARE PROVIDED “AS IS” WITHOUT ANY
        WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE REGARDING
        ANY SUBJECT MATTER OF SUCH DOCUMENT, INFORMATION OR MATERIAL. THE
        PARTICIPANT EXPRESSLY DISCLAIMS ANY IMPLIED WARRANTIES OF
        NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR
        PURPOSE, AND THOSE ARISING FROM A COURSE OF DEALING OR USAGE OF TRADE.
        WITHOUT LIMITING THE FOREGOING, NEITHER A4A NOR ANY PERSON PROVIDING ANY
        DOCUMENTS, OTHER INFORMATION OR OTHER MATERIAL TO THE FUEL PORTAL
        WARRANTS THAT ANY SUCH DOCUMENTS, OTHER INFORMATION OR OTHER MATERIAL
        SHALL BE FREE FROM ERRORS.
      </Typography>
      <Typography component="p" sx={{ fontWeight: "bold" }}>
        1.7. Indemnification
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        To the maximum extent allowed under applicable law, Participant shall
        indemnify, defend and hold harmless A4A, its parent company, affiliates,
        subsidiaries and each of their employees, officers, directors, agents,
        successors and assigns from and against any and all losses, damages,
        claims, fines, penalties and expenses (including reasonable attorneys’
        fees) that arise out of or result from (a) any acts or omissions of A4A
        and other Participants; (b) any failure to perform, or cause the
        performance of A4A and other Participants, its respective obligations
        under this Agreement, including but not limited to any subject matter of
        this Agreement under any contract, negligence, strict liability, or
        other theory: (i) for any indirect, incidental, consequential,
        exemplary, or punitive damages including, but not limited to, loss of
        profits or business interruption, even if a party has been notified of
        the possibility of such damages; or (ii) any amount of damages under any
        cause of action in excess of $100; or (c) any acts or omissions of A4A
        and other Participants or agent in violation of any applicable law or
        regulation.
      </Typography>
      <Typography component="p" sx={{ fontWeight: "bold" }}>
        1.8. Termination
      </Typography>
      <Typography component="p" sx={{ pl: 7, my: 2 }}>
        A4A may terminate this Agreement for any reason that it deems
        appropriate by providing advanced notice by written means to the
        Participant. A4A may immediately terminate Participant’s access to the
        Fuel Portal for any reasonably believed material breach of this
        Agreement.
      </Typography>
    </Box>
  );
};

export { ParticipationAgreement };
