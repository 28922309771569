import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { ICreateAirportParams } from "./types";
import {
  CreateAirportMutation,
  ListAirportsQuery,
  GetAirportQuery,
  DeleteAirportMutation,
  UpdateAirportMutation,
} from "../../API";

class AirportService {
  /*
    Task: Create a new Airport using graphql
  */
  async createAirport(airport: ICreateAirportParams) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createAirport,
        variables: { input: airport },
      })) as { data: CreateAirportMutation; errors: any[] };

      // send back newly created Airport details
      return temp.data.createAirport;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: get Airport by id using graphql
  */
  async getAirportById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: queries.getAirport,
        variables: { id },
      })) as { data: GetAirportQuery; errors: any[] };

      // send back Airport details
      return temp.data.getAirport;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: delete Airport by id using graphql
  */
  async deleteAirportById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteAirport,
        variables: { input: { id } },
      })) as { data: DeleteAirportMutation; errors: any[] };

      // send back Airport details
      return temp.data.deleteAirport;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: update Airport by id using graphql
  */
  async updateAirportById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateAirport,
        variables: { input },
      })) as { data: UpdateAirportMutation; errors: any[] };

      // send back technology details
      return temp.data.updateAirport;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: list all Airport using graphql
  */
  async getAllAirports(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listAirports
  ) {
    try {
      // send grapql api call
      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListAirportsQuery; errors: any[] };

      if (temp.data.listAirports?.items) {
        // send back all Airport details
        return {
          airports: temp.data.listAirports?.items,
          nextToken: temp.data.listAirports?.nextToken,
        };
      }

      return { airports: [], nextToken: null };
    } catch (error) {
      return { airports: [], nextToken: null };
    }
  }

  /*
    Task: recursively list all airports using graphql
  */
  async recursiveFetchListAiports(
    filter: any = {},
    currentAirports: Array<any> = [],
    reqNextToken: string | null = null,
    query_src = queries.listAirports
  ): Promise<any> {
    const { airports, nextToken } = await this.getAllAirports(
      filter,
      reqNextToken,
      query_src
    );

    // merge old and new data
    let allData = [...currentAirports, ...airports];

    if (nextToken) {
      return await this.recursiveFetchListAiports(
        filter,
        allData,
        nextToken,
        query_src
      );
    } else {
      if (allData?.length) {
        return allData;
      }

      return [];
    }
  }
}

export { AirportService };
