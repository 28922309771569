import React, { FC, useState } from "react";
import {
  AirportDetailDrawerDataAddButton,
  AirportDetailDrawerDocumentsSection,
  AirportDetailDrawerDocumentsSectionHeading,
  AirportDetailDrawerDocumentsSectionSegment,
} from "./style";
import AirportDetailsDrawerDocCard from "./AirportDetailsDrawerDocCard";
import { IAirportDetail } from "../MapProvider/types";
import DeliveryToPlaneTab from "./DeliveryToPlaneTab";
import DataItemCollapseExpandSegment from "./DataItemCollapseExpandSegment";
import DeliveryToFacilityTab from "./DeliveryToFacilityTab";
import StorageTab from "./StorageTab";
import { useMapContext } from "../MapProvider/MapProvider";
import { HydrantChoice } from "../../API";
import {
  DeliveryMethodChoiceMapping,
  StorageProductChoiceMapping,
} from "../../constants/map";
import { Box } from "@mui/material";

export enum dataTabIndex {
  DELIVERY_TO_AIRPLANE,
  DELIVERY_TO_FACILITY,
  STORAGE,
}

type IDataTab = {
  airport: IAirportDetail;
  // canUserEditOrgInfo: boolean;
};

const DataTab: FC<IDataTab> = ({
  airport,
}: //  canUserEditOrgInfo
IDataTab) => {
  const { state } = useMapContext();
  const [selectedTab, setSelectedTab] = useState<dataTabIndex>(
    dataTabIndex.DELIVERY_TO_AIRPLANE
  );
  const [isAddingDTA, setIsAddingDTA] = useState<boolean>(false);
  const [isAddingDTF, setIsAddingDTF] = useState<boolean>(false);
  const [isAddingStorage, setIsAddingStorage] = useState<boolean>(false);

  const isDeliveryToAirplaneTabActive =
    selectedTab === dataTabIndex.DELIVERY_TO_AIRPLANE;
  const isDeliveryToFacilityTabActive =
    selectedTab === dataTabIndex.DELIVERY_TO_FACILITY;
  const isStorageTabActive = selectedTab === dataTabIndex.STORAGE;

  const tabSelectedHandler = (index: dataTabIndex) => {
    setSelectedTab(index);
  };

  return (
    <AirportDetailDrawerDocumentsSection>
      <AirportDetailDrawerDocumentsSectionHeading>
        Data
      </AirportDetailDrawerDocumentsSectionHeading>
      <AirportDetailDrawerDocumentsSectionSegment>
        <AirportDetailsDrawerDocCard
          name="Delivery to Airplane"
          index={dataTabIndex.DELIVERY_TO_AIRPLANE}
          selected={isDeliveryToAirplaneTabActive}
          selectHandler={tabSelectedHandler}
        />
        <AirportDetailsDrawerDocCard
          name="Delivery to Facility"
          index={dataTabIndex.DELIVERY_TO_FACILITY}
          selected={isDeliveryToFacilityTabActive}
          selectHandler={tabSelectedHandler}
        />
        <AirportDetailsDrawerDocCard
          name="Storage"
          index={dataTabIndex.STORAGE}
          selected={isStorageTabActive}
          selectHandler={tabSelectedHandler}
        />
      </AirportDetailDrawerDocumentsSectionSegment>
      {isDeliveryToAirplaneTabActive && (
        <>
          <Box>
            <AirportDetailDrawerDataAddButton
              variant="contained"
              onClick={() => setIsAddingDTA(true)}
              disabled={isAddingDTA}
            >
              Add new
            </AirportDetailDrawerDataAddButton>
            {isAddingDTA && (
              <DeliveryToPlaneTab
                airportId={airport.id ?? ""}
                organizationId={state.activeConsortium?.organizationId ?? ""}
                airportCode={airport?.code ?? ""}
                oldCode={airport?.oldCode ?? ""}
                facility={airport?.facility ?? ""}
                // canUserEditOrgInfo={canUserEditOrgInfo}
                isAdding={true}
                setIsAdding={setIsAddingDTA}
              />
            )}
          </Box>
          {!!airport?.deliveryToPlane?.items?.length &&
            airport.deliveryToPlane?.items
              .filter(
                (deliveryToPlane) =>
                  deliveryToPlane?.organizationId ===
                  state.activeConsortium?.organizationId
              )
              .map((deliveryToPlane, dTPIndex) => (
                <DataItemCollapseExpandSegment
                  key={deliveryToPlane?.id}
                  title={
                    deliveryToPlane?.hydrant === HydrantChoice.YES
                      ? "Hydrant"
                      : "Truck"
                  }
                >
                  {!!deliveryToPlane && (
                    <DeliveryToPlaneTab
                      airportId={airport.id ?? ""}
                      organizationId={
                        state.activeConsortium?.organizationId ?? ""
                      }
                      airportCode={airport?.code ?? ""}
                      oldCode={airport?.oldCode ?? ""}
                      facility={airport?.facility ?? ""}
                      deliveryToPlane={deliveryToPlane}
                      // canUserEditOrgInfo={canUserEditOrgInfo}
                    />
                  )}
                </DataItemCollapseExpandSegment>
              ))}
        </>
      )}
      {isDeliveryToFacilityTabActive && (
        <>
          <Box>
            <AirportDetailDrawerDataAddButton
              variant="contained"
              onClick={() => setIsAddingDTF(true)}
              disabled={isAddingDTF}
            >
              Add new
            </AirportDetailDrawerDataAddButton>
            {isAddingDTF && (
              <DeliveryToFacilityTab
                airportId={airport.id ?? ""}
                organizationId={state.activeConsortium?.organizationId ?? ""}
                airportCode={airport?.code ?? ""}
                oldCode={airport?.oldCode ?? ""}
                facility={airport?.facility ?? ""}
                // canUserEditOrgInfo={canUserEditOrgInfo}
                isAdding={true}
                setIsAdding={setIsAddingDTF}
              />
            )}
          </Box>
          {!!airport?.deliveryToFacility?.items?.length &&
            airport.deliveryToFacility?.items
              .filter(
                (deliveryToFacility) =>
                  deliveryToFacility?.organizationId ===
                  state.activeConsortium?.organizationId
              )
              .map((deliveryToFacility, dTFIndex) => (
                <DataItemCollapseExpandSegment
                  key={deliveryToFacility?.id}
                  title={`${
                    deliveryToFacility?.method
                      ? DeliveryMethodChoiceMapping[
                          deliveryToFacility?.method as keyof typeof DeliveryMethodChoiceMapping
                        ]
                      : ""
                  }${
                    deliveryToFacility?.provider
                      ? ` - ${deliveryToFacility?.provider}`
                      : ""
                  }`}
                >
                  {!!deliveryToFacility && (
                    <DeliveryToFacilityTab
                      airportId={airport.id ?? ""}
                      organizationId={
                        state.activeConsortium?.organizationId ?? ""
                      }
                      airportCode={airport?.code ?? ""}
                      oldCode={airport?.oldCode ?? ""}
                      facility={airport?.facility ?? ""}
                      deliveryToFacility={deliveryToFacility}
                      // canUserEditOrgInfo={canUserEditOrgInfo}
                    />
                  )}
                </DataItemCollapseExpandSegment>
              ))}
        </>
      )}
      {isStorageTabActive && (
        <>
          <Box>
            <AirportDetailDrawerDataAddButton
              variant="contained"
              onClick={() => setIsAddingStorage(true)}
              disabled={isAddingStorage}
            >
              Add new
            </AirportDetailDrawerDataAddButton>
            {isAddingStorage && (
              <StorageTab
                airportId={airport.id ?? ""}
                organizationId={state.activeConsortium?.organizationId ?? ""}
                airportCode={airport?.code ?? ""}
                oldCode={airport?.oldCode ?? ""}
                facility={airport?.facility ?? ""}
                // canUserEditOrgInfo={canUserEditOrgInfo}
                isAdding={true}
                setIsAdding={setIsAddingStorage}
              />
            )}
          </Box>
          {!!airport?.storage?.items?.length &&
            airport.storage?.items
              .filter(
                (storage) =>
                  storage?.organizationId ===
                  state.activeConsortium?.organizationId
              )
              .sort((a, b) => a!.tankId.localeCompare(b!.tankId))
              .map((storage) => (
                <DataItemCollapseExpandSegment
                  key={storage?.id}
                  title={`${
                    storage?.product
                      ?.map((product) => StorageProductChoiceMapping[product!])
                      ?.join(", ") ?? ""
                  } ${storage?.tankId}`}
                >
                  {!!storage && (
                    <StorageTab
                      airportId={airport.id ?? ""}
                      organizationId={
                        state.activeConsortium?.organizationId ?? ""
                      }
                      airportCode={airport?.code ?? ""}
                      oldCode={airport?.oldCode ?? ""}
                      facility={airport?.facility ?? ""}
                      storage={storage}
                      // canUserEditOrgInfo={canUserEditOrgInfo}
                    />
                  )}
                </DataItemCollapseExpandSegment>
              ))}
        </>
      )}
    </AirportDetailDrawerDocumentsSection>
  );
};

export default DataTab;
