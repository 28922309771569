import React from "react";
import { helpers } from "../../../utils";
import { AuthSchema } from "../../../validations/schema";
import { TextFieldInput } from "./../TextFieldInput";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import { useMapContext } from "./../../MapProvider/MapProvider";
import { AutoCompleteInput } from "../AutoCompleteInput";
import joi from "joi";

// props types
interface IEmailListInput {
  emailList: Array<any>;
  setEmailList: (prev: any) => void;
  error?: string;
  placeholder?: string;
  selectedCompany?: string;
  defaultShowUserForm?: boolean;
}

const defaultFormState = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  mobile: "",
};

const EmailListInput: React.FC<IEmailListInput> = ({
  emailList,
  setEmailList,
  error = "",
  placeholder = "",
  selectedCompany,
  defaultShowUserForm = true,
}) => {
  const [formData, setFormData] = React.useState<any>({
    ...defaultFormState,
    company: selectedCompany ?? "",
  });
  const [formError, setFormError] = React.useState({
    ...defaultFormState,
  });
  const [showForm, setShowForm] = React.useState<boolean>(defaultShowUserForm);
  const [companyList, setCompanyList] = React.useState<Array<any>>([]);

  const { state: mapContextState } = useMapContext();

  React.useEffect(() => {
    if (mapContextState?.companyList?.length !== 0) {
      // set company
      setCompanyList([...mapContextState?.companyList]);
    } else {
      // reset company
      setCompanyList([]);
    }
  }, [mapContextState?.companyList]);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({
      ...prev,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));

    if (name !== "mobile") {
      // validate form data
      handleValidation(name, value);
    }
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setFormError({ ...defaultFormState });

    let result: any = {};

    result = AuthSchema.emailListValidationSchema[key].validate(value) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setFormError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setFormError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  const handleClearForm = () => {
    // clear form input data
    setFormData({ ...defaultFormState, company: selectedCompany ?? "" });
    // clear form error message
    setFormError({ ...defaultFormState });
    // set show form flag
    setShowForm(false);
  };

  // handle add email in list
  const handleAddEmailInList = (event: any) => {
    event.preventDefault();

    var isValidationError = false;

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      let isValid = true;

      if (key !== "mobile") {
        isValid = handleValidation(key, formData[key]);

        if (!isValidationError) {
          isValidationError = !isValid;
        }
      }
    });

    // if phone number is not empty then validate
    if (formData?.mobile !== "") {
      const validationSchema = joi
        .string()
        .length(10)
        .pattern(/^[0-9]+$/);
      const { value, error } = validationSchema.validate(formData?.mobile);

      setFormError((prev) => ({
        ...prev,
        mobile: error ? "Enter valid mobile no." : "",
      }));
      if (error) {
        isValidationError = true;
      }
    }

    if (!isValidationError) {
      const isEmailExist =
        emailList?.filter((item) => item?.email === formData?.email).length !==
        0
          ? true
          : false;

      if (isEmailExist) {
        // copy old email list
        let newEmailList = emailList;

        const atIndex = emailList?.findIndex(
          (item) => item?.email === formData?.email
        );

        // update value
        newEmailList[atIndex] = formData;

        // update email in list
        setEmailList([...newEmailList]);
      } else {
        // add email to list
        setEmailList((prev: any) => [...prev, formData]);
      }

      // clear form fields
      handleClearForm();
    }
  };

  // remove email from list
  const handleRemoveEmail = (itemId: number) => {
    const temp = emailList?.filter((item: any, idx: number) => idx !== itemId);

    // update email list
    setEmailList([...temp]);
  };

  return (
    <>
      <Grid container>
        <Grid md={12} item>
          {!showForm && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              sx={{ float: "right" }}
              onClick={() => setShowForm(true)}
            >
              <AddIcon />
            </Button>
          )}
          {showForm && (
            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ float: "right", mb: 1, textTransform: "none" }}
              onClick={handleClearForm}
            >
              <DeleteIcon />
            </Button>
          )}
        </Grid>
        {showForm && (
          <>
            <Grid md={6} item>
              <TextFieldInput
                id="firstName"
                name="firstName"
                placeholder="First Name"
                label="First Name"
                error={formError.firstName}
                value={formData.firstName}
                autoComplete="firstName"
                handleChange={handleChange}
                onBlur={() => handleValidation("firstName", formData.firstName)}
                fullWidth
                required
              />
            </Grid>
            <Grid md={6} item>
              <TextFieldInput
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                label="Last Name"
                error={formError.lastName}
                value={formData.lastName}
                autoComplete="lastName"
                handleChange={handleChange}
                onBlur={() => handleValidation("lastName", formData.lastName)}
                fullWidth
                required
              />
            </Grid>
            <Grid md={12} item>
              <AutoCompleteInput
                id="company"
                name="company"
                placeholder="Select Company"
                label="Select Company *"
                options={companyList?.map((item: any) => ({
                  label: item?.name,
                  value: item?.id,
                }))}
                error={formError.company}
                value={formData?.company}
                fullWidth={true}
                handleChange={handleChange}
                onBlur={() => handleValidation("company", formData.company)}
                disabled={!!selectedCompany}
              />
            </Grid>
            <Grid md={12} item>
              <TextFieldInput
                id="mobile"
                name="mobile"
                placeholder="Enter mobile no."
                label="Mobile (Optional)"
                error={formError.mobile}
                value={formData.mobile}
                autoComplete="mobile"
                helperText="10 digit mobile no."
                handleChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid md={12} item>
              <TextFieldInput
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                label="Email"
                error={formError.email}
                value={formData.email}
                helperText="This email will be used for login."
                autoComplete="email"
                handleChange={handleChange}
                onBlur={() => handleValidation("email", formData.email)}
                fullWidth
                required
              />
            </Grid>
            <Grid md={12} item>
              <Button
                variant="outlined"
                size="small"
                sx={{ textTransform: "none", float: "right" }}
                onClick={handleAddEmailInList}
              >
                {emailList?.filter((item) => item?.email === formData?.email)
                  .length !== 0
                  ? "Update"
                  : "Add"}
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <Divider sx={{ mt: 1 }} />
      <Box
        sx={{ display: "flex", flexWrap: "wrap", border: "1px dashed black" }}
      >
        {emailList?.map((item: any, idx: number) => (
          <Chip
            key={`chip-${idx}`}
            label={`${item?.firstName} ${item?.lastName} <${item?.email}>`}
            sx={{ m: 1 }}
            onDelete={() => handleRemoveEmail(idx)}
            onClick={() => {
              setFormData({ ...item });
              setShowForm(true);
            }}
          />
        ))}
        {/* {emailList?.length === 0 && (
          <p style={{ marginLeft: "auto", marginRight: "auto", color: "grey" }}>
            {placeholder}
          </p>
        )} */}
      </Box>

      {error !== "" && <p style={{ color: "red" }}>{error}</p>}
    </>
  );
};

export { EmailListInput };
