import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Box, SxProps, TextField } from "@mui/material";

interface ICustomInputField {
  label: string;
  value: string | number;
  changeHandler: React.ChangeEventHandler<HTMLInputElement>;
  sx?: SxProps;
  disabled?: boolean;
  hasPrefixes?: boolean;
}

const CustomInputField: React.FC<ICustomInputField> = ({
  label,
  value,
  changeHandler,
  sx,
  disabled = false,
}: ICustomInputField) => {
  return (
    <Box sx={sx}>
      <FormControl sx={{ m: 1, width: 175 }}>
        <TextField
          variant="outlined"
          label={label}
          id={`${label}-textfield`}
          value={value}
          onChange={changeHandler}
          disabled={disabled}
        />
      </FormControl>
    </Box>
  );
};

export default CustomInputField;
