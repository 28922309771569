import joi from "joi";

// create airport params
// validation schema
const createAirportValidationSchema: any = {
  name: joi.string().trim().required().messages({
    "string.empty": "Airport name cannot be empty.",
    "any.required": "Airport name required.",
  }),
  code: joi.string().trim().required().messages({
    "string.empty": "Airport code cannot be empty.",
    "any.required": "Airport code required.",
  }),
  state: joi.string().trim().required().messages({
    "string.empty": "Airport state cannot be empty.",
    "any.required": "Airport state required.",
  }),
  region: joi
    .string()
    .trim()
    .required()
    // .valid(
    //   "PADD_1A_NEW_ENGLAND",
    //   "PADD_1B_CENTERAL_ATLANTIC",
    //   "PADD_1C_LOWER_ATLANTIC",
    //   "PADD_2_MIDWEST",
    //   "PADD_3_GULF_COAST",
    //   "PADD_4_ROCKY_MOUNTAIN",
    //   "PADD_5_ALASKA",
    //   "PADD_5_HAWAII",
    //   "PADD_5_WEST_COAST",
    //   "PADD_NA_CARIBBEAN"
    // )
    .messages({
      "string.empty": "Airport region cannot be empty.",
      "any.required": "Airport region required.",
      // "any.only": "Invalid airport region.",
    }),
  lat: joi.number().required().messages({
    "any.required": "Airport latitude required.",
    "number.base": "Airport Lat. must be a number.",
  }),
  lon: joi.number().required().messages({
    "any.required": "Airport longitude required.",
    "number.base": "Airport Lon. must be a number.",
  }),
};

// update airport params
// validation schema
const updateAirportValidationSchema: any = {
  name: joi.string().trim().required().messages({
    "string.empty": "Airport name cannot be empty.",
    "any.required": "Airport name required.",
  }),
  code: joi.string().trim().required().messages({
    "string.empty": "Airport code cannot be empty.",
    "any.required": "Airport code required.",
  }),
  state: joi.string().trim().required().messages({
    "string.empty": "Airport state cannot be empty.",
    "any.required": "Airport state required.",
  }),
  region: joi
    .string()
    .trim()
    .required()
    // .valid(
    //   "PADD_1A_NEW_ENGLAND",
    //   "PADD_1B_CENTERAL_ATLANTIC",
    //   "PADD_1C_LOWER_ATLANTIC",
    //   "PADD_2_MIDWEST",
    //   "PADD_3_GULF_COAST",
    //   "PADD_4_ROCKY_MOUNTAIN",
    //   "PADD_5_ALASKA",
    //   "PADD_5_HAWAII",
    //   "PADD_5_WEST_COAST",
    //   "PADD_NA_CARIBBEAN"
    // )
    .messages({
      "string.empty": "Airport region cannot be empty.",
      "any.required": "Airport region required.",
      // "any.only": "Invalid airport region.",
    }),
  lat: joi.number().required().messages({
    "any.required": "Latitude required.",
    "number.base": "Lat. must be a number.",
  }),
  lon: joi.number().required().messages({
    "any.required": "Longitude required.",
    "number.base": "Lon. must be a number.",
  }),
};

export { createAirportValidationSchema, updateAirportValidationSchema };
