import React from "react";
import { OrganizationTypeChoice } from "../../API";
import { IUserOrganizationRole } from "../../services/MapService/MapService";
import { IAirport } from "../MapProvider/types";
import { Box } from "@mui/material";

const AirportPopup = ({
  airport,
  userRoles,
  isUserA4AAdmin,
}: {
  airport: IAirport;
  userRoles: IUserOrganizationRole[];
  isUserA4AAdmin: boolean;
}) => {
  let isSeeMoreEnabled = false;

  if (isUserA4AAdmin) {
    isSeeMoreEnabled = true;
  }
  if (
    // airport.consortiumGroup?.items?.find(
    //   (item) =>
    //     item.organization.organizationType ===
    //     OrganizationTypeChoice.ENERGY_COUNCIL
    // ) &&
    userRoles.find(
      (item) => item.organizationType === OrganizationTypeChoice.ENERGY_COUNCIL
    )
  ) {
    isSeeMoreEnabled = true;
  }
  if (
    airport.consortiumGroup?.items?.find(
      (item) =>
        item.organization.organizationType ===
          OrganizationTypeChoice.FUEL_CONSORTIUM &&
        userRoles.find(
          (userRole) => userRole.organizationId === item.organizationId
        )
    )
  ) {
    isSeeMoreEnabled = true;
  }

  return (
    <>
      {!!airport.code && (
        <Box className="popup-code-heading">{airport.code}</Box>
      )}
      <Box className="popup-heading">{airport.name}</Box>
      {(airport.consortiumGroup?.items?.length ?? 0) > 1 ? (
        airport.consortiumGroup?.items?.map((consortium, conIndex) => (
          <div key={`${consortium?.organization?.name}-${conIndex}`}>
            <Box className="popup-consortium-heading">
              <span>{consortium.organization.name}</span>
            </Box>
            <Box className="popup-multi-operator-wrapper">
              <Box className="popup-operator">
                <Box className="popup-operator-heading">Chair</Box>
                <Box>
                  {!!consortium.chairContact && (
                    <p
                      className={`popup-chair-contact popup-chair-contact-${conIndex}`}
                    >
                      {consortium.chairContact?.firstName ?? ""}{" "}
                      {consortium.chairContact?.lastName ?? ""}{" "}
                    </p>
                  )}
                  {!!consortium.chair && <h3>{consortium.chair?.name}</h3>}
                </Box>
              </Box>
              <Box className="popup-operator">
                <Box className="popup-operator-heading">GM</Box>
                <Box>
                  {!!consortium.operatorGeneralManager && (
                    <p
                      className={`popup-gm-contact popup-gm-contact-${conIndex}`}
                    >
                      {consortium.operatorGeneralManager?.firstName ?? ""}{" "}
                      {consortium.operatorGeneralManager?.lastName ?? ""}{" "}
                    </p>
                  )}
                  {!!consortium.operator && (
                    <h3>{consortium.operator?.name}</h3>
                  )}
                </Box>
              </Box>
            </Box>
          </div>
        ))
      ) : (
        <>
          <Box className="popup-operator">
            <Box className="popup-operator-heading">Chair</Box>
            <Box>
              {!!airport?.consortiumGroup?.items?.[0]?.chairContact && (
                <p className="popup-chair-contact popup-chair-contact-0">
                  {airport?.consortiumGroup?.items?.[0]?.chairContact
                    ?.firstName ?? ""}{" "}
                  {airport?.consortiumGroup?.items?.[0]?.chairContact
                    ?.lastName ?? ""}{" "}
                </p>
              )}
              {!!airport?.consortiumGroup?.items?.[0]?.chair && (
                <h3>{airport?.consortiumGroup?.items?.[0]?.chair?.name}</h3>
              )}
            </Box>
          </Box>
          <Box className="popup-operator">
            <Box className="popup-operator-heading">GM</Box>
            <Box>
              {!!airport?.consortiumGroup?.items?.[0]
                ?.operatorGeneralManager && (
                <p className="popup-gm-contact popup-gm-contact-0">
                  {airport?.consortiumGroup?.items?.[0]?.operatorGeneralManager
                    ?.firstName ?? ""}{" "}
                  {airport?.consortiumGroup?.items?.[0]?.operatorGeneralManager
                    ?.lastName ?? ""}{" "}
                </p>
              )}
              {!!airport?.consortiumGroup?.items?.[0]?.operator && (
                <h3>{airport?.consortiumGroup?.items?.[0]?.operator?.name}</h3>
              )}
            </Box>
          </Box>
        </>
      )}
      {isSeeMoreEnabled && <Box className="popup-viewmore-btn">More Info</Box>}
    </>
  );
};

export default AirportPopup;
