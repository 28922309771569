import React, { useState } from "react";
import {
  EventDetailSidebarContainer,
  EventDetailSidebarHeading,
  EventTypeAddButton,
  EventTypeInputBox,
} from "./style";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import SelectDropdown from "../SelectDropdown";
import {
  calendarContextAction,
  useCalendarContext,
} from "../CalendarProvider/CalendarProvider";
import { useDataContext } from "../DataProvider/DataProvider";
import CalendarService from "../../services/CalendarService";
import { Sidebar } from "../Common/Sidebar";
import AddEventTypeSidebar from "./AddEventTypeSidebar";
import { useMapContext } from "../MapProvider/MapProvider";

interface IEventForm {
  name: string;
  startDateTime: string;
  endDateTime: string;
  description: string;
  calendarEventTypeId: string;
  // organizationId: string;
  website: string;
  organizer: string;
  specialRates: boolean;
  location: string;
  venue: string;
  venueWebSite: string;
  groupRateCode: string;
}

const initialEventForm = {
  name: "",
  startDateTime: new Date().toISOString().slice(0, 16),
  endDateTime: new Date().toISOString().slice(0, 16),
  description: "",
  calendarEventTypeId: "",
  // organizationId: "",
  website: "",
  organizer: "",
  specialRates: false,
  location: "",
  venue: "",
  venueWebSite: "",
  groupRateCode: "",
};

type IAddEventSidebar = {
  isSuperUser: boolean;
  closeSidebar: Function;
};

const AddEventSidebar = ({ isSuperUser, closeSidebar }: IAddEventSidebar) => {
  const { state, dispatch } = useCalendarContext();
  const { state: mapState } = useMapContext();
  // const { state: dataState } = useDataContext();
  const [formData, setFormData] = useState<IEventForm>(initialEventForm);
  const [formDataError, setFormDataError] = useState<Partial<IEventForm>>({});
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: AlertColor;
  }>({
    message: "",
    severity: "success",
  });
  const [openAddEventTypeSidebar, setOpenAddEventTypeSidebar] =
    useState<boolean>(false);

  const formValidator = (): boolean => {
    const expression =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const websiteRegex = new RegExp(expression);

    let formHasNoError = true;
    if (!formData.name.trim()) {
      setFormDataError((prevFormDataError) => ({
        ...prevFormDataError,
        name: "Name is required.",
      }));
      formHasNoError = false;
    }
    if (!formData.startDateTime.trim()) {
      setFormDataError((prevFormDataError) => ({
        ...prevFormDataError,
        startDateTime: "Start date time is required.",
      }));
      formHasNoError = false;
    }
    if (!formData.endDateTime.trim()) {
      setFormDataError((prevFormDataError) => ({
        ...prevFormDataError,
        endDateTime: "End date time is required.",
      }));
      formHasNoError = false;
    }
    if (formData.endDateTime < formData.startDateTime) {
      setFormDataError((prevFormDataError) => ({
        ...prevFormDataError,
        endDateTime: "End date time can not be before start date",
      }));
      formHasNoError = false;
    }
    if (!formData.calendarEventTypeId.trim()) {
      setFormDataError((prevFormDataError) => ({
        ...prevFormDataError,
        calendarEventTypeId: "Type is required.",
      }));
      formHasNoError = false;
    }
    if (
      formData.website &&
      formData.website.toLowerCase() !== "none" &&
      !formData.website.match(websiteRegex)
    ) {
      setFormDataError((prevFormDataError) => ({
        ...prevFormDataError,
        website: "Please enter valid website URL.",
      }));
      formHasNoError = false;
    }
    if (formData.venueWebSite && 
      formData.venueWebSite.toLowerCase() !== "none" &&
      !formData.venueWebSite.match(websiteRegex)) {
      setFormDataError((prevFormDataError) => ({
        ...prevFormDataError,
        venueWebSite: "Please enter valid website URL.",
      }));
      formHasNoError = false;
    }
    return formHasNoError;
  };

  const addEventHandler = async () => {
    setFormDataError({});
    setIsAdding(true);
    try {
      if (!formValidator()) {
        return;
      }
      const response = await CalendarService.createCalendarEvent({
        ...formData,
        startDateTime: new Date(formData?.startDateTime ?? "").toISOString(),
        endDateTime: new Date(formData?.endDateTime ?? "").toISOString(),
        createdByUserId: mapState?.currentUserDbData?.id ?? "",
        modifiedByUserId: mapState?.currentUserDbData?.id ?? "",
      });
      if (typeof response !== "string") {
        setFormData(initialEventForm);
        dispatch({
          type: calendarContextAction.SET_CALENDAR_LIST,
          payload: [...state.calendarList, response],
        });
        setAlertMessage({
          message: "Added event successfully",
          severity: "success",
        });
        closeSidebar();
      } else {
        setAlertMessage({
          message: response ?? "An error occured while adding event",
          severity: "error",
        });
      }
    } catch (e) {
      console.error(e);
      setAlertMessage({
        message: "An error occured while adding event",
        severity: "error",
      });
    } finally {
      setIsAdding(false);
    }
  };

  const eventTypeAddTrigger = (id: string) =>
    setFormData((prevFormData) => ({
      ...prevFormData,
      calendarEventTypeId: id,
    }));

  return (
    <>
      <EventDetailSidebarContainer>
        <EventDetailSidebarHeading>Add new event</EventDetailSidebarHeading>
        <TextField
          label="Name"
          placeholder="Enter name"
          variant="outlined"
          value={formData.name}
          onChange={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              name: e.target.value,
            }))
          }
          error={!!formDataError.name}
          helperText={formDataError.name}
          fullWidth
          sx={{
            mt: "15px",
          }}
          required
        />
        <Box display="flex" columnGap={1}>
          <TextField
            label="Start date time"
            placeholder="Enter start date time"
            variant="outlined"
            value={formData.startDateTime}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                startDateTime: e.target.value,
              }))
            }
            error={!!formDataError.startDateTime}
            helperText={formDataError.startDateTime}
            fullWidth
            type="datetime-local"
            sx={{
              mt: "10px",
            }}
            required
          />
          <TextField
            label="End date time"
            placeholder="Enter end date time"
            variant="outlined"
            value={formData.endDateTime}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                endDateTime: e.target.value,
              }))
            }
            error={!!formDataError.endDateTime}
            helperText={formDataError.endDateTime}
            fullWidth
            type="datetime-local"
            sx={{
              mt: "10px",
            }}
            required
          />
        </Box>
        <TextField
          rows={3}
          multiline
          label="Description"
          placeholder="Enter description"
          variant="outlined"
          value={formData.description}
          onChange={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              description: e.target.value,
            }))
          }
          error={!!formDataError.description}
          helperText={formDataError.description}
          fullWidth
          type="text"
          sx={{
            mt: "10px",
          }}
        />
        <EventTypeInputBox>
          <SelectDropdown
            label="Type"
            selectHandler={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                calendarEventTypeId: e.target.value,
              }))
            }
            selectedValue={formData.calendarEventTypeId}
            options={state.calendarTypeList
              .map((type) => ({
                name: type.name,
                value: type.id,
              }))
              .sort((a, b) => a.name.localeCompare(b.name))}
            sx={{
              flex: 1,
              mt: "10px",
            }}
            required
            error={!!formDataError.calendarEventTypeId}
            helperText={formDataError.calendarEventTypeId}
          />
          {isSuperUser && (
            <EventTypeAddButton
              onClick={() => setOpenAddEventTypeSidebar(true)}
            />
          )}
        </EventTypeInputBox>
        {/* <SelectDropdown
          label="Organization"
          selectHandler={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              organizationId: e.target.value,
            }))
          }
          selectedValue={formData.organizationId}
          options={dataState.organizationList.map((organization) => ({
            name: organization.name,
            value: organization.id,
          }))}
          sx={{
            flex: 1,
            mt: "10px",
          }}
        /> */}
        <TextField
          label="Website"
          placeholder="Enter website"
          variant="outlined"
          value={formData.website}
          onChange={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              website: e.target.value,
            }))
          }
          error={!!formDataError.website}
          helperText={formDataError.website}
          fullWidth
          sx={{
            mt: "10px",
          }}
        />
        <TextField
          label="Organizer"
          placeholder="Enter organizer"
          variant="outlined"
          value={formData.organizer}
          onChange={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              organizer: e.target.value,
            }))
          }
          error={!!formDataError.organizer}
          helperText={formDataError.organizer}
          fullWidth
          sx={{
            mt: "10px",
          }}
        />
        <TextField
          label="Location"
          placeholder="Enter location"
          variant="outlined"
          value={formData.location}
          onChange={(e) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              location: e.target.value,
            }))
          }
          error={!!formDataError.location}
          helperText={formDataError.location}
          fullWidth
          sx={{
            mt: "10px",
          }}
        />
        <Box display="flex" columnGap={1}>
          <TextField
            label="Venue"
            placeholder="Enter venue"
            variant="outlined"
            value={formData.venue}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                venue: e.target.value,
              }))
            }
            error={!!formDataError.venue}
            helperText={formDataError.venue}
            fullWidth
            sx={{
              mt: "10px",
            }}
          />
          <TextField
            label="Venue Website"
            placeholder="Enter venue website"
            variant="outlined"
            value={formData.venueWebSite}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                venueWebSite: e.target.value,
              }))
            }
            error={!!formDataError.venueWebSite}
            helperText={formDataError.venueWebSite}
            fullWidth
            sx={{
              mt: "10px",
            }}
          />
        </Box>
        <Box display="flex" columnGap={1}>
          <FormControlLabel
            control={
              <Checkbox
                value={formData.specialRates}
                onChange={(e) =>
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    specialRates: e.target.checked,
                  }))
                }
              />
            }
            label="Special rates"
            sx={{
              mt: "10px",
              whiteSpace: "nowrap",
            }}
          />
          <TextField
            label="Group rate code"
            placeholder="Enter group rate code"
            variant="outlined"
            value={formData.groupRateCode}
            onChange={(e) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                groupRateCode: e.target.value,
              }))
            }
            error={!!formDataError.groupRateCode}
            helperText={formDataError.groupRateCode}
            fullWidth
            sx={{
              mt: "10px",
            }}
          />
        </Box>
        <Button
          type="button"
          size="medium"
          variant="contained"
          onClick={addEventHandler}
          disabled={isAdding}
          sx={{ mt: "20px", float: "right" }}
        >
          {isAdding ? "Adding Event..." : "Add event"}
        </Button>
        {!!alertMessage.message && (
          <Alert severity={alertMessage.severity} sx={{ mt: 10 }}>
            {alertMessage.message}
          </Alert>
        )}
      </EventDetailSidebarContainer>
      <Sidebar
        handleClose={() => setOpenAddEventTypeSidebar(false)}
        open={openAddEventTypeSidebar}
      >
        <AddEventTypeSidebar
          closeSidebar={() => setOpenAddEventTypeSidebar(false)}
          eventTypeAddTrigger={eventTypeAddTrigger}
        />
      </Sidebar>
    </>
  );
};

export default AddEventSidebar;
