import React from "react";
import { Box, InputBase, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
// import { S3Service } from "../../services/S3Service";
// import { constants } from "./../../utils";
import { useLocation, useNavigate } from "react-router-dom";

const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "12.5px",
  backgroundColor: "#eee",
  width: "22.5rem",
  marginLeft: "100px",
}));

const SearchIconWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

interface ITopSidebar {
  handleOpenUserProfile: () => void;
  handleSignout: () => void;
}

const TopSidebar = ({ handleOpenUserProfile, handleSignout }: ITopSidebar) => {
  const [profileMenuItemAnchorEl, setProfileMenuItemAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openProfileMenuItem = Boolean(profileMenuItemAnchorEl);
  let navigate = useNavigate();

  const { dispatch: dataProviderDispatch, state: dataProviderState } =
    useDataContext();

  const location = useLocation();

  // hide global search bar on maps page
  const hideGlobalSearchBar =
    location?.pathname?.includes("/main") ||
    location?.pathname?.includes("/volumes") ||
    location?.pathname?.includes("/map/list");

  // event: React.MouseEvent<HTMLButtonElement>
  const handleProfileMenuItemClick = (event: any) => {
    setProfileMenuItemAnchorEl(event.currentTarget);
  };

  const handleProfileMenuItemClose = () => {
    setProfileMenuItemAnchorEl(null);
  };

  const helpFileViewHandler = async () => {
    //`${constants?.DocumentBucketTypeChoice?.HELP}/Airline Fuel Portal Roles and Responsibilities.pdf`
    // try {
    //   const signedUrl = await new S3Service().getSignedUrl(
    //     `${constants?.DocumentBucketTypeChoice?.HELP}/Airline Fuel Portal Roles and Responsibilities_2024.1.pdf`
    //   );
    //   window.open(signedUrl, "_blank");
    // } catch (error) {
    //   console.log(error);
    // }

    // find portal user document category id
    const categoryArr = dataProviderState?.documentCategoryList?.filter(
      (category) => category?.name === "Portal User Documents"
    );
    const isCategoryFound = categoryArr.length !== 0;

    if (isCategoryFound) {
      // Redirect to general / global PORTAL_USER_DOCUMENTS folder
      navigate(`/doc/category/${categoryArr[0]["id"]}`);
    } else {
      // redirect to general / global document page
      navigate("/doc");
    }
  };

  return (
    <Box
      sx={{
        height: "100px",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <img
          width="190px"
          src={`${process.env.REACT_APP_BASE_URL}/assets/img/a4a_header_logo.png`}
          alt="A4A Header Logo"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/main");
          }}
        />
      </Box>
      {!hideGlobalSearchBar && (
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={dataProviderState?.topBarSearchPlaceholderText}
            inputProps={{ "aria-label": "search" }}
            value={dataProviderState?.topBarSearchText}
            onChange={(event: any) =>
              dataProviderDispatch({
                type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
                payload: event?.target?.value,
              })
            }
          />
        </Search>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "18px",
          marginLeft: "auto",
        }}
      >
        <InfoOutlinedIcon
          sx={{ fontSize: "28px", cursor: "pointer" }}
          onClick={helpFileViewHandler}
        />
        <AccountCircleOutlinedIcon
          id="profile-dropdown-menu-button"
          sx={{ fontSize: "28px", cursor: "pointer" }}
          onClick={handleProfileMenuItemClick}
        />
      </Box>
      <Menu
        id="profile-dropdown-menu"
        anchorEl={profileMenuItemAnchorEl}
        open={openProfileMenuItem}
        onClose={handleProfileMenuItemClose}
        MenuListProps={{
          "aria-labelledby": "profile-dropdown-menu-button",
        }}
      >
        <MenuItem
          onClick={() => {
            // open user profile sidebar
            handleOpenUserProfile();
            // close menu item
            handleProfileMenuItemClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={async () => {
            // signout user
            await handleSignout();
            // close menu item
            handleProfileMenuItemClose();
          }}
        >
          Sign-out
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TopSidebar;
