import React, { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MUILink from "@mui/material/Link";
import Container from "@mui/material/Container";
// import { SimpleMap } from "../SimpleMap";
import MapBoxMap from "../MapBoxMap";
import { A4ALoader } from "../Common/A4ALoader";
import { constants } from "../../utils";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { ViewNotification } from "../ViewNotification";
import { Sidebar as CommonSidebar } from "../Common/Sidebar";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MapIcon from "@mui/icons-material/Map";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DescriptionIcon from "@mui/icons-material/Description";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

import { Auth } from "aws-amplify";

import { useScreenSize } from "../../hooks";
import { useAuth } from "./../AuthProvider";
import { UserProfile } from "./../UserProfile";

import "./style.css";
import TopSidebar from "./TopSidebar";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useMapContext } from "./../MapProvider/MapProvider";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}{" "}
      <MUILink color="inherit" href="https://www.airlinefuelportal.com/">
        Airline Fuel Portal (AFP)
      </MUILink>
      {". "}
      All rights reserved.
    </Typography>
  );
}

interface IDashboard {
  children?: React.ReactNode;
  isVolumesMap?: boolean;
  isListView?: boolean;
}

const Dashboard: FC<IDashboard> = ({
  children,
  isVolumesMap = false,
  isListView = false,
}) => {
  const [openViewNotification, setOpenViewNotification] =
    React.useState<boolean>(false);
  const [openUserProfile, setOpenUserProfile] = React.useState<boolean>(false);

  const [apiLoader, setApiLoader] = React.useState<boolean>(false);
  const [sidebarCollapse, setSidebarCollapse] = React.useState<boolean>(true);

  const { width: screenWidth } = useScreenSize();
  const { state: authProviderState } = useAuth();
  const { state: mapProviderState } = useMapContext();

  // Show Org / Consortium page flag
  const showConsortiumLink =
    authProviderState?.user?.groups?.includes(constants?.ROLES?.A4A_ADMIN) ||
    authProviderState?.user?.orgRole?.admin?.length !== 0 ||
    authProviderState?.user?.orgRole?.supportAdmin?.length !== 0;

  // check logged in user is A4A Admin
  const isA4AAdmin = authProviderState?.user?.groups?.includes(
    constants?.ROLES?.A4A_ADMIN
  );

  // navigate from page to page
  const navigate = useNavigate();
  // url search params
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.DASHBOARD;

    // open user profile sidebar
    if (
      searchParams.get("show") !== null &&
      searchParams.get("show") === "profile"
    ) {
      setOpenUserProfile(true);
    }

    // cleanup
    return () => {};
  }, []);

  // collapse nav sidebar based on screen width
  React.useEffect(() => {
    if (screenWidth < 800) {
      setSidebarCollapse(true);
    }
  }, [screenWidth]);

  React.useEffect(() => {
    // change page title
    if (openViewNotification) {
      document.title = constants.PAGE_TITLE.VIEW_NOTIFICATION;
    } else {
      document.title = constants.PAGE_TITLE.HOME;
    }
  }, [openViewNotification]);

  const showApiLoader = (): void => {
    setApiLoader(true);
  };

  const hideApiLoader = (): void => {
    setApiLoader(false);
  };

  const handleSignout = async () => {
    await Auth.signOut();
    navigate("/");
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <TopSidebar
          handleOpenUserProfile={() => setOpenUserProfile((prev) => !prev)}
          handleSignout={handleSignout}
        />
        <Box sx={{ display: "flex", height: "calc(100vh - 100px)" }}>
          <Sidebar
            className="app"
            collapsed={sidebarCollapse}
            collapsedWidth="110px"
            rootStyles={
              sidebarCollapse
                ? {
                    "& .ps-menu-button": {
                      flexDirection: "column",
                      padding: "10px 0px",
                      height: "75px !important",
                      "& .ps-menu-icon": {
                        marginRight: "0px",
                      },
                    },
                  }
                : {}
            }
            // backgroundColor="rgb(0, 136, 188)"
            backgroundColor="rgb(96, 96, 96, 0.05)"
          >
            <Menu>
              <MenuItem
                className="menu1"
                onClick={() => setSidebarCollapse((prev) => !prev)}
                icon={<MenuRoundedIcon />}
                rootStyles={{
                  color: "black",
                  "&:hover": {
                    color: "black",
                  },
                  "& .ps-menu-button": sidebarCollapse
                    ? {
                        flexDirection: "column",
                        justifyContent: "center",
                        rowGap: "initial",
                        padding: "initial",
                        height: "50px !important",
                        "& .ps-menu-icon": {
                          marginRight: "0px",
                        },
                        "& .ps-menu-label": {
                          display: "none",
                        },
                      }
                    : "",
                }}
              >
                {/* <img
                  src="assets/img/afp-text-logo.png"
                  width="150"
                  alt="AFP logo"
                  style={{ marginTop: 15 }}
                /> */}
              </MenuItem>
              <SubMenu
                label="Maps"
                icon={<MapIcon />}
                rootStyles={{
                  color: "black",
                  "& .ps-menu-button:hover": {
                    color: "black",
                  },
                }}
              >
                <MenuItem
                  component={<Link to="/main" className="link" />}
                  rootStyles={{
                    color: "black",
                    // "&:hover": {
                    //   color: "black",
                    // },
                    "& .ps-menu-button": sidebarCollapse
                      ? {
                          height: "50px !important",
                        }
                      : {},
                    // backgroundColor: "rgb(0, 136, 188)",
                    backgroundColor: "rgb(96, 96, 96, 0.05)",
                  }}
                >
                  Airports Map
                </MenuItem>
                <MenuItem
                  component={<Link to="/volumes" className="link" />}
                  rootStyles={{
                    color: "black",
                    // "&:hover": {
                    //   color: "black",
                    // },
                    "& .ps-menu-button": sidebarCollapse
                      ? {
                          height: "50px !important",
                        }
                      : {},
                    // backgroundColor: "rgb(0, 136, 188)",
                    backgroundColor: "rgb(96, 96, 96, 0.05)",
                  }}
                >
                  Volumes Map
                </MenuItem>
              </SubMenu>
              <MenuItem
                component={<Link to="/doc" className="link" />}
                icon={<DescriptionIcon />}
                rootStyles={{
                  color: "black",
                  // "&:hover": {
                  //   color: "black",
                  // },
                }}
              >
                Documents
              </MenuItem>
              <MenuItem
                component={<Link to="/calendar" className="link" />}
                icon={<CalendarMonthIcon />}
                rootStyles={{
                  color: "black",
                  // "&:hover": {
                  //   color: "black",
                  // },
                }}
              >
                Calendar
              </MenuItem>
              {showConsortiumLink && (
                <MenuItem
                  component={<Link to="/org" className="link" />}
                  icon={<CorporateFareIcon />}
                  rootStyles={{
                    color: "black",
                    // "&:hover": {
                    //   color: "black",
                    // },
                  }}
                >
                  User Access
                </MenuItem>
              )}
              <MenuItem
                component={<Link to="/company" className="link" />}
                icon={<ApartmentIcon />}
                rootStyles={{
                  color: "black",
                  // "&:hover": {
                  //   color: "black",
                  // },
                }}
              >
                Contacts
              </MenuItem>
              {isA4AAdmin && (
                <SubMenu
                  label="Admin"
                  icon={<AdminPanelSettingsIcon />}
                  rootStyles={{
                    color: "black",
                    "& .ps-menu-button:hover": {
                      color: "black",
                    },
                  }}
                >
                  <MenuItem
                    component={<Link to="/airport" className="link" />}
                    // icon={<FlightTakeoffIcon />}
                    rootStyles={{
                      color: "black",
                      // "&:hover": {
                      //   color: "black",
                      // },
                      "& .ps-menu-button": sidebarCollapse
                        ? {
                            height: "50px !important",
                          }
                        : {},
                      // backgroundColor: "rgb(0, 136, 188)",
                      backgroundColor: "rgb(96, 96, 96, 0.05)",
                    }}
                  >
                    Airport
                  </MenuItem>
                  {/* <MenuItem
                    component={
                      <Link to="/document-categories" className="link" />
                    }
                    rootStyles={{
                      color: "black",
                      // "&:hover": {
                      //   color: "black",
                      // },
                      "& .ps-menu-button": sidebarCollapse
                        ? {
                            height: "50px !important",
                          }
                        : {},
                      // backgroundColor: "rgb(0, 136, 188)",
                      backgroundColor: "rgb(96, 96, 96, 0.05)",
                    }}
                  >
                    Doc. Category
                  </MenuItem> */}
                  <MenuItem
                    component={<Link to="/data-export" className="link" />}
                    rootStyles={{
                      color: "black",
                      // "&:hover": {
                      //   color: "black",
                      // },
                      "& .ps-menu-button": sidebarCollapse
                        ? {
                            height: "50px !important",
                          }
                        : {},
                      // backgroundColor: "rgb(0, 136, 188)",
                      backgroundColor: "rgb(96, 96, 96, 0.05)",
                    }}
                  >
                    Data Export
                  </MenuItem>
                </SubMenu>
              )}
            </Menu>
          </Sidebar>
          {!apiLoader && !children ? (
            <Container
              sx={{
                mx: 0,
                pt: 2,
                maxWidth: "unset !important",
                padding: "0 !important",
                background: "rgb(96, 96, 96, 0.05)",
              }}
            >
              {mapProviderState?.isMapRolesLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "75vh",
                  }}
                >
                  <A4ALoader />
                </Box>
              ) : (
                <MapBoxMap
                  showApiLoader={showApiLoader}
                  hideApiLoader={hideApiLoader}
                  isVolumesMap={isVolumesMap}
                  isListView={isListView}
                  sidebarCollapse={sidebarCollapse}
                />
              )}
            </Container>
          ) : (
            <Container
              sx={{
                m: 0,
                overflowY: "auto",
                maxWidth: "unset !important",
                // padding: "0 !important",
                background: "rgb(96, 96, 96, 0.05)",
              }}
            >
              {apiLoader ? <A4ALoader /> : ""}
              {/* {!apiLoader && !children ? <SimpleMap /> : ""} */}
              {!apiLoader && children ? children : ""}
              <Copyright sx={{ pt: 4 }} />
            </Container>
          )}
        </Box>
      </Box>
      {openViewNotification && (
        <CommonSidebar
          handleClose={() => setOpenViewNotification(false)}
          open={openViewNotification}
        >
          <ViewNotification />
        </CommonSidebar>
      )}
      {openUserProfile && (
        <CommonSidebar
          handleClose={() => setOpenUserProfile(false)}
          open={openUserProfile}
        >
          <UserProfile selectedTab={searchParams.get("tab")} />
        </CommonSidebar>
      )}
    </>
  );
};

export { Dashboard };
