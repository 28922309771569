import React, { useState } from "react";
import {
  EventDetailSidebarContainer,
  EventDetailSidebarHeading,
} from "./style";
import { Alert, AlertColor, Button, TextField } from "@mui/material";
import {
  calendarContextAction,
  useCalendarContext,
} from "../CalendarProvider/CalendarProvider";
import CalendarService from "../../services/CalendarService";

interface IEventTypeForm {
  name: string;
  description: string;
}

const initialEventTypeForm = {
  name: "",
  description: "",
};

type IAddEventTypeSidebar = {
  closeSidebar: Function;
  eventTypeAddTrigger: (id: string) => void;
};

const AddEventTypeSidebar = ({
  closeSidebar,
  eventTypeAddTrigger,
}: IAddEventTypeSidebar) => {
  const { state, dispatch } = useCalendarContext();
  const [formData, setFormData] =
    useState<IEventTypeForm>(initialEventTypeForm);
  const [formDataError, setFormDataError] = useState<Partial<IEventTypeForm>>(
    {}
  );
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<{
    message: string;
    severity: AlertColor;
  }>({
    message: "",
    severity: "success",
  });

  const formValidator = (): boolean => {
    let formHasNoError = true;
    if (!formData.name.trim()) {
      setFormDataError((prevFormDataError) => ({
        ...prevFormDataError,
        name: "Name is required.",
      }));
      formHasNoError = false;
    }
    return formHasNoError;
  };

  const addEventTypeHandler = async () => {
    setFormDataError({});
    setIsAdding(true);
    try {
      if (!formValidator()) {
        return;
      }
      const response = await CalendarService.createCalendarEventType({
        ...formData,
      });
      if (typeof response !== "string") {
        setFormData(initialEventTypeForm);
        dispatch({
          type: calendarContextAction.SET_CALENDAR_TYPE_LIST,
          payload: [...state.calendarTypeList, response],
        });
        setAlertMessage({
          message: "Added event type successfully",
          severity: "success",
        });
        eventTypeAddTrigger(response.id ?? '');
        closeSidebar();
      } else {
        setAlertMessage({
          message: response ?? "An error occured while adding event type",
          severity: "error",
        });
      }
    } catch (e) {
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <EventDetailSidebarContainer>
      <EventDetailSidebarHeading>Add new event type</EventDetailSidebarHeading>
      <TextField
        label="Name"
        placeholder="Enter name"
        variant="outlined"
        value={formData.name}
        onChange={(e) =>
          setFormData((prevFormData) => ({
            ...prevFormData,
            name: e.target.value,
          }))
        }
        error={!!formDataError.name}
        helperText={formDataError.name}
        fullWidth
        sx={{
          mt: "15px",
        }}
        required
      />
      <TextField
        rows={4}
        multiline
        label="Description"
        placeholder="Enter description"
        variant="outlined"
        value={formData.description}
        onChange={(e) =>
          setFormData((prevFormData) => ({
            ...prevFormData,
            description: e.target.value,
          }))
        }
        error={!!formDataError.description}
        helperText={formDataError.description}
        fullWidth
        type="text"
        sx={{
          mt: "10px",
        }}
      />

      <Button
        type="button"
        size="medium"
        variant="contained"
        onClick={addEventTypeHandler}
        disabled={isAdding}
        sx={{ mt: "20px", float: "right" }}
      >
        {isAdding ? "Adding type..." : "Add type"}
      </Button>
      {!!alertMessage.message && (
        <Alert severity={alertMessage.severity} sx={{ mt: 10 }}>
          {alertMessage.message}
        </Alert>
      )}
    </EventDetailSidebarContainer>
  );
};

export default AddEventTypeSidebar;
