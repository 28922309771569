import React, { FC } from "react";
import { Container, CircularProgress } from "@mui/material";

// Component props type
interface A4ALoaderProps {
  img?: boolean;
}

const A4ALoader: FC<A4ALoaderProps> = ({ img = false }) => {
  return (
    <Container sx={{ textAlign: "center" }}>
      {img && (
        <img
          src={`${process.env.REACT_APP_BASE_URL}/assets/img/afp-img-loader.gif`}
          alt="A4A Loader"
          style={{ width: "25%", height: "25%" }}
        />
      )}

      {!img && <CircularProgress />}
    </Container>
  );
};

export { A4ALoader };
