import React, { FC } from "react";
import { AirportDetailDrawerTabCustomPanel } from "./style";

interface ICustomTabPanel {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel: FC<ICustomTabPanel> = ({
  children,
  value,
  index,
  ...other
}: ICustomTabPanel) => {
  return (
    <AirportDetailDrawerTabCustomPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </AirportDetailDrawerTabCustomPanel>
  );
};

export default CustomTabPanel;
