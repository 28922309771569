import React, { useEffect, useRef, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  CardContent,
  Typography,
  Button,
  Alert,
  ButtonGroup,
  Divider,
} from "@mui/material";
import { Sidebar } from "./../Common/Sidebar";
import { constants, helpers } from "./../../utils";
import { OrganizationService } from "../../services/OrganizationService";
import { useParams, Link, useNavigate } from "react-router-dom";
import { A4ALoader } from "../Common/A4ALoader";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import { InviteOrganizationAdmin } from "../InviteOrganizationAdmin";
import { InviteOrganizationMember } from "../InviteOrganizationMember";
import { CompanyService } from "../../services/CompanyService";
import { AuthProvider, useAuth } from "./../AuthProvider";
import { SelectInput } from "../Common/SelectInput";

import * as SkeletonLoader from "../SkeletonLoader";
import SelectDropdown from "../SelectDropdown";
import {
  AirportConsortiumGroup,
  CompanyTypeChoice,
  OrgAdminUser,
  OrgSupportAdminUser,
} from "./../../API";
import { useMapContext } from "../MapProvider/MapProvider";
import MapService from "../../services/MapService";
import { AddOrganizationAirport } from "../AddOrganizationAirport";
import {
  InviteLogService,
  InviteLogSubscription,
} from "../../services/InviteLogService";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { OrgAdminUserService } from "../../services/OrgAdminUserService";
import { OrgMemberUserService } from "../../services/OrgMemberUserService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomInputField from "../CustomInputField/CustomInputField";
import { CognitoService } from "../../services/CognitoService";
import { UserService } from "../../services/UserService";
import { Auth } from "aws-amplify";
import * as x_queries from "./../../graphql/customQueries";
import { AirportConsortiumGroupService } from "./../../services/AirportConsortiumGroupService";
import { DataTableBase } from "./../Common/DataTableBase";
import IconButton from "@mui/material/IconButton";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
import { AutoCompleteInput } from "../Common/AutoCompleteInput";
import { OrgSchema } from "../../validations/schema";
import { OrgSupportAdminUserService } from "../../services/OrgSupportAdminUserService";
import { TextFieldInput } from "../Common/TextFieldInput";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { SendConsortiumEmailSidebar } from "../SendConsortiumEmailSidebar";
import {
  OrganizationCellText,
  OrganizationDetailHeading,
  OrganizationDetailTabButton,
  OrganizationDetailTabButtonGroup,
} from "./style";
import { useScreenSize } from "../../hooks";

interface ILoading {
  data: boolean;
  submit: boolean;
}

interface IAirportFormData {
  chairContactId: string;
  chairId: string;
  // facilityOwnerContact: string;
  facilityOwner: string;
  id: string;
  legalCounselContact: string;
  legalCounsel: string;
  operatorGeneralManagerId: string;
  operatorId: string;
  secondaryContact: string;
}

interface IUpdateConsortiumInfo {
  consortium: any;
  setConsortium: any;
}

const UpdateConsortiumInfo: React.FC<IUpdateConsortiumInfo> = ({
  consortium = {},
  setConsortium,
}) => {
  const { width } = useScreenSize();
  const defaultFormState = { name: "", groupType: "" };
  const [formData, setFormData] = useState<any>({ ...defaultFormState });
  const [errorData, setErrorData] = useState<any>({ ...defaultFormState });
  const [editable, setEditable] = useState<boolean>(false);
  const apiOrganization = new OrganizationService();
  const { state: dataProviderState, dispatch: dataProvderDispatch } =
    useDataContext();
  const { state: authState } = useAuth();
  const [openSendEmailSidebar, setOpenSendEmailSidebar] =
    useState<boolean>(false);

  useEffect(() => {
    setFormData({
      name: consortium?.name ?? "",
      groupType: consortium?.groupType ?? "",
    });
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setErrorData({ ...defaultFormState });

    let result: any = {};

    result = OrgSchema.updateOrgValidationSchema[key].validate(value) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setErrorData(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setErrorData(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  // Update organization
  const handleUpdateOrganization = async () => {
    // clear error state
    setErrorData({ ...defaultFormState });

    let isValidationError = false;

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      let isValid = true;

      if (
        key === "groupType" &&
        consortium?.organizationType ===
          constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM
      ) {
        isValid = handleValidation(key, formData[key]);
      } else if (key !== "groupType") {
        isValid = handleValidation(key, formData[key]);
      }

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    if (!isValidationError) {
      const promptResult = await Swal.fire({
        title: "Do you want to update consortium details ?",
        showCancelButton: true,
        confirmButtonText: "Update",
      });

      // If prompt not confirmed
      if (!promptResult?.isConfirmed) {
        handleCancelUpdateConsortium();
        return;
      }

      let payload: any = {
        id: consortium?.id,
        name: formData?.name,
      };

      if (
        formData?.type === constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM
      ) {
        payload = {
          ...payload,
          groupType: formData?.groupType,
        };
      }

      // start page loader
      helpers?.startPageLoader();

      // api call to update consortium or organization
      const response = await apiOrganization.updateOrganizationById(payload);

      if (response !== null) {
        const responsePayload = {
          name: response?.name,
          groupType: response?.groupType,
        };

        setFormData({
          ...responsePayload,
        });

        // update consortium state
        setConsortium((prev: any) => ({
          ...prev,
          name: response?.name,
          groupType: response?.groupType,
        }));

        // Update data provider context api organization list data
        // const consortiumAtIndex =
        //   dataProviderState?.organizationList?.findIndex(
        //     (temp) => temp?.id === consortium.id
        //   );

        // if (consortiumAtIndex !== -1) {
        //   let globalConsortiumDataToUpdate =
        //     dataProviderState?.organizationList?.filter(
        //       (temp) => temp?.id === consortium?.id
        //     )[0];

        //   globalConsortiumDataToUpdate = {
        //     ...globalConsortiumDataToUpdate,
        //     name: response?.name,
        //     groupType: response?.groupType,
        //   };

        //   let consortiumListCopy = dataProviderState?.organizationList;

        //   consortiumListCopy[consortiumAtIndex] = globalConsortiumDataToUpdate;

        //   dataProvderDispatch({
        //     type: dataContextAction?.SET_ORGANIZATION_LIST,
        //     payload: consortiumListCopy,
        //   });
        // }

        // set form fields not editable
        setEditable((prev) => !prev);
      } else {
        handleCancelUpdateConsortium();
      }

      // stop page loader
      helpers?.stopPageLoader();
    }
  };

  const handleCancelUpdateConsortium = () => {
    // clear validation errors
    setErrorData({ ...defaultFormState });

    // reset form data
    setFormData({
      name: consortium?.name ?? "",
      groupType: consortium?.groupType ?? "",
    });

    setEditable((prev) => !prev);
  };

  const canSendEmail =
    consortium?.admins?.items?.find(
      (admin: OrgAdminUser) => admin.user.id === authState.user?.dbUser?.id
    ) ||
    consortium?.supportAdmin?.items?.find(
      (supportAdmin: OrgSupportAdminUser) =>
        supportAdmin.user.id === authState.user?.dbUser?.id
    ) ||
    authState.user?.groups.includes(constants.ROLES.A4A_ADMIN);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        {authState?.user?.groups?.includes(constants?.ROLES?.A4A_ADMIN) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: width <= 600 ? "100%" : "30%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              {!editable && (
                <IconButton
                  sx={{ borderRadius: "16px", mb: 1 }}
                  color="primary"
                  onClick={() => setEditable((prev: boolean) => !prev)}
                >
                  <EditIcon />
                </IconButton>
              )}
              {editable && (
                <IconButton
                  sx={{ borderRadius: "16px", mb: 1 }}
                  color="primary"
                  onClick={handleUpdateOrganization}
                >
                  <SaveIcon />
                </IconButton>
              )}
              {editable && (
                <IconButton
                  sx={{ borderRadius: "16px", mb: 1 }}
                  color="error"
                  onClick={handleCancelUpdateConsortium}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
            <TextFieldInput
              id="name"
              name="name"
              placeholder="Consortium Name"
              label="Consortium Name"
              error={errorData.name}
              value={formData.name}
              margin="normal"
              handleChange={handleChange}
              onBlur={() => handleValidation("name", formData.name)}
              // autoFocus
              fullWidth
              disabled={!editable}
            />

            {consortium?.organizationType ===
              constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM && (
              <AutoCompleteInput
                id="groupType"
                name="groupType"
                placeholder="Select Consortium Type"
                label="Consortium Type"
                options={constants.DB_ENUM.GroupTypeChoice?.map(
                  (item: string) => ({
                    label: helpers?.capitalizeFirstLetterEveryWord(
                      item.replaceAll("_", " ").toLocaleLowerCase()
                    ),
                    value: item,
                  })
                )}
                error={errorData.groupType}
                value={formData.groupType}
                fullWidth={true}
                handleChange={handleChange}
                disabled={!editable}
                onBlur={() => handleValidation("groupType", formData.groupType)}
              />
            )}
          </Box>
        )}
        {canSendEmail && (
          <Box
            sx={{
              ml: "auto",
              mt: "auto",
              mb: "10px",
              position: width <= 600 ? "absolute" : "static",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => setOpenSendEmailSidebar(true)}
            >
              Send Email
            </Button>
          </Box>
        )}
      </Box>
      <Sidebar
        handleClose={() => setOpenSendEmailSidebar(false)}
        open={openSendEmailSidebar}
      >
        <SendConsortiumEmailSidebar
          // selectedCompany={selectedAirportCompany ?? selectedAirportOperator}
          // organization={item}
          consortium={consortium}
          closeSidebar={() => setOpenSendEmailSidebar(false)}
        />
      </Sidebar>
    </>
  );
};

const OrganizationDetail = () => {
  // state
  const { state } = useMapContext();
  const { width } = useScreenSize();

  const [value, setValue] = React.useState(0);
  const [item, setItem] = React.useState<any>("");
  const [apiLoading, setApiLoading] = useState<boolean>(false);

  const [orgAdminList, setOrgAdminList] = useState<Array<any>>([]);
  const [orgMemberList, setOrgMemberList] = useState<Array<any>>([]);

  const [openInviteOrgAdmin, setOpenInviteOrgAdmin] = useState<boolean>(false);
  const [openInviteOrgUser, setOpenInviteOrgUser] = useState<boolean>(false);
  const [openAddOrgAirport, setOpenAddOrgAirport] = useState<boolean>(false);

  const [companies, setCompanies] = useState<Array<any>>([]);

  const [selectedAirport, setSelectedAirport] = useState<string>("");
  const [airportFormData, setAirportFormData] = useState<IAirportFormData>();
  const [selectedAirportCompany, setSelectedAirportCompany] = useState<
    string | undefined
  >(undefined);
  const [selectedAirportOperator, setSelectedAirportOperator] = useState<
    string | undefined
  >(undefined);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedOperator, setSelectedOperator] = useState<string>("");
  const [selectedOperatorError, setSelectedOperatorError] =
    useState<string>("");
  const [isChairUpdated, setChairUpdated] = useState<boolean>(false);
  const [isOperatorUpdated, setOperatorUpdated] = useState<boolean>(false);

  const [loadingState, setLoadingState] = useState<ILoading>({
    data: false,
    submit: false,
  });

  const [tabsToShow, setTabsToShow] = useState<Array<string>>([]);
  const [isSuperUser, setSuperUser] = useState<boolean>(false);
  const [isUserSupportAdmin, setUserSupportAdmin] = useState<boolean>(false);
  const [selectedBtnGroup, setSelectedBtnGroup] =
    React.useState<string>("Administrators");
  const [filteredOrgAdminList, setFilteredOrgAdminList] = useState<Array<any>>(
    []
  );
  const [filteredOrgMemberList, setFilteredOrgMemberList] = useState<
    Array<any>
  >([]);

  const didMountRefAiportLength = useRef(false);
  const didMountRefUserLength = useRef(false);

  const { orgId } = useParams();
  const navigateTo = useNavigate();

  // auth provider
  const { state: auth } = useAuth();
  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();

  // if signed user is only member not admin to any org & also not A4A Admin
  const isUserOnlyOrgMember =
    auth?.user?.orgRole?.admin?.length === 0 &&
    !auth?.user?.groups?.includes(constants.ROLES.A4A_ADMIN) &&
    auth?.user?.orgRole?.member?.length !== 0 &&
    auth?.user?.orgRole?.supportAdmin?.length === 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.ORGANIZATION_DETAIL;

    // if user is only org member then redirect to main page
    if (isUserOnlyOrgMember) {
      // navigate to main page
      navigateTo("/main");
    }

    // clear global search bar text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
      payload: "",
    });

    //IIFE
    (async () => {
      // start loader
      setLoadingState((prev: ILoading) => ({ ...prev, data: true }));

      try {
        await getOrgById();
        // await getCompanyList();

        await getOrgAdminMemberList();
      } catch (e) {
        // show error message
        console.log(e);
      }

      // stop loader
      setLoadingState((prev: ILoading) => ({ ...prev, data: false }));
    })();

    // create invite log subscription for real time updates
    const createInviteLogSubscription = InviteLogSubscription.getOnCreate();
    const updateInviteLogSubscription = InviteLogSubscription.getOnUpdate();
    const deleteInviteLogSubscription = InviteLogSubscription.getOnDelete();

    // subscribe
    createInviteLogSubscription.subscribe({
      next: (data: any) => {
        // get all org admin & member user list
        (async () => {
          await getOrgAdminMemberList();
        })();
      },
    });

    updateInviteLogSubscription.subscribe({
      next: (data: any) => {
        // get all org admin & member user list
        (async () => {
          await getOrgAdminMemberList();
        })();
      },
    });

    deleteInviteLogSubscription.subscribe({
      next: (data: any) => {
        // get all org admin & member user list
        (async () => {
          await getOrgAdminMemberList();
        })();
      },
    });

    // cleanup
    return () => {
      // cleanup subscription on component unmount
      if ("unsubscribe" in createInviteLogSubscription) {
        createInviteLogSubscription.unsubscribe();
      }

      if ("unsubscribe" in updateInviteLogSubscription) {
        updateInviteLogSubscription.unsubscribe();
      }

      if ("unsubscribe" in deleteInviteLogSubscription) {
        deleteInviteLogSubscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (!openInviteOrgAdmin) {
      setSelectedAirportCompany(undefined);
      setSelectedAirportOperator(undefined);
    }
  }, [openInviteOrgAdmin]);

  // clear search bar text on tab switch
  useEffect(() => {
    // clear global search bar text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
      payload: "",
    });
  }, [selectedBtnGroup]);

  useEffect(() => {
    let timeoutId: any = null;

    let searchText = dataProviderState?.topBarSearchText;

    if (searchText.trim() !== "") {
      timeoutId = setTimeout(() => {
        // filter for admin
        if (selectedBtnGroup === "Administrators") {
          const filtered = orgAdminList?.filter(
            (item: any) =>
              item?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
              item?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
              item?.status?.toLowerCase().includes(searchText.toLowerCase()) ||
              item?.role?.toLowerCase().includes(searchText.toLowerCase()) ||
              item?.company?.toLowerCase().includes(searchText.toLowerCase())
          );

          // udpate admins list
          setFilteredOrgAdminList([...filtered]);
        }
        // filter for member
        if (selectedBtnGroup === "Members") {
          const filtered = orgMemberList?.filter(
            (item: any) =>
              item?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
              item?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
              item?.status?.toLowerCase().includes(searchText.toLowerCase()) ||
              item?.role?.toLowerCase().includes(searchText.toLowerCase()) ||
              item?.company?.toLowerCase().includes(searchText.toLowerCase())
          );

          // udpate members list
          setFilteredOrgMemberList([...filtered]);
        }
      }, 1000);
    } else {
      // udpate admin & members list
      setFilteredOrgAdminList([...orgAdminList]);
      setFilteredOrgMemberList([...orgMemberList]);
    }

    return () => clearTimeout(timeoutId);
  }, [dataProviderState?.topBarSearchText]);

  const getOrganizationRoles = async (orgType: string) => {
    // if user is A4A Admin or Site Admin
    if (auth?.user?.groups.includes(constants?.ROLES.A4A_ADMIN)) {
      if (orgType === constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM) {
        setTabsToShow(["Administrators", "Members", "Airport Contacts"]);
      } else {
        setTabsToShow(["Administrators", "Members"]);
      }
      setSuperUser(true);
      return;
    }

    // check user is org admin
    const isUserOrgAdmin =
      auth?.user?.orgRole?.admin?.filter(
        (item) => item?.organizationId === `${orgId}`
      ).length !== 0;

    // check user is org member
    const isUserOrgMember =
      auth?.user?.orgRole?.member?.filter(
        (item) => item?.organizationId === `${orgId}`
      ).length !== 0;

    if (isUserOrgAdmin) {
      if (orgType === constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM) {
        setTabsToShow(["Administrators", "Members", "Airport Contacts"]);
      } else {
        setTabsToShow(["Administrators", "Members"]);
      }
      setSuperUser(true);
    } else if (isUserOrgMember) {
      setTabsToShow(["Members"]);
      setSelectedBtnGroup("Members");
    }

    // check support admin role
    const isSupportAdmin =
      auth?.user?.orgRole?.supportAdmin?.filter(
        (relation) => relation?.organizationId === orgId
      )?.length !== 0;

    // set support admin condition
    setUserSupportAdmin(isSupportAdmin);
  };

  // handle revoke access
  const handleRevokeAccess = async (event: any, params: any) => {
    event.stopPropagation();

    // start page loader
    helpers.startPageLoader();

    const apiAdminRelation = new OrgAdminUserService();
    const apiMemberRelation = new OrgMemberUserService();
    const apiInviteLog = new InviteLogService();
    const apiCognito = new CognitoService();
    const apiUser = new UserService();
    const apiAirportConsortiumGroup = new AirportConsortiumGroupService();

    const { row } = params;

    const userInviteLogsFilter = {
      userId: { eq: row?.id },
      deleted: { ne: true },
    };

    const invites: any = await apiInviteLog.recursiveFetchListInviteLogs(
      userInviteLogsFilter
    );

    const isCognitoDelete = invites?.length === 1;

    let isCognitoDeleteApproved = false;

    let signOutUser = false;

    const log = invites[0];

    if (isCognitoDelete) {
      // get all user list from cognito user group
      const a4a_admin_list: any = await apiCognito.getListofUserInGroup(
        constants?.ROLES?.A4A_ADMIN
      );

      const email_list = a4a_admin_list?.Users?.map((item: any) =>
        helpers.getFormattedCognitoUserAttributes(item?.Attributes)
      );

      // is deleting email account is A4A_ADMIN
      const isAdminEmailMatched =
        email_list?.filter((item: any) => item?.email === log?.user?.email)
          .length !== 0;

      if (!isAdminEmailMatched) {
        isCognitoDeleteApproved = true;
      }

      // if user is not A4A_ADMIN and loggedin email is same as invited email
      if (
        auth?.user?.email === log?.user?.email &&
        !auth?.user?.groups.includes(constants?.ROLES?.A4A_ADMIN)
      ) {
        signOutUser = true;
      }
    }

    const filter = {
      organizationId: { eq: orgId },
      userId: { eq: row?.id },
    };

    if (row?.role === "Admin") {
      // remove relationship from OrgAdminUser table
      const relations = (await apiAdminRelation.recursiveFetchListOrgAdminUsers(
        filter
      )) as any;

      // if relationship exist
      if (relations?.length !== 0) {
        // delete relationship by id
        await apiAdminRelation.deleteOrgAdminUserById(relations[0]["id"]);
      }

      // clear invite log
      const logFilter = {
        organizationId: { eq: orgId },
        userId: { eq: row?.id },
        type: { eq: "ADMIN" },
        deleted: { ne: true },
      };

      const invites: any = await apiInviteLog.recursiveFetchListInviteLogs(
        logFilter
      );

      // if log exist
      if (invites?.length !== 0) {
        // delete log
        await apiInviteLog.updateInviteLogById({
          id: invites[0]["id"],
          deleted: true,
        });
      }

      // Remove AirportConsortiumGroup Mapping
      const airConGrpfilter = {
        organizationId: { eq: orgId },
      };

      const response =
        await apiAirportConsortiumGroup.recursiveFetchAirportConsortiumGroup(
          airConGrpfilter,
          []
        );

      await Promise.all(
        response?.map(async (mapping: any) => {
          let shouldUpdate = false;
          let apiPayload: any = { id: mapping?.id };

          if (row?.id === mapping?.chairContactId) {
            apiPayload = { ...apiPayload, chairContactId: null };
            shouldUpdate = true;
          }

          if (row?.id === mapping?.operatorGeneralManagerId) {
            apiPayload = { ...apiPayload, operatorGeneralManagerId: null };
            shouldUpdate = true;
          }

          if (row?.id === mapping?.operatorId) {
            apiPayload = { ...apiPayload, operatorId: null };
            shouldUpdate = true;
          }

          // update AirportConsortiumGroup table
          if (shouldUpdate) {
            await apiAirportConsortiumGroup.updateAirportConsortiumGroupById(
              apiPayload
            );
          }

          return null;
        })
      );
    } else if (row?.role === "Member") {
      // remove relationship from OrgMemberUser table
      const relations =
        (await apiMemberRelation.recursiveFetchListOrgMemberUsers(
          filter
        )) as any;

      // if relationship exist
      if (relations?.length !== 0) {
        // delete relationship by id
        await apiMemberRelation.deleteOrgMemberUserById(relations[0]["id"]);
      }

      // clear invite log
      const logFilter = {
        organizationId: { eq: orgId },
        userId: { eq: row?.id },
        type: { eq: "MEMBER" },
        deleted: { ne: true },
      };

      const invites: any = await apiInviteLog.recursiveFetchListInviteLogs(
        logFilter
      );

      // if log exist
      if (invites?.length !== 0) {
        // delete log
        await apiInviteLog.updateInviteLogById({
          id: invites[0]["id"],
          deleted: true,
        });
      }
    }

    // if cognito user delete is approved
    if (isCognitoDeleteApproved) {
      // delete user from cognito user pool
      await apiCognito.deleteUser(log?.user?.email as any);
      // update user table
      await apiUser?.updateUserById({ id: row?.id, deleted: true });
    }

    // stop page loader
    helpers.stopPageLoader();

    if (signOutUser) {
      // sign out user
      await Auth.signOut();

      // navigate to login page
      navigateTo("/auth/in");
    }

    // show toast message
    toast.success(`${row?.name} access revoked.`, { theme: "colored" });
  };

  const adminTblColumn = [
    {
      name: "Name",
      selector: (row: any) => row?.name,
      sortable: true,
      cell: (row: any) => (
        <OrganizationCellText>{row?.name ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Company",
      selector: (row: any) => row?.company,
      sortable: true,
      cell: (row: any) => (
        <OrganizationCellText>{row?.company ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Email",
      selector: (row: any) => row?.email,
      sortable: true,
      cell: (row: any) => (
        <OrganizationCellText>{row?.email ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Status",
      selector: (row: any) => row?.status,
      sortable: true,
      grow: 0.4,
      cell: (row: any) => (
        <OrganizationCellText>{row?.status ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Role",
      selector: (row: any) => row?.role,
      sortable: true,
      grow: 0.1,
      cell: (row: any) => (
        <OrganizationCellText>{row?.role ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Actions",
      grow: 0.4,
      cell: (row: any) => (
        <IconButton
          disabled={!isSuperUser}
          title="Revoke Access"
          onClick={(event) => {
            Swal.fire({
              title: "Do you want to revoke access ?",
              showCancelButton: true,
              confirmButtonText: "Revoke",
            }).then(async (result: any) => {
              if (result.isConfirmed) {
                await handleRevokeAccess(event, { row });
              }
            });
          }}
        >
          <DangerousIcon color={isSuperUser ? "error" : "disabled"} />
        </IconButton>
      ),
    },
  ];

  const memberTblColumn = [
    {
      name: "Name",
      selector: (row: any) => row?.name,
      sortable: true,
      cell: (row: any) => (
        <OrganizationCellText>{row?.name ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Company",
      selector: (row: any) => row?.company,
      sortable: true,
      cell: (row: any) => (
        <OrganizationCellText>{row?.company ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Email",
      selector: (row: any) => row?.email,
      sortable: true,
      cell: (row: any) => (
        <OrganizationCellText>{row?.email ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Status",
      selector: (row: any) => row?.status,
      sortable: true,
      grow: 0.4,
      cell: (row: any) => (
        <OrganizationCellText>{row?.status ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Role",
      selector: (row: any) => row?.role,
      sortable: true,
      grow: 0.1,
      cell: (row: any) => (
        <OrganizationCellText>{row?.role ?? ""}</OrganizationCellText>
      ),
    },
    {
      name: "Actions",
      grow: 0.4,
      cell: (row: any) => (
        <>
          <IconButton
            disabled={!isSuperUser || row?.isSupportAdmin}
            title="Support Admin"
            onClick={(event) => {
              Swal.fire({
                title: "Do you want to give support admin role ?",
                showCancelButton: true,
                confirmButtonText: "Yes",
              }).then(async (result: any) => {
                if (result.isConfirmed) {
                  await handleSupportAdminRole(event, row);
                }
              });
            }}
          >
            <GroupAddIcon
              color={
                isSuperUser && !row?.isSupportAdmin ? "success" : "disabled"
              }
            />
          </IconButton>
          <IconButton
            disabled={!isSuperUser || !row?.isSupportAdmin}
            title="Remove Support Admin"
            onClick={(event) => {
              Swal.fire({
                title: "Remove support admin role ?",
                showCancelButton: true,
                confirmButtonText: "Remove",
              }).then(async (result: any) => {
                if (result.isConfirmed) {
                  await handleRemoveSupportAdminRole(event, row);
                }
              });
            }}
          >
            <PersonOffIcon
              color={isSuperUser && row?.isSupportAdmin ? "error" : "disabled"}
            />
          </IconButton>
          <IconButton
            disabled={!isSuperUser}
            title="Revoke Access"
            onClick={(event) => {
              Swal.fire({
                title: "Do you want to revoke access ?",
                showCancelButton: true,
                confirmButtonText: "Revoke",
              }).then((result: any) => {
                if (result.isConfirmed) {
                  handleRevokeAccess(event, { row });
                }
              });
            }}
          >
            <DangerousIcon color={isSuperUser ? "error" : "disabled"} />
          </IconButton>
        </>
      ),
    },
  ];

  const getOrgById = async () => {
    const os = new OrganizationService();

    // start api loading
    setApiLoading(true);

    const data: any = await os.getOrganizationById(orgId as any);

    // if organization don't exist then return
    if (data === null) return;

    // update state
    setItem({ ...data });

    // set selected chair id
    if (data?.airports?.items?.length) {
      setSelectedAirport(data?.airports?.items?.[0]?.airportId ?? "");
    }

    // set selected operator id
    if (data?.operatorId !== null || data?.operatorId !== "") {
      setSelectedOperator(data?.operatorId as any);
    }

    await getOrganizationRoles(data?.organizationType);

    // start api loading
    setApiLoading(false);
  };

  // get list of companies
  const getCompanyList = async () => {
    const cs = new CompanyService();

    const { companies: x_companies, nextToken } = await cs.getAllCompanies();

    const t_data: any = x_companies?.map((item: any) => ({
      label: item?.name,
      value: item?.id,
    }));

    // update state data
    setCompanies([...t_data]);
  };

  // update operator for organization
  const handleUpdateOperator = async () => {
    // clear errors
    setSelectedOperatorError("");
    setOperatorUpdated(false);

    // if operator not selected
    if (selectedOperator === "") {
      setSelectedOperatorError("Select organization operator.");
      return;
    }

    const payload = {
      id: item?.id,
      operatorId: selectedOperator,
    };

    const os = new OrganizationService();

    try {
      // API to update
      await os.updateOrganizationById(payload);

      setOperatorUpdated(true);
    } catch (e) {
      // show error
      console.log(e);
    }
  };

  const airportFormDataChangeHandler = (
    key: keyof IAirportFormData,
    value: string
  ) => {
    setAirportFormData((prevAirportFormData) => ({
      ...prevAirportFormData!,
      [key]: value,
    }));
  };

  const handleAirportSelectChange = (airportId?: string) => {
    const currentAirport = airportId
      ? item?.airports?.items?.find(
          (airport: any) => airport.airportId === selectedAirport
        )
      : item?.airports?.items?.[0];
    if (currentAirport) {
      const {
        chairContactId = "",
        chairId = "",
        // facilityOwnerContact = "",
        facilityOwner = "",
        id = "",
        legalCounselContact = "",
        legalCounsel = "",
        operatorGeneralManagerId = "",
        operatorId = "",
        secondaryContact = "",
      } = currentAirport;
      setAirportFormData({
        chairContactId,
        chairId,
        // facilityOwnerContact,
        facilityOwner,
        id,
        legalCounselContact,
        legalCounsel,
        operatorGeneralManagerId,
        operatorId,
        secondaryContact,
      });
    }
  };

  useEffect(() => {
    // OLD: if (item?.airports?.items?.length) {
    if (item?.airports?.items?.length && selectedAirport !== "") {
      handleAirportSelectChange(selectedAirport);
    }
  }, [selectedAirport]);

  useEffect(() => {
    if (didMountRefAiportLength.current && item?.airports?.items?.length) {
      setSelectedAirport(
        item?.airports?.items?.[item?.airports?.items?.length - 1 ?? 0]
          .airportId
      );
    } else if (!didMountRefAiportLength.current && item?.airports?.items) {
      didMountRefAiportLength.current = true;
    }
  }, [item?.airports?.items?.length]);

  useEffect(() => {
    if (didMountRefUserLength.current && state.userList.length) {
      const addedUser = state.userList?.[state.userList.length - 1 ?? 0];
      if (addedUser && addedUser.companyId === airportFormData?.chairId) {
        airportFormDataChangeHandler("chairContactId", addedUser.id);
      }
      if (addedUser && addedUser.companyId === airportFormData?.operatorId) {
        airportFormDataChangeHandler("operatorGeneralManagerId", addedUser.id);
      }
    } else if (!didMountRefUserLength.current && state.userList) {
      didMountRefUserLength.current = true;
    }
  }, [state.userList.length]);

  const handleUpdateAirportConsortium = async () => {
    if (!airportFormData) return;
    setIsUpdating(true);
    try {
      const consortiumResponse = await MapService.updateAirportConsortiumGroup(
        airportFormData.id,
        airportFormData
      );
      if (typeof consortiumResponse !== "string") {
        setItem((prevItem: any) => ({
          ...prevItem,
          airports: {
            ...prevItem.airports,
            items: prevItem.airports.items.map((airportConsortium: any) => {
              if (airportConsortium.id === consortiumResponse.id) {
                return { ...airportConsortium, ...consortiumResponse };
              }
              return airportConsortium;
            }),
          },
        }));
        toast.success(`Updated Airport info successfully.`, {
          theme: "colored",
        });
      } else {
        alert(consortiumResponse);
        toast.error(consortiumResponse, { theme: "colored" });
        return;
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsUpdating(false);
    }
  };

  const companyOptions =
    state?.companyList
      ?.map((company) => ({
        name: company.name,
        value: company.id,
      }))
      ?.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  const getOrgAdminMemberList = async () => {
    const api = new InviteLogService();

    const filter = {
      organizationId: { eq: orgId },
      deleted: { ne: true },
    };

    const invites: any = await api.recursiveFetchListInviteLogs(
      filter,
      [],
      null,
      x_queries?.sg_CustomlistInviteLogs
    );

    // if data array is empty
    if (invites?.length === 0) {
      setOrgAdminList([]);
      setOrgMemberList([]);
      setFilteredOrgAdminList([]);
      setFilteredOrgMemberList([]);
    }

    // filter admin & member from array
    const admins = invites?.filter((item: any) => item?.type === "ADMIN");
    const members = invites?.filter((item: any) => item?.type === "MEMBER");

    // handle admin data
    if (admins?.length !== 0) {
      const temp: Array<any> = admins?.map((item: any) => ({
        organizationId: item?.organizationId,
        user: item?.user,
        isAccepted: item?.isAccepted,
      })) as any;

      let formatted = temp?.map((item) => ({
        id: item?.user?.id,
        name: `${item?.user?.firstName} ${item?.user?.lastName}`,
        email: item?.user?.email,
        status: item?.isAccepted ? "Accepted" : "Pending",
        role: "Admin",
        enabled: true,
        company: item?.user?.company?.name,
        isSupportAdmin: false,
      }));

      // sort org admin list
      formatted.sort((itemA: any, itemB: any) => {
        if (itemA.name < itemB.name) {
          return -1;
        }
        if (itemA.name > itemB.name) {
          return 1;
        }
        return 0;
      });

      setOrgAdminList([...formatted]);
      setFilteredOrgAdminList([...formatted]);
    }

    // handle member data
    if (members?.length !== 0) {
      const temp: Array<any> = members?.map((item: any) => ({
        organizationId: item?.organizationId,
        user: item?.user,
        isAccepted: item?.isAccepted,
      })) as any;

      let formatted = temp?.map((item) => ({
        id: item?.user?.id,
        name: `${item?.user?.firstName} ${item?.user?.lastName}`,
        email: item?.user?.email,
        status: item?.isAccepted ? "Accepted" : "Pending",
        role: "Member",
        enabled: true,
        company: item?.user?.company?.name,
        isSupportAdmin:
          item?.user?.organizationSupportAdmin?.items?.filter(
            (relation: any) =>
              relation?.organizationId === item?.organizationId &&
              relation?.userId === item?.user?.id
          ).length !== 0,
      }));

      // sort org member list
      formatted.sort((itemA: any, itemB: any) => {
        if (itemA.name < itemB.name) {
          return -1;
        }
        if (itemA.name > itemB.name) {
          return 1;
        }
        return 0;
      });

      setOrgMemberList([...formatted]);
      setFilteredOrgMemberList([...formatted]);
    }
  };

  const chairContactOptions = state.userList.reduce(
    (
      currentUserList: Array<{
        name: string;
        value: string;
      }>,
      user
    ) => {
      if (user.companyId && user.companyId === airportFormData?.chairId) {
        currentUserList.push({
          name: `${user.firstName} ${user.lastName}`,
          value: user.id,
        });
      }
      return currentUserList;
    },
    []
  );

  const handleSupportAdminRole = async (event: any, row: any) => {
    event.stopPropagation();

    // start page loader
    helpers.startPageLoader();

    const apiOrgSupportAdminUser = new OrgSupportAdminUserService();

    const relationCheckFilter = {
      organizationId: { eq: orgId },
      userId: { eq: row?.id },
    };

    const relation =
      await apiOrgSupportAdminUser.recursiveFetchListOrgSupportAdminUsers(
        relationCheckFilter,
        []
      );

    // if relation not created yet
    if (relation?.length === 0) {
      // api params
      const payload = {
        organizationId: orgId as any,
        userId: row?.id,
      };

      // create support admin role
      await apiOrgSupportAdminUser.createOrgSupportAdminUser(payload);
    }

    const newOrgMemberList = orgMemberList?.map((member) => {
      if (member?.id === row?.id) {
        return {
          ...member,
          isSupportAdmin: true,
        };
      }

      return { ...member };
    });

    // update member table data
    setOrgMemberList([...newOrgMemberList]);
    setFilteredOrgMemberList([...newOrgMemberList]);

    // start page loader
    helpers.stopPageLoader();
  };

  const handleRemoveSupportAdminRole = async (event: any, row: any) => {
    event.stopPropagation();

    // start page loader
    helpers.startPageLoader();

    const apiOrgSupportAdminUser = new OrgSupportAdminUserService();

    const relationCheckFilter = {
      organizationId: { eq: orgId },
      userId: { eq: row?.id },
    };

    const relation =
      await apiOrgSupportAdminUser.recursiveFetchListOrgSupportAdminUsers(
        relationCheckFilter,
        []
      );

    // if relation exist then remove it
    if (relation?.length !== 0) {
      // get support admin relation db id
      const supportAdminRelationId = relation[0]["id"];

      // delete support admin role
      await apiOrgSupportAdminUser.deleteOrgSupportAdminUserById(
        supportAdminRelationId
      );

      const newOrgMemberList = orgMemberList?.map((member) => {
        if (member?.id === row?.id) {
          return {
            ...member,
            isSupportAdmin: false,
          };
        }

        return { ...member };
      });

      // update member table data
      setOrgMemberList([...newOrgMemberList]);
      setFilteredOrgMemberList([...newOrgMemberList]);
    }

    // start page loader
    helpers.stopPageLoader();
  };

  // handle remove airport
  const handleRemoveAirport = async (event: any) => {
    event.preventDefault();

    const apiAirportConsortiumGroup = new AirportConsortiumGroupService();

    const promptResult = await Swal.fire({
      title: "Do you want to remove airport-consortium relationship ?",
      showCancelButton: true,
      confirmButtonText: "Remove",
    });

    if (promptResult?.isConfirmed) {
      // start page loader
      helpers?.startPageLoader();

      // delete api call from AirportConsortiumGroup table
      const response =
        await apiAirportConsortiumGroup.deleteAirportConsortiumGroupById(
          airportFormData?.id as any
        );

      if (response) {
        // set selected airport to empty
        setSelectedAirport("");

        // reset airport form data
        setAirportFormData({
          chairContactId: "",
          chairId: "",
          // facilityOwnerContact: "",
          facilityOwner: "",
          id: "",
          legalCounselContact: "",
          legalCounsel: "",
          operatorGeneralManagerId: "",
          operatorId: "",
          secondaryContact: "",
        });

        toast.success("Airport removed from consortium.", { theme: "colored" });
      }

      // stop page loader
      helpers?.stopPageLoader();
    }
  };

  return (
    <>
      {loadingState.data && <SkeletonLoader.OrganizationDetail />}
      {!loadingState.data && (
        <Grid sx={{ my: 2 }}>
          <Card
            style={{
              padding: "20px 5px",
              margin: "0 auto",
              borderRadius: "16px",
              background: "rgb(96, 96, 96, 0.05)",
              minHeight: "75vh",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  //justifyContent: "space-between",
                  alignItems: "baseline",
                  mb: 3,
                }}
              >
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                  <Link to="/org" style={{ textDecoration: "none" }}>
                    <OrganizationDetailHeading variant="h6">
                      User Access
                    </OrganizationDetailHeading>
                  </Link>
                  <Typography color="text.primary" variant="h6">
                    {item?.name}
                  </Typography>
                </Breadcrumbs>
                <Box sx={{ textAlign: "center", mt: 2, mx: "auto" }}>
                  <OrganizationDetailTabButtonGroup
                    variant="outlined"
                    color="primary"
                    // aria-label="outlined primary button group"
                    size="small"
                    disableElevation
                    orientation={width <= 600 ? "vertical" : "horizontal"}
                  >
                    {tabsToShow?.map((type: string, idx: number) => (
                      <OrganizationDetailTabButton
                        sx={{
                          borderTopLeftRadius: idx === 0 ? 50 : 0,
                          borderBottomLeftRadius: idx === 0 ? 50 : 0,
                          borderTopRightRadius:
                            idx === tabsToShow?.length - 1 ? 50 : 0,
                          borderBottomRightRadius:
                            idx === tabsToShow?.length - 1 ? 50 : 0,
                          textTransform: "none",
                        }}
                        variant={
                          selectedBtnGroup === type ? "contained" : "outlined"
                        }
                        onClick={() => setSelectedBtnGroup(type)}
                        key={`tabTxt_${type}_${idx}`}
                      >
                        {type}
                      </OrganizationDetailTabButton>
                    ))}
                  </OrganizationDetailTabButtonGroup>
                </Box>
              </Box>
              <UpdateConsortiumInfo consortium={item} setConsortium={setItem} />
              {apiLoading && (
                <Box sx={{ width: "50%", height: "50%", mx: "auto", mt: 3 }}>
                  <A4ALoader />
                </Box>
              )}
              {!apiLoading && (
                <>
                  <Box sx={{ width: "100%" }}>
                    {selectedBtnGroup === "Administrators" && (
                      <>
                        <Divider sx={{ mb: 2 }} />
                        <Box
                          sx={{
                            p: 2,
                            borderRadius: 5,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            // flexWrap: "wrap"
                          }}
                          // component={Paper}
                          // elevation={1}
                        >
                          <Button
                            // type="submit"
                            variant="contained"
                            size="small"
                            // color="primary"
                            sx={{
                              // float: "right",
                              textTransform: "none",
                              borderRadius: "16px",
                            }}
                            onClick={() =>
                              setOpenInviteOrgAdmin((prev) => !prev)
                            }
                            disabled={!isSuperUser}
                          >
                            Invite Admin
                          </Button>
                        </Box>
                        <DataTableBase
                          columns={adminTblColumn}
                          data={filteredOrgAdminList}
                          dense={false}
                          fixedHeader
                          pagination={false}
                          persistTableHead
                          highlightOnHover
                        />
                      </>
                    )}
                    {selectedBtnGroup === "Members" && (
                      <>
                        <Divider sx={{ mb: 2 }} />
                        <Box
                          sx={{
                            p: 2,
                            borderRadius: 5,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            // flexWrap: "wrap"
                          }}
                          // component={Paper}
                          // elevation={1}
                        >
                          <Button
                            // type="submit"
                            variant="contained"
                            size="small"
                            // color="primary"
                            sx={{
                              // float: "right",
                              textTransform: "none",
                              borderRadius: "16px",
                            }}
                            onClick={() =>
                              setOpenInviteOrgUser((prev) => !prev)
                            }
                            disabled={!isSuperUser && !isUserSupportAdmin}
                          >
                            Invite Member
                          </Button>
                        </Box>
                        <DataTableBase
                          columns={memberTblColumn}
                          data={filteredOrgMemberList}
                          dense={false}
                          fixedHeader
                          pagination={false}
                          persistTableHead
                          highlightOnHover
                        />
                      </>
                    )}
                    {item?.organizationType ===
                      constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM &&
                      selectedBtnGroup === "Airport Contacts" && (
                        <>
                          <Divider sx={{ mb: 2 }} />
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: width <= 600 ? "end" : "center",
                              rowGap: width <= 600 ? "10px" : "0px",
                              justifyContent: "space-between",
                              flexDirection:
                                width <= 600 ? "column-reverse" : "row",
                            }}
                          >
                            <SelectDropdown
                              sx={{
                                width:
                                  width <= 600 ? "100%" : "calc(50% - 10px)",
                                "& .MuiFormControl-root": {
                                  width: "100%",
                                  margin: "0px",
                                },
                              }}
                              // id="airport"
                              // name="airport"
                              // placeholder="Select Airport"
                              label="Airport"
                              options={
                                item?.airports?.items?.map((airport: any) => ({
                                  value: airport?.airportId,
                                  name: `${airport?.airport?.name ?? ""} (${
                                    airport?.airport?.code ?? ""
                                  })`,
                                })) ?? []
                              }
                              // error={error.type}
                              // error={selectedChairError}
                              selectedValue={selectedAirport}
                              // fullWidth={true}
                              selectHandler={(event: any) =>
                                setSelectedAirport(event?.target?.value)
                              }
                              // helperText="Select available airport"
                            />
                            {state.isUserA4AAdmin && (
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  textTransform: "none",
                                  borderRadius: "16px",
                                  ml: "auto",
                                  mr: width <= 600 ? 0 : 1,
                                }}
                                onClick={handleRemoveAirport}
                                disabled={!isSuperUser}
                              >
                                Remove Airport
                              </Button>
                            )}
                            {state.isUserA4AAdmin && (
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  textTransform: "none",
                                  borderRadius: "16px",
                                }}
                                onClick={() => setOpenAddOrgAirport(true)}
                                disabled={!isSuperUser}
                              >
                                Add Airport
                              </Button>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "20px",
                              marginTop: "20px",
                              flexDirection:
                                width <= 600 ? "column" : "row"
                            }}
                          >
                            <SelectDropdown
                              sx={{
                                width:
                                  width <= 600 ? "100%" : "calc(50% - 10px)",
                                "& .MuiFormControl-root": {
                                  width: "100%",
                                  margin: "0px",
                                },
                              }}
                              label="Chair Company"
                              options={state.companyList
                                .reduce(
                                  (
                                    currentCompanyList: Array<{
                                      name: string;
                                      value: string;
                                    }>,
                                    company
                                  ) => {
                                    if (
                                      company.type === CompanyTypeChoice.AIRLINE
                                    ) {
                                      currentCompanyList.push({
                                        name: company.name,
                                        value: company.id,
                                      });
                                    }
                                    return currentCompanyList;
                                  },
                                  []
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))}
                              selectedValue={airportFormData?.chairId ?? ""}
                              selectHandler={(event: any) =>
                                airportFormDataChangeHandler(
                                  "chairId",
                                  event?.target?.value
                                )
                              }
                              disabled={isUpdating || !isSuperUser}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                                width:
                                  width <= 600 ? "100%" : "calc(50% - 10px)",
                              }}
                            >
                              <SelectDropdown
                                sx={{
                                  flex: "1",
                                  "& .MuiFormControl-root": {
                                    width: "100%",
                                    margin: "0px",
                                  },
                                }}
                                label="Chair Contact"
                                options={chairContactOptions}
                                selectedValue={
                                  airportFormData?.chairContactId ??
                                  chairContactOptions?.[
                                    chairContactOptions.length - 1
                                  ]?.value ??
                                  ""
                                }
                                selectHandler={(event: any) =>
                                  airportFormDataChangeHandler(
                                    "chairContactId",
                                    event?.target?.value
                                  )
                                }
                                disabled={isUpdating || !isSuperUser}
                              />
                              <AddCircleOutlineIcon
                                onClick={() => {
                                  if (airportFormData?.chairId) {
                                    setSelectedAirportCompany(
                                      airportFormData?.chairId
                                    );
                                    setOpenInviteOrgAdmin((prev) => !prev);
                                  } else {
                                    toast.error(
                                      `Please select Chair Company first.`,
                                      { theme: "colored" }
                                    );
                                  }
                                }}
                                sx={{
                                  fontSize: "30px",
                                  color: "#1976d2",
                                  cursor: "pointer",
                                }}
                              />
                            </Box>
                            <SelectDropdown
                              sx={{
                                width:
                                  width <= 600 ? "100%" : "calc(50% - 10px)",
                                "& .MuiFormControl-root": {
                                  width: "100%",
                                  margin: "0px",
                                },
                              }}
                              label="Operator Company"
                              options={state.companyList
                                .reduce(
                                  (
                                    currentCompanyList: Array<{
                                      name: string;
                                      value: string;
                                    }>,
                                    company
                                  ) => {
                                    if (
                                      company.type ===
                                      CompanyTypeChoice.OPERATOR
                                    ) {
                                      currentCompanyList.push({
                                        name: company.name,
                                        value: company.id,
                                      });
                                    }
                                    return currentCompanyList;
                                  },
                                  []
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))}
                              selectedValue={airportFormData?.operatorId ?? ""}
                              selectHandler={(event: any) =>
                                airportFormDataChangeHandler(
                                  "operatorId",
                                  event?.target?.value
                                )
                              }
                              disabled={isUpdating || !isSuperUser}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                                width:
                                  width <= 600 ? "100%" : "calc(50% - 10px)",
                              }}
                            >
                              <SelectDropdown
                                sx={{
                                  flex: 1,
                                  "& .MuiFormControl-root": {
                                    width: "100%",
                                    margin: "0px",
                                  },
                                }}
                                label="Operator General Manager"
                                options={state.userList.reduce(
                                  (
                                    currentUserList: Array<{
                                      name: string;
                                      value: string;
                                    }>,
                                    user
                                  ) => {
                                    if (
                                      user.companyId &&
                                      user.companyId ===
                                        airportFormData?.operatorId
                                    ) {
                                      currentUserList.push({
                                        name: `${user.firstName} ${user.lastName}`,
                                        value: user.id,
                                      });
                                    }
                                    return currentUserList;
                                  },
                                  []
                                )}
                                selectedValue={
                                  airportFormData?.operatorGeneralManagerId ??
                                  ""
                                }
                                selectHandler={(event: any) =>
                                  airportFormDataChangeHandler(
                                    "operatorGeneralManagerId",
                                    event?.target?.value
                                  )
                                }
                                disabled={isUpdating || !isSuperUser}
                              />
                              <AddCircleOutlineIcon
                                onClick={() => {
                                  if (airportFormData?.operatorId) {
                                    setSelectedAirportOperator(
                                      airportFormData?.operatorId
                                    );
                                    setOpenInviteOrgAdmin((prev) => !prev);
                                  } else {
                                    toast.error(
                                      `Please select Operator Company first.`,
                                      { theme: "colored" }
                                    );
                                  }
                                }}
                                sx={{
                                  fontSize: "30px",
                                  color: "#1976d2",
                                  cursor: "pointer",
                                }}
                              />
                            </Box>
                            <CustomInputField
                              sx={{
                                width:
                                  width <= 600 ? "100%" : "calc(50% - 10px)",
                                "& .MuiFormControl-root": {
                                  width: "100%",
                                  margin: "0px",
                                },
                              }}
                              label="Operator Secondary Contact"
                              value={airportFormData?.secondaryContact ?? ""}
                              changeHandler={(event: any) =>
                                airportFormDataChangeHandler(
                                  "secondaryContact",
                                  event?.target?.value
                                )
                              }
                              disabled={isUpdating || !isSuperUser}
                            />
                            {/* <SelectDropdown
                            sx={{
                              width: "calc(50% - 10px)",
                              "& .MuiFormControl-root": {
                                width: "100%",
                                margin: "0px",
                              },
                            }}
                            label="Operator Secondary Contact"
                            options={state.userList.reduce(
                              (
                                currentUserList: Array<{
                                  name: string;
                                  value: string;
                                }>,
                                user
                              ) => {
                                if (
                                  user.companyId &&
                                  user.companyId === airportFormData?.operatorId
                                ) {
                                  currentUserList.push({
                                    name: `${user.firstName} ${user.lastName}`,
                                    value: user.id,
                                  });
                                }
                                return currentUserList;
                              },
                              []
                            )}
                            selectedValue={
                              airportFormData?.secondaryContactId ?? ""
                            }
                            changeHandler={(event: any) =>
                              airportFormDataChangeHandler(
                                "secondaryContact",
                                event?.target?.value
                              )
                            }
                            disabled={isUpdating || !isSuperUser}
                          /> */}
                            <CustomInputField
                              sx={{
                                width:
                                  width <= 600 ? "100%" : "calc(50% - 10px)",
                                "& .MuiFormControl-root": {
                                  width: "100%",
                                  margin: "0px",
                                },
                              }}
                              label="Facility Owner"
                              value={airportFormData?.facilityOwner ?? ""}
                              changeHandler={(event: any) =>
                                airportFormDataChangeHandler(
                                  "facilityOwner",
                                  event?.target?.value
                                )
                              }
                              disabled={isUpdating || !isSuperUser}
                            />
                            {/* <SelectDropdown
                            sx={{
                              width: "calc(50% - 10px)",
                              "& .MuiFormControl-root": {
                                width: "100%",
                                margin: "0px",
                              },
                            }}
                            label="Facility Owner"
                            options={state.companyList
                              .reduce(
                                (
                                  currentCompanyList: Array<{
                                    name: string;
                                    value: string;
                                  }>,
                                  company
                                ) => {
                                  if (
                                    company.type === CompanyTypeChoice.SUPPLIER
                                  ) {
                                    currentCompanyList.push({
                                      name: company.name,
                                      value: company.id,
                                    });
                                  }
                                  return currentCompanyList;
                                },
                                []
                              )
                              .sort((a, b) => a.name.localeCompare(b.name))}
                            selectedValue={
                              airportFormData?.facilityOwnerId ?? ""
                            }
                            selectHandler={(event: any) =>
                              airportFormDataChangeHandler(
                                "facilityOwnerId",
                                event?.target?.value
                              )
                            }
                            disabled={isUpdating || !isSuperUser}
                          /> */}
                            {/* <CustomInputField
                              sx={{
                                width: "calc(50% - 10px)",
                                "& .MuiFormControl-root": {
                                  width: "100%",
                                  margin: "0px",
                                },
                              }}
                              label="Facility Owner Contact"
                              value={
                                airportFormData?.facilityOwnerContact ?? ""
                              }
                              changeHandler={(event: any) =>
                                airportFormDataChangeHandler(
                                  "facilityOwnerContact",
                                  event?.target?.value
                                )
                              }
                              disabled={isUpdating || !isSuperUser}
                            /> */}
                            {/* <SelectDropdown
                            sx={{
                              width: "calc(50% - 10px)",
                              "& .MuiFormControl-root": {
                                width: "100%",
                                margin: "0px",
                              },
                            }}
                            label="Facility Owner Contact"
                            options={state.userList.reduce(
                              (
                                currentUserList: Array<{
                                  name: string;
                                  value: string;
                                }>,
                                user
                              ) => {
                                if (
                                  user.companyId &&
                                  user.companyId ===
                                    airportFormData?.facilityOwnerId
                                ) {
                                  currentUserList.push({
                                    name: `${user.firstName} ${user.lastName}`,
                                    value: user.id,
                                  });
                                }
                                return currentUserList;
                              },
                              []
                            )}
                            selectedValue={
                              airportFormData?.facilityOwnerContactId ?? ""
                            }
                            selectHandler={(event: any) =>
                              airportFormDataChangeHandler(
                                "facilityOwnerContactId",
                                event?.target?.value
                              )
                            }
                            disabled={isUpdating || !isSuperUser}
                          /> */}
                            <CustomInputField
                              sx={{
                                width:
                                  width <= 600 ? "100%" : "calc(50% - 10px)",
                                "& .MuiFormControl-root": {
                                  width: "100%",
                                  margin: "0px",
                                },
                              }}
                              label="Legal Counsel Company"
                              value={airportFormData?.legalCounsel ?? ""}
                              changeHandler={(event: any) =>
                                airportFormDataChangeHandler(
                                  "legalCounsel",
                                  event?.target?.value
                                )
                              }
                              disabled={isUpdating || !isSuperUser}
                            />
                            {/* <SelectDropdown
                            sx={{
                              width: "calc(50% - 10px)",
                              "& .MuiFormControl-root": {
                                width: "100%",
                                margin: "0px",
                              },
                            }}
                            label="Legal Counsel Company"
                            options={state.companyList
                              .reduce(
                                (
                                  currentCompanyList: Array<{
                                    name: string;
                                    value: string;
                                  }>,
                                  company
                                ) => {
                                  if (
                                    company.type === CompanyTypeChoice.LEGAL
                                  ) {
                                    currentCompanyList.push({
                                      name: company.name,
                                      value: company.id,
                                    });
                                  }
                                  return currentCompanyList;
                                },
                                []
                              )
                              .sort((a, b) => a.name.localeCompare(b.name))}
                            selectedValue={
                              airportFormData?.legalCounselId ?? ""
                            }
                            selectHandler={(event: any) =>
                              airportFormDataChangeHandler(
                                "legalCounselId",
                                event?.target?.value
                              )
                            }
                            disabled={isUpdating || !isSuperUser}
                          /> */}
                            <CustomInputField
                              sx={{
                                width:
                                  width <= 600 ? "100%" : "calc(50% - 10px)",
                                "& .MuiFormControl-root": {
                                  width: "100%",
                                  margin: "0px",
                                },
                              }}
                              label="Legal Counsel Contact"
                              value={airportFormData?.legalCounselContact ?? ""}
                              changeHandler={(event: any) =>
                                airportFormDataChangeHandler(
                                  "legalCounselContact",
                                  event?.target?.value
                                )
                              }
                              disabled={isUpdating || !isSuperUser}
                            />
                            {/* <SelectDropdown
                            sx={{
                              width: "calc(50% - 10px)",
                              "& .MuiFormControl-root": {
                                width: "100%",
                                margin: "0px",
                              },
                            }}
                            label="Legal Counsel Contact"
                            options={state.userList.reduce(
                              (
                                currentUserList: Array<{
                                  name: string;
                                  value: string;
                                }>,
                                user
                              ) => {
                                if (
                                  user.companyId &&
                                  user.companyId ===
                                    airportFormData?.legalCounselId
                                ) {
                                  currentUserList.push({
                                    name: `${user.firstName} ${user.lastName}`,
                                    value: user.id,
                                  });
                                }
                                return currentUserList;
                              },
                              []
                            )}
                            selectedValue={
                              airportFormData?.legalCounselContactId ?? ""
                            }
                            selectHandler={(event: any) =>
                              airportFormDataChangeHandler(
                                "legalCounselContactId",
                                event?.target?.value
                              )
                            }
                            disabled={isUpdating || !isSuperUser}
                          /> */}
                          </Box>
                          <Box sx={{ display: "flex", columnGap: "10px" }}>
                            <Button
                              type="button"
                              size="small"
                              variant="outlined"
                              onClick={handleUpdateAirportConsortium}
                              disabled={isUpdating || !isSuperUser}
                              sx={{ mt: "20px" }}
                            >
                              {isUpdating ? "Updating..." : "Update"}
                            </Button>
                            <Button
                              type="button"
                              size="small"
                              variant="outlined"
                              onClick={() =>
                                handleAirportSelectChange(selectedAirport)
                              }
                              disabled={isUpdating || !isSuperUser}
                              sx={{ mt: "20px" }}
                              color="warning"
                            >
                              Cancel
                            </Button>
                          </Box>
                          {isChairUpdated && (
                            <Alert severity="success">
                              Chair updated for organization.
                            </Alert>
                          )}
                        </>
                      )}
                    {/* {tabsToShow.includes("Operator") && (
                      <CustomTabPanel
                        value={value}
                        index={tabsToShow.indexOf("Operator")}
                      >
                        <Box sx={{ width: "50%" }}>
                          <SelectInput
                            id="operatorPerson"
                            name="operatorPerson"
                            placeholder="Select Operator Person"
                            label="Operator Person"
                            options={companies}
                            error={selectedOperatorError}
                            value={selectedOperator}
                            fullWidth={true}
                            handleChange={(event: any) =>
                              setSelectedOperator(event?.target?.value)
                            }
                            helperText="Add or Update Operator Person"
                            disabled={!isSuperUser}
                          />
                        </Box>
                        <Button
                          type="button"
                          size="small"
                          variant="outlined"
                          onClick={handleUpdateOperator}
                          disabled={!isSuperUser}
                        >
                          Update
                        </Button>
                        {isOperatorUpdated && (
                          <Alert severity="success">
                            Operator updated for organization.
                          </Alert>
                        )}
                      </CustomTabPanel>
                    )} */}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      )}
      {openInviteOrgAdmin && (
        <Sidebar
          handleClose={() => setOpenInviteOrgAdmin(false)}
          open={openInviteOrgAdmin}
        >
          <InviteOrganizationAdmin
            selectedCompany={selectedAirportCompany ?? selectedAirportOperator}
            organization={item}
            closeSidebar={() => setOpenInviteOrgAdmin(false)}
          />
        </Sidebar>
      )}
      {openInviteOrgUser && (
        <Sidebar
          handleClose={() => setOpenInviteOrgUser(false)}
          open={openInviteOrgUser}
        >
          <InviteOrganizationMember
            organization={item}
            closeSidebar={() => setOpenInviteOrgUser(false)}
          />
        </Sidebar>
      )}
      {openAddOrgAirport && (
        <Sidebar
          handleClose={() => setOpenAddOrgAirport(false)}
          open={openAddOrgAirport}
        >
          <AddOrganizationAirport
            closeSidebar={() => setOpenAddOrgAirport(false)}
            existingAirports={item.airports.items ?? []}
            organizationId={item.id}
            setCurrentOrganization={setItem}
          />
        </Sidebar>
      )}
    </>
  );
};

export { OrganizationDetail };
