import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";

class InviteLogSubscription {
  /*
    Task: Get InviteLog subscription on create from amplify graphql
  */
  static getOnCreate() {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateInviteLog)
    ) as any;
  }

  /*
    Task: Get InviteLog subscription on update from amplify graphql
  */
  static getOnUpdate() {
    return API.graphql(
      graphqlOperation(subscriptions.onUpdateInviteLog)
    ) as any;
  }

  /*
    Task: Get InviteLog subscription on delete from amplify graphql
  */
  static getOnDelete() {
    return API.graphql(
      graphqlOperation(subscriptions.onDeleteInviteLog)
    ) as any;
  }
}

export { InviteLogSubscription };
