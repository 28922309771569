import React from "react";
import DataTable, { TableProps } from "react-data-table-component";
import Checkbox from "@mui/material/Checkbox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";

//  Internally, customStyles will deep merges your customStyles with the default styling.
const customStyles = {
  table: {
    style: {
      height: "55vh",
      overflow: "scroll",
    },
  },
  rows: {
    style: {
      fontSize: "18px",
    },
  },
  headCells: {
    style: {
      fontSize: "18px",
    },
  },
  cells: {
    style: {
      fontSize: "18px",
    },
  },
};

const sortIcon = <ArrowDownward />;
const selectProps = {
  indeterminate: (isIndeterminate: boolean) => isIndeterminate,
};

function DataTableBase<T>(props: TableProps<T>): JSX.Element {
  return (
    <DataTable
      pagination
      selectableRowsComponent={Checkbox as any}
      selectableRowsComponentProps={selectProps}
      sortIcon={sortIcon}
      dense
      {...props}
      customStyles={customStyles}
    />
  );
}

export { DataTableBase };
