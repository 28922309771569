import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { ICreateUserParams } from "./types";
import {
  CreateUserMutation,
  ListUsersQuery,
  GetUserQuery,
  DeleteUserMutation,
  UpdateUserMutation,
} from "../../API";
import { CognitoService } from "../CognitoService";
import randomstring from "randomstring";

class UserService {
  /*
    Task: Create a new user using graphql
  */
  async createUser(user: ICreateUserParams) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createUser,
        variables: { input: user },
      })) as { data: CreateUserMutation; errors: any[] };

      // send back newly created User details
      return temp.data.createUser;
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  /*
    Task: get User by id using graphql
  */
  async getUserById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: queries.getUser,
        variables: { id },
      })) as { data: GetUserQuery; errors: any[] };

      // send back User details
      return temp.data.getUser;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: delete User by id using graphql
  */
  async deleteUserById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteUser,
        variables: { input: { id } },
      })) as { data: DeleteUserMutation; errors: any[] };

      // send back User details
      return temp.data.deleteUser;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: update User by id using graphql
  */
  async updateUserById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateUser,
        variables: { input },
      })) as { data: UpdateUserMutation; errors: any[] };

      // send back technology details
      return temp.data.updateUser;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: list all User using graphql
  */
  async getAllUsers(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listUsers
  ) {
    try {
      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListUsersQuery; errors: any[] };

      if (temp.data.listUsers?.items) {
        // send back all User details
        return {
          users: temp.data.listUsers?.items,
          nextToken: temp.data.listUsers?.nextToken,
        };
      }

      return { users: [], nextToken: null };
    } catch (error) {
      return { users: [], nextToken: null };
    }
  }

  /*
    Task: Sync user data with cognito user pool
  */
  async syncWithCognito() {
    // Get list of cognito user
    const cognitoService = new CognitoService();

    const cognitoServiceData: any = await cognitoService.getUserList();

    const users = await this.recursiveFetchListUser();

    await Promise.all(
      cognitoServiceData?.Users?.map(async (user: any) => {
        const cognitoId = user?.Username;

        const email = user?.Attributes?.filter(
          (item: any) => item?.Name === "email"
        )[0]["Value"];

        const alreadyExists =
          users?.filter((data: any) => data?.cognitoUserId === cognitoId)
            .length !== 0;

        if (!alreadyExists) {
          // create new User in DynamoDB database
          const userParams = {
            cognitoUserId: cognitoId,
            email: email,
            firstName: randomstring.generate(5),
            lastName: randomstring.generate(5),
          };

          // create new user in database
          await this.createUser(userParams);
        }
      })
    );

    return true;
  }

  /*
    Task: get user by email using graphql
  */
  async getUserByEmail(
    email: string,
    reqNextToken: string | null = null
  ): Promise<any> {
    try {
      // create filter
      const filter = {
        email: { eq: email },
        deleted: { ne: true },
      };

      // send grapql api call
      const temp: any = (await API.graphql(
        graphqlOperation(queries.listUsers, {
          filter,
          nextToken: reqNextToken,
        })
      )) as { data: ListUsersQuery; errors: any[] };

      const isUserArrayEmpty = temp.data.listUsers?.items?.length === 0;
      const nextToken = temp.data.listUsers?.nextToken;

      if (isUserArrayEmpty && nextToken) {
        return await this.getUserByEmail(email, nextToken);
      } else {
        if (temp.data.listUsers?.items?.length === 0) {
          return null;
        } else {
          // send back User details
          return temp.data.listUsers?.items[0];
        }
      }
    } catch (error) {
      return null;
    }
  }

  /*
    Task: recursively list all user using graphql
  */
  async recursiveFetchListUser(
    filter: any = {},
    currentUser: Array<any> = [],
    reqNextToken: string | null = null,
    query_src = queries.listUsers
  ): Promise<any> {
    const { users, nextToken } = await this.getAllUsers(
      filter,
      reqNextToken,
      query_src
    );

    // merge old and new data
    let allData = [...currentUser, ...users];

    if (nextToken) {
      return await this.recursiveFetchListUser(
        filter,
        allData,
        nextToken,
        query_src
      );
    } else {
      if (allData?.length) {
        return allData;
      }

      return [];
    }
  }
}

export { UserService };
