import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";

class DocumentSubscription {
  /*
    Task: Get Document subscription on create from amplify graphql
  */
  static getOnCreate() {
    return API.graphql(graphqlOperation(subscriptions.onCreateDocument)) as any;
  }

  /*
    Task: Get Document subscription on update from amplify graphql
  */
  static getOnUpdate() {
    return API.graphql(graphqlOperation(subscriptions.onUpdateDocument)) as any;
  }

  /*
    Task: Get Document subscription on delete from amplify graphql
  */
  static getOnDelete() {
    return API.graphql(graphqlOperation(subscriptions.onDeleteDocument)) as any;
  }
}

export { DocumentSubscription };
