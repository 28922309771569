import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { CardContent, Typography, Button } from "@mui/material";
import { Sidebar } from "../Common/Sidebar";
import { constants } from "../../utils";
import { useNavigate, Link } from "react-router-dom";
import { AddCompany } from "../AddCompany";
import * as SkeletonLoader from "../SkeletonLoader";
import { useAuth } from "../AuthProvider";
import { useMapContext } from "../MapProvider/MapProvider";
import { AddCompanyMember } from "../AddCompanyMember";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
import { DataTableBase } from "./../Common/DataTableBase";

interface ILoading {
  data: boolean;
  submit: boolean;
}

interface IGetGridView {
  filteredUserList: Array<any>;
}

interface IGetListView {
  filteredUserList: Array<any>;
  tableTitle: string;
}

const GetGridView: React.FC<IGetGridView> = ({ filteredUserList = [] }) => {
  const navigate = useNavigate();
  return (
    <Grid sx={{ mt: 2 }} container>
      {filteredUserList.map((itm: any, idx: number) => (
        <Grid sm={4} md={4} key={`itm_${idx}`} item>
          <Box
            sx={{
              m: 1,
              p: 2,
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
            component={Paper}
            elevation={2}
            onClick={() => navigate(`/company/${itm?.company?.id}`)}
          >
            <Box>
              <h5
                style={{
                  margin: 0,
                  padding: 0,
                }}
              >
                {itm?.firstName} {itm?.lastName}
              </h5>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                }}
              >
                {itm?.email}
              </p>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                }}
              >
                {itm?.company?.name}
              </p>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

const GetMemomizedListView: React.FC<IGetListView> = React.memo(
  ({ filteredUserList = [], tableTitle = "Table Data" }) => {
    const columns = [
      {
        name: "LastName",
        selector: (row: any) => row?.lastName,
        sortable: true,
      },
      {
        name: "FirstName",
        selector: (row: any) => row?.firstName,
        sortable: true,
      },
      {
        name: "Company",
        selector: (row: any) => row?.company,
        sortable: true,
        cell: (row: any) => (
          <Link to={`/company/${row?.companyId}`} title={row?.company}>
            {row?.company}
          </Link>
        ),
      },
      {
        name: "Email",
        selector: (row: any) => row?.email,
        sortable: true,
      },
      {
        name: "Mobile",
        selector: (row: any) => row?.mobile,
        sortable: true,
      },
    ];

    let tableData: Array<any> = filteredUserList?.map((item) => ({
      id: item?.id,
      firstName: item?.firstName,
      lastName: item?.lastName,
      company: item?.company?.name,
      mobile: item?.mobile,
      email: item?.email,
      companyId: item?.company?.id,
    }));

    // sort user data
    tableData.sort((itemA: any, itemB: any) => {
      if (itemA.lastName < itemB.lastName) {
        return -1;
      }
      if (itemA.lastName > itemB.lastName) {
        return 1;
      }
      return 0;
    });

    return (
      <DataTableBase
        columns={columns}
        data={tableData}
        dense={false}
        fixedHeader
        pagination={false}
        persistTableHead
        highlightOnHover
      />
    );
  }
);

const ViewCompany = () => {
  const { state } = useMapContext();
  // state
  const [openAddCompany, setOpenAddCompany] = useState<boolean>(false);
  const [openAddCompanyMember, setOpenAddCompanyMember] =
    useState<boolean>(false);

  const [loadingState, setLoadingState] = React.useState<ILoading>({
    data: false,
    submit: false,
  });
  // show data as List or Grid Item
  const [showDataAs, setShowDataAs] = React.useState<string>("list");
  const [isSuperUser, setSuperUser] = React.useState<boolean>(false);
  const [filteredUserList, setFilteredUserList] = React.useState<any>([]);

  const { state: auth } = useAuth();
  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.COMPANY;

    // is signed in user A4A Admin
    if (auth?.user?.groups.includes(constants.ROLES.A4A_ADMIN)) {
      setSuperUser(true);
    }

    // clear global search bar text on page load
    // dataProviderDispatch({
    //   type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
    //   payload: "",
    // });

    // set global search bar placeholder text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT,
      payload: "Search contacts...",
    });

    // cleanup
    return () => {};
  }, []);

  useEffect(() => {
    // change page title
    if (openAddCompany) {
      document.title = constants.PAGE_TITLE.ADD_COMPANY;
    } else {
      document.title = constants.PAGE_TITLE.COMPANY;
    }
  }, [openAddCompany]);

  // set user list from Map Provider state
  useEffect(() => {
    setFilteredUserList([...state?.userList]);
  }, [state?.userList]);

  useEffect(() => {
    let timeoutId: any = null;

    let searchText = dataProviderState?.topBarSearchText;

    if (searchText.trim() !== "") {
      timeoutId = setTimeout(() => {
        const generalFilter = state?.userList?.filter(
          (item: any) =>
            `${item?.firstName} ${item?.lastName}`
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
            item?.company?.name
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            item?.mobile?.toLowerCase().includes(searchText.toLowerCase())
        );

        setFilteredUserList([...generalFilter]);
      }, 1000);
    } else {
      // udpate user list
      setFilteredUserList([...state?.userList]);
    }

    return () => clearTimeout(timeoutId);
  }, [dataProviderState?.topBarSearchText]);

  return (
    <>
      <Grid sx={{ my: 2 }}>
        <Card
          style={{
            padding: "20px 5px",
            margin: "0 auto",
            borderRadius: "16px",
            background: "rgb(96, 96, 96, 0.05)",
            minHeight: "75vh",
          }}
        >
          <CardContent>
            <Typography variant="h6">Contacts</Typography>
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                float: "right",
                mb: 2,
              }}
              onClick={() => setOpenAddCompany((prev) => !prev)}
              disabled={!isSuperUser}
            >
              New
            </Button>
            {loadingState.data && <SkeletonLoader.ViewCompany />}
            {!loadingState.data && showDataAs === "tab" && (
              <GetGridView filteredUserList={filteredUserList} />
            )}
            {!loadingState.data && showDataAs === "list" && (
              <GetMemomizedListView
                filteredUserList={filteredUserList}
                tableTitle="Contacts"
              />
            )}
          </CardContent>
        </Card>
      </Grid>
      {openAddCompany && (
        <Sidebar
          handleClose={() => setOpenAddCompany(false)}
          open={openAddCompany}
        >
          <AddCompany closeSidebar={() => setOpenAddCompany(false)} />
        </Sidebar>
      )}
      {openAddCompanyMember && (
        <Sidebar
          handleClose={() => setOpenAddCompanyMember(false)}
          open={openAddCompanyMember}
        >
          <AddCompanyMember
            closeSidebar={() => setOpenAddCompanyMember(false)}
            existingMembers={[]}
            companyId=""
          />
        </Sidebar>
      )}
    </>
  );
};

export { ViewCompany };
