import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as customQueries from "../../graphql/customQueries";
import * as mutations from "../../graphql/mutations";
import { ICreateCompanyParams } from "./types";
import {
  CreateCompanyMutation,
  ListCompaniesQuery,
  GetCompanyQuery,
  DeleteCompanyMutation,
  UpdateCompanyMutation,
} from "../../API";

class CompanyService {
  /*
    Task: Create a new Company using graphql
  */
  async createCompany(Company: ICreateCompanyParams) {
    try {
      // send grapql api call
      const temp: any = (await API.graphql({
        query: mutations.createCompany,
        variables: { input: Company },
      })) as { data: CreateCompanyMutation; errors: any[] };

      // send back newly created Company details
      return temp.data.createCompany;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: get Company by id using graphql
  */
  async getCompanyById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: customQueries.customGetCompany,
        variables: { id },
      })) as { data: GetCompanyQuery; errors: any[] };

      // send back Company details
      return temp.data.getCompany;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: delete Company by id using graphql
  */
  async deleteCompanyById(id: string) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.deleteCompany,
        variables: { input: { id } },
      })) as { data: DeleteCompanyMutation; errors: any[] };

      // send back Company details
      return temp.data.deleteCompany;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: update Company by id using graphql
  */
  async updateCompanyById(input: any) {
    try {
      // send grapql api call
      const temp = (await API.graphql({
        query: mutations.updateCompany,
        variables: { input },
      })) as { data: UpdateCompanyMutation; errors: any[] };

      // send back technology details
      return temp.data.updateCompany;
    } catch (error) {
      return null;
    }
  }

  /*
    Task: list all Company using graphql
  */
  async getAllCompanies(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listCompanies
  ) {
    try {
      // send grapql api call
      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListCompaniesQuery; errors: any[] };

      if (temp.data.listCompanies?.items) {
        // send back all Company details
        return {
          companies: temp.data.listCompanies?.items,
          nextToken: temp.data.listCompanies?.nextToken,
        };
      }
      return { companies: [], nextToken: null };
    } catch (error) {
      return { companies: [], nextToken: null };
    }
  }

  /*
    Task: recursively list all companies using graphql
  */
  async recursiveFetchListCompany(
    filter: any = {},
    currentCompanies: Array<any> = [],
    reqNextToken: string | null = null,
    query_src = queries.listCompanies
  ): Promise<any> {
    const { companies, nextToken } = await this.getAllCompanies(
      filter,
      reqNextToken,
      query_src
    );

    // merge old and new data
    let allData = [...currentCompanies, ...companies];

    if (nextToken) {
      return await this.recursiveFetchListCompany(
        filter,
        allData,
        nextToken,
        query_src
      );
    } else {
      if (allData?.length) {
        return allData;
      }

      return [];
    }
  }
}

export { CompanyService };
