import { API, Auth, graphqlOperation } from "aws-amplify";

import {
  IAirport,
  IAirportDetail,
  IAirportDocument,
  IAirportVolume,
  ICompany,
  IAirportConsortiumGroup,
  IDeliveryToFacility,
  IDeliveryToPlane,
  IStorage,
  IUser,
} from "../../components/MapProvider/types";
import { airports, airportDetail } from "./mocks";
import * as mutations from "../../graphql/mutations";
import * as customQueries from "../../graphql/customQueries";
import * as queries from "../../graphql/queries";
import {
  CustomListAirportVolumesQuery,
  CustomListAirportsQuery,
  CustomListCompaniesQuery,
  CustomListUsersQuery,
} from "../../CustomAPI";
import {
  AirportVolume,
  CreateAirportConsortiumGroupMutation,
  CreateAirportVolumeMutation,
  CreateDeliveryToFacilityMutation,
  CreateDeliveryToPlaneMutation,
  CreateStorageMutation,
  DeleteAirportVolumeMutation,
  DeleteDeliveryToFacilityMutation,
  DeleteDeliveryToPlaneMutation,
  DeleteStorageMutation,
  GetAirportQuery,
  ListAirportVolumesQuery,
  ListOrgAdminUsersQuery,
  ListOrgMemberUsersQuery,
  OrganizationTypeChoice,
  UpdateAirportConsortiumGroupMutation,
  UpdateAirportMutation,
  UpdateAirportVolumeMutation,
  UpdateDeliveryToFacilityMutation,
  UpdateDeliveryToPlaneMutation,
  UpdateStorageMutation,
  VolumeByAirportidAndYearQuery,
} from "../../API";
import { filterObjectByKeys } from "../../utils/helpers";
import { constants } from "../../utils";
import { OrgAdminUserService } from "./../OrgAdminUserService";
import { OrgMemberUserService } from "./../OrgMemberUserService";

const __mock = false;

interface IListAirportsResponse {
  airports: IAirport[];
  nextToken: string | null;
}

interface IListAirportVolumesResponse {
  airportVolumes: IAirportVolume[];
  nextToken: string | null;
}

interface IListCompaniesResponse {
  companies: ICompany[];
  nextToken: string | null;
}

interface IListCompaniesResponse {
  companies: ICompany[];
  nextToken: string | null;
}

interface IListUsersResponse {
  users: IUser[];
  nextToken: string | null;
}

interface IListAirportDocumentsResponse {
  documents: IAirportDocument[];
  nextToken: string | null;
}

export interface IUserOrganizationRole {
  organizationId: string;
  organizationType: OrganizationTypeChoice | null;
  roles: (keyof typeof constants.ROLES)[];
}

const listAirports = async (
  token: string,
  nextToken: string | null
): Promise<IListAirportsResponse> => {
  if (__mock) {
    return { nextToken: null, airports };
  }
  try {
    // filter for deleted or deactivated airport
    // const filter = {
    //   deleted: { ne: true }
    // }

    const response = (await API.graphql({
      query: customQueries.customListAirports,
      variables: {
        nextToken,
        // filter
      },
    })) as { data: CustomListAirportsQuery; errors: any[] };
    if (response.data.listAirports?.items) {
      return {
        airports: response.data.listAirports?.items as IAirport[],
        nextToken: response.data.listAirports?.nextToken ?? null,
      };
    }
    return { nextToken: null, airports: [] };
  } catch (error) {
    console.log(error);
    return { nextToken: null, airports: [] };
  }
};

const listAirportVolumes = async (
  token: string,
  nextToken: string | null
): Promise<IListAirportVolumesResponse> => {
  if (__mock) {
    return { nextToken: null, airportVolumes: [] };
  }
  try {
    const response = (await API.graphql({
      query: customQueries.customListAirportVolumes,
      variables: {
        nextToken,
      },
      // authMode: 'AMAZON_COGNITO_USER_POOLS',
      // authToken: token,
    })) as { data: CustomListAirportVolumesQuery; errors: any[] };
    if (response.data.listAirportVolumes?.items) {
      return {
        airportVolumes: response.data.listAirportVolumes
          ?.items as IAirportVolume[],
        nextToken: response.data.listAirportVolumes?.nextToken ?? null,
      };
    }

    return { nextToken: null, airportVolumes: [] };
  } catch (error) {
    console.log(error);
    return { nextToken: null, airportVolumes: [] };
  }
};

const getAirport = async (id: string): Promise<IAirportDetail | null> => {
  if (__mock) {
    return airportDetail;
  }
  try {
    const response = (await API.graphql({
      query: customQueries.customGetAirport,
      variables: { id },
    })) as { data: GetAirportQuery; errors: any[] };
    return (response.data.getAirport as IAirportDetail) ?? null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const updateAirport = async (
  id: string,
  updatedFields: Partial<IAirportDetail>
): Promise<Partial<IAirportDetail> | string> => {
  const inputVariables = {
    id,
    ...updatedFields,
  };
  try {
    const response = (await API.graphql({
      query: mutations.updateAirport,
      variables: { input: inputVariables },
    })) as { data: UpdateAirportMutation; errors: any[] };
    return filterObjectByKeys(
      {
        ...updatedFields,
      },
      response.data.updateAirport
    );
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while updating information."
    );
  }
};

const listCompanies = async (
  nextToken: string | null
): Promise<IListCompaniesResponse> => {
  try {
    const response = (await API.graphql({
      query: customQueries.customListCompanies,
      variables: {
        nextToken,
      },
    })) as { data: CustomListCompaniesQuery; errors: any[] };
    if (response.data.listCompanies?.items) {
      return {
        companies: response.data.listCompanies?.items as ICompany[],
        nextToken: response.data.listCompanies?.nextToken ?? null,
      };
    }

    return { nextToken: null, companies: [] };
  } catch (error) {
    console.log(error);
    return { nextToken: null, companies: [] };
  }
};

const listUsers = async (
  nextToken: string | null,
  filter: any = {}
): Promise<IListUsersResponse> => {
  try {
    const response = (await API.graphql(
      graphqlOperation(customQueries.customListUsers, {
        filter,
        nextToken,
      })
    )) as { data: CustomListUsersQuery; errors: any[] };
    if (response.data.listUsers?.items) {
      return {
        users: response.data.listUsers?.items as IUser[],
        nextToken: response.data.listUsers?.nextToken ?? null,
      };
    }

    return { nextToken: null, users: [] };
  } catch (error) {
    console.log(error);
    return { nextToken: null, users: [] };
  }
};

const createDeliveryToAirport = async (
  input: Partial<IDeliveryToPlane>
): Promise<Partial<IDeliveryToPlane> | string> => {
  try {
    const response = (await API.graphql({
      query: mutations.createDeliveryToPlane,
      variables: { input },
    })) as { data: CreateDeliveryToPlaneMutation; errors: any[] };
    if (response.data.createDeliveryToPlane) {
      return response.data.createDeliveryToPlane as IDeliveryToPlane;
    } else {
      return response.errors[0];
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while adding delivery to plane."
    );
  }
};

const updateDeliveryToAirport = async (
  id: string,
  updatedFields: Partial<IDeliveryToPlane>
): Promise<Partial<IDeliveryToPlane> | string> => {
  const inputVariables = {
    id,
    ...updatedFields,
  };
  try {
    const response = (await API.graphql({
      query: mutations.updateDeliveryToPlane,
      variables: { input: inputVariables },
    })) as { data: UpdateDeliveryToPlaneMutation; errors: any[] };
    if (response.data.updateDeliveryToPlane) {
      return response.data.updateDeliveryToPlane;
    } else {
      return "Nothing to Update";
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while updating information."
    );
  }
};

const deleteDeliveryToPlane = async (
  id: string
): Promise<{ type: "success" | "error"; message: string }> => {
  try {
    const response = (await API.graphql({
      query: mutations.deleteDeliveryToPlane,
      variables: { input: { id } },
    })) as { data: DeleteDeliveryToPlaneMutation; errors: any[] };
    if (response.data.deleteDeliveryToPlane) {
      return {
        type: "success",
        message: "Deleted delivery to plane entry successfully.",
      };
    } else {
      return { type: "error", message: response.errors[0] };
    }
  } catch (error) {
    let errResponse = error as any;
    return {
      type: "error",
      message:
        errResponse?.errors?.[0]?.message ??
        "An error occured while deleting delivery to plane.",
    };
  }
};

const createDeliveryToFacility = async (
  input: Partial<IDeliveryToFacility>
): Promise<Partial<IDeliveryToFacility> | string> => {
  try {
    const response = (await API.graphql({
      query: mutations.createDeliveryToFacility,
      variables: { input },
    })) as { data: CreateDeliveryToFacilityMutation; errors: any[] };
    if (response.data.createDeliveryToFacility) {
      return response.data.createDeliveryToFacility as IDeliveryToFacility;
    } else {
      return response.errors[0];
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while adding delivery to facility."
    );
  }
};

const updateDeliveryToFacility = async (
  id: string,
  updatedFields: Partial<IDeliveryToFacility>
): Promise<Partial<IDeliveryToFacility> | string> => {
  const inputVariables = {
    id,
    ...updatedFields,
  };
  try {
    const response = (await API.graphql({
      query: mutations.updateDeliveryToFacility,
      variables: { input: inputVariables },
    })) as { data: UpdateDeliveryToFacilityMutation; errors: any[] };
    if (response.data.updateDeliveryToFacility) {
      return response.data.updateDeliveryToFacility;
    } else {
      return "Nothing to Update";
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while updating information."
    );
  }
};

const deleteDeliveryToFacility = async (
  id: string
): Promise<{ type: "success" | "error"; message: string }> => {
  try {
    const response = (await API.graphql({
      query: mutations.deleteDeliveryToFacility,
      variables: { input: { id } },
    })) as { data: DeleteDeliveryToFacilityMutation; errors: any[] };
    if (response.data.deleteDeliveryToFacility) {
      return {
        type: "success",
        message: "Deleted delivery to facility entry successfully.",
      };
    } else {
      return { type: "error", message: response.errors[0] };
    }
  } catch (error) {
    let errResponse = error as any;
    return {
      type: "error",
      message:
        errResponse?.errors?.[0]?.message ??
        "An error occured while deleting delivery to facility entry.",
    };
  }
};

const createStorage = async (
  input: Partial<IStorage>
): Promise<Partial<IStorage> | string> => {
  try {
    const response = (await API.graphql({
      query: mutations.createStorage,
      variables: { input },
    })) as { data: CreateStorageMutation; errors: any[] };
    if (response.data.createStorage) {
      return response.data.createStorage as IStorage;
    } else {
      return response.errors[0];
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while adding storage."
    );
  }
};

const updateStorage = async (
  id: string,
  updatedFields: Partial<IStorage>
): Promise<Partial<IStorage> | string> => {
  const inputVariables = {
    id,
    ...updatedFields,
  };
  try {
    const response = (await API.graphql({
      query: mutations.updateStorage,
      variables: { input: inputVariables },
    })) as { data: UpdateStorageMutation; errors: any[] };
    if (response.data.updateStorage) {
      return response.data.updateStorage;
    } else {
      return "Nothing to Update";
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while updating information."
    );
  }
};

const deleteStorage = async (
  id: string
): Promise<{ type: "success" | "error"; message: string }> => {
  try {
    const response = (await API.graphql({
      query: mutations.deleteStorage,
      variables: { input: { id } },
    })) as { data: DeleteStorageMutation; errors: any[] };
    if (response.data.deleteStorage) {
      return {
        type: "success",
        message: "Deleted storage entry successfully.",
      };
    } else {
      return { type: "error", message: response.errors[0] };
    }
  } catch (error) {
    let errResponse = error as any;
    return {
      type: "error",
      message:
        errResponse?.errors?.[0]?.message ??
        "An error occured while deleting storage entry.",
    };
  }
};

const updateAirportConsortiumGroup = async (
  id: string,
  updatedFields: Partial<IAirportConsortiumGroup>
): Promise<Partial<IAirportConsortiumGroup> | string> => {
  const inputVariables = {
    id,
    ...updatedFields,
  };
  try {
    const response = (await API.graphql({
      query: mutations.updateAirportConsortiumGroup,
      variables: { input: inputVariables },
    })) as { data: UpdateAirportConsortiumGroupMutation; errors: any[] };
    return filterObjectByKeys(
      {
        ...updatedFields,
        chair: {},
        chairContact: {},
        operator: {},
        operatorGeneralManager: {},
        facilityOwner: {},
        facilityOwnerContact: {},
        legalCounsel: {},
        secondaryContact: {},
      },
      response.data.updateAirportConsortiumGroup
    );
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while updating information."
    );
  }
};

const createAirportConsortiumGroup = async (
  input: Partial<IAirportConsortiumGroup>
): Promise<Partial<IAirportConsortiumGroup> | string> => {
  try {
    const response = (await API.graphql({
      query: mutations.createAirportConsortiumGroup,
      variables: { input },
    })) as { data: CreateAirportConsortiumGroupMutation; errors: any[] };
    if (response.data.createAirportConsortiumGroup) {
      return response.data
        .createAirportConsortiumGroup as IAirportConsortiumGroup;
    } else {
      return response.errors[0];
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while adding consortium."
    );
  }
};

const getUserOrganizationRoles = async (
  userId: string
): Promise<IUserOrganizationRole[]> => {
  const userOrganizationRoles: IUserOrganizationRole[] = [];
  const apiOrgAdminUsers = new OrgAdminUserService();
  const apiOrgMemberUsers = new OrgMemberUserService();
  try {
    const adminUsersResponse =
      await apiOrgAdminUsers.recursiveFetchListOrgAdminUsers({
        userId: { eq: userId },
      });

    // const adminUsersResponse = (await API.graphql({
    //   query: queries.listOrgAdminUsers,
    //   variables: {
    //     filter: { userId: { eq: userId } },
    //   },
    // })) as { data: ListOrgAdminUsersQuery; errors: any[] };

    // set organization id
    // adminUsersResponse.data.listOrgAdminUsers?.items?
    if (adminUsersResponse.length !== 0) {
      adminUsersResponse.forEach((itm: any) =>
        userOrganizationRoles.push({
          organizationId: itm?.organizationId ?? "",
          organizationType: itm?.organization?.organizationType ?? null,
          roles: [constants.ROLES.ORG_ADMIN as keyof typeof constants.ROLES],
        })
      );
    }

    const memberUsersResponse =
      await apiOrgMemberUsers.recursiveFetchListOrgMemberUsers({
        userId: { eq: userId },
      });

    // const memberUsersResponse = (await API.graphql({
    //   query: queries.listOrgMemberUsers,
    //   variables: {
    //     filter: { userId: { eq: userId } },
    //   },
    // })) as { data: ListOrgMemberUsersQuery; errors: any[] };

    // set organization id
    // memberUsersResponse.data.listOrgMemberUsers?.items
    if (memberUsersResponse.length !== 0) {
      memberUsersResponse.forEach((itm: any) => {
        const adminOrgIndex = userOrganizationRoles.findIndex(
          (organizationRole) =>
            itm?.organizationId === organizationRole.organizationId
        );
        if (adminOrgIndex !== -1) {
          userOrganizationRoles[adminOrgIndex].roles.push(
            constants.ROLES.ORG_MEMBER as keyof typeof constants.ROLES
          );
        } else {
          userOrganizationRoles.push({
            organizationId: itm?.organizationId ?? "",
            organizationType: itm?.organization?.organizationType ?? null,
            roles: [constants.ROLES.ORG_MEMBER as keyof typeof constants.ROLES],
          });
        }
      });
    }
  } catch (error) {
    let errResponse = error as any;
    console.error(
      errResponse?.errors?.[0]?.message ??
        "An error occured while getting user organization roles."
    );
  } finally {
    return userOrganizationRoles;
  }
};

const findAndGetAirportVolumeByCodeYear = async (
  airportId: string,
  year: string
): Promise<AirportVolume | null> => {
  try {
    const response = (await API.graphql({
      query: queries.volumeByAirportidAndYear,
      variables: {
        airportId,
        year: { eq: year },
      },
    })) as { data: VolumeByAirportidAndYearQuery; errors: any[] };
    if (response.data.volumeByAirportidAndYear?.items.length) {
      return response.data.volumeByAirportidAndYear?.items[0];
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};

const createAirportVolume = async (
  input: Partial<AirportVolume>
): Promise<Partial<AirportVolume> | string> => {
  try {
    const response = (await API.graphql({
      query: mutations.createAirportVolume,
      variables: { input },
    })) as { data: CreateAirportVolumeMutation; errors: any[] };
    if (response.data.createAirportVolume) {
      return response.data.createAirportVolume as AirportVolume;
    } else {
      return response.errors[0];
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while adding volume."
    );
  }
};

const updateAirportVolume = async (
  id: string,
  updatedFields: Partial<AirportVolume>
): Promise<Partial<AirportVolume> | string> => {
  const inputVariables = {
    id,
    ...updatedFields,
  };
  try {
    const response = (await API.graphql({
      query: mutations.updateAirportVolume,
      variables: { input: inputVariables },
    })) as { data: UpdateAirportVolumeMutation; errors: any[] };
    if (response.data.updateAirportVolume) {
      return response.data.updateAirportVolume as AirportVolume;
    } else {
      return "Nothing to Update";
    }
  } catch (error) {
    let errResponse = error as any;
    return (
      errResponse?.errors?.[0]?.message ??
      "An error occured while updating volume."
    );
  }
};

const deleteAirportVolume = async (
  id: string
): Promise<{ type: "success" | "error"; message: string }> => {
  try {
    const response = (await API.graphql({
      query: mutations.deleteAirportVolume,
      variables: { input: { id } },
    })) as { data: DeleteAirportVolumeMutation; errors: any[] };
    if (response.data.deleteAirportVolume) {
      return {
        type: "success",
        message: "Deleted airport volume successfully.",
      };
    } else {
      return { type: "error", message: response.errors[0] };
    }
  } catch (error) {
    let errResponse = error as any;
    return {
      type: "error",
      message:
        errResponse?.errors?.[0]?.message ??
        "An error occured while deleting airport volume.",
    };
  }
};

export default {
  listAirports,
  getAirport,
  listAirportVolumes,
  updateAirport,
  listCompanies,
  listUsers,
  createDeliveryToAirport,
  updateDeliveryToAirport,
  deleteDeliveryToPlane,
  createDeliveryToFacility,
  updateDeliveryToFacility,
  deleteDeliveryToFacility,
  createStorage,
  updateStorage,
  deleteStorage,
  updateAirportConsortiumGroup,
  createAirportConsortiumGroup,
  getUserOrganizationRoles,
  findAndGetAirportVolumeByCodeYear,
  updateAirportVolume,
  createAirportVolume,
  deleteAirportVolume,
};
