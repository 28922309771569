import { FC } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import { Home } from "../components/Home";
import { SignIn } from "../components/SignIn";
import { SignUp } from "../components/SignUp";
import { Dashboard } from "../components/Dashboard";
import { UserAdministration } from "../components/UserAdministration";
import { ViewOrganization } from "../components/ViewOrganization";
// import { AcceptOrganizationInvite } from "../components/AcceptOrganizationInvite";
import { OrganizationDetail } from "../components/OrganizationDetail";
import { AuthVerify } from "../components/AuthVerify";
import { ViewCompany } from "../components/ViewCompany";
import { CompanyDetail } from "../components/CompanyDetail";
import { ViewDocument } from "../components/ViewDocument";
import { DocumentDetail } from "../components/DocumentDetail";
import { ViewDocumentType } from "../components/ViewDocumentType";
import { ViewDocumentCategory } from "../components/ViewDocumentCategory";
import { ViewDocumentByCategory } from "../components/ViewDocumentByCategory";
import { ResetPassword } from "../components/ResetPassword";
import { ChangePassword } from "../components/ChangePassword";
import { ViewAirport } from "../components/ViewAirport";
import { AirportDetail } from "../components/AirportDetail";
import { ViewCalendar } from "./../components/ViewCalendar";
import { AcceptAllOrganizationInvite } from "./../components/AcceptAllOrganizationInvite";
import { ViewExportData } from "./../components/ViewExportData";

import { RequireAuth } from "../components/RequireAuth";

const AppRoutes: FC<any> = () => {
  const withAuth = (Component: React.ReactNode) => (
    <RequireAuth>{Component}</RequireAuth>
  );

  // STOP Auth Guard
  // const withAuth = (Component: React.ReactNode) => Component;

  return (
    <Routes>
      <Route path="/" element={<Home content={<SignIn />} />} />
      <Route path="/auth/in" element={<Home content={<SignIn />} />} />
      {/* <Route path="/auth/new" element={<Home content={<SignUp />} />} /> */}
      <Route
        path="/auth/reset"
        element={<Home content={<ResetPassword />} />}
      />
      <Route path="/auth/verify" element={<Home content={<AuthVerify />} />} />
      <Route
        path="/auth/password"
        element={<Dashboard children={withAuth(<ChangePassword />)} />}
      />
      {/* <Route
        path="/invite/accept/org"
        element={<Home content={<AcceptOrganizationInvite />} />}
      /> */}
      <Route
        path="/invite/accept/all"
        element={<Home content={<AcceptAllOrganizationInvite />} />}
      />
      <Route path="/main" element={withAuth(<Dashboard />)} />
      <Route
        path="/map/list"
        element={withAuth(<Dashboard isListView={true} />)}
      />
      <Route
        path="/volumes"
        element={withAuth(<Dashboard isVolumesMap={true} />)}
      />
      <Route
        path="/user_adm"
        element={<Dashboard children={withAuth(<UserAdministration />)} />}
      />
      <Route
        path="/org"
        element={<Dashboard children={withAuth(<ViewOrganization />)} />}
      />
      <Route
        path="/org/:orgId"
        element={<Dashboard children={withAuth(<OrganizationDetail />)} />}
      />
      <Route
        path="/company"
        element={<Dashboard children={withAuth(<ViewCompany />)} />}
      />
      <Route
        path="/company/:companyId"
        element={<Dashboard children={withAuth(<CompanyDetail />)} />}
      />
      <Route
        path="/doc"
        element={withAuth(<Dashboard children={<ViewDocument />} />)}
      />
      {/* <Route
        path="/doc/:documentId"
        element={withAuth(<Dashboard children={<DocumentDetail />} />)}
      /> */}
      {/* <Route
        path="/doc/type"
        element={withAuth(<Dashboard children={<ViewDocumentType />} />)}
      /> */}
      <Route
        path="/doc/category/:docCategory"
        element={withAuth(<Dashboard children={<ViewDocumentByCategory />} />)}
      />
      <Route
        path="/airport"
        element={withAuth(<Dashboard children={<ViewAirport />} />)}
      />
      <Route
        path="/airport/:airportId"
        element={withAuth(<Dashboard children={<AirportDetail />} />)}
      />
      <Route
        path="/calendar"
        element={withAuth(<Dashboard children={<ViewCalendar />} />)}
      />
      {/* <Route
        path="/document-categories"
        element={withAuth(<Dashboard children={<ViewDocumentCategory />} />)}
      /> */}
      <Route
        path="/data-export"
        element={withAuth(<Dashboard children={<ViewExportData />} />)}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export { AppRoutes };
