import React, { createContext, useEffect, useReducer } from "react";
import { ICalendarContext, ICalendarProvider, ICalendarState } from "./types";
import { useAuth } from "../AuthProvider";
import { Calendar, CalendarEventType } from "../../API";
import CalendarService from "../../services/CalendarService";

const calendarContextAction = {
  SET_CALENDAR_LIST: "SET_CALENDAR_LIST",
  SET_CALENDAR_TYPE_LIST: "SET_CALENDAR_TYPE_LIST",
  REINITIALIZE_STATE: "REINITIALIZE_STATE",
};

type Action = { type: string; payload: any };

const initialCalendarState: ICalendarState = {
  calendarList: [],
  calendarTypeList: [],
};

function mapReducer(state: ICalendarState, action: Action): ICalendarState {
  switch (action.type) {
    case calendarContextAction.SET_CALENDAR_LIST:
      return { ...state, calendarList: action.payload };
    case calendarContextAction.SET_CALENDAR_TYPE_LIST:
      return { ...state, calendarTypeList: action.payload };
    case calendarContextAction.REINITIALIZE_STATE:
      return { ...initialCalendarState };
    default:
      return state;
  }
}

const CalendarContext = createContext<ICalendarContext | undefined>(undefined);

const CalendarProvider: React.FC<ICalendarProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(mapReducer, initialCalendarState);
  const { state: userState } = useAuth();
  // useEffect(() => {
  //   if (!userState.user?.id) {
  //     dispatch({
  //       type: calendarContextAction.REINITIALIZE_STATE,
  //       payload: null,
  //     });
  //   }
  // }, [userState.user]);

  useEffect(() => {
    if (userState?.isSignedIn) {
      fetchCalendarEvents([]);
      fetchCalendarEventTypes([]);
    } else {
      dispatch({
        type: calendarContextAction.REINITIALIZE_STATE,
        payload: null,
      });
    }
  }, [userState.isSignedIn]);

  const fetchCalendarEvents = async (
    currentCalendarEvents: Calendar[],
    reqNextToken: string | null = null
  ) => {
    const { calendars, nextToken } = await CalendarService.listCalendars(
      userState.user?.idToken?.getJwtToken() ?? "",
      reqNextToken
    );
    let allCalendarEvents = [...currentCalendarEvents, ...calendars];
    if (nextToken) {
      fetchCalendarEvents(allCalendarEvents, nextToken);
    } else {
      dispatch({
        type: calendarContextAction.SET_CALENDAR_LIST,
        payload: allCalendarEvents,
      });
    }
  };

  const fetchCalendarEventTypes = async (
    currentCalendarEventTypes: CalendarEventType[],
    reqNextToken: string | null = null
  ) => {
    const { listCalendarEventTypes, nextToken } =
      await CalendarService.listCalendarTypes(
        userState.user?.idToken?.getJwtToken() ?? "",
        reqNextToken
      );
    let allCalendarEventTypes = [
      ...currentCalendarEventTypes,
      ...listCalendarEventTypes,
    ];
    if (nextToken) {
      fetchCalendarEventTypes(allCalendarEventTypes, nextToken);
    } else {
      dispatch({
        type: calendarContextAction.SET_CALENDAR_TYPE_LIST,
        payload: allCalendarEventTypes,
      });
    }
  };

  return (
    <CalendarContext.Provider
      value={{ state, dispatch, fetchCalendarEvents, fetchCalendarEventTypes }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

function useCalendarContext(): ICalendarContext {
  const context = React.useContext(CalendarContext);
  if (context === undefined) {
    throw new Error(
      "useCalendarContext must be used within an CalendarProvider"
    );
  }
  return context;
}

export { CalendarProvider, useCalendarContext, calendarContextAction };
