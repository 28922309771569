/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOrganization = /* GraphQL */ `
  subscription OnCreateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onCreateOrganization(filter: $filter) {
      id
      name
      organizationType
      groupType
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorContactId
      operatorContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      admins {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      airports {
        items {
          id
          organizationId
          airportId
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContact
          facilityOwner
          facilityOwnerContact
          legalCounselContact
          legalCounsel
          facilityLocation
          totalCapacityDays
          totalCapacityGallons
          secured
          moExpiration
          moType
          moTypeCustomValue
          leaseExpiration
          leaseType
          leaseTypeCustomValue
          dateLastFinancialSUP
          dateLastA4aOperationalReview
          netInventoryCalculation
          entryFee
          nonMemberRate
          costSharingFormula
          reserveDepositPrebill
          thirdPartyDebt
          memberLoans
          dateLastPropertyAppraisal
          aviationLiabilityLimit
          environmentalLiabilityLimit
          comments
          deleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companies {
        items {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        nextToken
        __typename
      }
      members {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      calendar {
        items {
          id
          organizationId
          calendarEventTypeId
          name
          startDateTime
          endDateTime
          description
          location
          website
          groupRateCode
          organizer
          specialRates
          venue
          venueWebSite
          createdAt
          createdByUserId
          modifiedByUserId
          updatedAt
          organizationCalendarId
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      supportAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      updatedAt
      companyOrganizationsId
      userOrganizationId
      __typename
    }
  }
`;
export const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onUpdateOrganization(filter: $filter) {
      id
      name
      organizationType
      groupType
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorContactId
      operatorContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      admins {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      airports {
        items {
          id
          organizationId
          airportId
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContact
          facilityOwner
          facilityOwnerContact
          legalCounselContact
          legalCounsel
          facilityLocation
          totalCapacityDays
          totalCapacityGallons
          secured
          moExpiration
          moType
          moTypeCustomValue
          leaseExpiration
          leaseType
          leaseTypeCustomValue
          dateLastFinancialSUP
          dateLastA4aOperationalReview
          netInventoryCalculation
          entryFee
          nonMemberRate
          costSharingFormula
          reserveDepositPrebill
          thirdPartyDebt
          memberLoans
          dateLastPropertyAppraisal
          aviationLiabilityLimit
          environmentalLiabilityLimit
          comments
          deleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companies {
        items {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        nextToken
        __typename
      }
      members {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      calendar {
        items {
          id
          organizationId
          calendarEventTypeId
          name
          startDateTime
          endDateTime
          description
          location
          website
          groupRateCode
          organizer
          specialRates
          venue
          venueWebSite
          createdAt
          createdByUserId
          modifiedByUserId
          updatedAt
          organizationCalendarId
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      supportAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      updatedAt
      companyOrganizationsId
      userOrganizationId
      __typename
    }
  }
`;
export const onDeleteOrganization = /* GraphQL */ `
  subscription OnDeleteOrganization(
    $filter: ModelSubscriptionOrganizationFilterInput
  ) {
    onDeleteOrganization(filter: $filter) {
      id
      name
      organizationType
      groupType
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorContactId
      operatorContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      admins {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      airports {
        items {
          id
          organizationId
          airportId
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContact
          facilityOwner
          facilityOwnerContact
          legalCounselContact
          legalCounsel
          facilityLocation
          totalCapacityDays
          totalCapacityGallons
          secured
          moExpiration
          moType
          moTypeCustomValue
          leaseExpiration
          leaseType
          leaseTypeCustomValue
          dateLastFinancialSUP
          dateLastA4aOperationalReview
          netInventoryCalculation
          entryFee
          nonMemberRate
          costSharingFormula
          reserveDepositPrebill
          thirdPartyDebt
          memberLoans
          dateLastPropertyAppraisal
          aviationLiabilityLimit
          environmentalLiabilityLimit
          comments
          deleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      companies {
        items {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        nextToken
        __typename
      }
      members {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      calendar {
        items {
          id
          organizationId
          calendarEventTypeId
          name
          startDateTime
          endDateTime
          description
          location
          website
          groupRateCode
          organizer
          specialRates
          venue
          venueWebSite
          createdAt
          createdByUserId
          modifiedByUserId
          updatedAt
          organizationCalendarId
          __typename
        }
        nextToken
        __typename
      }
      contacts {
        items {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      supportAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      updatedAt
      companyOrganizationsId
      userOrganizationId
      __typename
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onCreateCompany(filter: $filter) {
      id
      name
      type
      organizations {
        items {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        nextToken
        __typename
      }
      members {
        items {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationCompaniesId
      __typename
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onUpdateCompany(filter: $filter) {
      id
      name
      type
      organizations {
        items {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        nextToken
        __typename
      }
      members {
        items {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationCompaniesId
      __typename
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany($filter: ModelSubscriptionCompanyFilterInput) {
    onDeleteCompany(filter: $filter) {
      id
      name
      type
      organizations {
        items {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        nextToken
        __typename
      }
      members {
        items {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationCompaniesId
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      cognitoUserId
      termsAgree
      termsAgreeDate
      participationAgreement
      participationAgreementDate
      organization {
        items {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        nextToken
        __typename
      }
      roles
      companyId
      company {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      firstName
      lastName
      email
      office
      mobile
      linkedInUrl
      imageUrl
      timeZone
      monthlyUpdates
      generalAnnouncements
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      organizationAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationMember {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      lastLoginDate
      weeklyDigestNotification
      monthlyDigestNotification
      quarterlyEmailNotification
      annualDigestNotification
      membershipAlertsNotification
      generalEmailNotification
      organizationSupportAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      organizationContactsId
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      cognitoUserId
      termsAgree
      termsAgreeDate
      participationAgreement
      participationAgreementDate
      organization {
        items {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        nextToken
        __typename
      }
      roles
      companyId
      company {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      firstName
      lastName
      email
      office
      mobile
      linkedInUrl
      imageUrl
      timeZone
      monthlyUpdates
      generalAnnouncements
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      organizationAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationMember {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      lastLoginDate
      weeklyDigestNotification
      monthlyDigestNotification
      quarterlyEmailNotification
      annualDigestNotification
      membershipAlertsNotification
      generalEmailNotification
      organizationSupportAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      organizationContactsId
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      cognitoUserId
      termsAgree
      termsAgreeDate
      participationAgreement
      participationAgreementDate
      organization {
        items {
          id
          name
          organizationType
          groupType
          chairId
          chairContactId
          operatorId
          operatorContactId
          createdAt
          createdByUserId
          deleted
          updatedAt
          companyOrganizationsId
          userOrganizationId
          __typename
        }
        nextToken
        __typename
      }
      roles
      companyId
      company {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      firstName
      lastName
      email
      office
      mobile
      linkedInUrl
      imageUrl
      timeZone
      monthlyUpdates
      generalAnnouncements
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      organizationAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      organizationMember {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deleted
      lastLoginDate
      weeklyDigestNotification
      monthlyDigestNotification
      quarterlyEmailNotification
      annualDigestNotification
      membershipAlertsNotification
      generalEmailNotification
      organizationSupportAdmin {
        items {
          id
          organizationId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      organizationContactsId
      __typename
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onCreateDocument(filter: $filter) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      categoryType
      documentCategoryId
      documentCategory {
        id
        name
        type
        bucketType
        documents {
          nextToken
          __typename
        }
        description
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      bucketType
      title
      description
      publishedByUserId
      publishedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      publishedDate
      url
      fileSize
      fileType {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      companyId
      createdAt
      createByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      categoryTypeId
      category {
        id
        name
        type
        bucketType
        documents {
          nextToken
          __typename
        }
        description
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationDocumentsId
      documentCategoryDocumentsId
      documentFileTypeId
      __typename
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onUpdateDocument(filter: $filter) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      categoryType
      documentCategoryId
      documentCategory {
        id
        name
        type
        bucketType
        documents {
          nextToken
          __typename
        }
        description
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      bucketType
      title
      description
      publishedByUserId
      publishedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      publishedDate
      url
      fileSize
      fileType {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      companyId
      createdAt
      createByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      categoryTypeId
      category {
        id
        name
        type
        bucketType
        documents {
          nextToken
          __typename
        }
        description
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationDocumentsId
      documentCategoryDocumentsId
      documentFileTypeId
      __typename
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument($filter: ModelSubscriptionDocumentFilterInput) {
    onDeleteDocument(filter: $filter) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      categoryType
      documentCategoryId
      documentCategory {
        id
        name
        type
        bucketType
        documents {
          nextToken
          __typename
        }
        description
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      bucketType
      title
      description
      publishedByUserId
      publishedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      publishedDate
      url
      fileSize
      fileType {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      companyId
      createdAt
      createByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      categoryTypeId
      category {
        id
        name
        type
        bucketType
        documents {
          nextToken
          __typename
        }
        description
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationDocumentsId
      documentCategoryDocumentsId
      documentFileTypeId
      __typename
    }
  }
`;
export const onCreateFileType = /* GraphQL */ `
  subscription OnCreateFileType($filter: ModelSubscriptionFileTypeFilterInput) {
    onCreateFileType(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFileType = /* GraphQL */ `
  subscription OnUpdateFileType($filter: ModelSubscriptionFileTypeFilterInput) {
    onUpdateFileType(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFileType = /* GraphQL */ `
  subscription OnDeleteFileType($filter: ModelSubscriptionFileTypeFilterInput) {
    onDeleteFileType(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDocumentCategory = /* GraphQL */ `
  subscription OnCreateDocumentCategory(
    $filter: ModelSubscriptionDocumentCategoryFilterInput
  ) {
    onCreateDocumentCategory(filter: $filter) {
      id
      name
      type
      bucketType
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      description
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDocumentCategory = /* GraphQL */ `
  subscription OnUpdateDocumentCategory(
    $filter: ModelSubscriptionDocumentCategoryFilterInput
  ) {
    onUpdateDocumentCategory(filter: $filter) {
      id
      name
      type
      bucketType
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      description
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDocumentCategory = /* GraphQL */ `
  subscription OnDeleteDocumentCategory(
    $filter: ModelSubscriptionDocumentCategoryFilterInput
  ) {
    onDeleteDocumentCategory(filter: $filter) {
      id
      name
      type
      bucketType
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      description
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      deleted
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onCreateCalendar = /* GraphQL */ `
  subscription OnCreateCalendar($filter: ModelSubscriptionCalendarFilterInput) {
    onCreateCalendar(filter: $filter) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      calendarEventTypeId
      calendarEventType {
        id
        name
        description
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      name
      startDateTime
      endDateTime
      description
      location
      website
      groupRateCode
      organizer
      specialRates
      venue
      venueWebSite
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifiedByUserId
      modifiedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationCalendarId
      __typename
    }
  }
`;
export const onUpdateCalendar = /* GraphQL */ `
  subscription OnUpdateCalendar($filter: ModelSubscriptionCalendarFilterInput) {
    onUpdateCalendar(filter: $filter) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      calendarEventTypeId
      calendarEventType {
        id
        name
        description
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      name
      startDateTime
      endDateTime
      description
      location
      website
      groupRateCode
      organizer
      specialRates
      venue
      venueWebSite
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifiedByUserId
      modifiedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationCalendarId
      __typename
    }
  }
`;
export const onDeleteCalendar = /* GraphQL */ `
  subscription OnDeleteCalendar($filter: ModelSubscriptionCalendarFilterInput) {
    onDeleteCalendar(filter: $filter) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      calendarEventTypeId
      calendarEventType {
        id
        name
        description
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        __typename
      }
      name
      startDateTime
      endDateTime
      description
      location
      website
      groupRateCode
      organizer
      specialRates
      venue
      venueWebSite
      createdAt
      createdByUserId
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifiedByUserId
      modifiedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      organizationCalendarId
      __typename
    }
  }
`;
export const onCreateCalendarEventType = /* GraphQL */ `
  subscription OnCreateCalendarEventType(
    $filter: ModelSubscriptionCalendarEventTypeFilterInput
  ) {
    onCreateCalendarEventType(filter: $filter) {
      id
      name
      description
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCalendarEventType = /* GraphQL */ `
  subscription OnUpdateCalendarEventType(
    $filter: ModelSubscriptionCalendarEventTypeFilterInput
  ) {
    onUpdateCalendarEventType(filter: $filter) {
      id
      name
      description
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCalendarEventType = /* GraphQL */ `
  subscription OnDeleteCalendarEventType(
    $filter: ModelSubscriptionCalendarEventTypeFilterInput
  ) {
    onDeleteCalendarEventType(filter: $filter) {
      id
      name
      description
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onCreateAirportVolume = /* GraphQL */ `
  subscription OnCreateAirportVolume(
    $filter: ModelSubscriptionAirportVolumeFilterInput
  ) {
    onCreateAirportVolume(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      year
      volume
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAirportVolume = /* GraphQL */ `
  subscription OnUpdateAirportVolume(
    $filter: ModelSubscriptionAirportVolumeFilterInput
  ) {
    onUpdateAirportVolume(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      year
      volume
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAirportVolume = /* GraphQL */ `
  subscription OnDeleteAirportVolume(
    $filter: ModelSubscriptionAirportVolumeFilterInput
  ) {
    onDeleteAirportVolume(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      year
      volume
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const onCreateAirport = /* GraphQL */ `
  subscription OnCreateAirport($filter: ModelSubscriptionAirportFilterInput) {
    onCreateAirport(filter: $filter) {
      id
      name
      code
      oldCode
      facility
      region
      state
      place {
        terminal
        gate
        lat
        lon
        __typename
      }
      consortiumGroup {
        items {
          id
          organizationId
          airportId
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContact
          facilityOwner
          facilityOwnerContact
          legalCounselContact
          legalCounsel
          facilityLocation
          totalCapacityDays
          totalCapacityGallons
          secured
          moExpiration
          moType
          moTypeCustomValue
          leaseExpiration
          leaseType
          leaseTypeCustomValue
          dateLastFinancialSUP
          dateLastA4aOperationalReview
          netInventoryCalculation
          entryFee
          nonMemberRate
          costSharingFormula
          reserveDepositPrebill
          thirdPartyDebt
          memberLoans
          dateLastPropertyAppraisal
          aviationLiabilityLimit
          environmentalLiabilityLimit
          comments
          deleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      consortiumType
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorGeneralManagerId
      operatorGeneralManager {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      secondaryContactId
      secondaryContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      facilityOwnerId
      facilityOwner {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      facilityOwnerContactId
      facilityOwnerContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      inventoryAccounting
      deliveryToPlane {
        items {
          id
          airportId
          organizationId
          airportDeliveryToPlaneId
          obFiltration
          hydrant
          closedLoop
          length
          hydrantLocations
          pits
          refuelerLocations
          efso
          efsoLocation
          leakDetection
          leakDetectionDescription
          flowRateF1Peak
          flowRateF1Max
          flowRateF2Peak
          flowRateF2Max
          flowRateF3Peak
          flowRateF3Max
          pipingSizeMin
          pipingSizeMax
          currentIntoPlaneOperators
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deliveryToFacility {
        items {
          id
          airportId
          provider
          organizationId
          method
          primary
          numberLines
          frequency
          frequencyCustomValue
          filtrationType
          filtrationTypeCustomValue
          filtrationDescription
          flowRateTypical
          flowRateMax
          pipingSize
          offloadLocations
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      storage {
        items {
          id
          airportId
          organizationId
          tankId
          yearInstalled
          product
          storageLocation
          storageASTUST
          nextAPI653
          storageFunction
          totalBBL
          usableBBL
          opStatus
          owner
          security
          secondaryContainment
          cathodicProtection
          leakDetection
          totalGals
          usableGals
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      organizationAirportsId
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAirport = /* GraphQL */ `
  subscription OnUpdateAirport($filter: ModelSubscriptionAirportFilterInput) {
    onUpdateAirport(filter: $filter) {
      id
      name
      code
      oldCode
      facility
      region
      state
      place {
        terminal
        gate
        lat
        lon
        __typename
      }
      consortiumGroup {
        items {
          id
          organizationId
          airportId
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContact
          facilityOwner
          facilityOwnerContact
          legalCounselContact
          legalCounsel
          facilityLocation
          totalCapacityDays
          totalCapacityGallons
          secured
          moExpiration
          moType
          moTypeCustomValue
          leaseExpiration
          leaseType
          leaseTypeCustomValue
          dateLastFinancialSUP
          dateLastA4aOperationalReview
          netInventoryCalculation
          entryFee
          nonMemberRate
          costSharingFormula
          reserveDepositPrebill
          thirdPartyDebt
          memberLoans
          dateLastPropertyAppraisal
          aviationLiabilityLimit
          environmentalLiabilityLimit
          comments
          deleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      consortiumType
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorGeneralManagerId
      operatorGeneralManager {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      secondaryContactId
      secondaryContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      facilityOwnerId
      facilityOwner {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      facilityOwnerContactId
      facilityOwnerContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      inventoryAccounting
      deliveryToPlane {
        items {
          id
          airportId
          organizationId
          airportDeliveryToPlaneId
          obFiltration
          hydrant
          closedLoop
          length
          hydrantLocations
          pits
          refuelerLocations
          efso
          efsoLocation
          leakDetection
          leakDetectionDescription
          flowRateF1Peak
          flowRateF1Max
          flowRateF2Peak
          flowRateF2Max
          flowRateF3Peak
          flowRateF3Max
          pipingSizeMin
          pipingSizeMax
          currentIntoPlaneOperators
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deliveryToFacility {
        items {
          id
          airportId
          provider
          organizationId
          method
          primary
          numberLines
          frequency
          frequencyCustomValue
          filtrationType
          filtrationTypeCustomValue
          filtrationDescription
          flowRateTypical
          flowRateMax
          pipingSize
          offloadLocations
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      storage {
        items {
          id
          airportId
          organizationId
          tankId
          yearInstalled
          product
          storageLocation
          storageASTUST
          nextAPI653
          storageFunction
          totalBBL
          usableBBL
          opStatus
          owner
          security
          secondaryContainment
          cathodicProtection
          leakDetection
          totalGals
          usableGals
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      organizationAirportsId
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAirport = /* GraphQL */ `
  subscription OnDeleteAirport($filter: ModelSubscriptionAirportFilterInput) {
    onDeleteAirport(filter: $filter) {
      id
      name
      code
      oldCode
      facility
      region
      state
      place {
        terminal
        gate
        lat
        lon
        __typename
      }
      consortiumGroup {
        items {
          id
          organizationId
          airportId
          chairId
          chairContactId
          operatorId
          operatorGeneralManagerId
          secondaryContact
          facilityOwner
          facilityOwnerContact
          legalCounselContact
          legalCounsel
          facilityLocation
          totalCapacityDays
          totalCapacityGallons
          secured
          moExpiration
          moType
          moTypeCustomValue
          leaseExpiration
          leaseType
          leaseTypeCustomValue
          dateLastFinancialSUP
          dateLastA4aOperationalReview
          netInventoryCalculation
          entryFee
          nonMemberRate
          costSharingFormula
          reserveDepositPrebill
          thirdPartyDebt
          memberLoans
          dateLastPropertyAppraisal
          aviationLiabilityLimit
          environmentalLiabilityLimit
          comments
          deleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      consortiumType
      documents {
        items {
          id
          organizationId
          airportId
          categoryType
          documentCategoryId
          bucketType
          title
          description
          publishedByUserId
          publishedDate
          url
          fileSize
          companyId
          createdAt
          createByUserId
          categoryTypeId
          deleted
          updatedAt
          organizationDocumentsId
          documentCategoryDocumentsId
          documentFileTypeId
          __typename
        }
        nextToken
        __typename
      }
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorGeneralManagerId
      operatorGeneralManager {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      secondaryContactId
      secondaryContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      facilityOwnerId
      facilityOwner {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      facilityOwnerContactId
      facilityOwnerContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      inventoryAccounting
      deliveryToPlane {
        items {
          id
          airportId
          organizationId
          airportDeliveryToPlaneId
          obFiltration
          hydrant
          closedLoop
          length
          hydrantLocations
          pits
          refuelerLocations
          efso
          efsoLocation
          leakDetection
          leakDetectionDescription
          flowRateF1Peak
          flowRateF1Max
          flowRateF2Peak
          flowRateF2Max
          flowRateF3Peak
          flowRateF3Max
          pipingSizeMin
          pipingSizeMax
          currentIntoPlaneOperators
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      deliveryToFacility {
        items {
          id
          airportId
          provider
          organizationId
          method
          primary
          numberLines
          frequency
          frequencyCustomValue
          filtrationType
          filtrationTypeCustomValue
          filtrationDescription
          flowRateTypical
          flowRateMax
          pipingSize
          offloadLocations
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      storage {
        items {
          id
          airportId
          organizationId
          tankId
          yearInstalled
          product
          storageLocation
          storageASTUST
          nextAPI653
          storageFunction
          totalBBL
          usableBBL
          opStatus
          owner
          security
          secondaryContainment
          cathodicProtection
          leakDetection
          totalGals
          usableGals
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      createdBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      modifications {
        fieldName
        fieldValue
        dataOperation
        modifiedAt
        modifiedBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        id
        createdAt
        updatedAt
        __typename
      }
      organizationAirportsId
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateAirportConsortiumGroup = /* GraphQL */ `
  subscription OnCreateAirportConsortiumGroup(
    $filter: ModelSubscriptionAirportConsortiumGroupFilterInput
  ) {
    onCreateAirportConsortiumGroup(filter: $filter) {
      id
      organizationId
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorGeneralManagerId
      operatorGeneralManager {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      secondaryContact
      facilityOwner
      facilityOwnerContact
      legalCounselContact
      legalCounsel
      facilityLocation
      totalCapacityDays
      totalCapacityGallons
      secured
      moExpiration
      moType
      moTypeCustomValue
      leaseExpiration
      leaseType
      leaseTypeCustomValue
      dateLastFinancialSUP
      dateLastA4aOperationalReview
      netInventoryCalculation
      entryFee
      nonMemberRate
      costSharingFormula
      reserveDepositPrebill
      thirdPartyDebt
      memberLoans
      dateLastPropertyAppraisal
      aviationLiabilityLimit
      environmentalLiabilityLimit
      comments
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAirportConsortiumGroup = /* GraphQL */ `
  subscription OnUpdateAirportConsortiumGroup(
    $filter: ModelSubscriptionAirportConsortiumGroupFilterInput
  ) {
    onUpdateAirportConsortiumGroup(filter: $filter) {
      id
      organizationId
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorGeneralManagerId
      operatorGeneralManager {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      secondaryContact
      facilityOwner
      facilityOwnerContact
      legalCounselContact
      legalCounsel
      facilityLocation
      totalCapacityDays
      totalCapacityGallons
      secured
      moExpiration
      moType
      moTypeCustomValue
      leaseExpiration
      leaseType
      leaseTypeCustomValue
      dateLastFinancialSUP
      dateLastA4aOperationalReview
      netInventoryCalculation
      entryFee
      nonMemberRate
      costSharingFormula
      reserveDepositPrebill
      thirdPartyDebt
      memberLoans
      dateLastPropertyAppraisal
      aviationLiabilityLimit
      environmentalLiabilityLimit
      comments
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAirportConsortiumGroup = /* GraphQL */ `
  subscription OnDeleteAirportConsortiumGroup(
    $filter: ModelSubscriptionAirportConsortiumGroupFilterInput
  ) {
    onDeleteAirportConsortiumGroup(filter: $filter) {
      id
      organizationId
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      chairId
      chair {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      chairContactId
      chairContact {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      operatorId
      operator {
        id
        name
        type
        organizations {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        deleted
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        updatedAt
        organizationCompaniesId
        __typename
      }
      operatorGeneralManagerId
      operatorGeneralManager {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      secondaryContact
      facilityOwner
      facilityOwnerContact
      legalCounselContact
      legalCounsel
      facilityLocation
      totalCapacityDays
      totalCapacityGallons
      secured
      moExpiration
      moType
      moTypeCustomValue
      leaseExpiration
      leaseType
      leaseTypeCustomValue
      dateLastFinancialSUP
      dateLastA4aOperationalReview
      netInventoryCalculation
      entryFee
      nonMemberRate
      costSharingFormula
      reserveDepositPrebill
      thirdPartyDebt
      memberLoans
      dateLastPropertyAppraisal
      aviationLiabilityLimit
      environmentalLiabilityLimit
      comments
      deleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDeliveryToPlane = /* GraphQL */ `
  subscription OnCreateDeliveryToPlane(
    $filter: ModelSubscriptionDeliveryToPlaneFilterInput
  ) {
    onCreateDeliveryToPlane(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      airportDeliveryToPlaneId
      obFiltration
      hydrant
      closedLoop
      length
      hydrantLocations
      pits
      refuelerLocations
      efso
      efsoLocation
      leakDetection
      leakDetectionDescription
      flowRateF1Peak
      flowRateF1Max
      flowRateF2Peak
      flowRateF2Max
      flowRateF3Peak
      flowRateF3Max
      pipingSizeMin
      pipingSizeMax
      currentIntoPlaneOperators
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDeliveryToPlane = /* GraphQL */ `
  subscription OnUpdateDeliveryToPlane(
    $filter: ModelSubscriptionDeliveryToPlaneFilterInput
  ) {
    onUpdateDeliveryToPlane(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      airportDeliveryToPlaneId
      obFiltration
      hydrant
      closedLoop
      length
      hydrantLocations
      pits
      refuelerLocations
      efso
      efsoLocation
      leakDetection
      leakDetectionDescription
      flowRateF1Peak
      flowRateF1Max
      flowRateF2Peak
      flowRateF2Max
      flowRateF3Peak
      flowRateF3Max
      pipingSizeMin
      pipingSizeMax
      currentIntoPlaneOperators
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDeliveryToPlane = /* GraphQL */ `
  subscription OnDeleteDeliveryToPlane(
    $filter: ModelSubscriptionDeliveryToPlaneFilterInput
  ) {
    onDeleteDeliveryToPlane(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      airportDeliveryToPlaneId
      obFiltration
      hydrant
      closedLoop
      length
      hydrantLocations
      pits
      refuelerLocations
      efso
      efsoLocation
      leakDetection
      leakDetectionDescription
      flowRateF1Peak
      flowRateF1Max
      flowRateF2Peak
      flowRateF2Max
      flowRateF3Peak
      flowRateF3Max
      pipingSizeMin
      pipingSizeMax
      currentIntoPlaneOperators
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDeliveryToFacility = /* GraphQL */ `
  subscription OnCreateDeliveryToFacility(
    $filter: ModelSubscriptionDeliveryToFacilityFilterInput
  ) {
    onCreateDeliveryToFacility(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      provider
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      method
      primary
      numberLines
      frequency
      frequencyCustomValue
      filtrationType
      filtrationTypeCustomValue
      filtrationDescription
      flowRateTypical
      flowRateMax
      pipingSize
      offloadLocations
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDeliveryToFacility = /* GraphQL */ `
  subscription OnUpdateDeliveryToFacility(
    $filter: ModelSubscriptionDeliveryToFacilityFilterInput
  ) {
    onUpdateDeliveryToFacility(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      provider
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      method
      primary
      numberLines
      frequency
      frequencyCustomValue
      filtrationType
      filtrationTypeCustomValue
      filtrationDescription
      flowRateTypical
      flowRateMax
      pipingSize
      offloadLocations
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDeliveryToFacility = /* GraphQL */ `
  subscription OnDeleteDeliveryToFacility(
    $filter: ModelSubscriptionDeliveryToFacilityFilterInput
  ) {
    onDeleteDeliveryToFacility(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      provider
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      method
      primary
      numberLines
      frequency
      frequencyCustomValue
      filtrationType
      filtrationTypeCustomValue
      filtrationDescription
      flowRateTypical
      flowRateMax
      pipingSize
      offloadLocations
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateStorage = /* GraphQL */ `
  subscription OnCreateStorage($filter: ModelSubscriptionStorageFilterInput) {
    onCreateStorage(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      tankId
      yearInstalled
      product
      storageLocation
      storageASTUST
      nextAPI653
      storageFunction
      totalBBL
      usableBBL
      opStatus
      owner
      security
      secondaryContainment
      cathodicProtection
      leakDetection
      totalGals
      usableGals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateStorage = /* GraphQL */ `
  subscription OnUpdateStorage($filter: ModelSubscriptionStorageFilterInput) {
    onUpdateStorage(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      tankId
      yearInstalled
      product
      storageLocation
      storageASTUST
      nextAPI653
      storageFunction
      totalBBL
      usableBBL
      opStatus
      owner
      security
      secondaryContainment
      cathodicProtection
      leakDetection
      totalGals
      usableGals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteStorage = /* GraphQL */ `
  subscription OnDeleteStorage($filter: ModelSubscriptionStorageFilterInput) {
    onDeleteStorage(filter: $filter) {
      id
      airportId
      airport {
        id
        name
        code
        oldCode
        facility
        region
        state
        place {
          terminal
          gate
          lat
          lon
          __typename
        }
        consortiumGroup {
          nextToken
          __typename
        }
        consortiumType
        documents {
          nextToken
          __typename
        }
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorGeneralManagerId
        operatorGeneralManager {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        secondaryContactId
        secondaryContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        facilityOwnerId
        facilityOwner {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        facilityOwnerContactId
        facilityOwnerContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        inventoryAccounting
        deliveryToPlane {
          nextToken
          __typename
        }
        deliveryToFacility {
          nextToken
          __typename
        }
        storage {
          nextToken
          __typename
        }
        createdAt
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAirportsId
        deleted
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      tankId
      yearInstalled
      product
      storageLocation
      storageASTUST
      nextAPI653
      storageFunction
      totalBBL
      usableBBL
      opStatus
      owner
      security
      secondaryContainment
      cathodicProtection
      leakDetection
      totalGals
      usableGals
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      id
      type
      method
      scope {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      notificationDate
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onUpdateNotification(filter: $filter) {
      id
      type
      method
      scope {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      notificationDate
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onDeleteNotification(filter: $filter) {
      id
      type
      method
      scope {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      notificationDate
      title
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateModifyRecord = /* GraphQL */ `
  subscription OnCreateModifyRecord(
    $filter: ModelSubscriptionModifyRecordFilterInput
  ) {
    onCreateModifyRecord(filter: $filter) {
      fieldName
      fieldValue
      dataOperation
      modifiedAt
      modifiedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateModifyRecord = /* GraphQL */ `
  subscription OnUpdateModifyRecord(
    $filter: ModelSubscriptionModifyRecordFilterInput
  ) {
    onUpdateModifyRecord(filter: $filter) {
      fieldName
      fieldValue
      dataOperation
      modifiedAt
      modifiedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteModifyRecord = /* GraphQL */ `
  subscription OnDeleteModifyRecord(
    $filter: ModelSubscriptionModifyRecordFilterInput
  ) {
    onDeleteModifyRecord(filter: $filter) {
      fieldName
      fieldValue
      dataOperation
      modifiedAt
      modifiedBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInviteLog = /* GraphQL */ `
  subscription OnCreateInviteLog(
    $filter: ModelSubscriptionInviteLogFilterInput
  ) {
    onCreateInviteLog(filter: $filter) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      userId
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      isAccepted
      type
      sendByUserId
      sendBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      acceptedAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInviteLog = /* GraphQL */ `
  subscription OnUpdateInviteLog(
    $filter: ModelSubscriptionInviteLogFilterInput
  ) {
    onUpdateInviteLog(filter: $filter) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      userId
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      isAccepted
      type
      sendByUserId
      sendBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      acceptedAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInviteLog = /* GraphQL */ `
  subscription OnDeleteInviteLog(
    $filter: ModelSubscriptionInviteLogFilterInput
  ) {
    onDeleteInviteLog(filter: $filter) {
      id
      organizationId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      userId
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      isAccepted
      type
      sendByUserId
      sendBy {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      acceptedAt
      deleted
      updatedAt
      __typename
    }
  }
`;
export const onCreateOrgAdminUser = /* GraphQL */ `
  subscription OnCreateOrgAdminUser(
    $filter: ModelSubscriptionOrgAdminUserFilterInput
  ) {
    onCreateOrgAdminUser(filter: $filter) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateOrgAdminUser = /* GraphQL */ `
  subscription OnUpdateOrgAdminUser(
    $filter: ModelSubscriptionOrgAdminUserFilterInput
  ) {
    onUpdateOrgAdminUser(filter: $filter) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteOrgAdminUser = /* GraphQL */ `
  subscription OnDeleteOrgAdminUser(
    $filter: ModelSubscriptionOrgAdminUserFilterInput
  ) {
    onDeleteOrgAdminUser(filter: $filter) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOrgMemberUser = /* GraphQL */ `
  subscription OnCreateOrgMemberUser(
    $filter: ModelSubscriptionOrgMemberUserFilterInput
  ) {
    onCreateOrgMemberUser(filter: $filter) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateOrgMemberUser = /* GraphQL */ `
  subscription OnUpdateOrgMemberUser(
    $filter: ModelSubscriptionOrgMemberUserFilterInput
  ) {
    onUpdateOrgMemberUser(filter: $filter) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteOrgMemberUser = /* GraphQL */ `
  subscription OnDeleteOrgMemberUser(
    $filter: ModelSubscriptionOrgMemberUserFilterInput
  ) {
    onDeleteOrgMemberUser(filter: $filter) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateOrgSupportAdminUser = /* GraphQL */ `
  subscription OnCreateOrgSupportAdminUser(
    $filter: ModelSubscriptionOrgSupportAdminUserFilterInput
  ) {
    onCreateOrgSupportAdminUser(filter: $filter) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateOrgSupportAdminUser = /* GraphQL */ `
  subscription OnUpdateOrgSupportAdminUser(
    $filter: ModelSubscriptionOrgSupportAdminUserFilterInput
  ) {
    onUpdateOrgSupportAdminUser(filter: $filter) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteOrgSupportAdminUser = /* GraphQL */ `
  subscription OnDeleteOrgSupportAdminUser(
    $filter: ModelSubscriptionOrgSupportAdminUserFilterInput
  ) {
    onDeleteOrgSupportAdminUser(filter: $filter) {
      id
      organizationId
      userId
      organization {
        id
        name
        organizationType
        groupType
        chairId
        chair {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        chairContactId
        chairContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        operatorId
        operator {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        operatorContactId
        operatorContact {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        admins {
          nextToken
          __typename
        }
        airports {
          nextToken
          __typename
        }
        companies {
          nextToken
          __typename
        }
        members {
          nextToken
          __typename
        }
        documents {
          nextToken
          __typename
        }
        calendar {
          nextToken
          __typename
        }
        contacts {
          nextToken
          __typename
        }
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        supportAdmin {
          nextToken
          __typename
        }
        deleted
        updatedAt
        companyOrganizationsId
        userOrganizationId
        __typename
      }
      user {
        id
        cognitoUserId
        termsAgree
        termsAgreeDate
        participationAgreement
        participationAgreementDate
        organization {
          nextToken
          __typename
        }
        roles
        companyId
        company {
          id
          name
          type
          createdAt
          createdByUserId
          deleted
          updatedAt
          organizationCompaniesId
          __typename
        }
        firstName
        lastName
        email
        office
        mobile
        linkedInUrl
        imageUrl
        timeZone
        monthlyUpdates
        generalAnnouncements
        createdAt
        createdByUserId
        createdBy {
          id
          cognitoUserId
          termsAgree
          termsAgreeDate
          participationAgreement
          participationAgreementDate
          roles
          companyId
          firstName
          lastName
          email
          office
          mobile
          linkedInUrl
          imageUrl
          timeZone
          monthlyUpdates
          generalAnnouncements
          createdAt
          createdByUserId
          deleted
          lastLoginDate
          weeklyDigestNotification
          monthlyDigestNotification
          quarterlyEmailNotification
          annualDigestNotification
          membershipAlertsNotification
          generalEmailNotification
          updatedAt
          organizationContactsId
          __typename
        }
        modifications {
          fieldName
          fieldValue
          dataOperation
          modifiedAt
          id
          createdAt
          updatedAt
          __typename
        }
        organizationAdmin {
          nextToken
          __typename
        }
        organizationMember {
          nextToken
          __typename
        }
        deleted
        lastLoginDate
        weeklyDigestNotification
        monthlyDigestNotification
        quarterlyEmailNotification
        annualDigestNotification
        membershipAlertsNotification
        generalEmailNotification
        organizationSupportAdmin {
          nextToken
          __typename
        }
        updatedAt
        organizationContactsId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
