import React, { FC, useState, useEffect } from "react";
import { Button, Box, Alert } from "@mui/material";
import { A4ALoader } from "../Common/A4ALoader";
import { TextFieldInput } from "../Common/TextFieldInput";
import { AutoCompleteInput } from "../Common/AutoCompleteInput";
import { constants, helpers } from "../../utils";
import { CognitoService } from "../../services/CognitoService";
import { OrgSchema } from "../../validations/schema";

import { Auth } from "aws-amplify";
import { MailService } from "../../services/MailService";
import { OrganizationService } from "../../services/OrganizationService";
import { UserService } from "../../services/UserService";
import { v4 as uuidv4 } from "uuid";
import { InviteLogService } from "../../services/InviteLogService";
import { toast } from "react-toastify";
import { EmailListInput } from "./../Common/EmailListInput";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
import { useAuth } from "./../AuthProvider/AuthProvider";

// prop type
interface IAddOrganization {
  closeSidebar: () => void;
}

interface IDefaultState {
  name: string;
  type: string;
  groupType: string;
}

// default state
const defaultState = {
  name: "",
  type: "",
  groupType: "",
};

const AddOrganization: FC<IAddOrganization> = ({ closeSidebar }) => {
  // state
  const [apiLoading, setApiLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({ ...defaultState });
  const [error, setError] = useState<IDefaultState>({ ...defaultState });
  const [organizationAdmins, setOrganizationAdmins] = useState<Array<string>>(
    []
  );
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [organizationAdminsError, setOrganizationAdminsError] =
    useState<string>("");

  const { state: dataProviderState, dispatch } = useDataContext();
  const { state: authProviderState } = useAuth();

  // componentDidMount
  useEffect(() => {
    // cleanup
    return () => {};
  }, []);

  // input field on change handler
  const handleChange = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    // update form data
    setFormData((prev: any) => ({ ...prev, [name]: value }));

    // validate form data
    handleValidation(name, value);
  };

  // validate form data
  const handleValidation = (key = "", value = "") => {
    // clear all validation errors
    // setError({ ...defaultState });

    let result: any = {};

    result = OrgSchema.createOrgValidationSchema[key].validate(value) as any;

    // check if there is any validation error
    if (!helpers.isEmptyObject(result.error)) {
      // set error
      setError(
        (prev: any) =>
          ({
            ...prev,
            //...helpers.getFormattedValidationError(result.error),
            [key]: result.error.details[0].message,
          } as any)
      );

      return false;
    } else {
      // clear error
      setError(
        (prev: any) =>
          ({
            ...prev,
            [key]: "",
          } as any)
      );
    }

    // if validation ok
    return true;
  };

  // add organization
  const handleAddOrganization = async () => {
    // console.log("Add Organization.");

    var isValidationError = false;
    var orgId: string | null = null;

    // clear state
    setSuccessMessage("");
    setError({ ...defaultState });
    setOrganizationAdminsError("");

    // check validation error
    Object.keys(formData).forEach((key: any) => {
      let isValid = true;

      if (
        key === "groupType" &&
        formData?.type === constants?.OrganizationTypeChoice?.FUEL_CONSORTIUM
      ) {
        isValid = handleValidation(key, formData[key]);
      } else if (key !== "groupType") {
        isValid = handleValidation(key, formData[key]);
      }

      if (!isValidationError) {
        isValidationError = !isValid;
      }
    });

    // if admin email list empty
    // if (organizationAdmins.length === 0) {
    //   isValidationError = true;
    //   setOrganizationAdminsError("Add at least one admin email.");
    // }

    if (!isValidationError) {
      const ms = new MailService();
      const cs = new CognitoService();

      const us = new UserService();
      const ils = new InviteLogService();

      // start api loader
      setApiLoading(true);

      // create new organization in database
      try {
        const os = new OrganizationService();

        const params = {
          name: formData.name,
          organizationType: formData.type,
          groupType:
            formData.type === "FUEL_CONSORTIUM" ? formData.groupType : null,
          createdByUserId: authProviderState?.user?.dbUserId,
        };

        let data = await os.createOrganization(params);

        // set organization id
        orgId = data?.id;

        data = {
          ...data,
          airport: null,
          operatorGeneralManager: null,
          pending: [],
        };

        // update data provider context api
        dispatch({
          type: dataContextAction.SET_ORGANIZATION_LIST,
          payload: [...dataProviderState?.organizationList, data],
        });

        // console.log(data);
      } catch (error) {
        console.log(error);
        orgId = null;
      }

      // if organization creation failed
      if (orgId === null) {
        // final cleanup
        setFormData({ ...defaultState });
        setError({ ...defaultState });
        setOrganizationAdmins([]);
        setSuccessMessage("");
        setOrganizationAdminsError("");

        // stop api loader
        setApiLoading(false);

        // show toast error message
        toast.error("Consortium creation failed.", { theme: "colored" });

        // close sidebar
        // closeSidebar();

        return;
      }

      var inviteMaillist: any = [];

      if (organizationAdmins.length !== 0) {
        await Promise.all(
          organizationAdmins.map(async (tempUser: any) => {
            let tempData: any = {};

            try {
              // generate random password
              let password = helpers.getRandomPassword();

              // sign-up new user
              const data: any = await Auth.signUp({
                username: tempUser?.email,
                password: password,
                attributes: {
                  "custom:temporaryPassword": password,
                  "custom:isPasswordReset": "no",
                },
              });

              tempData = {
                ...tempData,
                cognitoUserId: `${data?.userSub}`,
                temporaryPassword: password,
                isPasswordReset: "no",
              };
            } catch (error: any) {
              // get error type
              const errorType = error.toString().split(":")[0];

              // if user already exists.
              if (errorType === "UsernameExistsException") {
                // get already exists cognito user data
                const u_data: any = await cs.adminGetUser(tempUser?.email);

                // get cognito user custom attributes
                const attributes: any =
                  helpers.getFormattedCognitoUserAttributes(
                    u_data?.UserAttributes
                  );

                tempData = {
                  ...tempData,
                  cognitoUserId: attributes["sub"],
                  temporaryPassword: attributes["custom:temporaryPassword"],
                  isPasswordReset: attributes["custom:isPasswordReset"],
                };
              }
            }

            // check user email already exist in User table
            const dbUser: any = await us.getUserByEmail(tempUser?.email);

            let emailPayload = {
              email: tempUser?.email,
              userId: "",
              temporaryPassword: tempData?.temporaryPassword,
              isPasswordReset: tempData?.isPasswordReset,
            };

            if (dbUser !== null) {
              if (dbUser?.cognitoUserId === null) {
                const userParams = {
                  id: dbUser?.id,
                  cognitoUserId: tempData?.cognitoUserId,
                };

                // update user data
                await us.updateUserById(userParams);
              }

              emailPayload = {
                ...emailPayload,
                userId: dbUser?.id,
              };
            } else {
              const userParams = {
                cognitoUserId: tempData?.cognitoUserId,
                email: tempUser?.email,
                firstName: tempUser?.firstName,
                lastName: tempUser?.lastName,
                companyId: tempUser?.company !== "" ? tempUser?.company : null,
                mobile: tempUser?.mobile !== "" ? tempUser?.mobile : null,
                quarterlyEmailNotification: true
              };

              // create database entry for new user
              const user = await us.createUser(userParams);

              emailPayload = {
                ...emailPayload,
                userId: user?.id,
              };
            }

            // add email params
            inviteMaillist.push(emailPayload);
          })
        );
      }

      // send invite mail to each user
      if (inviteMaillist.length !== 0) {
        await Promise.all(
          inviteMaillist.map(async (payload: any) => {
            try {
              // create invite uuid
              const inviteId = uuidv4();

              // create invite log api payload
              const inviteLogParams = {
                id: inviteId,
                userId: payload?.userId,
                organizationId: orgId,
                type: "ADMIN",
                isAccepted: false,
                sendByUserId: authProviderState?.user?.dbUserId,
              };

              // create api body
              const body = {
                email: payload?.email,
                organizationName: formData.name,
                inviteType: "Admin",
                inviteId: inviteId,
                temporaryPassword: payload?.temporaryPassword,
                isPasswordReset: payload?.isPasswordReset,
                sender: {
                  firstName: authProviderState?.user?.dbUser?.firstName,
                  lastName: authProviderState?.user?.dbUser?.lastName,
                  company: authProviderState?.user?.dbUser?.company?.name ?? "",
                },
              };

              // create invite log
              await ils.createInviteLog(inviteLogParams);

              await ms.sendOrgInviteMail(body);
            } catch (error: any) {
              console.log(error);
            }
          })
        );
      }

      // final cleanup
      setFormData({ ...defaultState });
      setError({ ...defaultState });
      setOrganizationAdmins([]);
      // setSuccessMessage("Created & invite sent.");
      setOrganizationAdminsError("");

      // stop api loader
      setApiLoading(false);

      // show toast message
      toast.success("Created & invite sent.", { theme: "colored" });

      // close sidebar
      closeSidebar();
    }
  };

  return (
    <div>
      <p>Let's add a new consortium.</p>
      <TextFieldInput
        id="name"
        name="name"
        placeholder="Consortium Name"
        label="Consortium Name"
        error={error.name}
        value={formData.name}
        margin="normal"
        handleChange={handleChange}
        onBlur={() => handleValidation("name", formData.name)}
        // autoFocus
        fullWidth
      />

      <AutoCompleteInput
        id="type"
        name="type"
        placeholder="Select Org. Type"
        label="Consortium Type"
        options={constants.DB_ENUM.OrganizationTypeChoice?.map(
          (item: string) => ({
            label: helpers?.capitalizeFirstLetterEveryWord(
              item.replaceAll("_", " ").toLocaleLowerCase()
            ),
            value: item,
          })
        )}
        error={error.type}
        value={formData.type}
        fullWidth={true}
        handleChange={handleChange}
        onBlur={() => handleValidation("type", formData.type)}
      />

      {formData?.type === "FUEL_CONSORTIUM" && (
        <AutoCompleteInput
          id="groupType"
          name="groupType"
          placeholder="Select Group Type"
          label="Group Type"
          options={constants.DB_ENUM.GroupTypeChoice?.map((item: string) => ({
            label: helpers?.capitalizeFirstLetterEveryWord(
              item.replaceAll("_", " ").toLocaleLowerCase()
            ),
            value: item,
          }))}
          error={error.groupType}
          value={formData.groupType}
          fullWidth={true}
          handleChange={handleChange}
          onBlur={() => handleValidation("groupType", formData.groupType)}
        />
      )}

      <EmailListInput
        emailList={organizationAdmins}
        setEmailList={setOrganizationAdmins}
        error={organizationAdminsError}
        placeholder="Add some admins."
        defaultShowUserForm={false}
      />

      {!apiLoading && (
        <Button
          onClick={handleAddOrganization}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none" }}
        >
          {/* <InsertInvitationIcon /> */}
          Add
        </Button>
      )}
      {!apiLoading && (
        <Button
          onClick={closeSidebar}
          size="small"
          variant="outlined"
          sx={{ my: 2, float: "right", textTransform: "none", mx: 1 }}
          color="error"
        >
          {/* <InsertInvitationIcon /> */}
          Cancel
        </Button>
      )}
      {apiLoading ? (
        <Box sx={{ mt: 2 }}>
          <A4ALoader />
        </Box>
      ) : (
        ""
      )}

      {successMessage !== "" && (
        <Alert severity="success" sx={{ mt: 10 }}>
          {successMessage}
        </Alert>
      )}
    </div>
  );
};

export { AddOrganization };
