import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { ICreateDocumentParams } from "./types";
import {
  CreateDocumentMutation,
  ListDocumentsQuery,
  GetDocumentQuery,
  DeleteDocumentMutation,
  UpdateDocumentMutation,
} from "../../API";
import documents from "../../data/documents.json";
import { constants } from "../../utils";

class DocumentService {
  /*
    Task: Create a new Document using graphql
  */
  async createDocument(Document: ICreateDocumentParams) {
    // send grapql api call
    const temp: any = (await API.graphql({
      query: mutations.createDocument,
      variables: { input: Document },
    })) as { data: CreateDocumentMutation; errors: any[] };

    // send back newly created Document details
    return temp.data.createDocument;
  }

  /*
    Task: get Document by id using graphql
  */
  async getDocumentById(id: string) {
    // send grapql api call
    const temp = (await API.graphql({
      query: queries.getDocument,
      variables: { id },
    })) as { data: GetDocumentQuery; errors: any[] };

    // send back Document details
    return temp.data.getDocument;
  }

  /*
    Task: delete Document by id using graphql
  */
  async deleteDocumentById(id: string) {
    // send grapql api call
    const temp = (await API.graphql({
      query: mutations.deleteDocument,
      variables: { input: { id } },
    })) as { data: DeleteDocumentMutation; errors: any[] };

    // send back Document details
    return temp.data.deleteDocument;
  }

  /*
    Task: update Document by id using graphql
  */
  async updateDocumentById(input: any) {
    // send grapql api call
    const temp = (await API.graphql({
      query: mutations.updateDocument,
      variables: { input },
    })) as { data: UpdateDocumentMutation; errors: any[] };

    // send back technology details
    return temp.data.updateDocument;
  }

  /*
    Task: list all Document using graphql
  */
  async getAllDocuments(
    filter = {},
    nextToken: string | null = null,
    query_src = queries.listDocuments
  ) {
    try {
      // send grapql api call
      const temp = (await API.graphql(
        graphqlOperation(query_src, {
          filter,
          nextToken,
        })
      )) as { data: ListDocumentsQuery; errors: any[] };

      if (temp.data.listDocuments?.items) {
        return {
          documents: temp.data.listDocuments?.items,
          nextToken: temp.data.listDocuments?.nextToken,
        };
      }

      return { documents: [], nextToken: null };
    } catch (error) {
      console.log(error);

      return { documents: [], nextToken: null };
    }
  }

  /*
    Task: load documents from json file
  */
  async loadDocuments() {
    await Promise.all(
      documents?.map(async (item: any) => {
        await this.createDocument(item);
      })
    );
  }
}

export { DocumentService };
