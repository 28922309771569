import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";

class UserSubscription {
  /*
    Task: Get User subscription on create from amplify graphql
  */
  static getOnCreate() {
    return API.graphql(graphqlOperation(subscriptions.onCreateUser)) as any;
  }

  /*
    Task: Get User subscription on update from amplify graphql
  */
  static getOnUpdate() {
    return API.graphql(graphqlOperation(subscriptions.onUpdateUser)) as any;
  }

  /*
    Task: Get User subscription on delete from amplify graphql
  */
  static getOnDelete() {
    return API.graphql(graphqlOperation(subscriptions.onDeleteUser)) as any;
  }
}

export { UserSubscription };
