import { API, graphqlOperation } from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";

class AirportConsortiumGroupSubscription {
  /*
    Task: Get AirportConsortiumGroup subscription on create from amplify graphql
  */
  static getOnCreate() {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateAirportConsortiumGroup)
    ) as any;
  }

  /*
    Task: Get AirportConsortiumGroup subscription on update from amplify graphql
  */
  static getOnUpdate() {
    return API.graphql(
      graphqlOperation(subscriptions.onUpdateAirportConsortiumGroup)
    ) as any;
  }

  /*
    Task: Get AirportConsortiumGroup subscription on delete from amplify graphql
  */
  static getOnDelete() {
    return API.graphql(
      graphqlOperation(subscriptions.onDeleteAirportConsortiumGroup)
    ) as any;
  }
}

export { AirportConsortiumGroupSubscription };
