import { Grid, Card, CardContent, Box, Skeleton } from "@mui/material";

const OrganizationDetail = () => {
  return (
    <>
      <Grid sx={{ my: 2 }}>
        <Card
          style={{
            padding: "20px 5px",
            margin: "0 auto",
            borderRadius: "16px",
            background: "rgb(96, 96, 96, 0.05)",
            minHeight: "75vh",
          }}
        >
          <CardContent>
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30%" }} />
            <>
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "30%" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "30%" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "30%" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "30%" }}
              />

              <Box sx={{ width: "100%", py: 3 }}>
                <Skeleton
                  variant="rounded"
                  sx={{ width: "100%" }}
                  height={200}
                />
              </Box>
            </>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default OrganizationDetail;
