import React, { FC, useState, useEffect } from "react";
import {
  AirportDetailDrawerDocumentSearchContainer,
  AirportDetailDrawerDocumentsSection,
  AirportDetailDrawerDocumentsSectionHeading,
  AirportDetailDrawerDocumentsSectionSubHeading,
  AirportDetailDrawerFilesSectionCardsSegment,
  AirportDetailDrawerFilesSectionGridIcon,
  AirportDetailDrawerFilesSectionIconsContainer,
  AirportDetailDrawerFilesSectionListIcon,
  AirportDetailDrawerFilesSectionSegment,
  AirportDetailDrawerFolderSectionBackIcon,
  AirportDetailDrawerFolderSectionHeadingSection,
  AirportDetailDrawerFolderSectionSegment,
  AirportDetailsDrawerFolderCardContainer,
  AirportDetailsDrawerFolderCardHeading,
} from "./style";
import { IAirportDetail, IAirportDocument } from "../MapProvider/types";
import AirportDetailsDrawerFolderCard from "./AirportDetailsDrawerFolderCard";
import {
  CompanyTypeChoice,
  Document,
  DocumentCategoryTypeChoice,
} from "../../API";
import {
  AirportFileView,
  DocumentCategoryTypeChoiceMapping,
} from "../../constants/map";
import AirportDetailsDrawerFileCard from "./AirportDetailsDrawerFileCard";
import { useMapContext } from "../MapProvider/MapProvider";
import { useAuth } from "../AuthProvider";
import AirportDetailsDrawerFileEditModal from "./AirportDetailsDrawerFileEditModal";
import AirportDetailsDrawerAddFolderCardIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Box,
  Collapse,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AirportDetailsDrawerAddFolderModal from "./AirportDetailsDrawerAddFolderModal";
import { useDataContext } from "../DataProvider/DataProvider";
import { DocumentBucketTypeChoice } from "../../utils/constants";
import { constants } from "../../utils";
import { A4ALoader } from "./../Common/A4ALoader";

interface IFilesTab {
  airport: IAirportDetail;
  // canUserEditOrgInfo: boolean;
}

export interface ICurrentFolder {
  category: DocumentCategoryTypeChoice;
  files: IAirportDocument[];
}

const FilesTab: FC<IFilesTab> = ({
  airport,
}: // canUserEditOrgInfo,
IFilesTab) => {
  const {
    state: { user },
  } = useAuth();
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { state } = useMapContext();
  const { state: dataState } = useDataContext();
  const [currentFolder, setCurrentFolder] = useState<ICurrentFolder | null>();
  const [filesView, setFilesView] = useState<AirportFileView>(
    AirportFileView.LIST
  );
  const [filteredFolders, setFilteredFolders] = useState<any>({});
  const [isAddModalOpened, setIsAddModalOpened] = useState<boolean>(false);
  const [categoryIdNameMapping, setCategoryIdNameMapping] = useState<any>({});
  const [isAddFolderModalOpened, setIsAddFolderModalOpened] =
    useState<boolean>(false);

  // const { documents } = airport;
  let documents: any = dataState?.airportDocumentList?.filter(
    (doc) => doc?.airportId === airport?.id
  );

  const [filteredDocuments, setFilteredDocuments] = useState<
    (IAirportDocument | null)[]
  >([]);

  const folderSelectHandler = (category: DocumentCategoryTypeChoice) => {
    setCurrentFolder({
      category,
      files: filteredFolders[category],
    });
  };

  useEffect(() => {
    if (dataState.documentCategoryList.length) {
      setCurrentFolder(null);
      const filteredFoldersArr: any = {
        // BUDGET_FINANCE: [],
        // ANNUAL_MEETING: [],
        // MO: [],
        // OTHER: [],
        // INSURANCE: [],
        // APPRAISAL: [],
        // LEASE: [],
        // LOGISTICS: [],
        // FUEL_QUALITY: [],
        // BYLAWS: [],
        // AUDIT: [],
        // ENVIRONMENTAL: [],
        // INTERLINE: [],
        // INVENTORY: [],
      };

      const tempCategoryIdNameMapping: any = {};

      dataState.documentCategoryList.forEach((category) => {
        if (category.bucketType === DocumentBucketTypeChoice.AIRPORT) {
          filteredFoldersArr[category.id] = [];
        }
        tempCategoryIdNameMapping[category.id] = category.name;
      });

      setCategoryIdNameMapping(tempCategoryIdNameMapping);

      filteredDocuments
        .filter((document) => {
          if (
            document?.organizationId ===
              state.activeConsortium?.organizationId &&
            (!state?.currentUserDbData?.company ||
              state?.currentUserDbData?.company?.type !==
                CompanyTypeChoice.OPERATOR)
          ) {
            return true;
          } else if (
            document?.organizationId ===
              state.activeConsortium?.organizationId &&
            state?.currentUserDbData?.company?.type ===
              CompanyTypeChoice.OPERATOR &&
            document?.companyId === state?.currentUserDbData?.companyId
          ) {
            return true;
          }
          return false;
        })
        .forEach((document) => {
          filteredFoldersArr[document?.documentCategoryId ?? ""] =
            filteredFoldersArr?.[document?.documentCategoryId ?? ""]?.length
              ? [
                  ...filteredFoldersArr[document?.documentCategoryId ?? ""],
                  document,
                ]
              : [document];
        });
      for (let folderName in filteredFoldersArr) {
        filteredFoldersArr[folderName].sort(
          (a: IAirportDocument, b: IAirportDocument) =>
            new Date(b.publishedDate!).getTime() -
            new Date(a.publishedDate!).getTime()
        );
      }
      setFilteredFolders(filteredFoldersArr);
      if (currentFolder) {
        setCurrentFolder({
          category: currentFolder.category,
          files: filteredFoldersArr[currentFolder.category],
        });
      }
    }
  }, [
    state.activeConsortium,
    filteredDocuments,
    dataState.documentCategoryList,
  ]);

  const handleAddModalClose = () => {
    setIsAddModalOpened(false);
  };

  const handleAddFolderModalClose = () => {
    setIsAddFolderModalOpened(false);
  };

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
  };

  // useEffect(() => {
  //   setFilteredDocuments(
  //     documents?.items?.filter((document: any) => {
  //       return document?.title
  //         ?.toLowerCase()
  //         ?.includes(searchText?.toLowerCase());
  //     }) ?? []
  //   );
  // }, [documents?.items, searchText]);

  useEffect(() => {
    setFilteredDocuments(
      documents?.filter((document: any) => {
        return document?.title
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());
      }) ?? []
    );
  }, [documents.length, searchText, dataState?.airportDocumentList]);

  return (
    <>
      <AirportDetailDrawerDocumentsSection>
        <AirportDetailDrawerDocumentSearchContainer>
          {searchExpanded && (
            <Grow in={searchExpanded}>
              <TextField
                placeholder="Search document..."
                fullWidth
                size="small"
                onChange={handleSearch}
                value={searchText}
              />
            </Grow>
          )}
          <Box>
            <IconButton
              onClick={() => {
                setSearchText("");
                setSearchExpanded((prev) => !prev);
              }}
            >
              <SearchIcon />
            </IconButton>
          </Box>
        </AirportDetailDrawerDocumentSearchContainer>
        <AirportDetailDrawerFolderSectionHeadingSection>
          {!!currentFolder && (
            <AirportDetailDrawerFolderSectionBackIcon
              onClick={() => setCurrentFolder(null)}
            />
          )}
          <AirportDetailDrawerDocumentsSectionHeading
            onClick={() => setCurrentFolder(null)}
          >
            Documents {currentFolder ? ">" : ""}
          </AirportDetailDrawerDocumentsSectionHeading>
          {!!currentFolder && (
            <AirportDetailDrawerDocumentsSectionSubHeading>
              {/*{DocumentCategoryTypeChoiceMapping[currentFolder.category]}}*/}
              {categoryIdNameMapping[currentFolder.category]}
            </AirportDetailDrawerDocumentsSectionSubHeading>
          )}
        </AirportDetailDrawerFolderSectionHeadingSection>
        {!!currentFolder ? (
          <AirportDetailDrawerFilesSectionSegment>
            <AirportDetailDrawerFilesSectionIconsContainer>
              {!!state.activeConsortium?.operatorId && (
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={() => setIsAddModalOpened(true)}
                  sx={{ padding: "0" }}
                >
                  <AddCircleOutlineOutlinedIcon
                    sx={{ fontSize: "40px", color: "black" }}
                  />
                </IconButton>
              )}
              <AirportDetailDrawerFilesSectionGridIcon
                className={
                  filesView === AirportFileView.GRID ? "selected-view" : ""
                }
                onClick={() => setFilesView(AirportFileView.GRID)}
              />
              <AirportDetailDrawerFilesSectionListIcon
                className={
                  filesView === AirportFileView.LIST ? "selected-view" : ""
                }
                onClick={() => setFilesView(AirportFileView.LIST)}
              />
            </AirportDetailDrawerFilesSectionIconsContainer>
            <AirportDetailDrawerFilesSectionCardsSegment
              className={`files-view ${
                filesView === AirportFileView.GRID ? "grid-view" : "list-view"
              }`}
              sx={{
                maxHeight: "50vh",
                overflow: "auto",
              }}
            >
              {currentFolder.files.map((file) => (
                <AirportDetailsDrawerFileCard
                  key={file.id}
                  document={file}
                  view={filesView}
                  isNewFile={(() => {
                    if (!file.publishedDate) return false;
                    if (
                      new Date(file.publishedDate).getTime() >
                      new Date().getTime() - 30 * 24 * 60 * 60 * 1000
                    )
                      return true;
                    return false;
                  })()}
                />
              ))}
            </AirportDetailDrawerFilesSectionCardsSegment>
          </AirportDetailDrawerFilesSectionSegment>
        ) : (
          <AirportDetailDrawerFolderSectionSegment>
            {dataState?.isAirportDocumentListApiLoading ? (
              <A4ALoader />
            ) : (
              Object.keys(filteredFolders)
                ?.sort((a, b) =>
                  categoryIdNameMapping[a]?.localeCompare(
                    categoryIdNameMapping[b]
                  )
                )
                ?.map((folderCategory) => (
                  <AirportDetailsDrawerFolderCard
                    key={folderCategory}
                    category={folderCategory as DocumentCategoryTypeChoice}
                    folderSelectHandler={folderSelectHandler}
                    filesCount={filteredFolders?.[folderCategory]?.length ?? 0}
                    categoryIdNameMapping={categoryIdNameMapping}
                    hasNewFile={filteredFolders[folderCategory].some(
                      (file: Document) => {
                        if (!file.publishedDate) return false;
                        if (
                          new Date(file.publishedDate).getTime() >
                          new Date().getTime() - 30 * 24 * 60 * 60 * 1000
                        )
                          return true;
                        return false;
                      }
                    )}
                  />
                ))
            )}
            {!dataState?.isAirportDocumentListApiLoading &&
              user?.groups?.includes(constants.ROLES.A4A_ADMIN) && (
                <AirportDetailsDrawerFolderCardContainer
                  onClick={() => setIsAddFolderModalOpened(true)}
                >
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    disableRipple
                  >
                    <AirportDetailsDrawerAddFolderCardIcon />
                  </IconButton>
                  <AirportDetailsDrawerFolderCardHeading>
                    Add New Folder
                  </AirportDetailsDrawerFolderCardHeading>
                </AirportDetailsDrawerFolderCardContainer>
              )}
          </AirportDetailDrawerFolderSectionSegment>
        )}
      </AirportDetailDrawerDocumentsSection>
      <AirportDetailsDrawerFileEditModal
        airport={airport}
        currentFolder={currentFolder}
        isModalOpened={isAddModalOpened}
        closeModal={handleAddModalClose}
        isEditMode={false}
        categoryIdNameMapping={categoryIdNameMapping}
      />
      <AirportDetailsDrawerAddFolderModal
        isModalOpened={isAddFolderModalOpened}
        closeModal={handleAddFolderModalClose}
      />
    </>
  );
};

export default FilesTab;
