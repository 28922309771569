import React, { SyntheticEvent, useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {
  Alert,
  Box,
  CardContent,
  Pagination,
  Select,
  Snackbar,
  Stack,
  Typography,
  MenuItem,
  Button,
} from "@mui/material";
import { DateTime } from "luxon";
import { constants } from "./../../utils";
import { useAuth } from "./../AuthProvider";
import {
  CalendarContainer,
  CalendarEventAddButton,
  CalendarEventsContainer,
  CalendarEventsHeading,
  CalendarEventsLoaderText,
  CalendarEventsSegment,
  CalendarLeftPanel,
} from "./style";
// import DateSelectCalendar from "../DateSelectCalendar";
import dayjs, { Dayjs } from "dayjs";
import {
  calendarContextAction,
  useCalendarContext,
} from "../CalendarProvider/CalendarProvider";
import { Calendar } from "../../API";
import CalendarEventCard from "./CalendarEventCard";
import { Sidebar } from "../Common/Sidebar";
import EventDetailSidebar from "./EventDetailSidebar";
import AddEventSidebar from "./AddEventSidebar";
import utc from "dayjs/plugin/utc";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import CalendarService from "../../services/CalendarService";

// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRangePicker } from 'react-date-range';

import DateRangePicker from "rsuite/DateRangePicker";

// (Optional) Import component styles. If you are using Less, import the `index.less` file.
import "rsuite/DateRangePicker/styles/index.css";
import SelectDropdown from "../SelectDropdown";
import DateSelectCalendar from "../DateSelectCalendar";
import {
  MapFilterCloseIcon,
  MapFilterContainer,
  MapFilterFade,
  MapFilterIcon,
  MapFilterIconContainer,
} from "../MapBoxMap/style";
import {
  AirportDetailDrawerFilesSectionGridIcon,
  AirportDetailDrawerFilesSectionIconsContainer,
  AirportDetailDrawerFilesSectionListIcon,
} from "../AiportDetailsDrawer/style";
import {
  useDataContext,
  dataContextAction,
} from "./../DataProvider/DataProvider";
import { useScreenSize } from "../../hooks";

dayjs.extend(utc);

const ViewCalendar = () => {
  const screenSize = useScreenSize();
  const currentDate = new Date();
  const [selectionRange, setSelectionRange] = useState<[Date, Date] | null>([
    currentDate,
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 6,
      currentDate.getDate()
    ),
  ]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // state
  // show data as List or Grid Item
  const { state, dispatch, fetchCalendarEvents, fetchCalendarEventTypes } =
    useCalendarContext();
  const [currentCalendars, setCurrentCalendars] = useState<Calendar[]>([]);
  const [filteredCurrentCalendars, setFilteredCurrentCalendars] = useState<
    Calendar[]
  >([]);
  const [isSuperUser, setIsSuperUser] = React.useState<boolean>(false);
  const [eventPage, setEventPage] = useState(1);

  //const eventPageCount = Math.ceil(currentCalendars.length / rowsPerPage);
  const eventPageCount = Math.ceil(
    filteredCurrentCalendars.length / rowsPerPage
  );

  const startIndex = (eventPage - 1) * rowsPerPage;
  const endIndex = eventPage * rowsPerPage;
  // const currentPageCalendars = currentCalendars.slice(startIndex, endIndex);
  const currentPageCalendars = filteredCurrentCalendars.slice(
    startIndex,
    endIndex
  );
  const [selectedCalendarDate, setSelectedCalendarDate] =
    useState<Dayjs | null>(dayjs(new Date()));
  const [currentMonth, setCurrentMonth] = useState<string>(
    `${selectedCalendarDate?.year()}-${(
      "0" +
      ((selectedCalendarDate?.month() ?? 0) + 1)
    ).slice(-2)}`
  );
  const [highlightedDates, setHighlightedDates] = useState<number[]>([]);
  const [activeEventDetail, setActiveEventDetail] = useState<Calendar | null>(
    null
  );
  const [openAddEventSidebar, setOpenAddEventSidebar] =
    useState<boolean>(false);
  const [deleteEventId, setDeleteEventId] = useState<string>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteAlert, setDeleteAlert] = useState<
    | {
        severity: "success" | "error";
        message: string;
      }
    | undefined
  >();
  const [selectedEventType, setSelectedEventType] = useState<string>("");
  const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(true);
  const [viewMode, setViewMode] = useState<"list" | "grid">("list");
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { state: auth } = useAuth();
  const { state: dataProviderState, dispatch: dataProviderDispatch } =
    useDataContext();

  function handleSelect(ranges: [Date, Date] | null) {
    setSelectedCalendarDate(null);
    setSelectionRange(ranges ?? null);
  }

  // componentDidMount
  useEffect(() => {
    // change page title
    document.title = constants.PAGE_TITLE.CALENDAR;

    // clear global search bar text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_TEXT,
      payload: "",
    });

    // set global search bar placeholder text on page load
    dataProviderDispatch({
      type: dataContextAction?.SET_TOP_BAR_SEARCH_PLACEHOLDER_TEXT,
      payload: "Search calendar...",
    });

    // is signed user is A4A Admin
    if (auth?.user?.groups?.includes(constants.ROLES.A4A_ADMIN)) {
      setIsSuperUser(true);
    }
    // if (!state.calendarList.length) {
    //   fetchCalendarEvents([]);
    // }
    // if (!state.calendarTypeList.length) {
    //   fetchCalendarEventTypes([]);
    // }
    // cleanup
    return () => {};
  }, []);

  useEffect(() => {
    let timeoutId: any = null;

    let searchText = dataProviderState?.topBarSearchText;

    if (searchText.trim() !== "") {
      timeoutId = setTimeout(() => {
        const generalFilter = currentCalendars.filter((calendar) =>
          calendar?.name?.toLowerCase().includes(searchText.toLowerCase())
        );

        // udpate calendar event list
        setFilteredCurrentCalendars([...generalFilter]);
      }, 1000);
    } else {
      // udpate calendar events list
      setFilteredCurrentCalendars(currentCalendars);
    }

    return () => clearTimeout(timeoutId);
  }, [dataProviderState?.topBarSearchText]);

  useEffect(() => {
    if (selectedCalendarDate) {
      // const targetDateString = selectedCalendarDate.toISOString().split("T")[0];
      const targetDateString = formatDateLikeIso(
        DateTime.fromISO(selectedCalendarDate.toISOString(), {
          zone: userTimeZone,
        }).toJSDate()
      );
      let currentCalendarsTemp =
        state.calendarList
          .filter((calendar) => {
            const startDateString = formatDateLikeIso(
              DateTime.fromISO(calendar?.startDateTime!, {
                zone: userTimeZone,
              }).toJSDate()
            );
            const endDateString = formatDateLikeIso(
              DateTime.fromISO(calendar?.endDateTime!, {
                zone: userTimeZone,
              }).toJSDate()
            );
            // const startDateString = calendar?.startDateTime?.split("T")[0] ?? "";
            // const endDateString = calendar?.endDateTime?.split("T")[0] ?? "";
            if (
              (targetDateString >= startDateString &&
                targetDateString <= endDateString) ||
              targetDateString === startDateString ||
              targetDateString === endDateString
            ) {
              return true;
            }

            return false;
          })
          .sort(
            (a, b) =>
              new Date(a?.startDateTime ?? "").getTime() -
              new Date(b?.startDateTime ?? "").getTime()
          ) ?? [];

      setCurrentCalendars(currentCalendarsTemp);
      setFilteredCurrentCalendars(currentCalendarsTemp);
    }
  }, [selectedCalendarDate, state.calendarList]);

  useEffect(() => {
    let currentCalendarsTemp: Calendar[] = [];
    if (selectionRange) {
      const targetStartDateString = formatDateLikeIso(
        DateTime.fromISO(selectionRange[0].toISOString(), {
          zone: userTimeZone,
        }).toJSDate()
      );
      const targetEndDateString = formatDateLikeIso(
        DateTime.fromISO(selectionRange[1].toISOString(), {
          zone: userTimeZone,
        }).toJSDate()
      );
      currentCalendarsTemp =
        state.calendarList.filter((calendar) => {
          const eventStartDateString = formatDateLikeIso(
            DateTime.fromISO(calendar?.startDateTime!, {
              zone: userTimeZone,
            }).toJSDate()
          );
          const eventEndDateString = formatDateLikeIso(
            DateTime.fromISO(calendar?.endDateTime!, {
              zone: userTimeZone,
            }).toJSDate()
          );
          if (
            eventStartDateString <= targetEndDateString &&
            eventEndDateString >= targetStartDateString
          ) {
            return true;
          }
          return false;
        }) ?? [];
    }
    if (selectedEventType) {
      currentCalendarsTemp = currentCalendarsTemp.filter(
        (calendar) => calendar.calendarEventTypeId === selectedEventType
      );
    }
    if (selectionRange || selectedEventType) {
      const tempArr = currentCalendarsTemp.sort(
        (a, b) =>
          new Date(a?.startDateTime ?? "").getTime() -
          new Date(b?.startDateTime ?? "").getTime()
      );

      setCurrentCalendars(tempArr);
      setFilteredCurrentCalendars(tempArr);
    }
  }, [selectionRange, selectedEventType, state.calendarList]);

  useEffect(() => {
    if (!selectedCalendarDate && !selectionRange && !selectedEventType) {
      setCurrentCalendars([]);
      setFilteredCurrentCalendars([]);
    }
  }, [selectedCalendarDate, selectionRange, selectedEventType]);

  const formatDateLikeIso = (date?: Date): string => {
    if (!date) return "";
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    const currentMonthHighlightedDates: number[] = [];
    state.calendarList?.forEach((calendar) => {
      const startDateString = formatDateLikeIso(
        DateTime.fromISO(calendar?.startDateTime!, {
          zone: userTimeZone,
        }).toJSDate()
      ).slice(0, 7);
      const endDateString = formatDateLikeIso(
        DateTime.fromISO(calendar?.endDateTime!, {
          zone: userTimeZone,
        }).toJSDate()
      ).slice(0, 7);
      if (currentMonth === startDateString || endDateString === currentMonth) {
        const startDate = formatDateLikeIso(
          DateTime.fromISO(calendar?.startDateTime!, {
            zone: userTimeZone,
          }).toJSDate()
        );
        const endDate = formatDateLikeIso(
          DateTime.fromISO(calendar?.endDateTime!, {
            zone: userTimeZone,
          }).toJSDate()
        );
        let alteredStartDate: any = startDate;
        while (alteredStartDate <= endDate) {
          const day = DateTime.fromISO(alteredStartDate, {
            zone: userTimeZone,
          });
          if (currentMonth === alteredStartDate?.slice(0, 7))
            currentMonthHighlightedDates.push(day.day);
          alteredStartDate = DateTime.fromISO(alteredStartDate, {
            zone: userTimeZone,
          });
          alteredStartDate = alteredStartDate.plus({ days: 1 });
          alteredStartDate = new Date(alteredStartDate);
          alteredStartDate = formatDateLikeIso(alteredStartDate);
        }
      }
    });
    setHighlightedDates(currentMonthHighlightedDates);
  }, [currentMonth, state.calendarList]);

  const CalendarDateChangeHandler = (value: Dayjs) => {
    setSelectionRange(null);
    setSelectedEventType("");
    setSelectedCalendarDate(value);
  };

  const CalendarMonthChangeHandler = (value: Dayjs) => {
    setCurrentMonth(`${value.year()}-${("0" + (value.month() + 1)).slice(-2)}`);
  };

  const deleteHandler = async () => {
    setIsDeleting(true);
    try {
      const { message, type: severity } =
        await CalendarService.deleteCalendarEvent(deleteEventId!);
      if (severity === "success") {
        dispatch({
          type: calendarContextAction.SET_CALENDAR_LIST,
          payload: state.calendarList.filter(
            (calendar) => calendar.id !== deleteEventId
          ),
        });
      }
      setDeleteAlert({ severity, message });
    } catch (e) {
      setDeleteAlert({
        severity: "error",
        message: "An error occured while deleting event",
      });
    } finally {
      setDeleteEventId("");
      setIsDeleting(false);
    }
  };

  const handleEventPageChange = (_: any, newPage: number) => {
    setEventPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setEventPage(1); // Reset to first page when changing rows per page
  };

  return (
    <>
      <Grid sx={{ mt: 2 }} position="relative">
        <Card
          style={{
            padding: "20px 5px",
            margin: "0 auto",
            borderRadius: "16px",
            background: "rgb(96, 96, 96, 0.05)",
            minHeight: "75vh",
          }}
        >
          <CardContent>
            {/* <Typography gutterBottom variant="h5">
              Calendar
            </Typography> */}
            <CalendarContainer>
              {!isFilterExpanded && (
                <MapFilterIconContainer
                  sx={{ border: "1px solid #eee" }}
                  onClick={() => setIsFilterExpanded(true)}
                >
                  <MapFilterIcon />
                </MapFilterIconContainer>
              )}
              <MapFilterFade in={isFilterExpanded} timeout={500}>
                <MapFilterContainer sx={{ border: "1px solid #eee" }}>
                  <Box width="16rem">
                    <Typography fontWeight="500" fontSize="18px">
                      Date range
                    </Typography>
                    <DateRangePicker
                      value={selectionRange}
                      onChange={handleSelect}
                      size="lg"
                      showOneCalendar={screenSize.width < 600}
                      placement="bottomEnd"
                      menuStyle={{ zIndex: "999" }}
                    />
                  </Box>
                  <SelectDropdown
                    label="Event Type"
                    options={[
                      { name: "All", value: "" },
                      ...state.calendarTypeList
                        .map((eventType) => ({
                          name: eventType.name,
                          value: eventType.id,
                        }))
                        .sort((a, b) => a.name.localeCompare(b.name)),
                    ]}
                    selectedValue={selectedEventType}
                    selectHandler={(e) => {
                      setSelectedCalendarDate(null);
                      setSelectedEventType(e.target.value);
                    }}
                    sx={{ width: "16rem", alignSelf: "end" }}
                  />
                  <Button
                    onClick={() => {
                      setSelectionRange(null);
                      setSelectedEventType("");
                    }}
                    sx={{ textTransform: "none", borderRadius: "16px" }}
                  >
                    Reset Filters
                  </Button>
                  <MapFilterCloseIcon
                    onClick={() => setIsFilterExpanded(false)}
                  />
                </MapFilterContainer>
              </MapFilterFade>
              <CalendarLeftPanel>
                <DateSelectCalendar
                  date={selectedCalendarDate}
                  dateChangeHandler={CalendarDateChangeHandler}
                  CalendarMonthChangeHandler={CalendarMonthChangeHandler}
                  highlightedDates={highlightedDates}
                  // disabled={!!selectionRange || !!selectedEventType}
                />
                {/* <Typography fontWeight="500" fontSize="18px" mt="15px">
                  Date range
                </Typography>
                <DateRangePicker
                  value={selectionRange}
                  onChange={handleSelect}
                  size="lg"
                /> */}

                <CalendarEventAddButton
                  variant="contained"
                  onClick={() => setOpenAddEventSidebar(true)}
                  sx={{ textTransform: "none", borderRadius: "16px" }}
                >
                  Add Event
                </CalendarEventAddButton>
              </CalendarLeftPanel>
              <CalendarEventsContainer>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <CalendarEventsHeading variant="h3">
                    Events
                  </CalendarEventsHeading>
                  <AirportDetailDrawerFilesSectionIconsContainer>
                    <AirportDetailDrawerFilesSectionGridIcon
                      className={viewMode === "grid" ? "selected-view" : ""}
                      onClick={() => setViewMode("grid")}
                    />
                    <AirportDetailDrawerFilesSectionListIcon
                      className={viewMode === "list" ? "selected-view" : ""}
                      onClick={() => setViewMode("list")}
                    />
                  </AirportDetailDrawerFilesSectionIconsContainer>
                </Box>
                {!state.calendarList.length && (
                  <CalendarEventsLoaderText>
                    Fetching events list
                  </CalendarEventsLoaderText>
                )}
                {!!state.calendarList.length && !currentCalendars.length && (
                  <CalendarEventsLoaderText>
                    Please select date(s) to view events
                  </CalendarEventsLoaderText>
                )}
                <CalendarEventsSegment container spacing={2}>
                  {currentPageCalendars.map((calendar) => (
                    <CalendarEventCard
                      key={calendar.name}
                      icon="E"
                      title={calendar.name ?? ""}
                      isDeleting={isDeleting}
                      text={`${
                        calendar.startDateTime
                          ? dayjs(
                              DateTime.fromISO(calendar.startDateTime, {
                                zone: userTimeZone,
                              }).toString()
                            )
                              // ?.utc(false)
                              .format("MMMM DD, YYYY hh:mm:ss A")
                          : ""
                      }${
                        calendar.endDateTime
                          ? ` - ${dayjs(
                              DateTime.fromISO(calendar.endDateTime, {
                                zone: userTimeZone,
                              }).toString()
                            )
                              // ?.utc(false)
                              .format("MMMM DD, YYYY hh:mm:ss A")}`
                          : ""
                      }`}
                      clickHandler={() => setActiveEventDetail(calendar)}
                      deleteHandler={() => {
                        setDeleteEventId(calendar.id);
                      }}
                      viewMode={viewMode}
                    />
                  ))}
                </CalendarEventsSegment>
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Pagination
                    count={eventPageCount}
                    page={eventPage}
                    onChange={handleEventPageChange}
                    shape="rounded"
                  />
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 2 }}
                  >
                    <Typography>Rows per page:</Typography>
                    <Select
                      value={rowsPerPage}
                      onChange={handleChangeRowsPerPage}
                      variant="standard"
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </Stack>
                </Box>
              </CalendarEventsContainer>
            </CalendarContainer>
          </CardContent>
        </Card>
      </Grid>
      <Sidebar
        handleClose={() => setActiveEventDetail(null)}
        open={!!activeEventDetail}
      >
        <EventDetailSidebar
          event={activeEventDetail!}
          setActiveEvent={setActiveEventDetail}
        />
      </Sidebar>
      <Sidebar
        handleClose={() => setOpenAddEventSidebar(false)}
        open={openAddEventSidebar}
      >
        <AddEventSidebar
          isSuperUser={isSuperUser}
          closeSidebar={() => setOpenAddEventSidebar(false)}
        />
      </Sidebar>
      <ConfirmDeleteModal
        open={!!deleteEventId}
        handleClose={() => setDeleteEventId("")}
        handleDelete={deleteHandler}
      />
      <Snackbar
        open={!!deleteAlert}
        autoHideDuration={3000}
        onClose={() => setDeleteAlert(undefined)}
      >
        <Alert
          onClose={() => setDeleteAlert(undefined)}
          severity={deleteAlert?.severity}
          variant="standard"
          sx={{ width: "100%" }}
        >
          {deleteAlert?.message ?? ""}
        </Alert>
      </Snackbar>
    </>
  );
};

export { ViewCalendar };
