import { Box, Button, Grid, Typography, styled, Paper } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

export const CalendarContainer = styled(Box)(({ theme }) => ({
  // marginTop: "50px",
  display: "flex",
  columnGap: "40px",
  "& .rs-input-group": {
    padding: "8px 2px",
    borderColor: "rgba(0, 0, 0, 0.23)",
  },
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    rowGap: "20px",
  },
}));

export const CalendarLeftPanel = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const CalendarEventAddButton = styled(Button)(({ theme }) => ({
  marginTop: "20px",
  padding: "10px 20px",
  alignSelf: "end",
  [theme.breakpoints.down("md")]: {
    marginTop: "15px",
    padding: "7px 15px",
  },
}));

export const CalendarEventsContainer = styled(Box)(() => ({
  width: "100%",
}));

export const CalendarEventsHeading = styled(Typography)(({ theme }) => ({
  fontSize: "26px",
  fontWeight: 600,
  color: "#444",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
}));

export const CalendarEventsLoaderText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontSize: "20px",
  color: "#777",
  marginTop: "17rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "17px",
  },
}));

export const CalendarEventsSegment = styled(Grid)(({ theme }) => ({
  // display: "flex",
  // flexDirection: "column",
  // rowGap: "15px",
  marginTop: "22px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    marginTop: "17px",
  },
}));

export const CalendarEventCardContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "12.5px",
  backgroundColor: "rgb(224, 224, 224)",
  width: "100%",
  gap: "10px",
  borderRadius: "10px",
  cursor: "pointer",
  height: "100%",
  justifyContent: "space-between",
  "&:hover": {
    "& .event-delete-icon": {
      opacity: 1,
    },
  },
  [theme.breakpoints.down("md")]: {
    padding: "10px",
    gap: "7px",
    borderRadius: "7px",
    "& .event-delete-icon": {
      opacity: 1,
    },
  },
}));

export const CalendarEventCardIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  Width: "45px",
  minWidth: "45px",
  height: "45px",
  minHeight: "45px",
  backgroundColor: "#aaa",
  borderRadius: "50%",
  fontWeight: "bold",
  fontSize: "18px",
  [theme.breakpoints.down("md")]: {
    Width: "32px",
    minWidth: "32px",
    height: "32px",
    minHeight: "32px",
    fontSize: "15px",
  },
}));

export const CalendarEventCardContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const CalendarEventCardTitle = styled(Box)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "500",
  [theme.breakpoints.down("md")]: {
    fontSize: "13px",
  },
}));

export const CalendarEventCardText = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  color: "#666",
  fontWeight: "500",
  [theme.breakpoints.down("md")]: {
    fontSize: "11px",
  },
}));

export const CalendarEventDeleteIcon = styled(DeleteIcon)(({ theme }) => ({
  fontSize: "40px",
  marginLeft: "auto",
  alignSelf: "center",
  cursor: "pointer",
  padding: "5px",
  transition: "opacity 300ms",
  opacity: 0,
  [theme.breakpoints.down("md")]: {
    fontSize: "35px",
    padding: "3.5px",
  },
}));

export const EventDetailSidebarContainer = styled(Box)(() => ({
  "& .event-info-card": {
    width: "auto",
    maxWidth: "100%",
  },
}));

export const EventDetailSidebarHeading = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "bold",
  color: "#333",
  marginBottom: "30px",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
    marginBottom: "20px",
  },
}));

export const EventDetailSidebarInfoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  columnGap: "20px",
  [theme.breakpoints.down("md")]: {
    marginTop: "15px",
    columnGap: "15px",
  },
}));

export const EventDetailSidebarInfoBoxHeading = styled(Typography)(
  ({ theme }) => ({
    fontSize: "20px",
    fontWeight: "bold",
    color: "#555",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  })
);

export const EventDetailSidebarInfoBoxText = styled(Typography)(
  ({ theme }) => ({
    fontSize: "18px",
    color: "#777",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  })
);

export const EventTypeInputBox = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: "10px",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    columnGap: "7px",
  },
}));

export const EventTypeAddButton = styled(AddCircleOutlineIcon)(({ theme }) => ({
  fontSize: "30px",
  color: "#1976d2",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    fontSize: "24px",
  },
}));
