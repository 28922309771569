import {
  Box,
  Collapse,
  Fade,
  Typography,
  styled,
  Backdrop,
  IconButton,
} from "@mui/material";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export const AllMapsWrapper = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  "& .mapboxgl-ctrl-bottom-left": {
    display: "none",
  },
  "& .mapboxgl-ctrl.mapboxgl-ctrl-attrib": {
    display: "none",
  },
  "& .marker-popup": {
    position: "absolute",
    left: 0,
    top: 0,
    translate: "50%",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid grey",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    paddingBottom: "2px",
    maxWidth: "240px",
    width: "240px",
    "& .mapboxgl-popup-content": {
      boxShadow: "none",
      padding: "0px",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    "& .popup-code-heading": {
      fontSize: "20px",
      fontWeight: "600",
    },
    "& .popup-heading": {
      fontSize: "17px",
      fontWeight: "600",
      color: "#666666",
      marginBottom: "10px",
    },
    "& .popup-viewmore-btn": {
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      color: "#1976d2",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: "0.02857em",
      textTransform: "uppercase",
      padding: "6px 8px",
      borderRadius: "4px",
      cursor: "pointer",
      justifyContent: "center",
      position: "relative",
      boxSizing: "border-box",
      WebkitTapHighlightColor: "transparent",
      backgroundColor: "transparent",
      outline: 0,
      border: 0,
      margin: 0,
      display: "inline-flex",
      WebkitAlignItems: "center",
      WebkitBoxAlign: "center",
      MsFlexAlign: "center",
      alignItems: "center",
      width: "7rem",
      marginLeft: "auto",
      "&:hover": {
        textDecoration: "none",
        backgroundColor: "rgba(25, 118, 210, 0.04)",
      },
    },
    "& .popup-consortium-heading": {
      fontSize: "16px",
      fontWeight: "600",
      marginTop: "10px",
      "& span": {
        fontWeight: "400",
      },
    },
    "& .popup-multi-operator-wrapper": {
      paddingLeft: "10px",
    },
    "& .popup-operator": {
      marginTop: "8px",
      display: "flex",
      columnGap: "10px",
    },
    "& .popup-operator-heading": {
      fontSize: "14px",
      fontWeight: "bold",
      color: "#666",
    },
    "& .popup-operator h3, & .popup-operator p": {
      fontSize: "13px",
      margin: "0px",
      fontWeight: "400",
    },
    "&  .popup-operator p": { cursor: "pointer", color:'rgb(25, 118, 210)' },
    "& .popup-operator h3": {
      marginBottom: "2px",
    },
    "& .popup-operator span": {
      fontWeight: "600",
      cursor: "pointer",
    },
    "& .volume-popup-operator h3": {
      marginTop: "8px",
      fontSize: "16px",
      fontWeight: "300",
    },
    "& .volume-last-5-year-box": {
      paddingLeft: "10px",
      marginTop: "0px",
    },
    "& .volume-last-5-year-reading": {
      display: "flex",
      fontSize: "14px",
      gap: "5px",
    },
    "& .volume-airport-heading": {
      fontSize: "17px",
      color: "#666666",
      marginBottom: "10px",
    },
  },
}));

export const SmallMapsWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  width: "0%",
  maxWidth: "550px",
}));

export const MainMapWrapper = styled(Box)(() => ({
  position: "relative",
  flex: "1",
}));

export const SmallMapWrapper = styled(Box)(() => ({
  position: "relative",
  width: "100%",
}));

export const SmallMapContainer = styled(Box)(() => ({
  height: "calc(100vh - 50vh)",
  maxHeight: "50vh",
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  position: "absolute",
  top: 0,
  left: 0,
  "&.hawaii-map": {
    borderTop: "10px solid white",
  },
}));

export const MapContainer = styled(Box)(() => ({
  height: "calc(100vh - 100px)",
  maxHeight: "calc(100vh - 100px)",
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  position: "absolute",
  top: 0,
  left: 0,
  // borderLeft: "10px solid white",
}));

export const MapFilterIconContainer = styled(Box)(() => ({
  position: "absolute",
  left: "50%",
  top: "10px",
  transform: "translateX(-50%)",
  zIndex: 2,
  padding: "10px",
  backgroundColor: "white",
  cursor: "pointer",
  borderRadius: "100%",
  columnGap: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const MapFilterIcon = styled(ExpandMoreIcon)(() => ({
  fontSize: "32px",
}));

export const MapFilterCloseIcon = styled(ExpandLessIcon)(() => ({
  fontSize: "42px",
  padding: "5px",
  cursor: "pointer",
}));

export const MapFilterFade = styled(Fade)(() => ({
  position: "absolute",
  left: "50%",
  top: "10px",
  zIndex: 10,
  transform: "translateX(-50%)",
}));

export const MapFilterContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "10px",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: 1,
  display: "flex",
  columnGap: "10px",
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "10px",
  alignItems: "center",
  overflowY: "auto",
  maxWidth: "calc(100% - 20px)",
  transition: "height 0.3s ease-in-out",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    rowGap: "10px",
    width: "200px",
    left: "auto",
    right: "0px",
    transform: "translateX(0)",
    "& .MuiBox-root": {
      width: "100%",
    },
  },
}));

export const MapLegendCollapseBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "10px",
  bottom: "calc(-100vh + 10px + 100px)",
  zIndex: 1,
}));

export const MapLegendContainer = styled(Box)(() => ({
  display: "flex",
  transition: "height 0.3s ease-in-out",
  backgroundColor: "white",
  borderRadius: "25px",
  minWidth: "225px",
  padding: "15px",
  flexDirection: "column",
  position: "relative",
}));

export const MapLegendHeading = styled(Typography)(() => ({
  fontSize: "1rem",
  fontWeight: "bold",
  // marginTop: "15px",
}));

export const MapOrgTypeLegendContainer = styled(Box)(() => ({
  zIndex: 4,
  paddingLeft: "15px",
  marginTop: "5px",
}));

export const MapOrgTypeLegendValueCard = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  columnGap: "10px",
  width: "100%",
  marginTop: "8px",
}));

export const MapOrgTypeLegendValueColor = styled(Box)(() => ({
  height: "12px",
  width: "12px",
  borderRadius: "50%",
}));

export const MapOrgTypeLegendValueText = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "500",
}));

export const VolumeMapLegendContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  transition: "height 0.3s ease-in-out",
  width: "400px",
  backgroundColor: "white",
  borderRadius: "25px",
  padding: "15px",
  flexDirection: "column",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    maxWidth: '100%',
    width:'265px',
    borderRadius: "20px",
    padding: "10px",
  },
}));

export const MapLegendTextBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

export const MapLegendCloseIcon = styled(CloseIcon)(({ theme }) => ({
  fontSize: "25px",
  cursor: "pointer",
  zIndex:5,
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
  // position: "absolute",
  // right: "20px",
}));

export const MapLegendHeatmapLegendContainer = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  marginTop: "10px",
}));

export const MapLegendHeatmapLegendCard = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
  flex: "1",
}));

export const MapLegendHeatmapLegendColor = styled(Box)(() => ({
  height: "17px",
  width: "100%",
}));

export const MapLegendHeatmapLegendName = styled(Typography)(() => ({
  fontSize: "14px",
  fontWeight: "500",
}));

export const MapLegendIconContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: "10px",
  bottom: "calc(-100vh + 10px + 100px)",
  zIndex: 1,
  padding: "10px",
  // height: "60px",
  backgroundColor: "white",
  cursor: "pointer",
  borderRadius: "15px",
  columnGap: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    padding: "5px",
    borderRadius: "7.5px",
    columnGap: "5px",
  },
}));

export const MapLegendIcon = styled(LegendToggleIcon)(({ theme }) => ({
  fontSize: "32px",
  [theme.breakpoints.down("md")]: {
    fontSize: "22px",
  },
}));

export const MapLegendText = styled(Typography)(() => ({
  fontSize: "1rem",
  fontWeight: "bold",
}));

export const VolumeCategoryIcon = styled(Box)(({ theme }) => ({
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  [theme.breakpoints.down("md")]: {
    width: "7px",
    height: "7px",
  },
}));

export const VolumeButtonContainer = styled(Box)(({ theme }) => ({
  zIndex: 2,
  display: "flex",
  alignItems: "center",
  columnGap: "10px",
  position: "absolute",
  right: "55px",
  bottom: "calc(-100vh + 10px + 100px)",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    rowGap: "10px",
    right: "10px",
    bottom: "calc(-100vh + 10px + 100px + 75px)",
  },
}));

export const AddVolumeButton = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  padding: "10px",
  borderRadius: "10px",
  fontSize: "1rem",
  fontWeight: "bold",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    zIndex: 2,
    padding: "7px",
    borderRadius: "7px",
  },
}));

export const AddVolumeModalContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  backgroundColor: "white",
  boxShadow: "24",
  borderRadius: "10px",
  border: "1px solid grey",
  padding: "15px 35px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    left: "0%",
    transform: "translate(0%, -50%)",
    boxShadow: "20",
    borderRadius: "7px",
    padding: "10px 25px",
  },
}));

export const AddVolumeModalHeading = styled(Typography)(() => ({
  fontSize: "1.35rem",
  fontWeight: "bold",
}));

export const AddVolumeModalBackdrop = styled(Backdrop)(() => ({
  backgroundColor: "#FFFa",
  color: "#0288d1",
  zIndex: "99999999",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const AddVolumeModalProgressBox = styled(Box)(() => ({
  display: "flex",
}));

export const AddVolumeModalProgressTitle = styled(Typography)(({ theme }) => ({
  fontsize: "11px",
  color: "#0288d1",
  [theme.breakpoints.down("md")]: {
    fontsize: "9px",
  },
}));

export const AddVolumeModalProgressValue = styled(Typography)(({ theme }) => ({
  fontsize: "10px",
  fontWeight: "bold",
  color: "#0288d1",
  marginLeft: "5px",
  marginRight: "10px",
  [theme.breakpoints.down("md")]: {
    fontsize: "7px",
    marginLeft: "3px",
    marginRight: "7px",
  },
}));

export const AddVolumeModalReportContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "15px",
  marginTop: "25px",
  width: "350px",
  [theme.breakpoints.down("md")]: {
    rowGap: "10px",
    marginTop: "20px",
    width: "275px",
  },
}));

export const AddVolumeReportContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  backgroundColor: "white",
  boxShadow: "24",
  borderRadius: "10px",
  border: "1px solid grey",
  padding: "15px 35px",
  display: "flex",
  alignItems: "center",
  maxHeight: "400px",
  flexDirection: "column",
  overflow: "auto",
  scrollbarWidth: "none",
  [theme.breakpoints.down("md")]: {
    padding: "10px 25px",
    maxHeight: "300px",
    boxShadow: "20",
    borderRadius: "7px",
    left: "0%",
    transform: "translate(0%, -50%)",
  },
}));

export const AddVolumeModalReportBox = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
}));

export const AddVolumeModalReportHeading = styled(Typography)(({ theme }) => ({
  fontsize: "12px",
  color: "#333",
  [theme.breakpoints.down("md")]: {
    fontsize: "10px",
  },
}));

export const AddVolumeModalReportText = styled(Typography)(({ theme }) => ({
  fontsize: "11px",
  color: "#666",
  fontWeight: "bold",
  [theme.breakpoints.down("md")]: {
    fontsize: "9px",
  },
}));

export const AddVolumeFailReasonBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  rowGap: "7.5px",
  [theme.breakpoints.down("md")]: {
    rowGap: "5px",
  },
}));

export const MapTitle = styled(Typography)(({ theme }) => ({
  fontSize: "22px",
  color: "#222",
  fontWeight: "bold",
  marginBottom: "5px",
  zIndex: 1,
  [theme.breakpoints.down("md")]: {
    fontSize: "17px",
    marginBottom: "3px",
  },
}));

export const MapListContainer = styled(Box)(() => ({
  position: "absolute",
  left: "0px",
  top: "0px",
  width: "100%",
  height: "calc(100vh - 100px)",
  '& [role="table"]': {
    height: "calc(100vh - 100px)",
  },
}));

export const ListViewSwitchButton = styled(IconButton)(() => ({
  position: "absolute",
  right: "55px",
  bottom: "calc(-100vh + 10px + 100px)",
  zIndex: 2,
  backgroundColor: "white",
  padding: "10px",
  borderRadius: "10px",
  fontSize: "1rem",
  fontWeight: "bold",
  cursor: "pointer",
  color: "black !important",
}));
