import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Box, SxProps } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IMultiSelectCheckboxDropdown {
  label: string;
  options: Array<{ name: string; value: number | string }>;
  selectedValue: string[] | number[];
  selectHandler: (event: SelectChangeEvent<string[]>) => void;
  sx?: SxProps;
  renderValue?: string;
}

const MultiSelectCheckboxDropdown: React.FC<IMultiSelectCheckboxDropdown> = ({
  label,
  selectedValue,
  selectHandler,
  options,
  sx,
  renderValue,
}: IMultiSelectCheckboxDropdown) => {
  return (
    <Box sx={sx}>
      <FormControl sx={{ m: 1, minWidth: 175 }}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId={`${label}-multiple-checkbox-label`}
          id={`${label}-multiple-checkbox`}
          multiple
          value={selectedValue.map((value) => value.toString())}
          onChange={selectHandler}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) =>
            renderValue ? renderValue : selected.join(", ")
          }
          MenuProps={MenuProps}
        >
          {options.map(({ name, value }) => (
            <MenuItem key={value} value={value}>
              <Checkbox
                checked={
                  selectedValue.findIndex(
                    (selectedVal) => selectedVal === value
                  ) > -1
                }
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MultiSelectCheckboxDropdown;
