import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import {
  DateSelectCalendarContainer,
  DateSelectCalendarDate,
  DateSelectCalendarDateContainer,
  DateSelectCalendarHeader,
} from "./style";
import {
  LocalizationProvider,
  PickersDayProps,
  PickersDay,
} from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { PickerSelectionState } from "@mui/x-date-pickers/internals";

interface IDateSelectCalendar {
  date: Dayjs | null;
  dateChangeHandler: (
    value: any,
    selectionState?: PickerSelectionState | undefined
  ) => void;
  CalendarMonthChangeHandler?: (value: Dayjs) => void;
  highlightedDates?: number[];
  disabled?: boolean;
}

const DateSelectCalendar: React.FC<IDateSelectCalendar> = ({
  date,
  dateChangeHandler,
  CalendarMonthChangeHandler,
  highlightedDates = [],
  disabled = false,
}: IDateSelectCalendar) => {
  const CustomDay: React.FC<PickersDayProps<Dayjs>> = ({
    children,
    day,
    ...others
  }) => {
    const isHighlighted = highlightedDates.includes(day.date());

    return (
      <PickersDay
        day={day}
        className={isHighlighted ? "Mui-custom-highlighted" : ""}
        sx={{
          width: "30px",
          height: "30px",
          margin: "2px",
        }}
        {...others}
      />
    );
  };

  return (
    <DateSelectCalendarContainer>
      <DateSelectCalendarHeader>
        <DateSelectCalendarDateContainer>
          <DateSelectCalendarDate>
            {date?.format("ddd, MMM D") ?? ""}
          </DateSelectCalendarDate>
        </DateSelectCalendarDateContainer>
      </DateSelectCalendarHeader>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={date}
          onChange={dateChangeHandler}
          slots={{
            day: CustomDay,
          }}
          onMonthChange={CalendarMonthChangeHandler}
          onYearChange={CalendarMonthChangeHandler}
          disabled={disabled}
        />
      </LocalizationProvider>
    </DateSelectCalendarContainer>
  );
};

export default DateSelectCalendar;
